import React, { forwardRef } from "react";
import { MdSort } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { MaterialTable } from "src/components/MaterialTable";
import { Spinner } from "src/components/Spinner"
import { useTranslation } from "react-i18next";

function CertificationsTable(props) {
  /** redux store useDispatch & useSelector ------------------------------ **/
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  /** Variable declaration --------------------------------------------------- **/
  const allData = [];
  const tableIcons = {
    SortArrow: forwardRef((props, ref) => <MdSort {...props} ref={ref} />),
  };


  /** handler functions--------------------------------------------------- **/

  /** Function execute when click on edit action--------------------- */
  const handleEditClickEvent = (o, p) => {
    navigate(`/certifications/profile/${o.id}`);
  };

  /** Function to create table data which is used to show on table------------------------ */
  const createTableData = () => {
    if (!!props.certificationsList && props?.certificationsList?.length > 0) {
      props?.certificationsList?.map((o, index) => {
        allData.push({
          name: o.name,
          url: o.url,
          template: o.template,
          action: (
            <MdEdit key={index}
              style={{ cursor: "pointer" }}
              onClick={(e) => handleEditClickEvent(o)}
            />
          ),
        });
      });
    }
  };

  createTableData();

  return (
    <>
      {props.loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <MaterialTable
          columnNames={[
            { title: "NAME", field: "name" },
            { title: "URL", field: "url" },
            { title: "TEMPLATE NAME", field: "template" },
            { title: t("Action"), field: "action", sorting: false, headerStyle: { textAlign: 'center' }, cellStyle: { textAlign: 'center' } },
          ]}
          tableData={allData}
        ></MaterialTable>
      )}
      <br></br>
    </>
  );
}

export default CertificationsTable;
