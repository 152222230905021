import React from "react";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { Spinner } from "src/components/Spinner";
import { MaterialTable } from "src/components/MaterialTable";
import { useTranslation } from "react-i18next";

function TemplatesTable(props) {
  /** redux store useDispatch & useSelector ------------------------------ **/
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  /** Variable declaration --------------------------------------------------- **/
  const allData = [];

   /** handler functions--------------------------------------------------- **/

  /** Function execute when click on edit action--------------------- */
  const handleEditClickEvent = (o) => {
    navigate(`/templates/${o.name}`,{state: { templateData: o }});
  };

  /** Function to create table data which is used to show on table------------------------ */
  const createTableData = () => {
    if (!!props.templatesList && props.templatesList.length > 0) {
      props.templatesList.map((o, index) => {
        allData.push({
          name: o.name,
          id: o.id,
          modifiedAt: moment.unix(o?.modifiedDate?._seconds).format('DD-MMM-YY HH:mm:ss'),
          action: (
            <MdEdit key={index} style={{ cursor: "pointer" }} onClick={(e) => handleEditClickEvent(o)} />
          ),
        });
      });
    }
  };
  createTableData();

  return (
    <>
      {props.loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <MaterialTable
          columnNames={[
            { title: t("productTemplateId"), field: "id" },
            { title: t("productTemplateName"), field: "name" },
            { title: t("productTemplateModifiedDate"), field: "modifiedAt" },
            { title: t("Action"), field: "action", sorting: false, headerStyle: { textAlign: 'center' }, cellStyle: { textAlign: 'center' } },
          ]}
          tableData={allData}
        ></MaterialTable>
      )}
      <br></br>
    </>
  );
}

export default TemplatesTable


