import React, { useState, useRef, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPassSchema } from '../../../helper/yup-schema'
import { useNavigate } from 'react-router-dom';
import { resetPassword } from "src/redux/auth/authThunk";
import { useTranslation } from 'react-i18next';
import { TextWithLink } from "src/components/TextWithLink";
import { Spinner } from "react-bootstrap";

const ResetPassword = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [linkSent, setLinkSent] = useState(false);
  const { register, handleSubmit, watch, formState: { errors }, } = useForm({
    resolver: yupResolver(resetPassSchema)
  });
  const onSubmit = (data) => {
    dispatch(resetPassword(data));
  };
  const _state = useSelector(
    (state) => (state.login),
    shallowEqual
  );
  useEffect(() => {
    _state.passwordSent && setLinkSent(true)
  }, [_state])
  return (
    <>
      <p className="content-header">
        {t('Trace the product lifecycles, at each single step at atomic level')}
      </p>
      <div>
        <p className="content-subtitle">{t('Reset Password')}</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div><input name='email' {...register("email")} className={"login-input"} type="text" placeholder={t('Enter Registered Email ID')} /></div>
          <span className="login-error-text"> {!!errors.email && errors.email.message}</span>
          {linkSent && <span className="reset-success-text">{t('Reset Link sent on the registered Email ID')}</span>}
          {_state.error && <span className="login-error-text">{t('Email not found')}</span>}
          <div style={{ marginTop: 65 }}>
            <button type='submit' className="login-button">
            {!_state.actionsLoading ? (
              t("RESET PASSWORD")
            ) : (
              <Spinner animation="border" variant="light" />
            )}
          </button>
          </div>
        </form>
        <div className="signup-container" style={{ fontSize: 13, flex: 1, cursor: 'pointer', paddingBottom: 50, textAlign: 'center', justifyContent: 'center' }} >
          <TextWithLink onClick={() => navigate("/login")} text={t("Log In")} color="white" />
        </div>
      </div>
    </>
  )
}

export default ResetPassword;