import React, { useContext, useEffect } from "react";
import { getProductFeedbackDetail } from "src/redux/feedback/feedbackThunk";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {  useParams } from "react-router-dom";

import { Spinner } from "src/components/Spinner";
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel";
import { InputTextareaWithLabel } from "src/components/inputComponents/InputTextareaWithLabel";
import { useTranslation } from "react-i18next";

import objectPath from "object-path";
import moment from 'moment';
import { AppContext } from "src/pages/AppContext";

const ViewFeedback = () => {
  const context = useContext(AppContext)
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  let { id } = useParams();

  useEffect(() => {
    dispatch(getProductFeedbackDetail({ id: id }))
  }, []);

  const _state = useSelector(
    (state) => (state.feedback),
    shallowEqual
  );

  const loading = _state.isLoading;
  return (
    <>
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div style={{ height: '100%' }} className="pt-3">
        
            {context.authState.level === 10 &&
              <InputTextWithLabel
                    title={t('Emails')}
                    readOnly={true}
                    value={!!_state.feedbackDetail.email ? _state.feedbackDetail.email : "Anonymous"}
                    type="text"
                    placeholder={t("Email")}
              ></InputTextWithLabel>}

          <InputTextWithLabel
                    label={t('UPIDS')}
                    readOnly={true}
                    value={_state.feedbackDetail.articleIdentifier}
                    type="text"
                    placeholder={t("UPIDS")}
              ></InputTextWithLabel>

         
          <InputTextWithLabel
                    label={t('_Business Id')}
                    readOnly={true}
                    value={objectPath.get(_state, 'feedbackDetail.feedbackToBusinessId.0.id')}
                    type="text"
                    placeholder={t("_Business Id")}
              ></InputTextWithLabel>

         
          <InputTextWithLabel
                    label={t('_Product Name')}
                    readOnly={true}
                    value={_state.feedbackDetail.productName}
                    type="text"
                    placeholder={t("Product Name")}
              ></InputTextWithLabel>

         
          <InputTextWithLabel
                    label={t('Date')}
                    readOnly={true}
                    value={moment.unix(_state.feedbackDetail.timestamp).format("YYYY-MM-DD HH:mm:ss")}
                    type="text"
                    placeholder={t("Date")}
              ></InputTextWithLabel>

              <InputTextWithLabel
                    label={t("Text Analytics")}
                    readOnly={true}
                    value={!!_state.feedbackDetail.textAnalytics ? _state.feedbackDetail.textAnalytics.score : ''}
                    type="text"
                    placeholder={t("Date")}
              ></InputTextWithLabel>    

              <InputTextareaWithLabel
                    label={t("Feedback")}
                    readOnly={true}
                    value={_state.feedbackDetail.text}
                    type="text"
                    placeholder={t("Feedback")}
                    showTooltip={false}
                  ></InputTextareaWithLabel>
        </div>
      )}
    </>
  )
}

export default ViewFeedback;