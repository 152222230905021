import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import common_en from './languages/en/translation.json'
import common_fi from './languages/fi/translation.json'

const langDetectorOptions = {
    // order and from where user language should be detected
    order: ['localStorage'],
  
    // keys or params to lookup language from
    // lookupCookie: 'locale',
    lookupLocalStorage: 'locale',
  
    // cache user language on
    caches: ['localStorage'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  
    // only detect languages that are in the whitelist
    checkWhitelist: true,
  };

// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const Languages = ['en', 'fi'];


i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        debug: false,
        whitelist: Languages,
        detection: langDetectorOptions,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                common: common_en
            },
            fi: {
                common: common_fi
            },
        }
    });


export default i18n;