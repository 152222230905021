import { createSlice } from "@reduxjs/toolkit";
import { saveFavourites, getFavourites } from "./favouritesThunk";

const initialFavouritesState = {
  actionsLoading: false,
  response: null,
  error: null,
  isLoading: false,
  list: [],
};

export const favouritesSlice = createSlice({
  name: "favourites",
  initialState: initialFavouritesState,
  reducers: {
    addToList: (state, action) => {
      state.list = [...state.list, action.payload];
    },

    removeFromList: (state, action) => {
      state.list = state.list.filter((item) => item !== action.payload);
    },

    updateTimestamp: (state, action) => {
      state.timestamp = action.payload;
    },

    resetFavouritesList: (state, action) => {
      state.list = [];
      state.timestamp = null;
    },
  },

  extraReducers:
    (builder) => {
      builder
        /**  */
        .addCase(saveFavourites.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.isLoading = true;
        })
        .addCase(saveFavourites.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = null;
        })
        .addCase(saveFavourites.rejected, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = action.payload;
        })


        /**  */
        .addCase(getFavourites.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.isLoading = true;
        })
        .addCase(getFavourites.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = null;
          // if (action.payload.length > 0) {}
          state.list = action.payload;
        })
        .addCase(getFavourites.rejected, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = action.payload;
        })


    },
});


export const { addToList, removeFromList, updateTimestamp, resetFavouritesList } = favouritesSlice.actions;
