import React, { useState, useRef, useCallback, useEffect } from 'react'


// To get the previous props or state
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}


export const findVal=(object, key)=>{
        
  let value;
  Object.keys(object).some(function(k) {
      if (k === key) {
          value = object[k];
          return true;
      }
      if (object[k] && typeof object[k] === 'object') {
          value = findVal(object[k], key);
          return value !== undefined;
      }
  });
    return value;
}

export const useStateCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // mutable ref to store current callback
  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store passed callback to ref
    setState(state);
  }, []);

  useEffect(() => {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);
  return [state, setStateCallback];
}

export function getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  // New lines to be added
  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, crop.width, crop.height);
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  // As Base64 string
  const base64Image = canvas.toDataURL("image/jpeg");
  return base64Image;

  // As a blob
  // return new Promise((resolve, reject) => {
  //   canvas.toBlob(
  //     (blob) => {
  //       blob.name = fileName;
  //       resolve(blob);
  //     },
  //     "image/jpeg",
  //     1
  //   );
  // });
}

export async function canvasPreview(image, crop, scale = 1) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image?.naturalWidth / image?.width;
  const scaleY = image?.naturalHeight / image?.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window?.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  // const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  // ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
  // const data = ctx.getImageData(crop.x, crop.y, crop.width, crop.height);

  // set canvas width to final desired crop size - this will clear existing context
  // canvas.width = pixelCrop.width;
  // canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  // ctx.putImageData(data, 0, 0);

  // As Base64 string
  return canvas.toDataURL('image/jpeg');

  // As a blob
  // return new Promise((resolve, reject) => {
  //   canvas.toBlob((blob) => {
  //     var blobUrl = URL.createObjectURL(blob);

  //     var link = document.createElement('a');
  //     link.href = blobUrl;
  //     link.download = 'springverify.jpg';

  //     link.click();
  //   }, 'image/jpeg');
  // });
}


export const toDataUrl = (url, callback) => {
  console.log('from toDataUrl')
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
          console.log('FROM toURL : ',reader.result)
          callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};



