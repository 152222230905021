import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import _ from 'lodash'
import { MdDelete } from "react-icons/md";
import objectPath from "object-path";
import Dropzone from "react-dropzone";
import Modal from "react-bootstrap/Modal";
import { certificationsEditSchema } from "src/helper/yup-schema";
import { getProductTemplateList } from "src/redux/products/productThunk";
import { deleteCertificateLogoImageAction, getCertificateDataByIdAction, uploadCertificateImageURL } from "src/redux/certifications/certificationsThunk";
import { certificateAllErrorsEditFormObject, changeCertificateEditModeAction, currentCertificateFormObject } from "src/redux/certifications/certificationsSlice";
import emptyimage from 'src/assets/images/emptyimage.png'
import { Spinner } from "src/components/Spinner";
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel";
import { InputSelectDropdown } from "src/components/inputComponents/InputSelectDropdown";
import { StSquareButton } from 'src/components/StSquareButton';
import { StCancelSquareButton } from 'src/components/StCancelSquareButton';
import { StCloseButton } from 'src/components/StCloseButton';


const ViewCertificate = () => {
  /** redux store useDispatch & useSelector ------------------------------ **/
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const _state = useSelector((state) => state.certifications, shallowEqual);

  /** hooks useState ----------------------------------------------------- **/
  const [touchedState, setTouchedState] = useState(false)
  const [getByIdLoader, setGetByIdLoader] = useState(true)
  const [productTemplateList, setProductTemplateList] = useState([])
  const [ImageUrl, setImageUrl] = useState({
    logo: { isDirty: false, list: [], readyToUpload: null, baseForm: null },
  });
  const [delModelState, setDelModelState] = useState({ modelTitle: '', modelText: '', modelDelUrl: '' })
  const [delShow, setDelShow] = useState(false)

  /** Variable declaration ----------------------------------------------------- **/
  let { id } = useParams();
  const viewData = _state.currentCertificateObj;
  let AllErrors = _state.certificateErrorsformEditObject;
  const MainImageList = ['logo',];

  /** hooks useEffect----------------------------------------------------- **/
  useEffect(() => {
    getProductTemplateListData();
    getCertificateByIdData();
  }, []);

  /** Useeffect used to the get the imageUrl from the viewData and set it on imageUrl */
  useEffect(() => {
    const newDocUrl = { ...ImageUrl }
    if (_.get(viewData, 'image_url')) {
      newDocUrl.logo.list[0] = _.get(viewData, 'image_url')
    } else {
      const newDocUrl = { ...ImageUrl }
      newDocUrl.logo.list = []
    }
    setImageUrl(newDocUrl)
    return () => {
    }
  }, [viewData])

  /** Handler functions ------------------------------------------------- */

  /** Function retrieve certificate data by id ------------------------------- */
  const getCertificateByIdData = async () => {
    let result = await dispatch(getCertificateDataByIdAction(id));
    setGetByIdLoader(false)
    await dispatch(currentCertificateFormObject(result.payload));
    await dispatch(certificateAllErrorsEditFormObject([]));
    dispatch(changeCertificateEditModeAction(false));
    return () => {
      dispatch(changeCertificateEditModeAction(false));
      dispatch(currentCertificateFormObject({}));

    };
  }

  /** Function retrieve all product template list -----------------------  */
  const getProductTemplateListData = async () => {
    try {
      const templateList = await dispatch(getProductTemplateList()).unwrap();
      const productTemplateTypeOptions = templateList.map((o, index) => {
        const item = { key: index, text: o.label, value: o.name };
        return item
      });
      setProductTemplateList(productTemplateTypeOptions)
    } catch (error) {
      console.log(error)

    }
  }

  /** Function execute when select on any of the image----------------------------- */
  const onImageDrop = (acceptedFiles, type) => {
    let file = acceptedFiles[0];

    // Get the file extension from the original file name
    const originalFileName = file.name;
    const fileExtension = originalFileName.split('.').pop().toLowerCase();

    // Generate a new file name using the current timestamp
    const newFileName = `${new Date().getTime()}.${fileExtension}`;

    // Create a new File object with the updated name
    const renamedFile = new File([file], newFileName, { type: file.type });

    const reader = new FileReader()
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.readAsDataURL(file)
    reader.onload = () => {
      let _docUrl = ImageUrl;
      objectPath.set(_docUrl, `${type}.baseForm`, reader.result);
      objectPath.set(_docUrl, `${type}.readyToUpload`, renamedFile);
      objectPath.set(_docUrl, `${type}.list`, [reader.result]);
      objectPath.set(_docUrl, `${type}.isDirty`, false);
      setImageUrl({ ..._docUrl });
      uploadImage(type)
    }
  }

  /** Function execute to upload image-------------------------- */
  const uploadImage = async (type) => {
    const formData = new FormData();
    formData.append(type, objectPath.get(ImageUrl, `${type}.readyToUpload`));
    try {
      let result = await dispatch(uploadCertificateImageURL({ body: formData, type: type, id: viewData?.id }));
      dispatch(currentCertificateFormObject({ ...viewData, ["image_url"]: result?.payload[0] }));

    } catch (error) {
      console.log(error)
    }
  }

  const deleteClicked = async (imageUrl) => {
    setDelModelState({ ...delModelState, modelDelUrl: imageUrl, modelTitle: t('Delete image'), modelText: t('Are you sure you want to delete the image?') })
    setDelShow(true)
  }

  const handleDelModelClose = () => {
    setDelShow(false)
  }

  /** Function to delete the certificate logo-------------------------------- */
  const handleDelModelDelete = async () => {
    const filename = viewData?.image_url || "";
    try {
      let result = await dispatch(deleteCertificateLogoImageAction({ id: viewData?.id, fileName: filename }))
      if (result?.payload?.message) {
        await dispatch(currentCertificateFormObject({ ...viewData, ["image_url"]: "" }))
      }
      setDelShow(false)
    } catch (error) {
      console.log(error)
    }
  }

  /** Function to save input field values--------------------------- */
  const handleFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name !== "template") {
      validator({ name: name, value: value });
    }
    dispatch(currentCertificateFormObject({ ...viewData, [name]: value }));
    setTouchedState(true)
  };

  /** Function to validate the input field values----------------------- */
  const validator = ({ name, value }) => {
    const existingErrorIndex = AllErrors.findIndex(
      (error) => error.path === name
    );
    let newErrors = [...AllErrors];
    yup
      .reach(certificationsEditSchema, name)
      .validate(value)
      .then((valid) => {
        if (newErrors.length > 0) {
          newErrors = newErrors.filter((error) => error.path !== name);
          dispatch(certificateAllErrorsEditFormObject(newErrors));
        }
      })
      .catch((err) => {
        if (existingErrorIndex !== -1) {
          newErrors[existingErrorIndex] = {
            path: name,
            message: err.message,
          };
        } else {
          newErrors.push({ path: name, message: err.message });
        }
        dispatch(certificateAllErrorsEditFormObject(newErrors));
      });
  }

  /** Funtion to create error object based on yup validation ------------------------- */
  const errorShown = (AllErrors) => {
    const errorObject = {}
    AllErrors &&
      AllErrors[0] &&
      AllErrors.forEach((obj, index) => {
        errorObject[obj.path] = obj.message;
      });
    return errorObject
  };

  const errorObject = errorShown(AllErrors);

  return (
    <>
      {getByIdLoader ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          <div className="manufacturersidebar">
            {MainImageList.map((item, index) => (
              <div key={index}>
                <div key={index} className="row justify-content-center mt-4" style={{ display: 'grid', alignItems: 'center' }}>
                  <Dropzone disabled={_state.isCertificateEditMode ? false : true} onDrop={acceptedFiles => onImageDrop(acceptedFiles, item)}
                    accept="image/*" // Specify accepted file types
                    multiple={false} // Allow only one file
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({
                        className: 'dropzone',
                        onClick: event => { event.target.id !== "image-upload-id" && event.stopPropagation() }
                      })}>
                        <input {...getInputProps()} />
                        <div className="flip-card">
                          <div className="flip-card-inner">
                            <div className="flip-card-front">
                              <img style={{ objectFit: 'contain' }} alt="alt" src={!!objectPath.get(ImageUrl, `${item}.list.0`) ? objectPath.get(ImageUrl, `${item}.list.0`) : emptyimage} />
                            </div>
                            <div className="flip-card-back">
                              <img style={{ objectFit: 'contain' }} alt="alt" id="image-upload-id" src={!!objectPath.get(ImageUrl, `${item}.list.0`) ? objectPath.get(ImageUrl, `${item}.list.0`) : emptyimage} />
                              {!!viewData?.image_url && _state.isCertificateEditMode &&
                                <div>
                                  <div className="media-card triangle"></div>
                                  <MdDelete onClick={() => deleteClicked(objectPath.get(ImageUrl, `${item}.list.0`), 'image')} size={22} className="media-card delete-icon" />
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            ))}
          </div>

          <div className="row col-12 mt-3 contentManagementForm">
            <InputTextWithLabel
              label={t("Certificate Name")}
              tooltipText={t("Certificate Name")}
              onChange={handleFormChange}
              name={"name"}
              value={viewData.name}
              type="text"
              placeholder={t("Certificate Name")}
              touched={touchedState}
              errorMessage={!!errorObject && errorObject.name ? errorObject.name : ""}
              readOnly={_state.isCertificateEditMode ? false : true}
            />
            <InputTextWithLabel
              label={t("Certificate URL")}
              tooltipText={t("Certificate URL")}
              onChange={handleFormChange}
              name={"url"}
              value={viewData.url}
              type="text"
              placeholder={t("Certificate URL")}
              touched={touchedState}
              errorMessage={!!errorObject && errorObject.url ? errorObject.url : ""}
              readOnly={_state.isCertificateEditMode ? false : true}
            />
            <InputSelectDropdown
              label={t("Template Associated")}
              placeholder={t('Template Associated')}
              name="template"
              value={viewData.template}
              options={productTemplateList}
              onChange={handleFormChange}
              touched={touchedState}
              readOnly={_state.isCertificateEditMode ? false : true}
            />
          </div>

          <Modal size="md" centered={true} backdrop="static" show={delShow} onHide={handleDelModelClose} >
            <Modal.Header >
              <div style={{ position: 'absolute', right: 10 }}>
                <StCloseButton text={t("Close")} onClick={() => handleDelModelClose()}></StCloseButton>
              </div>
            </Modal.Header>
            <Modal.Body style={{ fontWeight: 'normal', textAlign: 'center' }}>
              <div className="row m-0 justify-content-center">
                <span className="col-12 media-card model-text" >{delModelState.modelTitle}</span>
                <span className="col-12 media-card model-content mt-4" >{delModelState.modelText}</span>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'center', margin: 15 }}>
              <StSquareButton text={t("Delete")}
                loading={_state?.actionsLoading}
                onClick={() => handleDelModelDelete()}></StSquareButton>
              <StCancelSquareButton text={t("Cancel")} onClick={() => handleDelModelClose()}></StCancelSquareButton>
            </Modal.Footer>
          </Modal>

        </>
      )}
    </>
  );
};

export default ViewCertificate;
