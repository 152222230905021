import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeProdEditMode, changeFormObject, AllErrorsFormObject, formSaveValidationAction, toggleProductHistoryDrawerFlag, setFirstProductObjFromProductList } from "src/redux/products/productsSlice";
import { getProductByIdAction, deleteProduct, getAllSegmentList, addProduct, getProductHistoryModalShown, getProductsWithPage } from "src/redux/products/productThunk";
import { setAiImageAnalysisEmpty, } from "src/redux/scraping/scrapingSlice";
import { getAiImageAnalysisPrimary, getAiImageAnalysisFrontal, getAiImageAnalysisBackside, getAiImageAnalysisSquare, } from "src/redux/scraping/scrapingThunk";
import { setAiFilledInput } from "src/redux/aiData/aiDataSlice";
import { getAItranslation } from "src/redux/aiData/aiDataThunk";
import { clearAiTranslationData } from "src/redux/aiData/aiDataSlice";
import { getCompany } from 'src/redux/company/companyThunk';
import Modal from "react-bootstrap/Modal";
import { MdMenu, MdOutlineArrowCircleUp, MdOutlineModeEdit, MdInfoOutline, MdOutlineSave, MdOutlineClose, MdArrowBack } from "react-icons/md";
import { usePrevious, findVal } from "src/helper/CustomHook";
import { AppContext } from '../../AppContext';
import logo from 'src/assets/images/brand_icons/upids-pdm-logo.png'
import { BiTrash } from "react-icons/bi";
import { StMenu } from "src/components/StMenu";
import { IconButton } from "src/components/IconButton"
import { StSquareButton } from "src/components/StSquareButton";
import { StCancelSquareButton } from "src/components/StCancelSquareButton";
import { StCloseButton } from "src/components/StCloseButton";
import { EDIT } from "src/helper/constants/constants";
import { productUpdateSchema } from "src/helper/yup-schema";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { saveFavourites, getFavourites, } from "src/redux/favourites/favouritesThunk";
import { addToList, removeFromList, updateTimestamp, } from "src/redux/favourites/favouritesSlice";
import objectPath from "object-path";
import _ from 'lodash'
import { getValidationErrorObjectForYup, } from 'src/helper/validationYupHelper';
import ValidationErrorMessage from "src/components/ValidationErrorMessage";

const ProductProfileNavbar = () => {
  /** redux store useDispatch & useSelector ------------------------------ **/
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation('common');
  const { selectedProductSchema } = useSelector((state) => state.products);
  const _productsState = useSelector((state) => state.products, shallowEqual);
  const _companyState = useSelector((state) => state.company, shallowEqual);
  const _scrapingState = useSelector((state) => state.scraping, shallowEqual);
  const _translationState = useSelector((state) => state.aiTranslations, shallowEqual);
  const _mediaState = useSelector((state) => (state.media), shallowEqual);
  const _favouritesState = useSelector((state) => state.favourites, shallowEqual);
  const { deleteResponse } = _productsState;
  const prevPropsState = usePrevious({ deleteResponse });

  /** hooks useState ----------------------------------------------------- **/
  const [showDelPopup, setShowDelPopup] = useState(false);
  const [showTranslationModal, setShowTranslationModal] = useState(false);
  const [favouritesList, setFavouritesList] = useState(_favouritesState.list.length > 0 ? _favouritesState.list : []);
  const [lastModified, setLastModified] = useState(0);
  const [validationData, setValidationData] = useState({ showModal: false })

  /** Variable declaration ----------------------------------------------------- **/
  const context = useContext(AppContext);
  const fullUrl = window.location.href;
  let gtin = fullUrl.split("/").pop();
  const productHistory = JSON.parse(localStorage.getItem(`product_history_${context?.authState?.uId}`)) || [];
  const { firstProductObject, countOfPage } = location.state || {};
  const data = _productsState.formObject;


  /** hooks useEffect----------------------------------------------------- **/
  useEffect(() => {
    dispatch(getFavourites())
  }, []);

  useEffect(() => {
    if (prevPropsState) {
      if (prevPropsState.deleteResponse !== deleteResponse && deleteResponse
      ) {
        navigate("/products");
      }
    }
  }, [_productsState]);

  useEffect(() => {
    setFavouritesList(!!_favouritesState.list.length > 0 ? _favouritesState.list : []);
  }, [_favouritesState.list]);

  useEffect(() => {
    if (lastModified > 0) {
      dispatch(saveFavourites({ body: favouritesList, from: "productProfile" }));
    }
  }, [JSON.stringify(favouritesList)]);



  /** handler functions--------------------------------------------------- **/
  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  function validationModelHandler(isShowModel) {
    return setValidationData({ showModal: isShowModel })
  }

  const handleCreateClick = () => {
    navigate(`/addupidsproduct/${gtin}`);
  }

  const handleEditClick = () => {
    dispatch(changeProdEditMode(true));
  };

  let allErrors;
  const handleSaveProducctDataClick = async () => {
    try {
      const formObject = _productsState.formObject;
      await productUpdateSchema.validate(formObject, { abortEarly: false })
      setShowTranslationModal(false);

      /**
       * @modifyModel
       */
      const newData = JSON.parse(JSON.stringify(_productsState.productById));
      const ImageUrls = !!_mediaState.getAllProductImages ? _mediaState.getAllProductImages : null;
      if (ImageUrls) {
        objectPath.set(newData, "multimedia", ImageUrls);
      }

      const newProductFormData = {
        ...formObject,
        countryCode: context.authState.countryCode,
      }

      const productFlattenSchema = _productsState.selectedProductSchema.productFlattenSchema
      const { errorMessageObject, errorFieldList } = await getValidationErrorObjectForYup(productFlattenSchema, newProductFormData)

      if (!_.isEmpty(errorMessageObject) || !_.isEmpty(errorFieldList)) {
        /** show error message */
        dispatch(formSaveValidationAction({ errorMessageObject: errorMessageObject, errorFieldList: errorFieldList, }))
        setValidationData({
          showModal: true
        })
        return;
      } else {
        dispatch(formSaveValidationAction({ errorMessageObject: {}, errorFieldList: [], }))
      }

      let newProductFormDataUpdated = {
        ...newProductFormData,
        private: {
          ...newProductFormData?.private,
          modifiedBy: `${context?.authState?.email}${Math.floor(Math.random() * 900) + 100}`
        },
      };

      await dispatch(
        addProduct({ productData: newProductFormDataUpdated, formType: EDIT })
      ).unwrap();

      let newProductHistoryArray = productHistory?.map((item) => {
        if (item.id === newProductFormDataUpdated?.id) {
          // Replace the data for the specified ID
          return { ...item, ...newProductFormDataUpdated };
        }
        return item;
      });

      // Save the updated product history back to localStorage
      localStorage.setItem(`product_history_${context?.authState?.uId}`, JSON.stringify(newProductHistoryArray));

      dispatch(toggleProductHistoryDrawerFlag());
      dispatch(changeProdEditMode(false));
      dispatch(clearAiTranslationData([]));
      dispatch(AllErrorsFormObject([]));
    } catch (err) {
      allErrors = err.inner;
      dispatch(AllErrorsFormObject(allErrors));
    }
  };

  const handleDeleteClick = () => {
    setShowDelPopup(true);
  };

  const handleDelClose = () => {
    setShowDelPopup(false);
  }
  const handleTranslationClose = () => {
    setShowTranslationModal(false);
  }


  const handleDeleteChanges = () => {
    dispatch(deleteProduct((data.gtin == undefined || data.gtin == "") ? data._2an : data.gtin));

    const filteredArray = _.filter((productHistory) || [], (d) => (d?.id !== data?.id)) || []

    // Save the updated product history back to localStorage
    localStorage.setItem(`product_history_${context?.authState?.uId}`, JSON.stringify(filteredArray));
    dispatch(toggleProductHistoryDrawerFlag());
  };

  let alContains;
  let alFreeFrom;
  let alMayContain;
  let adContains;
  let adFreeFrom;
  let admayContain;

  try {
    alContains = !!findVal(data, "allergens") &&
      findVal(data, "allergens").list.contains;
    alFreeFrom = !!findVal(data, "allergens") &&
      findVal(data, "allergens").list.free_from;
    alMayContain = !!findVal(data, "allergens") &&
      findVal(data, "allergens").list.may_contain;
    adContains =
      !!findVal(data, "additives") &&
      findVal(data, "additives").contains;
    adFreeFrom =
      !!findVal(data, "additives") &&
      findVal(data, "additives").free_from;
    admayContain =
      !!findVal(data, "additives") &&
      findVal(data, "additives").may_contain;
  } catch (error) {
    console.log('ReactErrorBoundary......', error);
    // dispatch(productAction.setErrorBoundaryMessage())
  }



  const handleCancelClick = () => {
    dispatch(changeProdEditMode(false));
    dispatch(toggleProductHistoryDrawerFlag());
    dispatch(getProductByIdAction({ isLoader: true, gtin: gtin, isCancelBtn: true }));
    if (context.authState.level === 10) {
      dispatch(getCompany({}));
    }
    dispatch(clearAiTranslationData([]));
    dispatch(
      getAllSegmentList({
        collectionName: "categories",
        locale: "en",
      })
    );
  };

  const handleProductHistoryButtonClick = () => {
    dispatch(getProductHistoryModalShown(productHistory));

  }

  const handleAiImageAnalysisClick = () => {
    let keys = Object.keys(_productsState.productById.multimedia);
    keys.forEach((key, index) => {
      if (key !== "pdf" && key != "other") {
        apiCallsForImageAnalysis(key)
      } else {
        dispatch(setAiImageAnalysisEmpty())
      }
    });
  }

  const apiCallsForImageAnalysis = (key) => {
    switch (key) {
      case 'primary':
        dispatch(getAiImageAnalysisPrimary(_productsState.productById.multimedia[key][0]))
        break;
      case 'frontal':
        dispatch(getAiImageAnalysisFrontal(_productsState.productById.multimedia[key][0]))
        break;
      case 'backside':
        dispatch(getAiImageAnalysisBackside(_productsState.productById.multimedia[key][0]))
        break;
      case 'square':
        dispatch(getAiImageAnalysisSquare(_productsState.productById.multimedia[key][0]))
        break;
      default:
        dispatch(setAiImageAnalysisEmpty())
        break;
    }
  }
  const handleAITranslation = async () => {
    const toLanguages = _.get(_companyState, 'companyInfo.supportedLanguages', []).map(item => item.code)
    const textFields = []
    const aiFilledInputList = [];

    selectedProductSchema.productFlattenSchema.forEach((field) => {
      if (field.isMultilingual && (field.type === "text" || field.type === "textarea")) {
        toLanguages.some((languageCode) => {
          const value = _.get(_productsState.formObject, `${field.nameUpdated}.${languageCode}`, "");
          if (value) {
            const item = {
              fromLanguage: languageCode,
              id: field.nameUpdated,
              text: value,
            }
            textFields.push(item)
            return true
          }
        })
      }
    })

    try {
      const aiTranslationsResponse = await dispatch(getAItranslation({
        body: {
          toLanguages: toLanguages,
          texts: textFields,
        }
      })).unwrap();

      const newFormObject = JSON.parse(JSON.stringify(_productsState.formObject))


      aiTranslationsResponse.forEach((item) => {
        const path = `${item.id}.${item.language}`
        const value = _.get(_productsState.formObject, path, "");
        if (!value) {
          _.set(newFormObject, path, item.translation)
          aiFilledInputList.push(item)
        }
      })

      dispatch(setAiFilledInput(aiFilledInputList));
      dispatch(changeFormObject({ ...newFormObject }));
    } catch (error) {
      console.info("error", error)
    }

  }


  const handleFavouriteClick = (gtin) => {
    const index = favouritesList.indexOf(gtin);

    if (index > -1) {
      setFavouritesList(favouritesList.filter(item => item !== gtin));
      dispatch(removeFromList(gtin));
    } else {
      setFavouritesList(favouritesList.concat(gtin));
      dispatch(addToList(gtin));
    }
    // dispatch(saveFavourites({ body: favouritesList }));
    const timestamp = Math.floor(Date.now() / 1000);
    setLastModified(timestamp);
    dispatch(updateTimestamp(timestamp));
  };


  const productProfileNavbarCollection = [
    { menuItem: () => <IconButton text={t("Add to favourites")} icon={favouritesList.includes(gtin) ? AiFillStar : AiOutlineStar} onClick={() => handleFavouriteClick(gtin)} loading={_favouritesState.isLoading} /> },
    { menuItem: () => <IconButton text={t("Create Upids Product")} icon={MdOutlineArrowCircleUp} onClick={() => handleCreateClick()} /> },
    {
      menuItem: () => <IconButton text={t("Edit")} icon={MdOutlineModeEdit} onClick={() => handleEditClick()} />,
      submenu: [
        { menuItem: () => <IconButton text={t("Show Ai Translations")} icon={MdInfoOutline} onClick={() => handleAITranslation()} loading={_translationState.isLoadingTrans} /> },
        { menuItem: () => <IconButton text={t("Show Ai Image Analysis")} icon={MdInfoOutline} onClick={() => handleAiImageAnalysisClick()} loading={_scrapingState.loadingImageAnalysis} /> },
        // { menuItem: () => <IconButton text={t("Show Ai Suggestions")} icon={MdInfoOutline} onClick={() => handleAIButtonClick()} loading={_scrapingState.actionsLoading} /> },
        { menuItem: () => <IconButton text={t("Product History")} icon={MdInfoOutline} onClick={() => handleProductHistoryButtonClick()} /> },
        { menuItem: () => <IconButton text={t("Save Changes")} icon={MdOutlineSave} onClick={() => { _translationState.aiFilledInput.length > 0 ? setShowTranslationModal(true) : handleSaveProducctDataClick() }} /> },
        { menuItem: () => <IconButton text={t("Delete Product")} icon={BiTrash} onClick={() => handleDeleteClick()} /> },
        { menuItem: () => <IconButton text={t("Cancel")} icon={MdOutlineClose} onClick={() => handleCancelClick()} /> }
      ],
      submenuTitle: () => <IconButton text={t("Edit")} icon={MdOutlineModeEdit} onClick={() => handleEditClick()} />
    },
  ]

  const handleGoBackClick = async () => {
    navigate("/products")
    await dispatch(setFirstProductObjFromProductList({ ...firstProductObject, PageCount: countOfPage }))
  }

  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/dashboard">
            <img src={logo} alt="logo" />
          </Link>
          <Link
            className="navbar-brand brand-logo-mini d-inline-block d-lg-none"
            to="/"
          >
            <img
              src={require("../../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">
          <span
            style={{

              fontSize: 16,
              color: "#131E27",
              opacity: 1,
            }}
          >
            <MdArrowBack
              onClick={() => handleGoBackClick()}
              style={{
                width: 24,
                height: 24,
                backgroundColor: "transparent",
                opacity: 1,
                cursor: "pointer",
              }}
            />
            <Trans></Trans>
          </span>

          {!_productsState.isProdEditMode ? (
            <div className="navbar-nav navbar-nav-right d-none d-sm-flex">
              <IconButton
                text={t("Add To favourites")}
                icon={favouritesList.includes(gtin) ? AiFillStar : AiOutlineStar}
                onClick={() => handleFavouriteClick(gtin)}
                loading={_favouritesState.isLoading}
              />
              <IconButton
                text={t("Create Upids Product")}
                icon={MdOutlineArrowCircleUp}
                onClick={handleCreateClick}
              />
              <IconButton
                text={t("Edit")}
                icon={MdOutlineModeEdit}
                onClick={handleEditClick}
              />
            </div>
          ) : (
            <div className="navbar-nav navbar-nav-right d-none d-sm-flex">
              <IconButton
                text={t("Show Ai Translations")}
                icon={MdInfoOutline}
                loading={_translationState.isLoadingTrans}
                onClick={handleAITranslation}
              />
              <IconButton
                text={t("Show Ai Image Analysis")}
                icon={MdInfoOutline}
                loading={_scrapingState.loadingImageAnalysis}
                onClick={handleAiImageAnalysisClick}
              />
              {/* <IconButton
                text={t("Show Ai Suggestions")}
                icon={MdInfoOutline}
                loading={_scrapingState.actionsLoading}
                onClick={handleAIButtonClick}
              /> */}
              <IconButton
                text={t("Product History")}
                icon={MdInfoOutline}
                // loading={_scrapingState.actionsLoading}
                onClick={handleProductHistoryButtonClick}
              />
              <IconButton
                text={t("Save Changes")}
                icon={MdOutlineSave}
                loading={_productsState.isLoading}
                onClick={() => { _translationState.aiFilledInput.length > 0 ? setShowTranslationModal(true) : handleSaveProducctDataClick() }}
              />
              <IconButton
                text={t("Delete Product")}
                icon={BiTrash}
                onClick={handleDeleteClick}
              />
              <IconButton
                text={t("Cancel")}
                icon={MdOutlineClose}
                onClick={handleCancelClick}
              />
            </div>
          )}

          {/* responsive dropdown on mobile screen */}
          <ul className="navbar-nav navbar-nav-right productNavResponsiveDropdown">
            <StMenu menuArray={productProfileNavbarCollection}></StMenu>
          </ul>

          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
            <MdMenu color="#000000" size={24} />
          </button>
        </div>
      </nav>


      <ValidationErrorMessage
        validationData={validationData}
        validationModelHandler={validationModelHandler}
      />

      <Modal size="md" show={showDelPopup} onHide={handleDelClose} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleDelClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18, }}>
          <span>{t("Are you sure want to delete the product")}</span>
        </Modal.Body>
        <Modal.Footer>
          <StSquareButton text={t("Yes")} onClick={() => handleDeleteChanges()} loading={_productsState.isDeleteLoading}></StSquareButton>
          <StCancelSquareButton text={t("No")} onClick={() => handleDelClose()}></StCancelSquareButton>
        </Modal.Footer>
      </Modal>
      <Modal size="md" show={showTranslationModal} onHide={handleTranslationClose} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleTranslationClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18, }}>
          <span>{t("Some info is filled using Translation AI")}</span>
        </Modal.Body>
        <Modal.Footer>
          <StSquareButton text={t("Yes")} onClick={() => handleSaveProducctDataClick()} ></StSquareButton>
          <StCancelSquareButton text={t("No")} onClick={() => handleTranslationClose()}></StCancelSquareButton>
        </Modal.Footer>
      </Modal>


    </>

  );
};

export default ProductProfileNavbar;