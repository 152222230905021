import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FcOk } from "react-icons/fc";
import { addUser } from "src/redux/users/usersThunk";
import { usersAllErrorsFormObject } from "src/redux/users/usersSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { usePrevious } from "../../../helper/CustomHook";
import { userSchema } from "../../../helper/yup-schema";
import { MdArrowBack,MdAddCircle,MdMenu ,MdClose} from "react-icons/md";
import logo from '../../../assets/images/brand_icons/upids-pdm-logo.png'
import {IconButton} from "src/components/IconButton"
import { StMenu } from "src/components/StMenu";
import {Header} from "src/components/Header"
import { StSquareButton } from "src/components/StSquareButton";
import { StCloseButton } from "src/components/StCloseButton";

const UsersNavbar = () => {

  const usersNavbarCollection=[
    {menuItem:()=><IconButton text={t("Add User")} icon={MdAddCircle} onClick={()=>handleAddUserOnClick()}/>},
  ]

  let pathName = window.location.href;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const _state = useSelector((state) => state.users, shallowEqual);


  const response = _state.response;
  const prevPropsState = usePrevious({ response });

  let formObject = _state.formObject;

  useEffect(() => {
    if (prevPropsState) {
      if (
        prevPropsState.response !== response &&
        response.data.id &&
        response !== ""
      ) {
        setShow(true);
      }
    }
  }, [_state]);

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  let allErrors;

  const handleConfirmButtonChange = () => {
    userSchema
      .validate(formObject, { abortEarly: false })
      .then(function () {
        dispatch(usersAllErrorsFormObject([]));
      })
      .then(function () {
        let userData = createUserModel(formObject);
        dispatch(addUser({ body: userData }));
      })
      .catch(function (err, inner) {
        allErrors = err.inner;
        dispatch(usersAllErrorsFormObject(allErrors));
      });
  };

  const handleClose = () => {
    setShow(false);
    navigate("/users");
  }

  const handleClick = () => {
    setShow(false);
    navigate("/users");
  };

  const handleAddUserOnClick = () => {
    navigate('/users/add')  
  };

  const createUserModel = (formObject) => {
    return {
      email: formObject.email,
      foreName: formObject.foreName,
      sureName: formObject.sureName,
      phoneNumber: formObject.phoneNumber,
      timezone: formObject.offset,
      abbr: formObject.timezone,
      companyId: formObject.companyId,
      companyName: formObject.companyName,
      companies: formObject.companies,
      isAdmin: formObject.admin ? formObject.admin : false,
      password: formObject.newPassword,
      language: formObject.language,
      // userLevel: formObject.userLevel === 0 ? 1 : formObject.userLevel,
      sendInvitation: formObject.sendInvitation ? formObject.sendInvitation : false,
      userRole: formObject.userRole ? formObject.userRole : "Not-set",
    };
  };
  
  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/dashboard">
            <img src={logo} alt="logo" />
          </Link>
          <Link
            className="navbar-brand brand-logo-mini d-inline-block d-lg-none"
            to="/"
          >
            <img
              src={require("../../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">

          {!pathName.includes("/users/add") ? 
           <span> <Header text={t("Users")}/> </span> :
            <div>
              <span
                style={{
                  fontSize: 16,
                  color: "#131E27",
                  opacity: 1,
                }}
              >
                <MdArrowBack
                  onClick={() => navigate(-1)}
                  style={{
                    width: 24,
                    height: 24,
                    backgroundColor: "transparent",
                    opacity: 1,
                    cursor: "pointer",
                    marginLeft: -10,
                  }}
                />
                <Trans></Trans>
              </span>

              <span style={{marginLeft:20}}> <Header text={t("Add New User")}/> </span> </div>}

          <span className="navbar-nav navbar-nav-right">
            {!pathName.includes("/users/add") ?
              <span className="nav-item nav-profile d-none d-sm-flex">
                <IconButton text={t("Add User")} icon={MdAddCircle} onClick={() =>handleAddUserOnClick()} />
              </span> :
              <button
                style={{
                  width: 150,
                  height: 40,
                  backgroundColor: "#D1334C",
                  borderRadius: 20,
                  opacity: 1,
                  border: "1px solid",
                  color: "#FFFFFF",
                  textTransform: "uppercase",
                  fontSize: 12,
                  fontWeight: "bold",
                }}
                onClick={handleConfirmButtonChange}
              >
                {_state.isLoading ? (
                  <Spinner
                    style={{ width: 22, height: 22 }}
                    animation="border"
                    // size="sm"
                    variant="light"
                  />
                ) : (
                  t("Confirm")
                )}
              </button>
            }
          </span>

      {/* responsive dropdown on mobile screen */}
          <span className="navbar-nav navbar-nav-right productNavResponsiveDropdown">
            {!pathName.includes("/users/add") &&
              <StMenu menuArray={usersNavbarCollection}></StMenu>             
            }
          </span>

          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
             <MdMenu color="#000000" size={24} />
          </button>
        </div>
      </nav>

      <Modal size="md" show={show} onHide={handleClose} centered={true}>
        <Modal.Header >
        <div style={{position:'absolute',right:10}}>
               <StCloseButton text={t("Close")} onClick={()=>handleClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18, }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FcOk
              style={{
                width: 40,
                height: 40,
                textAlign: "center",
                alignSelf: "center",
              }}
            />
            <span>{t("New User successfully added")}</span>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Ok")} onClick={()=>handleClick()}></StSquareButton>
        </Modal.Footer>
      </Modal>
    </>

  );
};

export default UsersNavbar;
