import React, { forwardRef } from "react";
import { MdSort } from "react-icons/md";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { Navigate, useNavigate } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { useDispatch } from 'react-redux'
import { liveProductSearch } from "src/redux/products/productThunk";
import Pagination from "./Pagination";

import { MaterialTable } from "src/components/MaterialTable";
import { Spinner } from "src/components/Spinner";
import { useTranslation } from "react-i18next";

function ProductTable(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const { PageCount, setPageCount } = props.data;

  const tableIcons = {
    SortArrow: forwardRef((props, ref) => <MdSort {...props} ref={ref} />),
  };

  const getProductName = (o) => {
    if (!!o.productName) {
      if (!!o.productName.en && o.productName.en !== "") {
        return o.productName.en
      } else {
        return o.productName.fi
      }

    } else {
      return "NA";
    }
  }

  let allData = [];

  const handleViewClickEvent = (o) => {
    let identifier = !!o.gtin ? o.gtin : o._2an
   navigate(`/productProfile/${identifier}`,
      {state: { 
        productData: o,
        firstProductObject:props?.productList?.[0],
        countOfPage:PageCount
       },
    });
  };

  const createtableData = () => {
    if (!!props.productList) {
      allData = [];
      props.productList.map((o, index) => {
        let favouriteIcon;
        let identifier = !!o.gtin ? o.gtin : o._2an
        if (o.isFavourite) {
          favouriteIcon = <AiFillStar style={{ cursor: 'pointer', color: '#fcba03'}}  fontSize={18} fontWeight={20} onClick={(e) => props.favouritesClickHandle(identifier)} />;
        } else {
          favouriteIcon = <AiOutlineStar style={{ cursor: 'pointer', color: '#6c757d'}}  fontSize={18} fontWeight={20} onClick={(e) => props.favouritesClickHandle(identifier)} />;
        }
        allData.push({
          gtin: !!o.gtin ? o.gtin : o._2an,
          product_name: getProductName(o),
          action: <MdEdit key={index} style={{ cursor: 'pointer', color: 'grey' }} fontSize={18} onClick={(e) => handleViewClickEvent(o)} />,
          favourite: favouriteIcon
        });
      })
    }
  }

  createtableData();

  const nextPage = () => {
    setPageCount(PageCount + 1);

    if (props.searchText === "") {
      if (props.productList[props.productList.length - 1].gtin) {
        props.getProducts(
          "next",
          props.productList[props.productList.length - 1].gtin,
          "gtin"
        );
      } else {
        props.getProducts(
          "next",
          props.productList[props.productList.length - 1]._2an,
          "_2an"
        );
      }
    } else {
      setPageCount(PageCount + 1, (PageCount) => {
        dispatch(liveProductSearch({ searchText: props.searchText, pageNo: PageCount }));
      })
    }

  };

  const prevPage = () => {
    setPageCount(PageCount - 1);
    if (props.searchText === "") {
      if(props.productList[0].gtin){
        props.getProducts("previous", props.productList[0].gtin, "gtin");
      }else{
        props.getProducts("previous", props.productList[0]._2an, "_2an");
      }
    } else {
      setPageCount(PageCount - 1, (PageCount) => {
        dispatch(liveProductSearch({ searchText: props.searchText, pageNo: PageCount }));
      })
    }
  };

  return (
    <>
      {props.loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <MaterialTable
        columnNames={[
              { title: t("_Identifier"), field: "gtin" },
              { title: t("_Product Name"), field: "product_name" },
              { title: t("Action"), field: "action", sorting: false, headerStyle: { textAlign: 'center' }, cellStyle: { textAlign: 'center' } },
              { title: t("Favourite"), field: "favourite" },
            ]}
        tableData={allData}
      ></MaterialTable>
        
      )}
      <br></br>
      {!props.isFavourite &&
      <Pagination
        prevPage={prevPage}
        nextPage={nextPage}
        pageCount={PageCount}
        allProductsCount={props.productList.length}
      />}
    </>
  );
}

export default ProductTable;
