import React, { useEffect, useState } from "react";
import ManufacturersTable from "./manufacturersTable/ManufacturersTable";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCompany, getLiveCompanySearch } from 'src/redux/company/companyThunk';
import * as companyAction from "../../redux/company/companyActions";
import { useTranslation } from "react-i18next";
import { StSearchBox } from "src/components/StSearchBox";
import { Spinner } from "src/components/Spinner";

const Manufacturers = () => {
  const [searchText, setSearchText] = useState("");
  const [showViewAll, setShowViewAll] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (searchText === "") {
      dispatch(getCompany({}));
    } else {
      const timeOut = setTimeout(() => {
        clearTimeout(timeOut)
        dispatch(getLiveCompanySearch({ searchText: searchText, pageNo: 1 }));
      }, 1000)
      return () => clearTimeout(timeOut)
    }
  }, [searchText]);

  const getManufacturers = (_mode, _gtin) => {
    dispatch(getCompany({}));
  };

  const _state = useSelector((state) => state.company, shallowEqual);
  const isLoading = _state.isAllDataFetchedLoading;

  return (
    <>
      <div className="homepage-main-div ps-2">
        <div className="row col-12 homepage-search-input-div pt-3" style={{ paddingLeft: 0 }}>
          <div className="col-lg-6 col-md-6">
            <StSearchBox
              iconName={searchText === "" ? t("Search") : t("Close")}
              width={'100%'}
              placeholder={t("Search by Business Id or Name or Contact")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onClick={() => setSearchText("")}
            />
          </div>
        </div>

        <div style={{ marginTop: 76, position: 'fixed' }} className='st-divider divider-thin'></div>
        {_state.actionsLoading ?
          <div>
            <Spinner />
          </div> :
          <div className="homepage-main-subdiv">
            <ManufacturersTable
              getManufacturers={getManufacturers}
              manufacturersList={_state.companyList}
              loading={isLoading}
            />
          </div>}
      </div>
    </>
  );
};

export default Manufacturers;
