import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";
import * as yup from "yup";
import _ from 'lodash'
import appConfig from "src/helper/appConfig";
import { metadataEditSchema } from "src/helper/yup-schema";
import { getTaxonomyDataByIdAction } from "src/redux/products/productThunk";
import { changeTaxonomyEditModeAction, currentTaxonomyFormObject, taxonomyAllErrorsEditFormObject } from "src/redux/products/productsSlice";
import { Spinner } from "src/components/Spinner";
import { InputSelectDropdown } from "src/components/inputComponents/InputSelectDropdown";
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel";


const ViewMetadata = () => {
   /** redux store useDispatch & useSelector ------------------------------ **/
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const _state = useSelector((state) => state.products, shallowEqual);

  /** hooks useState ----------------------------------------------------- **/
  const [touchedState, setTouchedState] = useState(false)
  const [selectedDescriptionLanguage, setSelectedDescriptionLanguage] = useState("en")
  const [getByIdLoader, setGetByIdLoader] = useState(true)

   /** Variable declaration ----------------------------------------------------- **/
  let { id } = useParams();
  const viewData = _state.currentTaxonomyObj;
  let AllErrors = _state.taxonomyErrorsformEditObject;
  const languagesList = [
    { label: "English", value: "en" },
    { label: "Finnish", value: "fi" }
  ]

   /** hooks useEffect----------------------------------------------------- **/
  useEffect(() => {
    getTaxonomyByIdData();
  }, []);

  /** Handler functions ------------------------------------------------- */

  /** Function to retrieve taxonomy data by id -------------------------- */
  const getTaxonomyByIdData = async () => {
    let result = await dispatch(getTaxonomyDataByIdAction(id));
    setGetByIdLoader(false)
    await dispatch(currentTaxonomyFormObject(result.payload));
    await dispatch(taxonomyAllErrorsEditFormObject([]));
    dispatch(changeTaxonomyEditModeAction(false));
    return () => {
      dispatch(changeTaxonomyEditModeAction(false));
      dispatch(currentTaxonomyFormObject({}));

    };
  }

  /** Function to update the description language---------------------------- */
  const handleChangeProductLanguage = (item, activeIndex) => {
    setSelectedDescriptionLanguage(item.value)
  }

  /** Function to save input field values--------------------------- */
  const handleFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == "attributeName") {
      validator({ name: name, value: value });
    }
    if (name === "description") {
      const updatedDescription = {
        ...viewData.description,
        [selectedDescriptionLanguage]: value
      };
      dispatch(currentTaxonomyFormObject({ ...viewData, [name]: updatedDescription }));
    } else {
      dispatch(currentTaxonomyFormObject({ ...viewData, [name]: value }));
    }
    setTouchedState(true)
  };

  /** Function to validate the input field values----------------------- */
  const validator = ({ name, value }) => {
    const existingErrorIndex = AllErrors.findIndex(
      (error) => error.path === name
    );
    let newErrors = [...AllErrors];
    yup
      .reach(metadataEditSchema, name)
      .validate(value)
      .then((valid) => {
        if (newErrors.length > 0) {
          newErrors = newErrors.filter((error) => error.path !== name);
          dispatch(taxonomyAllErrorsEditFormObject(newErrors));
        }
      })
      .catch((err) => {
        if (existingErrorIndex !== -1) {
          newErrors[existingErrorIndex] = {
            path: name,
            message: err.message,
          };
        } else {
          newErrors.push({ path: name, message: err.message });
        }
        dispatch(taxonomyAllErrorsEditFormObject(newErrors));
      });
  }

   /** Funtion to create error object based on yup validation ------------------------- */
  const errorShown = (AllErrors) => {
    const errorObject = {}
    AllErrors &&
      AllErrors[0] &&
      AllErrors.forEach((obj, index) => {
        errorObject[obj.path] = obj.message;
      });
    return errorObject
  };

  const errorObject = errorShown(AllErrors);

  return (
    <>
      {getByIdLoader ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          <div className="row col-12 contentManagementForm">
            <InputTextWithLabel
              label={t("Taxonomy Item")}
              tooltipText={t("Taxonomy Item")}
              onChange={handleFormChange}
              name={"attributeName"}
              value={viewData.attributeName}
              type="text"
              readOnly={_state.isTaxonomyEditMode ? false : true}
              placeholder={t("Taxonomy Item")}
              touched={touchedState}
              errorMessage={!!errorObject && errorObject.attributeName ? errorObject.attributeName : ""}
            />
            <InputSelectDropdown
              label={t("Taxonomy Data Type")}
              placeholder={t('Taxonomy Data Type')}
              name="datatype"
              value={viewData.datatype}
              options={appConfig?.taxonomyDataTypes}
              onChange={handleFormChange}
              touched={touchedState}
              readOnly={_state.isTaxonomyEditMode ? false : true}
              errorMessage={!!errorObject && errorObject.datatype ? errorObject.datatype : ""}
            />
            <InputSelectDropdown
              label={t("Taxonomy Category")}
              placeholder={t('Taxonomy Category')}
              name="category"
              value={viewData.category}
              options={appConfig?.taxonomyCategoryTypes}
              onChange={handleFormChange}
              readOnly={_state.isTaxonomyEditMode ? false : true}
              touched={touchedState}
              errorMessage={!!errorObject && errorObject.category ? errorObject.category : ""}
            />
            <InputTextWithLabel
              label={t("Taxonomy Relation")}
              tooltipText={t("Taxonomy Relation")}
              onChange={handleFormChange}
              name={"relation"}
              value={viewData.relation}
              readOnly={_state.isTaxonomyEditMode ? false : true}
              type="text"
              placeholder={t("Taxonomy Relation")}
            />
            <InputTextWithLabel
              label={t("Taxonomy Linking")}
              tooltipText={t("Taxonomy Linking")}
              onChange={handleFormChange}
              name={"link"}
              value={viewData.link}
              readOnly={_state.isTaxonomyEditMode ? false : true}
              type="text"
              placeholder={t("Taxonomy Linking")}
            />
              <InputTextWithLabel
              label={t("Taxonomy Created Date")}
              tooltipText={t("Taxonomy Created Date")}
              name={"createdDate"}
              value={moment(viewData.createdDate).format("YYYY-MM-DD HH:mm:ss")}
              readOnly={true}
              type="text"
              placeholder={t("Taxonomy Created Date")}
            />
              <InputTextWithLabel
              label={t("Taxonomy Modified Date")}
              tooltipText={t("Taxonomy Modified Date")}
              name={"modifiedDate"}
              value={moment(viewData.modifiedDate).format("YYYY-MM-DD HH:mm:ss")}
              readOnly={true}
              type="text"
              placeholder={t("Taxonomy Modified Date")}
            />
          </div>
          <div style={{ marginTop: -35 }}>
            <div style={{ margin: "10px" }} className="d-flex product-profile-navbar align-items-center border-0" >
              <div className='fw-bold me-3'>
                Language
              </div>
              <div id="navbarProductProfile">
                <ul className="nav nav-pills">
                  {
                    languagesList && languagesList[0] && languagesList.map((item, index) => (
                      <React.Fragment key={index}>
                        <li className="nav-item">
                          <a className="nav-link fw-normal" role="button"
                            data-tabselect={item.value == selectedDescriptionLanguage}
                            onClick={() => handleChangeProductLanguage(item, index)}
                          >
                            {item.label.split(/[;,]/)[0].trim()}
                          </a>
                        </li>
                      </React.Fragment>
                    ))}
                </ul>
              </div>
            </div>
            <InputTextWithLabel
              label={t("Taxonomy Description")}
              tooltipText={t("Taxonomy Description")}
              onChange={handleFormChange}
              name={"description"}
              value={viewData?.description?.[selectedDescriptionLanguage]}
              type="text"
              readOnly={_state.isTaxonomyEditMode ? false : true}
              placeholder={t("Taxonomy Description")}
              touched={touchedState}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ViewMetadata;
