import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AllErrorsEditFormObject, changeCompanyEditMode, currentCompanyFormObject, clearEcomAllData } from "src/redux/company/companySlice";
import { getCompanyDetails, addEcomPoint, getEcomPoint, deleteEcomPoint } from "src/redux/company/companyThunk";
import * as companyActions from "src/redux/company/companyActions";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CompanySideBar from "../../manufacturers/Components/CompanySideBar";
import { MdAddCircleOutline, MdClose } from 'react-icons/md';
import { IoAddCircleOutline } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Dropdown } from "semantic-ui-react";
import countryCodes from "src/helper/countryCodes";
import { LanguageCode } from 'src/helper/MetadataCollection';

import { Spinner } from "src/components/Spinner";
import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from "src/components/StSquareButton";
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel";
import { IconButton } from 'src/components/IconButton';
import { StCancelSquareButton } from 'src/components/StCancelSquareButton'
import { InputSelectDropdown } from "src/components/inputComponents/InputSelectDropdown";
import { Badges } from 'src/components/Badges'
import { UrlCard } from "src/components/UrlCard";
import { BusinessIdCard } from "src/components/BusinessIdCard";
import _ from "lodash";
import objectPath from "object-path";
import { showPaymentModalAction } from "src/redux/account/accountSlice";

const ecommerceIntegrationTypes = [
  { key: 1, text: 'woocommerce', value: 'woocommerce' },
  { key: 1, text: 'shopify', value: 'shopify' },
  { key: 1, text: 'amazon', value: 'amazon' },
  { key: 1, text: 'ecwid', value: 'ecwid' },
  { key: 1, text: 'magento', value: 'magento' },
]

const amazonMarketPlaceRegions = [
  { key: 1, text: 'USA', value: 'USA' },
]


function ManufacturerProfile() {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const companyState = useSelector((state) => state.company, shallowEqual);
  const { companyInfo, isLoading, isCompanySearchLoading } = companyState
  const allViewData = companyState.companyList;
  let viewData = JSON.parse(JSON.stringify(companyState.currentCompanyObj))
  let AllErrors = companyState.ErrorsformEditObject;

  const [businessIdModelShow, setBusinessIdModelShow] = useState(false);
  const [ecomModelShow, setEcomModelShow] = useState(false);
  const [show, setShow] = useState(false);
  const [recipientEmail, setRecipientEmail] = useState()
  const [recipientEmailsShow, setRecipientEmailsShow] = useState(false);
  const [showApikey, setShowApikey] = useState(false);
  const [modelList, setModelList] = useState([]);

  let selectCountryCodeOption = [];

  const [ecomList, setEcomList] = useState([]);
  const [ecomState, setEcomState] = useState({
    ecomUrl: "",
    ecomConsumerKey: "",
    ecomConsumerSecret: "",
    storeName: "",
    apiKey: "",
    apiSecretKey: "",
    accessToken: "",
    url: "https://www.amazon.com",
    sellerId: "",
    marketPlaceRegion: "",
    marketPlaceId: "",
    ecwidUrl: "https://app.ecwid.com",
    secretToken: "",
    storeId: "",
    magentoUrl: "",
    consumerKey: "",
    consumerSecret: "",
    accessTokenSecret: ""
  });

  const [state, setState] = useState({
    businessIdInputValue: "",
    businessIdTypeValue: "",
    integrationType: 'woocommerce'

  });

  useEffect(() => {
    dispatch(getCompanyDetails({ isCancelBtn: false }));

    return () => {
      dispatch(changeCompanyEditMode(false));
      dispatch(currentCompanyFormObject({}));
      dispatch(clearEcomAllData([]))
      setEcomList([])
    };
  }, []);

  useEffect(() => {
    dispatch(currentCompanyFormObject(companyInfo));
    companyInfo && dispatch(getEcomPoint(objectPath.get(_.find(companyInfo.businessIdentifiers, function (o) { return o.type === "VATID" }), "id", companyInfo.businessIdentifiers[0].id)));
    dispatch(AllErrorsEditFormObject([]));
  }, [allViewData]);

  useEffect(() => {
    if (!!companyState.ecomList && companyState.ecomList.length > 0) {
      // let allEcomPoints = ecomList.length > 0 ? JSON.parse(JSON.stringify(ecomList)) : [];
      let allEcomPoints = [];
      companyState.ecomList.map((o, index) => {
        if (o.integrationType == "woocommerce") {
          allEcomPoints.push({ "ecomUrl": o.url, "ecomConsumerKey": o.consumerKey, "ecomConsumerSecret": o.consumerSecret, "integrationType": o.integrationType, id: o.id });
        } else if (o.integrationType == "shopify") {
          allEcomPoints.push({ "storeName": o.storeName, "apiKey": o.apiKey, "apiSecretKey": o.apiSecretKey, "hostUrl": o.hostUrl, "accessToken": o.accessToken, "integrationType": o.integrationType, id: o.id });
        } else if (o.integrationType == "amazon") {
          allEcomPoints.push({ "url": o.url, "sellerid": o.sellerid, "marketplaceids": [{ "region": o.marketplaceids.region, "id": o.marketplaceids.id }], "integrationType": o.integrationType, id: o.id });
        } else if (o.integrationType == "ecwid") {
          allEcomPoints.push({ "ecwidUrl": o.url, "storeId": o.storeId, "secretToken": o.secret_token, "integrationType": o.integrationType, id: o.id });
        } else if (o.integrationType == "magento") {
          allEcomPoints.push({
            url: o.url,
            access_token: o.access_token,
            access_token_secret: o.access_token_secret,
            consumer_key: o.consumer_key,
            consumer_secret: o.consumer_secret,
            integrationType: o.integrationType,
            id: o.id,
          });
        }
        else { }
      })
      setEcomList(allEcomPoints);
    }
  }, [companyState.ecomList])


  const isVatidAlreadyExists = viewData && viewData && viewData.businessIdentifiers && viewData.businessIdentifiers.find(({ type }) => type === 'VATID') ? true : false;


  const businessIdTypeOptions = [
    {
      key: 1,
      text: 'GLN',
      value: 'GLN',
    },
    {
      key: 2,
      text: 'VATID',
      value: 'VATID',
      disabled: isVatidAlreadyExists
    },
    {
      key: 3,
      text: 'CFPID',
      value: 'CFPID',
    },
  ]


  const createDropdownDataForCountryCode = (countryCodes) => {
    selectCountryCodeOption = [];
    countryCodes.map((o, index) => {
      let allCodes = {
        key: index, text: `(${o.countryCode})  ${o.name}`, value: o.countryCode, content: (
          <div key={index}>
            <span style={{ display: "block", fontWeight: 'normal', fontSize: 14, }}>{o.name}</span>
            <span style={{ display: "block", fontWeight: 'normal', fontSize: 12, marginTop: 4, color: '#6489A0', }}>{o.countryCode}</span>
          </div>
        ),
      };
      selectCountryCodeOption.push(allCodes);
    });
  };
  createDropdownDataForCountryCode(countryCodes);




  const handleFormChange = (e) => {
    let latestViewData = viewData;
    latestViewData[e.target.name] = e.target.value;
    dispatch(
      currentCompanyFormObject({
        ...latestViewData,
      })
    );
  };

  let errorObject = {};

  const errorShown = (AllErrors) => {
    AllErrors &&
      AllErrors[0] &&
      AllErrors.map((obj, index) => {
        errorObject[obj.path] = obj.message;
      });
  };

  errorShown(AllErrors);

  const handleDeleteBusinessId = (index) => {
    viewData.businessIdentifiers.splice(index, 1);
    dispatch(
      currentCompanyFormObject({
        ...viewData
      })
    );
  }

  const handleBusinessIdChange = (e) => {
    // trim() to remove white spaces from string
    setState({ ...state, businessIdInputValue: e.target.value.trim() })

  }
  const handleEcomChange = (e, data) => {
    // trim() to remove white spaces from string
    if (!!data) {
      setEcomState({ ...ecomState, [data.name]: data.value.trim() })
    } else {
      setEcomState({ ...ecomState, [e.target.name]: e.target.value.trim() })
    }
  }

  const handleAddBusinessId = () => {
    viewData.businessIdentifiers.push({ "type": state.businessIdTypeValue, "id": state.businessIdInputValue });
    dispatch(
      currentCompanyFormObject({
        ...viewData
      })
    );
    handleBusinessIdModelClose();

  }

  const getCompanyLanguage = (viewData) => {
    const languageList = []
    viewData && viewData[0] && viewData.map((o, index) => {
      let language = { key: o.code, text: o.name, value: o.code }
      languageList.push(language)
    })
    return languageList
  }

  const getSupportedLanguage = getCompanyLanguage(viewData && viewData.supportedLanguages && viewData.supportedLanguages)

  const handleSupportLangueges = (e) => {
    let value = e.target.value
    if (!!value) {
      let currentLanguage = getSupportedLanguage.find((item) => item.value === value);
      dispatch(
        currentCompanyFormObject({
          ...viewData,
          language: currentLanguage.value
        })
      );
    }
  };
  const handleBusinessIdModelClose = () => {
    setBusinessIdModelShow(false);
    setState({ ...state, businessIdInputValue: "", businessIdTypeValue: "" })

  };

  const handleEcomIntegrationTypeChange = (e, { value }) => {
    setState({ ...state, integrationType: value })
  }




  const handleAddEcomPoints = () => {
    let allEcomPoints = ecomList.length > 0 ? JSON.parse(JSON.stringify(ecomList)) : [];
    let addEcomReqModel;
    if (state.integrationType == "woocommerce") {
      allEcomPoints.push({ "ecomUrl": ecomState.ecomUrl, "ecomConsumerKey": ecomState.ecomConsumerKey, "ecomConsumerSecret": ecomState.ecomConsumerSecret, "integrationType": state.integrationType });
      addEcomReqModel = {
        "url": ecomState.ecomUrl,
        "consumerKey": ecomState.ecomConsumerKey,
        "consumerSecret": ecomState.ecomConsumerSecret,
        "integrationType": state.integrationType,
        "businessIdentifiers": viewData.businessIdentifiers
      }
    } else if (state.integrationType == "shopify") {
      allEcomPoints.push({ "storeName": ecomState.storeName, "apiKey": ecomState.apiKey, "apiSecretKey": ecomState.apiSecretKey, "accessToken": ecomState.accessToken, "integrationType": state.integrationType, "hostUrl": "https://prod.api.2nd.gdn/v2", });
      addEcomReqModel = {
        "storeName": ecomState.storeName,
        "apiKey": ecomState.apiKey,
        "apiSecretKey": ecomState.apiSecretKey,
        "accessToken": ecomState.accessToken,
        "integrationType": state.integrationType,
        "hostUrl": "https://prod.api.2nd.gdn/v2",
        "businessIdentifiers": viewData.businessIdentifiers
      }
    }
    else if (state.integrationType == "amazon") {
      let marketplaceIdsArray = [{ "region": ecomState.marketPlaceRegion, "id": ecomState.marketPlaceId }]
      allEcomPoints.push({ "url": ecomState.url, "sellerid": ecomState.sellerId, "marketplaceids": marketplaceIdsArray, "integrationType": state.integrationType });
      addEcomReqModel = {
        "url": ecomState.url,
        "sellerid": ecomState.sellerId,
        "marketplaceids": [
          {
            "region": ecomState.marketPlaceRegion,
            "id": ecomState.marketPlaceId
          }
        ],
        "integrationType": state.integrationType,
        "businessIdentifiers": viewData.businessIdentifiers
      }
    } else if (state.integrationType == "ecwid") {
      allEcomPoints.push({ "ecwidUrl": ecomState.ecwidUrl, "storeId": ecomState.storeId, "secretToken": ecomState.secretToken, "integrationType": state.integrationType })
      addEcomReqModel = {
        "url": ecomState.ecwidUrl,
        "secret_token": ecomState.secretToken,
        "storeId": ecomState.storeId,
        "integrationType": state.integrationType,
        "businessIdentifiers": viewData.businessIdentifiers
      }
    } else if (state.integrationType == "magento") {
      allEcomPoints.push({
        "url": ecomState.magentoUrl,
        "consumer_key": ecomState.consumerKey,
        "consumer_secret": ecomState.consumerSecret,
        "access_token": ecomState.accessToken,
        "access_token_secret": ecomState.accessTokenSecret,
        "integrationType": state.integrationType,
      });
      addEcomReqModel = {
        "url": ecomState.magentoUrl,
        "consumer_key": ecomState.consumerKey,
        "consumer_secret": ecomState.consumerSecret,
        "access_token": ecomState.accessToken,
        "access_token_secret": ecomState.accessTokenSecret,
        "integrationType": state.integrationType,
        "businessIdentifiers": viewData.businessIdentifiers,
      };
    }
    else { }
    setEcomList(allEcomPoints);
    dispatch(addEcomPoint(addEcomReqModel));
    handleEcomModelClose();
    setEcomState({ ...ecomState, ecomUrl: "", ecomConsumerKey: "", ecomConsumerSecret: "", storeName: "", apiKey: "", apiSecretKey: "", accessToken: "", sellerId: "", marketPlaceRegion: "", marketPlaceId: "", })

  }

  const handleDeleteEcomPoints = (index, id) => {
    let allEcomPoints = ecomList.length > 0 ? JSON.parse(JSON.stringify(ecomList)) : [];
    allEcomPoints.length > 0 && allEcomPoints.splice(index, 1);
    setEcomList(allEcomPoints);
    !!id && dispatch(deleteEcomPoint({ businessid: objectPath.get(_.find(viewData.businessIdentifiers, function (o) { return o.type === "VATID" }), "id", viewData.businessIdentifiers[0].id), id: id }));
  }

  const handleMultiSelectValueChange = (e, { name, value }) => {
    let _list = [];
    value.forEach((o) => {
      _list.push(LanguageCode.find((lang) => lang.code === o));
    })
    setModelList(_list);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleAdd = () => {
    if (!!viewData.supportedLanguages)
      viewData.supportedLanguages = viewData.supportedLanguages.concat(modelList);
    else
      viewData = { ...viewData, supportedLanguages: modelList }
    dispatch(currentCompanyFormObject(viewData));
    setShow(false);
  };

  const delLanguage = (i) => {
    let _oldList = [...viewData.supportedLanguages];
    _oldList.splice(i, 1)
    viewData = { ...viewData, supportedLanguages: _oldList }
    dispatch(currentCompanyFormObject(viewData));
  }

  const getLangDropDownData = () => {
    return LanguageCode.map((o, index) => {
      return { key: index, text: o.name, value: o.code }
    })
  }

  const deleteRecipientEmail = (i) => {
    let _oldList = [...viewData.recipientEmails];
    _oldList.splice(i, 1)
    viewData = { ...viewData, recipientEmails: _oldList }
    dispatch(currentCompanyFormObject(viewData));
  }

  const generateAPIKeyHandle = () => {
    const key = generateUUID();
    viewData = { ...viewData, apiKey: key }
    dispatch(currentCompanyFormObject(viewData));
  };

  function generateUUID() {
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now();
    }

    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }

  const handleTopupBalance = () => {
    dispatch(showPaymentModalAction(true))
  }

  const handleRecipientEmailCLose = () => {
    setRecipientEmailsShow(false)
  };

  const handleRecipientEmailChange = (e) => {
    setRecipientEmail(e.target.value)
  }

  const handleAddRecipientEmail = () => {
    let allRecipientEmails = !!viewData.recipientEmails ? JSON.parse(JSON.stringify(viewData.recipientEmails)) : [];
    if (!!recipientEmail) {
      allRecipientEmails.push(recipientEmail)
    }
    viewData = { ...viewData, recipientEmails: allRecipientEmails }
    dispatch(currentCompanyFormObject(viewData));
    handleRecipientEmailCLose()
    setRecipientEmail()
  }

  const handleEcomModelClose = () => {
    setEcomModelShow(false);
    setEcomState({ ...ecomState, ecomUrl: "", ecomConsumerKey: "", ecomConsumerSecret: "", storeName: "", apiKey: "", apiSecretKey: "", accessToken: "", sellerId: "", marketPlaceRegion: "", marketPlaceId: "", })

  };

  const handleDropdownChange = (e, { value }) => {
    setState({ ...state, businessIdTypeValue: value })

  }

  const getEcomForm = (ecomType) => {
    switch (ecomType) {
      case 'woocommerce':
        return (
          <div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} value={ecomState.ecomUrl} onChange={handleEcomChange} placeholder={t("URL")} name='ecomUrl' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} value={ecomState.ecomConsumerKey} onChange={handleEcomChange} placeholder={t("Consumer Key")} name='ecomConsumerKey' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} value={ecomState.ecomConsumerSecret} onChange={handleEcomChange} placeholder={t("Consumer Secret")} name='ecomConsumerSecret' type="text"></InputTextWithLabel>
            </div>
          </div>
        )
        break;

      case 'shopify':
        return (
          <div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} value={ecomState.storeName} onChange={handleEcomChange} placeholder={t("Store Name")} name='storeName' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} value={ecomState.apiKey} onChange={handleEcomChange} placeholder={t("Api Key")} name='apiKey' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} value={ecomState.apiSecretKey} onChange={handleEcomChange} placeholder={t("Api Secret Key")} name='apiSecretKey' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} value={ecomState.accessToken} onChange={handleEcomChange} placeholder={t("Access Token")} name='accessToken' type="text"></InputTextWithLabel>
            </div>
          </div>
        )
        break;

      case 'amazon':
        return (
          <div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} disabled value={ecomState.url} onChange={handleEcomChange} placeholder="" name={t('Url')} type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} value={ecomState.sellerId} onChange={handleEcomChange} placeholder={t("Seller Id")} name='sellerId' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <Dropdown style={{ width: 400 }} placeholder={t("Market Place Region")} search selection name="marketPlaceRegion"
                value={ecomState.marketPlaceRegion} options={amazonMarketPlaceRegions} onChange={handleEcomChange} />
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} value={ecomState.marketPlaceId} onChange={handleEcomChange} placeholder={t("Market Place Id")} name='marketPlaceId' type="text"></InputTextWithLabel>
            </div>
          </div>
        )
        break;

      case 'ecwid':
        return (
          <div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} disabled value={ecomState.ecwidUrl} onChange={handleEcomChange} placeholder="" name='url' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} value={ecomState.secretToken} onChange={handleEcomChange} placeholder={t("Secret Token")} name='secretToken' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} value={ecomState.storeId} onChange={handleEcomChange} placeholder={t("Store Id")} name='storeId' type="text"></InputTextWithLabel>
            </div>
          </div>
        )
        break;

      case "magento":
        return (
          <div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.magentoUrl}
                onChange={handleEcomChange}
                placeholder={t("URL")}
                name="magentoUrl"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.consumerKey}
                onChange={handleEcomChange}
                placeholder={t("Consumer Key")}
                name="consumerKey"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.consumerSecret}
                onChange={handleEcomChange}
                placeholder={t("Consumer Secret")}
                name="consumerSecret"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.accessToken}
                onChange={handleEcomChange}
                placeholder={t("Access Token")}
                name="accessToken"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.accessTokenSecret}
                onChange={handleEcomChange}
                placeholder={t("Access Token Secret")}
                name="accessTokenSecret"
                type="text"
              ></InputTextWithLabel>
            </div>
          </div>
        );
        break;

      default:
        break;
    }
  }

  return (
    <>
      {isCompanySearchLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="row">
          <CompanySideBar />
          <div style={{ height: '100vh', marginTop: 40 }} className="col-xl-9 col-xxl-8 col-md-8">
            {viewData && (
              <form >
                <div className="row col-12">

                  <InputTextWithLabel
                    label={t('Company Name')}
                    tooltipText={t("Company Name")}
                    readOnly={true}
                    onChange={handleFormChange}
                    name={'name'}
                    value={viewData.name}
                    type="text"
                    placeholder={t("Company Name")}
                    errorMsg={!!errorObject && errorObject.name ? errorObject.name : ""}
                  />

                  <InputTextWithLabel
                    label={t('Company Address')}
                    tooltipText={t("Company Address")}
                    readOnly={companyState.isCompanyEditMode ? false : true}
                    onChange={handleFormChange}
                    name={'address'}
                    value={viewData.address}
                    type="text"
                    placeholder={t("Company Address")}
                    errorMsg={!!errorObject && errorObject.address ? errorObject.address : ""}
                  />

                  <InputSelectDropdown
                    label={t("Country Code")}
                    tooltipText={"Country Code"}
                    placeholder={t("Select country code")}
                    value={viewData.countryCode}
                    name="countryCode"
                    disabled={true}
                    options={selectCountryCodeOption}
                  />

                  <InputTextWithLabel
                    label={t('Contact Details')}
                    tooltipText={t("Contact Details")}
                    readOnly={companyState.isCompanyEditMode ? false : true}
                    onChange={handleFormChange}
                    name={'contact'}
                    value={viewData.contact}
                    type="text"
                    placeholder={t("Contact Details")}
                    errorMsg={!!errorObject && errorObject.contact ? errorObject.contact : ""}
                  />


                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>
                      {t("Api Key")}
                    </label>
                    <div>
                      <input
                        name="apiKey"
                        value={viewData.apiKey}
                        type={showApikey ? "text" : "password"}
                        placeholder={t("Api Key")}
                        readOnly={true}
                      />
                      <span className="product-profile eyeIcon">{showApikey ? <i onClick={() => setShowApikey(false)} className="fa-eye-slash icon" ></i> : <i onClick={() => setShowApikey(true)} className="far fa-eye icon" ></i>}</span>
                      {companyState.isCompanyEditMode &&
                        <Badges add onClick={() => generateAPIKeyHandle()}>
                          {t("Generate New Api Key")}
                        </Badges>
                      }
                    </div>
                  </div>

                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>
                      {t("Recipient Emails")}
                    </label>
                    {!!viewData.recipientEmails &&
                      viewData.recipientEmails.map((o, index) =>
                      (
                        <Badges key={index} remove={companyState.isCompanyEditMode} onClick={() => deleteRecipientEmail(index)}>
                          {o}
                        </Badges>
                      )
                      )}
                    {companyState.isCompanyEditMode &&
                      <Badges add onClick={() => setRecipientEmailsShow(true)}>
                        {t("Add Email")}
                      </Badges>
                    }
                  </div>
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>
                      {t("Supported Languages")}
                    </label>
                    {!!viewData.supportedLanguages &&
                      viewData.supportedLanguages.map((o, index) =>
                      (
                        <Badges key={index} remove={companyState.isCompanyEditMode} onClick={() => delLanguage(index)}>
                          {o.name}
                        </Badges>
                      )
                      )}
                    {companyState.isCompanyEditMode &&
                      <Badges add onClick={() => setShow(true)}>
                        {t("Add Language")}
                      </Badges>
                    }
                  </div>

                  <InputSelectDropdown
                    label={t("Communication language")}
                    tooltipText={"Communication language"}
                    placeholder={t("Select Language")}
                    value={viewData.language}
                    name="language"
                    options={getSupportedLanguage}
                    onChange={handleSupportLangueges}
                  />

                </div>

                <div className="row col-12">
                  <div className='form-group col-lg-8 col-md-8 col-sm-8 col-8'>
                    <label>{t("Ecommerce Integrations")}</label>
                    {!!errorObject && errorObject.ecomPoints && (
                      <div className="text-danger">{errorObject.ecomPoints}</div>
                    )}
                  </div>
                  {companyState.isCompanyEditMode ? <div className='col-lg-4 col-md-4 col-sm-4 col-4'>
                    <IconButton text={t("Add New")} icon={MdAddCircleOutline} onClick={() => setEcomModelShow(true)} />
                  </div> : ""}

                  <div className="col-12 mb-2">
                    <div className="row">
                      {!!ecomList ?
                        ecomList.map((o, index) => (

                          <div key={index} className="col-11 mb-2">
                            <UrlCard
                              url={o.integrationType == "woocommerce" ? o.ecomUrl : o.integrationType == "shopify" ? o.storeName : o.integrationType == "amazon" ? o.url : o.integrationType == "ecwid" ? o.ecwidUrl : o.integrationType == "magento" ? o.url : null}
                              onClick={() => console.log("ecom url clicked")}
                              isDelete={companyState.isCompanyEditMode}
                              onDeletClick={() => handleDeleteEcomPoints(index, o.id)}
                            ></UrlCard>
                          </div>
                        )) : ""
                      }
                    </div>
                  </div>
                </div>


                <div className="row col-12">
                  <div className='form-group col-lg-8 col-md-8 col-sm-8 col-8'>
                    <label>{t("_Business Id")}</label>
                    {!!errorObject && errorObject.businessIdentifiers && (
                      <div className="text-danger">{errorObject.businessIdentifiers}</div>
                    )}
                  </div>

                  {/* {companyState.isCompanyEditMode ?
                                        <div className='col-lg-4 col-md-4 col-sm-4 col-4' onClick={() => setBusinessIdModelShow(true)}>
                                            <IconButton text={t("Add New")} icon={MdAddCircleOutline} onClick={() => setBusinessIdModelShow(true)} />
                                        </div> : ""} */}
                </div>

                <div className="col-12">
                  <div className="row">
                    {viewData && !!viewData.businessIdentifiers ?
                      viewData.businessIdentifiers.map((o, index) => (
                        <div key={index} className="col-11 mb-2">
                          <BusinessIdCard
                            businessIdLabel={o.type}
                            businessId={o.id}
                            isDelete={false}
                          //  onClick={() => handleDeleteBusinessId(index)}
                          ></BusinessIdCard>
                        </div>
                      )) : ""
                    }
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      )}


      <Modal size="lg" centered={true} backdrop="static" show={recipientEmailsShow} onHide={handleRecipientEmailCLose} >
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleRecipientEmailCLose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal' }}>
          <div className="row col-12 m-0 justify-content-center form-group">
            <InputTextWithLabel isSmallScreen={true}
              label={t("Enter Recipient Email")}
              name={"recipientEmail"}
              type="text"
              placeholder={t("Enter Recipient Email")}
              value={recipientEmail}
              onChange={handleRecipientEmailChange}
            ></InputTextWithLabel>
          </div>

        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Add")} onClick={() => handleAddRecipientEmail()}></StSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal size="md" show={ecomModelShow} onHide={handleEcomModelClose} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleEcomModelClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal', textAlign: 'center' }}>
          <div className="row m-0 justify-content-center">
            <span className="col-12 product-profile model-text mb-10" >{t("Enter Ecommerce Integration Info")}</span>
            <div className="form-group col-12">
              <Dropdown style={{ marginTop: 20, width: 400, height: 40 }}
                placeholder={t("Select Ecommerce Integration Type")}
                selection
                value={state.integrationType}
                options={ecommerceIntegrationTypes}
                onChange={handleEcomIntegrationTypeChange}
                name="type"
              />
            </div>
            {/* ecom integrations add form */}
            {getEcomForm(state.integrationType)}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Add")} onClick={() => handleAddEcomPoints()}></StSquareButton>
          <StCancelSquareButton text={t("Cancel")} onClick={() => handleEcomModelClose()}></StCancelSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal size="md" show={businessIdModelShow} onHide={handleBusinessIdModelClose} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleBusinessIdModelClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal', textAlign: 'center' }}>
          <div className="row m-0 justify-content-center">
            <span className="col-12 product-profile model-text mb-10" >{t("Enter Business manufacture Id")}</span>
            <div className="form-group col-12">
              <Dropdown style={{ marginTop: 20, width: 400, height: 40 }}
                placeholder={t("Select Business Id Type")}
                selection
                value={state.businessIdTypeValue}
                options={businessIdTypeOptions}
                onChange={handleDropdownChange}
                name="type"
              />
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} value={state.businessIdInputValue} onChange={handleBusinessIdChange} placeholder={t("_Business Id")} name='businessIdInputValue' type="text"></InputTextWithLabel>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Add")} onClick={() => handleAddBusinessId()}></StSquareButton>
          <StCancelSquareButton text={t("Cancel")} onClick={() => handleBusinessIdModelClose()}></StCancelSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" centered={true} backdrop="static" show={show} onHide={handleClose} >
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal' }}>
          <div className="row col-12 m-0 justify-content-center">
            <span style={{ fontSize: 18, color: '#000000', fontWeight: 'bold' }}>{t("Select Supported Languages")}</span>
            <Dropdown style={{ margin: 15, fontSize: 14 }} onChange={handleMultiSelectValueChange} selection multiple search fluid options={getLangDropDownData()} placeholder={t('Choose an Option')} />
          </div>

        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Add")} onClick={() => handleAdd()}></StSquareButton>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default ManufacturerProfile;