import React from 'react'
import { useTranslation } from 'react-i18next';

function Pagination(props) {
    const { t } = useTranslation('common');
    const { prevPage, nextPage, pageCount, allProductsCount } = props;

    return (

        <div className="container">

            {pageCount === 1 ? <button className="pagination_button" style={{ width: 'auto', height: 20, backgroundColor: '#EFF8FD', border: 'none' }} onClick={() => prevPage()} disabled={true} >{t("Previous")}</button> :
                <button className="pagination_button" style={{ width: 'auto', height: 20, backgroundColor: '#EFF8FD', border: 'none' }} onClick={() => prevPage()}>{t("Previous")}</button>}

            {allProductsCount >= 20 ?
                <button className="pagination_button" style={{ width: 'auto', height: 20, backgroundColor: '#EFF8FD', marginLeft: 20, border: 'none' }} onClick={() => nextPage()}>{t("Next")}</button>
                : <button className="pagination_button" style={{ width: 'auto', height: 20, backgroundColor: '#EFF8FD', marginLeft: 20, border: 'none' }} onClick={() => nextPage()} disabled={true}>{t("Next")}</button>}

        </div>


    )
}

export default Pagination