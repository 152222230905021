import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import logoImage from "src/assets/images/brand_icons/upids-pdm-logo.png";
import supportIcon from "src/assets/images/login/Component_25.svg";
import contentLeftImage from "src/assets/images/login/Login_Diagram.svg";
import menuButton from "src/assets/images/login/Menu.svg";
import menuCloseButton from "src/assets/images/login/Menu_close.svg";
import SupportOutsideCheck from './component/SupportOutsideCheck'
import { useLocation, Route, Routes, useNavigate, } from 'react-router-dom';
import LoginForm from './sections/LoginForm';
import ResetPassword from './sections/ResetPassword';
import NewPassword from './sections/NewPassword';
import { clearAuthError } from "src/redux/auth/authSlice";
import { useTranslation } from 'react-i18next'
import Cookies from "universal-cookie";
import { TextWithLink } from "src/components/TextWithLink";
import Unsubscribe from "./sections/Unsubscribe";
import moment from "moment";


const LoginLayout = (props) => {
  const { t, i18n } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation()
  const pathname = location.pathname

  const cookies = new Cookies();
  const supportCardRef = useRef();
  const supporTextRef = useRef();
  const dispatch = useDispatch();
  const [showSupport, setShowSupport] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  SupportOutsideCheck(supportCardRef, supporTextRef, setShowSupport);

   // Get the current year
   const currentYear = moment().format('YYYY');

   const landingPageYearTranslatedString = t('All Right Reserved @ 2022 Second Thought Ltd').replace('2022', `2021-${currentYear}`);

  useEffect(() => {
    dispatch(clearAuthError())
    // for showing language dropdown selected value
    // document.getElementById(i18n.language).selected = true;
  }, [location.pathname]);

  const _authState = cookies.get('AUTH_STATE');
  if (_authState && !location.pathname.includes("unsubscribe")) {
    navigate('/dashboard')
    return null
  }

  const goToPrivacyPolicy = () => {
    window.open(
      'https://www.secondthought.fi/privacy-policy/',
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  const handleLangChange = (e) => {
    i18n.changeLanguage(e.target.value);
  }

  return (
    <div className="maincontainer">
      <div className="stheader">
        <img alt="alt" src={logoImage} className="logo" />
        <span className="headerSubtitle" > {t('Product Traceability Management')}</span>

        <div className="support-container">
          <img alt="alt" src={supportIcon} className="support-icon" />
          <span ref={supporTextRef} onClick={() => setShowSupport(!showSupport)} className="support-text cursor"><TextWithLink text={t('Need Support ?')} color="white" /></span>
        </div>
        <div className="language-dropdown mt-3 ps-0">
          <select value={i18n.language} onChange={handleLangChange} className="form-select" aria-label="Default select example">
            <option id="en" value="en">English</option>
            <option id="fi" value="fi">Finnish</option>
          </select>
        </div>

        {!showMenu ? <img alt="alt" onClick={() => setShowMenu(!showMenu)} src={menuButton} className="menu-button" /> :
          <img alt="alt" onClick={() => setShowMenu(!showMenu)} src={menuCloseButton} className="menu-button" />}
      </div>
      <div ref={supportCardRef} className={"support-content ui card" + (!showSupport ? " support-content-fadeOut" : " support-content-fadeIn")}>
        <p className="title">{t('Happy to Help')}</p>
        <div style={{ marginTop: 20 }}>
          <p className="subtitle">{t('EMAIL US')}</p>
          <p className="text">support@secondthought.fi</p>
        </div>
        <div style={{ marginTop: 20 }}>
          <p className="subtitle">{t('Call Us')}</p>
          <p className="text">+358 45 7832 7251</p>
        </div>
      </div>
      <div className="content">
        <div className="center-content">
          <div className="content-left">
            <div className={"menu-card-content" + (showMenu ? " menu-card-content-slide-down" : " menu-card-content-slide-up")}>
              <p className="menu-title">{t('AkkadAI is Unique Product Identification Service stores rich data about product lifecycle')}</p>
              <div className="divider"></div>
              <div className="menu-content">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <img alt="alt" src={supportIcon} className="support-logo" />
                  <span style={{ marginLeft: 16, fontWeight: 'bold', alignSelf: 'center' }}><TextWithLink text={t('Need Support ?')} color="dark" /></span>
                </div>

                <div style={{ marginTop: 23 }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}><p className="menu-subtitle">{t('Email Us')}</p><p className="menu-text">support@secondthought.fi</p></div>
                  <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}><p className="menu-subtitle" >{t('Call Us')}</p><p className="menu-text">+358 45 7832 7251</p></div>
                </div>
              </div>

              <div className="divider"></div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p style={{ fontWeight: 'bold', fontSize: 14, color: '#182631', textDecorationLine: 'underline' }}>{t('Privacy Policy')}</p>
                {/* <p style={{ fontWeight: 'bold', fontSize: 14, color: '#182631', textDecorationLine: 'underline', textAlign: 'right', marginLeft: 50 }}>{t('Terms of Use')}</p>
                <p style={{ fontWeight: 'bold', fontSize: 14, color: '#182631', textDecorationLine: 'underline', textAlign: 'right', marginLeft: 50 }} 
                >API</p> */}
              </div>
              <p className="menu-footer mt-1">{landingPageYearTranslatedString}</p>
            </div>
            <span className="headerSubtitle-mobile " >{t('Product Traceability Management')}</span>
            <p className="content-header-mobile">
              {t('Trace the product lifecycles, at each single step at atomic level')}
            </p>
            <img alt="alt" src={contentLeftImage} className="content-image" />

          </div>
          <div className="content-right">
            {
              pathname.includes("resetpassword") ? <ResetPassword />
                :
                pathname.includes("newpassword") ? <NewPassword />
                  :
                  pathname.includes("unsubscribe") ? <Unsubscribe />
                    :
                    pathname.includes("signup") ? <LoginForm />
                      :
                      <LoginForm />
            }
          </div>
        </div>
        <div className="loginfooter">
          <div>
            <p >{landingPageYearTranslatedString}</p>
            {/* <p style={{ marginLeft: 30 }}>{t('Terms of Use')}</p> */}
          </div>
          <div>
            <p style={{ marginLeft: 26, cursor: 'pointer' }} onClick={() => goToPrivacyPolicy()}>{t('Privacy Policy')}</p>
            {/* <p style={{ marginLeft: 26, cursor: 'pointer' }} 
            >API</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
