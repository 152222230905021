import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Badges } from 'src/components/Badges';
import Modal from "react-bootstrap/Modal";
import { InputTextWithLabel } from 'src/components/inputComponents/InputTextWithLabel';
import { changeFormObject } from 'src/redux/products/productsSlice';
import { StSquareButton } from 'src/components/StSquareButton';
import { StCloseButton } from 'src/components/StCloseButton';
import { IconButton } from 'src/components/IconButton';
import { Spinner } from 'react-bootstrap';
import { EcomCard } from 'src/components/EcomCard';
import { StCancelSquareButton } from 'src/components/StCancelSquareButton';
import { Dropdown } from 'semantic-ui-react';
import { MdAddCircleOutline, MdClose } from 'react-icons/md';
import _ from "lodash";
import objectPath from "object-path";
import { AppContext } from 'src/pages/AppContext';
import * as companyAction from '../../redux/company/companyActions'


export const EcommerceComponent = () => {
  const { t } = useTranslation('common');
  const context = useContext(AppContext);
  const dispatch = useDispatch();



  const _product = useSelector((state) => (state.products.formObject), shallowEqual);
  const _productState = useSelector((state) => (state.products), shallowEqual);
  const _state = useSelector((state) => (state.company), shallowEqual);
  const { companyInfo } = _state;

  const [urlModelShow, setUrlModelShow] = useState(false);
  const [urlModelEditShow, setUrlModelEditShow] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [selectEcomData, setSelectEcomData] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [integrationPointObject, setIntegrationPointObject] = useState({})
  const [ecomIndexToEdit, setEcomIndexToEdit] = useState()
  const [integrationType, setIntegrationType] = useState('')
  const [integrationLanguage, setIntegrationLanguage] = useState('');
  const supportedLanguege = [];


  useEffect(() => {
    let _selectData = [];
    _state.ecomList.map((o, index) => {
      if (o.integrationType == "woocommerce") {
        _selectData.push({ key: index, text: o.url, value: o.id, disabled: isEcomPointPresent(o.id) })
      }
      else if (o.integrationType == "shopify") {
        _selectData.push({ key: index, text: o.storeName, value: o.id, disabled: isEcomPointPresent(o.id) })
      }
      else if (o.integrationType == "amazon") {
        _selectData.push({ key: index, text: o.url, value: o.id, disabled: isEcomPointPresent(o.id) })
      }
      else if (o.integrationType == "ecwid") {
        _selectData.push({ key: index, text: o.url, value: o.id, disabled: isEcomPointPresent(o.id) })
      }
      else if (o.integrationType == "magento") {
        _selectData.push({ key: index, text: o.url, value: o.id, disabled: isEcomPointPresent(o.id) })
      }
      else { }
    })
    setSelectData([..._selectData]);
  }, [_state, _productState.productById]);

  useEffect(() => {
    let _selectEcomData = [];
    _state.selectedEcomList.map((o, index) => {
      _selectEcomData.push(...o.ecommerceIntegrations)
    })
    setSelectEcomData(_selectEcomData);
  }, [_state.selectedEcomList]);

  const checkUserAssociatedWithProduct = () => {
    let companyBIds = context.authState.businessIdentifiers
    let productBids = _product.businessIdentifiers

    const companyBid = objectPath.get(_.find(companyBIds, function (o) { return o.type === "VATID" }), 'id', _product.businessIdentifiers[0].id)

    if (_.find(productBids, ["id", companyBid])) {
      return true
    } else {
      return false
    }

  }

  const isEcomPointPresent = (id) => {
    let isPresent = false;
    if (!!_productState.productById.private && _productState.productById.private.ecommerceIntegrations && _productState.productById.private.ecommerceIntegrations.length > 0) {
      _productState.productById.private.ecommerceIntegrations.find((o) => {
        if (o.id == id) {
          isPresent = true
        }
      })
    }
    return isPresent
  }

  const getEcomUrl = (id) => {
    let _url = '';
    _state.ecomList.find((o, i) => {
      if (o.id == id)
        // woocommerce object contain url and shopify object contains storeName
        _url = o.url ? o.url : o.storeName
    })
    return _url;
  };

  const handleUrlFormChange = e => {
    // if(e.target.name=="url"){
    //     if(pattern.test(e.target.value)){
    //         setBuyurlErrorMsg(false)
    //     }else{
    //         setBuyurlErrorMsg(true)
    //     }
    // }
    // setBuyUrl({ ...buyUrl, [e.target.name]: e.target.value })
  };

  const handleUrlModelClose = () => {
    setUrlModelShow(false);
    setIntegrationType('')
    setUrlModelEditShow(false)
    setIntegrationPointObject({})
  };

  const addEcomIntegrationPointToProduct = () => {
    let ecomObjectToAdd = { id: modelList, price: integrationPointObject.retailPrice, category: integrationPointObject.category, lang: integrationLanguage ? integrationLanguage : 'en', productId: integrationPointObject?.productId }

    if (integrationType == "amazon" || integrationType == "magento") {
      ecomObjectToAdd.sku = integrationPointObject.sku
    }
    let reqModel = {
      "articleIdentifer": !!_product.gtin ? _product.gtin : _product._2an,
      "articleIdentiferType": !!_product.gtin ? "gtin" : "_2an",
      "ecommerceIntegration": ecomObjectToAdd
    }
    let ecomUrl = getEcomUrl(ecomObjectToAdd.id)
    dispatch(companyAction.setSelectedEcom({ ecomReqModel: reqModel, productId: !!_product.gtin ? _product.gtin : _product._2an, from: 'AddEcom', ecommerceShopUrl: ecomUrl }));
    handleUrlModelClose();
    setIntegrationPointObject({})
  }

  const editEcomIntegrationPointOfProduct = () => {
    let ecomObjectToEdit = { id: integrationPointObject.id, price: integrationPointObject.retailPrice, category: integrationPointObject.category, lang: integrationLanguage ? integrationLanguage : 'en',productId:integrationPointObject?.productId }
    if (integrationType == "amazon" || integrationType == "magento") {
      ecomObjectToEdit.sku = integrationPointObject.sku
    }
    let reqModel = {
      "articleIdentifer": !!_product.gtin ? _product.gtin : _product._2an,
      "articleIdentiferType": !!_product.gtin ? "gtin" : "_2an",
      "ecommerceIntegration": ecomObjectToEdit
    }
    dispatch(companyAction.setSelectedEcom({ ecomReqModel: reqModel, productId: !!_product.gtin ? _product.gtin : _product._2an }));
    handleUrlModelClose();
    setIntegrationPointObject({})
  }

  const deleteEcommerceIntegrationFromProduct = (data, index) => {
    let deleteEcomModel = {
      "articleIdentifer": !!_product.gtin ? _product.gtin : _product._2an,
      "articleIdentiferType": !!_product.gtin ? "gtin" : "_2an",
      "id": data.id
    }
    dispatch(companyAction.deleteEcomIntegrationPointFromProduct({ ecomReqModel: deleteEcomModel, productId: !!_product.gtin ? _product.gtin : _product._2an, from: 'DeleteEcom' }))
    setIntegrationLanguage('')
    handleUrlModelClose();
  }

  const deleteEcomIntegrationsFromProduct = (ecomData, productData) => {
    let newEcomIntArray = [...productData.productById.ecomIntegrations];
    let removeIndex = newEcomIntArray.findIndex(item => item.url == getEcomUrl(ecomData.id));
    newEcomIntArray.splice(removeIndex, 1);
    dispatch(changeFormObject({ ..._product, ecomIntegrations: newEcomIntArray, isChange: 1 }))
  }

  const editUrl = (data, index) => {
    checkAmazonIntegrationPresent(data.id)
    setUrlModelShow(true)
    setUrlModelEditShow(true)
    setIntegrationLanguage(data.lang)
    setIntegrationPointObject({
      retailPrice: data.price,
      category: data.category,
      id: data.id,
      lang: data.lang,
      productId : data.productId
    })
  }

  function checkAmazonIntegrationPresent(id) {
    let url = getEcomUrl(id)
    if (url == "https://www.amazon.com") {
      setIntegrationType("amazon")
    } else if (url.includes('magento')) {
      setIntegrationType('magento')
    }
  }

  const handleSelectEcomIntegration = (e, { name, value }) => {
    if (e.target.textContent == "https://www.amazon.com") {
      setIntegrationType('amazon')
    } else if (e.target.textContent.includes('magento')) {
      setIntegrationType('magento')
    } else {
      setIntegrationType('')
    }
    setModelList(value);
  };

  const handleSelectIntegrationLanguage = (e, { name, value }) => {
    setIntegrationLanguage(value)
  }

  const handleEcomIntegrationFormChange = (e) => {
    setIntegrationPointObject({ ...integrationPointObject, [e.target.name]: e.target.value })
  };

  const getCompanySupportedLanguege = () => {
    const supportLanguage = _.get(companyInfo, 'supportedLanguages', '');
    supportLanguage && supportLanguage[0] && supportLanguage.map((obj, index) => {
      const langObj = { key: index, text: obj.name, value: obj.code }
      supportedLanguege.push(langObj);
    })
    return supportedLanguege;
  }

  const companySupportedLanguage = getCompanySupportedLanguege();

  const getLanguageKeyLabel = (langKey = "en") => {
    let langaugeLabel = ""
    supportedLanguege && supportedLanguege.forEach((item) => {
      if (item.value === langKey) {
        langaugeLabel = item.text
      }
    })
    return langaugeLabel
  }



  const getEcomDetails = (data) => {
    let ecomDetailsToShow = []
    let keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (key == "category" || key == "price") {
        ecomDetailsToShow.push({ key: _.capitalize(key), value: data[key] })
      }
      else if (key == 'lang') {
        ecomDetailsToShow.push({ key: "Language", value: getLanguageKeyLabel(data[key]) })
      }
    });
    return ecomDetailsToShow
  }

  const isIngrationType = (integrationType) => {
    const isIntegrationType = integrationType.includes('magento') || integrationType.includes('amazon')
    return isIntegrationType;
  }

  if (!_state.ecomList.length == 0 &&
    ((_state.ecomList.length > 0 && context.authState.level == 10) || (context.authState.level != 10 && _state.ecomList.length > 0
      // && isCompanyAssociatedWithProduct
    ))) {
    // condition copy from manufacturer tab 

    return (
      <>
        <div style={{ width: '100%' }}>
          <div className="st-divider divider-thin my-3"></div>
          <div className="row">
            <div className='col-lg-8 col-md-8 col-sm-8 col-8 mb-3'>
              <span className='tab-section-info'>{t("_Ecommerce Integration Points")}</span>
            </div>
            {_productState.isProdEditMode && <div style={{ cursor: 'pointer' }} className='col-lg-4 col-md-4 col-sm-4 col-4'>
              <IconButton text={t("Add New")} icon={MdAddCircleOutline} onClick={() => setUrlModelShow(true)} />
            </div>}
          </div>
          <div className="col-12 ps-md-5 pe-5 mt-3">
            {
              _state.ecomLoading ?
                <div className="spinnerPosition">
                  <Spinner className="spinner-class"
                    animation="border"
                    variant="dark"
                  />
                </div>
                :
                <div className="row">
                  {!!_productState.productById && !_.isEmpty(_productState.productById.private) && _productState.productById.private.ecommerceIntegrations &&
                    _productState.productById.private.ecommerceIntegrations.map((data, index) => (
                      <div className='mt-1'>
                        <EcomCard
                          key={index}
                          url={getEcomUrl(data.id)}
                          cardData={getEcomDetails(data)}
                          isDelete={_productState.isProdEditMode}
                          onDeletClick={() => deleteEcommerceIntegrationFromProduct(data, index)}
                          isEdit={_productState.isProdEditMode}
                          onEditClick={() => editUrl(data, index)}
                        ></EcomCard>
                      </div>
                    ))
                  }
                </div>
            }
          </div>
        </div>

        <Modal size="md" centered={true} backdrop="static" show={urlModelShow} onHide={handleUrlModelClose} >
          <Modal.Header >
            <div style={{ position: 'absolute', right: 10 }}>
              <StCloseButton text={t("Close")} onClick={() => handleUrlModelClose()}></StCloseButton>
            </div>
          </Modal.Header>
          <Modal.Body style={{ fontWeight: 'normal' }}>
            <div className="row m-0 justify-content-center">
              <Dropdown style={{ margin: 15, fontSize: 14, width: 400, height: 40 }}
                selection search fluid
                className="analytics-dropdown"
                placeholder={t('Choose an Option')}
                options={selectData}
                value={integrationPointObject.id}
                disabled={urlModelEditShow ? true : false}
                onChange={handleSelectEcomIntegration} />
            </div>
            {companySupportedLanguage && companySupportedLanguage[0] && <div className="row m-0 justify-content-center">
              <Dropdown style={{ margin: 15, fontSize: 14, width: 400, height: 40 }}
                className="analytics-dropdown"
                selection search fluid
                placeholder={t("Language")}
                name={"Language"}
                options={companySupportedLanguage}
                value={integrationLanguage}
                disabled={false}
                onChange={handleSelectIntegrationLanguage} />
            </div>}
            {isIngrationType(integrationType) && <div className="form-group col-12"  >
              <InputTextWithLabel isSmallScreen={true}
                label={t("SKU")}
                name={"sku"}
                type="text"
                placeholder={t("SKU")}
                value={integrationPointObject.sku}
                readOnly={urlModelEditShow ? true : false}
                onChange={handleEcomIntegrationFormChange}
              ></InputTextWithLabel>
            </div>}
            <div className="form-group col-12"  >
              <InputTextWithLabel isSmallScreen={true}
                label={t("Retail Price")}
                name={"retailPrice"}
                type="text"
                placeholder={t("Retail Price")}
                value={integrationPointObject.retailPrice}
                onChange={handleEcomIntegrationFormChange}
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12"  >
              <InputTextWithLabel isSmallScreen={true}
                label={t("_Category")}
                name={"category"}
                type="text"
                placeholder={t("_Category")}
                value={integrationPointObject.category}
                onChange={handleEcomIntegrationFormChange}
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12"  >
              <InputTextWithLabel isSmallScreen={true}
                label={t("_Product Id")}
                name={"productId"}
                type="text"
                placeholder={t("_Product Id")}
                value={integrationPointObject.productId}
                onChange={handleEcomIntegrationFormChange}
              ></InputTextWithLabel>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: 'center' }}>
            {urlModelEditShow ?
              <StSquareButton text={t("Save")} onClick={() => editEcomIntegrationPointOfProduct()}></StSquareButton> :
              <StSquareButton text={t("Add")}
                disabled={integrationType == 'amazon' ? (!!integrationPointObject.sku && !!integrationPointObject.retailPrice && !!integrationPointObject.category) ? false : true : (!!integrationPointObject.retailPrice && !!integrationPointObject.category) ? false : true}
                onClick={() => addEcomIntegrationPointToProduct()}></StSquareButton>}
            <StCancelSquareButton text={t("Cancel")} onClick={() => handleUrlModelClose()}></StCancelSquareButton>
          </Modal.Footer>
        </Modal>

      </>

    )
  }

  return (
    <>

    </>
  )
}


