import ReactDOM from "react-dom";
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { MdClose } from "react-icons/md";
import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from "src/components/StSquareButton";
import { StCancelSquareButton } from "src/components/StCancelSquareButton";
import { useTranslation } from "react-i18next";



const UserLeaveConfirmation = (
    message,
    callback,
    confirmOpen,
    setConfirmOpen
) => {
    const { t } = useTranslation('common');
    const container = document.createElement("div");

    container.setAttribute("custom-confirm-view", "");

    const handleConfirm = (callbackState) => {
        ReactDOM.unmountComponentAtNode(container);
        callback(callbackState);
        setConfirmOpen(false);
    };

    const handleCancel = (callbackState) => {
        ReactDOM.unmountComponentAtNode(container);
        callback();
        setConfirmOpen(false);
    };

    document.body.appendChild(container);
    const { content } = JSON.parse(message);
    ReactDOM.render(

        <Modal size="md" show={confirmOpen} onHide={handleCancel} centered={true}>
            <Modal.Header>
                <div style={{position:'absolute',right:10}}>
               <StCloseButton text={t("Close")} onClick={()=>handleCancel()}></StCloseButton>
          </div>
            </Modal.Header>
            <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18,  }}>
                <span>{content}</span>
            </Modal.Body>
            <Modal.Footer>
                <StSquareButton text={t("Ok")} onClick={()=>handleConfirm()}></StSquareButton>
            <StCancelSquareButton text={t("Cancel")} onClick={()=>handleCancel()}></StCancelSquareButton>
            </Modal.Footer>
        </Modal>,

        container
    );
};

export default UserLeaveConfirmation;
