import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getAllFeedbacks, getFeedbackSearch } from "src/redux/feedback/feedbackThunk";
import FeedbackTable from './feedbackTable/FeedbackTable'
import { useTranslation } from "react-i18next";
import { Spinner } from "src/components/Spinner";
import { StSearchBox } from "src/components/StSearchBox";


const Feedbacks = () => {
  const [searchText, setSearchText] = useState("");
  const [showViewAll, setShowViewAll] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (searchText === "") {
      dispatch(getAllFeedbacks({}))
    }else{
      const timeOut = setTimeout(() => {
        clearTimeout(timeOut)
          dispatch(getFeedbackSearch(searchText));
      }, 1000)
      return () => clearTimeout(timeOut)
    }
  }, [searchText]);

  const _state = useSelector((state) => state.feedback, shallowEqual);
  const isLoading = _state.isAllDataFetchedLoading;

  return (
    <div className="homepage-main-div ps-2">
      <div className="row col-12 homepage-search-input-div pt-3" style={{paddingLeft:0}}>
      <div className="col-lg-6 col-md-6">
           <StSearchBox
                iconName={searchText === "" ? t("Search") : t("Close")}
                width={'100%'}
                placeholder={t("Search by Business Id or Name or Email")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onClick={() => setSearchText("")}
              />
            </div>
          </div>

      <div style={{ marginTop: 76, position: 'fixed' }} className='st-divider divider-thin'></div>
      {_state.isLoading ? 
      <div>
        <Spinner/>
      </div> :
      <div className="homepage-main-subdiv">
        <FeedbackTable
          feedbackList={_state.allFeedbacks}
          loading={isLoading}
        />
      </div>}
    </div>
  )
}

export default Feedbacks;