import axios from "axios";
/** import Cookies from 'js-cookie'; */
import appConfig from 'src/helper/appConfig';
import store from "src/redux/store";
import { changeSessionStatFetched } from "src/redux/dashboard/dashboardSlice";
import { Keys } from "./localStorageHelper";

/**
 * Interceptors are a feature that allows an application to intercept requests or responses before they are handled by the .then() or the .catch().
 * There are 2 type of interceptor 1) interceptors.request   &&   2) interceptors.response
 * Both types of Axios interceptors accept two functions. 
 * The first function of the request interceptor modifies the request if it’s a valid, successful request, 
 * the second function handles when the request is invalid and throws an error.
**/


export const getAxios = (customBaseUrl) => {
    const instance = axios.create();
    if (customBaseUrl) {
        instance.defaults.baseURL = customBaseUrl;
    } else {
        instance.defaults.baseURL = appConfig.SERVER_URL;
    }

    // interceptors Request------------------------------------
    instance.interceptors.request.use(
        async (config) => {
            /**
            const userToken = Cookies.get('appToken');
            const token = userToken ? JSON.parse(userToken) : '';
             */
            const authState = localStorage.getItem(Keys.AUTH_STATE)
            let token
            if (authState) {
                token = (JSON.parse(authState)).token;
            }

            if (token) {
                config.headers = {
                    ...config.headers,
                    Authorization: "Bearer " + token
                };
            }
            // Set a timeout of 15000 milliseconds (15 seconds) only if the token is not present
            if (!token) {
                config.timeout = 15000;
            }
            return config;
        },
        async (error) => {
            return new Promise((resolve, reject) => {
                console.info("resolve", resolve)
                reject(error);
            });
        }
    );

    //validating the token expiration scenario --------------------------
    // interceptors Response------------------------------------
    instance.interceptors.response.use(
        async (Response) => {
            store.dispatch(changeSessionStatFetched(false))
            return Response
        },
        async (error) => {
            if (error.response && error.response.status === 401) {
                //dispatch action using store to show token expire popup-----
                // Cookies.remove('hrmAuth')
                localStorage.removeItem(Keys.AUTH_STATE);
                return new Promise((resolve, reject) => {
                    window.location.pathname = "/"
                    store.dispatch(changeSessionStatFetched(true))
                    reject(error);
                });
            } else {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
        }
    );

    return instance;
}