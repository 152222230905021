import { configureStore } from "@reduxjs/toolkit";
import { reduxBatch } from "@manaflair/redux-batch";
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'
import { rootReducer } from "./rootReducer";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['favourites'],
  //   transforms: [
  //     // Create a transformer by passing the reducer key and configuration. Values
  //     // shown below are the available configurations with default values
  //     expireReducer('products', {
  //       // (Optional) Key to be used for the time relative to which store is to be expired
  //       persistedAtKey: '__persisted_at',
  //       // (Required) Seconds after which store will be expired
  //       expireSeconds: 5,
  //       // (Optional) State to be used for resetting e.g. provide initial reducer state
  //       expiredState: {},
  //       // (Optional) Use it if you don't want to manually set the time in the reducer i.e. at `persistedAtKey` 
  //       // and want the store to  be automatically expired if the record is not updated in the `expireSeconds` time
  //       autoExpire: false
  //     })
  //     // You can add more `expireReducer` calls here for different reducers
  //     // that you may want to expire
  //  ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// const middleware = [
//   ...getDefaultMiddleware({
//     immutableCheck: false,
//     serializableCheck: false,
//     thunk: true,
//   }),
// ];

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false, serializableCheck: false, thunk: true, }),
  // enhancers: [reduxBatch],
  enhancers:(getDefaultEnhancers) => getDefaultEnhancers([reduxBatch])
});

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);


export default store;
