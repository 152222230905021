import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import objectPath from "object-path";
import Dropzone from "react-dropzone";
import * as yup from "yup";
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel"
import { InputSelectDropdown } from "src/components/inputComponents/InputSelectDropdown";
import { certificationsSchema } from "src/helper/yup-schema";
import { getProductTemplateList } from "src/redux/products/productThunk";
import { certificationsAllErrorsFormObject, resetCertificationsDataFormAction, updateCertificationsDataFormAction } from "src/redux/certifications/certificationsSlice";
import { uploadCertificateImageURL } from "src/redux/certifications/certificationsThunk";
import emptyimage from 'src/assets/images/emptyimage.png'

const AddCertificate = () => {
    /** redux store useDispatch & useSelector ------------------------------ **/
    const dispatch = useDispatch();
    const { t } = useTranslation("common");
    const certificationsState = useSelector((state) => state.certifications, shallowEqual);

    /** hooks useState ----------------------------------------------------- **/
    const [touchedState, setTouchedState] = useState(true);
    const [productTemplateList, setProductTemplateList] = useState([])
    const [ImageUrl, setImageUrl] = useState({
        logo: { isDirty: false, list: [], readyToUpload: null, baseForm: null },
    });

    /** Variable declaration ----------------------------------------------------- **/
    const { certificationsFormData, certificationsErrorsformObject } = certificationsState;
    const MainImageList = ['logo',];
    let AllErrors = certificationsErrorsformObject;

    /** hooks useEffect----------------------------------------------------- **/
    useEffect(() => {
        getProductTemplateListData();
        dispatch(resetCertificationsDataFormAction())

        return () => {
            dispatch(certificationsAllErrorsFormObject([]))
        }
    }, [])

    /** handler functions--------------------------------------------------- **/

    /** Function to retrieve all product template data */
    const getProductTemplateListData = async () => {
        try {
            const templateList = await dispatch(getProductTemplateList()).unwrap();
            const productTemplateTypeOptions = templateList.map((o, index) => {
                const item = { key: index, text: o.label, value: o.name };
                return item
            });
            setProductTemplateList(productTemplateTypeOptions)
        } catch (error) {
            console.log(error)

        }
    }

    /** Function execute when select on any of the image----------------------------- */
    const onImageDrop = (acceptedFiles, type) => {
        let file = acceptedFiles[0];

        // Get the file extension from the original file name
        const originalFileName = file.name;
        const fileExtension = originalFileName.split('.').pop().toLowerCase();

        // Generate a new file name using the current timestamp
        const newFileName = `${new Date().getTime()}.${fileExtension}`;

        // Create a new File object with the updated name
        const renamedFile = new File([file], newFileName, { type: file.type });

        const reader = new FileReader()
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.readAsDataURL(file)
        reader.onload = () => {
            let _docUrl = ImageUrl;
            objectPath.set(_docUrl, `${type}.baseForm`, reader.result);
            objectPath.set(_docUrl, `${type}.readyToUpload`, renamedFile);
            objectPath.set(_docUrl, `${type}.list`, [reader.result]);
            objectPath.set(_docUrl, `${type}.isDirty`, false);
            setImageUrl({ ..._docUrl });
            uploadImage(type)
        }
    }

    /** Function execute to upload image-------------------------- */
    const uploadImage = async (type) => {
        const formData = new FormData();
        formData.append(type, objectPath.get(ImageUrl, `${type}.readyToUpload`));
        try {
            let result = await dispatch(uploadCertificateImageURL({ body: formData, type: type }));
            dispatch(updateCertificationsDataFormAction({
                name: "image_url",
                value: result?.payload[0]
            }))

        } catch (error) {
            console.log(error)
        }
    }

    /** Function to save input field values--------------------------- */
    const handleFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        validator({ name: name, value: value });
        dispatch(updateCertificationsDataFormAction({
            name: name,
            value: value
        }))
        setTouchedState(true)
    }

    /** Function to validate the input field values----------------------- */
    const validator = ({ name, value }) => {
        const existingErrorIndex = AllErrors.findIndex(
            (error) => error.path === name
        );
        let newErrors = [...AllErrors];
        yup
            .reach(certificationsSchema, name)
            .validate(value)
            .then((valid) => {
                if (newErrors.length > 0) {
                    newErrors = newErrors.filter((error) => error.path !== name);
                    dispatch(certificationsAllErrorsFormObject(newErrors));
                }
            })
            .catch((err) => {
                if (existingErrorIndex !== -1) {
                    newErrors[existingErrorIndex] = {
                        path: name,
                        message: err.message,
                    };
                } else {
                    newErrors.push({ path: name, message: err.message });
                }
                dispatch(certificationsAllErrorsFormObject(newErrors));
            });
    };

    /** Funtion to create error object based on yup validation ------------------------- */
    const errorShown = (AllErrors) => {
        const errorObject = {}
        AllErrors &&
            AllErrors[0] &&
            AllErrors.forEach((obj, index) => {
                errorObject[obj.path] = obj.message;
            });
        return errorObject
    };

    const errorObject = errorShown(AllErrors);

    return (
        <>
            <div className="manufacturersidebar">
                {MainImageList.map((item, index) => (
                    <div key={index}>
                        <div key={index} className="row justify-content-center mt-4" style={{ display: 'grid', alignItems: 'center' }}>
                            <Dropzone onDrop={acceptedFiles => onImageDrop(acceptedFiles, item)}
                                accept="image/*" // Specify accepted file types
                                multiple={false} // Allow only one file
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({
                                        className: 'dropzone',
                                        onClick: event => { event.target.id !== "image-upload-id" && event.stopPropagation() }
                                    })}>
                                        <input {...getInputProps()} />
                                        <div className="flip-card">
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img style={{ objectFit: 'contain' }} alt="alt" src={!!objectPath.get(ImageUrl, `${item}.list.0`) ? objectPath.get(ImageUrl, `${item}.list.0`) : emptyimage} />


                                                </div>
                                                <div className="flip-card-back">
                                                    <img style={{ objectFit: 'contain' }} alt="alt" id="image-upload-id" src={!!objectPath.get(ImageUrl, `${item}.list.0`) ? objectPath.get(ImageUrl, `${item}.list.0`) : emptyimage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    </div>
                ))}
            </div>


            <div className="row col-12 mt-3 contentManagementForm">
                <InputTextWithLabel
                    label={t("Certificate Name")}
                    tooltipText={t("Certificate Name")}
                    onChange={handleFormChange}
                    name={"name"}
                    value={certificationsFormData.name}
                    type="text"
                    placeholder={t("Certificate Name")}
                    touched={touchedState}
                    errorMessage={!!errorObject && errorObject.name ? errorObject.name : ""}
                />
                <InputTextWithLabel
                    label={t("Certificate URL")}
                    tooltipText={t("Certificate URL")}
                    onChange={handleFormChange}
                    name={"url"}
                    value={certificationsFormData.url}
                    type="text"
                    placeholder={t("Certificate URL")}
                    touched={touchedState}
                    errorMessage={!!errorObject && errorObject.url ? errorObject.url : ""}
                />
                <InputSelectDropdown
                    label={t("Template Associated")}
                    placeholder={t('Template Associated')}
                    name="template"
                    value={certificationsFormData.template}
                    options={productTemplateList}
                    onChange={handleFormChange}
                    touched={touchedState}
                    errorMessage={!!errorObject && errorObject.template ? errorObject.template : ""}
                />
            </div>
        </>
    );
};

export default AddCertificate;
