import React, { forwardRef, Fragment, useContext, useEffect, useRef, useState } from "react";
import { MdSort,MdEdit,MdLaptopMac,MdSend ,MdPerson} from "react-icons/md";
import { BsChatSquareText } from "react-icons/bs";

import { useNavigate } from "react-router-dom";

import {Spinner} from "src/components/Spinner";
import { MaterialTable } from "src/components/MaterialTable";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { getFeedbackConversations, replyToFeedback } from "src/redux/feedback/feedbackThunk";

import { StCloseButton } from "src/components/StCloseButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { usePrevious } from "../../../helper/CustomHook";

import moment from 'moment'; // require
import objectPath from "object-path";
import _ from "lodash";
import { AppContext } from "src/pages/AppContext";

function FeedbackTable(props) {
  const navigate = useNavigate();
  const { t,i18n } = useTranslation('common');
  const dispatch = useDispatch();
  const elementRef = useRef();
  const context = useContext(AppContext);


  const [showConversationsView, setShowConversationsView] = useState(false)
  const [feedbackReplyText, setFeedbackReplyText] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0);

  const _state = useSelector(
    (state) => (state.feedback),
    shallowEqual
  );

  const {feedbackConversationsResponse } = _state;
  const prevPropsState = usePrevious({ feedbackConversationsResponse });

  useEffect(() => {
    if (prevPropsState) {
       if (prevPropsState.feedbackConversationsResponse !== feedbackConversationsResponse && feedbackConversationsResponse) {
             setFeedbackReplyText("")
            //  window.scrollTo(0, elementRef.current.scrollHeight);
            // elementRef.current.scrollHeight({ behavior: "smooth" });
          }
    }
  }, [_state.feedbackConversationsResponse]);


  const tableIcons = {
    SortArrow: forwardRef((props, ref) => <MdSort {...props} ref={ref} />),
  };

  const allData = [];

  const handleEditClickEvent = (o, p) => {
   navigate(`/feedbacks/${o.id}`);
  };

  const handleOnConversationButtonClick = (feedbackId, index) => {
    setSelectedIndex(index)
    setShowConversationsView(true)
    dispatch(getFeedbackConversations(feedbackId))
  }

  const sendReplyToFeedback = () => {
    const lastConversationMessage = _.last(feedbackConversationsResponse);
    const feedback = props.feedbackList[selectedIndex]


    const feedbackData = {
      "previousFeedbackText": lastConversationMessage.text,
      // "userEmail": feedback.email,
      "productName": feedback.productName,
      "feedbackId": feedback.feedbackId,
      "emailText": feedbackReplyText,
      "sourceUrl": feedback.sourceUrl,
      "businessIdentifiers": feedback.feedbackToBusinessId,
      "language": feedback.language
    }
    dispatch(replyToFeedback(feedbackData))
  }

  const handleWriteMessage = (e) => {
    setFeedbackReplyText(e.target.value)
  }

  const openParticularProduct =(o)=>{
    if(o.upidsId){
      navigate(`/productProfile/${o.articleIdentifier}/upidsProduct/${o.upidsId}`,
        // state: { productData: props.productData }
     )
    }else{
     navigate(`/productProfile/${o.articleIdentifier}`);
    }
  }

  const openParticularCompany =(businessId)=>{
    navigate(`/manufacturers/profile/${businessId}`);
  }

  const createTableData = () => {
    if (!!props.feedbackList && props.feedbackList.length > 0) {
      props.feedbackList.map((o, index) => {
        const businessId = objectPath.get(_.find(o.feedbackToBusinessId, function(o){return o.type === "VATID"}), "id", o.feedbackToBusinessId[0].id);
        allData.push({
          key:index,
          identifier: <p className="feedbackTableText" style={{cursor:'pointer'}} onClick={()=>openParticularProduct(o)}>{o.upidsId ? o.upidsId : o.articleIdentifier}</p>,
          productName: o.productName,
          businessId: context.authState.level === 10 ? <p className="feedbackTableText" style={{cursor:'pointer'}} onClick={()=>openParticularCompany(businessId)}>{businessId}</p> : <p>{businessId}</p>,
          date: moment.unix(o.timestamp).format("YYYY-MM-DD HH:mm:ss"),
          action: (
            <>
            <MdEdit
              style={{ cursor: "pointer" }}
              onClick={(e) => handleEditClickEvent(o)}
            />
             <BsChatSquareText
              style={{ cursor: "pointer",marginLeft:15 }}
              onClick={() => handleOnConversationButtonClick(o.feedbackId, index)}
            />
            </>
           
          ),
        });
      });
    }
  };

  createTableData();

  return (
    <>
      {props.loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <MaterialTable
        columnNames={[
          { title: t("Identifier"), field: "identifier" },
          { title: t("_Business Id"), field: "businessId" },
          { title: t("_Product Name"), field: "productName" },
          { title: t("Date"), field: "date" },
          { title: t("Action"), field: "action", sorting: false, headerStyle: { textAlign: 'center' }, cellStyle: { textAlign: 'center' } },
            ]}
        tableData={allData}
      ></MaterialTable>
       
      )}
      <br></br>

      <Modal scrollable show={showConversationsView} onHide={() => setShowConversationsView(false)} centered={true}>
        <Modal.Header style={{ height: 40 }}>
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => setShowConversationsView(false)}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body>
          {_state.conversationLoading ? <Spinner /> :
            <Fragment>
              <div ref={elementRef}>
                {!!feedbackConversationsResponse && feedbackConversationsResponse.map((data, i) => (
                  data.feedbackConversationFrom == "PDM_System" ?
                    <div className='d-flex align-items-center'>
                      <div><MdLaptopMac fontSize={26} style={{ color: '#6489a0' }} /></div>
                      <div style={{ backgroundColor: "#f8fcfd", color: '#6489a0', margin: 5, width: '60%', borderRadius: 20, padding: 5, border: '1px solid rgb(223, 237, 245' }}>
                        <span>{data.text}</span><br></br>
                        <span style={{ fontSize: 11 }}>{moment.unix(data.timestamp).format("YYYY-MM-DD hh:mm:ssA")}</span>
                      </div>
                    </div> :
                    <div className='d-flex justify-content-center align-items-center'>
                      <div style={{ backgroundColor: "#f8fcfd", color: '#6489a0', margin: 5, width: '60%', borderRadius: 20, padding: 5, marginLeft: '40%', border: '1px solid rgb(223, 237, 245' }}>
                        <span>{data.text}</span><br></br>
                        <span style={{ fontSize: 11 }}>{moment.unix(data.timestamp).format("YYYY-MM-DD hh:mm:ssA")}</span>
                      </div>
                     <div><MdPerson fontSize={26} style={{ color: '#6489a0' }} /></div>
                    </div>
                ))}
              </div>

            </Fragment>
          }
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <div className='product_feedback_modal' style={{ pointerEvents: props.feedbackList.length > 0 && props.feedbackList[selectedIndex].emailAvailable ? 'auto' : 'none' }}>
            <div className='form-group  col-lg-11 col-md-11 col-sm-11 col-xs-11'>
              <input type='text' placeholder='Enter something'
                className='product_feedback_modal_reply_input'
                value={feedbackReplyText}
                onChange={(e)=>handleWriteMessage(e)}
                onKeyUp={(e) => (e.key === 'Enter' ? sendReplyToFeedback() : null)}
              />
            </div>
           
            <div className='form-group  col-lg-1 col-md-1 col-sm-1 col-xs-1' style={{ pointerEvents: feedbackReplyText ? 'auto' : 'none' }}>
            {_state.feedbackReplyLoading ? 
                     <div className="spinner-border text-secondary" role="status">
                          {" "}
                      </div> :
              <MdSend className='product_feedback_modal_reply_button' onClick={sendReplyToFeedback} />
            }
        </div>
          </div>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default FeedbackTable;
