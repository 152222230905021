import React, { useState, useEffect, useContext } from "react";
import { MdApps, MdViewHeadline } from 'react-icons/md';
import GridView from '../components/GridView';
import ProductTable from '../components/productsTable/ProductTable';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getProductsWithPage, getFavouriteProducts } from "src/redux/products/productThunk";
import { useStateCallback } from "../../../helper/CustomHook";
import { ProductContext } from '../ProductsContext'
import { saveFavourites } from "src/redux/favourites/favouritesThunk";
import { addToList, removeFromList, updateTimestamp, } from "src/redux/favourites/favouritesSlice";
import { useTranslation } from "react-i18next";

const Favourites = () => {
  const { t } = useTranslation('common');

  const [PageCount, setPageCount] = useStateCallback(1);
  const [prodList, setProdList] = useState([]);
  const context = useContext(ProductContext);
  const _favouritesState = useSelector((state) => state.favourites, shallowEqual);
  const [favouritesList, setFavouritesList] = useState(_favouritesState.list.length > 0 ? _favouritesState.list : []);
  const [lastModified, setLastModified] = useState(0);

  const dispatch = useDispatch();
  const _state = useSelector(
    (state) => (state.products),
    shallowEqual
  );

  useEffect(() => {
    setPageCount(1)
    dispatch(getFavouriteProducts());
  }, []);

  useEffect(() => {
    setFavouritesList(!!_favouritesState.list.length > 0 ? _favouritesState.list : []);
  }, [_favouritesState.list]);

  const getProducts = (_mode, _gtin) => {
    dispatch(getProductsWithPage({ mode: _mode, gtin: _gtin }));
  }

  const isLoading = _state.isAllDataFetchedLoading;

  useEffect(() => {
    setProdList(modifyProductList())
  }, [_state.productsPerPage]);

  useEffect(() => {
    setProdList(modifyProductList());
    if (lastModified > 0) {
      dispatch(saveFavourites({body: favouritesList}));
    }
  }, [favouritesList]);

  const modifyProductList = () => {
    let tList = []
      if (_state.productsPerPage) {
        tList = _state.productsPerPage.slice();
        if (tList) {
          favouritesList.map((favourite, index) => {
            // const tListIndex = tList.findIndex(x => x.gtin === favourite);
            const tListIndex = tList.findIndex((x)=>{
              let identifier = !!x.gtin ? x.gtin : x._2an
              return identifier == favourite
            });
            tList[tListIndex] = {...tList[tListIndex], isFavourite: true};
          });
        }
      }
    return tList;
  }

  const handleFavouriteClick = (gtin) => {
    const index = favouritesList.indexOf(gtin);

    if (index > -1) {
      setFavouritesList(favouritesList.filter(item => item !== gtin));
      dispatch(removeFromList(gtin));
    } else {
      setFavouritesList(favouritesList.concat(gtin));
      dispatch(addToList(gtin));
    }

    const timestamp = Math.floor(Date.now() / 1000);
    setLastModified(timestamp);
    dispatch(updateTimestamp(timestamp));
  };

  return (
    <div className="products_main-div">

      <div className="products_main-subdiv">
        <div className="products_subdiv">
          <div className="products_subdiv-2">
            <span className="products_view-span-text">{t("View")}</span>
            <MdApps className="products_grid-icon" onClick={() => context.setViewType('GRID')} color={context.viewType === 'GRID' ? '#6489A0' : '#A9C1CE'} size={24} />
            <span className="products_horizontal-line"></span>
            <MdViewHeadline className="products_grid-icon" onClick={() => context.setViewType('TABLE')} color={context.viewType === 'TABLE' ? '#6489A0' : '#A9C1CE'} size={24} />
          </div>
        </div>
        {context.viewType === 'GRID' ?
          <GridView getProducts={getProducts} productList={prodList} data={{ PageCount: PageCount, setPageCount: setPageCount }} searchText={_state.productSearchText} loading={isLoading} favouritesClickHandle={handleFavouriteClick} isFavourite={true}/>
          :
          <ProductTable getProducts={getProducts} searchText={_state.productSearchText} productList={prodList} data={{ PageCount: PageCount, setPageCount: setPageCount }} loading={isLoading} favouritesClickHandle={handleFavouriteClick} isFavourite={true}/>

        }
      </div>
    </div>
  );
};

export default Favourites;
