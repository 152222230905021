import React from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from 'src/components/StSquareButton';
import { deleteVouchersAction, getVouchersAction } from 'src/redux/admin/adminThunk';


function DeleteVoucherConfirmModal(props) {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()

    const { isShowModal, voucherData, handleClose } = props

    const reduxState = useSelector(state => state)


    const handleDeleteVoucher = async () => {
        try {
            await dispatch(deleteVouchersAction(voucherData.id)).unwrap()
            dispatch(getVouchersAction())
            handleClose()
        } catch (error) {
            dispatch(getVouchersAction())
        }
    }

    return (
        <>
            <Modal size="md" centered={true} backdrop="static" show={isShowModal} onHide={handleClose} >
                <Modal.Header >
                    <div style={{ position: 'absolute', right: 10 }}>
                        <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ fontWeight: 'normal' }}>
                    <div className="row col-12 m-0 justify-content-center">
                        {t("Are you sure you want to delete the voucher")}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'space-around' }}>
                    <StSquareButton text={t("Yes")} onClick={() => handleDeleteVoucher()}></StSquareButton>
                    <StSquareButton text={t("No")} onClick={() => handleClose()}></StSquareButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DeleteVoucherConfirmModal