import React, { useEffect, useState, useContext, useRef, Fragment } from "react";
import { useNavigate } from "react-router-dom";
// import CountCard from "../commonComponent/CountCard/CountCard";
import { getProductsWithPage } from "src/redux/products/productThunk";
import {
  getDashboardNewsfeedData, getContentfulData, getDashboardLatestFeedbackData,
  getAverageNpsScore, getFeedbackSentimentScore, sendFeedbackOfProduct,
} from "src/redux/dashboard/dashboardThunk";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppContext } from '../AppContext';
import ProductCardPlaceholder from "../commonComponent/ProductCard/ProductCardPlaceholder";
import _ from "lodash";
import { MdArrowBack, MdArrowForward, MdAddCircle, MdSend, MdPerson, MdLaptopMac } from "react-icons/md";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { IconButton } from "src/components/IconButton";
import { StMenu } from "src/components/StMenu";
import { ProductCard } from "src/components/ProductCard";
import { Spinner } from "src/components/Spinner";
import food_pic from '../../assets/images/Dummy_Product_Pic.png';
import { useTranslation } from 'react-i18next'
import NewsFeedHtml from "./components/NewsFeedHtml";
import Modal from "react-bootstrap/Modal";
import moment from 'moment'; // require
import objectPath from "object-path";
import { getFeedbackConversations, replyToFeedback } from "src/redux/feedback/feedbackThunk";
import { StCloseButton } from 'src/components/StCloseButton';
import { usePrevious } from "src/helper/CustomHook";
import { StSquareButton } from "src/components/StSquareButton";
import { StCancelSquareButton } from "src/components/StCancelSquareButton";
import LatestFeedbacks from "./components/LatestFeedbacks";

let productCardsToShow;
const itemsToShow = 1;


const Dashboard = () => {

  const dashboardNavbarCollectionAdmin = [
    { menuItem: () => <IconButton text={t("Add Manufacturer")} icon={MdAddCircle} onClick={() => handleAddCompanyOnClick()} /> },
    { menuItem: () => <IconButton text={t("Add Product")} icon={MdAddCircle} onClick={() => handleAddProductClick()} /> },
  ]
  const dashboardNavbarCollectionUser = [
    { menuItem: () => <IconButton text={t("Add Product")} icon={MdAddCircle} onClick={() => handleAddProductClick()} /> },
  ]

  const navigate = useNavigate();
  const { t, i18n } = useTranslation('common');
  const dispatch = useDispatch();
  const context = useContext(AppContext);
  const [PageCount, setPageCount] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const [showConversationsView, setShowConversationsView] = useState(false);
  const [feedbackReplyText, setFeedbackReplyText] = useState('')
  const [selectedIndex, setSelectedIndex] = useState();
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [productFeedbackMessage, setProductFeedbackMessage] = useState({});
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const elementRef = useRef();
  const feedbackElementRef = useRef();

  const handleAddCompanyOnClick = () => {
    navigate("/manufacturers/add");
  };

  useEffect(() => {
    dispatch(getProductsWithPage({}));
    // dispatch(getDashboardCount());
    dispatch(getDashboardNewsfeedData({ language: i18n.language }));
    dispatch(getDashboardLatestFeedbackData());
    dispatch(getAverageNpsScore({ businessId: objectPath.get(_.find(context?.authState?.businessIdentifiers, function (o) { return o.type === "VATID" }), "id", context?.authState?.businessIdentifiers[0]?.id) }));
    dispatch(getFeedbackSentimentScore({ businessId: objectPath.get(_.find(context?.authState?.businessIdentifiers, function (o) { return o.type === "VATID" }), "id", context?.authState?.businessIdentifiers[0]?.id) }));
    dispatch(getContentfulData({ contentType: 'videoTutorials', language: i18n.language }));
  }, []);

  const _state = useSelector(
    (state) => (state.products),
    shallowEqual
  );
  const _state1 = useSelector(
    (state) => (state.dashboard),
    shallowEqual
  );
  const _feedbackState = useSelector(
    (state) => (state.feedback),
    shallowEqual
  );

  const { feedbackConversationsResponse } = _feedbackState;
  const { sendProductFeedbackSuccess } = _state1;

  const prevPropsState = usePrevious({ feedbackConversationsResponse, sendProductFeedbackSuccess });

  useEffect(() => {
    if (prevPropsState) {
      if (prevPropsState.feedbackConversationsResponse !== feedbackConversationsResponse && feedbackConversationsResponse) {
        setFeedbackReplyText("")
      }
    }
  }, [_feedbackState.feedbackConversationsResponse]);

  useEffect(() => {
    if (prevPropsState) {
      if (prevPropsState.sendProductFeedbackSuccess !== sendProductFeedbackSuccess && sendProductFeedbackSuccess) {
        setProductFeedbackMessage({});
        setOpenFeedbackModal(false);
        setShowSuccessMsg(true);

        setTimeout(() => {
          setShowSuccessMsg(false);
        }, 3000);
      }
    }
  }, [_state1.sendProductFeedbackSuccess]);

  let usersCount;
  let productsCount;
  let companiesCount;

  if (_state1.dashboardCounts) {
    usersCount = _state1.dashboardCounts.usersCount;
    productsCount = _state1.dashboardCounts.productsCount;
    companiesCount = _state1.dashboardCounts.companiesCount
  }

  const allProducts = _state.productsPerPage;
  let getOnlyTop10Products;
  if (allProducts) {
    let productHistory = JSON.parse(localStorage.getItem(`product_history_${context?.authState?.uId}`)) || [];

    const compareById = (product1, product2) => {
      return product1?.id === product2?.id;
    };
    
    // Get the first records from local storage
    const localHistorySlice = productHistory?.slice(0, Math.min(productHistory?.length, 10));
    
    // Fill in the remaining slots with unique products from allProducts
    const remainingProductsCount = Math.max(0, 10 - localHistorySlice?.length);
    const remainingProducts = allProducts?.filter(product => !productHistory?.some(localProduct => compareById(localProduct, product))).slice(0, remainingProductsCount);
    
    // Combine records from local storage and allProducts
    getOnlyTop10Products = [...localHistorySlice, ...remainingProducts];

    productCardsToShow = []
    for (let i = 0; i < 10; i++) {
      if (!!getOnlyTop10Products[i]) {
        productCardsToShow.push(getOnlyTop10Products[i])
      } else {
        productCardsToShow.push({})
      }
    }
  }

  let latestFeedbacks;
  if (!!_state1.dashboardLatestFeedbackData) {
    latestFeedbacks = _state1.dashboardLatestFeedbackData
  }




  const showMoreToggle = () => {
    setShowMore(!showMore)
  }


  const isLoading = _state.isAllDataFetchedLoading;

  const handleAddProductClick = () => {
    navigate("/addproduct");
  };

  const getProductName = (o) => {
    if (!!o.productName) {
      if (!!o.productName.en && o.productName.en !== "") {
        return o.productName.en
      } else {
        return o.productName.fi
      }

    } else {
      return "NA";
    }
  }

  const getProductCardImage = (data) => {
    let image = ''
    if (!!data.multimedia && data.multimedia.primary) {
      image = data.multimedia.primary[0]
    } else if (!!data.media) {
      image = typeof (data.media) == "string" ? data.media :
        typeof (data.media) == "object" ? data.media.images[1024][0] !== "" && data.media.images[1024][0] !== null ? data.media.images[1024][0] : food_pic : food_pic
    } else {
      image = food_pic
    }
    return image
  }

  // scroll function handle
  function scrollHandle(element, change, duration) {
    var start = element.current.scrollLeft,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.current.scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }
  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };

  const handleOnConversationButtonClick = (feedbackId, index) => {
    setSelectedIndex(index)
    setShowConversationsView(true)
    dispatch(getFeedbackConversations(feedbackId))
  }

  const handleWriteMessage = (e) => {
    setFeedbackReplyText(e.target.value)
  }

  const sendReplyToFeedback = () => {
    const lastConversationMessage = _.last(feedbackConversationsResponse);
    const feedback = latestFeedbacks[selectedIndex];

    const feedbackData = {
      "previousFeedbackText": lastConversationMessage.text,
      // "userEmail": feedback.email,
      "productName": feedback.productName,
      "feedbackId": feedback.feedbackId,
      "emailText": feedbackReplyText,
      "sourceUrl": feedback.sourceUrl ? feedback.sourceUrl : "",
      "businessIdentifiers": feedback.feedbackToBusinessId,
      "language": feedback.language
    };
    dispatch(replyToFeedback(feedbackData));
  }

  const handleSendFeedbackForProduct = () => {
    let feedbackModel = {
      email: context.authState.email,
      id: '06429830025306',
      productName: 'UPIDS PDM',
      text: productFeedbackMessage.productFeedbackText,
      subscribeMarketing: false,
      feedbackToBusinessId: context.authState.businessIdentifiers,
      sourceUrl: window.location.href,
      npsScore: 0,
      language: i18n.language,
    }
    dispatch(sendFeedbackOfProduct(feedbackModel));
  }

  const handleInputChange = e => {
    setProductFeedbackMessage({ ...productFeedbackMessage, [e.target.name]: e.target.value })
    // dispatch(productAction.changeFormObj({ ...productData, [e.target.name]: e.target.value, isChange: 1 }))
  };

  const getVideoUrl = (youtubeUrl) => {
    let youtubeVideoUrl;
    if (youtubeUrl && youtubeUrl.includes("youtube")) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = youtubeUrl.match(regExp);
      const youtubeVideoId = (match && match[7].length == 11) ? match[7] : false;
      youtubeVideoUrl = `https://www.youtube-nocookie.com/embed/${youtubeVideoId}?controls=1&autoplay=0&version=3&enablejsapi=1`
    }
    return youtubeVideoUrl;
  }

  const getNpsColor = (avg_npsScore) => {
    if (avg_npsScore <= 6) {
      return '#ff0000'
    } else if (avg_npsScore <= 8) {
      return '#ffdd00'
    } else {
      return '#00ff00'
    }
  }

  return (
    <>

      <div className="dashboard-main-div">
        <div className="subdiv-1">
          <span className="dashboard-span-text">{t("Dashboard")}</span>
          <div className="rightside-div d-none d-sm-flex">
            {context.authState.level === 10 && (
              <IconButton
                text={t("Add Manufacturer")}
                icon={MdAddCircle}
                onClick={() => handleAddCompanyOnClick()} />
            )}
            <IconButton
              text={t("Add Product")}
              icon={MdAddCircle}
              onClick={() => handleAddProductClick()} />
          </div>

          {/* responsive dropdown on mobile screen */}
          <div className="rightside-div-dashboard mt-4 productNavResponsiveDropdown">
            {context.authState.level === 10 ?
              <StMenu menuArray={dashboardNavbarCollectionAdmin}></StMenu> :
              <StMenu menuArray={dashboardNavbarCollectionUser}></StMenu>}
          </div>
        </div>
        <div className='st-divider divider-thin'></div>


        {(_state1.actionsLoading || _state.actionsLoading) ?
          <div>
            <Spinner />
          </div> :
          <div>

            {!!productCardsToShow && <div className="d-flex flex-row flex-nowrap mt-1" style={{ width: '100%', marginBottom: -10 }}>
              <div className="carousalArrow ms-1" onClick={() => scrollHandle(elementRef, -200, 1000)}>
                <BsChevronLeft color="#cf2948" size={32}></BsChevronLeft>
              </div>
              <div ref={elementRef} className="m-1 d-flex flex-row flex-nowrap overflow-auto product_card">
                {!!productCardsToShow &&
                  productCardsToShow.map((data, index) => (
                    !!Object.keys(data).length ?
                      <div key={index} >
                        <ProductCard
                          imageSrc={getProductCardImage(data)
                            // !!data.multimedia && data.multimedia.primary ? data.multimedia.primary[0]  :
                            // !!data.media && typeof(data.media) == "string" ? data.media : typeof(data.media) == "object" ?
                            // data.media.images[1024][0] !== "" && data.media.images[1024][0] !== null  ? data.media.images[1024][0]
                            //  : food_pic : food_pic


                          }
                          content={getProductName(data)}
                          height={'200px'}
                          width={'176px'}
                          imageHeight={'136px'}
                          imageWidth={'176px'}
                          onClick={() => navigate(`/productProfile/${!!objectPath.get(data, 'gtin') ? objectPath.get(data, 'gtin') : objectPath.get(data, '_2an')}`,
                            {state: { productData: data }
                          })}
                        // isFavourite={data.isFavourite}
                        // onStarClick={()=>alert("fav clicked")}
                        />
                      </div>
                      :
                      <ProductCardPlaceholder
                        key={index}
                        pName={t('Placeholder')}
                        productData={data}
                      // favouritesClickHandle={props.favouritesClickHandle}
                      />
                  ))}
              </div>
              <div className="carousalArrow rightScrollCardIcon" onClick={() => scrollHandle(elementRef, 200, 1000)}>
                <BsChevronRight color="#cf2948" size={32}></BsChevronRight>

              </div>
            </div>}

            <div className="row ms-1">
              <div className="newsfeedbackCard overflow-auto">
                <span className="newsfeedbackCard-span mt-1 mb-1">{t("News and announcements")}</span>
                <div style={{ height: 2, backgroundColor: '#cf2948', width: '100%', margin: 2 }}></div>

                <NewsFeedHtml _state1={_state1} showMore={showMore} />
                {/* <button className="btn btn-outline float-right" style={{ color: '#cf2948' }} onClick={() => showMoreToggle()}>
                  {showMore ? t('Show Less') : t('Show More')}
                </button> */}
              </div>

              <div className="scoreCountCard">
                <span className="newsfeedbackCard-span mt-1 mb-1">{t("Average NPS Score")}</span>
                <div style={{ height: 2, backgroundColor: '#cf2948', width: '100%', margin: 2 }}></div>
                {_state1.averageNpsScore &&
                  <div className="feedbackModalDiv">
                    <div>
                      <h1 className="scoreHeading" style={{ color: getNpsColor(_state1.averageNpsScore.avg_npsScore) }}>{!!_state1.averageNpsScore.avg_npsScore ? _state1.averageNpsScore.avg_npsScore.toFixed(2) : t("N/A")}</h1>
                      <p className="newsAndFeedbackSubtitle text-center">{t('NPS Score')}</p>
                    </div>
                  </div>}
              </div>
              <div className="scoreCountCard">
                <span className="newsfeedbackCard-span mt-1 mb-1">{t("Feedback sentiment score")}</span>
                <div style={{ height: 2, backgroundColor: '#cf2948', width: '100%', margin: 2 }}></div>
                {_state1.feedbackSentimentScore &&
                  <div className="feedbackModalDiv">
                    <div>
                      <h1 className="scoreHeading">{!!_state1.feedbackSentimentScore.avgtextAnalyticsScore ? _state1.feedbackSentimentScore.avgtextAnalyticsScore.toFixed(2) : t("N/A")}</h1>
                      <p className="newsAndFeedbackSubtitle text-center">{t('Feedback score')}</p>
                    </div>
                  </div>}
              </div>

              <div className=" feedbacksCard overflow-auto">
                <span className="newsfeedbackCard-span mt-1 mb-1">{t("Conversations with consumers")}</span>
                <div style={{ height: 2, backgroundColor: '#cf2948', width: '100%', margin: 2 }}></div>
                <LatestFeedbacks latestFeedbacks={latestFeedbacks} handleOnConversationButtonClick={handleOnConversationButtonClick} />
              </div>

              <div className="scoreCountCard overflow-auto">
                <span className="newsfeedbackCard-span mt-1 mb-1">{t("Tutorial of the day")}</span>
                <div style={{ height: 2, backgroundColor: '#cf2948', width: '100%', margin: 2 }}></div>
                <div className="m-1 mt-2">
                  {_state1.contentfulData && _state1.contentfulData.map((data, i) => (
                    <div dangerouslySetInnerHTML={{ __html: data.content }} key={i}>
                      {/* <iframe allowFullScreen className="m-1" width="100%" height="180" src={getVideoUrl(data.videoUrl)}> </iframe> */}
                    </div>
                  ))}
                </div>
              </div>
              <div className="scoreCountCard">
                <span className="newsfeedbackCard-span mt-1 mb-1">{t("Open feedback form")}</span>
                <div style={{ height: 2, backgroundColor: '#cf2948', width: '100%', margin: 2 }}></div>
                <div className="feedbackModalDiv">
                  <div className="m-1 mt-2">
                    <StSquareButton text={t("Open feedback form")} onClick={() => setOpenFeedbackModal(true)}></StSquareButton>
                    {showSuccessMsg && <p style={{ color: '#50C878', marginTop: 5 }}>{t('Feedback response submitted')}</p>}
                  </div>
                </div>
              </div>
            </div>

          </div>}
      </div>

      <Modal scrollable size="md" show={showConversationsView} onHide={() => setShowConversationsView(false)} centered={true}>
        <Modal.Header style={{ height: 40 }}>
          {latestFeedbacks && latestFeedbacks[selectedIndex] && <div className="d-flex flex-column feedback-modal-prodName">
            <span className="fw-bold">{latestFeedbacks[selectedIndex].productName}</span>
            <span className="prod_subtitle">{latestFeedbacks[selectedIndex].articleIdentifier}</span>
          </div>}
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => setShowConversationsView(false)}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body>
          {_feedbackState.conversationLoading ? <Spinner /> :
            <Fragment>
              <div ref={feedbackElementRef} className="mt-2">
                {!!feedbackConversationsResponse && feedbackConversationsResponse.map((data, i) => (
                  data.feedbackConversationFrom == "PDM_System" ?
                    <div className='d-flex align-items-center' key={i}>
                      <div><MdLaptopMac fontSize={26} style={{ color: '#6489a0' }} /></div>
                      <div style={{ backgroundColor: "#f8fcfd", color: '#6489a0', margin: 5, width: '60%', borderRadius: 20, padding: 5, border: '1px solid rgb(223, 237, 245' }}>
                        <span>{data.text}</span><br></br>
                        <span style={{ fontSize: 11 }}>{moment.unix(data.timestamp).format("YYYY-MM-DD hh:mm:ssA")}</span>
                      </div>
                    </div> :
                    <div className='d-flex justify-content-center align-items-center' key={i}>
                      <div style={{ backgroundColor: "#f8fcfd", color: '#6489a0', margin: 5, width: '60%', borderRadius: 20, padding: 5, marginLeft: '40%', border: '1px solid rgb(223, 237, 245' }}>
                        <span>{data.text}</span><br></br>
                        <span style={{ fontSize: 11 }}>{moment.unix(data.timestamp).format("YYYY-MM-DD hh:mm:ssA")}</span>
                      </div>
                      <div><MdPerson fontSize={26} style={{ color: '#6489a0' }} /></div>
                    </div>
                ))}
              </div>

            </Fragment>
          }
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <div className='product_feedback_modal'
            style={{ pointerEvents: latestFeedbacks && latestFeedbacks.length > 0 && latestFeedbacks[selectedIndex] && latestFeedbacks[selectedIndex].emailAvailable ? 'auto' : 'none' }}
          >
            <div className='form-group  col-lg-11 col-md-11 col-sm-11 col-xs-11'>
              <input type='text' placeholder='Enter something'
                className='product_feedback_modal_reply_input'
                value={feedbackReplyText}
                onChange={(e) => handleWriteMessage(e)}
                onKeyUp={(e) => (e.key === 'Enter' ? sendReplyToFeedback() : null)}
              />
            </div>

            <div className='form-group  col-lg-1 col-md-1 col-sm-1 col-xs-1' style={{ pointerEvents: feedbackReplyText ? 'auto' : 'none' }}>
              {_feedbackState.feedbackReplyLoading ?
                <div className="spinner-border text-secondary" role="status">
                  {" "}
                </div> :
                <MdSend className='product_feedback_modal_reply_button' onClick={sendReplyToFeedback} />
              }
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal size="md" centered={true} backdrop="static" show={openFeedbackModal} onHide={() => setOpenFeedbackModal(false)} >
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => setOpenFeedbackModal(false)}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal' }}>
          <div className='form-group col-12'>
            <label>{t("Your feedback")}</label>
            <textarea
              onChange={handleInputChange}
              type="textarea"
              name="productFeedbackText"
              className="feedbackText"
            />
            {/* <label>{t("Email")}</label>
                   <input name="email" onChange={handleInputChange} type="email" className="emailBox"></input> */}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton disabled={!!productFeedbackMessage?.productFeedbackText ? false : true} text={t("Submit")} loading={_state1.actionsLoading} onClick={() => handleSendFeedbackForProduct()}></StSquareButton>
          <StCancelSquareButton text={t("Cancel")} onClick={() => setOpenFeedbackModal(false)}></StCancelSquareButton>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default Dashboard;
