import React, { useContext, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearProductById, changeProdEditMode, changeFormObject, changeFromErrorObject, toggleProductHistoryDrawerFlag, setFirstProductObjFromProductList } from "src/redux/products/productsSlice";
import { clearEcomAllData } from "src/redux/company/companySlice";
import { getParticularCompanySearch, getCompanyInfo } from "src/redux/company/companyThunk";
import { getProductByIdAction, getProductTemplateSchemaAction } from "src/redux/products/productThunk";
import { toggleAiSuggestionsDrawerFlag } from "src/redux/scraping/scrapingSlice";
import { changeAllProductImagesData } from "src/redux/media/mediaSlice";
import { clearAiTranslationData } from "src/redux/aiData/aiDataSlice";
import ProductSidebar from "../components/ProductSideBar";
import foodPic from "../../../assets/images/Dummy_Product_Pic.png";
import qrcode from "../../../assets/images/qrcode.png";
import ProductProfileTabs from "./ProductProfileTabs";
import { MdClose, MdContentCopy } from 'react-icons/md';
import { BiCheckDouble } from "react-icons/bi";
import { Spinner } from "src/components/Spinner";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from 'react-router-dom';
import objectPath from "object-path";
import { AppContext } from "src/pages/AppContext";
import { EDIT } from 'src/helper/constants/constants'
import _ from "lodash";
import CustomTooltip from "src/components/inputComponents/CustomTooltip";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { StCloseButton } from 'src/components/StCloseButton';
import { StSquareButton } from 'src/components/StSquareButton';

const ProductProfile = () => {
  /** redux store useDispatch & useSelector ------------------------------ **/
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  let { gtin } = useParams();

  /** hooks useState ----------------------------------------------------- **/
  const _state = useSelector((state) => state.products, shallowEqual);
  const _compInfo = useSelector((state) => state.company.companyInfo, shallowEqual);
  const _scrapingState = useSelector((state) => state.scraping, shallowEqual);
  const { selectedProductSchema, productHistoryData, productHistoryModal } = _state
  const [copyText, setCopyText] = useState({})
  const [loading, setLoading] = useState(false)
  const [productNotFound, setProductNotFound] = useState(false);


  // variable declarations
  const productHistory = JSON.parse(localStorage.getItem(`product_history_${appContext?.authState?.uId}`)) || [];
  const isProductInHistory = productHistory.some(entry => entry.id === _state?.productById?.id);
  let newArray = [{ ..._state?.productById }, ...productHistory]
 

  useEffect(() => {
    getProductData_template()
    return () => {
      document.title = "UPIDS PDM"
      dispatch(changeFormObject({}));
      dispatch(changeProdEditMode(false));
      dispatch(changeFromErrorObject([]));
      dispatch(toggleAiSuggestionsDrawerFlag());
      dispatch(changeAllProductImagesData());
      dispatch(clearEcomAllData([]));
      dispatch(clearAiTranslationData([]));
      dispatch(clearProductById({}));
      dispatch(toggleProductHistoryDrawerFlag());
    };
  }, [gtin]);

  useEffect(() => {
    document.title = !!_state.productById ? `UPIDS PDM-${_state.productById.productName.en}` : "UPIDS PDM"
    if (!!_state.productById) {
      if (appContext.authState && appContext.authState.level === 10) {
        dispatch(getParticularCompanySearch({ businessid: objectPath.get(_.find(_state.productById.businessIdentifiers, function (o) { return o.type === "VATID" }), 'id', !!_state.productById.businessIdentifiers ? _state.productById.businessIdentifiers[0].id : null) }));
      } else {
        dispatch(getCompanyInfo());
      }
    }

    if (!isProductInHistory && !!_state?.productById) {
      localStorage.setItem(`product_history_${appContext?.authState?.uId}`, JSON.stringify(newArray));
    }

  }, [_state.productById])

  function usePromptUnsavedChanges(unsavedChanges) {
    const location = useLocation();

    useEffect(() => {
      const handleBeforeUnload = (event) => {
        if (unsavedChanges) {
          const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
          event.returnValue = confirmationMessage;
          return confirmationMessage;
        }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [unsavedChanges, location]);
  }


  const getProductData_template = async () => {
    setLoading(true)
    try {
      const productData = await dispatch(getProductByIdAction({ isLoader: true, gtin: gtin })).unwrap();
      await dispatch(getProductTemplateSchemaAction({ action: EDIT, productType: productData.responseData.productType }))
      setProductNotFound(false)
    } catch (error) {
      if (error?.response?.data?.error?.status == 404) {
        setProductNotFound(true)
      }
    }
    setLoading(false)
  }

  const getProductName = (o) => {
    if (!!o.productName) {
      if (!!o.productName.en && o.productName.en !== "") {
        return o.productName.en;
      } else {
        return o.productName.fi;
      }

    } else {
      return "";
    }
  }

  const handleAiSuggestionClose = () => {
    dispatch(toggleAiSuggestionsDrawerFlag());
  }

  const handleProductHistoryClose = () => {
    dispatch(toggleProductHistoryDrawerFlag());
  }

  function getLanguageFromCode(code) {
    switch (code) {
      case 'fi':
        return 'Finish'
      case 'sv':
        return 'Swedish'
      default:
        return 'English'
    }
  }

  const copyTextToClipboard = (desc, i) => {

    var textField = document.createElement('textarea');
    textField.innerText = desc;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    setCopyText({
      [i]: true,
    })

    setTimeout(() => {
      setCopyText({
        ...copyText, [i]: false,
      })
    }, 2000);
  }

  const getAiImageAnalysisDataResponse = () => {
    let analysisData = [];
    let primary = objectPath.get(_scrapingState, 'aiImageAnalysisPrimaryData');
    let frontal = objectPath.get(_scrapingState, 'aiImageAnalysisFrontalData');
    let backside = objectPath.get(_scrapingState, 'aiImageAnalysisBacksideData');
    let square = objectPath.get(_scrapingState, 'aiImageAnalysisSquareData');
    !!primary && analysisData.push({ ...primary[0], type: 'primary' });
    !!frontal && analysisData.push({ ...frontal[0], type: 'frontal' });
    !!backside && analysisData.push({ ...backside[0], type: 'backside' });
    !!square && analysisData.push({ ...square[0], type: 'square' });
    return analysisData;
  }

  const handleProductNameClick = (data) => {
    const currentPath = location.pathname;
    const productProfileExists = currentPath.includes('productProfile');

    if (data._2an && productProfileExists) {
      dispatch(changeProdEditMode(true));
      navigate(`/productProfile/${data._2an}`);
    } else if (data.gtin && productProfileExists) {
      dispatch(changeProdEditMode(true));
      navigate(`/productProfile/${data.gtin}`);
    }
  }

  const handleCopyMarketingData = async (copyMarketingData) => {
    let existingObject = { ..._state.formObject };
    let newObject = { ...copyMarketingData };

    // List of properties to delete 
    const propertiesToDelete = [
      'brandName',
      'businessIdentifiers',
      'companyId',
      'countryCode',
      'createdDate',
      'description',
      'descriptionShort',
      'gtin',
      'id',
      'modifiedDate',
      'private',
      'productName',
      'productType',
      'profileActive',
      'regulatedProductName',
      '_2an',
      'dataInjectorVersion',
      'productHierarchyType',
      'dataSource'
    ];

    Object.keys(newObject).forEach(key => {
      if (propertiesToDelete.includes(key)) {
        delete newObject[key];
      }
    });

    // Check if newObject has any remaining keys
    const remainingKeys = Object.keys(newObject);

    // Concate existing and new object for product
    const destinationObject = { ...existingObject, ...newObject };
    await dispatch(changeFormObject({ ...destinationObject }))
    if (remainingKeys?.length > 0) {
      toast.success(t("Sucessfully Copied"))
    } else {
      toast.error(t("No Data Found To Copy"))
    }

  }

  const handleDeleteProductFromLocalStorage = () => {
    // Filter the records whose product data is not found
    const filteredArray = _.filter((productHistory || []), (d) => !(d?._2an === gtin || d?.gtin === gtin)) || [];

    // Save the updated product history back to localStorage
    localStorage.setItem(`product_history_${appContext?.authState?.uId}`, JSON.stringify(filteredArray));
    navigate("/dashboard");
    setProductNotFound(false)

  }

  const shouldBlockNavigation = _state.isProdEditMode && _state.formObject.isChange === 1;

  usePromptUnsavedChanges(shouldBlockNavigation);

  return (
    <>
      {loading && <Spinner />}
      {!!_state.productById && (
        <div className="row">
          <ProductSidebar
            prod_image={
              !!_state.productById.multimedia && _state.productById.multimedia.primary ? _state.productById.multimedia.primary[0] :
                !!_state.productById.media &&
                  _state.productById.media.images[1024][0] !== "" &&
                  _state.productById.media.images[1024][0] !== null
                  ? _state.productById.media.images[1024][0]
                  : foodPic
            }
            prod_title={getProductName(_state.productById)}
            prod_subtitle={
              _state.productById.descriptionShort
                ? _state.productById.descriptionShort.en
                : ""
            }
            prod_desc={_state.productById.brandName}
            prod_status="Published"
            productData={_state.productById}
            prod_qrcode={qrcode}
          />

          {!!_state.productById && !!_compInfo && selectedProductSchema &&
            selectedProductSchema.productTabStructuredSchema && selectedProductSchema.productTabStructuredSchema[0] &&
            <ProductProfileTabs
              productData={_state.productById}
              selectedProductSchema={selectedProductSchema}
            />}

          {/* AI SUGGESTIONS DRAWER */}
          <div style={{ overflow: 'scroll' }} className={_scrapingState.aiSuggestionsDrawerToggle ? 'product-profile ai-suggestions-show' : 'product-profile ai-suggestions-hide'}>
            <span className="close-text" onClick={handleAiSuggestionClose}>
              <MdClose style={{ margin: 5, color: 'red', position: 'absolute', right: 0 }} fontSize={24} fontWeight={'bold'} />
            </span>
            <h4 style={{ padding: 25 }}>{t("AI Suggestions")}</h4>
            {!!_scrapingState.scrapingProductDescriptions && _scrapingState.scrapingProductDescriptions.length > 0 ? _scrapingState.scrapingProductDescriptions[0].map((data, i) => (
              <><div key={i} style={{ padding: 10 }} className='form-group'>
                <div style={{ color: 'black', marginLeft: 10, display: 'flex' }}>
                  <div>{getLanguageFromCode(data.lang)}</div>
                  <div>
                    {copyText[i] ?
                      <div>
                        <BiCheckDouble style={{ marginLeft: 5 }} fontSize={18} />
                        <span style={{ fontSize: 9, color: 'grey' }}> {t("Copied to clipboard")}</span>
                      </div> :
                      <MdContentCopy style={{ cursor: 'pointer', marginLeft: 5 }} fontSize={18} fontWeight={'bold'} onClick={() => copyTextToClipboard(data.desc + data.lang, i)} />
                    }
                  </div>
                </div>
                <textarea style={{ height: 100 }} cols={6} readonly={''} type="text" value={data.desc + data.lang} />
              </div></>
            )) :
              <div>
                <span style={{ marginLeft: 20 }} className="product-profile passive-message">{t("No suggestions found")}</span>
              </div>
            }
          </div>


          {/* PRODUCT HISTORY DRAWER */}
          <div style={{ overflow: 'scroll' }} className={productHistoryModal ? 'product-profile ai-suggestions-show' : 'product-profile ai-suggestions-hide'}>
            <span className="close-text" onClick={handleProductHistoryClose}>
              <MdClose style={{ margin: 5, color: 'red', position: 'absolute', right: 0 }} fontSize={24} fontWeight={'bold'} />
            </span>
            <h4 style={{ padding: 25 }}>{t("Product History")}</h4>
            <div style={{ marginBottom: 90 }}>
              {!!productHistoryData && productHistoryData.length > 0 ? productHistoryData?.map((data, i) => (
                <><div key={i} style={{ padding: 10, borderBottom: "1px solid #c8dbe6" }} className='form-group'>
                  <div className="d-flex flex-row justify-content-between">
                    <div onClick={() => handleProductNameClick(data)} style={{ cursor: "pointer" }}>{data.productName?.en}</div>
                    <div className="d-flex flex-row">
                      <MdContentCopy style={{ color: "#cf2948", cursor: "pointer" }} onClick={() => handleCopyMarketingData(data)} />
                      <CustomTooltip
                        tooltipContent={<p>{t("Copy marketing data from selected product to currently open product")}</p>}
                      />
                    </div>
                  </div>
                </div>
                </>
              )) :
                <div>
                  <span style={{ marginLeft: 20 }} className="product-profile passive-message">{t("No products found")}</span>
                </div>
              }
            </div>
          </div>

          {/* AI IMAGE ANALYSIS DRAWER */}
          <div className={_scrapingState.aiImageAnalysisDrawerToggle ? 'product-profile ai-suggestions-show' : 'product-profile ai-suggestions-hide'} style={{ overflow: 'scroll' }}>
            <span className="close-text" onClick={handleAiSuggestionClose}>
              <MdClose style={{ margin: 5, color: 'red', position: 'absolute', right: 0 }} fontSize={24} fontWeight={'bold'} />
            </span>
            <h4 style={{ padding: 25 }}>{t("AI Image Analysis")}</h4>
            {!!_scrapingState && getAiImageAnalysisDataResponse().map((data, i) => (
              <div key={i} style={{ padding: 10 }} className='form-group'>
                <div style={{ color: 'black', marginLeft: 10, display: 'flex' }}>
                  <div>{data.type}</div>
                  <div>
                    {copyText[i] ?
                      <div>
                        <BiCheckDouble style={{ marginLeft: 10 }} fontSize={18} />
                        <span style={{ fontSize: 9, color: 'grey' }}> {t("Copied to clipboard")}</span>
                      </div> :
                      <MdContentCopy style={{ cursor: 'pointer', marginLeft: 10 }} fontSize={18} fontWeight={'bold'} onClick={() => copyTextToClipboard(data.text, i)} />
                    }
                  </div>
                </div>
                <textarea style={{ height: 150 }} cols={6} readonly={''} type="text" value={data.text} />
              </div>
            ))}
            {_scrapingState.setAiImageAnalysisEmpty && <div>
              <span style={{ marginLeft: 20 }} className="product-profile passive-message">{t("No Ai Image Analysis")}</span>
            </div>}
          </div>
        </div>
      )}

      <Modal size="md" centered={true} backdrop="static" show={productNotFound} onHide={() => handleDeleteProductFromLocalStorage()} >
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleDeleteProductFromLocalStorage()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal' }}>
          <div className="row col-12 m-0 justify-content-center">
            {t("productNotFound")} with id {gtin}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'space-around' }}>
          <StSquareButton text={t("Ok")} onClick={() => handleDeleteProductFromLocalStorage()}></StSquareButton>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default ProductProfile;
