import { createSlice } from "@reduxjs/toolkit";
import {
  getProductDescriptions, getAiImageAnalysisPrimary, getAiImageAnalysisFrontal,
  getAiImageAnalysisBackside, getAiImageAnalysisSquare
} from "./scrapingThunk";

const initialScrapingState = {
  actionsLoading: false,
  response: null,
  error: null,
  isAllDataFetchedLoading: false,
  isLoading: false,
  aiSuggestionsDrawerToggle: false,
  setAiImageAnalysisEmpty: false
};

const scrapingSlice = createSlice({
  name: "scraping",
  initialState: initialScrapingState,
  reducers: {
    toggleAiSuggestionsDrawerFlag: (state, action) => {
      state.aiSuggestionsDrawerToggle = false
      state.aiImageAnalysisDrawerToggle = false;
      // state.aiImageAnalysisPrimaryData = null;
      // state.aiImageAnalysisFrontalData = null;
      // state.aiImageAnalysisBacksideData = null;
      // state.aiImageAnalysisSquareData = null;
      // state.setAiImageAnalysisEmpty = false
    },

    setAiImageAnalysisEmpty: (state, action) => {
      state.aiImageAnalysisDrawerToggle = true;
      state.setAiImageAnalysisEmpty = true
    }

  },




  extraReducers:
    (builder) => {
      builder
        .addCase(getProductDescriptions.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getProductDescriptions.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.aiSuggestionsDrawerToggle = true;
          state.error = null;
          state.scrapingProductDescriptions = action.payload;
        })
        .addCase(getProductDescriptions.rejected, (state, action) => {
          state.actionsLoading = false;
        })



        .addCase(getAiImageAnalysisPrimary.pending, (state) => {
          state.loadingImageAnalysis = true
        })
        .addCase(getAiImageAnalysisPrimary.fulfilled, (state, action) => {
          state.loadingImageAnalysis = false;
          state.aiImageAnalysisDrawerToggle = true;
          state.error = null;
          state.setAiImageAnalysisEmpty = false
          state.aiImageAnalysisPrimaryData = action.payload;
        })
        .addCase(getAiImageAnalysisPrimary.rejected, (state, action) => {
          state.loadingImageAnalysis = false;
        })


        .addCase(getAiImageAnalysisFrontal.pending, (state) => {
          state.error = null;
          state.loadingImageAnalysis = true
        })
        .addCase(getAiImageAnalysisFrontal.fulfilled, (state, action) => {
          state.loadingImageAnalysis = false;
          state.aiImageAnalysisDrawerToggle = true;
          state.error = null;
          state.setAiImageAnalysisEmpty = false
          state.aiImageAnalysisFrontalData = action.payload;
        })
        .addCase(getAiImageAnalysisFrontal.rejected, (state, action) => {
          state.loadingImageAnalysis = false;
        })


        .addCase(getAiImageAnalysisBackside.pending, (state) => {
          state.error = null;
          state.loadingImageAnalysis = true;
        })
        .addCase(getAiImageAnalysisBackside.fulfilled, (state, action) => {
          state.loadingImageAnalysis = false;
          state.aiImageAnalysisDrawerToggle = true;
          state.error = null;
          state.setAiImageAnalysisEmpty = false
          state.aiImageAnalysisBacksideData = action.payload;
        })
        .addCase(getAiImageAnalysisBackside.rejected, (state, action) => {
          state.loadingImageAnalysis = false;
        })


        .addCase(getAiImageAnalysisSquare.pending, (state) => {
          state.error = null;
          state.loadingImageAnalysis = true;
        })
        .addCase(getAiImageAnalysisSquare.fulfilled, (state, action) => {
          state.loadingImageAnalysis = false;
          state.aiImageAnalysisDrawerToggle = true;
          state.setAiImageAnalysisEmpty = false
          state.error = null;
          state.aiImageAnalysisSquareData = action.payload;
        })
        .addCase(getAiImageAnalysisSquare.rejected, (state, action) => {
          state.loadingImageAnalysis = false;
        })

    },
});


export const { toggleAiSuggestionsDrawerFlag, setAiImageAnalysisEmpty } = scrapingSlice.actions;


export default scrapingSlice;
