import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeUsersFormObjectAction, usersAllErrorsFormObject } from "src/redux/users/usersSlice";
import { getCompany } from 'src/redux/company/companyThunk';
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import { getAllTimeZones } from "src/helper/timezones";
import AddUserImage from "../../../assets/images/addUser.png";
import { useStateCallback } from "../../../helper/CustomHook";
import { generatePassword } from "../../../helper/helpers";
import _ from "lodash";
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel"
import { InputSelectDropdown } from "src/components/inputComponents/InputSelectDropdown";
import { InputSwitchWithLable } from "src/components/inputComponents/InputSwitchWithLable";
import { userRoleOptions } from "src/helper/MetadataCollection";
import { userSchema } from "src/helper/yup-schema";
import * as yup from "yup";

const AddUser = () => {
  const [checked, setChecked] = useStateCallback(false);
  const [sendInvitation, setSendInvitation] = useStateCallback(false);
  let timezoneValue;
  let BusinessIdentifiers;
  const allTimezones = getAllTimeZones();
  let selectTimezoneOption = [];
  let selectCompanyOption = [];
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const _state = useSelector((state) => state.users, shallowEqual);
  const _companyState = useSelector((state) => state.company, shallowEqual);
  const [touchedState, setTouchedState] = useState(true)

  const companyNameList = _companyState.companyList

  const errorIs = _state.error;
  let formObject = _state.formObject;
  let AllErrors = _state.ErrorsformObject;

  useEffect(() => {
    dispatch(getCompany({}));
    return () => {
      dispatch(changeUsersFormObjectAction({}));
      dispatch(usersAllErrorsFormObject([]));
    };
  }, []);

  const isAdminFlagSetToTrue = !!formObject.admin && formObject.admin === true ? true : false;

  const selectUserTypeOptions = [
    {
      key: 1,
      text: 'SuperAdmin',
      value: '10',
    },
    {
      key: 2,
      text: 'Company User',
      value: '1',
    },
    {
      key: 3,
      text: 'Normal User',
      value: '0',
      disabled: isAdminFlagSetToTrue
    },
  ]

  const createDropdownDataForTimezone = (allTimezones) => {
    selectTimezoneOption = [];
    allTimezones.map((o, index) => {
      let eve = { key: index, text: o.text, value: o.abbr };
      selectTimezoneOption.push(eve);
    });
  };
  createDropdownDataForTimezone(allTimezones);


  const createDropdownDataForCompany = (companyNameList) => {
    if (!!companyNameList && companyNameList[0]) {
      companyNameList.map((o, index) => {
        let eve = { key: index, text: o.name, value: o.id };
        selectCompanyOption.push(eve);
      });
    }
  };
  createDropdownDataForCompany(companyNameList);

  const handleTimezoneSelect = (e) => {
    const value = e.target.value
    if (!!value) {
      timezoneValue = allTimezones.find(({ abbr }) => abbr === value);
      dispatch(
        changeUsersFormObjectAction({
          ...formObject,
          offset: timezoneValue.offset.toString(),
          timezone: value,
        })
      );
      AllErrors = _.filter((_state.ErrorsformObject) || [], (d) => (d?.path !== e.target.name)) || []
      dispatch(usersAllErrorsFormObject(AllErrors));
    }
  };

  const handleCompanySelect = (e) => {
    const value = e.target.value
    if (!!value) {
      let allCompanyInfo = companyNameList.find(e1 => e1.id === value);
      let allBusinessIdentifiers = allCompanyInfo?.businessIdentifiers;
      dispatch(
        changeUsersFormObjectAction({
          ...formObject,
          companyId: value,
          companyName: allCompanyInfo?.name,
          companies: allBusinessIdentifiers,
          language: "",
        })
      );
      AllErrors = _.filter((_state.ErrorsformObject) || [], (d) => (d?.path !== e.target.name)) || []
      dispatch(usersAllErrorsFormObject(AllErrors));
    }
  };
  const handleUserTypeSelect = (e) => {
    const value = e.target.value
    if (!!value) {
      dispatch(
        changeUsersFormObjectAction({
          ...formObject,
          userLevel: value

        })
      );
    }
  };

  const toggleChecked = () => {
    setChecked(!checked, (checked) => {
      dispatch(changeUsersFormObjectAction({ ...formObject, admin: checked }));
    });
  };

  const handleFormChange = (e) => {
    if (e.target.name !== "language") {
      validator({ name: e.target.name, value: e.target.value });
    }
    dispatch(
      changeUsersFormObjectAction({
        ...formObject,
        [e.target.name]: e.target.value.trim(),
      })
    );
    setTouchedState(true)
  };

  const validator = ({ name, value }) => {
    const existingErrorIndex = AllErrors.findIndex(
      (error) => error.path === name
    );
    let newErrors = [...AllErrors];
    if (name === "retypePassword") {
      if (!value) {
        newErrors.push({ path: name, message: "Confirm password is required" });
      } else if (value !== formObject.newPassword) {
        newErrors.push({ path: name, message: "Password must match" });
      } else {
        newErrors = AllErrors.filter((error) => error.path !== name);
      }
      dispatch(usersAllErrorsFormObject(newErrors));
    } else {
      yup
        .reach(userSchema, name)
        .validate(value)
        .then((valid) => {
          if (newErrors.length > 0) {
            newErrors = newErrors.filter((error) => error.path !== name);
            dispatch(usersAllErrorsFormObject(newErrors));
          }
        })
        .catch((err) => {
          if (existingErrorIndex !== -1) {
            newErrors[existingErrorIndex] = {
              path: name,
              message: err.message,
            };
          } else {
            newErrors.push({ path: name, message: err.message });
          }
          dispatch(usersAllErrorsFormObject(newErrors));
        });
    }
  };

  const errorShown = (AllErrors) => {
    const errorObject = {}
    AllErrors &&
      AllErrors[0] &&
      AllErrors.forEach((obj, index) => {
        errorObject[obj.path] = obj.message;
      });
    return errorObject
  };

  const errorObject = errorShown(AllErrors);

  const handleGeneratePassword = () => {
    const generatedPassword = generatePassword(16);
    AllErrors = _.filter((_state.ErrorsformObject) || [], (d) => (d?.path !== "newPassword" && d?.path !== "retypePassword")) || []
    dispatch(usersAllErrorsFormObject(AllErrors));
    dispatch(changeUsersFormObjectAction({ ...formObject, newPassword: generatedPassword, retypePassword: generatedPassword }));
  };

  const toggleSendInvitation = () => {
    setSendInvitation(!sendInvitation, (sendInvitation) => {
      dispatch(changeUsersFormObjectAction({ ...formObject, sendInvitation: sendInvitation }));
    });
  };

  const handleUserRoleSelect = (e, { value }) => {
    if (!!value) {
      dispatch(changeUsersFormObjectAction({ ...formObject, userRole: value }))
    } else {
      dispatch(changeUsersFormObjectAction({ ...formObject, userRole: 'Not-set' }));
    }
  }


  const getCurrentComapnyLanguage = (viewData, companyNameList) => {
    let language = [];
    const businessId = viewData && viewData.companyId && viewData.companyId
    const currentCompanyObj = companyNameList && companyNameList[0] && companyNameList.find((item) => item.id === businessId)
    const supportedLanguage = currentCompanyObj && currentCompanyObj.supportedLanguages;
    supportedLanguage && supportedLanguage[0] && supportedLanguage.map((o, index) => {
      let eve = { key: index, label: o.name, value: o.code };
      language.push(eve)
    })
    return language
  }

  const selectCompanyLanguage = getCurrentComapnyLanguage(formObject, companyNameList)

  return (
    <>
      <div className="row add-user main-container">
        <div className="add-user sidebar-container col-xl-3 col-xxl-4 col-md-4">
          <img alt="alt"
            src={AddUserImage}
            className="add-user profile-image"
          ></img>
          <span className="add-user role-title" > User Roles </span>
          <div className="add-user permission-card" >
            <div className="d-flex">
              <span className="add-user role-subtitle col-7">Admin</span>
              <div className="mt-2 col-5 d-flex justify-content-end" style={{ right: 45 }}>
                <InputSwitchWithLable
                  onHandleColor='#32cd32'
                  offHandleColor='#ffffff'
                  onColor='#32cd32'
                  offColor='#6c757d'
                  onChange={toggleChecked}
                  value={checked}
                />
              </div>
            </div>
            {!!errorObject && errorObject.admin && (
              <div className="text-danger add-user error-text">{errorObject.admin}</div>
            )}

            <span className="add-user description-text">
              Allow admin access to this user so that they can add or edit profile details.
            </span>
          </div>

          <span className="add-user role-title" > {t("User role")} </span>
          <div className="d-flex m-2">
            <Dropdown
              value={!!formObject && formObject.userRole}
              selection fluid
              onChange={handleUserRoleSelect}
              options={userRoleOptions}
              placeholder={t("Select user role")}
            // disabled={_state.isUserEditMode ? false : true}
            />
          </div>

          <span className="add-user role-title">Options</span>
          <div className="add-user permission-card">
            <div className="d-flex">
              <span className="add-user role-subtitle col-7">Send invitation email</span>
              <div className="mt-2 col-5 d-flex justify-content-end" style={{ right: 45 }}>
                <InputSwitchWithLable
                  onHandleColor='#32cd32'
                  offHandleColor='#ffffff'
                  onColor='#32cd32'
                  offColor='#6c757d'
                  onChange={toggleSendInvitation}
                  value={sendInvitation}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="add-user form-container col-xl-9 col-xxl-8 col-md-8">
          <form >
            <div className="row col-12" >

              <InputTextWithLabel
                label={'Email Address'}
                tooltipText={t("Email Address")}
                name={'email'}
                value={formObject.email}
                type="text"
                placeholder={"Email"}
                onChange={handleFormChange}
                touched={touchedState}
                errorMessage={!!errorObject && errorObject.email ? errorObject.email : ""}
              />


              <InputTextWithLabel
                label={'First Name'}
                tooltipText={t("First Name")}
                name={'foreName'}
                value={formObject.foreName}
                type="text"
                placeholder={"First Name"}
                onChange={handleFormChange}
                touched={touchedState}
                errorMessage={!!errorObject && errorObject.foreName ? errorObject.foreName : ""}
              />


              <InputTextWithLabel
                label={'Last Name'}
                tooltipText={t("Last Name")}
                name={'sureName'}
                value={formObject.sureName}
                type="text"
                placeholder={"Last Name"}
                onChange={handleFormChange}
                touched={touchedState}
                errorMessage={!!errorObject && errorObject.sureName ? errorObject.sureName : ""}
              />


              <InputSelectDropdown
                label={"Company"}
                tooltipText={"Company"}
                name="companyId"
                placeholder="Select Company Name"
                search={true}
                value={formObject.companyId}
                options={selectCompanyOption}
                onChange={handleCompanySelect}
                touched={touchedState}
                errorMessage={!!errorObject && errorObject.companyId ? errorObject.companyId : ""}
              />


              <InputTextWithLabel
                label={'Phone'}
                tooltipText={t("Phone")}
                name={'phoneNumber'}
                value={formObject.phoneNumber}
                type="text"
                placeholder={"Phone"}
                onChange={handleFormChange}
                touched={touchedState}
                errorMessage={!!errorObject && errorObject.phoneNumber ? errorObject.phoneNumber : ""}
              />


              <InputSelectDropdown
                label={"Timezone"}
                tooltipText={"Timezone"}
                name="timezone"
                placeholder="Select Timezone"
                search={true}
                value={formObject.timezone}
                options={selectTimezoneOption}
                onChange={handleTimezoneSelect}
                touched={touchedState}
                errorMessage={!!errorObject && errorObject.timezone ? errorObject.timezone : ""}
              />


              <InputSelectDropdown
                label={t("Communication language")}
                tooltipText={t("Communication language")}
                placeholder={t("Select Language")}
                search={true}
                name="language"
                value={formObject.language}
                options={selectCompanyLanguage && selectCompanyLanguage}
                onChange={handleFormChange}
              />


              <InputTextWithLabel
                label={'New password'}
                tooltipText={t("New password")}
                name={'newPassword'}
                value={formObject.newPassword}
                type="text"
                placeholder={"New password"}
                onChange={handleFormChange}
                touched={touchedState}
                errorMessage={!!errorObject && errorObject.newPassword ? errorObject.newPassword : ""}
              />


              <InputTextWithLabel
                label={'Retype Password'}
                tooltipText={t("Retype Password")}
                name={'retypePassword'}
                value={formObject.retypePassword}
                type="text"
                placeholder={"Retype Password"}
                onChange={handleFormChange}
                touched={touchedState}
                errorMessage={!!errorObject && errorObject.retypePassword ? errorObject.retypePassword : ""}
              />


              <div className="form-group col-12">
                <button type="button" className="confirm_button" onClick={handleGeneratePassword}>{t("Generate password")}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddUser;
