import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';
// import * as _ from 'lodash'



export const getAccountTransactionsAction = createAsyncThunk(
    "account/getAccountTransactionsAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/integrations/stripe/transactions?businessId=${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getAccountInvoicesAction = createAsyncThunk(
    "account/getAccountInvoicesAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/services/invoice/businessid/${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getAccountCountAction = createAsyncThunk(
    "account/getAccountCountAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/masterdata/products/businessid/count/${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const paymentByVoucherCodeAction = createAsyncThunk(
    "account/paymentByVoucherCodeAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().put(
                `/services/voucher/attempt`, arg
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getAccountBillingAction = createAsyncThunk(
    "account/getAccountBillingAction",
    async (arg, thunkApi) => {
        try {
            const { company: companyState } = thunkApi.getState()
            const { companyInfo } = companyState

            let businessId;
            const businessIdObj = companyInfo.businessIdentifiers.find(
                obj => obj.type === "VATID",
            );

            if (businessIdObj) {
                businessId = businessIdObj.id;
            } else {
                businessId = companyInfo.businessIdentifiers[0].id;
            }

            const billingActions = [getAccountTransactionsAction, getAccountInvoicesAction, getAccountCountAction]

            await Promise.all(billingActions.map(async (item) => {
                await thunkApi.dispatch(item(businessId)) // arg = businessId
            }))

            return true;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);