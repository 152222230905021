import React,{ useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import IconButton from "../../commonComponent/Icons/Icons";
import Modal from "react-bootstrap/Modal";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { usePrevious } from "../../../helper/CustomHook";
import { MdClose,MdMenu } from "react-icons/md";
import logo from '../../../assets/images/brand_icons/upids-pdm-logo.png'
import {Header} from "src/components/Header"

const DocumentationNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const [showDelPopup, setShowDelPopup] = useState(false);

  let fullUrl = window.location.href;
  let id = fullUrl.split("/").pop();

  const _state = useSelector((state) => state.feedback, shallowEqual);

  const {  deleteResponse } = _state;
  const prevPropsState = usePrevious({  deleteResponse });

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  return (
    <>
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/dashboard">
          <img src={logo} alt="logo" />
        </Link>
        <Link
          className="navbar-brand brand-logo-mini d-inline-block d-lg-none"
          to="/"
        >
          <img
            src={require("../../../assets/images/logo-mini.svg")}
            alt="logo"
          />
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center">          
          <span><Header text={t("Documentation")}/></span>
          
        <div className="navbar-nav navbar-nav-right">
        <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
             <MdMenu color="#000000" size={24} />
          </button>
        </div>

      </div>
    </nav>
</>
  );
};

export default DocumentationNavbar;
