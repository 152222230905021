import React, { useEffect, useState } from "react";
import food_pic from '../../../assets/images/Dummy_Product_Pic.png';
import appConfig from "src/helper/appConfig";
import { useTranslation } from "react-i18next";
import { BiDownload, BiPrinter } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import { IconButton } from "src/components/IconButton";
import { StSquareButton } from "src/components/StSquareButton";
import { StCloseButton } from "src/components/StCloseButton";
import { printJobTypes } from "../../../helper/MetadataCollection";
import { QRCodeCanvas } from 'qrcode.react';
import moment from 'moment';
// import logo from '../../../assets/images/upids_io.svg';
import logo from '../../../assets/svg/upidsIo.svg';
import _ from 'lodash';
import { useSelector, shallowEqual } from "react-redux";

const printTypesInstructions = {
  avery_3475: ["In print settings set scaling to 100%", "Set margins to “None” or zero.", "Do test print on normal paper and view that overlaying the pre-cut label sheet to see that labels are correctly aligned."],
}
function ProductSideBar(props) {
  const { t } = useTranslation('common');

  const _state = useSelector((state) => state.products, shallowEqual);

  const [imgsrc, setImgSrc] = useState('')
  const [upidsIdGs1ImgSrc, setUpidsIdGs1ImgSrc] = useState('')

  const [showPrintModal, setShowPrintModal] = useState(false)
  const [printJobType, setPrintJobType] = useState('')

  function addDefaultSrc(ev) {
    ev.target.src = food_pic
  }

  const digitalLinkObj = _.find(_state?.selectedProductSchema?.productFlattenSchema || [], (d) => d.type == "gs1DigitalLinkStructure")


  useEffect(() => {
    var img1 = document.getElementById('img1');
    var img2 = document.getElementById('img2');
    var canvas = document.createElement('canvas')

    var context = canvas.getContext('2d')
    canvas.width = 900;
    canvas.height = 1200;
    context.fillStyle = '#ffffff'
    context.fillRect(0, 0, 900, 1200)
    context.globalAlpha = 1.0;
    context.drawImage(img1, 50, 50, 800, 800);

    var imageObj1 = new Image();
    imageObj1.src = logo
    imageObj1.onload = function () {
      context.globalAlpha = 1.0;
      context.drawImage(imageObj1, 50, 950, 800, 200);

      let dataURL = canvas.toDataURL("image/png");
      setImgSrc(dataURL)
    }

    if (!!props.upidsIdData && props.upidsIdData.IdentifierType == "gtin") {
      createUpidsIdGs1DigitalLinkQrCode()
    }
  }, []);

  const createUpidsIdGs1DigitalLinkQrCode = () => {
    var img1 = document.getElementById('upidsImgQrcode');
    var img2 = document.getElementById('upidsImg');
    var canvas = document.createElement('canvas')

    var context = canvas.getContext('2d')
    canvas.width = 900;
    canvas.height = 1200;
    context.fillStyle = '#ffffff'
    context.fillRect(0, 0, 900, 1200)
    context.globalAlpha = 1.0;
    context.drawImage(img1, 50, 50, 800, 800);

    var imageObj1 = new Image();
    imageObj1.src = logo
    imageObj1.onload = function () {
      context.globalAlpha = 1.0;
      context.drawImage(imageObj1, 50, 950, 800, 200);

      let dataURL = canvas.toDataURL("image/png");
      setUpidsIdGs1ImgSrc(dataURL)
    }
  }

  const downloadQRCode = () => {
    let productId = !!props.isUpidsProd ? props.prod_upids : (props.productData.gtin == undefined || props.productData.gtin == "") ? props.productData._2an : props.productData.gtin
    let dataURL = imgsrc;
    var link = document.createElement("a");
    link.download = `QR_DOWNLOAD_${productId}`;
    link.href = dataURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handlePrintJobTypeChanged = (e, data) => {
    setPrintJobType(data.value)
  }
  const handlePrintTypeOkClick = () => {
    let productId = !!props.isUpidsProd ? props.prod_upids : (props.productData.gtin == undefined || props.productData.gtin == "") ? props.productData._2an : props.productData.gtin
    // window.open(`http://localhost:3001/print.html?id=${productId}`,'_blank');
    window.open(`${appConfig.pdmUrl}/print.html?id=${productId}/`, '_blank');
    setShowPrintModal(false)
    setPrintJobType('')
  }

  const closeShowPrintModal = () => {
    setShowPrintModal(false)
    setPrintJobType('')
  }

  const getUpidsIdGs1DigitalLink = (upidsData) => {
    let upidsIdGs1DigitalLink;
    let upidsproductExpiresData = moment.unix(upidsData.productExpires).format('YYMMDD');
    let upidsIdArray = upidsData.upidsId.split("-")

    const urlParams = digitalLinkObj?.value?.urlStructureList.map((item) => {
      if (item?.name === "gtin") {
        return upidsIdArray[0];
      } else if (item?.name === "upidsCodeSegment2") {
        return upidsIdArray[1];
      } else if (item?.name == "upidsCodeSegment3") {
        return upidsIdArray[2]
      } else if (item?.name === "expiryDate") {
        return upidsproductExpiresData;
      } else {
        return item.value || "";
      }
    });
    upidsIdGs1DigitalLink = urlParams?.join('');

    return `${appConfig.b2cAppUrl}${upidsIdGs1DigitalLink}`

  }

  const getUpidsUrlByGtin = () => {
    let productUrl = '';
    if (!!props.isUpidsProd) {
      productUrl = `${appConfig.b2cAppUrl}${props.prod_upids}`
    } else if (_.has(props.productData, `gtin`)) {
      productUrl = `${appConfig.b2cAppUrl}01/${_.get(props.productData, `gtin`, '')}`
    } else {
      productUrl = `${appConfig.b2cAppUrl}${_.get(props.productData, `_2an`, '')}`
    }
    return productUrl
  }

  return (
    <>
      <div style={{ paddingRight: 0 }} className="col-xl-3 col-xxl-4 col-md-12 productsidebar">
        <div className="col-md-5 col-sm-5 col-5 col-xl-12" style={{ padding: 17 }}>
          <img alt="alt" onError={addDefaultSrc} className="imgTag " src={props.prod_image}></img>
        </div>
        <div style={{ height: 1, backgroundColor: '#DFEDF5', width: '100%' }}></div>
        <div className="col-md-7 col-sm-7 col-7 col-xl-12 mt-4">
          <span className="title">{props.prod_title}</span>
          <span className="subtitle">{props.prod_subtitle}</span>
          <span className="subtitle">{props.prod_desc}</span>

          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 15, marginBottom: 20 }}>
            <QRCodeCanvas id='img1' size={1000} style={{ margin: 'auto', alignSelf: 'center', position: 'absolute', display: 'none' }} value={!!props.isUpidsProd ? `${appConfig.b2cAppUrl}${props.prod_upids}` : `${appConfig.b2cAppUrl}${(props.productData.gtin == undefined || props.productData.gtin == "") ? props.productData._2an : '01/' + props.productData.gtin}`} />
            <img id='img2' src={logo} style={{ margin: 'auto', alignSelf: 'center', position: 'absolute', display: 'none' }}></img>

            <img src={imgsrc} style={{ margin: 'auto', alignSelf: 'center', height: 140 }} ></img>

            <a href={getUpidsUrlByGtin()}
              rel="noopener noreferrer" target="_blank"
              className="subtitle" style={{ cursor: 'pointer', fontSize: 12, fontWeight: 'normal', overflowWrap: 'break-word' }}
            >
              {getUpidsUrlByGtin()}
            </a>

            <div style={{ alignSelf: 'center', marginTop: 10 }}>
              <IconButton
                text={t("Get QRCode")}
                icon={BiDownload}
                onClick={() => downloadQRCode()}
              />
            </div>

            <div style={{ alignSelf: 'center', marginTop: 15 }}>
              <IconButton
                text={t("Print Labels")}
                icon={BiPrinter}
                onClick={() => setShowPrintModal(true)}
              />
            </div>

          </div>

          {!!props.upidsIdData && props.upidsIdData.IdentifierType == "gtin" &&
            <>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 15, marginBottom: 20 }}>
                <div style={{ height: 1, backgroundColor: '#DFEDF5', width: '100%', marginBottom: 15 }}></div>
                <QRCodeCanvas id='upidsImgQrcode' size={1000} style={{ margin: 'auto', alignSelf: 'center', position: 'absolute', display: 'none' }} value={getUpidsIdGs1DigitalLink(props.upidsIdData)} />
                <img id='upidsImg' src={logo} style={{ margin: 'auto', alignSelf: 'center', position: 'absolute', display: 'none' }}></img>

                <img src={upidsIdGs1ImgSrc} style={{ margin: 'auto', alignSelf: 'center', height: 140 }} ></img>

                <a href={getUpidsIdGs1DigitalLink(props.upidsIdData)}
                  rel="noopener noreferrer" target="_blank"
                  className="subtitle" style={{ cursor: 'pointer', fontSize: 12, fontWeight: 'normal', overflowWrap: 'break-word' }}
                >
                  {getUpidsIdGs1DigitalLink(props.upidsIdData)}
                </a>
              </div>

              <div style={{ alignSelf: 'center', marginTop: 10 }}>
                <IconButton
                  text={t("Get QRCode")}
                  icon={BiDownload}
                  onClick={() => downloadQRCode()}
                />
              </div>

              <div style={{ alignSelf: 'center', marginTop: 15 }}>
                <IconButton
                  text={t("Print Labels")}
                  icon={BiPrinter}
                  onClick={() => setShowPrintModal(true)}
                />
              </div>

            </>
          }
          
          {/* <ScrapingButton gtin={props.productData.gtin}/> */}

          {/* <div onClick={()=>window.open(`https://dev.2nd.gdn/${props.productData.gtin}`, '_blank').focus()} style={{cursor:'pointer'}} className="d-flex mt-5 justify-content-center">
            <MdShare color="#CF2948" size={18} className="mr-2" />
            <span style={{color:'#131E27',fontSize:12,fontWeight:'bold',marginTop:2}}>SHARE PRODUCT</span>
          </div> */}

          {/* <div className="descdiv">
          <Button className="btn">{props.prod_status}</Button>
        </div>

        <div className="descdiv">
          <img alt="alt"
            className="qrcodeimg"
            src={props.prod_qrcode}
            alt="qr code image"
          ></img>
        </div> */}
        </div>

      </div>

      <Modal size="md" show={showPrintModal} onHide={() => closeShowPrintModal()} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => closeShowPrintModal()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ display: 'block', padding: 50, color: '#131e27', opacity: 1, fontSize: 16, }}>
          <div className="form-group col-12" style={{ fontWeight: 'normal', fontSize: 'initial' }}>
            <Dropdown style={{ width: 400 }}
              placeholder={t("Select Print Type")}
              search selection
              name="printJobType"
              value={printJobType}
              options={printJobTypes}
              onChange={handlePrintJobTypeChanged} />
          </div>
          {printJobType && <div>Instructions for printing</div>}
          {printJobType == "Avery 3475" ?
            <ul style={{ listStyleType: 'none' }}>
              {printTypesInstructions.avery_3475.map((data, i) => (
                <li>- {data}</li>
              ))}
            </ul>
            : null
          }
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Ok")} onClick={() => handlePrintTypeOkClick()} disabled={printJobType == '' ? true : false} ></StSquareButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProductSideBar;
