import { createSlice } from "@reduxjs/toolkit";
import { getFieldAiDataAction, getAItranslation } from "./aiDataThunk";


const initialAITranslationState = {
  error: null,
  isLoading: false,
  translations: [],
  aiFilledInput: [],
  isLoadingTrans: false
};

const aiDataSlice = createSlice({
  name: "aiTranslation",
  initialState: initialAITranslationState,
  reducers: {
    setAiFilledInput: (state, action) => {
      state.aiFilledInput = action.payload;
    },
    clearAiTranslationData: (state, action) => {
      state.aiFilledInput = [];
      state.translations = [];
    },
  },

  extraReducers:
    (builder) => {
      builder
        .addCase(getFieldAiDataAction.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getFieldAiDataAction.fulfilled, (state, action) => {
          state.isLoading = false;
        })
        .addCase(getFieldAiDataAction.rejected, (state, action) => {
          state.isLoading = false;
        })


        .addCase(getAItranslation.pending, (state) => {
          state.isLoadingTrans = true;
        })
        .addCase(getAItranslation.fulfilled, (state, action) => {
          state.isLoadingTrans = false;
          state.error = null;
          state.translations = action.payload;
        })
        .addCase(getAItranslation.rejected, (state, action) => {
          state.isLoadingTrans = false;
        })
    },
});


export const { setAiFilledInput, clearAiTranslationData } = aiDataSlice.actions;
export default aiDataSlice