import React, { useEffect, useState } from "react";
import { getClickstreamEntries } from "src/redux/analytics/analyticsThunk";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useStateCallback } from "../../helper/CustomHook";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Spinner } from 'src/components/Spinner'
import { MaterialTable } from "src/components/MaterialTable";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from "src/components/StSquareButton";
import Modal from "react-bootstrap/Modal";
import GoogleMapComponents from "src/components/googleMapComponents";
import { googleMarkers } from "./googleMapData/data";
import { FaLocationDot } from "react-icons/fa6";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import countries from 'countries-and-timezones';


const ClickstreamTable = (props) => {
  const { selectedAnalyticsView } = props
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const analyticsState = useSelector((state) => state.analytics, shallowEqual);
  const { actionsLoading: isLoading, analyticsClickstreamEntries } = analyticsState

  const [OffsetCount, setOffsetCount] = useStateCallback(0);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [geoLocation, setGeoLocation] = useState([])


  useEffect(() => {
    // Load initial data
    const clickstreamEntriesParams = "?pagesize=100&offset=0";
    dispatch(getClickstreamEntries(clickstreamEntriesParams));
  }, []);

  // Handle next page loading
  const handleNextPage = () => {
    setOffsetCount(OffsetCount + 100, (OffsetCount) => {
      const clickstreamParams = "?pagesize=100&offset=" + OffsetCount;
      dispatch(getClickstreamEntries(clickstreamParams));
    });
  };

  // Handle previous page loading
  const handlePrevPage = () => {
    setOffsetCount(OffsetCount - 100, (OffsetCount) => {
      if (OffsetCount < 0) {
        setOffsetCount(0);
      }
      const clickstreamParams = "?pagesize=100&offset=" + OffsetCount;
      dispatch(getClickstreamEntries(clickstreamParams));
    });
  };

  const openParticularProduct = (item) => {
    if (item.codetype === "upids") {
      navigate(`/productProfile/${item.articleidentifier}/upidsProduct/${item.articleidentifier}-${item.serialnumber}-${item.smartsegmentextension}`)
    } else {
      navigate(`/productProfile/${item.articleidentifier}`);
    }
  }


  const openGoogleMap = (item, locInfo) => {
    let geoLocationObj = {
      text: item.codetype === "upids" ? `${item.articleidentifier}-${item.serialnumber}-${item.smartsegmentextension}` : item.articleidentifier
    }

    let geoLocation = {};

    let latAndLng = locInfo?.loc?.split(",");

    if (_.isEmpty(locInfo)) {
      _.set(geoLocation, 'lat', "0.0000");
      _.set(geoLocation, 'lng', "0.0000");
    } else {
      _.set(geoLocation, 'lat', Number(latAndLng[0]));
      _.set(geoLocation, 'lng', Number(latAndLng[1]));
    }

    setGeoLocation(Array({ ...geoLocation, ...geoLocationObj }))
    setIsModalOpen(true)
  }
  // const handleModalAdd =()=>{
  //   setIsModalOpen(true)
  // }

  const handleModalHide = () => {
    setIsModalOpen(false)
  }

  const defaultProps = {
    center: {
      lat: 18.5196,
      lng: 73.8554
    },
    zoom: 15
  };

  const getCountryAndCityFromUserAgent = (useragent) => {
    const segments = useragent.split('/');
    let country = "", city = "";
    segments.forEach(segment => {
      const parts = segment.split('=');
      if (parts[0] === "country") {
        const countryCode = parts[1];
        const countryObj = countries.getCountry(countryCode);
        country = countryObj ? countryObj.name : countryCode;
      } else if (parts[0] === "city") {
        city = parts[1];
      }
    });
    return `${country}, ${city}`;
  }


  function geoLocationSeprater(detailsString) {
    const parts = detailsString.split('/');
    const obj = {};
    parts.forEach(part => {
      const [key, value] = part.split('=');
      obj[key] = value;
    });
    return obj;
  }

  const allData = [];

  const createTableData = () => {
    if (!!analyticsClickstreamEntries && analyticsClickstreamEntries.entries.length > 0) {
      analyticsClickstreamEntries.entries.forEach((item) => {
        let locInfo = item?.geolocation ? geoLocationSeprater(item?.geolocation) : {};
        if (selectedAnalyticsView === "mobile" && item.mobilescan !== "1") {
          return;
        }
        else if (selectedAnalyticsView === "web" && item.mobilescan === "1") {
          return;
        }

        allData.push({
          timestamp: item?.timestamp && moment.unix(item?.timestamp).format('YYYY-MM-DD HH:mm:ss'),
          eventname: item.eventname,
          codetype: <p style={{ cursor: 'pointer' }} className='feedbackTableText' onClick={() => openParticularProduct(item)}>
            {item.codetype === "upids" ? `${item.articleidentifier}-${item.serialnumber}-${item.smartsegmentextension}` : item.articleidentifier}
          </p>,
          location: getCountryAndCityFromUserAgent(item?.geolocation),
          geoLocation: <div className="d-flex">
            <FaLocationDot
              onClick={() => openGoogleMap(item, locInfo)}
              cursor={"pointer"}
              size={23}
              style={{ alignSelf: "flex-end" }}
            />
          </div>
        });
      });
    }
  }
  createTableData()

  const columnNameList = [
    { title: t("Timestamp"), field: "timestamp", sorting: true },
    { title: t("Event Name"), field: "eventname", sorting: false, },
    {
      title: t("UPIDS / GTIN"), field: "codetype", sorting: false, cellStyle: { minWidth: 200, maxWidth: 200 },
      render: (params) => (
        <Tooltip title={params.codetype} >
          <span >{params.codetype}</span>
        </Tooltip>
      )
    },
    {
      title: t("location"), field: "location", sorting: false, cellStyle: { minWidth: 300, maxWidth: 300 },
      render: (params) => (
        <Tooltip title={params.location} >
          <span >{params.location}</span>
        </Tooltip>
      )
    },
    { title: t("GeoLocation"), field: "geoLocation", sorting: false },
  ]


  return (
    <>
      {analyticsState?.clickStreamEntries ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="">
          <MaterialTable
            columnNames={columnNameList}
            tableData={allData}
          />

          {!!analyticsClickstreamEntries && allData?.length >= 99 || OffsetCount!==0 ?
            <div className="row col-12">
              <button
                className="paginationButton col-6"
                onClick={handlePrevPage}
                {...(OffsetCount === 0 && { disabled: true })}
              >
                {t("Previous Page")}
              </button>

              <button
                className="paginationButton col-6"
                onClick={handleNextPage}
                {...(OffsetCount + 100 >
                  analyticsClickstreamEntries.totalcount && {
                  disabled: true,
                })}
              >
                {t("Next Page")}
              </button>
            </div>
            : ""}
        </div>
      )}
      <Modal size="xl" centered={true} backdrop="static" show={isModalOpen} onHide={handleModalHide} >
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleModalHide()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal' }}>
          <div className="">
            <GoogleMapComponents
              zoom={15}
              height={"100vh"}
              googleMarkers={geoLocation}
            />
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClickstreamTable;
