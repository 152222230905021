import { MdDashboard,MdSchema  } from 'react-icons/md';
import { FaShoppingBasket, FaUsers, FaFileAlt, FaChartArea, FaChartLine,FaCertificate  } from 'react-icons/fa';
import { BsBuilding, } from "react-icons/bs";


// manufacturers, users, analytics, billing
export const sidebarJson = [
    {
        key: "routekey-dashboard",
        label: "Dashboard",
        path: "/dashboard",
        acitvePathList: ["/dashboard"],
        icon: <MdDashboard />,
        // vissbleOnSmallScrren: true,
        requiredRoles: ["anyAuth"]
    },
    {
        key: "routekey-products",
        label: "Products",
        path: "/products",
        acitvePathList: ["/products", "/productProfile", "/addproduct", "/addupidsproduct"],
        icon: <FaShoppingBasket />,
        // vissbleOnSmallScrren: true,
        requiredRoles: ["anyAuth"]
    },
    {
        key: "routekey-cms",
        label: "Content management",
        path: "/cms",
        acitvePathList: ["/cms",],
        icon: <FaChartArea />,
        // vissbleOnSmallScrren: true,
        requiredRoles: [10]
    },

    {
        key: "routekey-company",
        label: "Company",
        path: "/company",
        acitvePathList: ["/company",],
        icon: <BsBuilding />,
        // vissbleOnSmallScrren: true,
        requiredRoles: ["anyAuth"]
    },
    {
        key: "routekey-feedbacks",
        label: "Feedbacks",
        path: "/feedbacks",
        acitvePathList: ["/feedbacks",],
        icon: <FaChartArea />,
        // vissbleOnSmallScrren: true,
        requiredRoles: ["anyAuth"]
    },
    {
        key: "routekey-documentation",
        label: "Documentation",
        path: "/documentation",
        acitvePathList: ["/documentation",],
        icon: <FaFileAlt />,
        // vissbleOnSmallScrren: true,
        requiredRoles: ["anyAuth"]
    },
    {
        key: "routekey-analytics",
        label: "Analytics",
        path: "/analytics",
        acitvePathList: ["/analytics",],
        icon: <FaChartLine />,
        // vissbleOnSmallScrren: true,
        requiredRoles: ["anyAuth"]
    },
    {
        key: "routekey-dashboard",
        label: "Admin",
        acitvePathList: ["/admin"],
        icon: <MdDashboard />,
        // vissbleOnSmallScrren: true,
        requiredRoles: [10],
        subMenu: [
            {
                key: "routekey-manufacturers",
                label: "Manufacturers",
                path: "/manufacturers",
                acitvePathList: ["/manufacturers",],
                icon: <FaChartArea />,
                // vissbleOnSmallScrren: true,
                requiredRoles: [10]
            },
            {
                key: "routekey-users",
                label: "Users",
                path: "/users",
                acitvePathList: ["/users", "/myprofile"],
                icon: <FaUsers />,
                // vissbleOnSmallScrren: true,
                requiredRoles: [10]
            },
            {
                key: "routekey-billing",
                label: "Billing",
                path: "/billing",
                acitvePathList: ["/billing",],
                icon: <FaChartLine />,
                // vissbleOnSmallScrren: true,
                requiredRoles: [10]
            },
            {
                key: "routekey-vouchers",
                label: "Vouchers",
                path: "/vouchers",
                acitvePathList: ["/vouchers",],
                icon: <BsBuilding />,
                // vissbleOnSmallScrren: true,
                requiredRoles: [10]
            },
            {
                key: "routekey-templates",
                label: "Templates",
                path: "/templates",
                acitvePathList: ["/templates",],
                icon: <MdSchema />,
                // vissbleOnSmallScrren: true,
                requiredRoles: [10]
            },
            {
                key: "routekey-metadata",
                label:"Metadata",
                path: "/metadata",
                acitvePathList: ["/metadata",],
                icon: <MdSchema />,
                // vissbleOnSmallScrren: true,
                requiredRoles: [10]
            },
            {
                key: "routekey-metadata",
                label:"_Certifications_sidebar",
                path: "/certifications",
                acitvePathList: ["/certifications",],
                icon: <FaCertificate />,
                // vissbleOnSmallScrren: true,
                requiredRoles: [10]
            },
        ]
    },
]


