import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';
import { getCompanyDetails } from 'src/redux/company/companyThunk';
import appConfig from 'src/helper/appConfig';



export const startMediaUplaodAction = createAction(
    'media/startMediaUplaodAction',
    (arg) => {
        return { payload: arg }
    }
)


export const getAllProductImagesAction = createAsyncThunk(
    "media/getAllProductImagesAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/file/all?businessid=${arg.businessId}&${arg.articleIdentifier}`,
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const uploadProductFileAction = createAsyncThunk(
    "media/uploadProductFileAction",
    async (arg, thunkApi) => {
        try {
            thunkApi.dispatch(startMediaUplaodAction(arg.type))
            const response = await getAxios(appConfig.cloudRunUrl).post(
                // `/upids/file/upload?businessid=${arg.businessId}&id=${arg.gtin}`,arg.body
                `/file/upload?businessid=${arg.businessId}&id=${arg.gtin}`, arg.body
            );
            thunkApi.dispatch(getAllProductImagesAction({ 
                businessId: arg.businessId, 
                articleIdentifier: arg.articleIdentifier, 
                isLoaderShow: arg.isLoaderShow }));
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const deleteImageAction = createAsyncThunk(
    "media/deleteImageAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().delete(
                `/upids/file?filename=${arg.url}`,
            );
            thunkApi.dispatch(getAllProductImagesAction({ businessId: arg.businessId, articleIdentifier: arg.articleIdentifier, isLoaderShow: arg.isLoaderShow }));
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const getImageDownload = createAsyncThunk(
    "media/getImageDownload",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/file?filename=${arg.url}`,
              );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const uploadCompanyLogoImage = createAsyncThunk(
    "media/uploadCompanyLogoImage",
    async (arg, thunkApi) => {
        try {
            thunkApi.dispatch(startMediaUplaodAction('logo'))
            const response = await getAxios().post(
                `/iam/company/logo/upload?businessid=${arg.businessId}`,
                arg.body
              );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const deleteCompanyLogoImage = createAsyncThunk(
    "media/deleteCompanyLogoImage",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().delete(
                `/iam/company/logo/delete?filename=${arg.fileName}`,
                arg.body
            );
            await thunkApi.dispatch(getCompanyDetails());
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);
