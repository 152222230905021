import React,{ useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import IconButton from "../../commonComponent/Icons/Icons";
import Modal from "react-bootstrap/Modal";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { changeFeedbackEditMode } from "src/redux/feedback/feedbackSlice";
import { getProductFeedbackDetail, deleteFeedback } from "src/redux/feedback/feedbackThunk";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { usePrevious } from "../../../helper/CustomHook";
import logo from '../../../assets/images/brand_icons/upids-pdm-logo.png'
import { BiTrash } from "react-icons/bi";
import {MdArrowBack, MdClose,MdMenu,MdOutlineModeEdit,MdOutlineSave,MdOutlineClose } from "react-icons/md";
import { StMenu } from "src/components/StMenu";
import {IconButton} from "src/components/IconButton"
import {Header} from "src/components/Header"
import { StSquareButton } from "src/components/StSquareButton";
import { StCancelSquareButton } from "src/components/StCancelSquareButton";
import { StCloseButton } from "src/components/StCloseButton";

const FeedbackNavbar = () => {

  const feedbackNavbarCollection=[
    {menuItem: ()=><IconButton text={t("Edit")} icon={MdOutlineModeEdit} onClick={()=>changeMode()}/>,
      submenu:[
        {menuItem: ()=><IconButton text={t("Delete Feedback")} icon={BiTrash} onClick={()=>handleDeleteClick()}/>},
        {menuItem: ()=><IconButton text={t("Cancel")} icon={MdOutlineClose} onClick={()=>handleCancelClick()}/>}
      ],
      submenuTitle: ()=><IconButton text={t("Edit")} icon={MdOutlineModeEdit} onClick={()=>changeMode()}/>},
  ]

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const [showDelPopup, setShowDelPopup] = useState(false);

  let fullUrl = window.location.href;
  let id = fullUrl.split("/").pop();

  const _state = useSelector((state) => state.feedback, shallowEqual);

  const {  deleteResponse } = _state;
  const prevPropsState = usePrevious({  deleteResponse });

  useEffect(() => {
    if (prevPropsState) {
      if (prevPropsState.deleteResponse !== deleteResponse && deleteResponse) {
        setShowDelPopup(false)
        dispatch(changeFeedbackEditMode(false));
        navigate("/feedbacks");
      } 
    }
  }, [_state]);

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  const changeMode = () => {
    dispatch(changeFeedbackEditMode(true));
  };

  const handleDeleteClick = () => {
    setShowDelPopup(true);
  };

  const handleCancelClick = () => {
    dispatch(changeFeedbackEditMode(false));
    dispatch(getProductFeedbackDetail({ id: id, isCancelBtn: true }))
  }

  const handleDelClose = () => setShowDelPopup(false);

  const handleDeleteChanges = () => {
    dispatch(deleteFeedback(id));
  };

  return (
    <>
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/dashboard">
          <img src={logo} alt="logo" />
        </Link>
        <Link
          className="navbar-brand brand-logo-mini d-inline-block d-lg-none"
          to="/"
        >
          <img
            src={require("../../../assets/images/logo-mini.svg")}
            alt="logo"
          />
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center">
        {location.pathname.includes("/feedbacks") && location.pathname.slice(11) ?
          <MdArrowBack
            onClick={() => navigate(-1)}
            style={{
              width: 24,
              height: 24,
              backgroundColor: "transparent",
              opacity: 1,
              cursor: "pointer",
            }}
          /> : ""  }
          
        {location.pathname.includes("/feedbacks") && location.pathname.slice(11) ? "" :
         <span><Header text={t("Feedbacks")}/></span>}
       
        {location.pathname.includes("/feedbacks") && location.pathname.slice(11) &&
      <div>
      {!_state.isFeedbackEditMode ? (
            <div className="navbar-nav navbar-nav-right d-none d-sm-flex" style={{position:'absolute',right:20,bottom:15}}>
              <IconButton
                text={t("Edit")}
                icon={MdOutlineModeEdit}
                onClick={changeMode}
              />
            </div>
          ) : (
            <div className="navbar-nav navbar-nav-right d-none d-sm-flex" style={{position:'absolute',right:20,bottom:15}}>
              <IconButton
                text={t("Delete Feedback")}
                icon={BiTrash}
                onClick={handleDeleteClick}
              />
              <IconButton
                text={t("Cancel")}
                icon={MdOutlineClose}
                onClick={handleCancelClick}
              />
            </div>
          )}
          </div>
          }

{/* responsive dropdown on mobile screen */}
    {location.pathname.includes("/feedbacks") && location.pathname.slice(11) ? <ul className="navbar-nav navbar-nav-right productNavResponsiveDropdown">
            <StMenu menuArray={feedbackNavbarCollection}></StMenu>
           </ul> : <ul  className="navbar-nav navbar-nav-right productNavResponsiveDropdown"></ul>}

        <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
             <MdMenu color="#000000" size={24} />
          </button>
      </div>
    </nav>

<Modal size="md" show={showDelPopup} onHide={handleDelClose} centered={true}>
<Modal.Header >
  <div style={{position:'absolute',right:10}}>
                    <StCloseButton text={t("Close")} onClick={()=>handleDelClose()}></StCloseButton>
                    </div>
</Modal.Header>
<Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18,  }}>
  <span>{t("Are you sure want to delete the feedback")}</span>
</Modal.Body>
<Modal.Footer>
  <StSquareButton text={t("Yes")} onClick={()=>handleDeleteChanges()} loading={_state.isDeleteLoading}></StSquareButton>
            <StCancelSquareButton text={t("No")} onClick={()=>handleDelClose()}></StCancelSquareButton>
</Modal.Footer>
</Modal>
</>
  );
};

export default FeedbackNavbar;
