import generic from "./productSchema/generic";
import foodBewerage from "src/helper/productSchema/foodBewerage";
import textile from "./productSchema/textile";
import software from "src/helper/productSchema/software";
import { GENERIC, FOOD_BEWERAGE, TEXTILE, SOFTWARE } from 'src/helper/constants/constants'
import _ from 'lodash';
import objectPath from "object-path";
import { commonComponentSchema } from "./productSchema/commonComponentSchema";

/**
 * @param youtubeVideoLink
 * @export embaded youtube video url
 */
export const getEmmbededYoutubeVideoUrl = (videoUrl) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = videoUrl.match(regExp);
  const youtubeVideoId = (match && match[7].length == 11) ? match[7] : false;
  const youtubeVideoUrl = `https://www.youtube-nocookie.com/embed/${youtubeVideoId}?controls=1&autoplay=0&version=3&enablejsapi=1`
  return youtubeVideoUrl;
}

export const privacyAddendunFormSchema = [
  {
    "tooltipText": "Privay policy content item URL",
    "isMultilingual": false,
    "validationType": "string",
    "name": "contentItemUrl",
    "readOnly": false,
    "label": "_Privay policy content item URL",
    "placeholder": "",
    "type": "text",
  },
  {
    "tooltipText": "Marketing subscription webhook URL",
    "isMultilingual": false,
    "validationType": "string",
    "name": "webhookUrl",
    "readOnly": false,
    "label": "_Marketing subscription webhook URL",
    "placeholder": "",
    "type": "text",
  },
  {
    "tooltipText": "Webhook key",
    "isMultilingual": false,
    "validationType": "string",
    "name": "webhookKey",
    "readOnly": false,
    "label": "_Webhook key",
    "placeholder": "",
    "type": "text",
  },
]



/**
 * Function to generate random password
 * @param {int} passwordLength Length of password
 * @param {boolean} specialChars Are special characters used
 * @returns Generated password
 */
export const generatePassword = (passwordLength = 16) => {
  const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
  const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const nums = "0123456789";
  const specialChars = "@$!%*#?&";
  const allChars = lowerCaseChars + upperCaseChars + nums + specialChars;

  var requiredChars = [4];
  requiredChars[0] = [...window.crypto.getRandomValues(new Uint32Array(1)),].map((x) => lowerCaseChars[x % lowerCaseChars.length]).join("");
  requiredChars[1] = [...window.crypto.getRandomValues(new Uint32Array(1)),].map((x) => upperCaseChars[x % upperCaseChars.length]).join("");
  requiredChars[2] = [...window.crypto.getRandomValues(new Uint32Array(1)),].map((x) => nums[x % nums.length]).join("");
  requiredChars[3] = [...window.crypto.getRandomValues(new Uint32Array(1)),].map((x) => specialChars[x % specialChars.length]).join("");

  const randomString = [...window.crypto.getRandomValues(new Uint32Array(passwordLength - 4)),].map((x) => allChars[x % allChars.length]).join("");

  const combinedString = requiredChars.join("") + randomString;

  let shuffledString = [...combinedString];

  const getRandomValue = (i, N) => Math.floor(Math.random() * (N - i) + i);

  shuffledString.forEach((elem, i, arr, j = getRandomValue(i, arr.length)) => [arr[i], arr[j]] = [arr[j], arr[i]]);

  shuffledString = shuffledString.join('');

  return shuffledString;
};


/**
 * function to replace "A" with "-" from upids
 * @param {string} upidsId, @param {}
 * @returns {string} upidsId,
 */

export const updateUpidsToShowUI = (upidsId) => {
  return upidsId.replace(/A/g, "-")
}


export const getSpecificFlattenSchemaPath_ObjectForItemName = (productFlattenSchema, itemNameUpdated) => {
  let fieldObject = {}
  let fieldPath = ''

  productFlattenSchema.forEach((item, index) => {
    if (item.nameUpdated === itemNameUpdated) {
      fieldObject = item
      fieldPath = `[${index}]`
    }
  })

  return { fieldObject, fieldPath, }
}



export const getSpecificStructuredSchemaPath_ObjectForItemName = (productSchema, itemName) => {
  // const componentItem = {
  //     name: '_2an',
  // }
  let fieldObject = {}
  let schemaPath = ''

  let productSchemaIndex = null;
  let childTabIndex = null;
  let fieldIndex = null;


  const objectHandle = (item) => {
    if (item.childTabList && item.childTabList[0]) {
      item.childTabList.some((element, cIndex) => {
        if (fieldIndex !== null) {
          return true
        }
        childTabIndex = cIndex
        objectHandle(element);
      })
    }
    else if (item.fieldList && item.fieldList[0]) {
      item.fieldList.some((fieldItem, fIndex) => {
        if (fieldItem.name === itemName) {
          const componentfieldItem = commonComponentSchema.find((componentItem) => componentItem.name === itemName)
          if (componentfieldItem) {
            fieldObject = { ...fieldItem, ...componentfieldItem }
          }
          else {
            fieldObject = fieldItem
          }
          fieldIndex = fIndex
          return true
        }
      })
    }
  }

  if (productSchema && productSchema[0] && itemName) {
    productSchema.some((item, sIndex) => {
      if (fieldIndex !== null) {
        return true
      }
      productSchemaIndex = sIndex;
      childTabIndex = null;
      fieldIndex = null;

      objectHandle(item)
    })
  }
  if (productSchemaIndex !== null && childTabIndex !== null && fieldIndex !== null) {
    schemaPath = `[${productSchemaIndex}].childTabList[${childTabIndex}].fieldList[${fieldIndex}]`
  }
  else if (productSchemaIndex !== null && fieldIndex !== null) {
    schemaPath = `[${productSchemaIndex}].fieldList[${fieldIndex}]`
  }
  const fieldListPath = `fieldList[${fieldIndex}]`

  return { fieldObject, schemaPath, fieldListPath }
}


/**
 * @param {*} productSchema 
 * @returns updatedNameFlattenSchema
 */
const getMergedComponent_updatedNameValue_flattenJson = (productSchema) => {
  let updatedFlattenSchema = []

  const objectHandle = (fieldObject, parentName) => {
    if (fieldObject.tabName && fieldObject.type) {
      const nameUpdated = parentName ? `${parentName}.${fieldObject.name}` : `${fieldObject.name}`
      let newItemObject = { ...fieldObject, nameUpdated: nameUpdated }

      const componentfieldItem = commonComponentSchema.find((componentItem) => componentItem.name === fieldObject.name)
      if (componentfieldItem) {
        newItemObject = { ...newItemObject, ...componentfieldItem }
      }

      updatedFlattenSchema.push(newItemObject)
    }
    else {
      try {
        Object.entries(fieldObject).map(([key, value]) => {
          const newParentName = parentName ? `${parentName}.${key}` : `${key}`
          if (value?.length) {
            value && value[0] && value.map(itemObject => {
              objectHandle(itemObject, newParentName)
            })
          } else if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
            objectHandle(value, newParentName)
          }
        })
      } catch (error) {
        const nameUpdated = parentName ? `${parentName}.${fieldObject.name}` : `${fieldObject.name}`
        updatedFlattenSchema.push({ ...fieldObject, nameUpdated })
      }
    }
  }

  productSchema.map((fieldObject) => {
    return objectHandle(fieldObject, "")
  })

  updatedFlattenSchema = updatedFlattenSchema.map((fieldItem) => {
    if (fieldItem.dependsOnName) {
      const dependsOnObject = updatedFlattenSchema.find((item) => item.name === fieldItem.dependsOnName)
      const newFieldItem = { ...fieldItem, dependsOnNameUpdated: dependsOnObject.nameUpdated }
      return newFieldItem
    }
    return fieldItem
  })


  return updatedFlattenSchema
}


/**
 * @param {*} selectedProductSchema 
 * @returns converted product schema
 */
export const getProduct_flatten_TabStructured_Schema = (productSchema) => {
  const productFlattenSchema = getMergedComponent_updatedNameValue_flattenJson(productSchema)
  /**
   * @description UPIDS PDM
   */
  const productTabStructuredSchema = [
    /**
    {
      parentTab: "parentTabName",
      childTabList: [
        {
          tabname: "",
          fieldList: []
        }
      ]
    },
    {
      tabName: "tabNameTabName",
      fieldList: []
    }
    */
  ]

  productFlattenSchema && productFlattenSchema[0] && productFlattenSchema.forEach((item) => {

    if (item.parentTab && item.tabName) {
      /** adding ParentTabs (main menu tab) */
      if (!_.find(productTabStructuredSchema, (tab) => tab.tabName === item.parentTab)) {
        productTabStructuredSchema.push(
          {
            tabName: item.parentTab,
            childTabList: [
              // {
              //   tabname: "",
              //   fieldList: []
              // }
            ]
          }
        )
      }

      /** adding childTabs (submenu tab) */
      const parentTabIndex = _.findIndex(productTabStructuredSchema, (tab) => tab.tabName === item.parentTab)
      if (!_.find(productTabStructuredSchema[parentTabIndex].childTabList, (tab) => tab.tabName === item.tabName)) {
        productTabStructuredSchema[parentTabIndex].childTabList.push(
          {
            tabName: item.tabName,
            fieldList: []
          }
        )
      }

      /** adding field in childTabs */
      const childTabIndex = _.findIndex(productTabStructuredSchema[parentTabIndex].childTabList, (tab) => tab.tabName === item.tabName)
      productTabStructuredSchema[parentTabIndex].childTabList[childTabIndex].fieldList.push(item)
    } else if (item.tabName) {
      /** add tab */
      if (!_.find(productTabStructuredSchema, (tab) => tab.tabName === item.tabName)) {
        productTabStructuredSchema.push(
          {
            tabName: item.tabName,
            fieldList: []
          }
        )
      }


      /** adding field in tabs */
      const tabIndex = _.findIndex(productTabStructuredSchema, (tab) => tab.tabName === item.tabName)
      productTabStructuredSchema[tabIndex].fieldList.push(item)
    }
  })



  /**
 * @description UPIDS IO
 */
  const upidsIoProductTabStructuredSchema = [
    /** 
      {
        tabName: "parentTabName",
        fieldOrder: 0,
        tabIcon: "bi bi-house",
        tabRequired: false,
        fieldList: [
          // field Containes subsection
          {
            section: "",
          sectionRequired: false,
            fieldOrder: 0,
            fieldList: [
              {
                subSection: "",
                fieldOrder: 0,
                fieldList: [{ name: "", fieldOrder: 0 }]
              }
            ]
          },
          // field Containes section
          {
            section: "",
            fieldList: [
              { name: "", fieldOrder: 0 }
            ]
          },
          // directly field
          {
            name: ""
            fieldOrder: 0
          },
        ],
      },
      */
  ]



  productFlattenSchema && productFlattenSchema[0] && productFlattenSchema.forEach((item) => {
    const tabName = _.get(item, 'upidsIo.tabName', "")
    const tabIcon = _.get(item, 'upidsIo.tabIcon', "")
    const tabRequired = _.get(item, "upidsIo.tabRequired", false)

    const section = _.get(item, 'upidsIo.section', "")
    const sectionTitle = _.get(item, 'upidsIo.sectionTitle', false)
    const sectionRequired = _.get(item, "upidsIo.sectionRequired", false)
    const swiperSlide = _.get(item, 'upidsIo.swiperSlide', false)

    const subSection = _.get(item, 'upidsIo.subSection', "")
    const subSectionTitle = _.get(item, 'upidsIo.subSectionTitle', false)

    const fieldOrder = _.get(item, 'upidsIo.fieldOrder', null)

    if (tabName && section && subSection) {
      /** add tab */
      const isTabNameExist = _.find(upidsIoProductTabStructuredSchema, (element) => element.tabName === tabName)
      if (!isTabNameExist) {
        upidsIoProductTabStructuredSchema.push(
          {
            tabName: tabName,
            fieldOrder: fieldOrder,
            tabIcon: tabIcon,
            fieldList: []
          }
        )
      }

      const tabNameIndex = _.findIndex(upidsIoProductTabStructuredSchema, (element) => element.tabName === tabName)
      if (tabRequired) {
        upidsIoProductTabStructuredSchema[tabNameIndex].tabRequired = true
      }

      /** adding section */
      const isSectionExists = _.find(upidsIoProductTabStructuredSchema[tabNameIndex].fieldList, (element) => element.section === section)
      if (!isSectionExists) {
        upidsIoProductTabStructuredSchema[tabNameIndex].fieldList.push(
          {
            section: section,
            fieldOrder: fieldOrder,
            sectionTitle: sectionTitle,
            swiperSlide: swiperSlide,
            fieldList: []
          }
        )
      }

      const sectionIndex = _.findIndex(upidsIoProductTabStructuredSchema[tabNameIndex].fieldList, (element) => element.section === section)
      if (sectionRequired) {
        upidsIoProductTabStructuredSchema[tabNameIndex].fieldList[sectionIndex].sectionRequired = true
      }
      /** adding subSection */
      const isSubSectionExists = _.find(upidsIoProductTabStructuredSchema[tabNameIndex].fieldList[sectionIndex].fieldList, (element) => element.subSection === subSection)
      if (!isSubSectionExists) {
        upidsIoProductTabStructuredSchema[tabNameIndex].fieldList[sectionIndex].fieldList.push(
          {
            subSection: subSection,
            subSectionTitle: subSectionTitle,
            fieldOrder: fieldOrder,
            fieldList: []
          }
        )
      }

      /** adding field in fieldList */
      const subsectionIndex = _.findIndex(upidsIoProductTabStructuredSchema[tabNameIndex].fieldList[sectionIndex].fieldList, (element) => element.subSection === subSection)
      upidsIoProductTabStructuredSchema[tabNameIndex].fieldList[sectionIndex].fieldList[subsectionIndex].fieldList.push({ ...item, fieldOrder: fieldOrder })


    }
    else if (tabName && section) {
      /** add tab */
      const isTabNameExist = _.find(upidsIoProductTabStructuredSchema, (element) => element.tabName === tabName)
      if (!isTabNameExist) {
        upidsIoProductTabStructuredSchema.push(
          {
            tabName: tabName,
            fieldOrder: fieldOrder,
            tabIcon: tabIcon,
            fieldList: []
          }
        )
      }

      const tabNameIndex = _.findIndex(upidsIoProductTabStructuredSchema, (element) => element.tabName === tabName)
      if (tabRequired) {
        upidsIoProductTabStructuredSchema[tabNameIndex].tabRequired = true
      }
      /** adding section */
      const isSectionExists = _.find(upidsIoProductTabStructuredSchema[tabNameIndex].fieldList, (element) => element.section === section)
      if (!isSectionExists) {
        upidsIoProductTabStructuredSchema[tabNameIndex].fieldList.push(
          {
            section: section,
            fieldOrder: fieldOrder,
            sectionTitle: sectionTitle,
            swiperSlide: swiperSlide,
            fieldList: []
          }
        )
      }

      const sectionIndex = _.findIndex(upidsIoProductTabStructuredSchema[tabNameIndex].fieldList, (element) => element.section === section)
      if (sectionRequired) {
        upidsIoProductTabStructuredSchema[tabNameIndex].fieldList[sectionIndex].sectionRequired = true
      }
      /** adding field in fieldList */
      upidsIoProductTabStructuredSchema[tabNameIndex].fieldList[sectionIndex].fieldList.push({ ...item, fieldOrder: fieldOrder })
    }
    else if (tabName) {
      /** add tab */
      const isTabNameExist = _.find(upidsIoProductTabStructuredSchema, (element) => element.tabName === tabName)
      if (!isTabNameExist) {
        upidsIoProductTabStructuredSchema.push(
          {
            tabName: tabName,
            fieldOrder: fieldOrder,
            tabIcon: tabIcon,
            fieldList: []
          }
        )
      }

      const tabNameIndex = _.findIndex(upidsIoProductTabStructuredSchema, (element) => element.tabName === tabName)
      if (tabRequired) {
        upidsIoProductTabStructuredSchema[tabNameIndex].tabRequired = true
      }
      /** adding field in fieldList */
      upidsIoProductTabStructuredSchema[tabNameIndex].fieldList.push({ ...item, fieldOrder: fieldOrder })

    }
  })

  /** adding childTabs (submenu tab) */


  /**
   * @description sort according to fieldOrder
   */



  const handleFieldListRecurssionItemToOrder = (item) => {

    if (item && item.fieldList && item.fieldList[0]) {
      const orderedFieldList = _.orderBy(item.fieldList, ['fieldOrder',], ['asc',]);
      const newFieldList = orderedFieldList.map((element) => {
        const newElementList = handleFieldListRecurssionItemToOrder(element)
        return newElementList
      })
      const newItem = {
        ...item,
        fieldList: newFieldList,
      }
      return newItem

    }
    else {
      return item
    }
  }

  const upidsIoProductTabStructuredOrderdSchema = _.orderBy(upidsIoProductTabStructuredSchema, ['fieldOrder',], ['asc',]).map((tabItem) => {
    const newItem = handleFieldListRecurssionItemToOrder(tabItem)
    return newItem
  })




  return { productFlattenSchema, productTabStructuredSchema, upidsIoProductTabStructuredSchema: upidsIoProductTabStructuredOrderdSchema }
}


export const getSelectTabInfoFromProductTabStructuredSchema_tabName = (productTabStructuredSchema, tabName) => {
  const selectTabInfo = { tabName: "", fieldList: [] }

  if (productTabStructuredSchema && productTabStructuredSchema[0]) {
    productTabStructuredSchema.forEach((item) => {
      if (item && item.childTabList && item.childTabList[0]) {
        item.childTabList.forEach((element) => {
          if (element && element.tabName === tabName) {
            selectTabInfo.tabName = tabName
            selectTabInfo.fieldList = element.fieldList
          }
        })
      }
      else if (item && item.tabName === tabName) {
        selectTabInfo.tabName = tabName
        selectTabInfo.fieldList = item.fieldList
      }
    })
  }

  return selectTabInfo
}

/**
 * return product Schema based on productType
 */
export const getProductSchemaBaseOnProductType = (productSchemaType) => {
  if (productSchemaType === GENERIC) {
    return generic
  }
  else if (productSchemaType === FOOD_BEWERAGE) {
    return foodBewerage;
  }
  else if (productSchemaType === TEXTILE) {
    return textile;
  }
  else if (productSchemaType === SOFTWARE) {
    return software;
  }
  else {
    return [];
  }
}


/**
 * @param {*} productTabStructuredSchema 
 * @param {*} productCurrentTabName 
 * @returns  {productFirstTabName, productLastTabName, productCurrentTabName, productPreviousTabName, productnextTabName,}
 */
export const getProductProfileFirstLastPreviousNextTabName = (productTabStructuredSchema, productCurrentTabName) => {
  let isproductCurrentTabNameMatch = false
  const productPreviousNextTabName = {
    productFirstTabName: "",
    productLastTabName: "",
    productCurrentTabName: productCurrentTabName,
    productPreviousTabName: "",
    productnextTabName: "",
  }

  if (productTabStructuredSchema && productCurrentTabName) {
    productTabStructuredSchema.forEach((item) => {
      if (item.childTabList) {
        item.childTabList.forEach((element) => {
          if (!productPreviousNextTabName.productFirstTabName) {
            productPreviousNextTabName.productFirstTabName = element.tabName;
          }
          productPreviousNextTabName.productLastTabName = element.tabName;


          if (isproductCurrentTabNameMatch && !productPreviousNextTabName.productnextTabName) {
            productPreviousNextTabName.productnextTabName = element.tabName;
          }
          if (element.tabName === productCurrentTabName) {
            isproductCurrentTabNameMatch = true
          }
          if (!isproductCurrentTabNameMatch) {
            productPreviousNextTabName.productPreviousTabName = element.tabName;
          }
        })
      }
      else {
        if (!productPreviousNextTabName.productFirstTabName) {
          productPreviousNextTabName.productFirstTabName = item.tabName;
        }
        productPreviousNextTabName.productLastTabName = item.tabName;

        if (isproductCurrentTabNameMatch && !productPreviousNextTabName.productnextTabName) {
          productPreviousNextTabName.productnextTabName = item.tabName;
        }
        if (item.tabName === productCurrentTabName) {
          isproductCurrentTabNameMatch = true
        }
        if (!isproductCurrentTabNameMatch) {
          productPreviousNextTabName.productPreviousTabName = item.tabName;
        }
      }
    })
  }
  return productPreviousNextTabName
}


const getGtinValue = (gtin) => {
  let newGtin = gtin.trim();
  return newGtin.padStart(14, "0");
}

export const createAddProductRequestModel = (formData, selectedProductSchema, context) => {
  let productData = {
    productType: selectedProductSchema.productSchemaType,
    gtin: formData.gtin ? getGtinValue(formData.gtin) : undefined,
    _2an: formData._2an,
    countryCode: context.authState.level === 10 ? formData.countryCode : context.authState.countryCode,
    businessId: formData.businessId,
    businessIdentifiers: formData.businessIdentifiers,
    videos: formData.videos,
    companyId: formData.companyId,
    brandName: formData.brandName,
    profileActive: (formData.profileActive === undefined || formData.profileActive) ? true : false,
    media: {
      images: {
        '1024': [
          formData.media,
        ],
      },
    },
    productName: {
      en: formData.productName_en ? formData.productName_en.trim() : undefined,
      fi: formData.productName_fi ? formData.productName_fi.trim() : undefined,
      sv: formData.productName_sv ? formData.productName_sv.trim() : undefined,
      zh: formData.productName_zh ? formData.productName_zh.trim() : undefined,
    },
    regulatedProductName: {
      en: formData.regulatedProductName_en,
      fi: formData.regulatedProductName_fi,
      sv: formData.regulatedProductName_sv,
      zh: formData.regulatedProductName_zh,
    },
    descriptionShort: {
      en: formData.descriptionShort_en,
      fi: formData.descriptionShort_fi,
      sv: formData.descriptionShort_sv,
      zh: formData.descriptionShort_zh,
    },
    description: {
      en: formData.description_en,
      fi: formData.description_fi,
      sv: formData.description_sv,
      zh: formData.description_zh,
    },
    marketing: {
      websiteUrl: formData.websiteUrl,
      overlayUrl: formData.overlayUrl,
      publicEmail: formData.publicEmail,
      publicTelephone: formData.publicTelephone,
      postalAddress: formData.postalAddress,
      buyNowUrls: formData.buyNowUrls,
      externalLinks: formData.externalLinks,
      socialMediaUrls: formData.socialMediaUrls,
      marketingTexts: {
        en: formData.marketingTexts_en,
        fi: formData.marketingTexts_fi,
        sv: formData.marketingTexts_sv,
        zh: formData.marketingTexts_zh,
      },
      directCommerce: {
        status: formData.status,
        price: parseFloat(formData.price),
        tax: parseFloat(formData.tax),
        shipping: parseFloat(formData.shipping),
        deliveryType: formData.deliveryType,
      },
      banner1: {
        en: formData.banner1_en,
        fi: formData.banner1_fi,
        sv: formData.banner1_sv,
        zh: formData.banner1_zh,
      },
      banner2: {
        en: formData.banner2_en,
        fi: formData.banner2_fi,
        sv: formData.banner2_sv,
        zh: formData.banner2_zh,
      },
      usageInstructions: {
        en: formData.usageInstructions_en,
        fi: formData.usageInstructions_fi,
        sv: formData.usageInstructions_sv,
        zh: formData.usageInstructions_zh,
      },
      custom: {
        bgColor: formData.bgColor,
        headerText: formData.headerText,
      },
    },
    carbonFootprint: {
      total: formData.total,
      dateOfCalculation: formData.dateOfCalculation,
      source: formData.source,
      externalReference: formData.externalReference,
    },
    category: {
      categoryCode: {
        id: formData.categoryCodeId,
      },
      classCode: {
        id: formData.classCodeId,
      },
      familyCode: {
        id: formData.familyCodeId,
      },
      segmentCode: {
        id: formData.segmentCodeId,
      },
    },
    restricted: {
      type: formData.restrictionType,
      value: formData.restrictionValue,
    },
    certifications: formData.certifications,
    productHierarchyType: 'item',
    dataInjectorVersion: '1.0.7',
    dataSource: "PDM",
  };

  let productTypeSpecificData = getProductTypeSpecificDataForAdd(formData, selectedProductSchema);
  return { ...productData, ...productTypeSpecificData };
}

const getProductTypeSpecificDataForAdd = (formData, selectedProductSchema) => {
  switch (selectedProductSchema.productSchemaType) {
    case 'foodbewerage':
      let foodProductData = {
        ingredients: {
          en: formData.ingredients_en,
          fi: formData.ingredients_fi,
          sv: formData.ingredients_sv,
          zh: formData.ingredients_zh,
        },
        nutritional: {
          calculatedFor: formData.calculatedFor,
          claim: formData.claim,
          details: formData.details,
          perQuantity: formData.perQuantity,
        },
        additives: {
          contains: !!formData.additives && formData.additives.contains ? formData.additives.contains : [],
          free_from: !!formData.additives && formData.additives.free_from ? formData.additives.free_from : [],
          may_contain: !!formData.additives && formData.additives.may_contain ? formData.additives.may_contain : [],
        },
        allergens: {
          list: {
            contains: !!formData.allergens && !!formData.allergens.list && formData.allergens.list.contains ? formData.allergens.list.contains : [],
            free_from: !!formData.allergens && !!formData.allergens.list && formData.allergens.list.free_from ? formData.allergens.list.free_from : [],
            may_contain: !!formData.allergens && !!formData.allergens.list && formData.allergens.list.may_contain ? formData.allergens.list.may_contain : [],
          },
        },
      }
      return foodProductData;

    case 'textile':
      let textileProductData = {
        textileSizing: {
          sizeChart: formData.sizeChart,
          physicalDimentions: formData.physicalDimentions,
          weight: formData.weight,
          bodyType: formData.bodyType,
        },
        material: {
          color: formData.color,
          fiberContent: formData.fiberContent,
          materialInfo: formData.materialInfo,
          generalFiberInfo: formData.generalFiberInfo,
        },
        productLifecycleStage: {
          introduction: formData.introduction,
          growth: formData.growth,
          maturity: formData.maturity,
          decline: formData.decline,
        },
        production: {
          piecesProduced: formData.piecesProduced,
          whereProduced: formData.whereProduced,
          productionPrice: formData.productionPrice,
          season: formData.season,
        },
      }
      return textileProductData;

    default:
      break;
  }
}


export const createEditProductRequestModel = (newData, oldData, _companyState, selectedProductSchema, context, addZerosToGtin) => {

  //Identifier tab
  newData.brandName = oldData.brandName;
  newData.businessId = oldData.businessId;
  newData.videos = oldData.videos;
  // newData.countryCode = context.authState.countryCode;
  newData.countryCode = newData.countryCode;
  objectPath.set(newData, "gtin", addZerosToGtin ? getGtinValue(oldData.gtin) : oldData.gtin);
  objectPath.set(newData, "businessIdentifiers", oldData.businessIdentifiers);
  objectPath.set(newData, "companyId", oldData.companyId);
  objectPath.set(newData, "profileActive", (oldData.profileActive === undefined || oldData.profileActive) ? true : false);
  objectPath.set(newData, "externalSources", oldData.externalSources);
  //Multi language fields
  objectPath.get(_companyState, 'companyInfo.supportedLanguages', []).map((o) => {
    objectPath.set(newData, `productName.${o.code}`, objectPath.get(oldData, `productName.${o.code}`));
    objectPath.set(newData, `regulatedProductName.${o.code}`, objectPath.get(oldData, `regulatedProductName.${o.code}`));
    objectPath.set(newData, `descriptionShort.${o.code}`, objectPath.get(oldData, `descriptionShort.${o.code}`));
    objectPath.set(newData, `description.${o.code}`, objectPath.get(oldData, `description.${o.code}`));
    objectPath.set(newData, `ingredients.${o.code}`, objectPath.get(oldData, `ingredients.${o.code}`));
    objectPath.set(newData, `marketing.marketingTexts.${o.code}`, objectPath.get(oldData, `marketingTexts_${o.code}`));
    objectPath.set(newData, `marketing.usageInstructions.${o.code}`, objectPath.get(oldData, `usageInstructions_${o.code}`));
    objectPath.set(newData, `marketing.banner1.${o.code}`, objectPath.get(oldData, `banner1_${o.code}`));
    objectPath.set(newData, `marketing.banner2.${o.code}`, objectPath.get(oldData, `banner2_${o.code}`));
  });

  // media tab
  const tArray = [];
  tArray.push(oldData.media);
  const tObject = { '1024': tArray };
  objectPath.set(newData, "media.images", tObject);

  // Marketing tab
  objectPath.set(newData, "marketing.websiteUrl", oldData.websiteUrl);
  objectPath.set(newData, "marketing.overlayUrl", oldData.overlayUrl);
  objectPath.set(newData, "marketing.publicEmail", oldData.publicEmail);
  objectPath.set(newData, "marketing.publicTelephone", oldData.publicTelephone);
  objectPath.set(newData, "marketing.buyNowUrls", oldData.buyNowUrls);
  objectPath.set(newData, "marketing.externalLinks", oldData.externalLinks);
  objectPath.set(newData, "marketing.socialMediaUrls", oldData.socialMediaUrls);
  objectPath.set(newData, "marketing.postalAddress", oldData.postalAddress);
  objectPath.set(newData, "certifications", oldData.certifications);
  objectPath.set(newData, "marketing.custom.bgColor", oldData.bgColor);
  objectPath.set(newData, "marketing.custom.headerText", oldData.headerText);

  // sales tab
  objectPath.set(newData, "directCommerce.status", oldData.status);
  objectPath.set(newData, "directCommerce.price", parseFloat(oldData.price));
  objectPath.set(newData, "directCommerce.tax", parseFloat(oldData.tax));
  objectPath.set(newData, "directCommerce.shipping", parseFloat(oldData.shipping));
  objectPath.set(newData, "directCommerce.deliveryType", oldData.deliveryType);

  // recipientEmails from product profile manufacturer tab
  newData.private = { ...newData.private, ...oldData.private };

  // carbon footprint data
  objectPath.set(newData, "carbonFootprint.total", oldData.total);
  objectPath.set(newData, "carbonFootprint.dateOfCalculation", oldData.dateOfCalculation);
  objectPath.set(newData, "carbonFootprint.source", oldData.source);
  objectPath.set(newData, "carbonFootprint.externalReference", oldData.externalReference);

  //Category tab
  objectPath.set(newData, "category.categoryCode.id", oldData.categoryCodeId);
  objectPath.set(newData, "category.classCode.id", oldData.classCodeId);
  objectPath.set(newData, "category.familyCode.id", oldData.familyCodeId);
  objectPath.set(newData, "category.segmentCode.id", oldData.segmentCodeId);

  // restricted data
  objectPath.set(newData, "restricted", oldData.restricted);

  getProductTypeSpecificDataForEdit(newData, oldData, selectedProductSchema);

  return newData;
}

const getProductTypeSpecificDataForEdit = (newData, oldData, selectedProductSchema) => {
  switch (selectedProductSchema.productSchemaType) {
    case 'foodbewerage':
      //Allergens tab
      objectPath.set(newData, "allergens", oldData.allergens);

      //Additives tab
      objectPath.set(newData, "additives", oldData.additives);

      //Nutritional tab
      objectPath.set(newData, "nutritional.calculatedFor", oldData.calculatedFor);
      objectPath.set(newData, "nutritional.claim", oldData.claim);
      objectPath.set(newData, "nutritional.details", oldData.details);
      objectPath.set(newData, "nutritional.perQuantity", oldData.perQuantity);

      return newData;

    case 'textile':
      // Size tab
      objectPath.set(newData, "textileSizing.sizeChart", oldData.sizeChart);
      objectPath.set(newData, "textileSizing.physicalDimentions", oldData.physicalDimentions);
      objectPath.set(newData, "textileSizing.weight", oldData.weight);
      objectPath.set(newData, "textileSizing.bodyType", oldData.bodyType);

      // Material tab
      objectPath.set(newData, "material.color", oldData.color);
      objectPath.set(newData, "material.fiberContent", oldData.fiberContent);
      objectPath.set(newData, "material.materialInfo", oldData.materialInfo);
      objectPath.set(newData, "material.generalFiberInfo", oldData.generalFiberInfo);

      // productLifecycleStage tab
      objectPath.set(newData, "productLifecycleStage.introduction", oldData.introduction);
      objectPath.set(newData, "productLifecycleStage.growth", oldData.growth);
      objectPath.set(newData, "productLifecycleStage.maturity", oldData.maturity);
      objectPath.set(newData, "productLifecycleStage.decline", oldData.decline);

      // Production and buying tab
      objectPath.set(newData, "production.piecesProduced", oldData.piecesProduced);
      objectPath.set(newData, "production.whereProduced", oldData.whereProduced);
      objectPath.set(newData, "production.productionPrice", oldData.productionPrice);
      objectPath.set(newData, "production.season", oldData.season);

      return newData;

    default:
      break;
  }
};


export const findVal = (object, key) => {

  var value;
  Object.keys(object).some(function (k) {
    if (k === key) {
      value = object[k];
      return true;
    }
    if (object[k] && typeof object[k] === 'object') {
      value = findVal(object[k], key);
      return value !== undefined;
    }
  });
  return value;
}
