import React, { useEffect, useState, useContext } from "react";
import { getClickstreamTotalCount, getClickstreamCompanies, getClickstreamListProducts, getClickstreamListProductsNotFound, getClickstreamTotalCountNotFound } from "src/redux/analytics/analyticsThunk";
import { Dropdown } from "semantic-ui-react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import DatePicker from "react-datepicker";
import { AppContext } from "../AppContext";

import LineGraph from "./LineGraph";
import { Spinner } from "src/components/Spinner";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";
import { getManufacturerSpecificProducts } from "src/redux/products/productThunk";

let emptyLineData = [
  {
    id: "Scans",
    data: [
      {
        x: 0,
        y: 0,
      },
    ],
  },
  {
    id: "Errors",
    data: [
      {
        x: 0,
        y: 0,
      },
    ],
  },
];

const ClickstreamScans = (props) => {
  const { selectedAnalyticsView } = props
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const context = useContext(AppContext);


  const analyticsState = useSelector((state) => state.analytics, shallowEqual);
  const productsState = useSelector((state) => state.products, shallowEqual);

  const { actionsLoading: isLoading, analyticsClickstreamTotalCount } = analyticsState
  // State variables
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [chartMode, setChartMode] = useState("total");
  const [chartTitle, setChartTitle] = useState(t("Total number of scans and errors"));
  const [currentCompany, setCurrentCompany] = useState("all");
  const [currentArticle, setCurrentArticle] = useState("all");
  const [selectCompanyData, setSelectCompanyData] = useState([{ text: t("All companies"), value: "all" }])
  const [selectArticleData, setSelectArticleData] = useState([{ text: t("All Articles"), value: "all" }])
  const [productErrorState, setProductErrorState] = useState("");
  const [startDateFlag, setStartDateFlag] = useState(false)
  const [endDateFlag, setEndDateFlag] = useState(false)
  const [startEpoch, setStartEpoch] = useState(null);
  const [endEpoch, setEndEpoch] = useState(null);

  const scans = [];
  const webScan = [];
  const mobileScan = [];
  const errors = [];
  const labels = [];

  let lineGraphData = [
    {
      id: t("Scans"),
      data: [],
    },
    {
      id: t("Errors"),
      data: [],
    },
  ];

  // Load initial data
  useEffect(() => {
    getAllCompaniesAndProductsList();
  }, []);

  useEffect(() => {
    handleAPICallBasedOnDateChange();
  }, [startDateFlag, endDateFlag]);

  const getAllCompaniesAndProductsList = async () => {
    try {
      const companiesData = await dispatch(getClickstreamCompanies()).unwrap();
      const productList = await dispatch(getClickstreamListProducts()).unwrap();

      const newSelectCompanyData = context?.authState?.level == 1 ? [] : [{ text: t("All companies"), value: "all" }];
      const newSelectArticleData = [{ text: t("All Articles"), value: "all" }];

      // Generate data for company dropdown
      if (!!companiesData) {
        companiesData.forEach((company) => {
          const sortedItems = [];

          // Push VATID first, then GLN, and finally others
          company.businessIdentifiersWithType.forEach((item) => {
            if (item.type === 'VATID') {
              sortedItems.unshift(item); // Push VATID to the beginning of the array
            } else if (item.type === 'GLN') {
              sortedItems.splice(sortedItems.length > 1 ? 1 : 0, 0, item); // Push GLN at the second position if VATID exists
            } else {
              sortedItems.push(item); // Push other types to the end of the array
            }
          });

          sortedItems.forEach((item, index) => {
            newSelectCompanyData.push({
              text: company.name + " " + item.id,
              value: item.id,
              key: `${item.id}_${index}_${generateRandom4DigitNumber()}`,
              bids: company.businessIdentifiersWithType
            });
          });
        });
      }

      // Generate data for article dropdown
      if (!!productList) {
        productList?.forEach((item, index) => {
          newSelectArticleData.push({ text: item?.gtin ? item?.gtin : item?._2an, value: item?.gtin ? item?.gtin : item?._2an, key: `${index}_${generateRandom4DigitNumber()},`, gtin: item?.gtin, _2an: item?._2an });
        });
      }

      if (context?.authState?.level == 1) {
        handleArticleChange(newSelectArticleData?.[0]?.value, newSelectArticleData, "initialProductSelect")
      } else {
        handleArticleChange(newSelectArticleData?.[0]?.value, newSelectArticleData)
      }

      if (newSelectArticleData?.[0]?.value && context.authState.level === 1) {
        handleCompanyChange(newSelectCompanyData?.[0]?.value, newSelectCompanyData, newSelectCompanyData?.[0]?.value)
      }
      setSelectCompanyData(newSelectCompanyData)
      setSelectArticleData(newSelectArticleData)
    } catch (error) {

    }
    setProductErrorState("")
  }

  const handleAPICallBasedOnDateChange = async () => {
    if (startDateFlag && endDateFlag) {
      // Both flags are true, so execute your logic here
      let gtinValue = selectArticleData?.find(option => option.value === currentArticle)?.gtin;
      let _2anValue = selectArticleData?.find(option => option.value === currentArticle)?._2an;
      let queryString = "";

      if (currentArticle === "all") {
        queryString = `?start=${startEpoch}&end=${endEpoch}`;
      } else {
        if (gtinValue) {
          queryString = `?gtin=${gtinValue}&start=${startEpoch}&end=${endEpoch}`;
        } else if (_2anValue) {
          queryString = `?_2an=${_2anValue}&start=${startEpoch}&end=${endEpoch}`;
        } else {
          queryString = "";
        }
      }
      if (currentCompany !== "all") {
        queryString = queryString + `&businessid=${currentCompany}`
      }
      await dispatch(getClickstreamTotalCount(queryString));
      setStartDateFlag(false)
      setEndDateFlag(false)
    }
  }

  // Convert epoch to date
  const convertEpoch = (epoch) => {
    var d = new Date(0);
    d.setUTCSeconds(epoch);
    return (
      d.getUTCDate() + "." + (d.getUTCMonth() + 1) + "." + d.getUTCFullYear()
    );
  };

  function generateRandom4DigitNumber() {
    return Math.floor(Math.random() * 9000) + 1000;
  }

  // Convert YearMonthDate formatted timestamp
  const convertTimestamp = (timestamp) => {
    const dateArray = timestamp.split(/^(\d{4})(\d{2})(\d{2})/);
    return dateArray[3] + "." + dateArray[2] + "." + dateArray[1];
  };

  // Set article dropdown data
  const setArticleDropdownData = (productList) => {
    const newSelectArticleData = [{ text: t("All Articles"), value: "all" }];
    // Generate data for article dropdown
    if (!!productList) {
      productList?.forEach((item, index) => {
        newSelectArticleData.push({ text: item?.gtin ? item?.gtin : item?._2an, value: item?.gtin ? item?.gtin : item?._2an, key: `${index}_${generateRandom4DigitNumber()},`, gtin: item?.gtin, _2an: item?._2an });
      });
    }
    setSelectArticleData(newSelectArticleData)
  }

  // Handle date change
  const handleDateChange = async (date, type) => {
    if (type === "start") {
      if (date > endDate) {
        alert(t("Start date can't be greater than end date"));
        return;
      } else {
        setStartDate(date);
        setStartDateFlag(true);
        const startTime = Math.floor(date.getTime() / 1000);
        setStartEpoch(startTime);
      }
    } else {
      if (date < startDate) {
        alert(t("End date can't be lesser than start date"));
        return;
      } else {
        setEndDateFlag(true);
        setEndDate(date);
        const endTime = Math.floor(date.getTime() / 1000);
        setEndEpoch(endTime);
      }
    }
  };

  const setDefaultDates = () => {
    setStartDate(moment().subtract(30, 'days').toDate())
    setEndDate(new Date())
  }


  // Handle company change
  const handleCompanyChange = async (value, selectCompanyList = selectCompanyData, companyValue) => {
    setChartMode("company");
    if (value === "all" && companyValue !== "all") {
      const productList = await dispatch(getClickstreamListProducts()).unwrap();
      await dispatch(getClickstreamTotalCount(""));
      setArticleDropdownData(productList);
      setCurrentCompany("all");
      setChartTitle(t("Total number of scans and errors"));
      setDefaultDates();
    } else {
      setCurrentCompany(value);
      let allBidsValue = selectCompanyList?.find(option => option.value === value)?.bids
      if (context?.authState?.level == 10) {
        try {
          const productList = await dispatch(getClickstreamListProducts(`?businessId=${JSON.stringify(allBidsValue)}`)).unwrap();
          setArticleDropdownData(productList);
          setProductErrorState("")
        } catch (error) {
          const productList = await dispatch(getClickstreamListProductsNotFound()).unwrap();
          setSelectArticleData(productList)
          setProductErrorState(error)
          await dispatch(getClickstreamTotalCountNotFound())
        }

      }
      let queryParams = `?businessid=${value}`
      await dispatch(getClickstreamTotalCount(queryParams));
      setCurrentArticle("all");
      setDefaultDates();
      setChartTitle(`${t("Scans And Errors Of Business Id")} ${value}`);
    }
  };

  // Handle article change
  const handleArticleChange = async (value, selectArticleList = selectArticleData, flagForInitialProductSet) => {
    setChartMode("article");
    if (value === "all" && currentCompany !== "all") {
      setCurrentArticle("all");
      let queryParams = `?businessid=${currentCompany}`
      await dispatch(getClickstreamTotalCount(queryParams));
      setChartTitle(t("Total number of scans and errors"));
      setDefaultDates();
    } else if (value == "all" && flagForInitialProductSet !== "initialProductSelect") {
      setCurrentArticle("all");
      await dispatch(getClickstreamTotalCount(""));
      setChartTitle(t("Total number of scans and errors"));
      setDefaultDates();
    }
    else if (flagForInitialProductSet !== "initialProductSelect") {
      setCurrentArticle(value);
      let gtinValue = selectArticleList?.find(option => option.value === value)?.gtin;
      let _2anValue = selectArticleList?.find(option => option.value === value)?._2an;
      let queryString = "";
      if (gtinValue && _2anValue) {
        queryString = `?gtin=${gtinValue}&_2an=${_2anValue}`;
      } else if (gtinValue) {
        queryString = `?gtin=${gtinValue}`;
      } else if (_2anValue) {
        queryString = `?_2an=${_2anValue}`;
      } else {
        queryString = "";
      }
      if (currentCompany !== "all") {
        queryString = queryString + `&businessid=${currentCompany}`
      }
      await dispatch(getClickstreamTotalCount(queryString));
      setDefaultDates();
      setStartDateFlag(false)
      setEndDateFlag(false)
      setChartTitle(`${t("Scans And Errors Of Article Id")} ${value}`);
    }
  };

  // Map count to arrays
  if (!!analyticsClickstreamTotalCount) {
    analyticsClickstreamTotalCount.forEach((item) => {
      scans.push(item.scans)
      webScan.push(item.scans - item.mobileScan)
      mobileScan.push(item.mobileScan)
      errors.push(item.errors)
      labels.push(String(item.timestamp).length > 8 ? convertEpoch(item.timestamp).split(" ")[0] : convertTimestamp(item.timestamp))
    })

    //create data model for nivo line graph
    lineGraphData = [
      {
        id: t("Scans"),
        data: [],
      },
      {
        id: t("Errors"),
        data: [],
      },
    ];


    lineGraphData.forEach((o, index) => {

      _.forEach(analyticsClickstreamTotalCount, function (e) {
        let timestamp = 0;
        if (String(e.timestamp).length > 8) {
          timestamp = convertEpoch(e.timestamp).split(" ")[0];
        } else {
          timestamp = convertTimestamp(e.timestamp);
        }

        let showScan
        if (selectedAnalyticsView === "all") {
          showScan = e.scans
        } else if (selectedAnalyticsView === "mobile") {
          showScan = e.mobilescans
        } else if (selectedAnalyticsView === "web") {
          showScan = e.scans - e.mobilescans
        }

        o.data.push({
          x: timestamp,
          y: index == 0 ? showScan : e.errors,
        });
      });
    });
  }



  // Parameters for chart
  const data = {
    labels: labels,
    datasets: [
      {
        label: t("Scans"),
        data: scans,
        borderColor: "#0000FF",
        backgroundColor: "#0000FF",
        fill: false,
        borderWidth: 2,
        tension: 0,
      },
      {
        label: t("Errors"),
        data: errors,
        borderColor: "#FF0000",
        backgroundColor: "#FF0000",
        fill: false,
        borderWidth: 2,
        tension: 0,
      },
    ],
  };

  // Options for chart
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: chartTitle,
    },
  };

  return (
    <>
      {analyticsState?.clickStreamTotalCountLoading || analyticsState?.clickStreamListingProduct ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div>

          <div className="row mt-4">
            <div className="col-lg-5 col-md-6 col-sm-12 mb-3" style={{ marginTop: 14 }}>
              <Dropdown
                className="analytics-dropdown"
                style={{ backgroundColor: "#F8FCFD", width: "90%" }}
                value={currentCompany}
                name="company"
                onChange={(e, { name, value }) => {
                  handleCompanyChange(value);
                }}
                placeholder={"Select Company"}
                search
                selection
                options={selectCompanyData}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3" style={{ marginTop: 14 }}>
              <Dropdown
                className="analytics-dropdown"
                style={{ backgroundColor: "#F8FCFD", width: "90%" }}
                value={currentArticle}
                name="article"
                onChange={(e, { name, value }) => {
                  handleArticleChange(value);
                }}
                placeholder={"Select Article"}
                search
                selection
                options={selectArticleData}
              />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 mb-3">
              <label className="me-2" htmlFor="startDatePicker">
                Start date
              </label>
              <DatePicker
                dateFormat="YYYY-MM-dd"
                id="startDatePicker"
                selected={startDate}
                onChange={(date) => handleDateChange(date, "start")}
              />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 mb-3">
              <label className="me-2" htmlFor="endDatePicker">
                End date
              </label>
              <DatePicker
                dateFormat="YYYY-MM-dd"
                id="endDatePicker"
                selected={endDate}
                onChange={(date) => handleDateChange(date, "end")}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 mx-3 border lineGraphContainerStyle">
              {/* <Line data={data} options={options} /> */}
              <LineGraph lineData={lineGraphData} title={chartTitle} productNotFound={productErrorState} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClickstreamScans;
