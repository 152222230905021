import { createSlice } from "@reduxjs/toolkit";
import { getVouchersAction, createVouchersAction, deleteVouchersAction } from "./adminThunk";

const initialState = {
    isLoading: false,
    voucherList: [],
    isShowAddUpdateVoucherModal: false,
    error: null,
};

const adminSlice = createSlice({
    name: "adminSlice",
    initialState: initialState,
    reducers: {
        showAddUpdateVoucherModalAction: (state, action) => {
            state.isShowAddUpdateVoucherModal = action.payload;
        },
    },
    extraReducers:
        (builder) => {
            builder
                /** RETRIEVE VOUCHER*/
                .addCase(getVouchersAction.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(getVouchersAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.voucherList = action.payload;
                })
                .addCase(getVouchersAction.rejected, (state, action) => {
                    state.isLoading = false;
                    state.voucherList = [];
                    state.error = action.payload;
                })


                /** CREATE VOUCHER*/
                .addCase(createVouchersAction.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(createVouchersAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                })
                .addCase(createVouchersAction.rejected, (state, action) => {
                    state.isLoading = false;
                    state.error = action.payload;
                })


                /** DELETE VOUCHER*/
                .addCase(deleteVouchersAction.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(deleteVouchersAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                })
                .addCase(deleteVouchersAction.rejected, (state, action) => {
                    state.isLoading = false;
                    state.error = action.payload;
                })

        },
});

export const { showAddUpdateVoucherModalAction } = adminSlice.actions;

export default adminSlice;
