import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { changeProdEditMode, currentUpidsFormObject } from "src/redux/products/productsSlice";
import { getProductByIdAction, getProductTemplateSchemaAction, getUpidsProductById } from "src/redux/products/productThunk";
import ProductSidebar from "../components/ProductSideBar";
import foodPic from "../../../assets/images/Dummy_Product_Pic.png";
import qrcode from "../../../assets/images/qrcode.png";
import './UpidsForm'
import UpidsForm from "./UpidsForm";
import { Spinner } from "src/components/Spinner";
import { EDIT } from 'src/helper/constants/constants'
import _ from "lodash"


function ViewUpidsProduct() {
  let { gtin, upids } = useParams();
  const dispatch = useDispatch();

  const _state = useSelector((state) => state.products, shallowEqual);
  const allViewDataForUpidsProduct = !!_state.upidsProductById && _state.upidsProductById;

  const [loader, setLoader] = useState(true);

  const loading = _state.getUpidsProductLoader;

  useEffect(() => {
   getUpidsProductData();
    return () => {
      dispatch(changeProdEditMode(false));
      dispatch(currentUpidsFormObject({}));

    };

  }, []);

  const getUpidsProductData = async () =>{
    await getProductData_template()
    await dispatch(getUpidsProductById({ upidsId: upids }))
    setLoader(false)
  }


  useEffect(() => {
    dispatch(currentUpidsFormObject(allViewDataForUpidsProduct));
  }, [allViewDataForUpidsProduct]);

  const getProductData_template = async () => {
    try {
      const productData = await dispatch(getProductByIdAction({ isLoader: true, gtin: gtin })).unwrap();
      await dispatch(getProductTemplateSchemaAction({ action: EDIT, productType: productData.responseData.productType }))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    loader ? 
    <Spinner />
     :
      <div>
        {!!_state.productById && (
          <div className="product-profile upids-view-container row" >
            <ProductSidebar
              prod_image={
                !!_state.productById.multimedia && _state.productById.multimedia.primary ? _state.productById.multimedia.primary[0] :
                  !!_state.productById.media &&
                    _state.productById.media.images[1024][0] !== "" &&
                    _state.productById.media.images[1024][0] !== null
                    ? _state.productById.media.images[1024][0]
                    : foodPic
              }
              prod_title={
                !!_state.productById.productName
                  ? _state.productById.productName.en
                  : ""
              }
              prod_subtitle={
                _state.productById.descriptionShort
                  ? _state.productById.descriptionShort.en
                  : ""
              }
              prod_desc={_state.productById.brandName}
              prod_status="Published"
              productData={_state.productById}
              prod_qrcode={qrcode}
              prod_upids={upids}
              isUpidsProd={true}
              upidsIdData={allViewDataForUpidsProduct}
            />
            {/* {!!_state.upidsProductDetail && _state.upidsProductDetail.length > 0 && <UpidsForm loading={_state.actionsLoading} productData={_state.upidsProductDetail[0]} />} */}
            {<UpidsForm loading={loading} />}

          </div>
        )}
      </div>
  );
}

export default ViewUpidsProduct;
