import React, { forwardRef } from "react";
import { MdSort } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Spinner } from "src/components/Spinner"
import { MaterialTable } from "src/components/MaterialTable";
import { useTranslation } from "react-i18next";

import objectPath from "object-path";

import _ from "lodash";

function UsersTable(props) {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const tableIcons = {
    SortArrow: forwardRef((props, ref) => <MdSort {...props} ref={ref} />),
  };

  const allData = [];

  const handleEditClickEvent = (o, p) => {
    navigate(`/users/profile/${o.uId}`);
  };

  const createTableData = () => {
    if (!!props.usersList && props.usersList.length > 0) {
      props.usersList.map((o, index) => {
        allData.push({
          // businessId: o.companies && o.companies[0] && o.companies[0].businessId ? o.companies[0].businessId : o.companies[0].id,
          businessId: o.companies.length > 0 ? objectPath.get(_.find(o.companies, function (o) { return o.type === "VATID" }), "id", o.companies[0].businessId ? o.companies[0].businessId : o.companies[0].id) : '',
          fullname: o.foreName + " " + o.sureName,
          email: o.email,
          phoneNumber: o.phoneNumber,
          isNewUser: o.userLevel == 0 ? true : false,
          action: (
            <MdEdit key={index}
              style={{ cursor: "pointer" }}
              onClick={(e) => handleEditClickEvent(o)}
            />
          ),
        });
      });
    }
  };

  createTableData();

  return (
    <>
      {props.loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <MaterialTable
          columnNames={[
            { title: t("_Business Id"), field: "businessId" },
            { title: t("Full Name"), field: "fullname" },
            { title: t("Email Address"), field: "email" },
            { title: t("Phone Number"), field: "phoneNumber" },
            { title: t("Action"), field: "action", sorting: false, headerStyle: { textAlign: 'center' }, cellStyle: { textAlign: 'center' } },
          ]}
          tableData={allData}
        ></MaterialTable>
      )}
      <br></br>
    </>
  );
}

export default UsersTable;
