import React, { useState, useEffect } from "react";
import { currentUpidsFormObject, addCustomMultiInputFields } from "src/redux/products/productsSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Dropdown } from "semantic-ui-react";
import { FcOk } from "react-icons/fc";
import { usePrevious } from "../../../helper/CustomHook";
import { MdClose, MdAddCircleOutline } from "react-icons/md";
import { getAllTimeZones } from "src/helper/timezones";
import SpinnerLoader from "../../components/Spinner";
import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from "src/components/StSquareButton";
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel";
import { AddButton } from "src/components/AddButton";
import { InputSelectDropdown } from "src/components/inputComponents/InputSelectDropdown";
import { IconButton } from "src/components/IconButton";
import { useTranslation } from "react-i18next";
import { JSONTree } from "react-json-tree";
import { TabAccordion } from "src/components/TabAccordion";
import objectPath from "object-path";
import { salesDigitalTypeData, salesStatusData } from "src/helper/MetadataCollection";

let inputFieldsToRender = []
const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633'
};
function UpidsForm(props) {
  const [show, setShow] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState({
    valueError: ""
  })
  const [customField, setCustomField] = useState({ customFieldType: '', customFieldToAdd: '' })
  const [customMultiValuesInput, setCustomMultiValuesInput] = useState([])
  const [renderInputFields, setRenderInputFields] = useState([])
  const [addLinkedUpidsModal, setAddLinkedUpidsModal] = useState(false);
  const [addObjectKeyValueModal, setAddObjectKeyValueModal] = useState(false);
  const [linkedUpidsValue, setLinkedUpidsValue] = useState('');
  const [customObjectKeysArray,setCustomObjectKeysArray]= useState(null);
  const [newKeyValuePair,setNewKeyValuePair]= useState({});
  const [attributeToChange,setAttributeToChange] =useState("");

  const _state = useSelector((state) => state.products, shallowEqual);
  let productData = _state.currentUpidsObject;
  let dropdownValue = productData && productData.abbr
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const response = _state.response;
  const prevPropsState = usePrevious({ response });

  useEffect(() => {
    if (prevPropsState) {
      if (
        prevPropsState.response !== response &&
        response.id &&
        response !== ""
      ) {
        setShowSubmit(true);
      }
    }
  }, [_state]);

  let selectTimezoneOption = [];
  const allTimezones = getAllTimeZones();

  const checkLinkedUpidsPresent = () => {
    if (!!productData.linkedUpids) {
      return true
    } else {
      return false
    }
  }

  const checkLinkedUpidsSelected = () => {
    if (customField.customFieldToAdd == "linkedUpids" || customField.customFieldToAdd == "directCommerce") {
      return true
    } else {
      return false
    }
  }

  const checkDirectCommerceSelected = () => {
    if (customField.customFieldToAdd == "directCommerce") {
      return false
    } else {
      return true
    }
  }

  // dropdowns for custom field
  const selectFieldTypeOptions = [
    { key: 1, text: 'Single value', value: 'Single value', disabled: checkLinkedUpidsSelected() },
    { key: 2, text: 'Multiple values', value: 'Multiple values' },
    { key: 2, text: 'Object', value: 'Object', disabled: checkLinkedUpidsSelected()  },

  ]
  const fieldToAddOptions = [
    { key: 1, text: 'Linked Upids', value: 'linkedUpids', disabled: checkLinkedUpidsPresent() },
    { key: 2, text: 'Other Field', value: 'otherField' },
  ]

  const inputFieldTypeArray =[
    {key: 1, text:'number', value:'number'},
    {key: 2, text:'text', value:'text'},
  ]

  const createEventDropdownData = (allTimezones) => {
    selectTimezoneOption = [];
    allTimezones.map((o, index) => {
      let eve = { key: index, text: o.text, value: o.abbr };
      selectTimezoneOption.push(eve);
    });
  };
  createEventDropdownData(allTimezones);

  const handleClose = () => {
    setError({ ...error, valueError: "" })
    setCustomField({ customFieldType: '', customFieldToAdd: '' })
    setShow(false);
  }

  const handleSubmitClose = () => setShowSubmit(false);

  const handleOkClick = () => {
    setShowSubmit(false)
  }



  const handleChange = (e) => {
    setInputValue(e.target.value)
    setError({ ...error, valueError: '' })
  }

  const handleClick = () => {
    if (inputValue === "") {
      setError({ ...error, valueError: "Please enter new form item here" })
    }
    else {
      dispatch(
        currentUpidsFormObject({
          ...productData,
          [inputValue]: ""
        })
      );
      setShow(false);
      setInputValue("");
      setCustomField({ ...customField, customFieldType: '', customFieldToAdd: '', customFieldNumberOfValues: '' })
    }


  };

  const getLable = (text) => {
    return text.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  }



  // useEffect(() => {
  //   let tArray = [];
  //   for (let key in productData) {
  //     if (key !== "timezone" && key !== "abbr" && key !== "businessIdentifiers" && key !== "gtin") {
  //       if (upidsFormFields.includes(key))
  //         tArray.push({ name: key, lable: getLable(key), readOnly: true, value: productData[key] });
  //       else
  //         tArray.push({ name: key, lable: getLable(key), readOnly: false, value: productData[key] });
  //     }
  //   }
  //   setData([...tArray])
  // }, [_state])

  const handleFormChange = (e) => {
    dispatch(
      currentUpidsFormObject({
        ...productData,
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleCustomField = (e, data) => {
    if (!!data) {
      setCustomField({ ...customField, [data.name]: data.value })
    } else {
      setCustomField({ ...customField, [e.target.name]: Number(e.target.value) })
    }
  }

  const handleCustomFieldToAdd = (e, data) => {
    if (data.value == "linkedUpids") {
      setCustomField({ ...customField, customFieldType: 'Multiple values', [data.name]: data.value })
      setInputValue('linkedUpids')
    } else {
      setInputValue("")
      setCustomField({ ...customField, [data.name]: data.value })
    }
  }

  const getCustomInputFieldData = (allData) => {
    let dataToAdd = {}
    allData.map((o, i) => {
      let property = { [o.label]: [] }
      let keys = Object.keys(o);
      keys.forEach((key, index) => {
        if (key !== "label") {
          property[o.label].push(o[key])
        }
      });
      dataToAdd = { ...dataToAdd, ...property }
    })
    return dataToAdd
  }

  const handleMultiValuesInputChanged = (e, i) => {
    // setCustomMultiValuesInput({...customMultiValuesInput, [e.target.name]: e.target.value})
    let allData = [...customMultiValuesInput]
    let currentCustomMultiValue = { ...customMultiValuesInput[i], label: inputValue.split(" ").join(""), [e.target.name]: e.target.value }
    allData[i] = currentCustomMultiValue

    let customDataToAdd = getCustomInputFieldData(allData)

    setCustomMultiValuesInput(allData)
    dispatch(addCustomMultiInputFields(customDataToAdd));

  }
  const handleMultipleValuesClick = () => {
    let inputFields = []
    for (let index = 0; index < customField.customFieldNumberOfValues; index++) {
      inputFields.push(`customMultiValuesInput_${index}`)
    }
    let data = { label: inputValue, renderData: inputFields }
    inputFieldsToRender.push(data)
    setRenderInputFields(inputFieldsToRender)
    setShow(false);
    // setInputValue("");
    setCustomField({ ...customField, customFieldType: '', customFieldToAdd: '', customFieldNumberOfValues: '' })
  }

  const handleAddLinkedUpidsValue = () => {
    let data = [...productData.linkedUpids]
    data.push(linkedUpidsValue)
    dispatch(currentUpidsFormObject({ ...productData, linkedUpids: data }));
    setAddLinkedUpidsModal(false)
    setLinkedUpidsValue('')
  }
  const deleteLinkedUpidsValue = (i) => {
    let data = [...productData.linkedUpids]
    data.splice(i, 1);
    dispatch(currentUpidsFormObject({ ...productData, linkedUpids: data }));
  }

  const getUpdatedData = () => {
    let tArray = [];
    for (let key in productData) {
      if (key !== "timezone" && key !== "abbr" && key !== "businessIdentifiers" && key !== "gtin" ) {
        if (upidsFormFields.includes(key))
          tArray.push({ name: key, lable: getLable(key), readOnly: true, value: productData[key] });
        else
          tArray.push({ name: key, lable: getLable(key), readOnly: false, value: productData[key] });
      }
    }
    return tArray
  }

  const getAccordianData = () => {
    let productJsonData = [{
      title: objectPath.get(_state.productById, 'gtin', _state.productById._2an), tabs: () => <JSONTree
        data={_state.productById}
        hideRoot={true}
        sortObjectKeys={true}
        labelRenderer={([key]) => <strong>{key}</strong>}
        valueRenderer={(raw) => <em>{raw}</em>}
        theme={{ extend: theme, }}
        invertTheme={true} />
    },
    {
      title: objectPath.get(productData, 'upidsId'), tabs: () => <JSONTree
        data={_state.currentUpidsObject}
        hideRoot={true}
        sortObjectKeys={true}
        labelRenderer={([key]) => <strong>{key}</strong>}
        valueRenderer={(raw) => <em>{raw}</em>}
        theme={theme}
        invertTheme={true} />
    }]
    return productJsonData
  }

  const handleFormObjectValuesChange = (e,productDataAtrribute,key) => {
      dispatch(currentUpidsFormObject({ ...productData, [productDataAtrribute]: { ...productData[productDataAtrribute],[key]: e.target.type=="number"? parseFloat(e.target.value) : e.target.value} }));
    // if(!!data){
    //     dispatch(action.currentUpidsFormObj({ ...productData, directCommerce: { ...productData.directCommerce,[data.name]: data.value} }));
    // }else{
    //     dispatch(action.currentUpidsFormObj({ ...productData, directCommerce: { ...productData.directCommerce,[e.target.name]: e.target.value} }));
    // }
};

const handleObjectKeyValues=()=>{
let variableName = inputValue;
let keyValueObj={}
customObjectKeysArray.forEach((obj,i)=>{
  keyValueObj = {...keyValueObj,[obj.key]: obj.value}
})
let data = {...productData, [variableName]: keyValueObj}


  // let directCommerceObj = {
  //   deliveryType:"",
  //   price: "",
  //   shipping: "",
  //   status: "",
  //   tax: "",
  // }
  dispatch(currentUpidsFormObject({...productData, [variableName]: keyValueObj}) );
  setShow(false);
  setCustomField({ ...customField, customFieldType: '', customFieldToAdd: '', customFieldNumberOfValues: ''})
}

const handleCustomFieldNumberOfKeyValuePairs=(e)=>{
  let value= Number(e.target.value);
  let keysArray=[];
  for(let i=1; i<=value; i++){
    keysArray.push({key :"", value :"", type:"text"});
  }
  setCustomField({ ...customField, [e.target.name]: value });
  setCustomObjectKeysArray(keysArray);
}

const handleCustomObjectKeyInputChanged=(e,i)=>{
  let currentObjData = [...customObjectKeysArray];
  currentObjData[i][e.target.name] = e.target.value;
  setCustomObjectKeysArray(currentObjData);
}
const handleCustomObjectValuesInputChanged=(e,i)=>{
  let currentObjData = [...customObjectKeysArray];
  currentObjData[i][e.target.name] = currentObjData[i].type == "number" ? parseFloat(e.target.value) : e.target.value;
  setCustomObjectKeysArray(currentObjData);
}

const handleInputTypeOfCustumObject=(e,data,i)=>{
  let currentObjData = [...customObjectKeysArray];
  currentObjData[i].type = data.value;
  setCustomObjectKeysArray(currentObjData);
}

const deleteObjectFromProductData=(productDataAtrribute,key)=>{
  let dataToDelete = {...productData[productDataAtrribute]};
  delete dataToDelete[key];
  dispatch(currentUpidsFormObject({ ...productData, [productDataAtrribute]: dataToDelete }));
}

const handleKeyValueInputChange=(e,data)=>{
  if(!!data){
    setNewKeyValuePair({...newKeyValuePair, type: data.value})
  }else{
    setNewKeyValuePair({...newKeyValuePair, [e.target.name]: e.target.value})
  }
}

const handleAddNewKeyValuePair=()=>{
  let productDataObjectToUpdate = {...productData[attributeToChange]};
  productDataObjectToUpdate = {...productDataObjectToUpdate, [newKeyValuePair.key]: newKeyValuePair.type=="number" ?  parseFloat(newKeyValuePair.value) : newKeyValuePair.value};
  dispatch(currentUpidsFormObject({ ...productData, [attributeToChange]: productDataObjectToUpdate }));
  setAddObjectKeyValueModal(false);
  setNewKeyValuePair({});
}

const addNewObjectModal=(productDataAtrribute)=>{
  setAddObjectKeyValueModal(true);
  setAttributeToChange(productDataAtrribute);
}

  return (
    <>
      {
        props.loading ? (
          <div>
            <SpinnerLoader UpidsProductCard={true} />
          </div>
        ) : (
          <div className="product-profile upids-form-container col-xl-9 col-xxl-8 col-md-12 col-sm-12 col-xs-12 " >
            <div>
              <span className="tab-section-info">{t("View UPIDS Product")}</span>
              <div>
                <div>
                  <form>
                    <div className="row mt-4">
                      {
                        !!productData && getUpdatedData().map((o, index) => (
                          o.lable == "linked Upids" ?
                            <div key={index} className="form-group">
                              <div className="row">
                                <label className="col-lg-8 col-md-8 col-sm-8 col-8">{o.lable}</label>
                                {_state.isProdEditMode ?
                                  <div className='col-lg-4 col-md-4 col-sm-4 col-4'>
                                    <IconButton text={t("Add New")} icon={MdAddCircleOutline} onClick={() => setAddLinkedUpidsModal(true)} />
                                  </div> : ""}
                              </div>
                              <div className="row col-12">
                                {o.value.map((val, i) => (
                                  <div key={i} className="col-6 mt-2 d-flex align-items-center">
                                    <input readOnly={true} value={val}></input>
                                    {_state.isProdEditMode && <MdClose style={{ color: '#cf2948', cursor: 'pointer' }} fontSize={16} fontWeight={'bold'} onClick={() => deleteLinkedUpidsValue(i)} />}
                                  </div>
                                ))}
                              </div>
                            </div> :
                            typeof o.value == "object" ? 
                            <>
                            <div className="row">
                            <label className="col-lg-8 col-md-8 col-sm-8 col-8">{o.lable}</label>
                            {_state.isProdEditMode && !o.readOnly ?
                                  <div className='col-lg-4 col-md-4 col-sm-4 col-4'>
                                    <IconButton text={t("Add New")} icon={MdAddCircleOutline} onClick={() => addNewObjectModal(o.name)} />
                                  </div> : ""}
                            </div>
                              {Object.keys(o.value).map((keyAttribute,i)=>(
                                <>
                                  <InputTextWithLabel
                                  key={i}
                                  label={t(`${keyAttribute}`)}
                                  readOnly={_state.isProdEditMode && o.readOnly === false ? false : true}
                                  name={keyAttribute}
                                  value={o.value[keyAttribute]}
                                  // type="text"
                                  type={typeof o.value[keyAttribute]}
                                  placeholder={t(`${keyAttribute}`)}
                                  onChange={(e)=>handleFormObjectValuesChange(e, o.name, keyAttribute)}
                                ></InputTextWithLabel>
                                {_state.isProdEditMode && !o.readOnly && <span style={{display:'contents'}}><MdClose style={{ color: '#cf2948', cursor: 'pointer' }} fontSize={16} fontWeight={'bold'} onClick={() => deleteObjectFromProductData(o.name,keyAttribute)} /></span>}
                             </>
                             ))}
                            </> :
                              <InputTextWithLabel
                              key={index}
                              label={o.lable.toUpperCase()}
                              readOnly={_state.isProdEditMode && o.readOnly === false ? false : true}
                              name={o.name}
                              value={o.value}
                              type="text"
                              placeholder={o.lable}
                              onChange={handleFormChange}
                            ></InputTextWithLabel>

                        ))
                      }

                      <InputSelectDropdown
                        label={t("Timezone")}
                        placeholder={t("Select Timezone")}
                        value={dropdownValue}
                        name="timezone"
                        options={selectTimezoneOption}
                        disabled={true}
                      />

                    </div>

                    <div className="row col-12">
                      {renderInputFields.length > 0 && renderInputFields.map((field, i) => (
                        <div key={i}>
                          <div className="col-12"><label>{field.label}</label></div>
                          <div className="row col-12">
                            {field.renderData.map((data, index) => (
                              <div key={index} className="form-group col-6">
                                <input
                                  value={customMultiValuesInput[i] ? customMultiValuesInput[i][`multiValuesInput${index + 1}`] : ""}
                                  onChange={(e) => handleMultiValuesInputChanged(e, i)} type="text" name={`multiValuesInput${index + 1}`} placeholder={`Enter ${index + 1} value`} />
                              </div>
                            ))}
                          </div>
                        </div>
                      ))
                      }
                    </div>
                    {_state.isProdEditMode ?
                      <div style={{ backgroundColor: '#F8FCFD', height: 40, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <AddButton buttontext={t('Add New Form Field')} onClick={() => setShow(true)}></AddButton>
                      </div> : ""}
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

      <div>
        {!!_state.currentUpidsObject && <TabAccordion accordionData={getAccordianData()} isOpen={false}></TabAccordion>}
      </div>

      <Modal show={show} dialogClassName="customModalClass" onHide={handleClose} centered={true} scrollable>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ display: 'block', textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18,  }}>
          <div className="form-group col-12">
            <label className="text-start ms-4">{t("Choose Field To Add")}</label>
            <Dropdown
              style={{ width: "95%",  backgroundColor: '#f8fcfd',padding:12,fontWeight:'normal',fontSize:14 }}
              placeholder={t("Select Field")}
              selection
              value={customField.customFieldToAdd}
              options={fieldToAddOptions}
              onChange={handleCustomFieldToAdd}
              name="customFieldToAdd"
            />
          </div>
          <div className="form-group col-12">
            <label className="text-start ms-4">{t("Choose Field Type")}</label>
            <Dropdown
              style={{ width: "95%", backgroundColor: '#f8fcfd',padding:12,fontWeight:'normal',fontSize:14 }}
              placeholder={t("Select Field Type")}
              selection
              value={customField.customFieldType}
              options={selectFieldTypeOptions}
              onChange={handleCustomField}
              name="customFieldType"
            />
          </div>
          {customField.customFieldType == 'Multiple values' &&
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={t("Enter Number Of Values To Enter")}
                name={"customFieldNumberOfValues"}
                type="text"
                placeholder={t("Enter Number Of Values To Enter")}
                value={customField.customFieldNumberOfValues}
                onChange={handleCustomField}
              ></InputTextWithLabel>
            </div>}
          {customField.customFieldToAdd == "otherField" && <div className="form-group col-12"  >
            <InputTextWithLabel isSmallScreen={true}
              label={t("Enter name of new form item")}
              name={"name"}
              type="text"
              placeholder={t("Enter name of field")}
              value={inputValue}
              onChange={handleChange}
              errorMsg={error.valueError ? error.valueError : ''}
            ></InputTextWithLabel>
          </div>}
          {customField.customFieldType == 'Object' &&
          <>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={t("Enter Number Of key-value pairs To Enter")}
                name={"customFieldNumberOfKeyValuePairs"}
                type="text"
                placeholder={t("Enter Number Of key-value pairs To Enter")}
                value={customField.customFieldNumberOfKeyValuePairs}
                onChange={handleCustomFieldNumberOfKeyValuePairs}
              ></InputTextWithLabel>
            </div>
            <div>
            {customObjectKeysArray && customObjectKeysArray.map((objData,i)=>(
              <div className="row ms-4 me-4 form-group">
                <div className="col-4 mt-2">
                     <Dropdown  style={{border:'1px solid #c5cfd6', borderRadius:7,padding:8,fontWeight:'normal',fontSize:14}}
                                  fluid
                                        placeholder={t("Select type")}
                                        value={objData.type}
                                        name="inputType"
                                        options={inputFieldTypeArray}
                                        onChange={(e,data)=>handleInputTypeOfCustumObject(e,data,i)}
                                    />
                </div>
                <div className="col-4">
                <InputTextWithLabel isSmallScreen={true}
                    title={t("")}
                    name={"key"}
                    type={'text'}
                    placeholder={t("Enter key")}
                    value={objData.key}
                    onChange={(e) => handleCustomObjectKeyInputChanged(e, i)}
                  ></InputTextWithLabel>
                </div>
                <div className="col-4">
                <InputTextWithLabel isSmallScreen={true}
                    title={t("")}
                    name={"value"}
                    type={objData.type}
                    placeholder={t("Enter value")}
                    value={objData.value}
                    onChange={(e) => handleCustomObjectValuesInputChanged(e, i)}
                ></InputTextWithLabel>
                </div>
                 
                </div>
            ))}
            </div>
            </>
            }
         
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t('Ok')}
            disabled={customField.customFieldToAdd == 'linkedUpids' ? (!!customField.customFieldNumberOfValues && inputValue != '') ? false : true : customField.customFieldToAdd == 'directCommerce' ? (customField.customFieldType !='' && customField.customFieldToAdd != '') ? false : true : (inputValue == '') ? true : false}
            onClick={customField.customFieldType == 'Multiple values' ? handleMultipleValuesClick : customField.customFieldType == 'Object' ? handleObjectKeyValues : handleClick}>
          </StSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal show={addLinkedUpidsModal} onHide={() => setAddLinkedUpidsModal(false)} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => setAddLinkedUpidsModal(false)}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group col-12">
            <InputTextWithLabel isSmallScreen={true}
              label={"Enter linkedUpids"} type="text" placeholder={"Enter linkedUpids"}
              value={linkedUpidsValue}
              onChange={(e) => setLinkedUpidsValue(e.target.value)}
            ></InputTextWithLabel>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Add")} onClick={() => handleAddLinkedUpidsValue()} disabled={linkedUpidsValue == "" ? true : false}></StSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal show={showSubmit} onHide={handleSubmitClose} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleSubmitClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18,  }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FcOk
              style={{
                width: 40,
                height: 40,
                textAlign: "center",
                alignSelf: "center",
              }}
            />
            <span>{t("Upids Product Inserted Successfully")}</span>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Ok")} onClick={() => handleOkClick()}></StSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal show={addObjectKeyValueModal} onHide={() => setAddObjectKeyValueModal(false)} centered={true}
              dialogClassName="customModalClass"
            //  dialogClassName="modal-90w"
            //  aria-labelledby="example-custom-modal-styling-title"
              >
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => setAddObjectKeyValueModal(false)}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body >
        <div className="row">
          <div className="col-4 form-group">
            <label>{t("Select type")}</label>
               <Dropdown    style={{border:'1px solid #c5cfd6', borderRadius:7,padding:8}}
                                        fluid
                                        placeholder={t("Select type")}
                                        value={newKeyValuePair.type}
                                        name="inputType"
                                        // options={inputFieldTypeArray}
                                        options={inputFieldTypeArray}
                                        onChange={(e,data)=>handleKeyValueInputChange(e,data)}
                                    />
          </div>
          <div className="col-4">
          <InputTextWithLabel isSmallScreen={true}
                    title={t("Key")}
                    name={"key"}
                    type="text"
                    placeholder={t("Enter key")}
                    value={newKeyValuePair.key}
                    onChange={(e) => handleKeyValueInputChange(e)}
                  ></InputTextWithLabel>
          </div>
          <div className="col-4">
          <InputTextWithLabel isSmallScreen={true}
                    title={t("Value")}
                    name={"value"}
                    type={newKeyValuePair.type ? newKeyValuePair.type : 'text'}
                    placeholder={t("Enter value")}
                    value={newKeyValuePair.value}
                    onChange={(e) => handleKeyValueInputChange(e)}
                ></InputTextWithLabel>
          </div>
         </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Add")} onClick={() => handleAddNewKeyValuePair()} disabled={(newKeyValuePair.key && newKeyValuePair.value) ? false : true }></StSquareButton>
        </Modal.Footer>
      </Modal>

     

    </>
  );
}

export default UpidsForm;

const upidsFormFields = [
  'IdentifierType',
  'sse',
  'timezone',
  'createdDate',
  'modifiedDate',
  'productExpires',
  'expireAt',
  'upidsId',
  'id',
  'articleIdentifier',
  '_2an',
  'Company',
  'targetEnvironment',
  'separator',
]
