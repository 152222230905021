
import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setAiFilledInput } from "src/redux/aiData/aiDataSlice";
import { changeAddProdFormObject } from "src/redux/products/productsSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MdArrowBack,MdAddCircle,MdMenu,MdInfoOutline,MdStars } from "react-icons/md";
import logo from 'src/assets/images/brand_icons/upids-pdm-logo.png'
import {IconButton} from "src/components/IconButton"
import { StMenu } from "src/components/StMenu";
import {Header} from "src/components/Header"

import objectPath from "object-path";
import { diff } from 'json-diff-ts';



const ProductsNavbar = () => {
 
  const productsNavbarCollection=[
    {menuItem:()=><IconButton text={t("Show Favourite Products")} icon={MdStars} onClick={()=>handleViewFavouritesClick()}/>},
    {menuItem:()=><IconButton text={t("Add Product")} icon={MdAddCircle} onClick={()=>handleAddProductClick()}/>},
  ]

  let pathName = window.location.href;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
 
  const _state = useSelector((state) => state.products, shallowEqual);
  const _translationState = useSelector((state) => state.aiTranslations, shallowEqual);

  useEffect(()=>{
    if(_translationState.translations.length > 0){
      let _prodObj = JSON.parse(JSON.stringify(_state.addProdFormObject));
      _translationState.translations.filter((o)=>{
        if(objectPath.get(_prodObj,`${o.id}_${o.language}`) == "")
          objectPath.set(_prodObj,`${o.id}_${o.language}`,o.translation,false);
        else
          objectPath.set(_prodObj,`${o.id}_${o.language}`,o.translation,true);
      })
      let diffs = diff(_state.addProdFormObject, _prodObj);
      dispatch(setAiFilledInput(diffs.filter(o=>o.type === "ADD" || o.type === "EDIT")));
      dispatch(changeAddProdFormObject({..._prodObj}));
    }
  },[_translationState.translations])

  const handleAddProductClick=()=>{
    navigate('/addproduct')
  }
  const handleViewFavouritesClick = () => {
    navigate("/favourites");
  }

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/dashboard">
          <img src={logo} alt="logo" />
        </Link>
        <Link
          className="navbar-brand brand-logo-mini d-inline-block d-lg-none"
          to="/"
        >
          <img
            src={require("../../../assets/images/logo-mini.svg")}
            alt="logo"
          />
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center">
        {!pathName.includes("/addproduct") ?
          <span
            style={{ fontSize: 24,  fontWeight: 600 }}
          >
            <Trans>{t("Products")}</Trans>
          </span>
           :
          <div>
            <span
              style={{
                
                fontSize: 16,
                color: "#131E27",
                opacity: 1,
              }}
            >
              <MdArrowBack
                onClick={() => navigate(-1)}
                style={{
                  width: 24,
                  height: 24,
                  backgroundColor: "transparent",
                  opacity: 1,
                  cursor: "pointer",
                  marginLeft: -10,
                }}
              />
              <Trans></Trans>
            </span>

            <span style={{marginLeft:20}}> <Header text={t("Add New Product")}/> </span>
          </div>}


        <ul className="navbar-nav navbar-nav-right">
          {!pathName.includes("/addproduct") ?
            <li className="nav-item nav-profile d-none d-sm-flex">
           <IconButton 
              text={t("Show Favourite Products")} 
              icon={MdStars} 
              onClick={()=>handleViewFavouritesClick()}/>
            <IconButton 
              text={t("Add Product")} 
              icon={MdAddCircle} 
              onClick={()=>handleAddProductClick()}/>
           </li> : ""}
        </ul>

{/* responsive dropdown on mobile screen */}
        {!pathName.includes("/addproduct") ? <ul className="navbar-nav navbar-nav-right productNavResponsiveDropdown">
           <StMenu menuArray={productsNavbarCollection}></StMenu>
          </ul> : ""} 

        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
         <MdMenu color="#000000" size={24} />
        </button>
      </div>
    </nav>
  );
};

export default ProductsNavbar;

