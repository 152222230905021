import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './App.js'
// import * as serviceWorker from "./serviceWorker";
import swDev from "./swDev";

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// serviceWorker.unregister();
swDev();
