import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { InputTextWithLabel } from 'src/components/inputComponents/InputTextWithLabel'
import { gateSpecificCmsContentAction } from 'src/redux/contentManagement/contentManagementThunk';
import { updateCmsDataFormAction, resetCmsDataFormAction, changeEditModeAction } from 'src/redux/contentManagement/contentManagementSlice';
import { InputSelectDropdown } from 'src/components/inputComponents/InputSelectDropdown';
import { Spinner } from "src/components/Spinner";
import DatePicker from "react-datepicker";
import moment from 'moment';
import JoditEditotRichText from './JoditEditotRichText';


function ContentmanagementEdit() {
  const { t } = useTranslation("common");
  const { id } = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [cmsCategoriesList, setCmsCategoriesList] = useState([]);

  const companyState = useSelector((state) => state.company, shallowEqual);
  const { companyInfo } = companyState

  const cmsContent = useSelector((state) => state.cmsContent, shallowEqual);
  const { isEditMode, cmsFormData, isLoading, allCmsCategoriesList } = cmsContent

  const [createdDate, setCreatedDate] = useState();

  useEffect(() => {
    dispatch(resetCmsDataFormAction())
    dispatch(changeEditModeAction(false))
    fetchContentDataWithId();

    let categories = [];
    if (allCmsCategoriesList?.categories) {
      allCmsCategoriesList?.categories?.forEach((templateData, i) => {
        categories.push({ key: i, text: templateData, value: templateData });
      });
      setCmsCategoriesList(categories);
    }
    return () => {
    }
  }, [])

  useEffect(() => {
    if (cmsFormData.createdDate) {
      setCreatedDate(new Date(cmsFormData.createdDate._seconds * 1000))
    }
  }, [cmsContent])


  const fetchContentDataWithId = async () => {
    try {
      await dispatch(gateSpecificCmsContentAction(id)).unwrap()
    } catch (error) {

    }
  }


  const handleFormChange = (e) => {
    dispatch(updateCmsDataFormAction({
      name: e.target.name,
      value: e.target.value
    }))
  };



  const handleCancel = () => {
    navigate(`/cms`)
  }


  const getSupportedLanguageList = () => {
    if (companyInfo && companyInfo.supportedLanguages && companyInfo.supportedLanguages[0]) {
      const languageOption = companyInfo.supportedLanguages.map((item, index) => {
        return { key: index, text: item.name, value: item.code }
      })
      return languageOption
    }
    else {
      const languageOption = [
        { key: 1, text: "English", value: "en" },
        { key: 2, text: "Finnish", value: "fi" },
      ]
      return languageOption
    }

  }

  // Handle date change
  const handleDateChange = (date, type) => {
    let formatedDate = { ...cmsFormData.createdDate };
    if (date > new Date()) {
      alert(t("Created date can't be greater than today's date"));
      return;
    } else {
      formatedDate._seconds = moment(date).unix();
      dispatch(updateCmsDataFormAction({
        name: "createdDate",
        value: formatedDate
      }))
      setCreatedDate(date);
    }
  };

  const supportedLanguageOption = getSupportedLanguageList();

  return (
    <>
      {isLoading && <Spinner />}
      <div className="row col-12 contentManagementForm">
        <InputTextWithLabel
          label={t("Title")}
          tooltipText={t("Title")}
          onChange={handleFormChange}
          name={"title"}
          value={cmsFormData.title}
          type="text"
          placeholder={t("Title")}
          readOnly={!isEditMode}
        // errorMsg={!!errorObject && errorObject.name ? errorObject.name : ""}
        />
        <InputTextWithLabel
          label={t("Product identifier")}
          tooltipText={t("Product identifier")}
          onChange={handleFormChange}
          name={"productIdentifier"}
          value={cmsFormData.productIdentifier}
          type="text"
          placeholder={t("Product identifier")}
          readOnly={!isEditMode}
        // errorMsg={!!errorObject && errorObject.name ? errorObject.name : ""}
        />

        <InputSelectDropdown
          label={t("_Category")}
          placeholder={t('Select category')}
          name="category"
          value={cmsFormData.category}
          options={cmsCategoriesList}
          onChange={handleFormChange}
          disabled={!isEditMode}
        />

        <InputTextWithLabel
          label={t("Reference")}
          tooltipText={t("Reference")}
          onChange={handleFormChange}
          name={"reference"}
          value={cmsFormData.reference}
          type="text"
          placeholder={t("Reference")}
          readOnly={!isEditMode}
        // errorMsg={!!errorObject && errorObject.name ? errorObject.name : ""}
        />

        <InputTextWithLabel
          label={t("Order")}
          tooltipText={t("Order")}
          onChange={handleFormChange}
          name={"order"}
          value={cmsFormData.order}
          type="number"
          placeholder={t("Order")}
          readOnly={!isEditMode}
        // errorMsg={!!errorObject && errorObject.name ? errorObject.name : ""}
        />

        <InputSelectDropdown
          label={t("Language")}
          placeholder={t('Select language')}
          name="language"
          value={cmsFormData.language}
          options={supportedLanguageOption}
          onChange={handleFormChange}
          disabled={!isEditMode}
        />

        <div className="form-group contentCreatedDatePicker">
          <label className='ms-3'>{("Created date")}</label>
          <DatePicker
            className='col-lg-6 col-md-6 col-sm-6 col-xs-12'
            dateFormat="yyyy-MM-dd HH:mm"
            selected={createdDate}
            showTimeSelect
            disabled={!isEditMode}
            onChange={(date) => handleDateChange(date)}
          />
        </div>

        <div className="form-group col-12" >
          <label>{t("cms content")}</label>
          <JoditEditotRichText
            isEditMode={isEditMode}
            joditEditorValue={cmsFormData.content}
          />
        </div>


      </div>
    </>
  )
}

export default ContentmanagementEdit