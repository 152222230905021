import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next"; 
import { useNavigate } from "react-router-dom";
import { usePrevious } from "../../../helper/CustomHook";
import { userEditSchema } from "../../../helper/yup-schema";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDataByUidAction } from "src/redux/users/usersThunk";
import { deleteUser } from "src/redux/users/usersThunk";
import { editUser } from "src/redux/users/usersThunk";
import { changeUserEditModeAction, usersAllErrorsEditFormObject } from "src/redux/users/usersSlice";
import logo from '../../../assets/images/brand_icons/upids-pdm-logo.png'
import { BiTrash } from "react-icons/bi";
import {MdArrowBack, MdMenu,MdOutlineModeEdit,MdOutlineSave,MdOutlineClose } from "react-icons/md";
import { StMenu } from "src/components/StMenu";
import {IconButton} from "src/components/IconButton"
import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from "src/components/StSquareButton";
import { StCancelSquareButton } from "src/components/StCancelSquareButton";

const UserProfileNavbar = () => {

  const userProfileNavbarCollection=[
    {menuItem: ()=><IconButton text={t("Edit")} icon={MdOutlineModeEdit} onClick={()=>changeMode()}/>,
      submenu:[
        {menuItem: ()=><IconButton text={t("Save Changes")} icon={MdOutlineSave} onClick={()=>handleSaveClick()}/>},
        {menuItem: ()=><IconButton text={t("Delete User")} icon={BiTrash} onClick={()=>handleDeleteClick()}/>},
        {menuItem: ()=><IconButton text={t("Cancel")} icon={MdOutlineClose} onClick={()=>handleCancelClick()}/>}
      ],
      submenuTitle: ()=><IconButton text={t("Edit")} icon={MdOutlineModeEdit} onClick={()=>changeMode()}/>},
  ]

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [show, setShow] = useState(false);
  const [showDelPopup, setShowDelPopup] = useState(false);
  let fullUrl = window.location.href;
  let uid = fullUrl.split("/").pop();

  const _state = useSelector((state) => state.users, shallowEqual);

  const data = _state.currentUserObj;
  const { editResponse, deleteResponse } = _state;
  const prevPropsState = usePrevious({ editResponse, deleteResponse });

  useEffect(() => {
    if (prevPropsState) {
      if (prevPropsState.editResponse !== editResponse && editResponse) {
        dispatch(changeUserEditModeAction(false));
        setShow(false)
      } else if (
        prevPropsState.deleteResponse !== deleteResponse &&
        deleteResponse
      ) {
        navigate("/users");
      }
    }
  }, [_state]);

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  const changeMode = () => {
    dispatch(changeUserEditModeAction(true));
  };

  const handleClose = () => setShow(false);

  let allErrors;
  const handleSaveClick = () => {
    userEditSchema
      .validate(data, { abortEarly: false })
      .then(function () {
        dispatch(usersAllErrorsEditFormObject([]));
      })
      .then(function () {
        setShow(true);
      })
      .catch(function (err, inner) {
        allErrors = err.inner;
        dispatch(usersAllErrorsEditFormObject(allErrors));
      });
  };

  const handleDeleteClick = () => {
    setShowDelPopup(true);
  };

  const handleCancelClick = () => {
    dispatch(changeUserEditModeAction(false));
    dispatch(getDataByUidAction({ uid: uid, isCancelBtn: true }));



  }

  const handleDelClose = () => setShowDelPopup(false);

  const handleSaveChanges = () => {
    if (data.password === "" || data.password === undefined) {
      let userData = createUserModel(data);
      dispatch(editUser({ id: data.uId, body: userData }));
    } else {
      let userDataWithPwd = createUserModelPwd(data);
      dispatch(editUser({ id: data.uId, body: userDataWithPwd }));
    }
  };

  const handleDeleteChanges = () => {
    dispatch(deleteUser(data.uId));
  };

  const createUserModel = (data) => {

    return {
      foreName: data.foreName,
      sureName: data.sureName,
      phoneNumber: data.phoneNumber,
      timezone: data.timezone,
      abbr: data.abbr,
      companyId: data.companyId,
      companyName: data.companyName,
      companies: data.companies,
      isAdmin: data.isAdmin,
      userLevel: data.userLevel,
      language : data.language,
      userRole: data.userRole ? data.userRole : "Not-set",
    };
  };

  const createUserModelPwd = (data) => {
    return {
      foreName: data.foreName,
      sureName: data.sureName,
      phoneNumber: data.phoneNumber,
      timezone: data.timezone,
      abbr: data.abbr,
      companyId: data.companyId,
      companyName: data.companyName,
      companies: data.companies,
      isAdmin: data.isAdmin,
      password: data.password,
      userLevel: data.userLevel,
      language : data.language,
      userRole: data.userRole ? data.userRole : "Not-set",
    };
  };

  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/dashboard">
            <img src={logo} alt="logo" />
          </Link>
          <Link
            className="navbar-brand brand-logo-mini d-inline-block d-lg-none"
            to="/"
          >
            <img
              src={require("../../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">
          <span className="back-arrow-span">
            <MdArrowBack
              className="back-arrow"
              onClick={() => navigate(-1)}
            />
            <Trans></Trans>
          </span>
          <span className="user-profile-text">{t("User Profile")}</span>
          <span className="horizontal-line"></span>
          <span className="full-user-name">{!!data && data.foreName !== undefined && data.sureName !== undefined && data.foreName !== "" && data.sureName !== "" ? data.foreName + " " + data.sureName : ''}</span>

          {!_state.isUserEditMode ? (
            <div className="navbar-nav navbar-nav-right d-none d-sm-flex">
              <IconButton
                text={t("Edit")}
                icon={MdOutlineModeEdit}
                onClick={changeMode}
              />
            </div>
          ) : (
            <div className="navbar-nav navbar-nav-right d-none d-sm-flex">
              <IconButton
                text={t("Save Changes")}
                icon={MdOutlineSave}
                onClick={handleSaveClick}
              />
              <IconButton
                text={t("Delete User")}
                icon={BiTrash}
                onClick={handleDeleteClick}
              />
              <IconButton
                text={t("Cancel")}
                icon={MdOutlineClose}
                onClick={handleCancelClick}
              />
            </div>
          )}

{/* responsive dropdown on mobile screen */}
<ul className="navbar-nav navbar-nav-right productNavResponsiveDropdown">
            <StMenu menuArray={userProfileNavbarCollection}></StMenu>
           </ul>

          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
             <MdMenu color="#000000" size={24} />
          </button>
        </div>
      </nav>

      <Modal size="md" show={show} onHide={handleClose} centered={true}>
        <Modal.Header >
        <div style={{position:'absolute',right:10}}>
                    <StCloseButton text={t("Close")} onClick={()=>handleClose()}></StCloseButton>
                    </div>
        </Modal.Header>
        <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18,  }}>
          <span >{t("Are you sure want to save the changes")}</span>
        </Modal.Body>
        <Modal.Footer>
          <StSquareButton text={t("Save Changes")} onClick={()=>handleSaveChanges()} loading={_state.isLoading ? true : false} disabled={false}></StSquareButton>
         <StCancelSquareButton text={t("Discard")} onClick={()=>handleClose()}></StCancelSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal size="md" show={showDelPopup} onHide={handleDelClose} centered={true}>
        <Modal.Header >
        <div style={{position:'absolute',right:10}}>
                    <StCloseButton text={t("Close")} onClick={()=>handleDelClose()}></StCloseButton>
                    </div>
        </Modal.Header>
        <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18,  }}>
          <span>{t("Are you sure want to delete the user")}</span>
        </Modal.Body>
        <Modal.Footer>
          <StSquareButton text={t("Yes")} onClick={()=>handleDeleteChanges()} loading={_state.isDeleteLoading ? true : false} disabled={false}></StSquareButton>
         <StCancelSquareButton text={t("No")} onClick={()=>handleDelClose()}></StCancelSquareButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserProfileNavbar;
