import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { usersAllErrorsEditFormObject, currentUserFormObject, changeUserEditModeAction, } from "src/redux/users/usersSlice";
import { getDataForSpecificUserAction } from "src/redux/users/usersThunk";
import { getAllTimeZones } from "src/helper/timezones";
import AddUserImage from "../../assets/images/addUser.png";
import { AppContext } from '../AppContext';
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel";
import { InputSelectDropdown } from "src/components/inputComponents/InputSelectDropdown";
import { Spinner } from "src/components/Spinner";
import { InputSwitchWithLable } from "src/components/inputComponents/InputSwitchWithLable";
import { getCompany } from "src/redux/company/companyThunk";
import { InputTextPasswordWithLabel } from "src/components/inputComponents/InputTextPasswordWithLabel";

function EditOwnProfile() {
    const context = useContext(AppContext)
    const { t } = useTranslation('common');
    let timezoneValue;
    let selectTimezoneOption = [];
    const allTimezones = getAllTimeZones();

    const dispatch = useDispatch();
    const _state = useSelector((state) => state.users, shallowEqual);
    const allViewData = _state.specificUserList;

    const _companyState = useSelector((state) => state.company, shallowEqual);
    const companyNameList = _companyState.companyList

    useEffect(() => {
        dispatch(getCompany({}));
        dispatch(getDataForSpecificUserAction({}))
        return () => {
            dispatch(changeUserEditModeAction(false));
            dispatch(currentUserFormObject({}));

        };

    }, []);

    useEffect(() => {
        dispatch(currentUserFormObject(allViewData));
        dispatch(usersAllErrorsEditFormObject([]));
    }, [allViewData]);

    const viewData = _state.currentUserObj;
    let AllErrors = _state.ErrorsformEditObject;

    const createDropdownDataForTimezone = (allTimezones) => {
        selectTimezoneOption = [];
        allTimezones.map((o, index) => {
            let eve = { key: index, text: o.text, value: o.abbr };
            selectTimezoneOption.push(eve);
        });
    };
    createDropdownDataForTimezone(allTimezones);

    const handleFormChange = (e) => {
        dispatch(
            currentUserFormObject({
                ...viewData,
                [e.target.name]: e.target.value,
            })
        );
    };

    const handleFormChangeCompany = (e) => {
        let latestViewData = JSON.parse(JSON.stringify({ ...viewData }));
        latestViewData.companies[0][e.target.name] = e.target.value;
        dispatch(
            currentUserFormObject({
                ...latestViewData,
            })
        );
    };

    const handleTimezoneSelect = (e) => {
        const value = e.target.value
        if (!!value) {
            timezoneValue = allTimezones.find(({ abbr }) => abbr === value);
            dispatch(
                currentUserFormObject({
                    ...viewData,
                    timezone: timezoneValue.offset.toString(),
                    abbr: value,
                })
            );
        }
    };



    const getCurrentComapnyLanguage = (viewData, companyNameList) => {
        let language = [];
        const businessId = viewData && viewData.companyId && viewData.companyId
        const currentCompanyObj = companyNameList && companyNameList[0] && companyNameList.find((item) => item.id === businessId)
        const supportedLanguage = currentCompanyObj && currentCompanyObj.supportedLanguages;
        supportedLanguage && supportedLanguage[0] && supportedLanguage.map((o, index) => {
            let eve = { key: index, label: o.name, value: o.code };
            language.push(eve)
        })
        return language
    }

    const selectCompanyLanguage = getCurrentComapnyLanguage(viewData, companyNameList)

    let errorObject = {};

    const errorShown = (AllErrors) => {
        AllErrors &&
            AllErrors[0] &&
            AllErrors.map((obj, index) => {
                errorObject[obj.path] = obj.message;
            });
    };

    errorShown(AllErrors);

    return (
        <>
            {_state.isGetDataByUidLoading ? (
                <div>
                    <Spinner />
                </div>
            ) : (
                <div className="row add-user main-container">
                    <div className="add-user sidebar-container col-xl-3 col-xxl-4 col-md-4" >
                        <img
                            alt="alt"
                            src={AddUserImage}
                            className="add-user profile-image"
                        ></img>
                        <span className="add-user profile-name">{viewData && viewData.foreName + " " + viewData.sureName}</span>
                        {!!context.authState && context.authState.level === 10 &&
                            <div>
                                <span className="add-user role-title"> User Roles </span>
                                <div className="add-user permission-card" >
                                    <div className="d-flex">
                                        <span className="add-user role-subtitle col-7">Admin</span>
                                        <div className="mt-2 col-5 d-flex justify-content-end" style={{ right: 45 }}>
                                            <InputSwitchWithLable
                                                checked={viewData && viewData.isAdmin === true ? true : false}
                                                onHandleColor='#32cd32'
                                                offHandleColor='#ffffff'
                                                onColor='#32cd32'
                                                offColor='#6c757d'
                                                disabled={_state.isUserEditMode ? false : true}
                                            />
                                        </div>
                                    </div>
                                    <span className="add-user description-text">
                                        Allow admin access to this user so that they can add or edit profile details.
                                    </span>
                                </div>
                            </div>}
                    </div>

                    <div className="add-user form-container col-xl-9 col-xxl-8 col-md-8">
                        {viewData && viewData.companies && viewData.companies[0] && (
                            <form >
                                <div className="row col-12">

                                    <InputTextWithLabel
                                        label={'Email Address'}
                                        readOnly={true}
                                        name={'email'}
                                        value={viewData.email}
                                        type="text"
                                        placeholder={"Email"}
                                        errorMsg={!!errorObject && errorObject.name ? errorObject.name : ""}
                                    ></InputTextWithLabel>

                                    <InputTextWithLabel
                                        label={'First Name'}
                                        readOnly={_state.isUserEditMode ? false : true}
                                        name={'foreName'}
                                        type="text"
                                        placeholder={"First Name"}
                                        value={viewData.foreName}
                                        onChange={handleFormChange}
                                        errorMsg={!!errorObject && errorObject.foreName ? errorObject.foreName : ""}
                                    ></InputTextWithLabel>

                                    <InputTextWithLabel
                                        label={'Last Name'}
                                        readOnly={_state.isUserEditMode ? false : true}
                                        name={'sureName'}
                                        type="text"
                                        placeholder={"Last Name"}
                                        value={viewData.sureName}
                                        onChange={handleFormChange}
                                        errorMsg={!!errorObject && errorObject.sureName ? errorObject.sureName : ""}
                                    ></InputTextWithLabel>

                                    <InputTextWithLabel
                                        label={'Company'}
                                        readOnly={true}
                                        name={'name'}
                                        type="text"
                                        placeholder={"Company Name"}
                                        value={viewData.companyName}
                                        onChange={handleFormChangeCompany}
                                    ></InputTextWithLabel>

                                    <InputTextWithLabel
                                        label={'Phone'}
                                        readOnly={_state.isUserEditMode ? false : true}
                                        name={'phoneNumber'}
                                        type="text"
                                        placeholder={"Phone"}
                                        value={viewData.phoneNumber}
                                        onChange={handleFormChange}
                                        errorMsg={!!errorObject && errorObject.phoneNumber ? errorObject.phoneNumber : ""}
                                    ></InputTextWithLabel>

                                    <InputSelectDropdown
                                        label={"Timezone"}
                                        placeholder="Select Timezone"
                                        value={viewData.abbr}
                                        name="countryCode"
                                        search={true}
                                        disabled={_state.isUserEditMode ? false : true}
                                        options={selectTimezoneOption}
                                        onChange={handleTimezoneSelect}
                                    />

                                    <InputTextPasswordWithLabel
                                        label={t('New Password')}
                                        input="password"
                                        name="password"
                                        readOnly={_state.isUserEditMode ? false : true}
                                        placeholder={t("New Password")}
                                        onChange={handleFormChange}
                                        errorMsg={!!errorObject && errorObject.password ? errorObject.password : ""}
                                    />


                                    <InputTextPasswordWithLabel
                                        label={t('Retype Password')}
                                        input="password"
                                        name="retypePassword"
                                        placeholder={t("New Password")}
                                        readOnly={_state.isUserEditMode ? false : true}
                                        onChange={handleFormChange}
                                        errorMsg={!!errorObject && errorObject.retypePassword ? errorObject.retypePassword : ""}
                                    />

                                    <InputSelectDropdown
                                        label={t("Communication language")}
                                        tooltipText={t("Communication language")}
                                        placeholder={t("Select Language")}
                                        search={true}
                                        name="language"
                                        value={viewData.language}
                                        disabled={_state.isUserEditMode ? false : true}
                                        options={selectCompanyLanguage && selectCompanyLanguage}
                                        onChange={handleFormChange}
                                    />

                                </div>
                            </form>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};


export default EditOwnProfile
