import React, { useEffect, useState } from 'react'
import ManufacturerLogo from '../../../assets/images/ManufacturerLogo.png'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { InputSwitchWithLable } from 'src/components/inputComponents/InputSwitchWithLable';
import { useTranslation } from 'react-i18next';
import AddIcon from "../../../assets/images/Add.png";
import Dropzone from 'react-dropzone';
import objectPath from "object-path";
import emptyimage from 'src/assets/images/emptyimage.png'
import _ from 'lodash';
import { uploadCompanyLogoImage, deleteCompanyLogoImage } from 'src/redux/media/mediaThunk';
import { MdDelete, MdSecurityUpdateWarning, } from 'react-icons/md';
import Modal from "react-bootstrap/Modal";
import { StSquareButton } from 'src/components/StSquareButton';
import { StCancelSquareButton } from 'src/components/StCancelSquareButton';
import { StCloseButton } from 'src/components/StCloseButton';
import { currentCompanyFormObject } from 'src/redux/company/companySlice';
import FormFieldSchemaRender from 'src/components/schemaRender/FormFieldSchemaRender';
import { privacyAddendunFormSchema } from "src/helper/helpers";

function ManufacturerSideBar(props) {



  const { t } = useTranslation("common");
  const _state = useSelector((state) => state.company, shallowEqual);
  const data = _state.currentCompanyObj;
  const { manufacturerData, handlePrivacyAddendumChange, handleFormChange, errorObject } = props;
  const MainImageList = ['logo',];
  const [docUrl, setDocUrl] = useState({
    logo: { isDirty: false, list: [], readyToUpload: null, baseForm: null },
  });
  const [delShow, setDelShow] = useState(false)
  const [delModelState, setDelModelState] = useState({ modelTitle: '', modelText: '', modelDelUrl: '' })

  const dispatch = useDispatch()
  const companyState = useSelector((state) => state.company, shallowEqual);
  const mediaState = useSelector((state) => state.media, shallowEqual);
  const { currentCompanyObj, companyInfo } = companyState
  const allViewData = companyState.companyList;

  useEffect(() => {
    const newDocUrl = { ...docUrl }
    if (_.get(currentCompanyObj[0], 'logoUrl')) {
      newDocUrl.logo.list[0] = _.get(currentCompanyObj[0], 'logoUrl')
    } else {
      const newDocUrl = { ...docUrl }
      newDocUrl.logo.list = []
    }
    setDocUrl(newDocUrl)
    return () => {
    }
  }, [currentCompanyObj[0]])

  const onImageDrop = (acceptedFiles, type) => {
    let file = acceptedFiles[0];
    const reader = new FileReader()
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.readAsDataURL(file)
    reader.onload = () => {
      let _docUrl = docUrl;
      objectPath.set(_docUrl, `${type}.baseForm`, reader.result);
      objectPath.set(_docUrl, `${type}.readyToUpload`, file);
      objectPath.set(_docUrl, `${type}.list`, [reader.result]);
      objectPath.set(_docUrl, `${type}.isDirty`, false);
      setDocUrl({ ..._docUrl });
      uploadImage(type)
    }
  }

  const uploadImage = (type) => {
    const formData = new FormData();
    formData.append(type, objectPath.get(docUrl, `${type}.readyToUpload`));
    const businessId = objectPath.get(_.find(currentCompanyObj[0].businessIdentifiers, function (item) { return item.type === "VATID" }), 'id', currentCompanyObj[0].businessIdentifiers[0].id)
    dispatch(uploadCompanyLogoImage({ businessId: businessId, body: formData, type: type, }));
  }

  const deleteClicked = (url, type) => {
    setDelModelState({ ...delModelState, modelDelUrl: url, modelTitle: t('Delete image'), modelText: t('Are you sure you want to delete the image?') })
    setDelShow(true)
  }

  const handleDelModelClose = () => {
    setDelShow(false)
  }
  const handleDelModelDelete = async () => {
    const fileName = _.get(currentCompanyObj[0], 'logoUrl') ? _.get(currentCompanyObj[0], 'logoUrl') : _.get(mediaState, `uploadResponse[0]`)
    await dispatch(deleteCompanyLogoImage({ fileName: fileName }));
    dispatch(currentCompanyFormObject([_.omit(allViewData[0], 'logoUrl')]))
    handleDelModelClose()
  }

  return (
    <div className="col-xl-3 col-xxl-4 col-md-4 manufacturersidebar mt-3">
      {/* <div className="manufacturer image-container"> */}
      {MainImageList.map((item, index) => (
        <div key={index} >
          <div key={index} className="row justify-content-center" style={{ display: 'grid', alignItems: 'center' }}>
            <Dropzone disabled={companyState.isCompanyEditMode ? false : true} onDrop={acceptedFiles => onImageDrop(acceptedFiles, item)}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({
                  className: 'dropzone',
                  onClick: event => { event.target.id !== "image-upload-id" && event.stopPropagation() }
                })}>
                  <input {...getInputProps()} />
                  <div className="flip-card">
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <img style={{ objectFit: 'contain' }} alt="alt" src={!!objectPath.get(docUrl, `${item}.list.0`) ? objectPath.get(docUrl, `${item}.list.0`) : emptyimage} />
                      </div>
                      <div className="flip-card-back">
                        <img style={{ objectFit: 'contain' }} alt="alt" id="image-upload-id" src={!!objectPath.get(docUrl, `${item}.list.0`) ? objectPath.get(docUrl, `${item}.list.0`) : emptyimage} />
                        {!!objectPath.get(docUrl, `${item}.list.0`) && companyState.isCompanyEditMode &&
                          <div>
                            <div className="media-card triangle"></div>
                            <MdDelete onClick={() => deleteClicked(objectPath.get(docUrl, `${item}.list.0`), 'image')} size={22} className="media-card delete-icon" />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
          {!objectPath.get(docUrl, `${item}.list.0`) && <p className="manufacturer image-title">{t('Upload Company Logo Here')}</p>}
        </div>
      ))}
      {/* </div> */}
      <div style={{ height: 1, backgroundColor: '#DFEDF5', width: '100%', marginTop: 18, marginBottom: 32 }}></div>
      <div className="col-12">
        <span className="title">{data && data[0] && data[0].name}</span>
        <span className="subtitle">{data && data[0] && data[0].contact}</span>
      </div>
      <div>
        <div className="d-flex mt-2">
          <span className="add-user role-subtitle col-9">{t("Privacy addendum")}</span>
          <div className="mt-2 col-3 d-flex justify-content-end" style={{ right: 70 }}>
            <InputSwitchWithLable
              value={manufacturerData?.privacyAddendum?.target?.value}
              onHandleColor='#32cd32'
              offHandleColor='#ffffff'
              onColor='#32cd32'
              offColor='#6c757d'
              name="privacyAddendum"
              onChange={handlePrivacyAddendumChange}
              disabled={_state.isCompanyEditMode ? false : true}
            />
          </div>
        </div>
        <div style={{ marginLeft: 5 }}>
          {privacyAddendunFormSchema && privacyAddendunFormSchema[0] && privacyAddendunFormSchema.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <FormFieldSchemaRender
                  key={`formFieldSchemaRender${item.name}_${index}`}
                  index={index}
                  formItem={item}
                  isSmallScreen={true}
                  formReadOnly={_state.isCompanyEditMode ? false : true}
                  formValueObject={manufacturerData}
                  touched={true}
                  validationObj={errorObject?.[item?.name] ? `${errorObject?.[item?.name]}` : ""}
                  onChange={handleFormChange}
                />
              </React.Fragment>
            )
          })}
        </div>
      </div>

      <Modal size="md" centered={true} backdrop="static" show={delShow} onHide={handleDelModelClose} >
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleDelModelClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal', textAlign: 'center' }}>
          <div className="row m-0 justify-content-center">
            <span className="col-12 media-card model-text" >{delModelState.modelTitle}</span>
            <span className="col-12 media-card model-content mt-4" >{delModelState.modelText}</span>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center', margin: 15 }}>
          <StSquareButton text={t("Delete")}
            // loading={_mediaState.isMediaDeleting}
            onClick={() => handleDelModelDelete()}></StSquareButton>
          <StCancelSquareButton text={t("Cancel")} onClick={() => handleDelModelClose()}></StCancelSquareButton>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ManufacturerSideBar
