import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import Editor from '@monaco-editor/react';
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { getIndividualProductTemplateData } from "src/redux/products/productThunk";
import { changeProductTemplateEditMode, currentProductTemplateObject, handleErrorsInProductTemplate, handleJSONErrorsInProductTemplate, handleJSONTemplateNameError, handleJSONTemplateLabelError } from "src/redux/products/productsSlice";
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel";
import { Spinner } from "src/components/Spinner";
import CustomTooltip from "src/components/inputComponents/CustomTooltip";

const ViewTemplates = (props) => {
     /** redux store useDispatch & useSelector ------------------------------ **/
     const dispatch = useDispatch();
     const { t } = useTranslation("common");
     const _state = useSelector((state) => (state.products), shallowEqual);
     
     /** hooks useState ----------------------------------------------------- **/
     const [jsonObjError, setJsonObjError] = useState(false)
     
     /** Variable declaration ----------------------------------------------------- **/
     let { name } = useParams();
     const isLoading = _state.actionsLoading;
     const dataForCurrentTemplateObj = _state.currentTemplateObj;

      /** hooks useEffect----------------------------------------------------- **/
    useEffect(() => {
        getProductTemplateSearchByName();
        setJsonObjError(false);
        return () => {
            dispatch(changeProductTemplateEditMode(false));
            dispatch(handleErrorsInProductTemplate(false));
            setJsonObjError(false);
            dispatch(currentProductTemplateObject({}));
            dispatch(handleJSONErrorsInProductTemplate([]))
            dispatch(handleJSONTemplateNameError(false))
            dispatch(handleJSONTemplateLabelError(false))
        };
    }, []);

    /** handler functions--------------------------------------------------- **/

  /** Function to retrieve individual template data by name------------------------- */
    const getProductTemplateSearchByName = async () => {
        try {
            let result = await dispatch(getIndividualProductTemplateData(name))
            await dispatch(currentProductTemplateObject(result?.payload));
        } catch (error) {
            console.log(error)
        }

    }

    /** Function to save input field values--------------------------- */
    const handleFormChange = (e) => {
        let oldTemplateData = dataForCurrentTemplateObj;
        let oldData = { ...oldTemplateData }
        if (e.target.name == "name") {
            _.set(oldData, "name", e.target.value)
            dispatch(handleJSONTemplateNameError(false))
        }
        if (e.target.name == "label") {
            _.set(oldData, "label", e.target.value)
            dispatch(handleJSONTemplateLabelError(false))
        }
        dispatch(currentProductTemplateObject({ ...oldData }));
    }

    /** Function execute when click on edit action----------------------- */
    const handleTemplateEditClick = (value) => {
        let oldTemplateData = dataForCurrentTemplateObj;
        let oldData = { ...oldTemplateData }
        _.set(oldData, "template", value)
        dispatch(currentProductTemplateObject({ ...oldData }));
    }

    return (
        <>
            {isLoading ? (
                <div>
                    <Spinner />
                </div>
            ) : (
                <div className="mt-3"
                    style={{ paddingLeft: "26px", paddingRight: "26px" }}
                >
                    <div className="row col-12">
                        <InputTextWithLabel
                            label={t('Template Name')}
                            tooltipText={t("Template Name")}
                            onChange={handleFormChange}
                            name={'name'}
                            value={dataForCurrentTemplateObj?.name}
                            type="text"
                            placeholder={t("Template Name")}
                            readOnly={_state?.isProductTemplateEditMode ? false : true}
                            touched={true}
                            errorMessage={_state?.jsonTemplateNameError ? t("Template name required") : ""}
                        ></InputTextWithLabel>
                        <InputTextWithLabel
                            label={t('Template Label')}
                            tooltipText={t("Template Label")}
                            onChange={handleFormChange}
                            name={'label'}
                            value={dataForCurrentTemplateObj?.label}
                            type="text"
                            placeholder={t("Template Label")}
                            readOnly={_state?.isProductTemplateEditMode ? false : true}
                            touched={true}
                            errorMessage={_state?.jsonTemplateLabelError ? t("Template label required") : ""}
                        ></InputTextWithLabel>
                    </div>

                    <div className={`form-group inputTextWithLabel"} mt-2`}>
                        <div className="col inputTextWithLabel__label col-12 justify-content-start pb-3">
                            <div className="col-md-6 d-flex flex-row">
                                <label className="form-label" >
                                    {t("Template Body")}
                                </label>
                                <CustomTooltip
                                    tooltipContent={<p>{t("Template Body")}</p>}
                                />
                            </div>
                        </div>
                        <Editor
                            height={"60vh"}
                            language="json"
                            theme="vs-light"
                            onValidate={(markers) => {
                                if (markers.length > 0) {
                                    setJsonObjError(true);
                                    dispatch(handleErrorsInProductTemplate(true))
                                } else {
                                    setJsonObjError(false);
                                    dispatch(handleErrorsInProductTemplate(false))
                                }
                            }}
                            value={JSON.stringify(dataForCurrentTemplateObj?.template, null, 3)}
                            onChange={(value) => {
                                try {
                                    const parsedValue = JSON.parse(value);
                                    handleTemplateEditClick(parsedValue)

                                } catch (error) {
                                    // console.error('Invalid JSON:', error);
                                }
                            }}
                            options={{
                                inlineSuggest: true,
                                fontSize: "16px",
                                formatOnType: true,
                                formatOnPaste: true,
                                autoClosingBrackets: true,
                                minimap: { enabled: true, scale: 20 },
                                readOnly: _state?.isProductTemplateEditMode ? false : true,
                            }}
                        />
                        <div className="d-flex justify-content-between">
                            <div>
                                {jsonObjError ?
                                    <div className="mt-4 text-danger" style={{ fontSize: "14px" }}>{t("Invalid JSON input. Please correct the errors before save.")}</div>
                                    :
                                    <>
                                        {_state?.jsonErrorsInProductTemplate?.length > 0 && (
                                            _state?.jsonErrorsInProductTemplate?.map((error, index) => (
                                                <div className="mt-2 text-danger" style={{ fontSize: "14px" }} key={index}>
                                                    {`${index + 1}>`}{error?.section ?
                                                        <span>
                                                            <span>Section - </span>
                                                            <span style={{ fontWeight: "bold" }}>{error.section}</span>
                                                        </span>
                                                        : ""}&nbsp;
                                                    Object {error?.obj ?
                                                        (
                                                            <>
                                                                <span>Name - </span>
                                                                <span style={{ fontWeight: "bold" }}>{error.obj}</span>
                                                            </>
                                                        )
                                                        : error?.type ?
                                                            (
                                                                <>
                                                                    <span>Type - </span>
                                                                    <span style={{ fontWeight: "bold" }}>{error.type}</span>
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                    <span>at index - </span>
                                                                    <span style={{ fontWeight: "bold" }}>{error.index}</span>
                                                                </>
                                                            )
                                                    }: Missing fields - {error.missingFields.join(', ')}
                                                </div>
                                            ))
                                        )}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
};

export default ViewTemplates;
