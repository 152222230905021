import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';
import axios from "axios";
import objectPath from "object-path";
import { localStorageSetWithExpiry, localStorageGetWithExpiry, } from 'src/helper/localStorageHelper';
import { getProduct_flatten_TabStructured_Schema, } from "src/helper/helpers";
import { ADD, EDIT, FOOD_BEWERAGE } from "src/helper/constants/constants";
import _ from "lodash";
import { getProductSchemaBaseOnProductType } from 'src/helper/helpers';




export const getProductByGtinGsM = createAsyncThunk(
    "product/getProductByGtinGsM",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/masterdata/products/gtin/02354061100000`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getProductUpidsMaster = createAsyncThunk(
    "product/getProductUpidsMaster",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/masterdata/products/filter?orderby=gtin&order=desc`,
                arg
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const dashboardSearch = createAsyncThunk(
    "product/dashboardSearch",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/masterdata/dashboard/search?value=${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

/** getting products data per page 20 */
export const getProductsWithPage = createAsyncThunk(
    "product/getProductsWithPage",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/masterdata/products/filter?orderby=gtin&order=desc&perpagecount=20&mode=${arg.mode}&gtin=${arg.gtin}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/** getting products data per manufacturer */
export const getManufacturerSpecificProducts = createAsyncThunk(
    "product/getManufacturerSpecificProducts",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                //     // `/upids/masterdata/products/filter?orderby=gtin&order=desc&perpagecount=20&mode=${arg.mode}&gtin=${arg.gtin}`
                `/upids/masterdata/products/businessid/all?businessid=${arg.businessId}&perpagecount=${arg.perpagecount}&mode=${arg.mode}&gtin=${arg.gtin}&field=${arg.field}`,
            );

            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getFavouriteProducts = createAsyncThunk(
    "product/getFavouriteProducts",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/masterdata/products/favourites`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


//Deprecated
//Action call for search product by gtin
export const getProductsSearch = createAsyncThunk(
    "product/getProductsSearch",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/masterdata/products/search?value=${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const getProductTemplateList = createAsyncThunk(
    "product/getProductTemplateList",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `services/product/template/filter/all`
            );
            let productTemplateData = _.filter(response?.data || [], (d) => d.isActive);
            return productTemplateData;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



/** get product template */
export const getProductTemplateSchemaAction = createAsyncThunk(
    "product/getProductTemplateSchemaAction",
    async (arg, thunkApi) => {
        try {
            const { productType = FOOD_BEWERAGE, formType = EDIT } = arg
            const localStorageKey = `productTemplateSchema_name=${productType}`
            const localStorageData = localStorageGetWithExpiry(localStorageKey)
            if (false && localStorageData && localStorageData.productType === productType && localStorageData.formType === formType) {
                return localStorageData;
            }
            else {
                const response = await getAxios().get(
                    `services/product/template/filter/use?name=${productType}`
                );
                const productSchema = response.data.template;
                // const productSchema = getProductSchemaBaseOnProductType(productType);
                const { productFlattenSchema, productTabStructuredSchema } = getProduct_flatten_TabStructured_Schema(productSchema)
                const responseData = { productSchema, productFlattenSchema, productTabStructuredSchema, formType, productType }
                localStorageSetWithExpiry(localStorageKey, responseData)
                return responseData;
            }
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/** search product by gtin */
export const getProductByIdAction = createAsyncThunk(
    "product/getProductByIdAction",
    async (arg, thunkApi) => {
        try {
            const { isMultimediaGet } = arg
            const { products } = thunkApi.getState()
            const formObject = products.formObject
            const response = await getAxios().get(
                `/upids/masterdata/products/gtin/${arg.gtin}`
            );
            const responseData = response.data
            if (isMultimediaGet) {
                const NewResponseData = {
                    ...formObject,
                    multimedia: responseData.multimedia,
                    media: responseData.media
                }
                return { isLoader: arg.isLoader, responseData: NewResponseData, formType: arg.formType };
            }
            return { isLoader: arg.isLoader, responseData: responseData, formType: arg.formType };
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const addProduct = createAsyncThunk(
    "product/addProduct",
    async (arg, thunkApi) => {
        try {
            const { productData, formType } = arg;
            const response = await getAxios().put(
                `/upids/masterdata/products`,
                productData
            );

            await thunkApi.dispatch(getProductByIdAction({ isLoader: false, formType: formType ? formType : ADD, gtin: !!objectPath.get(productData, 'gtin') ? objectPath.get(productData, 'gtin') : !!objectPath.get(response, 'data._2an') ? objectPath.get(response, 'data._2an') : objectPath.get(productData, '_2an') }))
            if (!!objectPath.get(response, '_2an')) {
                // dispatch(actions.add2AnToFormObject(objectPath.get(response, 'data._2an')));
            }
            // dispatch(actions.addProductFetched(response));


            return response;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/** deleting the product record */
export const deleteProduct = createAsyncThunk(
    "product/deleteProduct",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().delete(
                `/upids/masterdata/products/gtin/${arg}`
            );
            return response;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/** adding new product */
export const addUpidsProduct = createAsyncThunk(
    "product/addUpidsProduct",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().put(
                `/upids/product`,
                arg
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getUpidsProductById = createAsyncThunk(
    "product/getUpidsProductById",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/product/upidsId/${arg.upidsId}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getSpecificMetadataServer = createAsyncThunk(
    "product/getSpecificMetadataServer",
    async (arg, thunkApi) => {
        try {
            const collections = localStorageGetWithExpiry(arg.collectionName)
            if (collections) {
                return collections;
            } else {

                const [metadataEn, metadataFi, metadataSv] = await Promise.all([
                    getAxios().get(
                        `/upids/metadata/${arg.collectionName}/locale?locale=en`
                    ),
                    getAxios().get(
                        `/upids/metadata/${arg.collectionName}/locale?locale=fi`
                    ),
                    getAxios().get(
                        `/upids/metadata/${arg.collectionName}/locale?locale=sv`
                    )
                ]);
                let metaDataList = { metadataEn: metadataEn.data, metadataFi: metadataFi.data, metadataSv: metadataSv.data }
                localStorageSetWithExpiry(arg.collectionName, metaDataList)
                return { metadataEn: metadataEn.data, metadataFi: metadataFi.data, metadataSv: metadataSv.data };
            }
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getSegmentCodeDetails = createAsyncThunk(
    "product/getSegmentCodeDetails",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/metadata/${arg.collectionName}/code/${arg.segmentCode}?locale=${arg.locale}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/**  */
export const getAllSegmentList = createAsyncThunk(
    "product/getAllSegmentList",
    async (arg, thunkApi) => {
        try {
            const segmentList = localStorageGetWithExpiry('segmentAllList')
            if (segmentList) {
                return segmentList;
            } else {
                const [segmentListEn, segmentListFi, segmentListSv] = await Promise.all([
                    getAxios().get(
                        `/upids/metadata/${arg.collectionName}/locale/distinct?locale=en`
                    ),
                    getAxios().get(
                        `/upids/metadata/${arg.collectionName}/locale/distinct?locale=fi`
                    ),
                    getAxios().get(
                        `/upids/metadata/${arg.collectionName}/locale/distinct?locale=sv`
                    )
                ]);

                const segmentAllList = { segmentListEn: segmentListEn.data, segmentListFi: segmentListFi.data, segmentListSv: segmentListSv.data }
                localStorageSetWithExpiry('segmentAllList', segmentAllList)
                return segmentAllList;
            }
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getFamilyDependsOnSegmanetAction = createAsyncThunk(
    "product/getFamilyDependsOnSegmanetAction",
    async (arg, thunkApi) => {
        try {
            const { collectionName = "categories", segmentCode } = arg
            const localStorageKey = `segmentCode=${segmentCode}`
            const familyList = localStorageGetWithExpiry(localStorageKey)

            if (familyList) {
                return familyList;
            }
            else {
                const languageList = ['en', 'fi', 'sv']
                const [familyListEn, familyListFi, familyListSv] = await Promise.all(languageList.map(async (lang) => {
                    const response = await getAxios().get(
                        `upids/metadata/${collectionName}/distinct/family?locale=${lang}&segmentCode=${segmentCode}`
                    );
                    return response.data;
                }))
                const familyList = { familyListEn: familyListEn, familyListFi: familyListFi, familyListSv: familyListSv }
                localStorageSetWithExpiry(localStorageKey, familyList)
                return familyList;
            }
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const getClassDependsOnFamilyAction = createAsyncThunk(
    "product/getClassDependsOnSegmanetFamilyAction",
    async (arg, thunkApi) => {
        try {
            const { collectionName = "categories", familyCode } = arg
            const localStorageKey = `familyCode=${familyCode}`
            const segmentList = localStorageGetWithExpiry(localStorageKey)

            if (segmentList) {
                return segmentList;
            }
            else {
                const languageList = ['en', 'fi', 'sv']
                const [classListEn, classListFi, classListSv] = await Promise.all(languageList.map(async (lang) => {
                    const response = await getAxios().get(
                        `upids/metadata/${collectionName}/distinct/class?locale=${lang}&familyCode=${familyCode}`
                    );
                    return response.data;
                }))
                const classList = { classListEn: classListEn, classListFi: classListFi, classListSv: classListSv }
                localStorageSetWithExpiry(localStorageKey, classList)
                return classList;
            }
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const getBrickDependsOnClassAction = createAsyncThunk(
    "product/getBrickDependsOnClassAction",
    async (arg, thunkApi) => {
        try {
            const { collectionName = "categories", classCode } = arg
            const localStorageKey = `classCode=${classCode}`
            const segmentList = localStorageGetWithExpiry(localStorageKey)

            if (segmentList) {
                return segmentList;
            }
            else {
                const languageList = ['en', 'fi', 'sv']
                const [brickListEn, brickListFi, brickListSv] = await Promise.all(languageList.map(async (lang) => {
                    const response = await getAxios().get(
                        `upids/metadata/${collectionName}/distinct/brick?locale=${lang}&classCode=${classCode}`
                    );
                    return response.data;
                }))
                const brickList = { brickListEn: brickListEn, brickListFi: brickListFi, brickListSv: brickListSv }
                localStorageSetWithExpiry(localStorageKey, brickList)
                return brickList;
            }
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);




export const getUpidsProduct = createAsyncThunk(
    "product/getUpidsProduct",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/product/search?field=${arg.field}&value=${arg.value}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getUpidsProduct2an = createAsyncThunk(
    "product/getUpidsProduct2an",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/product/search?field=${arg.field}&value=${arg.value}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/** deleting the upids product record */
export const deleteUpidsProduct = createAsyncThunk(
    "product/deleteUpidsProduct",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().delete(
                `/upids/product/upidsId/${arg}`
            );
            return response;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/** */
export const liveProductSearch = createAsyncThunk(
    "product/liveProductSearch",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/search/masterdata?value=${arg.searchText}&perpagecount=20&pageno=${arg.pageNo}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);




/** IMP LOOK */
export const getSegmentList = createAsyncThunk(
    "product/getSegmentList",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/metadata/${arg.collectionName}/locale/distinct?locale=${arg.locale}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



/**  */
export const getAudienceCountOfManufacturer = createAsyncThunk(
    "product/getAudienceCountOfManufacturer",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/services/marketing/audience/${arg}`
            )
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



/**  */
export const sendEmailMessageToAudience = createAsyncThunk(
    "product/sendEmailMessageToAudience",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/services/marketing/deliver`, arg
            )
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



/**  */
export const unsubscribeMarketingEmails = createAsyncThunk(
    "product/unsubscribeMarketingEmails",
    async (arg, thunkApi) => {
        try {
            const response = await axios.put(
                `/services/marketing/unsubscribe`,
                arg
            )
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/**  */
export const getWarningsForProducts = createAsyncThunk(
    "product/getWarningsForProducts",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/warning/all/${arg.businessId}`)
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/**  */
export const addWarningsForProduct = createAsyncThunk(
    "product/addWarningsForProduct",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/upids/warning`,
                arg.warningData
            )
            thunkApi.dispatch(getWarningsForProducts({ businessId: arg.businessId }))
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

/**  */
export const deleteWarningForProduct = createAsyncThunk(
    "product/deleteWarningForProduct",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().delete(
                `/upids/warning/${arg.id}`
            )
            thunkApi.dispatch(getWarningsForProducts({ businessId: arg.businessId }))
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);




/**  */
export const editWarningForProduct = createAsyncThunk(
    "product/editWarningForProduct",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().put(
                `/upids/warning`,
                arg.warningData
            )
            thunkApi.dispatch(getWarningsForProducts({ businessId: arg.businessId }))
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);




/**  */
export const getNutritionalClaimsAction = createAsyncThunk(
    "product/getNutritionalClaimsAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/metadata/nutritional/locale?locale=${arg.language}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);




export const getNutritionalClaimsMetadataAction = createAsyncThunk(
    "product/getNutritionalClaimsMetadataAction",
    async (arg, thunkApi) => {
        try {
            const localStorageKey = "nutritionalClaims"
            const metaData = localStorageGetWithExpiry(localStorageKey)
            if (metaData) {
                return metaData;
            } else {
                const languageList = ['en', 'fi', 'sv']
                const [metadataEn, metadataFi, metadataSv] = await Promise.all(languageList.map(async (lang) => {
                    const response = await getAxios().get(
                        `/upids/metadata/nutritional/locale?locale=${lang}`
                    );
                    return response.data;
                }))

                let metaDataList = { metadataEn: metadataEn, metadataFi: metadataFi, metadataSv: metadataSv }
                localStorageSetWithExpiry(localStorageKey, metaDataList)
                return { metadataEn: metadataEn, metadataFi: metadataFi, metadataSv: metadataSv };
            }
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getProductHistoryModalShown = createAsyncThunk(
    "product/getProductHistoryModalShown",
    async (arg, thunkApi) => {
        try {
            return arg
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
)

/** Get all product templates */
export const getAllProductTemplates = createAsyncThunk(
    "product/getAllProductTemplates",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/services/product/template/filter/all`
            );
            let productTemplateData = _.filter(response?.data || [], (d) => d.isActive);
            return productTemplateData;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

/** Get individual product template data */
export const getIndividualProductTemplateData = createAsyncThunk(
    "product/getIndividualProductTemplateData",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `services/product/template/filter/use?name=${arg}`
            );
            
            return response.data
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
)

/** Update individual product template data */
export const updateIndividualProductTemplateData = createAsyncThunk(
    "product/updateIndividualProductTemplateData",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().put(
                `services/product/template`, arg
            );
            return response.data
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
)

/** Add new product template data */
export const addProductTemplateData = createAsyncThunk(
    "product/addProductTemplateData",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `services/product/template`, arg
            );
            return response.data
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
)

/** Delete product template data */
export const deleteProductTemplateData = createAsyncThunk(
    "product/deleteProductTemplateData",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().delete(
                `services/product/template/${arg}`
            );
            return response.data
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
)

/** Get all metadata taxonomy items data */
export const getAllMetadataTaxonomyData = createAsyncThunk(
    "product/getAllMetadataTaxonomyData",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `upids/metadata/taxonomyitems/all`
            );
            return response.data
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
)

/** Add new taxonomy items data */
export const addNewMetadataTaxonomyData = createAsyncThunk(
    "product/addNewMetadataTaxonomyData",
    async (model, thunkApi) => {
        try {
            const response = await getAxios().post(
                `upids/metadata/taxonomyitems`, model
            );
            return response.data
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
)

/** Add new taxonomy items data */
export const getTaxonomyDataByIdAction = createAsyncThunk(
    "product/getTaxonomyDataByIdAction",
    async (id, thunkApi) => {
        try {
            const response = await getAxios().get(`upids/metadata/taxonomyitems/${id}`);
            return response.data
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
)

/** edit taxonomy items data */
export const editTaxonomyData = createAsyncThunk(
    "product/editTaxonomyData",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().patch(`upids/metadata/taxonomyitems?id=${arg?.body?.id}`,arg.body);
            return response.data
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
)

/** edit taxonomy items data */
export const deleteTaxonomyItem = createAsyncThunk(
    "product/deleteTaxonomyItem",
    async (id, thunkApi) => {
        try {
            const response = await getAxios().delete(`upids/metadata/taxonomyitems?id=${id}`);
            return response.data
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
)



