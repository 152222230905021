import React from 'react';
import _ from 'lodash';
import { TabAccordion } from "src/components/TabAccordion";
import { getEmmbededYoutubeVideoUrl } from 'src/helper/helpers';

function DocumentaionHtml(props) {
    const { contentfulData } = props;

    const handleMedia = (mediaList) => {
        const mediaRender = mediaList && mediaList[0] && mediaList.map((item, index) => {
            const contentType = _.get(item, 'sys.mediaFields.file.contentType', "")
            const mediaUrl = _.get(item, 'sys.mediaFields.file.url', "")

            if (contentType.includes("image") && mediaUrl) {
                return (
                    <div key={index} className='m-3'>
                        <img src={mediaUrl} className="contenfulData__image" />
                    </div>
                )
            }
            else if (contentType.includes("video") && mediaUrl) {
                let embadedUrl = mediaUrl
                if (mediaUrl.includes("youtube")) {
                    embadedUrl = getEmmbededYoutubeVideoUrl(mediaUrl)
                }

                return (
                    <div key={index} className='m-3'>
                        <div className='contenfulData__youtubeVideo'>
                            <iframe
                                className='youTubeVideoIframe'
                                id="youTubeVideoIframe"
                                width="100%"
                                height="100%"
                                src={embadedUrl}
                                title="YouTube video player"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                frameBorder="0"
                                allowFullScreen
                            />
                        </div>
                    </div>
                )
            }

        })
        return mediaRender
    }
    

    let documentaionData = [];
    if (contentfulData) {
        documentaionData = _.orderBy(contentfulData, function (obj) {
            return obj.order;
        }, 'asc');
    }

    const documentationList = documentaionData && documentaionData.map((item, index) => {
        const object = {
            title: item.title,
            tabs: () => (
                <div key={index} className="mt-2">
                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                    {item.media && item.media[0] &&
                        <div>{handleMedia(item.media)}</div>
                    }
                </div>
            )
        }

        return object
    })


    return (
        <>
            {documentationList && documentationList[0] &&
                <div>
                    <TabAccordion accordionData={documentationList}></TabAccordion>
                </div>
            }
        </>
    );
}

export default DocumentaionHtml

