import React from 'react'
import food_pic from '../../../assets/images/Dummy_Product_Pic.png';
import { useNavigate } from 'react-router-dom'
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

const ProductCardPlaceholder = (props) => {
    let navigate = useNavigate();
    function addDefaultSrc(ev) {
        ev.target.src = food_pic
    }
    return (
        <>

            <div className="productcard" style={{position: 'relative'}}
                // onClick={() => alert('Add new product')} 
            >
                <div>
                    <img onError={addDefaultSrc} className="prod_image" src={food_pic} alt="pic"></img>
                </div>

                <div style={{ padding: 10 }}>
                    <span className="prod_subtitle">{props.pName}</span>
                </div>
                {!!props.favouritesClickHandle ?
                  <div style={{position: 'absolute', bottom: 0, right: 0}}>
                    {!!props.productData.isFavourite ?
                        <AiFillStar style={{ cursor: 'pointer', color: '#fcba03'}}  fontSize={18} fontWeight={20} onClick={(e) => {e.stopPropagation(); props.favouritesClickHandle(props.productData.gtin)}} />
                         :
                         <AiOutlineStar style={{ cursor: 'pointer', color: '#6c757d'}}  fontSize={18} fontWeight={20} onClick={(e) => {e.stopPropagation(); props.favouritesClickHandle(props.productData.gtin)}} />
                    }
                  </div>
                : <></>}
            </div>

        </>
    )
}

export default ProductCardPlaceholder