import { createSlice } from "@reduxjs/toolkit";
import {
  getProductFeedbackDetail, getAllFeedbacks, getProductFeedback, getFeedbackSearch, deleteFeedback,
  getFeedbackConversations, replyToFeedback
} from "./feedbackThunk";

const initialFeedbackState = {
  actionsLoading: false,
  response: null,
  error: null,
  allFeedbacks: [],
  productFeedbacks: null,
  feedbackDetail: {},
  isLoading: false,
  isAllDataFetchedLoading: false,
  isFeedbackEditMode: false,
  isDeleteLoading: false,
  conversationLoading: false,
  feedbackReplyLoading: false,
  deleteResponse: null,
  replyToFeedbackResponse: null,
  feedbackConversationsResponse: null

};

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState: initialFeedbackState,
  
  reducers: {
    changeFeedbackEditMode: (state, action) => {
      state.isFeedbackEditMode = action.payload;
    },
  },

  extraReducers:
    (builder) => {
      builder
        /**  */
        .addCase(getProductFeedbackDetail.pending, (state, action) => {
          state.error = null;
          state.actionsLoading = true;

          const arg = action.meta.arg

          if (arg && !arg.isCancelBtn) {
            state.isLoading = true;
            state.editResponse = null;
          }

        })
        .addCase(getProductFeedbackDetail.fulfilled, (state, action) => {
          state.error = null;
          state.actionsLoading = false;
          state.isLoading = false;
          state.feedbackDetail = action.payload;
        })
        .addCase(getProductFeedbackDetail.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })



        .addCase(getAllFeedbacks.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.isAllDataFetchedLoading = true;
        })
        .addCase(getAllFeedbacks.fulfilled, (state, action) => {
          state.error = null;
          state.actionsLoading = false;
          state.isAllDataFetchedLoading = false;
          state.allFeedbacks = action.payload;
        })
        .addCase(getAllFeedbacks.rejected, (state, action) => {
          state.actionsLoading = false;
          state.isAllDataFetchedLoading = false;
          state.error = action.payload;
        })


        .addCase(getProductFeedback.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.isLoading = true;
          state.editResponse = null;
        })
        .addCase(getProductFeedback.fulfilled, (state, action) => {
          state.error = null;
          state.isLoading = false;
          state.actionsLoading = false;
          state.productFeedbacks = action.payload;
        })
        .addCase(getProductFeedback.rejected, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = action.payload;
        })


        .addCase(getFeedbackSearch.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.isLoading = true;
          state.editResponse = null;
        })
        .addCase(getFeedbackSearch.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = null;
          state.allFeedbacks = action.payload;
        })
        .addCase(getFeedbackSearch.rejected, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = action.payload;
        })


        .addCase(deleteFeedback.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.isDeleteLoading = true;
          state.deleteResponse = null;
        })
        .addCase(deleteFeedback.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.isDeleteLoading = false;
          state.deleteResponse = action.payload.status;
        })
        .addCase(deleteFeedback.rejected, (state, action) => {
          state.actionsLoading = false;
          state.isDeleteLoading = false;
          state.error = action.payload;
        })


        .addCase(getFeedbackConversations.pending, (state) => {
          state.error = null;
          state.conversationLoading = true
        })
        .addCase(getFeedbackConversations.fulfilled, (state, action) => {
          state.conversationLoading = false
          state.feedbackConversationsResponse = action.payload;
        })
        .addCase(getFeedbackConversations.rejected, (state, action) => {
          state.conversationLoading = false;
          state.error = action.payload;
        })

        .addCase(replyToFeedback.pending, (state) => {
          state.error = null;
          state.feedbackReplyLoading = true
        })
        .addCase(replyToFeedback.fulfilled, (state, action) => {
          state.feedbackReplyLoading = false
          state.replyToFeedbackResponse = action.payload;
        })
        .addCase(replyToFeedback.rejected, (state, action) => {
          state.feedbackReplyLoading = false;
          state.error = action.payload;
        })


    },
});


export const { changeFeedbackEditMode } = feedbackSlice.actions