import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { Tab } from 'semantic-ui-react'
import Vouchers from './components/Vouchers';
import AddUpdateVoucherModal from './components/AddUpdateVoucherModal';

const VoucherMain = () => {
    const { t } = useTranslation("common");

    const [voucherTabIndex, setVoucherTabIndex] = useState(0)
    const panes = [
        {
            menuItem: t("Vouchers"),
            render: () => (
                <Tab.Pane className="product-profile tab-pane-no-shadow border-0 shadow-none">
                    <Vouchers />
                </Tab.Pane>
            )
        },
        // {
        //     menuItem: t("Billing"),
        //     render: () => (
        //         <Tab.Pane className="product-profile tab-pane-no-shadow border-0 shadow-none">
        //             <Transactions />
        //         </Tab.Pane>
        //     )
        // },
    ]

    const handleTabChange = (e, data) => {
        setVoucherTabIndex(data.activeIndex)
    }

    return (
        <div>
            <Tab
                style={{ width: '100%' }}
                activeIndex={voucherTabIndex}
                onTabChange={handleTabChange}
                className="product-profile tab-container multilang-tab"
                menu={{ secondary: true }}
                panes={panes}
            />
            <AddUpdateVoucherModal />
        </div>
    )
}

export default VoucherMain