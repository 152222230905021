import { getAxios } from "../../helper/axiosInterceptor";


//Api call for getting all company data
// export async function getCompanyServer(params) {
//   return await getAxios().get(
//     `/iam/company/filter?orderby=name&order=desc`
//   );
// }

//Api call for search company by business id or company name
export async function getCompanySearchServer(params) {
  return await getAxios().get(
    `/iam/company/search?value=${params.businessid}`
  );
}

//Api call for live search company 
export async function getLiveCompanySearchServer(params) {
  return await getAxios().get(
    `/upids/search/manufacturers?value=${params.searchText}&perpagecount=20&pageno=${params.pageNo}`
  );
}

export async function getCompanyInfoServer() {
  return await getAxios().get(
    `/iam/company`
  );
}
//Api call for editing the company record
export async function editCompanyServer(params) {
  return await getAxios().patch(
    `/iam/company/${params.id}`,
    params.body
  );
}

//Api call for adding new company record
export async function addCompanyServer(params) {
  return await getAxios().post(
    `/iam/company`,
    params.body
  );
}

//Api call for deleting the company record
export async function deleteCompanyServer(params) {
  return await getAxios().delete(
    `/iam/company/${params}`
  );
}

export async function addEcomServer(params) {
  return await getAxios().put(
    `/integrations/integrations`,
    params
  );
}

export async function getEcomServer(params) {
  return await getAxios().get(
    `/integrations/integrations/businessid/${params}`
  );
}

export async function deleteEcomServer(params) {
  return await getAxios().delete(
    `/integrations/integrations/${params.id}/businessid/${params.businessid}`
  );
}

export async function getSelectedEcomServer(params) {
  return await getAxios().get(
    `/integrations/integrations/product/${params}`
  );
}

export async function setSelectedEcomServer(params) {
  return await getAxios().put(
    `/integrations/integrations/product`,
    params
  );
}

// api cal for checking whether ecomId is generated
export async function checkEcomIdGenerated(params) {
  return await getAxios().post(
    `/integrations/integrations/product/check`,
    params
  );
}

//Api call for deleting the ecom integration point from product
export async function deleteEcomIntegrationPointFromProduct(params) {
  return await getAxios().delete(
    `/integrations/integrations/product/${params.articleIdentifer}/${params.articleIdentiferType}/${params.id}`
  );
}

//Api call for editing the company user record
export async function editCompanyUserToServer(params) {
  return await getAxios().patch(
    `/iam/company`,
    params.body
  );
}