import { createSlice, current } from "@reduxjs/toolkit";
import { getSpecificFlattenSchemaPath_ObjectForItemName, getSpecificStructuredSchemaPath_ObjectForItemName } from "src/helper/helpers";
import _ from 'lodash';
import objectPath from "object-path";
import { toast } from "react-toastify";
import { t } from "src/locales/localeHelper"

import {
  getProductByGtinGsM, getProductUpidsMaster, dashboardSearch, getProductsWithPage, getManufacturerSpecificProducts,
  getFavouriteProducts, getProductsSearch, getProductByIdAction, getProductTemplateSchemaAction, addProduct, deleteProduct, addUpidsProduct, deleteUpidsProduct,
  getSpecificMetadataServer, getSegmentCodeDetails, getUpidsProduct, getUpidsProduct2an, getUpidsProductById,
  liveProductSearch, getSegmentList, getAllSegmentList, getAudienceCountOfManufacturer, sendEmailMessageToAudience,
  unsubscribeMarketingEmails, getWarningsForProducts, addWarningsForProduct, deleteWarningForProduct, editWarningForProduct,
  getNutritionalClaimsAction, getProductHistoryModalShown, getAllProductTemplates, getIndividualProductTemplateData, addProductTemplateData, deleteProductTemplateData, getAllMetadataTaxonomyData
  , addNewMetadataTaxonomyData, editTaxonomyData, deleteTaxonomyItem
} from "./productThunk";


const initialProductsState = {
  actionsLoading: false,
  response: null,
  error: null,
  passwordSent: false,
  newPassDone: false,
  refreshTokenDone: false,
  product: null,
  gsproduct: null,
  upidsMasterProduct: null,
  searching: false,
  dashSearchData: null,
  productsPerPage: null,
  isProdEditMode: false,
  tmpProdObj: {},
  searchProduct: null,
  productById: null,

  /**@description product form info */
  formMetaData: {},
  formObject: {},
  productFormValidation: {
    isAllTouched: false,
    touched: {},
    errorMessage: {},
    errorFieldList: [],
  },


  addProdFormObject: {},
  formErrorObject: [],
  isAllDataFetchedLoading: false,
  editProductProfileLoading: false,
  isDeleteLoading: false,
  deleteResponse: null,
  addResponse: null,
  isDeleteLoadingForUpids: false,
  deleteUpidsResponse: null,
  liveProductList: null,
  segmentListEn: null,
  segmentListFi: null,
  segmentListSv: null,
  segmentCodeDetailsEn: null,
  segmentCodeDetailsFi: null,
  segmentCodeDetailsSv: null,
  segmentLoading: false,
  familyLoading: false,
  currentUpidsObject: {},
  segmentAllList: { segmentListEn: [], segmentListFi: [], segmentListSv: [] },
  upidsProductDetail: [],
  upidsProductDetail2an: [],
  upidsProductById: null,
  metadataList: { metadataEn: [], metadataFi: [], metadataSv: [] },
  productSearchText: "",
  isConfirmFlag: false,
  uploadResponse: null,
  getAllProductImages: null,
  getUpidsProductLoader: false,
  downloadImageData: null,
  sendEmailMessageToAudienceSuccess: null,
  unsubscribeLoading: false,
  unsubscribeSuccessful: false,
  selectedProductSchema: {
    formType: '', // add/update
    productSchemaType: "",
    productSchema: [],
    productFlattenSchema: [],
    productTabStructuredSchema: [],
    selectedTabInfo: {
      tabName: "",
      fieldList: [],
      sectionLoading: [
        // { name: "primary[0]", section: "Product profile Images" },
      ],
    }
  },
  ErrorsformObject: [],
  viewCompanyPageLoads: false,
  showGtinExistingErrorMessage: false,
  warningsDataForProducts: [],
  addWarningForProduct: {},
  nutritionalCliams: [],
  productHistoryData: [],
  productHistoryModal: false,
  allProductTemplate: [],
  individualProductTemplateData: {},
  isProductTemplateEditMode: false,
  errorsInProductTemplate: false,
  jsonErrorsInProductTemplate: [],
  jsonTemplateNameError: false,
  jsonTemplateLabelError: false,
  currentTemplateObj: {},
  isTemplateDeleteLoading: false,
  isAddtemplateLoading: false,
  taxonomyItemsData: [],
  taxonomyFormData: {
    attributeName: "",
    description: {
      en: "",
      fi: ""
    },
    relation: "",
    link: "",
    datatype: "",
    category: ""
  },
  TaxonomyErrorsformObject: [],
  currentTaxonomyObj: {},
  taxonomyErrorsformEditObject: [],
  isTaxonomyEditMode: false,
  editTaxonomyResponse: null,
  deleteTaxonomyResponse: null,
  firstProductObj: {}
};

export const productsSlice = createSlice({
  name: "products",
  initialState: initialProductsState,
  reducers: {
    //Catch error if any.
    toggleProductHistoryDrawerFlag: (state, action) => {
      state.productHistoryModal = false;
    },

    changeProductTemplateEditMode: (state, action) => {
      state.isProductTemplateEditMode = action.payload;
    },

    handleErrorsInProductTemplate: (state, action) => {
      state.errorsInProductTemplate = action.payload;
    },

    handleJSONErrorsInProductTemplate: (state, action) => {
      state.jsonErrorsInProductTemplate = action.payload;
    },

    handleJSONTemplateNameError: (state, action) => {
      state.jsonTemplateNameError = action.payload;
    },

    taxonomyAllErrorsFormObject: (state, action) => {
      state.TaxonomyErrorsformObject = action.payload;
    },

    resetTaxonomyDataFormAction: (state, action) => {
      state.taxonomyFormData = initialProductsState.taxonomyFormData
    },

    updateTaxonomyDataFormAction: (state, action) => {
      if (action.payload && action.payload.name) {
        const { name, value } = action.payload
        state.taxonomyFormData[name] = value;
      }
    },

    currentTaxonomyFormObject: (state, action) => {
      state.currentTaxonomyObj = action.payload;
    },

    taxonomyAllErrorsEditFormObject: (state, action) => {
      state.taxonomyErrorsformEditObject = action.payload;
    },

    changeTaxonomyEditModeAction: (state, action) => {
      state.isTaxonomyEditMode = action.payload;
    },

    handleJSONTemplateLabelError: (state, action) => {
      state.jsonTemplateLabelError = action.payload;
    },


    currentProductTemplateObject: (state, action) => {
      state.currentTemplateObj = action.payload;
    },

    setFirstProductObjFromProductList: (state, action) => {
      state.firstProductObj = action.payload;
    },

    //Starting of a call
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
      state.viewCompanyPageLoads = false;
    },



    addNewProductSuccesfully: (state, action) => {
      state.selectedProductSchema = {
        formType: '', // add/update
        productSchemaType: "",
        productSchema: [],
        productTabStructuredSchema: [],
        selectedTabInfo: {
          tabName: "",
          fieldList: [],
          sectionLoading: [],
        }
      }
    },


    setSegmentLoading: (state, action) => {
      state.segmentLoading = action.payload;
    },

    setFamLoading: (state, action) => {
      state.familyLoading = action.payload;
    },
    clearUpidsArray: (state, action) => {
      state.upidsProductDetail = [];
    },
    changeProdEditMode: (state, action) => {
      state.isConfirmFlag = false;
      state.isProdEditMode = action.payload;
    },
    setFlagToShowConfirmDone: (state, action) => {
      state.isConfirmFlag = action.payload;
    },

    changeFormObject: (state, action) => {
      state.formObject = action.payload;
    },

    /** form field */
    productFormFieldUpdateAction: (state, action) => {
      const { name, value } = action.payload
      _.set(state.formObject, `${name}`, value);
    },
    // clearProductFormAction: (state) => {
    //   state.productFormData = initialState.productFormData;
    //   state.productFormValidation = initialState.productFormValidation;
    // },

    formFieldValidationAction: (state, action) => {
      const { nameUpdated, errorMessage, touched } = action.payload;

      _.set(state.productFormValidation.touched, `${nameUpdated}`, touched ? touched : false);

      if (errorMessage) {
        _.set(state.productFormValidation.errorMessage, `${nameUpdated}`, errorMessage);
      }
      else {
        _.set(state.productFormValidation.errorMessage, `${nameUpdated}`, "");
      }
    },

    formSaveValidationAction: (state, action) => {
      const { errorMessageObject, isAllTouched, errorFieldList } = action.payload;
      _.set(state.productFormValidation, `errorMessage`, errorMessageObject);
      _.set(state.productFormValidation, `isAllTouched`, true);

      if (errorFieldList) {
        _.set(state.productFormValidation, `errorFieldList`, errorFieldList);
      }

    },

    currentUpidsFormObject: (state, action) => {
      state.currentUpidsObject = action.payload;
    },

    changeAddProdFormObject: (state, action) => {
      state.addProdFormObject = action.payload;
    },

    changeFromErrorObject: (state, action) => {
      state.formErrorObject = action.payload;
    },
    changeProductSearchText: (state, action) => {
      state.productSearchText = action.payload;
    },


    addCustomMultiInputFields: (state, action) => {
      state.addCustomMultiInputFields = action.payload;
    },
    clearAddCustomMultiInputFields: (state, action) => {
      state.addCustomMultiInputFields = null
    },
    clearProductById: (state, action) => {
      state.productById = null;
    },



    selectProductTabInfoAction: (state, action) => {
      const { tabName, fieldList } = action.payload
      state.selectedProductSchema.selectedTabInfo = {
        tabName: tabName,
        fieldList: fieldList,
        sectionLoading: [],
      }
    },

    updatedFlattenSchemaFormItemAction: (state, action) => {
      const { formField } = action.payload
      const currentProductFlattenSchema = current(state.selectedProductSchema.productFlattenSchema);
      const { fieldPath } = getSpecificFlattenSchemaPath_ObjectForItemName(currentProductFlattenSchema, formField.nameUpdated)
      _.set(state.selectedProductSchema.productFlattenSchema, fieldPath, formField);
    },

    updateStructuredSchemaObjectOptionsAction: (state, action) => {
      const currentProductTabStructuredSchema = current(state.selectedProductSchema.productTabStructuredSchema);
      const { options, name } = action.payload;
      const { schemaPath, fieldListPath } = getSpecificStructuredSchemaPath_ObjectForItemName(currentProductTabStructuredSchema, name)
      const updatedSchemaPath = `productTabStructuredSchema${schemaPath}.options`
      _.set(state.selectedProductSchema, updatedSchemaPath, options);
      _.set(state.selectedProductSchema, `selectedTabInfo.${fieldListPath}.options`, options)
      // const currentProductTabStructuredSchema1 = current(state.selectedProductSchema.productTabStructuredSchema);
      // console.log("state.selectedProductSchema", currentProductTabStructuredSchema1)
    },


    addRemoveSectionLoadingAction: (state, action) => {
      const { name, section, type } = action.payload
      const currentSectionLoading = current(state.selectedProductSchema.selectedTabInfo.sectionLoading);

      if (type === "add") {
        const newSectionLoading = [...currentSectionLoading]
        newSectionLoading.push({ name, section },)
        state.selectedProductSchema.selectedTabInfo.sectionLoading = newSectionLoading
      }
      else if (type === "remove") {
        const newSectionLoading = currentSectionLoading.filter((item) => {
          if (item.section !== section || item.name !== name) {
            return true
          }
        });
        state.selectedProductSchema.selectedTabInfo.sectionLoading = newSectionLoading
      }
    },


    AllErrorsFormObject: (state, action) => {
      state.ErrorsformObject = action.payload;
    },
    showGtinExistingErrorMessage: (state, action) => {
      state.showGtinExistingErrorMessage = action.payload
    },

    clearSearchResults: (state, action) => {
      state.actionsLoading = false;
      state.dashSearchData = null;
    },

    changeSingleAddProdFormObject: (state, action) => {
      state.addProdFormObject = { ...state.addProdFormObject, ...action.payload };
    },

    clearManufacturerProductsPerPage: (state, action) => {
      state.productsPerPage = [];
    },
  },

  extraReducers:
    (builder) => {
      builder
        /** RETRIEVE VOUCHER*/
        .addCase(getProductByGtinGsM.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
        })
        .addCase(getProductByGtinGsM.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.gsproduct = action.payload;
        })
        .addCase(getProductByGtinGsM.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })


        .addCase(getProductUpidsMaster.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
        })
        .addCase(getProductUpidsMaster.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.upidsMasterProduct = action.payload;
        })
        .addCase(getProductUpidsMaster.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })

        .addCase(dashboardSearch.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
        })
        .addCase(dashboardSearch.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.dashSearchData = action.payload;
        })
        .addCase(dashboardSearch.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })



        .addCase(getProductsWithPage.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isAllDataFetchedLoading = true;
        })
        .addCase(getProductsWithPage.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.isAllDataFetchedLoading = false;
          state.productsPerPage = action.payload;
          state.viewCompanyPageLoads = true;
        })
        .addCase(getProductsWithPage.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })

        .addCase(getManufacturerSpecificProducts.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isAllDataFetchedLoading = true;
          state.productsPerPage = [];
        })
        .addCase(getManufacturerSpecificProducts.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.isAllDataFetchedLoading = false;
          state.productsPerPage = action.payload;
          state.viewCompanyPageLoads = true;
        })
        .addCase(getManufacturerSpecificProducts.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
          state.productsPerPage = [];
        })


        .addCase(getFavouriteProducts.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isAllDataFetchedLoading = true;
        })
        .addCase(getFavouriteProducts.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.isAllDataFetchedLoading = false;
          state.productsPerPage = action.payload;
          state.viewCompanyPageLoads = true;
        })
        .addCase(getFavouriteProducts.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })


        .addCase(getProductsSearch.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.editProductProfileLoading = true;
        })
        .addCase(getProductsSearch.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.editProductProfileLoading = false;
          state.error = null;
          state.productsPerPage = action.payload;
          state.searchProduct = action.payload[0];
        })
        .addCase(getProductsSearch.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })

        .addCase(getProductByIdAction.pending, (state, action) => {
          const arg = action.meta.arg

          if (arg && arg.isCancelBtn && arg.isLoader) {
            state.error = null;
          }
          else if (arg && arg.isLoader) {
            state.error = null;
            state.editProductProfileLoading = true;
          }

        })
        .addCase(getProductByIdAction.fulfilled, (state, action) => {
          /** UPDATE PRODUCT */
          const data = action.payload.responseData;
          state.editProductProfileLoading = false;
          state.error = null;
          state.productById = data;
          state.formObject = data;
        })
        .addCase(getProductByIdAction.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })


        .addCase(getProductTemplateSchemaAction.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getProductTemplateSchemaAction.fulfilled, (state, action) => {
          const { formType, productSchemaType, productSchema, productFlattenSchema, productTabStructuredSchema, } = action.payload
          state.actionsLoading = false;
          const stateProductSchema = current(state.selectedProductSchema.productSchema)

          if (!_.isEqual(stateProductSchema, productSchema)) {
            state.selectedProductSchema.formType = formType;
            state.selectedProductSchema.productSchemaType = productSchemaType;
            state.selectedProductSchema.productSchema = productSchema;
            state.selectedProductSchema.productFlattenSchema = productFlattenSchema;
            state.selectedProductSchema.productTabStructuredSchema = productTabStructuredSchema
          }
        })
        .addCase(getProductTemplateSchemaAction.rejected, (state, action) => {
          state.actionsLoading = false;
        })


        .addCase(addProduct.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;

          state.isLoading = true;
          state.addResponse = null;

          state.showGtinExistingErrorMessage = false

        })
        .addCase(addProduct.fulfilled, (state, action) => {
          if (!!objectPath.get(action.payload, 'data._2an')) {
            state.addProdFormObject._2an = objectPath.get(action.payload, 'data._2an');
          }
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = null;
          state.response = action.payload.data;
          state.addResponse = action.payload.status;

        })
        .addCase(addProduct.rejected, (state, action) => {
          // action.payload.data.error.message
          setTimeout(() => {
            toast.error(t(action.payload.response.data.error.message), 2000);
          }, 10);
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
          if (action.payload.response.status == 400) {
            state.showGtinExistingErrorMessage = true
          }
        })


        .addCase(deleteProduct.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isDeleteLoading = true;
          state.deleteResponse = null;
        })
        .addCase(deleteProduct.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.isDeleteLoading = false;
          state.deleteResponse = action.payload.status;
        })
        .addCase(deleteProduct.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })


        .addCase(addUpidsProduct.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isLoading = true;
          state.addResponse = null;
        })
        .addCase(addUpidsProduct.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = null;
          state.response = action.payload;
        })
        .addCase(addUpidsProduct.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })


        .addCase(getSpecificMetadataServer.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isLoading = true;
          state.addResponse = null;
        })
        .addCase(getSpecificMetadataServer.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = null;
          state.metadataList = action.payload;
        })
        .addCase(getSpecificMetadataServer.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })




        .addCase(getSegmentCodeDetails.pending, (state) => {
          state.familyLoading = true;
        })
        .addCase(getSegmentCodeDetails.fulfilled, (state, action) => {
          const arg = action.meta.arg
          if (arg.locale === 'en') {
            state.segmentCodeDetailsEn = action.payload;
          } else if (arg.locale === 'fi') {
            state.segmentCodeDetailsFi = action.payload;
          } else if (arg.locale === 'sv') {
            state.segmentCodeDetailsSv = action.payload;
          }

          state.isLoading = false;
          state.error = null;
          state.familyLoading = false;
        })
        .addCase(getSegmentCodeDetails.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
          state.familyLoading = false;
        })



        .addCase(getUpidsProduct.pending, (state, action) => {
          const arg = action.meta.arg
          if (arg && arg.isCancelBtn) {
          }
          else {
            state.error = null;
            state.getUpidsProductLoader = true;
          }
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
        })
        .addCase(getUpidsProduct.fulfilled, (state, action) => {
          state.actionsLoading = false;
          // state.isLoading = false;
          state.getUpidsProductLoader = false;
          state.error = null;
          state.upidsProductDetail = action.payload;
        })
        .addCase(getUpidsProduct.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
          state.segmentLoading = false;
        })


        .addCase(getUpidsProduct2an.pending, (state, action) => {
          const arg = action.meta.arg
          if (arg && arg.isCancelBtn) {
            state.error = null;
          }
          else {
            state.error = null;
            state.getUpidsProductLoader = true;
          }
        })
        .addCase(getUpidsProduct2an.fulfilled, (state, action) => {
          state.actionsLoading = false;
          // state.isLoading = false;
          state.getUpidsProductLoader = false;
          state.error = null;
          state.upidsProductDetail2an = action.payload;
        })
        .addCase(getUpidsProduct2an.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
          state.segmentLoading = false;
        })

        .addCase(getUpidsProductById.pending, (state, action) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;

          const arg = action.meta.arg

          if (arg && arg.isCancelBtn) {
          }
          else {
            state.error = null;
            state.getUpidsProductLoader = true;
          }
        })
        .addCase(getUpidsProductById.fulfilled, (state, action) => {
          state.actionsLoading = false;
          // state.isLoading = false;
          state.getUpidsProductLoader = false;
          state.error = null;
          state.upidsProductById = action.payload;
        })
        .addCase(getUpidsProductById.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.getUpidsProductLoader = false;
          state.unsubscribeLoading = false;
          state.segmentLoading = false;
          state.upidsProductById = {};
        })


        .addCase(deleteUpidsProduct.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isDeleteLoadingForUpids = true;
          state.deleteUpidsResponse = null;
        })
        .addCase(deleteUpidsProduct.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.isDeleteLoadingForUpids = false;
          state.deleteUpidsResponse = action.payload.status;
        })
        .addCase(deleteUpidsProduct.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })


        .addCase(liveProductSearch.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isLoading = true;
          state.addResponse = null;
          state.isAllDataFetchedLoading = true;
        })
        .addCase(liveProductSearch.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = null;
          state.isAllDataFetchedLoading = false;
          state.productsPerPage = action.payload;
        })
        .addCase(liveProductSearch.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })




        .addCase(getSegmentList.pending, (state) => {
          state.segmentLoading = true;
        })
        .addCase(getSegmentList.fulfilled, (state, action) => {
          const arg = action.meta.arg

          if (arg) {
            const locale = action.meta.arg.locale
            if (locale === 'en') {
              state.segmentListEn = action.payload;
            }
            else if (locale === 'fi') {
              state.segmentListFi = action.payload;
            }
            else if (locale === 'sv') {
              state.segmentListSv = action.payload;
            }
          }
          state.segmentLoading = false;
          state.isLoading = false;
          state.error = null;

        })
        .addCase(getSegmentList.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
          state.segmentLoading = false
        })



        .addCase(getAllSegmentList.pending, (state) => {
          state.segmentLoading = true;
        })
        .addCase(getAllSegmentList.fulfilled, (state, action) => {
          state.isLoading = false;
          state.error = null;
          state.segmentAllList = action.payload;
          state.segmentLoading = false;
        })
        .addCase(getAllSegmentList.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
          state.segmentLoading = false;
        })



        .addCase(getAudienceCountOfManufacturer.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
        })
        .addCase(getAudienceCountOfManufacturer.fulfilled, (state, action) => {
          state.audienceCountOfManufacturer = action.payload;
          state.actionsLoading = false
        })
        .addCase(getAudienceCountOfManufacturer.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })


        .addCase(sendEmailMessageToAudience.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.sendEmailMessageToAudienceSuccess = null;
          state.viewCompanyPageLoads = false;
        })
        .addCase(sendEmailMessageToAudience.fulfilled, (state, action) => {
          state.actionsLoading = false
          state.sendEmailMessageToAudienceSuccess = action.payload
        })
        .addCase(sendEmailMessageToAudience.rejected, (state, action) => {
          state.error = action.payload;
          state.sendEmailMessageToAudienceSuccess = null
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })


        .addCase(unsubscribeMarketingEmails.pending, (state) => {
          state.unsubscribeLoading = true;
          state.unsubscribeSuccessful = false
        })
        .addCase(unsubscribeMarketingEmails.fulfilled, (state, action) => {
          state.unsubscribeLoading = false
          state.unsubscribeSuccessful = true
        })
        .addCase(unsubscribeMarketingEmails.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })



        .addCase(getWarningsForProducts.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
        })
        .addCase(getWarningsForProducts.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.warningsDataForProducts = action.payload;
        })
        .addCase(getWarningsForProducts.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })


        .addCase(addWarningsForProduct.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
        })
        .addCase(addWarningsForProduct.fulfilled, (state, action) => {
          // state.actionsLoading = false;
          // state.addWarningForProduct = action.payload;
        })
        .addCase(addWarningsForProduct.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })


        .addCase(deleteWarningForProduct.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
        })
        .addCase(deleteWarningForProduct.fulfilled, (state, action) => {

        })
        .addCase(deleteWarningForProduct.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })


        .addCase(editWarningForProduct.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
        })
        .addCase(editWarningForProduct.fulfilled, (state, action) => {

        })
        .addCase(editWarningForProduct.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })



        .addCase(getNutritionalClaimsAction.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
        })
        .addCase(getNutritionalClaimsAction.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.nutritionalCliams = action.payload;
        })
        .addCase(getNutritionalClaimsAction.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.unsubscribeLoading = false;
        })


        .addCase(getProductHistoryModalShown.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
        })
        .addCase(getProductHistoryModalShown.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.productHistoryModal = true;
          state.productHistoryData = action.payload;

        })
        .addCase(getProductHistoryModalShown.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.productHistoryModal = false;
        })

        // Get all product templates
        .addCase(getAllProductTemplates.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
        })
        .addCase(getAllProductTemplates.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.allProductTemplate = action.payload;

        })
        .addCase(getAllProductTemplates.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
        })

        // Get specific product templates data
        .addCase(getIndividualProductTemplateData.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
        })
        .addCase(getIndividualProductTemplateData.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.individualProductTemplateData = action.payload;

        })
        .addCase(getIndividualProductTemplateData.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
        })

        // Add product template data
        .addCase(addProductTemplateData.pending, (state) => {
          state.error = null;
          state.isAddtemplateLoading = true;
        })
        .addCase(addProductTemplateData.fulfilled, (state, action) => {
          state.isAddtemplateLoading = false;

        })
        .addCase(addProductTemplateData.rejected, (state, action) => {
          state.error = action.payload;
          state.isAddtemplateLoading = false;
        })

        // Delete product template data
        .addCase(deleteProductTemplateData.pending, (state) => {
          state.error = null;
          state.isTemplateDeleteLoading = true;
          state.deleteResponse = null;
        })
        .addCase(deleteProductTemplateData.fulfilled, (state, action) => {
          state.error = null;
          state.isTemplateDeleteLoading = false;
        })
        .addCase(deleteProductTemplateData.rejected, (state, action) => {
          state.error = action.payload;
          state.isTemplateDeleteLoading = false;
          state.unsubscribeLoading = false;
        })


        // Get all metadata taxonomy data
        .addCase(getAllMetadataTaxonomyData.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
        })
        .addCase(getAllMetadataTaxonomyData.fulfilled, (state, action) => {
          state.error = null;
          state.actionsLoading = false;
          state.taxonomyItemsData = action.payload
        })
        .addCase(getAllMetadataTaxonomyData.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
        })

        // Add new metadata taxonomy data
        .addCase(addNewMetadataTaxonomyData.pending, (state) => {
          state.error = null;
          state.isLoading = true;
        })
        .addCase(addNewMetadataTaxonomyData.fulfilled, (state, action) => {
          state.error = null;
          state.isLoading = false;
        })
        .addCase(addNewMetadataTaxonomyData.rejected, (state, action) => {
          state.error = action.payload;
          state.isLoading = false;
        })

        // Edit taxonomy data
        .addCase(editTaxonomyData.pending, (state) => {
          state.error = null;
          state.isLoading = true;
          state.editTaxonomyResponse = null;
        })
        .addCase(editTaxonomyData.fulfilled, (state, action) => {
          state.error = null;
          state.isLoading = false;
          state.editTaxonomyResponse = action?.payload?.status;
        })
        .addCase(editTaxonomyData.rejected, (state, action) => {
          state.error = action.payload;
          state.isLoading = false;
        })

        // Delete taxonomy data
        .addCase(deleteTaxonomyItem.pending, (state) => {
          state.error = null;
          state.isLoading = true;
          state.deleteTaxonomyResponse = null;
        })
        .addCase(deleteTaxonomyItem.fulfilled, (state, action) => {
          state.error = null;
          state.isLoading = false;
          state.deleteTaxonomyResponse = action?.payload?.status;
        })
        .addCase(deleteTaxonomyItem.rejected, (state, action) => {
          state.error = action.payload;
          state.isLoading = false;
        })



    },

});


export const {
  addNewProductSuccesfully, selectProductTabInfoAction, updatedFlattenSchemaFormItemAction, updateStructuredSchemaObjectOptionsAction,
  addRemoveSectionLoadingAction, clearManufacturerProductsPerPage,
  clearUpidsArray, clearProductById, clearAddCustomMultiInputFields,
  setFamLoading, setSegmentLoading, changeProdEditMode, setFlagToShowConfirmDone, changeFormObject, currentUpidsFormObject,
  changeAddProdFormObject, changeFromErrorObject, changeProductSearchText, addCustomMultiInputFields, AllErrorsFormObject,
  clearSearchResults, productFormFieldUpdateAction, formFieldValidationAction, formSaveValidationAction,
  changeSingleAddProdFormObject, toggleProductHistoryDrawerFlag, changeProductTemplateEditMode, handleErrorsInProductTemplate, handleJSONErrorsInProductTemplate, handleJSONTemplateNameError, handleJSONTemplateLabelError, currentProductTemplateObject, updateTaxonomyDataFormAction, resetTaxonomyDataFormAction
  , taxonomyAllErrorsFormObject, taxonomyAllErrorsEditFormObject, currentTaxonomyFormObject, changeTaxonomyEditModeAction, setFirstProductObjFromProductList
} = productsSlice.actions;
