import React, { useEffect, } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { InputTextWithLabel } from 'src/components/inputComponents/InputTextWithLabel'
import { resetCmsDataFormAction, updateCmsDataFormAction } from 'src/redux/contentManagement/contentManagementSlice';
import { InputSelectDropdown } from 'src/components/inputComponents/InputSelectDropdown';
import JoditEditotRichText from './JoditEditotRichText';


function ContentmanagementCreate() {
  const { t } = useTranslation("common");

  const dispatch = useDispatch()


  const companyState = useSelector((state) => state.company, shallowEqual);
  const { companyInfo, } = companyState

  const cmsContent = useSelector((state) => state.cmsContent, shallowEqual);

  const { cmsFormData, allCmsCategoriesList } = cmsContent


  useEffect(() => {
    dispatch(resetCmsDataFormAction())
    return () => {
    }
  }, [])


  const handleFormChange = (e) => {
    dispatch(updateCmsDataFormAction({
      name: e.target.name,
      value: e.target.value
    }))

  };

  const getSupportedLanguageList = () => {
    if (companyInfo && companyInfo.supportedLanguages && companyInfo.supportedLanguages[0]) {
      const languageOption = companyInfo.supportedLanguages.map((item, index) => {
        return { key: index, text: item.name, value: item.code }
      })
      return languageOption
    }
    else {
      const languageOption = [
        { key: 1, text: "English", value: "en" },
        { key: 2, text: "Finnish", value: "fi" },
      ]
      return languageOption
    }

  }
  const supportedLanguageOption = getSupportedLanguageList()


  let cmsCategoriesList = []
  if (allCmsCategoriesList?.categories) {
    allCmsCategoriesList?.categories?.forEach((templateData, index) => {
      cmsCategoriesList.push({ key: index, label: templateData, value: templateData });
    });
  }

  return (
    <div className="row col-12 contentManagementForm">
      <InputTextWithLabel
        label={t("Title")}
        tooltipText={t("Title")}
        onChange={handleFormChange}
        name={"title"}
        value={cmsFormData.title}
        type="text"
        placeholder={t("Title")}
      // errorMsg={!!errorObject && errorObject.name ? errorObject.name : ""}
      />
      <InputTextWithLabel
        label={t("Product identifier")}
        tooltipText={t("Product identifier")}
        onChange={handleFormChange}
        name={"productIdentifier"}
        value={cmsFormData.productIdentifier}
        type="text"
        placeholder={t("Product identifier")}
      // errorMsg={!!errorObject && errorObject.name ? errorObject.name : ""}
      />

      <InputSelectDropdown
        label={t("_Category")}
        placeholder={t('Select category')}
        name="category"
        value={cmsFormData.category}
        options={cmsCategoriesList}
        onChange={handleFormChange}
      />

      <InputTextWithLabel
        label={t("Reference")}
        tooltipText={t("Reference")}
        onChange={handleFormChange}
        name={"reference"}
        value={cmsFormData.reference}
        type="text"
        placeholder={t("Reference")}
      // errorMsg={!!errorObject && errorObject.name ? errorObject.name : ""}
      />

      <InputTextWithLabel
        label={t("Order")}
        tooltipText={t("Order")}
        onChange={handleFormChange}
        name={"order"}
        value={cmsFormData.order}
        type="number"
        placeholder={t("Order")}
      // errorMsg={!!errorObject && errorObject.name ? errorObject.name : ""}
      />

      <InputSelectDropdown
        label={t("Language")}
        placeholder={t('Select language')}
        name="language"
        value={cmsFormData.language}
        options={supportedLanguageOption}
        onChange={handleFormChange}
      />

      <div className="form-group col-12" >
        <label>{t("cms content")}</label>
        <>
          <JoditEditotRichText
            isEditMode={true}
            joditEditorValue={cmsFormData.content}
          />
        </>
      </div>


    </div>
  )
}

export default ContentmanagementCreate