import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';
import _ from "lodash";



export const getAllCmsListContentAction = createAsyncThunk(
    "cmsContent/getAllCmsListContentAction",
    async (params, thunkApi) => {
        try {
            if (params) {
                const response = await getAxios().get(`/upids/cms/list/all?${params}`);
                return response.data;
            } else {
                const response = await getAxios().get(`/upids/cms/list/all`);
                return response.data;
            }
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getAllCmsCategoriesAction = createAsyncThunk(
    "cmsContent/getAllCmsCategoriesAction",
    async (params, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/services/config/${params}`
            );
            return response?.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const gateSpecificCmsContentAction = createAsyncThunk(
    "cmsContent/gateSpecificCmsContentAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/cms/${arg}`,
            );
            return { ...response.data, id: arg };
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);




export const createCmsContentAction = createAsyncThunk(
    "cmsContent/createCmsContentAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/upids/cms/add`,
                arg
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const updateCmsContentAction = createAsyncThunk(
    "cmsContent/updateCmsContentAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().put(
                `/upids/cms/update`,
                arg
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const deleteCmsContentAction = createAsyncThunk(
    "cmsContent/deleteCmsContentAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().delete(
                `/upids/cms/${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);
