import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown, Popup } from "semantic-ui-react";
import { MdAddCircleOutline, MdClose, MdSend } from 'react-icons/md';
import { IoIosHelpCircleOutline } from 'react-icons/io'
import { IconButton } from 'src/components/IconButton';
import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from "src/components/StSquareButton";
import _ from 'lodash';
import objectPath from "object-path";
import JoditEditor from 'jodit-react'


import { getAudienceCountOfManufacturer, sendEmailMessageToAudience } from 'src/redux/products/productThunk';


import Modal from "react-bootstrap/Modal";
import { usePrevious } from 'src/helper/CustomHook';
import { toast } from 'react-toastify';


export const EmailConsentComponent = () => {
  const { t } = useTranslation('common')

  const dispatch = useDispatch()
  const _productState = useSelector(
    (state) => (state.products),
    shallowEqual
  );

  const productData = _productState.formObject


  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [sendEmailMsgToAudienceSuccess, setSendEmailMessageToAudienceSuccess] = useState(false);
  const [emailData, setEmailData] = useState({});


  const { sendEmailMessageToAudienceSuccess, ErrorsformObject } = _productState;
  const prevPropsState = usePrevious({ sendEmailMessageToAudienceSuccess });

  useEffect(() => {
    dispatch(getAudienceCountOfManufacturer(objectPath.get(_.find(productData.businessIdentifiers, function (o) { return o.type === "VATID" }), "id", productData.businessIdentifiers[0].id)))
  }, []);

  useEffect(() => {
    if (prevPropsState) {
      if (prevPropsState.sendEmailMessageToAudienceSuccess !== sendEmailMessageToAudienceSuccess && sendEmailMessageToAudienceSuccess) {
        closeEmailPopup()
        setSendEmailMessageToAudienceSuccess(true)
        setTimeout(() => {
          setSendEmailMessageToAudienceSuccess(false)
        }, 3000);
      }
    }
  }, [sendEmailMessageToAudienceSuccess])


  const closeEmailPopup = () => {
    setShowEmailPopup(false)
    setEmailData({})
  }


  function handleEmailData(e) {
    setEmailData({ ...emailData, [e.target.name]: e.target.value })
  }

  const handleEmailBodyRichTextHtml = (newContent) => {
    setEmailData({ ...emailData, body: newContent })
  }


  const handleSubmitEmail = async () => {
    let emailInfo = {
      "text": emailData.body,
      "subject": emailData.subject,
      "businessIdentifiers": productData.businessIdentifiers
    }
    try {
      let res = await dispatch(sendEmailMessageToAudience(emailInfo)).unwrap();
      toast.success(t("Markeing email consent successful"))
    } catch (error) {
      toast.error(t(error?.response?.data?.error?.message || "Something went wrong!"));
    }
  }


  const joditEditorConfig = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    placeholder: t('Type something'),
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    minHeight: 200,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    // disablePlugins: "fullsize",
    toolbarAdaptive: false,
    buttons: [
      'bold', 'strikethrough', 'underline', 'italic', 'ul', 'ol', 'outdent', 'indent', 'font', 'fontsize',
      'brush', 'paragraph', 'table', 'source', 'align', 'undo', 'redo', 'hr', 'eraser', 'copyformat', 'print',
      // 'symbol', 'fullsize', 'about', 'image','video','link', 
    ],
  }

  return (
    <>
      {!!_productState.audienceCountOfManufacturer &&
        <>
          <div className="st-divider divider-thin my-3"></div>
          <div className="row">
            <div className='col-lg-9 col-md-9 col-sm-9 col-9 mb-3'>
              <span className='tab-section-info'>{t("_Email consent")}</span>
              <Popup pinned wide='very' position='top center' on={'click'}
                trigger={<IoIosHelpCircleOutline style={{ marginBottom: 2, color: '#6c757d', cursor: 'pointer', marginLeft: 5 }} fontWeight={'bold'} />} >
                <p>{t("Email consent")}</p>
              </Popup>
            </div>

            <div className='col-lg-3 col-md-3 col-sm-3 col-3'>
              <IconButton text={t("Send Email")} icon={MdSend} onClick={() => setShowEmailPopup(true)} />
            </div>
          </div>
          <div className="col-12 product-profile feedback-count">
            {t("Total consent emails count")} : <span>{_productState.audienceCountOfManufacturer[0].count}</span>
          </div>
          {sendEmailMsgToAudienceSuccess && <p style={{ color: '#50C878', marginTop: 6 }}>{t('Markeing email consent successful')}</p>}
        </>
      }


      <Modal scrollable size="lg" centered={true} backdrop="static" show={showEmailPopup} onHide={() => closeEmailPopup()} >
        <Modal.Header style={{ height: 40 }}>
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => closeEmailPopup()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal' }}>
          <div className="row m-0 justify-content-center">
            <div className="form-group col-11"  >
              <label>{t("Subject")}</label>
              <input onBlur={handleEmailData} name={"subject"} type="text" placeholder={t("Subject")} style={{ outline: 'none' }} />
            </div>
            <div className="form-group col-11" >
              <label>{t("Body")}</label>
              <JoditEditor
                value={emailData.body}
                config={joditEditorConfig}
                onBlur={(newContent) => { handleEmailBodyRichTextHtml(newContent) }}
              ></JoditEditor>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Send Email")} onClick={() => handleSubmitEmail()} loading={_productState.actionsLoading}></StSquareButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}
