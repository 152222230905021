import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PostAuthRoutes from "./PostAuthRoutes";
import DashboardNavbar from "./components/navbars/DashboardNavbar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Sidebar from "./components/sidebar/Sidebar";
import Cookies from 'universal-cookie';
import AppContextProvider from './AppContext';
import ProductsNavbar from "./components/navbars/ProductsNavbar";
import ProductProfileNavbar from "./components/navbars/ProductProfileNavbar";
import ContentManagementNavbar from './components/navbars/ContentManagementNavbar'
import ManufacturerNavbar from "./components/navbars/ManufacturerNavbar";
import ManufacturerProfileNavbar from "./components/navbars/ManufacturerProfileNavbar";
import UsersNavbar from "./components/navbars/UsersNavbar";
import UserProfileNavbar from "./components/navbars/UserProfileNavbar";
import MetadataNavbar from "./components/navbars/MetadataNavbar";
import CertificateNavbar from "./components/navbars/CertificationNavbar";
import FeedbackNavbar from "./components/navbars/FeedbackNavbar";
import DocumentationNavbar from "./components/navbars/DocumentationNavbar";
import OwnProfileNavbar from "./components/navbars/OwnProfileNavbar";
import UpidsProductNavbar from "./components/navbars/UpidsProductNavbar";
import UpidsNavbar from "./components/navbars/CreateUpidsNavbar";
import CompanyNavbar from "./components/navbars/CompanyNavbar";
import VouchersNavbar from "./components/navbars/VouchersNavbar";
import ProudctTemplateNavbar from "./components/navbars/ProductTemplateNavbar";
import { changeSessionStatFetched } from "src/redux/dashboard/dashboardSlice";
import MetadataProfileNavbar from "./components/navbars/MetadataProfileNavbar";
import CertificationProfileNavbar from "./components/navbars/CertificationProfileNavbar";


const getNavbar = (pathName) => {
  let navbar = <DashboardNavbar />
  if (pathName.includes("/dashboard")) {
    navbar = <DashboardNavbar />
  }
  else if (pathName.includes("/productProfile" && "/upidsProduct")) {
    navbar = <UpidsProductNavbar />
  }
  else if (pathName.includes("/productProfile")) {
    navbar = <ProductProfileNavbar />
  }
  else if (pathName.includes("/products") || pathName.includes("/addproduct")) {
    navbar = <ProductsNavbar />
  }
  else if (pathName.includes("/cms") || pathName.includes("/addproduct")) {
    navbar = <ContentManagementNavbar />
  }
  else if (pathName.includes("/manufacturers/profile") || pathName.includes("/manufacturer/")) {
    navbar = <ManufacturerProfileNavbar />
  }
  else if (pathName.includes("/manufacturers") || pathName.includes("/manufacturers/add")) {
    navbar = <ManufacturerNavbar />
  }
  else if (pathName.includes("/users/profile")) {
    navbar = <UserProfileNavbar />
  }
  else if (pathName.includes("/myprofile")) {
    navbar = <OwnProfileNavbar />
  }
  else if (pathName.includes("/users") || pathName.includes("/users/add")) {
    navbar = <UsersNavbar />
  }
  else if (pathName.includes("/metadata/profile")) {
    navbar = <MetadataProfileNavbar />
  }
  else if (pathName.includes("/metadata") || pathName.includes("/metadata/add")) {
    navbar = <MetadataNavbar />
  }
  else if (pathName.includes("/certifications/profile")) {
    navbar = <CertificationProfileNavbar />
  }
  else if (pathName.includes("/certifications") || pathName.includes("/certifications/add")) {
    navbar = <CertificateNavbar />
  }
  else if (pathName.includes("/addupidsproduct")) {
    navbar = <UpidsNavbar />
  }
  else if (pathName.includes("/feedbacks")) {
    navbar = <FeedbackNavbar />
  }
  else if (pathName.includes("/documentation")) {
    navbar = <DocumentationNavbar />
  }
  else if (pathName.includes("/company")) {
    navbar = <CompanyNavbar />
  }
  else if (pathName.includes("/vouchers")) {
    navbar = <VouchersNavbar />
  }
  else if (pathName.startsWith("/templates/")) {
    navbar = <ProudctTemplateNavbar />
  }
  return navbar
}

const AppContainer = (props) => {
  const [state, setState] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const cookies = new Cookies();
  const _state = useSelector(
    (state) => (state.dashboard),
    shallowEqual
  );
  useEffect(() => {
    onRouteChanged();

    let _authState = cookies.get('AUTH_STATE');
    !_authState && navigate('/login')
  }, []);

  useEffect(() => {
    if (_state.sessionExpire) {
      cookies.remove("AUTH_STATE");
      //Store.localStorageRemove(Store.Keys.AUTH_STATE);
      dispatch(changeSessionStatFetched(false))
      alert('Invalid Token please login again')
      navigate("/login");
    }
  }, [_state.sessionExpire]);

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  function onRouteChanged() {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/user-pages/login-1",
      "/user-pages/login-2",
      "/user-pages/register-1",
      "/user-pages/register-2",
      "/user-pages/lockscreen",
      "/error-pages/error-404",
      "/error-pages/error-500",
      "/general-pages/landing-page",
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (location.pathname === fullPageLayoutRoutes[i]) {
        setState({
          isFullPageLayout: true,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.add("full-page-wrapper");
        break;
      } else {
        setState({
          isFullPageLayout: false,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");
      }
    }
  }


  return (
    <React.Fragment>
      <AppContextProvider>
        <div className="container-scroller">
          {!state.isFullPageLayout && getNavbar(location.pathname)}
          <div className="container-fluid page-body-wrapper">
            {!state.isFullPageLayout && <Sidebar key={'sidebar'} />}
            <div className="main-panel">
              <div style={{ height: '100%', paddingTop: 1 }}>
                <PostAuthRoutes />
              </div>
              {/* {footerComponent} */}
            </div>
          </div>
        </div>
      </AppContextProvider>
    </React.Fragment>
  );
};

export default AppContainer;
