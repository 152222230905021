import React from "react";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import objectPath from "object-path";
import { clearUpidsArray } from 'src/redux/products/productsSlice';
import { MaterialTable } from "src/components/MaterialTable";
import { Spinner } from "src/components/Spinner";


function UpidsProductTableListing(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation("common");

    const productState = useSelector((state) => (state.products), shallowEqual);

    const allData = [];

    const handleEditClickEvent = (o) => {
        dispatch(clearUpidsArray({}));
        navigate(`/productProfile/${objectPath.get(productState.productById, 'gtin', productState.productById._2an)}/upidsProduct/${o.upidsId}`,
            {state: { productData: productState.productById }
        })
    };

    const createTableData = () => {
        if (!!props.upidsProductList && props.upidsProductList.length > 0) {
            props.upidsProductList.map((o, index) => {
                allData.push({
                    productName: productState?.productById?.productName?.en,
                    upidsId: o.upidsId,
                    creationDate: moment(o.createdDate * 1000).format('DD-MMM-YYYY, h:mm:ss'),
                    action: (
                        <MdEdit key={index} style={{ cursor: "pointer" }}
                            onClick={(e) => handleEditClickEvent(o)}
                        />
                    ),
                });
            });
        }
    };
    createTableData();

    return (
        <>
            {props.loading ? (
                <div>
                    <Spinner />
                </div>
            ) : (
                <MaterialTable
                    columnNames={[
                        { title: t("ProductName"), field: "productName" },
                        { title: t("Upids Id"), field: "upidsId" },
                        { title: t("Creation date"), field: "creationDate" },
                        { title: t("Action"), field: "action", sorting: false, headerStyle: { textAlign: 'center' }, cellStyle: { textAlign: 'center' } },
                    ]}
                    tableData={allData}
                    options={{
                        pageSizeOptions: [], // Set an empty array
                        // other options...
                    }}
                ></MaterialTable>
            )}
            <br></br>
        </>
    );
}

export default UpidsProductTableListing


