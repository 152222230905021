import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';
// import * as _ from 'lodash'



/**
 * Api call for getting all user data
 */
export const getUsersAction = createAsyncThunk(
    "users/getUsersAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/iam/user/filter?orderby=uId&order=desc`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

/**
 * edit the specific user data
 */
export const editSpecifcUser = createAsyncThunk(
    "users/editSpecifcUser",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().patch(
                `/iam/user`, arg.body
            );
            return response;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/**
 * get data search by firstname, lastname or email
 */
export const getUsersSearch = createAsyncThunk(
    "users/getUsersSearch",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/iam/user/search?value=${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/**
 * get data search by firstname, lastname or email
 */
export const getLiveUsersSearch = createAsyncThunk(
    "users/getLiveUsersSearch",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/search/users?value=${arg.searchText}&perpagecount=20&pageno=${arg.pageNo}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



/**
 * get specific user data
 */
export const getDataForSpecificUserAction = createAsyncThunk(
    "users/getDataForSpecificUserAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/iam/user`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



/**
 * get data by uid
 */
 export const getDataByUidAction = createAsyncThunk(
    "users/getDataByUidAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/iam/user/${arg.uid}`
              );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



/**
 * editing the user record
 */
export const editUser = createAsyncThunk(
    "users/editUser",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().patch(
                `/iam/user/${arg.id}`,
                arg.body
            );
            return response;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

/**
 * add new user record
 */
export const addUser = createAsyncThunk(
    "users/addUser",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/iam/user`,
                arg.body
            );
            return response;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



/**
 *  delete the user record
 */
export const deleteUser = createAsyncThunk(
    "users/deleteUser",
    async (arg, thunkApi) => {
        try {
            const response = getAxios().delete(
                `/iam/user/${arg}`
            );
            return response;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);