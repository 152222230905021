import { createSlice } from "@reduxjs/toolkit";
import {
  getDashboardCount, getDashboardNewsfeedData, getContentfulData, getDashboardLatestFeedbackData,
  getAverageNpsScore, getFeedbackSentimentScore, sendFeedbackOfProduct
} from "./dashboardThunk";

const initialDashboardState = {
  actionsLoading: false,
  response: null,
  error: null,
  passwordSent: false,
  newPassDone: false,
  refreshTokenDone: false,
  sessionExpire: false,
  dashboardCounts: [],
  feedbackSentimentScore: null,
  averageNpsScore: null,
  sendProductFeedbackSuccess: null,
  showSuccessMsg: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    changeSessionStatFetched: (state, action) => {
      state.sessionExpire = action.payload;
    },

  },


  extraReducers:
    (builder) => {
      builder
        /**  */
        .addCase(getDashboardCount.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getDashboardCount.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.dashboardCounts = action.payload;
        })
        .addCase(getDashboardCount.rejected, (state, action) => {
          state.actionsLoading = false;
          state.error = action.payload;
        })


        /**  */
        .addCase(getDashboardNewsfeedData.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getDashboardNewsfeedData.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.dashboardNewsfeedData = action.payload;
        })
        .addCase(getDashboardNewsfeedData.rejected, (state, action) => {
          state.actionsLoading = false;
          state.error = action.payload;
        })


        /**  */
        .addCase(getContentfulData.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getContentfulData.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.contentfulData = action.payload;
        })
        .addCase(getContentfulData.rejected, (state, action) => {
          state.actionsLoading = false;
          state.error = action.payload;
        })


        /**  */
        .addCase(getDashboardLatestFeedbackData.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getDashboardLatestFeedbackData.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.dashboardLatestFeedbackData = action.payload;
        })
        .addCase(getDashboardLatestFeedbackData.rejected, (state, action) => {
          state.actionsLoading = false;
          state.error = action.payload;
        })


        /**  */
        .addCase(getAverageNpsScore.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getAverageNpsScore.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.averageNpsScore = action.payload;
        })
        .addCase(getAverageNpsScore.rejected, (state, action) => {
          state.actionsLoading = false;
          state.error = action.payload;
        })


        /**  */
        .addCase(getFeedbackSentimentScore.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getFeedbackSentimentScore.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.feedbackSentimentScore = action.payload;
        })
        .addCase(getFeedbackSentimentScore.rejected, (state, action) => {
          state.actionsLoading = false;
          state.error = action.payload;
        })


        /**  */
        .addCase(sendFeedbackOfProduct.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(sendFeedbackOfProduct.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.showSuccessMsg = true;
          state.sendProductFeedbackSuccess = action.payload;
        })
        .addCase(sendFeedbackOfProduct.rejected, (state, action) => {
          state.actionsLoading = false;
          state.error = action.payload;
        })




    },
});


export const { changeSessionStatFetched } = dashboardSlice.actions;

