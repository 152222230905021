import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import _ from 'lodash';
import Dropzone from 'react-dropzone'
import objectPath from "object-path";
import { MdDelete, } from 'react-icons/md';
import Modal from "react-bootstrap/Modal";
import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from "src/components/StSquareButton";
import { StCancelSquareButton } from 'src/components/StCancelSquareButton'
import emptyimage from 'src/assets/images/emptyimage.png'
import { uploadCompanyLogoImage, deleteCompanyLogoImage } from 'src/redux/media/mediaThunk';


function CompanySideBar() {
  const { t } = useTranslation('common');
  const dispatch = useDispatch()
  const companyState = useSelector((state) => state.company, shallowEqual);
  const { companyInfo } = companyState

  const [delShow, setDelShow] = useState(false)
  const [delModelState, setDelModelState] = useState({ modelTitle: '', modelText: '', modelDelUrl: '' })
  const [docUrl, setDocUrl] = useState({
    logo: { isDirty: false, list: [], readyToUpload: null, baseForm: null },
  });

  useEffect(() => {
    const newDocUrl = { ...docUrl }
    if (_.get(companyInfo, 'logoUrl')) {
      newDocUrl.logo.list[0] = _.get(companyInfo, 'logoUrl')
    } else {
      const newDocUrl = { ...docUrl }
      newDocUrl.logo.list =  []
    }
    setDocUrl(newDocUrl)
    return () => {

    }
  }, [companyInfo])


  const onImageDrop = (acceptedFiles, type) => {
    let file = acceptedFiles[0];
    const reader = new FileReader()
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.readAsDataURL(file)
    reader.onload = () => {
      let _docUrl = docUrl;
      objectPath.set(_docUrl, `${type}.baseForm`, reader.result);
      objectPath.set(_docUrl, `${type}.readyToUpload`, file);
      objectPath.set(_docUrl, `${type}.list`, [reader.result]);
      objectPath.set(_docUrl, `${type}.isDirty`, false);
      setDocUrl({ ..._docUrl });
      uploadImage(type)
    }
  }



  const uploadImage = (type) => {
    const formData = new FormData();
    formData.append(type, objectPath.get(docUrl, `${type}.readyToUpload`));
    const businessId = objectPath.get(_.find(companyInfo.businessIdentifiers, function (item) { return item.type === "VATID" }), 'id', companyInfo.businessIdentifiers[0].id)
    dispatch(uploadCompanyLogoImage({ businessId: businessId, body: formData, type: type, }));
  }

  const deleteClicked = (url, type) => {
    setDelModelState({ ...delModelState, modelDelUrl: url, modelTitle: t('Delete image'), modelText: t('Are you sure you want to delete the image?') })
    setDelShow(true)
  }

  const handleDelModelClose = () => {
    setDelShow(false)
  }

  const handleDelModelDelete = () => {
    const fileName = _.get(companyInfo, 'logoUrl')
    dispatch(deleteCompanyLogoImage({ fileName: fileName }));
    handleDelModelClose()
  }


  const MainImageList = ['logo',];

  return (
    <div className="col-xl-3 col-xxl-4 col-md-4 manufacturersidebar mt-3">
      {/* <div className="main-image-div">
        <img className="imgTag" src={emptyimage} alt="alt"></img>
      </div> */}

      {MainImageList.map((item, index) => (
        <div key={index} >
          <div key={index} className="row justify-content-center" style={{ display: 'grid', alignItems: 'center' }}>
            <Dropzone onDrop={acceptedFiles => onImageDrop(acceptedFiles, item)}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({
                  className: 'dropzone',
                  onClick: event => { event.target.id !== "image-upload-id" && event.stopPropagation() }
                })}>
                  <input {...getInputProps()} />
                  <div className="flip-card">
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <img style={{objectFit:'contain'}} alt="alt" src={!!objectPath.get(docUrl, `${item}.list.0`) ? objectPath.get(docUrl, `${item}.list.0`) : emptyimage} />
                      </div>
                      <div className="flip-card-back">
                        <img style={{objectFit:'contain'}} alt="alt" id="image-upload-id" src={!!objectPath.get(docUrl, `${item}.list.0`) ? objectPath.get(docUrl, `${item}.list.0`) : emptyimage} />
                        {!!objectPath.get(docUrl, `${item}.list.0`) &&
                          <div>
                            <div className="media-card triangle"></div>
                            <MdDelete onClick={() => deleteClicked(objectPath.get(docUrl, `${item}.list.0`), 'image')} size={22} className="media-card delete-icon" />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        </div>
      ))}

      <div style={{ height: 1, backgroundColor: '#DFEDF5', width: '100%', marginTop: 18, marginBottom: 32 }}></div>
      <div className="col-12">
        <span className="title">{_.get(companyInfo, 'name')}</span>
        <span style={{wordBreak:'break-word'}} className="subtitle">{_.get(companyInfo, 'contact')}</span>
      </div>


      <Modal size="md" centered={true} backdrop="static" show={delShow} onHide={handleDelModelClose} >
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleDelModelClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal', textAlign: 'center' }}>
          <div className="row m-0 justify-content-center">
            <span className="col-12 media-card model-text" >{delModelState.modelTitle}</span>
            <span className="col-12 media-card model-content mt-4" >{delModelState.modelText}</span>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center', margin: 15 }}>
          <StSquareButton text={t("Delete")}
            // loading={_mediaState.isMediaDeleting}
            onClick={() => handleDelModelDelete()}></StSquareButton>
          <StCancelSquareButton text={t("Cancel")} onClick={() => handleDelModelClose()}></StCancelSquareButton>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CompanySideBar
