import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import IconButton from "../../commonComponent/Icons/Icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { clearAddCustomMultiInputFields, changeProdEditMode } from "src/redux/products/productsSlice";
import { addUpidsProduct, deleteUpidsProduct, getUpidsProductById, getUpidsProduct } from "src/redux/products/productThunk";
import { usePrevious } from "../../../helper/CustomHook";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";

import logo from '../../../assets/images/brand_icons/upids-pdm-logo.png'
import { BiTrash } from "react-icons/bi";
import { MdClose,MdMenu,MdOutlineArrowCircleUp,MdOutlineModeEdit,MdInfoOutline,MdOutlineSave,MdAddCircle,MdOutlineClose ,MdArrowBack} from "react-icons/md";
import { StMenu } from "src/components/StMenu";
import {IconButton} from "src/components/IconButton"
import { StSquareButton } from "src/components/StSquareButton";
import { StCancelSquareButton } from "src/components/StCancelSquareButton";
import { StCloseButton } from "src/components/StCloseButton";


const UpidsProductNavbar = () => {

    const upidsProductNavbarCollection=[
        {menuItem: ()=><IconButton text={t("Create Upids Product")} icon={MdOutlineArrowCircleUp} onClick={()=>handleCreateClick()}/>},
        {menuItem: ()=><IconButton text={t("Edit")} icon={MdOutlineModeEdit} onClick={()=>handleEditClick()}/>,
          submenu:[
            {menuItem: ()=><IconButton text={t("Save Changes")} icon={MdOutlineSave} onClick={()=>handleSaveClick()}/>},
            {menuItem: ()=><IconButton text={t("Delete Upids Product")} icon={BiTrash} onClick={()=>handleDeleteClick()}/>},
            {menuItem: ()=><IconButton text={t("Cancel")} icon={MdOutlineClose} onClick={()=>handleCancelClick()}/>}
          ],
          submenuTitle: ()=><IconButton text={t("Edit")} icon={MdOutlineModeEdit} onClick={()=>handleEditClick()}/>},
      ]

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    const fullUrl = window.location.href;
    let gtin = fullUrl.split("/").pop();
    const [showDelPopup, setShowDelPopup] = useState(false);


    function toggleOffcanvas() {
        document.querySelector(".sidebar-offcanvas").classList.toggle("active");
    }
    const _state = useSelector((state) => state.products, shallowEqual);
    let data = _state.currentUpidsObject;
    const { deleteUpidsResponse,addCustomMultiInputFields } = _state;
    const prevPropsState = usePrevious({ deleteUpidsResponse });

    useEffect(() => {
        if (prevPropsState) {
            if (prevPropsState.deleteUpidsResponse !== deleteUpidsResponse && deleteUpidsResponse) {
                navigate(`/productProfile/${data.articleIdentifier}`);
            }
        }
    }, [_state]);

    const handleCreateClick = () => {
        navigate(`/addupidsproduct/${gtin}`);
    }

    const handleEditClick = () => {

        dispatch(changeProdEditMode(true));
    };

    const handleSaveClick = () => {
        dispatch(addUpidsProduct({...data,...addCustomMultiInputFields}));
        dispatch(changeProdEditMode(false));

    };

    const handleDeleteClick = () => {
        setShowDelPopup(true);
    };

    const handleDelClose = () => setShowDelPopup(false);

    const handleDeleteChanges = () => {
        dispatch(deleteUpidsProduct(data.upidsId));
    };


    const handleCancelClick = () => {
        dispatch(changeProdEditMode(false));
        dispatch(clearAddCustomMultiInputFields())
        dispatch(getUpidsProduct({ field: 'upidsId', value: data.upidsId, isCancelBtn: true }))
        dispatch(getUpidsProductById({upidsId: data.upidsId }))
    };


    return (
        <>
            <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <Link className="navbar-brand brand-logo" to="/dashboard">
                        <img src={logo} alt="logo" />
                    </Link>
                    <Link
                        className="navbar-brand brand-logo-mini d-inline-block d-lg-none"
                        to="/"
                    >
                        <img
                            src={require("../../../assets/images/logo-mini.svg")}
                            alt="logo"
                        />
                    </Link>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-center">
                    <span
                        style={{
                            
                            fontSize: 16,
                            color: "#131E27",
                            opacity: 1,
                        }}
                    >
                        <MdArrowBack
                            onClick={() => navigate(-1)}
                            style={{
                                width: 24,
                                height: 24,
                                backgroundColor: "transparent",
                                opacity: 1,
                                cursor: "pointer",
                            }}
                        />
                        <Trans></Trans>
                    </span>
                    {!_state.isProdEditMode ? (
                        <div className="navbar-nav navbar-nav-right d-none d-sm-flex">
                            <IconButton
                                text={t("Create Upids Product")}
                                icon={MdOutlineArrowCircleUp}
                                onClick={handleCreateClick}
                            />
                            <IconButton
                                text={t("Edit")}
                                icon={MdOutlineModeEdit}
                                onClick={handleEditClick}
                            />
                        </div>
                    ) : (
                        <div className="navbar-nav navbar-nav-right d-none d-sm-flex">
                            <IconButton
                                text={t("Save Changes")}
                                icon={MdOutlineSave}
                                onClick={handleSaveClick}
                            />
                            <IconButton
                                text={t("Delete Upids Product")}
                                icon={BiTrash}
                                onClick={handleDeleteClick}
                            />
                            <IconButton
                                text={t("Cancel")}
                                icon={MdOutlineClose}
                                onClick={handleCancelClick}
                            />
                        </div>
                    )}

{/* responsive dropdown on mobile screen */}
        <ul className="navbar-nav navbar-nav-right productNavResponsiveDropdown">
           <StMenu menuArray={upidsProductNavbarCollection}></StMenu>
          </ul> 

                    <button
                        className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                        type="button"
                        onClick={toggleOffcanvas}
                    >
                        <MdMenu color="#000000" size={24} />
                    </button>
                </div>
            </nav>

            <Modal size="md" show={showDelPopup} onHide={handleDelClose} centered={true}>
                <Modal.Header >
                    <div style={{position:'absolute',right:10}}>
                    <StCloseButton text={t("Close")} onClick={()=>handleDelClose()}></StCloseButton>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18,  }}>
                    <span>{t("Are you sure want to delete the upids product")}</span>
                </Modal.Body>
                <Modal.Footer>
                    <StSquareButton text={t("Yes")} onClick={()=>handleDeleteChanges()} loading={_state.isDeleteLoadingForUpids}></StSquareButton>
            <StCancelSquareButton text={t("No")} onClick={()=>handleDelClose()}></StCancelSquareButton>
                </Modal.Footer>
            </Modal>


        </>

    );
};

export default UpidsProductNavbar;
