import { combineReducers } from "redux";
import { authSlice } from "./auth/authSlice";
import { productsSlice } from "./products/productsSlice";
import { companySlice } from "./company/companySlice";
import { usersSlice } from "./users/usersSlice";
import { dashboardSlice } from "./dashboard/dashboardSlice";
import { feedbackSlice } from "./feedback/feedbackSlice";
import { mediaSlice } from "./media/mediaSlice";
import { analyticsSlice } from "./analytics/analyticsSlice";
import { swaggerauthSlice } from "./swaggerAuth/swaggerauthSlice";
import scrapingSlice from "./scraping/scrapingSlice";
import aiDataSlice from "./aiData/aiDataSlice";
import { versioningSlice } from "./versioning/versioningSlice";
import { favouritesSlice } from "./favourites/favouritesSlice";
import accountSlice from "./account/accountSlice";
import cmsContent from "./contentManagement/contentManagementSlice";
import adminSlice from "./admin/adminSlice";
import { certificationsSlice } from "./certifications/certificationsSlice";

export const rootReducer = combineReducers({
  login: authSlice.reducer,
  account: accountSlice.reducer,
  admin: adminSlice.reducer,
  products: productsSlice.reducer,
  company: companySlice.reducer,
  users: usersSlice.reducer,
  dashboard: dashboardSlice.reducer,
  feedback: feedbackSlice.reducer,
  media: mediaSlice.reducer,
  analytics: analyticsSlice.reducer,
  swaggerauth: swaggerauthSlice.reducer,
  scraping: scrapingSlice.reducer,
  aiTranslations: aiDataSlice.reducer,
  versioning: versioningSlice.reducer,
  favourites: favouritesSlice.reducer,
  cmsContent: cmsContent.reducer,
  certifications : certificationsSlice.reducer
});

