import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';



export const getClickstreamEntries = createAsyncThunk(
    "analytics/getClickstreamEntries",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/reporting/clickstream/list${arg}`
            );

            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const getClickstreamList = createAsyncThunk(
    "analytics/getClickstreamList",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/reporting/clickstream/list/article/6430041645115?start=1535161128&end=1635249124`
            );

            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const getProductScansAndErrors = createAsyncThunk(
    "analytics/getProductScansAndErrors",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/reporting/clickstream/count/article/${arg.articleId}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getUpidsProductScanCount = createAsyncThunk(
    "analytics/getUpidsProductScanCount",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/reporting/clickstream/count/realtime/article${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getClickstreamTotalCount = createAsyncThunk(
    "analytics/getClickstreamTotalCount",
    async (arg, thunkApi) => {
        try {
            let queryParams = !!arg ? arg : ""
            const response = await getAxios().get(
                `/reporting/clickstream/count/realtime/total${queryParams}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getClickstreamTotalCountNotFound = createAsyncThunk(
    "analytics/getClickstreamTotalCountNotFound",
    async (arg, thunkApi) => {
        try {

            return [];
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getClickstreamListProducts = createAsyncThunk(
    "analytics/getClickstreamListProducts",
    async (arg, thunkApi) => {
        try {
            let queryParams = !!arg ? arg : ""
            const response = await getAxios().get(
                `/reporting/clickstream/list/products${queryParams}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err?.response?.data?.error?.message);
        }
    }
);

export const getClickstreamListProductsNotFound = createAsyncThunk(
    "analytics/getClickstreamListProductsNotFound",
    async (arg, thunkApi) => {
        try {
            return [];
        } catch (err) {
            return thunkApi.rejectWithValue(err?.response?.data?.error?.message);
        }
    }
);

export const getClickstreamCompanies = createAsyncThunk(
    "analytics/getClickstreamCompanies",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/reporting/clickstream/list/companies`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const getClickstreamArticles = createAsyncThunk(
    "analytics/getClickstreamArticles",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/reporting/clickstream/list/articles`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getClickstreamScanCountListByBusinessId = createAsyncThunk(
    "analytics/getClickstreamScanCountListByBusinessId",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/reporting/clickstream/list/company/${arg.businessId}${arg.queryParams}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const getClickstreamScanCountListByArticleId = createAsyncThunk(
    "analytics/getClickstreamScanCountListByArticleId",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/reporting/clickstream/list/article/${arg.articleId}${arg.queryParams}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getClickstreamEnvs = createAsyncThunk(
    "analytics/getClickstreamEnvs",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/reporting/clickstream/count/realtime/envs`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getBillingDaily = createAsyncThunk(
    "analytics/getBillingDaily",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/reporting/billing/api/today${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getBillingEstimation = createAsyncThunk(
    "analytics/getBillingEstimation",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/reporting/billing/api/list${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getBillingMonthly = createAsyncThunk(
    "analytics/getBillingMonthly",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/reporting/billing/api/monthly${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);