import { createSlice } from "@reduxjs/toolkit";
import { getVersionsList, getVersion, getCurrentVersion, restoreVersion } from './versioningThunk'

const initialVersioningState = {
  actionsLoading: false,
  response: null,
  error: null,
  isAllDataFetchedLoading: false,
  isLoading: false,
};

export const versioningSlice = createSlice({
  name: "versioning",
  initialState: initialVersioningState,
  reducers: {
  
  },

  extraReducers:
    (builder) => {
      builder
        .addCase(getVersionsList.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getVersionsList.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.versions = action.payload;
        })
        .addCase(getVersionsList.rejected, (state, action) => {
          state.actionsLoading = false;
        })


        .addCase(getVersion.pending, (state) => {
          state.compareLoading = true;
        })
        .addCase(getVersion.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.compareLoading = false;
          state.versionToCompare = action.payload;
        })
        .addCase(getVersion.rejected, (state, action) => {
          state.compareLoading = false;
        })


        .addCase(getCurrentVersion.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getCurrentVersion.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.currentVersion = action.payload;
        })
        .addCase(getCurrentVersion.rejected, (state, action) => {
          state.actionsLoading = false;
        })


        .addCase(restoreVersion.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(restoreVersion.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.currentVersion = action.payload;
        })
        .addCase(restoreVersion.rejected, (state, action) => {
          state.actionsLoading = false;
        })


    },
});
