import { createSlice, current } from "@reduxjs/toolkit";
import _ from 'lodash';
import { toast } from "react-toastify";
import { t } from "src/locales/localeHelper"
import { getAllCertificationsData, addNewCertificationsData, deleteCertificateByIdAction, editCertificatAction, deleteCertificateLogoImageAction } from "./certificationsThunk";




const initialCertificationsState = {
    actionsLoading: false,
    CertificationsData: [],
    certificationsFormData: {},
    certificationsErrorsformObject: [],
    isCertificateEditMode: false,
    certificateErrorsformEditObject:[]
};

export const certificationsSlice = createSlice({
    name: "certifications",
    initialState: initialCertificationsState,
    reducers: {

        certificationsAllErrorsFormObject: (state, action) => {
            state.certificationsErrorsformObject = action.payload;
        },

        resetCertificationsDataFormAction: (state, action) => {
            state.certificationsFormData = initialCertificationsState.certificationsFormData
        },

        updateCertificationsDataFormAction: (state, action) => {
            if (action.payload && action.payload.name) {
                const { name, value } = action.payload
                state.certificationsFormData[name] = value;
            }
        },

        changeCertificateEditModeAction: (state, action) => {
            state.isCertificateEditMode = action.payload;
          },

          certificateAllErrorsEditFormObject: (state, action) => {
            state.certificateErrorsformEditObject = action.payload;
          },

          currentCertificateFormObject: (state, action) => {
            state.currentCertificateObj = action.payload;
          },

    },

    extraReducers:
        (builder) => {
            builder

                // Get all metadata taxonomy data
                .addCase(getAllCertificationsData.pending, (state) => {
                    state.error = null;
                    state.actionsLoading = true;
                })
                .addCase(getAllCertificationsData.fulfilled, (state, action) => {
                    state.error = null;
                    state.actionsLoading = false;
                    state.CertificationsData = action.payload
                })
                .addCase(getAllCertificationsData.rejected, (state, action) => {
                    state.error = action.payload;
                    state.actionsLoading = false;
                })

                // Add new certifications data
                .addCase(addNewCertificationsData.pending, (state) => {
                    state.error = null;
                    state.actionsLoading = true;
                })
                .addCase(addNewCertificationsData.fulfilled, (state, action) => {
                    state.error = null;
                    state.actionsLoading = false;
                })
                .addCase(addNewCertificationsData.rejected, (state, action) => {
                    state.error = action.payload;
                    state.actionsLoading = false;
                })

                 // delete certificate by id action
                 .addCase(deleteCertificateByIdAction.pending, (state) => {
                    state.error = null;
                    state.actionsLoading = true;
                })
                .addCase(deleteCertificateByIdAction.fulfilled, (state, action) => {
                    state.error = null;
                    state.actionsLoading = false;
                })
                .addCase(deleteCertificateByIdAction.rejected, (state, action) => {
                    state.error = action.payload;
                    state.actionsLoading = false;
                })

                 // edit certificate by id action
                 .addCase(editCertificatAction.pending, (state) => {
                    state.error = null;
                    state.actionsLoading = true;
                })
                .addCase(editCertificatAction.fulfilled, (state, action) => {
                    state.error = null;
                    state.actionsLoading = false;
                })
                .addCase(editCertificatAction.rejected, (state, action) => {
                    state.error = action.payload;
                    state.actionsLoading = false;
                })

                  // edit certificate by id action
                  .addCase(deleteCertificateLogoImageAction.pending, (state) => {
                    state.error = null;
                    state.actionsLoading = true;
                })
                .addCase(deleteCertificateLogoImageAction.fulfilled, (state, action) => {
                    state.error = null;
                    state.actionsLoading = false;
                })
                .addCase(deleteCertificateLogoImageAction.rejected, (state, action) => {
                    state.error = action.payload;
                    state.actionsLoading = false;
                })

        },

});


export const {
    resetCertificationsDataFormAction, updateCertificationsDataFormAction, certificationsAllErrorsFormObject,changeCertificateEditModeAction,
    certificateAllErrorsEditFormObject,currentCertificateFormObject
} = certificationsSlice.actions;
