import React from 'react';
import Modal from "react-bootstrap/Modal";
import { StCloseButton } from "src/components/StCloseButton";
import { useTranslation } from "react-i18next";

const LowBalanceAlertModel = (props) => {
    const { t } = useTranslation('common');
    const { isShowModel, handleClose } = props


    return (
        <Modal size="md" centered={true} backdrop="static" show={isShowModel} onHide={handleClose} >
            <Modal.Header >
                <div style={{ position: 'absolute', right: 10 }}>
                    <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
                </div>
            </Modal.Header>
            <Modal.Body style={{ fontWeight: 'normal' }}>
                {t("Your account balance is low, please top-up immediately")}
            </Modal.Body>
            {/* <Modal.Footer style={{ justifyContent: 'center' }}>
                    <StSquareButton text={t("Select")} onClick={() => handleSelect()}></StSquareButton>
                </Modal.Footer> */}
        </Modal>
    )
}

export default LowBalanceAlertModel