import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "src/components/Spinner";
import { MaterialTable } from "src/components/MaterialTable";
import { useTranslation } from "react-i18next";
import { getAccountBillingAction } from 'src/redux/account/accountThunk';
import _ from 'lodash';
import moment from 'moment';
import LowBalanceAlertModel from './LowBalanceAlertModel';


function Billing() {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()


    const reduxState = useSelector(state => state)
    const { company: companyState, account: accountState } = reduxState
    const { companyInfo } = companyState

    const [isShowModel, setIsShowModel] = useState(false)


    useEffect(() => {
        dispatch(getAccountBillingAction())
    }, [])




    const productInfoCount = () => {
        const countes = {
            activeProductCount: _.get(accountState, "accountCount.productCount", 0),
            perProductPrice: +_.get(companyInfo, "perProductPrice", 0),
            nextBillingDay: "", // company logic
            nextBillingAmount: 0, //  activeProductCount * perProductPrice
            creditBalance: _.get(companyInfo, "creditBalance", 0), // creditBalance
            forecastBalanceAfterNextpayment: 0,  // creditBalance - nextBillingAmount
            isBalanceNegativeAfterNextBill: false,
        }

        const billingDay = _.get(companyInfo, "billingDay", 1)

        countes.nextBillingDay = moment().date(billingDay).add(1, 'months').format("MMM, Do");

        countes.nextBillingAmount = countes.activeProductCount * countes.perProductPrice
        countes.forecastBalanceAfterNextpayment = countes.creditBalance - countes.nextBillingAmount

        if (countes.forecastBalanceAfterNextpayment < 0) {
            countes.isBalanceNegativeAfterNextBill = true
        }

        return countes
    }

    const companyProductInfo = productInfoCount()

    useEffect(() => {
        if (companyProductInfo.isBalanceNegativeAfterNextBill) {
            setIsShowModel(true)
        }
    }, [companyProductInfo.isBalanceNegativeAfterNextBill])


    let paymentData = _.get(accountState, "transactionEventList", []).map((item) => {
        const lastEvent = _.last(item.events);
        const dateTime = (lastEvent && lastEvent.timestamp) ? moment(lastEvent.timestamp * 1000).format("YYYY-MM-DD HH:mm:ss") : "-"

        const newItem = {
            activity: t("Credit Purchase"),
            dateTime: dateTime,
            paymentId: item.paymentId,
            status: item.status,
            receiptUrl: (
                <>
                    <a href={item.receiptUrl} target="_blank" rel="noopener noreferrer">
                        {t("Download receipt (PDF)")}
                    </a>
                </>
            ),
        }
        return newItem
    })

    paymentData = _.orderBy(paymentData, 'dateTime', 'desc')


    let invoiceData = _.get(accountState, "invoiceList", []).map((item) => {
        let businessId;
        const businessIdObj = item.manufacturerBuisnessId.find(
            obj => obj.type === "VATID",
        );

        if (businessIdObj) {
            businessId = businessIdObj.id;
        } else {
            businessId = item.manufacturerBuisnessId[0].id;
        }

        const newItem = {
            invoiceDate: moment(item.invoiceDate._seconds * 1000).format("YYYY-MM-DD HH:mm:ss"),
            manufacturerName: item.manufacturerName,
            businessId: businessId,
            productTotalCount: item.productTotalCount,
            status: item.status,
            totalCharge: item.totalCharge,
        }
        return newItem
    })
    invoiceData = _.orderBy(invoiceData, 'invoiceDate', 'desc')




    return (
        <>
            {accountState.isBillingLoading ? (
                <div>
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className='row col-12'>
                        <div className="companyProductCount">
                            <div className='companyProductCount__data'>{companyProductInfo.activeProductCount}</div>
                            <div className='companyProductCount__info'>{t("Active products")}</div>
                        </div>
                        <div className="companyProductCount">
                            <div className='companyProductCount__data'>{companyProductInfo.perProductPrice} €</div>
                            <div className='companyProductCount__info'>{t("Per product/month")}</div>
                        </div>
                        <div className="companyProductCount">
                            <div className='companyProductCount__data'>{companyProductInfo.nextBillingDay}</div>
                            <div className='companyProductCount__info'>{t("Next billing run")}</div>
                        </div>
                        <div className="companyProductCount">
                            <div className='companyProductCount__data'>{companyProductInfo.nextBillingAmount} €</div>
                            <div className='companyProductCount__info'>{t("Next bill")}</div>
                        </div>
                        <div className="companyProductCount">
                            <div className='companyProductCount__data' data-negativeBalance={companyProductInfo.isBalanceNegativeAfterNextBill}>{companyProductInfo.creditBalance} €</div>
                            <div className='companyProductCount__info'>{t("Account balance")}</div>
                        </div>
                        <div className="companyProductCount">
                            <div className='companyProductCount__data' data-negativeBalance={companyProductInfo.isBalanceNegativeAfterNextBill}>{companyProductInfo.forecastBalanceAfterNextpayment} €</div>
                            <div className='companyProductCount__info'>{t("Forecast balance")}</div>
                        </div>
                    </div>

                    <div className="row billing-info col-12">
                        <hr />
                        <h3>{t("Account Transactions")}</h3>
                        <MaterialTable
                            className="billing-info__table"
                            columnNames={[
                                { title: t("Activity"), field: "activity" },
                                { title: t("Date"), field: "dateTime" },
                                { title: t("ID"), field: "paymentId" },
                                { title: t("_Status"), field: "status" },
                                { title: t("Attachments"), field: "receiptUrl", sorting: false, headerStyle: { textAlign: 'center' }, cellStyle: { textAlign: 'center' } },
                            ]}
                            tableData={paymentData}
                        />
                    </div>

                    <div className="row billing-info col-12" >
                        <hr />
                        <h3>{t("Invoice")}</h3>
                        <MaterialTable
                            columnNames={[
                                { title: t("Date"), field: "invoiceDate" },
                                { title: t("Product Total Count"), field: "productTotalCount" },
                                { title: t("Total Charge"), field: "totalCharge", sorting: false, headerStyle: { textAlign: 'center' }, cellStyle: { textAlign: 'center' } },
                                { title: t("_Status"), field: "status" },
                            ]}
                            tableData={invoiceData}
                        />
                    </div>
                    <LowBalanceAlertModel isShowModel={isShowModel} handleClose={() => setIsShowModel(false)} />
                </>
            )
            }
        </>
    )
}

export default Billing