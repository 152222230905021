import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { MaterialTable } from "src/components/MaterialTable";
import { MdDelete } from "react-icons/md";
import DeleteVoucherConfirmModal from './DeleteVoucherConfirmModal';
import _ from 'lodash';
import { getVouchersAction } from 'src/redux/admin/adminThunk';
import moment from 'moment';

function Vouchers() {
  const { t } = useTranslation('common');
  const dispatch = useDispatch()

  const reduxState = useSelector(state => state)
  const { admin: adminState } = reduxState

  const [voucherModalData, setVoucherModalData] = useState({
    isShowModal: false,
    voucherData: null
  })


  useEffect(() => {
    getVoucherList()
  }, [])

  const getVoucherList = async () => {
    dispatch(getVouchersAction())
  }

  const handleShowDeleteVoucherModal = (item) => {
    if (!voucherModalData.isShowModal && item) {
      setVoucherModalData({
        isShowModal: true,
        voucherData: item
      })
    }
    else {
      setVoucherModalData({
        isShowModal: false,
        voucherData: null
      })
    }
  }


  const voucherListData = _.get(adminState, "voucherList", []).map((item) => {
    const createdDateTime = _.get(item, "createdDate._seconds", "") ? moment(item.createdDate._seconds * 1000).format("YYYY-MM-DD HH:mm:ss") : "-"
    const expiryDateTime = _.get(item, "expiryDate._seconds", "")  ? moment(item.expiryDate._seconds * 1000).format("YYYY-MM-DD HH:mm:ss") : "-"
    const newItem = {
      GUID: item.GUID,
      createdDate: createdDateTime,
      expiryDate: expiryDateTime,
      status: item.status,
      value: item.value,
      action: (
        <>
          <MdDelete
            style={{ cursor: 'pointer', color: 'black' }} fontSize={18} fontWeight={20}
            onClick={() => handleShowDeleteVoucherModal(item)}
          />
        </>
      ),
    }
    return newItem
  })


  return (
    <div>
      <div className="row billing-info col-12">
        <hr />
        <h3>{t("Voucher list")}</h3>
        <MaterialTable
          className=""
          columnNames={[
            { title: t("GUID"), field: "GUID" },
            { title: t("Created date"), field: "createdDate" },
            { title: t("Expiry date"), field: "expiryDate" },
            { title: t("_Status"), field: "status" },
            { title: t("Value"), field: "value" },
            { title: t("Action"), field: "action", sorting: false, headerStyle: { textAlign: 'center' }, cellStyle: { textAlign: 'center' } },
          ]}
          tableData={voucherListData}
        />
      </div>

      <DeleteVoucherConfirmModal
        isShowModal={voucherModalData.isShowModal}
        voucherData={voucherModalData.voucherData}
        handleClose={handleShowDeleteVoucherModal}
      />
    </div>
  )
}

export default Vouchers