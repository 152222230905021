import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import VersionTable from '../../../versioning/VersionTable';

export const Versioning = () => {
  const productState = useSelector(
    (state) => (state.products),
    shallowEqual
  );
  const productData = productState.formObject;

  return (
    <>
      <div>
        <VersionTable gtin={productData.gtin} _2an={productData._2an} mode="product" />
      </div>
    </>
  )

}



