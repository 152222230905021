import React, { useState, useRef, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { AppContext } from '../../AppContext';
import * as Store from "../../../helper/localStorageHelper";
import { resetFavouritesList, } from "src/redux/favourites/favouritesSlice";
import { MdArrowBack, MdClose, MdMenu,MdList,MdPowerSettingsNew, MdOutlineModeEdit, MdOutlineSave, MdOutlineClose } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import logo from '../../../assets/images/brand_icons/upids-pdm-logo.png'
import { StMenu } from "src/components/StMenu";
import { IconButton } from "src/components/IconButton";
import objectPath from "object-path";
import { showPaymentModalAction,showPaymentTypeModalAction } from 'src/redux/account/accountSlice';
import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from "src/components/StSquareButton";
import { getCompanyDetails } from "src/redux/company/companyThunk";
import { AllErrorsEditFormObject, changeCompanyEditMode } from "src/redux/company/companySlice";
import { editCompanyUser } from "src/redux/company/companyThunk";
import { companyEditSchema } from "src/helper/yup-schema";
import { usePrevious } from "src/helper/CustomHook";


const CompanyNavbar = () => {

  const companyNavbarCollection = [
    {
      menuItem: () => <IconButton text={t("Edit")} icon={MdOutlineModeEdit} onClick={() => changeMode()} />,
      submenu: [
        { menuItem: () => <IconButton text={t("Save Changes")} icon={MdOutlineSave} onClick={() => handleSaveClick()} /> },
        { menuItem: () => <IconButton text={t("Cancel")} icon={MdOutlineClose} onClick={() => handleCancelClick()} /> }
      ],
      submenuTitle: () => <IconButton text={t("Edit")} icon={MdOutlineModeEdit} onClick={() => changeMode()} />
    },
  ]

  const cookies = new Cookies();
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const _state = useSelector((state) => state.company, shallowEqual);
  const {activeTabInfo} = _state
  const data = JSON.parse(JSON.stringify(_state.currentCompanyObj));

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  // function toggleRightSidebar() {
  //   document.querySelector(".right-sidebar").classList.toggle("open");
  // }
  const logout = (evt) => {
    // evt.preventDefault();
    cookies.remove("AUTH_STATE");
    //Store.localStorageRemove(Store.Keys.AUTH_STATE);
    Store.localStorageRemove("persist:root");
    dispatch(resetFavouritesList());
    navigate("/login");
  };

  const editProfile = () => {
    navigate(`/myprofile/${context.authState.uId}`);
  }

  const goToApiDocs = () => {
    navigate("/swagger/private");
  }

  const handleTopupBalance = () => {
    // dispatch(showPaymentModalAction(true))
    dispatch(showPaymentTypeModalAction(true));
}

const { editResponse } = _state;
const prevPropsState = usePrevious({ editResponse });

useEffect(() => {
  if (prevPropsState) {
    if (prevPropsState.editResponse !== editResponse && editResponse) {
      dispatch(changeCompanyEditMode(false));
      setShow(false);
    } 
  }
}, [_state]);

const handleClose = () => setShow(false);

let allErrors;
  const handleSaveClick = () => {
    let tempData;
    if (data) {
      tempData = {
        ...data,
        name: data.name,
        address: data.address,
        contact: data.contact,
        IBAN: data.IBAN,
        SWIFT_BIC: data.SWIFT_BIC,
        businessIdentifiers: data.businessIdentifiers,
      }
    }
    companyEditSchema
      .validate(tempData, { abortEarly: false })
      .then(function () {
        dispatch(AllErrorsEditFormObject([]));
      })
      .then(function () {
        setShow(true);
      })
      .catch(function (err, inner) {
        allErrors = err.inner;
        dispatch(AllErrorsEditFormObject(allErrors));
      });
  };
  
  const createManufacturerModel = (data) => {
    return {
      ...data,
      name: data.name,
      businessId: data.businessIdentifiers[0].id,
      businessIdentifiers: data.businessIdentifiers,
      address: data.address,
      countryCode: data.countryCode,
      // IBAN: data.IBAN,
      // SWIFT_BIC: data.SWIFT_BIC,
      supportedLanguages: data.supportedLanguages,
      contact: data.contact,
      recipientEmails: data.recipientEmails,
      apiKey: data.apiKey,
      // billedFor: data.billedFor ? data.billedFor : 'events',
      // billingType: data.billingType ? data.billingType : 'post-charge',
      // creditBalance: data.creditBalance,
      // perProductPrice: data.perProductPrice,
      // vat: data.vat,
      // region: data.region,
      // datapools: data.datapools,
      // privacyAddendum: data.privacyAddendum ? data.privacyAddendum : false,
    };
  };

  const handleSaveChanges = () => {
    let companyData = createManufacturerModel(data);
    // dispatch(action.editCompany({ id: data.id, body: companyData }));
    dispatch(editCompanyUser({ body: companyData }));

  };

  const changeMode = () => {
    dispatch(changeCompanyEditMode(true));
  }; 

  const handleCancelClick = () => {
    dispatch(changeCompanyEditMode(false));
    dispatch(getCompanyDetails({ isCancelBtn: true }));
    // dispatch(action.getCompanySearch({ businessid: businessid, isCancelBtn: true }));

  };

  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/dashboard">
            <img src={logo} alt="logo" />
          </Link>
          <Link
            className="navbar-brand brand-logo-mini d-inline-block d-lg-none"
            to="/"
          >
            <img
              src={require("../../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">
          <span
            style={{
              fontSize: 18,
              
              fontWeight: 600,
            }}
          >
            <Trans>{t("Company")}</Trans>
          </span>

          {!_state.isCompanyEditMode ? (
            <div className="navbar-nav navbar-nav-right d-none d-sm-flex me-4">
              <IconButton
                text={t("Edit")}
                icon={MdOutlineModeEdit}
                onClick={changeMode}
              />
            </div>
          ) : (
              <div className="navbar-nav navbar-nav-right d-none d-sm-flex me-4">
                {activeTabInfo.tabName !== "Warnings" &&
                  <IconButton
                    text={t("Save Changes")}
                    icon={MdOutlineSave}
                    onClick={handleSaveClick}
                  />
                }
              <IconButton
                text={t("Cancel")}
                icon={MdOutlineClose}
                onClick={handleCancelClick}
              />
            </div>
          )}


            <button type="button"
              onClick={() => handleTopupBalance()}
              className="btn text-uppercase form-button"
              style={{  background: "#cf2948", height: 40, color: "#ffffff" }}
            >
              {t("TOP-UP BALANCE")}
            </button>
          
           {/* responsive dropdown on mobile screen */}
           <ul className="navbar-nav navbar-nav-right productNavResponsiveDropdown">
            <StMenu menuArray={companyNavbarCollection}></StMenu>
          </ul>

          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
            <MdMenu color="#000000" size={24} />
          </button>
        </div>
      </nav>
      <Modal size="md" show={show} onHide={handleClose} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 16, fontFamily: 'Poppins' }}>
          <span>{t("Are you sure want to save the changes")}</span>
        </Modal.Body>
        <Modal.Footer>
          <StSquareButton text={t("Save Changes")} loading={_state.isLoading} onClick={() => handleSaveChanges()}></StSquareButton>
          <StSquareButton text={"Discard"} onClick={() => handleClose()}></StSquareButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CompanyNavbar;
