import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { getCompanyDetails } from 'src/redux/company/companyThunk';
import { StCloseButton } from "src/components/StCloseButton";
import { useTranslation } from "react-i18next";
import CardPayment from 'src/pages/analytics/CardPayment';
import { showPaymentModalAction, showPaymentVoucherModalAction } from 'src/redux/account/accountSlice';
import { getAccountBillingAction, paymentByVoucherCodeAction } from 'src/redux/account/accountThunk';
import { StSquareButton } from 'src/components/StSquareButton';
import { Loader } from 'src/components/Loader';


function PaymentModel() {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()

    const [paymentTopupAmount, setPaymentTopupAmount] = useState("");
    const [voucherCode, setVoucherCode] = useState("");

    const { isShowPaymentModal, isShowPaymentVoucherModal,isBillingLoading,paymentStatus,voucherCodeValue } = useSelector((state) => state.account);
    const _state = useSelector(state => state.company, shallowEqual);
    const { companyInfo } = _state;

    const handleClose = () => {
        dispatch(getCompanyDetails());
        dispatch(getAccountBillingAction());
        dispatch(showPaymentModalAction(false));
        dispatch(showPaymentVoucherModalAction(false));
        setPaymentTopupAmount("");
        setVoucherCode("");
    }

    const handleProcessVoucherPayment = () => {
        let voucherPaymentReqModel = {
            value : parseFloat(paymentTopupAmount),
            voucherCode: voucherCode,
            businessIdentifiers : companyInfo.businessIdentifiers
        }
        dispatch(paymentByVoucherCodeAction(voucherPaymentReqModel))
    }

    const handleSucessOk = () => {
        // dispatch(companyActions.getCompanyDetails());
        // dispatch(getAccountBillingAction())
        handleClose();
      }


    if (isShowPaymentModal) {
        return (
            <>
                <Modal size="md" centered={true} backdrop="static" show={isShowPaymentModal} onHide={handleClose} >
                    <Modal.Header >
                        <div style={{ position: 'absolute', right: 10 }}>
                            <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ fontWeight: 'normal' }}>
                        <CardPayment handleClose={handleClose} />
                    </Modal.Body>
                </Modal>
            </>
        )
    } else if (isShowPaymentVoucherModal) {
        return (
            <>
                {isBillingLoading &&
                    <div className="loading-overlay">
                        <Loader message={t("Processing payment")} color='primary' />
                    </div>
                }
                <Modal size="md" centered={true} backdrop="static" show={isShowPaymentVoucherModal} onHide={handleClose} >
                    <Modal.Header >
                        <div style={{ position: 'absolute', right: 10 }}>
                            <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ fontWeight: 'normal' }}>
                    <div className="upidsPayment__heading">
                            {t("Balance top-up payment")}
                        </div>
                        <div>
                           {(paymentStatus =="" || paymentStatus=="failed") ?  
                             <>
                                <div>
                                    <input style={{ height: 40 }}
                                        id="paymentName"
                                        className="payment-input"
                                        type={"text"}
                                        placeholder={t("Enter voucher code")}
                                        value={voucherCode}
                                        onChange={e => setVoucherCode(e.target.value)}
                                    />
                                </div>
                                {/* <div className='mt-3'>
                                    <input style={{ height: 40 }}
                                        id="paymentTopupAmount"
                                        className="payment-input"
                                        type={"number"}
                                        placeholder={t("Amount to top-up")}
                                        value={paymentTopupAmount}
                                        onChange={e => setPaymentTopupAmount(e.target.value)}
                                    />
                                </div> */}
                                { paymentStatus === "failed" &&
                                <div className="margin-top-10" style={{ color: 'red' }}>{t("Payment failed")}</div>}
                              </> 
                            :
                            <div className="upidsPayment__sucess">
                                <div className="upidsPayment__sucess__text">
                                    {`${t("Your account has been successfully credited with an amount of")} ${voucherCodeValue} €` }
                                </div>
                            </div>}

                        </div>
                      
                      
                    </Modal.Body>
                     <Modal.Footer style={{ justifyContent: 'center' }}>
                        {paymentStatus == "success" ? 
                        <StSquareButton text={t("Ok")} onClick={() => handleSucessOk()}></StSquareButton> :
                        <StSquareButton disabled={voucherCode ? false : true} text={t("Process payment")} onClick={() => handleProcessVoucherPayment()}></StSquareButton>
                         }
                    </Modal.Footer>
                </Modal>
            </>
        )
    } else {
        return null
    }

}

export default PaymentModel