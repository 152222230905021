import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import * as yup from "yup";
import { InputSelectDropdown } from "src/components/inputComponents/InputSelectDropdown";
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel"
import { updateTaxonomyDataFormAction, resetTaxonomyDataFormAction, taxonomyAllErrorsFormObject } from "src/redux/products/productsSlice";
import appConfig from "src/helper/appConfig";
import { metadataSchema } from "src/helper/yup-schema";

const AddMetadata = () => {
  /** redux store useDispatch & useSelector ------------------------------ **/
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const productState = useSelector((state) => state.products, shallowEqual);

  /** hooks useState ----------------------------------------------------- **/
  const [touchedState, setTouchedState] = useState(true);
  const [selectedDescriptionLanguage, setSelectedDescriptionLanguage] = useState("en")

  /** Variable declaration ----------------------------------------------------- **/
  const { taxonomyFormData, TaxonomyErrorsformObject } = productState;
  let AllErrors = TaxonomyErrorsformObject;
  const languagesList = [
    { label: "English", value: "en" },
    { label: "Finnish", value: "fi" }
  ]

 /** hooks useEffect----------------------------------------------------- **/
  useEffect(() => {
    dispatch(resetTaxonomyDataFormAction())
    return () => {
      dispatch(taxonomyAllErrorsFormObject([]))
    }
  }, [])

   /** handler functions--------------------------------------------------- **/

/** Function to update the description language---------------------------- */
  const handleChangeProductLanguage = (item, activeIndex) => {
    setSelectedDescriptionLanguage(item.value)
  }

   /** Function to save input field values--------------------------- */
  const handleFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (["attributeName", "category", "datatype"].includes(name)) {
      validator({ name: name, value: value });
    }
    if (name === "description") {
      const updatedDescription = {
        ...taxonomyFormData.description,
        [selectedDescriptionLanguage]: value
      };
      dispatch(updateTaxonomyDataFormAction({
        name,
        value: updatedDescription
      }));
    } else {
      dispatch(updateTaxonomyDataFormAction({
        name: name,
        value: value
      }))
    }
    setTouchedState(true)

  };

  /** Function to validate the input field values----------------------- */
  const validator = ({ name, value }) => {
    const existingErrorIndex = AllErrors.findIndex(
      (error) => error.path === name
    );
    let newErrors = [...AllErrors];
    yup
      .reach(metadataSchema, name)
      .validate(value)
      .then((valid) => {
        if (newErrors.length > 0) {
          newErrors = newErrors.filter((error) => error.path !== name);
          dispatch(taxonomyAllErrorsFormObject(newErrors));
        }
      })
      .catch((err) => {
        if (existingErrorIndex !== -1) {
          newErrors[existingErrorIndex] = {
            path: name,
            message: err.message,
          };
        } else {
          newErrors.push({ path: name, message: err.message });
        }
        dispatch(taxonomyAllErrorsFormObject(newErrors));
      });
  };

  /** Funtion to create error object based on yup validation ------------------------- */
  const errorShown = (AllErrors) => {
    const errorObject = {}
    AllErrors &&
      AllErrors[0] &&
      AllErrors.forEach((obj, index) => {
        errorObject[obj.path] = obj.message;
      });
    return errorObject
  };

  const errorObject = errorShown(AllErrors);

  return (
    <>
      <div className="row col-12 contentManagementForm">
        <InputTextWithLabel
          label={t("Taxonomy Item")}
          tooltipText={t("Taxonomy Item")}
          onChange={handleFormChange}
          name={"attributeName"}
          value={taxonomyFormData.attributeName}
          type="text"
          placeholder={t("Taxonomy Item")}
          touched={touchedState}
          errorMessage={!!errorObject && errorObject.attributeName ? errorObject.attributeName : ""}
        />
        <InputSelectDropdown
          label={t("Taxonomy Data Type")}
          placeholder={t('Taxonomy Data Type')}
          name="datatype"
          value={taxonomyFormData.datatype}
          options={appConfig?.taxonomyDataTypes}
          onChange={handleFormChange}
          touched={touchedState}
          errorMessage={!!errorObject && errorObject.datatype ? errorObject.datatype : ""}
        />
        <InputSelectDropdown
          label={t("Taxonomy Category")}
          placeholder={t('Taxonomy Category')}
          name="category"
          value={taxonomyFormData.category}
          options={appConfig?.taxonomyCategoryTypes}
          onChange={handleFormChange}
          touched={touchedState}
          errorMessage={!!errorObject && errorObject.category ? errorObject.category : ""}
        />
        <InputTextWithLabel
          label={t("Taxonomy Relation")}
          tooltipText={t("Taxonomy Relation")}
          onChange={handleFormChange}
          name={"relation"}
          value={taxonomyFormData.relation}
          type="text"
          placeholder={t("Taxonomy Relation")}
        />
        <InputTextWithLabel
          label={t("Taxonomy Linking")}
          tooltipText={t("Taxonomy Linking")}
          onChange={handleFormChange}
          name={"link"}
          value={taxonomyFormData.link}
          type="text"
          placeholder={t("Taxonomy Linking")}
        />
      </div>
      <div style={{ marginTop: -35 }}>
        <div style={{ margin: "10px" }} className="d-flex product-profile-navbar align-items-center border-0" >
          <div className='fw-bold me-3'>
            Language
          </div>
          <div id="navbarProductProfile">
            <ul className="nav nav-pills">
              {
                languagesList && languagesList[0] && languagesList.map((item, index) => (
                  <React.Fragment key={index}>
                    <li className="nav-item">
                      <a className="nav-link fw-normal" role="button"
                        data-tabselect={item.value == selectedDescriptionLanguage}
                        onClick={() => handleChangeProductLanguage(item, index)}
                      >
                        {item.label.split(/[;,]/)[0].trim()}
                      </a>
                    </li>
                  </React.Fragment>
                ))}
            </ul>
          </div>
        </div>
        <InputTextWithLabel
          label={t("Taxonomy Description")}
          tooltipText={t("Taxonomy Description")}
          onChange={handleFormChange}
          name={"description"}
          value={taxonomyFormData.description[selectedDescriptionLanguage]}
          type="text"
          placeholder={t("Taxonomy Description")}
          touched={touchedState}
        />
      </div>
    </>
  );
};

export default AddMetadata;
