import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import * as Store from "../../../helper/localStorageHelper";
import { useNavigate } from "react-router-dom";
// import IconButton from "../../commonComponent/Icons/Icons";
import { AllErrorsEditFormObject, changeCompanyEditMode } from "src/redux/company/companySlice";
import { getCompanySearch, editCompany, deleteCompany } from "src/redux/company/companyThunk";
import * as action from "../../../redux/company/companyActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { usePrevious } from "../../../helper/CustomHook";
import { useParams } from "react-router-dom";
import ManufacturerImage from "../../../assets/images/Manufacturers.png";

import { companyEditSchema, companySchema } from "../../../helper/yup-schema";
import { MdArrowBack, MdClose, MdMenu, MdOutlineModeEdit, MdOutlineSave, MdOutlineClose } from "react-icons/md";
import { BiTrash } from "react-icons/bi";
import logo from '../../../assets/images/brand_icons/upids-pdm-logo.png'
import { IconButton } from "src/components/IconButton"
import { StMenu } from "src/components/StMenu";
import { StSquareButton } from "src/components/StSquareButton";
import { StCancelSquareButton } from "src/components/StCancelSquareButton";
import { StCloseButton } from "src/components/StCloseButton";


const ManufacturerProfileNavbar = () => {

  const manufacturerProfileNavbarCollection = [
    {
      menuItem: () => <IconButton text={t("Edit")} icon={MdOutlineModeEdit} onClick={() => changeMode()} />,
      submenu: [
        { menuItem: () => <IconButton text={t("Save Changes")} icon={MdOutlineSave} onClick={() => handleSaveClick()} /> },
        { menuItem: () => <IconButton text={t("Delete Manufacturer")} icon={BiTrash} onClick={() => handleDeleteClick()} /> },
        { menuItem: () => <IconButton text={t("Cancel")} icon={MdOutlineClose} onClick={() => handleCancelClick()} /> }
      ],
      submenuTitle: () => <IconButton text={t("Edit")} icon={MdOutlineModeEdit} onClick={() => changeMode()} />
    },
  ]

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const [show, setShow] = useState(false);
  const [showDelPopup, setShowDelPopup] = useState(false);
  const [state, setState] = useState("")
  let fullUrl = window.location.href;
  let businessid = fullUrl.split("/").pop();

  const _state = useSelector((state) => state.company, shallowEqual);
  const _productState = useSelector((state) => state.products, shallowEqual);

  const data = JSON.parse(JSON.stringify(_state.currentCompanyObj));

  const { editResponse, deleteResponse } = _state;
  const prevPropsState = usePrevious({ editResponse, deleteResponse });

  useEffect(() => {
    if (prevPropsState) {
      if (prevPropsState.editResponse !== editResponse && editResponse) {
        dispatch(changeCompanyEditMode(false));
        setShow(false);
      } else if (
        prevPropsState.deleteResponse !== deleteResponse &&
        deleteResponse
      ) {
        navigate("/manufacturers");
      }
    }
  }, [_state]);

  const handleClose = () => setShow(false);

  let allErrors;
  const handleSaveClick = () => {
    let tempData;
    if (data && data[0]) {
      tempData = {
        ...data,
        name: data[0].name,
        address: data[0].address,
        contact: data[0].contact,
        IBAN: data[0].IBAN,
        SWIFT_BIC: data[0].SWIFT_BIC,
        businessIdentifiers: data[0].businessIdentifiers,
        contentItemUrl:data[0]?.contentItemUrl,
        webhookUrl:data[0]?.webhookUrl,
        webhookKey:data[0]?.webhookKey,
        flagForPolicy : data[0]?.privacyAddendum?.target?.value || false,
        invoicingEmail:data[0]?.invoicingEmail
      }
    }
    companyEditSchema
      .validate(tempData, { abortEarly: false })
      .then(function () {
        dispatch(AllErrorsEditFormObject([]));
      })
      .then(function () {
        setShow(true);
      })
      .catch(function (err, inner) {
        allErrors = err.inner;
        dispatch(AllErrorsEditFormObject(allErrors));
      });
  };

  const handleDeleteClick = () => {
    setShowDelPopup(true);
  };

  const handleCancelClick = () => {
    dispatch(changeCompanyEditMode(false));
    dispatch(getCompanySearch({ businessid: businessid, isCancelBtn: true }));

  };

  const handleDelClose = () => setShowDelPopup(false);

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  function toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }
  const logout = (evt) => {
    evt.preventDefault();
    Store.localStorageRemove(Store.Keys.AUTH_STATE);
    navigate("/login");
  };

  const changeMode = () => {
    dispatch(changeCompanyEditMode(true));
  };

  const createManufacturerModel = (data) => {

    return {
      name: data[0].name,
      businessId: data[0].businessIdentifiers[0].id,
      businessIdentifiers: data[0].businessIdentifiers,
      address: data[0].address,
      countryCode: data[0].countryCode,
      IBAN: data[0].IBAN,
      SWIFT_BIC: data[0].SWIFT_BIC,
      supportedLanguages: data[0].supportedLanguages,
      language: data[0].language,
      contact: data[0].contact,
      recipientEmails: data[0].recipientEmails,
      apiKey: data[0].apiKey,
      billedFor: data[0].billedFor ? data[0].billedFor : 'events',
      billingType: data[0].billingType ? data[0].billingType : 'post-charge',
      creditBalance: data[0].creditBalance,
      perProductPrice: data[0].perProductPrice,
      vat: data[0].vat,
      region: data[0].region,
      datapools: data[0].datapools,
      privacyAddendum: data[0].privacyAddendum ? data[0].privacyAddendum : false,
      contentItemUrl: data[0]?.contentItemUrl,
      webhookUrl: data[0]?.webhookUrl,
      webhookKey: data[0]?.webhookKey,
      priceTable : data[0]?.priceTable,
      invoicingEmail:data[0]?.invoicingEmail
    };
  };

  const handleSaveChanges = () => {
    let companyData = createManufacturerModel(data);
    dispatch(editCompany({ id: data[0].id, body: companyData }));
  };

  const handleDeleteChanges = () => {
    if (data && data[0]) {
      dispatch(deleteCompany(data[0].id));
    }
  };

  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/dashboard">
            <img src={logo} alt="logo" />
          </Link>
          <Link
            className="navbar-brand brand-logo-mini d-inline-block d-lg-none"
            to="/"
          >
            <img
              src={require("../../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">
          <span
            style={{

              fontSize: 16,
              color: "#131E27",
              opacity: 1,
            }}
          >
            <MdArrowBack
              onClick={() => navigate(-1)}
              style={{
                width: 24,
                height: 24,
                backgroundColor: "transparent",
                opacity: 1,
                cursor: "pointer",
              }}
            />
            <Trans></Trans>
          </span>
          <img
            style={{
              width: 24,
              height: 24,
              opacity: 1,
              backgroundColor: "transparent",
              marginLeft: 15,
            }}
            src={ManufacturerImage}
            alt="Logo Image"
          />
          {(_state.viewCompanyPageLoads || _productState.viewCompanyPageLoads) && <span
            style={{
              marginLeft: 15,

              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: 16,
              color: "#131E27",
            }}
          >
            {/* {!!data && data.length === 1 ? data[0].name : ""} */}
            {/* {data && data[0] && data.length === 1 && data[0].name} */}
            {data && data[0] && data[0].name}

          </span>}


          {!_state.isCompanyEditMode ? (
            <div className="navbar-nav navbar-nav-right d-none d-sm-flex">
              <IconButton
                text={t("Edit")}
                icon={MdOutlineModeEdit}
                onClick={changeMode}
              />
            </div>
          ) : (
            <div className="navbar-nav navbar-nav-right d-none d-sm-flex">
              <IconButton
                text={t("Save Changes")}
                icon={MdOutlineSave}
                onClick={handleSaveClick}
              />
              <IconButton
                text={t("Delete Manufacturer")}
                icon={BiTrash}
                onClick={handleDeleteClick}
              />
              <IconButton
                text={t("Cancel")}
                icon={MdOutlineClose}
                onClick={handleCancelClick}
              />
            </div>
          )}

          {/* responsive dropdown on mobile screen */}
          <ul className="navbar-nav navbar-nav-right productNavResponsiveDropdown">
            <StMenu menuArray={manufacturerProfileNavbarCollection}></StMenu>
          </ul>

          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
            <MdMenu color="#000000" size={24} />
          </button>
        </div>
      </nav>

      <Modal size="md" show={show} onHide={handleClose} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18, }}>
          <span>{t("Are you sure want to save the changes")}</span>
        </Modal.Body>
        <Modal.Footer>
          <StSquareButton text={t("Save Changes")} loading={_state.isLoading} onClick={() => handleSaveChanges()}></StSquareButton>
          <StSquareButton text={"Discard"} onClick={() => handleClose()}></StSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal size="md" show={showDelPopup} onHide={handleDelClose} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleDelClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18, }}>
          <span>{t("Are you sure want to delete the manufacturer")}</span>
        </Modal.Body>
        <Modal.Footer>
          <StSquareButton text={t("Yes")} loading={_state.isDeleteLoading} onClick={() => handleDeleteChanges()}></StSquareButton>
          <StCancelSquareButton text={t("No")} onClick={() => handleDelClose()}></StCancelSquareButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManufacturerProfileNavbar;
