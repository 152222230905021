import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { StCloseButton } from "src/components/StCloseButton";
import { useTranslation } from "react-i18next";
import { StSquareButton } from "src/components/StSquareButton";
import { changeProdEditMode } from "src/redux/products/productsSlice";
import { getProductTemplateList } from 'src/redux/products/productThunk';
import { getCompanyInfo } from "src/redux/company/companyThunk";
import FormFieldSchemaRender from "src/components/schemaRender/FormFieldSchemaRender";
import { addProduct } from 'src/redux/products/productThunk'
import { ADD } from "src/helper/constants/constants";
import { getCompany } from 'src/redux/company/companyThunk';
import { AppContext } from 'src/pages/AppContext';
import { getValidationErrorObjectForYup } from 'src/helper/validationYupHelper';
import _ from 'lodash';
import LoadingSpinner from 'src/components/LoadingOverlay';



const AddProduct = () => {

  const context = useContext(AppContext);
  const navigate = useNavigate();
  const dispatch = useDispatch()


  const { t, i18n } = useTranslation('common');


  const [productCategoery, setProductCategoeryList] = useState([])
  const [companyData, setCompanyData] = useState({
    companyList: [],
    options: []
  })
  const [formData, setFormData] = useState({
    profileActive: true
  })
  const [loading, setLoading] = useState(false);

  const [formValidation, setFormValidation] = useState({
    isAllTouched: true,
    touched: {},
    errorMessage: {},
  })


  useEffect(() => {
    getInitialData()
    return () => {
    }
  }, [])

  // const params = new URLSearchParams(window.location.search);
  // const locale = params.get("lang");

  const getInitialData = async () => {
    setLoading(true)
    try {
      const templateData = await dispatch(getProductTemplateList()).unwrap();
      const productTypeOptions = templateData.map((o, index) => {
        const item = { key: index, label: o.label, value: o.name };
        return item
      });
      setProductCategoeryList(productTypeOptions)
      await handleCompanyDetails();
      setLoading(false)
    } catch (error) {

    }
  }

  const handleCompanyDetails = async () => {
    if (context.authState.level === 10) {
      try {
        const companyList = await dispatch(getCompany({})).unwrap();
        const companyListOptions = companyList.map((o, index) => {
          const item = { key: index, label: o.name, value: o.id };
          return item
        });
        setCompanyData({
          companyList: companyList,
          options: companyListOptions
        })
      } catch (error) {

      }
    } else {
      setFormData({
        ...formData,
        companyId: context.authState.companyId,
        businessIdentifiers: context.authState.businessIdentifiers,
      });
      const options = [{ key: context.authState.companyId, value: context.authState.companyId, label: context.authState.companyName }]
      setCompanyData({
        companyList: [{ ...context.authState, }],
        options: options
      })
    }
  }


  const handleClose = () => {
    navigate(-1)
  }

  const handleSaveProduct = async () => {
    try {
      const companyDetails = companyData.companyList.find((item) => (item.id === formData.companyId || item.companyId === formData.companyId))
      const productAddModel = {
        ...formData,
        businessIdentifiers: companyDetails.businessIdentifiers,
        countryCode: companyDetails.countryCode,
        productHierarchyType: 'item',
        dataInjectorVersion: '1.0.7',
        dataSource: "PDM",
      }
      const { errorMessageObject, errorFieldList } = await getValidationErrorObjectForYup(addProductSchema, formData)
      setFormValidation({
        ...formValidation,
        errorMessage: errorMessageObject
      })

      if (!_.isEmpty(errorMessageObject)) {
        return;
      }

      setLoading(true)
      const response = await dispatch(addProduct({ productData: productAddModel, formType: ADD })).unwrap();
      const responseData = response.data
      if (responseData._2an) {
        dispatch(changeProdEditMode(true));
        navigate(`../productProfile/${responseData._2an}`, { replace: true })
      }
      else if (responseData.gtin) {
        dispatch(changeProdEditMode(true));
        navigate(`../productProfile/${responseData.gtin}`, { replace: true });
      }
    } catch (error) {
    }
    setLoading(false)
  }



  const addProductSchema = [
    {
      name: 'companyId',
      label: '_Company',
      placeholder: '',
      tooltipText: 'Business ID',
      parentTab: 'General',
      tabName: 'Identifier',
      readOnly: false,
      type: 'select',
      isMultilingual: false,
      options: _.get(companyData, "options", []),
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: [
            'Company is required.'
          ]
        }
      ]
    },
    {
      name: 'productType',
      label: 'Product type',
      tooltipText: 'Select product type',
      readOnly: false,
      type: 'select',
      options: [
        ...productCategoery,
      ],
      isMultilingual: true,
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: [
            'Product type is required'
          ]
        }
      ]
    },
    {
      name: `productName.${i18n.language}`,
      label: '_Product Name',
      placeholder: '',
      tooltipText: 'Product Name',
      readOnly: false,
      type: 'text',
      isMultilingual: false,
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: [
            'Product Name is required'
          ]
        }
      ]
    },
    {
      name: 'gtin',
      label: '_GTIN',
      placeholder: '',
      tooltipText: 'gtin help text',
      readOnly: false,
      type: 'text',
      isMultilingual: false,
      validationType: 'string',
      validations: [
        {
          type: 'matches',
          params: [
            "^[0-9]{0}$|^[0-9]{8}$|^[0-9]{12}$|^[0-9]{14}$",
            'gtin should be 8 or 12 or 14 character & should be number.'
          ]
        }
      ]
    },

  ]


  const handleInputChange = (e) => {
    const newFormData = { ...formData }
    _.set(newFormData, e.target.name, e.target.value)
    setFormData({
      ...newFormData,
    })
  }



  useEffect(() => {
    dispatch(getCompanyInfo({}));
  }, []);



  return (
    <>
      {loading && <LoadingSpinner />}
      <Modal size="md" centered={true} backdrop="static" show={true} onHide={handleClose} >
        <Modal.Header >
          <div className="d-flex flex-column">
            <span className="fw-bold">{t("Add new product")}</span>
          </div>
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal' }}>
          <div className="row col-12 m-0 justify-content-center">
            {addProductSchema && addProductSchema[0] && addProductSchema.map((item, index) => (
              <React.Fragment key={index}>
                <FormFieldSchemaRender
                  key={`formFieldSchemaRender${item.name}_${index}`}
                  index={index}
                  formItem={item}
                  formValueObject={formData}
                  formValidationObject={formValidation}
                  // onBlur={handleBlurChange}
                  onChange={handleInputChange}
                  isSmallScreen={true}
                />
              </React.Fragment>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Add")} onClick={() => handleSaveProduct()}></StSquareButton>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default AddProduct;
