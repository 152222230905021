import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';
import { getProductByIdAction } from '../products/productThunk';
import _ from 'lodash';

/** Action call for getting all company data */
export const getCompany = createAsyncThunk(
    "company/getCompany",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/iam/company/filter?orderby=name&order=desc&perpagecount=500`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

/** search company by business id or company name */
export const getCompanySearch = createAsyncThunk(
    "company/getCompanySearch",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/iam/company/search?value=${arg.businessid}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



/** getting data search by business id or company name */
export const getParticularCompanySearch = createAsyncThunk(
    "company/getParticularCompanySearch",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/iam/company/search?value=${arg.businessid}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

/** getting live search for company */
export const getLiveCompanySearch = createAsyncThunk(
    "company/getLiveCompanySearch",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/search/manufacturers?value=${arg.searchText}&perpagecount=20&pageno=${arg.pageNo}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



/** */
export const getCompanySearchList = createAsyncThunk(
    "company/getCompanySearchList",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/iam/company`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



/** */
export const getCompanyInfo = createAsyncThunk(
    "company/getCompanyInfo",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/iam/company`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



/** */
export const getCompanyDetails = createAsyncThunk(
    "company/getCompanyDetails",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/iam/company`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/** editing the company record */
export const editCompany = createAsyncThunk(
    "company/editCompany",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().patch(
                `/iam/company/${arg.id}`,
                arg.body
            );
            return response;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getEcomPoint = createAsyncThunk(
    "company/getEcomPoint",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/integrations/integrations/businessid/${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const addEcomPoint = createAsyncThunk(
    "company/addEcomPoint",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().put(
                `/integrations/integrations`,
                arg
            );
            const { businessIdentifiers } = arg

            const getEcomArgument = _.get(_.find(businessIdentifiers, function (o) { return o.type === "VATID" }), 'id', businessIdentifiers[0].id)
            await thunkApi.dispatch(getEcomPoint(getEcomArgument))
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);




/** adding new company */
export const addCompany = createAsyncThunk(
    "company/addCompany",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/iam/company`,
                arg.body
            );

            arg.ecomBody.map((o, i) => {
                thunkApi.dispatch(addEcomPoint(o))
            })

            return response;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const deleteEcomPoint = createAsyncThunk(
    "company/deleteEcomPoint",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().delete(
                `/integrations/integrations/${arg.id}/businessid/${arg.businessid}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

/** deleting company record */
export const deleteCompany = createAsyncThunk(
    "company/deleteCompany",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().delete(
                `/iam/company/${arg}`
            );
            return response;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/** Action call for deleting company record */
export const getSelectedEcom = createAsyncThunk(
    "company/getSelectedEcom",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/integrations/integrations/product/${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/** editing the company user record */
export const editCompanyUser = createAsyncThunk(
    "company/editCompanyUser",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().patch(
                `/iam/company`,
                arg.body
            );
            return response;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const checkEcomIdGenerated = createAsyncThunk(
    "company/checkEcomIdGenerated",
    async (arg, thunkApi) => {
        try {
            let checkEcomIdGeneratedModel = {
                url: arg.ecommerceShopUrl,
                articleIdentifer: arg.ecomReqModel.articleIdentifer,
                articleIdentiferType: arg.ecomReqModel.articleIdentiferType
            }

            const response = await getAxios().post(
                `/integrations/integrations/product/check`,
                checkEcomIdGeneratedModel
            );

            if (response.data.ecomId) {
                thunkApi.dispatch(getProductByIdAction({ isLoader: true, gtin: arg.productId, isCancelBtn: true }))

                return { response: response.data };
            }
            else {
                thunkApi.dispatch(checkEcomIdGenerated(arg));
                return false;
            };
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);
