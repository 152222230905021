import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';
import axios from "axios";
import objectPath from "object-path";
import { localStorageSetWithExpiry, localStorageGetWithExpiry, } from 'src/helper/localStorageHelper';
import { getProduct_flatten_TabStructured_Schema, } from "src/helper/helpers";
import { ADD, EDIT, FOOD_BEWERAGE } from "src/helper/constants/constants";
import _ from "lodash";



/** Get all certifications data */
export const getAllCertificationsData = createAsyncThunk(
    "certifications/getAllCertificationsData",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `upids/metadata/certifications/all`
            );
            return response.data
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
)

/** Add new certifications data */
export const addNewCertificationsData = createAsyncThunk(
    "certifications/addNewCertificationsData",
    async (model, thunkApi) => {
        try {
            const response = await getAxios().post(
                `upids/metadata/certifications`,model
            );
            return response.data
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
)

/** Upload certificate Image URL */
export const uploadCertificateImageURL = createAsyncThunk(
    "certifications/uploadCertificateImageURL",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `upids/metadata/logo/upload?id=${arg.id}`,
                arg.body
              );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

/** Get certificate data by id action */
export const getCertificateDataByIdAction = createAsyncThunk(
    "certifications/getCertificateDataByIdAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `upids/metadata/certifications/${arg}`);
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

/** Delete certificate by id action */
export const deleteCertificateByIdAction = createAsyncThunk(
    "certifications/deleteCertificateByIdAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().delete(
                `upids/metadata/certifications?id=${arg}`);
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

/** Delete certificate logo image */
export const deleteCertificateLogoImageAction = createAsyncThunk(
    "certifications/deleteCertificateLogoImageAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().delete(
                `upids/metadata/logo/delete?id=${arg.id}&filename=${arg.fileName}`);
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

/** edit taxonomy items data */
export const editCertificatAction = createAsyncThunk(
    "certifications/editCertificatAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().patch(`upids/metadata/certifications?id=${arg?.body?.id}`,arg.body);
            return response.data
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
)









