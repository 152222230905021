import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MdArrowBack ,MdMenu} from "react-icons/md";
import logo from '../../../assets/images/brand_icons/upids-pdm-logo.png'

const CreateUpidsNavbar = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    function toggleOffcanvas() {
        document.querySelector(".sidebar-offcanvas").classList.toggle("active");
    }
    function toggleRightSidebar() {
        document.querySelector(".right-sidebar").classList.toggle("open");
    }

    return (
        <>
            <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <Link className="navbar-brand brand-logo" to="/dashboard">
                        <img src={logo} alt="logo" />
                    </Link>
                    <Link
                        className="navbar-brand brand-logo-mini d-inline-block d-lg-none"
                        to="/"
                    >
                        <img
                            src={require("../../../assets/images/logo-mini.svg")}
                            alt="logo"
                        />
                    </Link>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-center">
                    <span className="back-arrow-span">
                        <MdArrowBack
                            className="back-arrow"
                            onClick={() => navigate(-1)}
                        />
                        <Trans></Trans>
                    </span>
                    <span className="user-profile-text">{t("UPIDS Product")}</span>
                    {/* <span className="horizontal-line"></span>
                    <span className="full-user-name">{!!data && data.foreName !== undefined && data.sureName !== undefined && data.foreName !== "" && data.sureName !== "" ? data.foreName + " " + data.sureName : ''}</span> */}



                    <ul className="navbar-nav navbar-nav-right">
                    </ul>


                    <button
                        className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                        type="button"
                        onClick={toggleOffcanvas}
                    >
                         <MdMenu color="#000000" size={24} />
                    </button>
                </div>
            </nav>

        </>
    );
};

export default CreateUpidsNavbar;
