import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';




export const getVouchersAction = createAsyncThunk(
    "account/getVouchersAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/services/voucher/filter/all`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const createVouchersAction = createAsyncThunk(
    "account/createVouchersAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/services/voucher`,
                arg
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const deleteVouchersAction = createAsyncThunk(
    "account/deleteVouchersAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().delete(
                `/services/voucher/${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);
