import { createSlice } from "@reduxjs/toolkit";
import {
  getCompany, getCompanySearch, getParticularCompanySearch, getLiveCompanySearch, getCompanySearchList,
  getCompanyInfo, getCompanyDetails, editCompany, getEcomPoint, addEcomPoint, addCompany, deleteEcomPoint,
  deleteCompany, getSelectedEcom, editCompanyUser, checkEcomIdGenerated,
} from "./companyThunk";


const initialCompanyState = {
  actionsLoading: false,
  response: null,
  error: null,
  activeTabInfo: {
    index: 0,
    tabName: ""
  },

  companyList: null,
  isLoading: false,
  deleteResponse: null,
  isDeleteLoading: false,
  isCompanySearchLoading: false,
  editResponse: null,
  isAllDataFetchedLoading: false,
  formObject: {},
  isCompanyEditMode: false,
  currentCompanyObj: {},
  ErrorsformObject: [],
  ErrorsformEditObject: [],
  companyInfo: null,
  ecomList: [],
  selectedEcomList: [],
  ecomLoading: false,
  ecommerceIntegrationData: null,
  viewCompanyPageLoads: false,
  masterCompanyList :[]
};

export const companySlice = createSlice({
  name: "company",
  initialState: initialCompanyState,
  reducers: {
    // Catch error if any.
    catchError: (state, action) => {
      console.log("Slice error : ", action.payload)
      state.error = `${action.type}: ${action.payload}`;
      state.actionsLoading = false;
      state.isLoading = false;
    },
    //Starting of a call
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
      state.viewCompanyPageLoads = false;

    },
    //Start loading
    startLoading: (state, action) => {
      state.error = null;
      state.isLoading = true;
      state.editResponse = null;
    },

    startLoadingForAllRecords: (state, action) => {
      state.error = null;
      state.isAllDataFetchedLoading = true;
    },
    startLoadingForCompanySearch: (state, action) => {
      state.error = null;
      state.isCompanySearchLoading = true;
    },

    startLoadingForCompanySearchForCancelBtn: (state, action) => {
      state.error = null;
    },

    startLoadingForDelete: (state, action) => {
      state.error = null;
      state.isDeleteLoading = true;
      state.deleteResponse = null;
    },
    startLoadingForEcom: (state, action) => {
      state.ecomLoading = true;
    },
    stopLoadingForEcom: (state, action) => {
      state.ecomLoading = false;
    },

    //All company data fetched 
    getCompanyFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.isAllDataFetchedLoading = false;
      state.companyList = action.payload.data;
    },

    //Search by company name or business id data fetched
    getCompanySearchFetched: (state, action) => {
      state.actionsLoading = false;
      state.isCompanySearchLoading = false;
      state.error = null;
      state.companyList = action.payload.data;
      state.viewCompanyPageLoads = true;
    },
    getParticularCompanySearchFetched: (state, action) => {
      state.actionsLoading = false;
      state.isCompanySearchLoading = false;
      state.error = null;
      state.companyInfo = action.payload.data.length > 0 ? action.payload.data[0] : setDefaultSupportedLanguages();
      state.viewCompanyPageLoads = true;

    },
    getLiveCompanySearchFetched: (state, action) => {
      state.actionsLoading = false;
      state.isCompanySearchLoading = false;
      state.error = null;
      let data = filterCompanyData(action.payload.data);
      state.companyList = data;
    },

    getCompanySearchDataFetched: (state, action) => {
      state.actionsLoading = false;
      state.isCompanySearchLoading = false;
      state.error = null;
      state.companyList = [action.payload.data]
    },

    getCompanyInfoFetched: (state, action) => {
      state.actionsLoading = false;
      state.isLoading = false;
      state.error = null;
      state.companyInfo = action.payload.data;
      state.companyList = action.payload.data;
      state.isCompanySearchLoading = false;
    },
    //Edit company data fetched
    editCompanyFetched: (state, action) => {
      state.actionsLoading = false;
      state.isLoading = false;
      state.error = null;
      state.editResponse = action.payload.status;
    },

    //Add company data fetched
    addCompanyhFetched: (state, action) => {
      state.actionsLoading = false;
      state.isLoading = false;
      state.error = null;
      state.response = action.payload;
      state.formObject = {};

    },

    //Delete company data fetched
    deleteCompanyhFetched: (state, action) => {
      state.actionsLoading = false;
      state.isLoading = false;
      state.isDeleteLoading = false;
      state.error = null;
      state.deleteResponse = action.payload.status;
    },

    //Clear error if any.
    clearError: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    clearSearchResults: (state, action) => {
      state.actionsLoading = false;
      state.dashSearchData = null;
    },
    changeFormObject: (state, action) => {
      state.formObject = action.payload;
    },

    AllErrorsFormObject: (state, action) => {
      state.ErrorsformObject = action.payload;
    },

    AllErrorsEditFormObject: (state, action) => {
      state.ErrorsformEditObject = action.payload;
    },

    changeCompanyEditMode: (state, action) => {
      state.isCompanyEditMode = action.payload;
    },
    currentCompanyFormObject: (state, action) => {
      state.currentCompanyObj = action.payload;
    },
    getEcomFetched: (state, action) => {
      state.ecomList = action.payload.data;
    },
    getSelectedEcomFetched: (state, action) => {
      state.selectedEcomList = action.payload.data;
      state.actionsLoading = false;
    },
    setSelectedEcomFetched: (state, action) => {
      //state.selectedEcomList = action.payload.data;
    },
    deleteEcomIntegrationFromProductFetched: () => {

    },
    clearEcomAllData: (state, action) => {
      state.selectedEcomList = action.payload;
      state.ecomList = action.payload;
    },
    stopLoading: (state, action) => {
      state.isLoading = false
    },
    checkEcomIdGeneratedFetched: (state, action) => {
      state.checkEcomIdGenerated = action.payload.data;
    },
    ecommerceIntegrationData: (state, action) => {
      state.ecommerceIntegrationData = action.payload;
    },

    changeCompanyTabAction: (state, action) => {
      const { tabName } = action.payload
      state.activeTabInfo.tabName = tabName
    },
  },


  extraReducers:
    (builder) => {
      builder
        /** RETRIEVE INFO*/
        .addCase(getCompany.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isAllDataFetchedLoading = true;
        })
        .addCase(getCompany.fulfilled, (state, action) => {
          state.error = null;
          state.actionsLoading = false;
          state.isAllDataFetchedLoading = false;
          state.companyList = action.payload;
          state.masterCompanyList = action.payload;
        })
        .addCase(getCompany.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })


        .addCase(getCompanySearch.pending, (state, action) => {
          // state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;

          const arg = action.meta.arg

          if (arg && arg.isCancelBtn) {
            state.error = null;
          } else {
            state.error = null;
            state.isCompanySearchLoading = true;
          }
        })
        .addCase(getCompanySearch.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isCompanySearchLoading = false;
          state.error = null;
          state.companyList = action.payload;
          state.viewCompanyPageLoads = true;
        })
        .addCase(getCompanySearch.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })


        .addCase(getParticularCompanySearch.pending, (state, action) => {
          // state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;

          const arg = action.meta.arg

          if (arg && arg.isCancelBtn) {
            state.error = null;
          } else {
            state.error = null;
            state.isCompanySearchLoading = true;
          }
        })
        .addCase(getParticularCompanySearch.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isCompanySearchLoading = false;
          state.error = null;
          state.companyInfo = action.payload.length > 0 ? action.payload[0] : setDefaultSupportedLanguages();
          state.viewCompanyPageLoads = true;
        })
        .addCase(getParticularCompanySearch.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })



        .addCase(getLiveCompanySearch.pending, (state, action) => {
          // state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;

          const arg = action.meta.arg

          if (arg && arg.isCancelBtn) {
            state.error = null;
          } else {
            state.error = null;
            state.isCompanySearchLoading = true;
          }
        })
        .addCase(getLiveCompanySearch.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isCompanySearchLoading = false;
          state.error = null;
          let data = filterCompanyData(action.payload);
          state.companyList = data;
        })
        .addCase(getLiveCompanySearch.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })


        .addCase(getCompanySearchList.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
        })
        .addCase(getCompanySearchList.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isCompanySearchLoading = false;
          state.error = null;
          state.companyList = [action.payload]
        })
        .addCase(getCompanySearchList.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })


        .addCase(getCompanyInfo.pending, (state) => {

        })
        .addCase(getCompanyInfo.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = null;
          state.companyInfo = action.payload;
          state.companyList = action.payload;
          state.isCompanySearchLoading = false;
        })
        .addCase(getCompanyInfo.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })

        .addCase(getCompanyDetails.pending, (state, action) => {
          // state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isLoading = true;
          state.editResponse = null;

          const arg = action.meta.arg

          if (arg && arg.isCancelBtn) {
            state.error = null;
          } else {
            state.error = null;
            state.isCompanySearchLoading = true;
          }

        })
        .addCase(getCompanyDetails.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = null;
          state.companyInfo = action.payload;
          state.companyList = action.payload;
          state.isCompanySearchLoading = false;
        })
        .addCase(getCompanyDetails.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })


        .addCase(editCompany.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isLoading = true;
          state.editResponse = null;
        })
        .addCase(editCompany.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = null;
          state.editResponse = action.payload.status;
        })
        .addCase(editCompany.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })


        .addCase(getEcomPoint.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isLoading = true;
          state.editResponse = null;
        })
        .addCase(getEcomPoint.fulfilled, (state, action) => {
          state.ecomList = action.payload;
          state.isLoading = false;
          state.actionsLoading = false;
        })
        .addCase(getEcomPoint.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })


        .addCase(addEcomPoint.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isLoading = true;
          state.editResponse = null;
        })
        .addCase(addEcomPoint.fulfilled, (state, action) => {
          state.isLoading = false
        })
        .addCase(addEcomPoint.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })


        .addCase(addCompany.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isLoading = true;
          state.editResponse = null;
        })
        .addCase(addCompany.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = null;
          state.response = action.payload;
          state.formObject = {};
        })
        .addCase(addCompany.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })




        .addCase(deleteEcomPoint.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isLoading = true;
          state.editResponse = null;
        })
        .addCase(deleteEcomPoint.fulfilled, (state, action) => {
          state.isLoading = false
        })
        .addCase(deleteEcomPoint.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })



        .addCase(deleteCompany.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isDeleteLoading = true;
          state.deleteResponse = null;
        })
        .addCase(deleteCompany.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.isDeleteLoading = false;
          state.error = null;
          state.deleteResponse = action.payload.status;
        })
        .addCase(deleteCompany.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })


        .addCase(getSelectedEcom.pending, (state) => {

        })
        .addCase(getSelectedEcom.fulfilled, (state, action) => {
          state.selectedEcomList = action.payload;
          state.actionsLoading = false;
        })
        .addCase(getSelectedEcom.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })


        .addCase(editCompanyUser.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
          state.viewCompanyPageLoads = false;
          state.isLoading = true;
          state.editResponse = null;
        })
        .addCase(editCompanyUser.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = null;
          state.editResponse = action.payload.status;
        })
        .addCase(editCompanyUser.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })



        .addCase(checkEcomIdGenerated.pending, (state) => {

        })
        .addCase(checkEcomIdGenerated.fulfilled, (state, action) => {
          if (action.payload) {
            state.ecomLoading = false;
            state.checkEcomIdGenerated = action.payload.response;
          }
        })
        .addCase(checkEcomIdGenerated.rejected, (state, action) => {
          state.error = action.payload;
          state.actionsLoading = false;
          state.isLoading = false;
        })




    },
});

// live search api returns companies array...each company contains businessIdentifiers array but gets string instead of array 
const filterCompanyData = (companyData) => {
  let newData = []
  companyData.forEach((o) => {
    newData.push({ ...o, businessIdentifiers: JSON.parse(o.businessIdentifiers) })
  })
  return newData;
}

const setDefaultSupportedLanguages = () => {
  let supportedLanguages = {
    supportedLanguages: [
      { name: "English", code: "en" },
      { name: "Finnish", code: "fi" },
      { code: "sv", name: "Swedish" },
    ]
  }
  return supportedLanguages
}


export const {
  changeFormObject, AllErrorsFormObject, AllErrorsEditFormObject, changeCompanyEditMode,
  currentCompanyFormObject, clearEcomAllData, changeCompanyTabAction
} = companySlice.actions