import React, { useState, useEffect, useContext } from "react";
import { MdApps, MdViewHeadline } from 'react-icons/md';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeProductSearchText } from "src/redux/products/productsSlice";
import { AllErrorsEditFormObject, currentCompanyFormObject } from "src/redux/company/companySlice";
import { getCompanySearch } from "src/redux/company/companyThunk";
import { getManufacturerSpecificProducts, liveProductSearch } from "src/redux/products/productThunk";
import { useStateCallback } from "../../../helper/CustomHook";
import { saveFavourites, getFavourites, } from "src/redux/favourites/favouritesThunk";
import { addToList, removeFromList, updateTimestamp, } from "src/redux/favourites/favouritesSlice";
import { useNavigate, useParams } from "react-router-dom";
import { StSearchBox } from "src/components/StSearchBox"
import { useTranslation } from "react-i18next";
import { ProductContext } from "src/pages/products/ProductsContext";
import GridView from "src/pages/products/components/GridView";
import ProductTable from "src/pages/products/components/productsTable/ProductTable";
import objectPath from "object-path";
import { clearManufacturerProductsPerPage } from "src/redux/products/productsSlice";

const ManufacturerProducts = () => {
  const [PageCount, setPageCount] = useStateCallback(1);
  const [prodList, setProdList] = useState([]);
  const [showOnlyFavourites, setShowOnlyFavourites] = useStateCallback(false);
  const context = useContext(ProductContext);
  const _favouritesState = useSelector((state) => state.favourites, shallowEqual);
  const companyState = useSelector((state) => state.company, shallowEqual);
  const allViewData = companyState.companyList;
  const [favouritesList, setFavouritesList] = useState(_favouritesState.list && _favouritesState.list.length > 0 ? _favouritesState.list : []);
  const [lastModified, setLastModified] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const dispatch = useDispatch();

  let { businessid } = useParams();

  const _state = useSelector(
    (state) => (state.products),
    shallowEqual
  );
  const _companyState = useSelector((state) => (state.company), shallowEqual)

  useEffect(() => {
    dispatch(getFavourites());
    dispatch(getCompanySearch({ businessid: businessid }));

    return () => dispatch(changeProductSearchText(''))
  }, [])

  useEffect(() => {
    dispatch(currentCompanyFormObject(allViewData));
    dispatch(AllErrorsEditFormObject([]));
  }, [allViewData]);

  useEffect(() => {
    setFavouritesList(!!_favouritesState.list && _favouritesState.list.length > 0 ? _favouritesState.list : []);
  }, [_favouritesState.list]);

  const allBusinessId = objectPath.get(_companyState, `currentCompanyObj.0.businessIdentifiers`)

  useEffect(() => {
    setPageCount(1)
    if (allBusinessId) {
      getAllManufacturersProductList();
    }
    return () => {
      dispatch(clearManufacturerProductsPerPage())
    }
  }, [allBusinessId]);

  const getAllManufacturersProductList = async () => {
    await dispatch(getManufacturerSpecificProducts({
      businessId: JSON.stringify(allBusinessId),
      perpagecount: 20,
    }));
  }

  const getProducts = (_mode, _gtin, field) => {
    dispatch(getManufacturerSpecificProducts({
      businessId: JSON.stringify(allBusinessId),
      perpagecount: 20,
      mode: _mode,
      gtin: _gtin,
      field: field
    }));
  }


  const isLoading = _state.isAllDataFetchedLoading;

  useEffect(() => {
    setProdList(modifyProductList())
  }, [_state.productsPerPage]);

  useEffect(() => {
    setProdList(modifyProductList());
    if (lastModified > 0) {
      dispatch(saveFavourites({ body: favouritesList }));
    }
  }, [favouritesList]);

  const modifyProductList = () => {
    let tList = []
    if (_state.productsPerPage) {
      tList = _state.productsPerPage.slice();
      if (tList) {
        favouritesList.map((favourite, index) => {
          const tListIndex = tList.findIndex(function (x) {
            let identifier = !!x.gtin ? x.gtin : x._2an
            return identifier == favourite
          });
          tList[tListIndex] = { ...tList[tListIndex], isFavourite: true };
        });
      }
    }
    return tList;
  }

  const handleFavouriteClick = (gtin) => {
    const index = favouritesList.indexOf(gtin);

    if (index > -1) {
      setFavouritesList(favouritesList.filter(item => item !== gtin));
      dispatch(removeFromList(gtin));
    } else {
      setFavouritesList(favouritesList.concat(gtin));
      dispatch(addToList(gtin));
    }

    const timestamp = Math.floor(Date.now() / 1000);
    setLastModified(timestamp);
    dispatch(updateTimestamp(timestamp));
  };

  return (
    <div className="products_main-div">

      <div className="products_main-subdiv">
        <div className="products_subdiv">
          <div className="products_subdiv-2">

            <span className="products_view-span-text">{t("View")}</span>
            <MdApps className="products_grid-icon" onClick={() => context.setViewType('GRID')} color={context.viewType === 'GRID' ? '#6489A0' : '#A9C1CE'} size={24} />
            <span className="products_horizontal-line"></span>
            <MdViewHeadline className="products_grid-icon" onClick={() => context.setViewType('TABLE')} color={context.viewType === 'TABLE' ? '#6489A0' : '#A9C1CE'} size={24} />
          </div>
        </div>
        {context.viewType === 'GRID' ?
          <GridView getProducts={getProducts} productList={prodList} data={{ PageCount: PageCount, setPageCount: setPageCount }} searchText={_state.productSearchText} loading={isLoading} favouritesClickHandle={handleFavouriteClick} />
          :
          <ProductTable getProducts={getProducts} searchText={_state.productSearchText} productList={prodList} data={{ PageCount: PageCount, setPageCount: setPageCount }} loading={isLoading} showOnlyFavourites={showOnlyFavourites} favouritesClickHandle={handleFavouriteClick} />

        }

      </div>
    </div>
  );
};

export default ManufacturerProducts;
