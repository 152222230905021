import React, { useEffect, useState, useContext } from "react";
import { Dropdown } from "semantic-ui-react";
import ClickstreamTable from "./ClickstreamTable";
import ClickstreamScans from "./ClickstreamScans";
import ClickstreamEnvs from "./ClickstreamEnvs";
import { useTranslation } from "react-i18next";
import _ from 'lodash'
import GoogleMapComponents from "src/components/googleMapComponents";
import { useSelector, shallowEqual } from "react-redux";
import { AppContext } from 'src/pages/AppContext';



const Analytics = () => {
  const { t } = useTranslation('common');
  const context = useContext(AppContext);

  const analyticsState = useSelector((state) => state.analytics, shallowEqual);
  const { actionsLoading: isLoading, analyticsClickstreamEntries } = analyticsState
  const [selectedAnalyticsView, setSelectedAnalyticsView] = useState("all")
  const [geoLocations, setGeoLocations] = useState([])

  const analyticsViewOption = [
    { text: t("All Data"), value: "all" },
    { text: t("Mobile Data"), value: "mobile" },
    { text: t("Web Data"), value: "web" },
  ];

  useEffect(() => {
    setGeoLocations(getAllGeolocations(analyticsClickstreamEntries))
  }, [analyticsClickstreamEntries.entries])

  const getAllGeolocations = (data) => {
    let payload = [];
    if (data && data.entries[0] && data.entries.length !== 0) {
      data?.entries.forEach((ele) => {
        let locInfo = ele.geolocation ? geoLocationSeprater(ele.geolocation) : {};
        let geoLocation = {};

        let latAndLng = locInfo.loc ? locInfo.loc.split(",") : ["0.0000", "0.0000"];

        _.set(geoLocation, 'lat', Number(latAndLng[0]));
        _.set(geoLocation, 'lng', Number(latAndLng[1]));

        payload.push({
          text: ele.articleidentifier,
          ...geoLocation
        });
      });
    }
    return payload;
  };


  function geoLocationSeprater(detailsString) {
    const parts = detailsString.split('/');
    const obj = {};
    parts.forEach(part => {
      const [key, value] = part.split('=');
      obj[key] = value;
    });
    return obj;
  }

  const handleChangeAnalyticsView = (e, { name, value }) => {
    setSelectedAnalyticsView(value)
  }


  const defaultProps = {
    center: {
      lat: 18.5196,
      lng: 73.8554
    },
    zoom: 15
  };

  return (
    <>
      <div className="dashboard-main-div">
        <div className="subdiv-1">
          <span className="dashboard-span-text">{t("Analytics")}</span>
          <div className="mt-1 me-1" style={{ zIndex: 99, display: 'flex', alignItems: 'center' }}>
            <Dropdown
              className="analytics-dropdown"
              style={{
                marginTop: 10,
                width: 200,
                backgroundColor: "#F8FCFD",

              }}
              value={selectedAnalyticsView}
              name="analyticsView"
              onChange={handleChangeAnalyticsView}
              placeholder={t("Select Company")}
              search
              selection
              options={analyticsViewOption}
            />
          </div>
        </div>
        <div className="st-divider divider-thin"></div>
        <div className="ms-4">

          <div className="row mt-1">
            <div className="col-sm-12">
              <div className="p-3">
                <GoogleMapComponents
                  zoom={3}
                  mapType={"roadmap"}
                  height={"70vh"}
                  width={"81vw"}
                  googleMarkers={geoLocations}
                />
              </div>
              <ClickstreamScans selectedAnalyticsView={selectedAnalyticsView} />
            </div>
          </div>

          <div className="row mb-3" style={{ marginTop: 30 }}>
            <div className="col-sm-12">
              <ClickstreamTable selectedAnalyticsView={selectedAnalyticsView} />
            </div>
          </div>

          {context.authState.level == 10 &&
            <div className="row mb-4">
              <div className="col-sm-12">
                <ClickstreamEnvs />
              </div>
            </div>}

          {/* <div className="row">
          <div className="col-sm-12">
            <LineGraph />
          </div>
        </div> */}
          {/* <div className="row">
          <div className="col-sm-12">
            <PieGraph />
          </div>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default Analytics;
