import React, { useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux';
import JoditEditor from 'jodit-react';
import { updateCmsDataFormAction } from 'src/redux/contentManagement/contentManagementSlice';


function JoditEditotRichText({ isEditMode, joditEditorValue }) {

  const dispatch = useDispatch()
  
  const editor = useRef(null);


  const handleRichTextHtmlContent = (newContent) => {
    dispatch(updateCmsDataFormAction({
      name: "content",
      value: newContent
    }))
  }


  const configMemot = useMemo(() => (
    {
      readonly: !isEditMode, // all options from https://xdsoft.net/jodit/doc/,
      // placeholder: t('Type something'),
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      minHeight: 500,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      // // disablePlugins: "fullsize",
      // toolbarAdaptive: false,
      // buttons: [
      //     'bold', 'strikethrough', 'underline', 'italic', 'ul', 'ol', 'outdent', 'indent', 'font', 'fontsize',
      //     'brush', 'paragraph', 'table', 'source', 'align', 'undo', 'redo', 'hr', 'eraser', 'copyformat', 'print',
      //     // 'symbol', 'fullsize', 'about', 'image','video','link', 
      // ],
    }
  ), [isEditMode])





  return (
    <>
      <JoditEditor
        value={joditEditorValue}
        ref={editor}
        config={configMemot}
        onBlur={(newContent) => {
          handleRichTextHtmlContent(newContent)
        }}
      />

    </>
  )
}

export default JoditEditotRichText