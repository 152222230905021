import React, { forwardRef } from "react";
import { MdSort } from "react-icons/md";
import { MdEdit,MdOutlineQueuePlayNext,MdOutlineSubtitles } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {Spinner} from "src/components/Spinner";
import { MaterialTable } from "src/components/MaterialTable";
import { useTranslation } from "react-i18next";

import _ from "lodash";
import objectPath from "object-path";

function ManufacturersTable(props) {

  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const tableIcons = {
    SortArrow: forwardRef((props, ref) => <MdSort {...props} ref={ref} />),
  };

  const allData = [];

  const handleEditClickEvent = (o) => {
    let businessid = objectPath.get(_.find(o.businessIdentifiers, function(o){return o.type === "VATID"}), "id", o.businessIdentifiers[0].id)
    navigate(`/manufacturers/profile/${businessid}`,
      // state: { companyData: _.find(props.manufacturersList, function(obj) { return obj.businessId == o.businessId })}
    );
  };

  const showProductList=(o)=>{
    let businessid = objectPath.get(_.find(o.businessIdentifiers, function(o){return o.type === "VATID"}), "id", o.businessIdentifiers[0].id);
    navigate(`/manufacturer/${businessid}`);
  }

  const createTableData = () => {
    if (!!props.manufacturersList && props.manufacturersList.length > 0) {
      props.manufacturersList.map((o, index) => {
        allData.push({
          // businessId: o.businessIdentifiers && o.businessIdentifiers[0] && o.businessIdentifiers[0].id,
          businessId: objectPath.get(_.find(o.businessIdentifiers, function (t) { return t.type === "VATID" }), 'id', o.businessIdentifiers[0].id),
          manufacturerName: o.name,
          contact: o.contact,
          address: o.address,
          action: (
            <MdEdit key={index} style={{ cursor: "pointer" }} onClick={(e) => handleEditClickEvent(o)} />
          ),
          products: (
            <MdOutlineSubtitles key={index} style={{cursor:'pointer',fontSize:20}} onClick={()=> showProductList(o)}/>
          ),
        });
      });
    }
  };
  createTableData();

  return (
    <>
      {props.loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <MaterialTable
          columnNames={[
                { title: t("_Business Id"), field: "businessId" },
                { title: t("ManufacturerName"), field: "manufacturerName" },
                { title: t("ManufacturerContact"), field: "contact" },
                { title: t("ManufacturerAddress"), field: "address" },
                { title: t("Action"), field: "action", sorting: false, headerStyle: { textAlign: 'center' }, cellStyle: { textAlign: 'center' } },
                {title: t('Products'), field: "products",sorting: false, headerStyle: { textAlign: 'center' }, cellStyle: { textAlign: 'center' }},
              ]}
          tableData={allData}
        ></MaterialTable>
       
      )}
      <br></br>
    </>
  );
}

export default ManufacturersTable


