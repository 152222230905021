import { createSlice } from "@reduxjs/toolkit";
import { loginAction, resetPassword, newPassword, newToken, userRegistrationAction } from './authThunk';

const initialLoginState = {
  actionsLoading: false,
  response: null,
  error: null,
  passwordSent: false,
  newPassDone: false,
  refreshTokenDone: false,
  userRegistration: null,
  ErrorsformObject: [],

};

export const authSlice = createSlice({
  name: "login",
  initialState: initialLoginState,
  reducers: {
    clearAuthError: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.userRegistrationFailedError = false
    },
    allAuthErrorsFormObject: (state, action) => {
      state.ErrorsformObject = action.payload;
    },
  },


  extraReducers:
    (builder) => {
      builder
        /**  */
        .addCase(loginAction.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
        })
        .addCase(loginAction.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.response = action.payload;
        })
        .addCase(loginAction.rejected, (state, action) => {
          state.actionsLoading = false;
          state.error = action.payload;
        })


        .addCase(resetPassword.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
        })
        .addCase(resetPassword.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.passwordSent = true;
        })
        .addCase(resetPassword.rejected, (state, action) => {
          state.actionsLoading = false;
          state.error = action.payload;
        })


        .addCase(newPassword.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
        })
        .addCase(newPassword.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.newPassDone = true;
        })
        .addCase(newPassword.rejected, (state, action) => {
          state.actionsLoading = false;
          state.error = action.payload;
        })


        .addCase(newToken.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
        })
        .addCase(newToken.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.refreshTokenDone = true;
        })
        .addCase(newToken.rejected, (state, action) => {
          state.actionsLoading = false;
          state.error = action.payload;
        })


        .addCase(userRegistrationAction.pending, (state) => {
          state.error = null;
          state.actionsLoading = true;
        })
        .addCase(userRegistrationAction.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.userRegistration = action.payload;
          state.userRegistrationFailedError = false
        })
        .addCase(userRegistrationAction.rejected, (state, action) => {
          state.actionsLoading = false;
          state.userRegistrationFailedError = true
          state.error = action.payload;
        })


    },
});


export const { clearAuthError, allAuthErrorsFormObject } = authSlice.actions