import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { Tab } from 'semantic-ui-react'
import ManufacturerProfile from './components/ManufacturerProfile'
import Transactions from './components/Billing';
import PaymentModel from './components/PaymentModel';
import Warnings from './components/Warnings';
import PaymentTypeModel from './components/PaymentTypeModal';
import { changeCompanyTabAction } from 'src/redux/company/companySlice';

function CompanyInfo() {
    const { t } = useTranslation("common");

    const dispatch = useDispatch()

    const [companyTabIndex, setCompanyTabIndex] = useState(0)
    const panes = [
        {
            menuItem: t("Profile"),
            render: () => (
                <Tab.Pane className="product-profile tab-pane-no-shadow border-0 shadow-none">
                    <ManufacturerProfile />
                </Tab.Pane>
            )
        },
        {
            menuItem: t("Billing"),
            render: () => (
                <Tab.Pane className="product-profile tab-pane-no-shadow border-0 shadow-none">
                    <Transactions />
                </Tab.Pane>
            )
        },
        {
            menuItem: t("Warnings"),
            render: () => (
                <Tab.Pane className="product-profile tab-pane-no-shadow border-0 shadow-none">
                    <Warnings />
                </Tab.Pane>
            )
        },
    ]

    const handleTabChange = (e, data) => {
        setCompanyTabIndex(data.activeIndex)
        dispatch(changeCompanyTabAction({tabName: e.target.textContent, }))
    }

    return (
        <div>
            <Tab
                style={{ width: '100%' }}
                activeIndex={companyTabIndex}
                onTabChange={handleTabChange}
                className="product-profile tab-container multilang-tab"
                menu={{ secondary: true }}
                panes={panes} />
            <PaymentModel />
            <PaymentTypeModel/>
        </div>
    )
}

export default CompanyInfo