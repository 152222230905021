import React, { useEffect, useContext } from "react";
import { getBillingEstimation } from "src/redux/analytics/analyticsThunk";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {Spinner} from 'src/components/Spinner'
import { BillingEstimation } from "@upidspdm/billing-estimation";
import { AppContext } from "../AppContext";

const BillingTableEstimation = (props) => {
  const dispatch = useDispatch();
  let businessId;
  const context = useContext(AppContext);

  // Get current business id
  let businessIdObj = context.authState.businessIdentifiers.find((obj) => obj.type === "VATID");

  if (businessIdObj) {
    businessId = businessIdObj.id;
  } else {
    businessId = context.authState.businessIdentifiers[0].id;
  }

  // DEBUG
  // businessId = "FI28763341";
  // DEBUG

  // Load initial data
  const billingEstimationParams = "?businessid=" + businessId;
  useEffect(() => {
    dispatch(getBillingEstimation(billingEstimationParams));
  }, []);

  const _state = useSelector((state) => state.analytics, shallowEqual);

  const isLoading = _state.actionsLoading;

  return (
    <>
      {isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div><BillingEstimation data={_state.billingEstimation} /></div>
      )}
    </>
  );
};

export default BillingTableEstimation;
