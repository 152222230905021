import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from "react-bootstrap/Modal";
import { StCloseButton } from "src/components/StCloseButton";
import { Dropdown } from "semantic-ui-react";
import { LanguageCode } from 'src/helper/MetadataCollection';
import { StSquareButton } from 'src/components/StSquareButton';



function LanguageSelectInput(props) {

    const {show, handleClose, handleAddLang, supportedLanguages, } = props
    const { t } = useTranslation('common');

    const [state, setState] = useState({
        langList: []
    })

    const getLangDropDownData = () => {
        const languages = []
        LanguageCode.forEach((o, index) => {
            if (supportedLanguages && supportedLanguages[0] && supportedLanguages.find((item) => item.code === o.code)) {
                return;
            }
             languages.push({ key: index, text: o.name, value: o.code })
        })
        return languages
    }
    

    const handleMultiSelectValueChange = (e, { name, value }) => {
        const langList = [];
        value.forEach((o) => {
          langList.push(LanguageCode.find((lang) => lang.code === o));
        })
        setState({
            ...state,
            langList: langList,
        })
    };
    
    const handleAddLanguageOkButton = () => {
        let newSupportedLanguages = (supportedLanguages && supportedLanguages[0]) ? supportedLanguages : []
        newSupportedLanguages = [...newSupportedLanguages, ...state.langList]

        const model = {
            target: {
                name: "supportedLanguages",
                value: newSupportedLanguages
            }
        }
        
        handleAddLang(model)
        // setState({ langList: []})
    }

    return (
        <div>
            <Modal size="lg" centered={true} backdrop="static" show={show} onHide={handleClose}  className="languageSelectInput">
                <Modal.Header >
                    <div style={{ position: 'absolute', right: 10 }}>
                        <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ fontWeight: 'normal' }}>
                    <div className="row col-12 m-0 justify-content-center">
                        <span style={{ fontSize: 18, color: '#000000',fontWeight: 'bold' }}>{t("Choose languages for your products")}</span>
                        <Dropdown style={{ margin: 15, fontSize: 14 }} onChange={handleMultiSelectValueChange} selection multiple search fluid options={getLangDropDownData()} placeholder={t('Choose an Option')} />
                    </div>

                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <StSquareButton text={t("Add")} onClick={() => handleAddLanguageOkButton()}></StSquareButton>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default LanguageSelectInput