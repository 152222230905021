import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';
// import * as _ from 'lodash'



/**
 * Get versions list of product
 */
export const getVersionsList = createAsyncThunk(
    "versioning/getVersionsList",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/masterdata/versioning/list${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


/**
 * Get specific version of product
 */
export const getVersion = createAsyncThunk(
    "versioning/getVersion",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/masterdata/versioning/get${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



/**
 * Restore wanted version
 */
export const restoreVersion = createAsyncThunk(
    "versioning/restoreVersion",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().patch(
                `/upids/masterdata/versioning/restore${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



/**
 * Get specific version of product
 */
export const getCurrentVersion = createAsyncThunk(
    "versioning/getCurrentVersion",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/masterdata/products/gtin/${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);
