import React, { useEffect, useContext } from "react";
import { getBillingDaily } from "src/redux/analytics/analyticsThunk";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {Spinner} from 'src/components/Spinner'
import { BillingDaily } from "@upidspdm/billing-daily";
import { AppContext } from "../AppContext";

const BillingTableDaily = (props) => {
  const dispatch = useDispatch();
  let businessId;
  const context = useContext(AppContext);

  // Get current business id
  let businessIdObj = context.authState.businessIdentifiers.find((obj) => obj.type === "VATID");

  if (businessIdObj) {
    businessId = businessIdObj.id;
  } else {
    businessId = context.authState.businessIdentifiers[0].id;
  }

  // DEBUG
  // businessId = "FI28763341";
  // DEBUG

  // Load initial data
  const billingDailyParams = "?businessid=" + businessId;
  useEffect(() => {
    dispatch(getBillingDaily(billingDailyParams));
  }, []);

  const _state = useSelector((state) => state.analytics, shallowEqual);

  const isLoading = _state.actionsLoading;

  return (
    <>
      {isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div><BillingDaily data={_state.billingDaily} /></div>
      )}
    </>
  );
};

export default BillingTableDaily;
