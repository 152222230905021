import React, { useState, useEffect, useContext } from "react";
import { MdApps, MdViewHeadline } from 'react-icons/md';
import GridView from '../components/GridView';
import ProductTable from '../components/productsTable/ProductTable';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeProductSearchText, setFirstProductObj, setFirstProductObjFromProductList } from "src/redux/products/productsSlice";
import { getProductsWithPage, liveProductSearch } from "src/redux/products/productThunk";
import { useStateCallback } from "../../../helper/CustomHook";
import { ProductContext } from '../ProductsContext'
import { saveFavourites, getFavourites } from "src/redux/favourites/favouritesThunk";
import { addToList, removeFromList, updateTimestamp, } from "src/redux/favourites/favouritesSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { StSearchBox } from "src/components/StSearchBox"
import { useTranslation } from "react-i18next";

const Products = () => {
  /** redux store useDispatch & useSelector ------------------------------ **/
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const location = useLocation();
  const dispatch = useDispatch();
  const _state = useSelector((state) => (state.products), shallowEqual);
  const _favouritesState = useSelector((state) => state.favourites, shallowEqual);
  const context = useContext(ProductContext);

  /** hooks useState ----------------------------------------------------- **/
  const [PageCount, setPageCount] = useStateCallback(1);
  const [showOnlyFavourites, setShowOnlyFavourites] = useStateCallback(false);
  const [searchText, setSearchText] = useState("");
  const [showViewAll, setShowViewAll] = useState(false);
  const [prodList, setProdList] = useState([]);
  const [favouritesList, setFavouritesList] = useState(_favouritesState.list && _favouritesState.list.length > 0 ? _favouritesState.list : []);
  const [lastModified, setLastModified] = useState(0);

  /** Variable declaration ----------------------------------------------------- **/
  const isLoading = _state.isAllDataFetchedLoading;

  /** hooks useEffect----------------------------------------------------- **/
  useEffect(() => {
    dispatch(getFavourites());
  }, []);

  useEffect(() => {
    setFavouritesList(!!_favouritesState.list && _favouritesState.list.length > 0 ? _favouritesState.list : []);
  }, [_favouritesState.list]);

  useEffect(() => {
    setSearchText(_state.productSearchText);
  }, [_state.productSearchText])

  useEffect(() => {
    if (_state.productSearchText === "" && Object.keys(_state?.firstProductObj)?.length <= 0
    ) {
      setPageCount(1)
      dispatch(getProductsWithPage({}));
    }
    if (_state.productSearchText == "" && Object.keys(_state?.firstProductObj)?.length > 0) {
      setPageCount(_state?.firstProductObj?.PageCount)
      dispatch(getProductsWithPage({ mode: "next", gtin: _state?.firstProductObj?.gtin ? _state?.firstProductObj?.gtin : _state?.firstProductObj?._2an }));
    }
    if (_state.productSearchText.length > 0) {
      const timeOut = setTimeout(() => {
        clearTimeout(timeOut)
        dispatch(liveProductSearch({ searchText: _state.productSearchText, pageNo: 1 }));
        setShowViewAll(true)
      }, 1000)
      return async () => {
        clearTimeout(timeOut)
      }
    }
    return () => {
      dispatch(setFirstProductObjFromProductList({}))
    }
  }, [_state.productSearchText]);




  useEffect(() => {

    setProdList(modifyProductList())
  }, [_state.productsPerPage]);

  useEffect(() => {
    setProdList(modifyProductList());
    if (lastModified > 0) {
      dispatch(saveFavourites({ body: favouritesList }));
    }
  }, [favouritesList]);

  /** handler functions--------------------------------------------------- **/
  const getProducts = (_mode, _gtin) => {
    dispatch(getProductsWithPage({ mode: _mode, gtin: _gtin }));
  }

  const modifyProductList = () => {
    let tList = []
    if (_state.productSearchText !== "") {

      _state.productsPerPage.map((o, index) => {
        const favouriteIndex = favouritesList.indexOf(!!o.gtin ? o.gtin : o._2an)
        tList.push({
          productName: { en: o.name_en, fi: o.name_fi, sv: o.name_sv },
          gtin: o.gtin,
          _2an: o._2an,
          isFavourite: favouriteIndex > -1 ? true : false,
          media: { images: { '1024': [`${o.media}`] } }
        })
      })
    } else {
      if (_state.productsPerPage) {
        tList = _state.productsPerPage.slice();
        if (tList) {
          favouritesList.map((favourite, index) => {
            // const tListIndex = tList.findIndex(x => x.gtin === favourite);
            const tListIndex = tList.findIndex(function (x) {
              let identifier = !!x.gtin ? x.gtin : x._2an
              return identifier == favourite
            });
            tList[tListIndex] = { ...tList[tListIndex], isFavourite: true };
          });
        }
      }
    }
    return tList;
  }

  const handleFavouriteClick = (gtin) => {
    const index = favouritesList.indexOf(gtin);

    if (index > -1) {
      setFavouritesList(favouritesList.filter(item => item !== gtin));
      dispatch(removeFromList(gtin));
    } else {
      setFavouritesList(favouritesList.concat(gtin));
      dispatch(addToList(gtin));
    }

    const timestamp = Math.floor(Date.now() / 1000);
    setLastModified(timestamp);
    dispatch(updateTimestamp(timestamp));
  };

  return (
    <div className="products_main-div">

      <div className="row col-12 products_search-input-div">
        <div className="mt-3 col-lg-6 col-md-6">
          <StSearchBox
            iconName={_state.productSearchText === "" ? t("Search") : t("Close")}
            width={'100%'}
            placeholder={t("Search products by GTIN or Product Names")}
            value={_state.productSearchText}
            onChange={(e) => { setSearchText(e.target.value); dispatch(changeProductSearchText(e.target.value)) }}
            onClick={() => dispatch(changeProductSearchText(""))}
          />
        </div>
      </div>

      <div style={{ marginTop: 76, position: 'fixed' }} className='st-divider divider-thin'></div>
      <div className="products_main-subdiv">
        <div className="products_subdiv">
          <div className="products_subdiv-2">

            <span className="products_view-span-text">{t("View")}</span>
            <MdApps className="products_grid-icon" onClick={() => context.setViewType('GRID')} color={context.viewType === 'GRID' ? '#6489A0' : '#A9C1CE'} size={24} />
            <span className="products_horizontal-line"></span>
            <MdViewHeadline className="products_grid-icon" onClick={() => context.setViewType('TABLE')} color={context.viewType === 'TABLE' ? '#6489A0' : '#A9C1CE'} size={24} />
          </div>
        </div>
        {context.viewType === 'GRID' ?
          <GridView getProducts={getProducts} productList={prodList} data={{ PageCount: PageCount, setPageCount: setPageCount }} searchText={_state.productSearchText} loading={isLoading} favouritesClickHandle={handleFavouriteClick} />
          :
          <ProductTable getProducts={getProducts} searchText={_state.productSearchText} productList={prodList} data={{ PageCount: PageCount, setPageCount: setPageCount }} loading={isLoading} showOnlyFavourites={showOnlyFavourites} favouritesClickHandle={handleFavouriteClick} />

        }

      </div>

    </div>
  );
};

export default Products;
