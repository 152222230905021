import React, { useState } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import { Modal, } from "react-bootstrap";
import { StCloseButton } from 'src/components/StCloseButton';
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel";
import { InputSelectDropdown } from "src/components/inputComponents/InputSelectDropdown";
import { InputField } from "src/components/inputComponents/InputField";
import { StSquareButton } from 'src/components/StSquareButton';
import { TextWithLink } from 'src/components/TextWithLink';
import { Badges } from 'src/components/Badges'
import LanguageSelectInput from '../component/LanguageSelectInput';


const SignupModal = (props) => {
  const {
    showSignupModal, userRegistrationData, handleSignupModalClose, handleFormChange, errorObject,
    selectTimezoneOption, handleTimezoneSelect, handleIsAgreeTerms,
    handleTermsButtonClick, errorMsg, handleUserRegistration,touchedState
  } = props
  const { t } = useTranslation('common');
  const _state = useSelector((state) => state.login, shallowEqual);

  const { supportedLanguages } = userRegistrationData


  const [addSuportedLanguages, setAddSupportedLanguages] = useState({
    isShowModal: false
  })


  const handleDeleteLanguage = (index) => {
    const newList = [...supportedLanguages];
    newList.splice(index, 1)
    // setSupportedLanguages(newList)

    const model = {
      target: {
        name: "supportedLanguages",
        value: newList
      }
    }

    handleFormChange(model)
  }

  const handleAddLanguage = (model) => {
    handleFormChange(model)
    setAddSupportedLanguages({
      isShowModal: false
    })

  }

  const showAddSupportedLanguageModal = (showValue) => {
    setAddSupportedLanguages({
      ...addSuportedLanguages,
      isShowModal: showValue
    })
  }

  return (
    <>
      <Modal size="lg" centered={true} backdrop="static" show={showSignupModal} onHide={() => handleSignupModalClose()} >
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleSignupModalClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal' }}>
          <div className="form-group">
            <p className="col-12 media-card model-text">
              {t("Account Registration")}
            </p>
            <form>
              <div className="row">
                <InputTextWithLabel
                  label={''}
                  name={'foreName'}
                  value={userRegistrationData?.foreName}
                  type="text"
                  placeholder={t("First Name")}
                  onChange={handleFormChange}
                  errorMessage={!!errorObject && errorObject.foreName ? errorObject.foreName : ""}
                  touched={touchedState}
                />

                <InputTextWithLabel
                  label={''}
                  name={'sureName'}
                  value={userRegistrationData.sureName}
                  type="text"
                  placeholder={t("Last Name")}
                  onChange={handleFormChange}
                  errorMessage={!!errorObject && errorObject.sureName ? errorObject.sureName : ""}
                  touched={touchedState}

                />

                <InputTextWithLabel
                  label={''}
                  name={'companyName'}
                  value={userRegistrationData.companyName}
                  type="text"
                  placeholder={t("Company Name")}
                  onChange={handleFormChange}
                  errorMessage={!!errorObject && errorObject.companyName ? errorObject.companyName : ""}
                  touched={touchedState}

                />

                <InputTextWithLabel
                  label={''}
                  name={'businessId'}
                  value={userRegistrationData.businessId}
                  type="text"
                  placeholder={t("Company Id")}
                  onChange={handleFormChange}
                  errorMessage={!!errorObject && errorObject.businessId ? errorObject.businessId : ""}
                  touched={touchedState}

                />

                <InputTextWithLabel
                  label={''}
                  name={'phoneNumber'}
                  value={userRegistrationData.phoneNumber}
                  type="number"
                  placeholder={t("Telephone")}
                  onChange={handleFormChange}
                  errorMessage={!!errorObject && errorObject.phoneNumber ? errorObject.phoneNumber : ""}
                  touched={touchedState}

                />

                <InputSelectDropdown
                  label={""}
                  className="signup-timezone"
                  name="timezone"
                  placeholder={t("Select Timezone")}
                  value={userRegistrationData.abbr}
                  search={true}
                  options={selectTimezoneOption}
                  onChange={handleTimezoneSelect}
                  errorMessage={!!errorObject && errorObject.timezone ? errorObject.timezone : ""}
                  touched={touchedState}
                />

                <InputTextWithLabel
                  label={''}
                  name={'email'}
                  value={userRegistrationData.email}
                  type="text"
                  placeholder={t("Email")}
                  onChange={handleFormChange}
                  errorMessage={!!errorObject && errorObject.email ? errorObject.email : ""}
                  touched={touchedState}

                />

              </div>

              <div className="row" >
                <InputField
                  input="password"
                  name="password"
                  placeholder={t("Password")}
                  type="password"
                  value={userRegistrationData.password}
                  onChange={handleFormChange}
                  errorMsg={!!errorObject && errorObject.password ? errorObject.password : ""}
                />
                <InputField 
                  input="password"
                  name="confirmPassword"
                  placeholder={t("Confirm Password")}
                  value={userRegistrationData.confirmPassword}
                  onChange={handleFormChange}
                  errorMsg={!!errorObject && errorObject.confirmPassword ? errorObject.confirmPassword : ""}
                />
                {/* {showConfirmPassError && <span style={{color:"#ff6060",marginBottom:10}}>{t("Please make sure your password match")}</span>} */}
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                <label>
                  {t("Choose languages for your products")}
                </label>
                {supportedLanguages &&
                  supportedLanguages.map((o, index) =>
                  (
                    <Badges key={index} remove={true} onClick={() => handleDeleteLanguage(index)}>
                      {o.name}
                    </Badges>
                  )
                  )}

                <Badges add onClick={() => showAddSupportedLanguageModal(true)}>
                  {t("Add Language")}
                </Badges>

                {
                  addSuportedLanguages.isShowModal &&
                  <LanguageSelectInput
                    supportedLanguages={supportedLanguages}
                    show={addSuportedLanguages.isShowModal}
                    handleClose={() => showAddSupportedLanguageModal(false)}
                    handleAddLang={handleAddLanguage}
                  />
                }
              </div>

              <p className="content-subtitle text-center">
                {t("Registration will be reviewed by UPIDS admin team")}
              </p>
              <div className="d-flex justify-content-center">
                <input type={'checkbox'} name='isAgreeTerms' onChange={handleIsAgreeTerms} style={{ height: 20, width: 30 }}></input>
                <span className="content-subtitle text-center">
                  {t('I agree to terms of use')}
                </span>
                <span className='ms-2' style={{ cursor: 'pointer',marginTop:1 }}>
                  <TextWithLink onClick={() => handleTermsButtonClick()} text={t('General terms of use')} color="primary" />
                </span>
              </div>
              {/* <div className="d-flex justify-content-center text-decoration-underline" style={{fontSize:13,cursor:'pointer'}}>
            <TextWithLink onClick={() => handleTermsButtonClick()} text={t('General terms of use')} color="primary" />
        </div> */}

            </form>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <div>
            {_state.userRegistrationFailedError && <p style={{ color: '#ff6060' }}>{errorMsg}</p>}
            <StSquareButton text={t("Register")} loading={_state.actionsLoading} onClick={() => handleUserRegistration()}></StSquareButton>
          </div>

        </Modal.Footer>
      </Modal>


    </>
  )
}

export default SignupModal