import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  AllErrorsEditFormObject,
  changeCompanyEditMode,
  currentCompanyFormObject,
  clearEcomAllData,
} from "src/redux/company/companySlice";
import {
  getCompanySearch,
  addEcomPoint,
  getEcomPoint,
  deleteEcomPoint,
} from "src/redux/company/companyThunk";
import * as companyActions from "../../../redux/company/companyActions";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ManufacturerSideBar from "../Components/ManufacturerSideBar";
import { MdAddCircleOutline, MdClose } from "react-icons/md";
import { IoAddCircleOutline } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Dropdown } from "semantic-ui-react";
import countryCodes from "src/helper/countryCodes";
import { EULanguageCodes, LanguageCode } from "src/helper/MetadataCollection";
import { InputSwitchWithLable } from "src/components/inputComponents/InputSwitchWithLable";
import { Spinner } from "src/components/Spinner";
import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from "src/components/StSquareButton";
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel";
import { IconButton } from "src/components/IconButton";
import { StCancelSquareButton } from "src/components/StCancelSquareButton";
import { InputSelectDropdown } from "src/components/inputComponents/InputSelectDropdown";
import { Badges } from "src/components/Badges";
import { UrlCard } from "src/components/UrlCard";
import { BusinessIdCard } from "src/components/BusinessIdCard";
import _ from "lodash";
import objectPath from "object-path";
import { useStateCallback } from "../../../helper/CustomHook";
import * as yup from "yup";
import { companySchema } from "src/helper/yup-schema";



const ecommerceIntegrationTypes = [
  { key: 1, text: "woocommerce", value: "woocommerce" },
  { key: 2, text: "shopify", value: "shopify" },
  { key: 3, text: "amazon", value: "amazon" },
  { key: 4, text: "ecwid", value: "ecwid" },
  { key: 5, text: "magento", value: "magento" },
];

const amazonMarketPlaceRegions = [{ key: 1, text: "USA", value: "USA" }];

const billedForOptions = [
  { key: 1, text: "Events", value: "events" },
  { key: 2, text: "Products", value: "products" },
];

const billingTypeOptions = [
  { key: 1, text: "Post-charge", value: "post-charge" },
  { key: 2, text: "Prepaid", value: "prepaid" },
];

const regionsOptions = [
  { key: 1, text: "EU", value: "eu" },
  { key: 2, text: "Non-EU", value: "non-eu" },
];

const ViewCompany = () => {
  const { t } = useTranslation("common");
  const [businessIdModelShow, setBusinessIdModelShow] = useState(false);
  const [ecomModelShow, setEcomModelShow] = useState(false);
  const [show, setShow] = useState(false);
  const [recipientEmailsShow, setRecipientEmailsShow] = useState(false);
  const [recipientEmail, setRecipientEmail] = useState();
  const [modelList, setModelList] = useState([]);
  const [showApikey, setShowApikey] = useState(false);
  const [datapoolModalShow, setDatapoolModalShow] = useState(false);
  const [datapoolId, setDatapoolId] = useState();
  const [businessIdInputWarrning, setBusinessIdInputWarrning] = useState();
  const [disableButtonHandler, setDisableButtonHandler] = useState(true);
  const [warnningHandler, setWarningHandler] = useState(false);
  const [AddAllEULangChecked, setAddAllEULangChecked] = useStateCallback(false);
  const [hasEffectExecuted, setHasEffectExecuted] = useState(false);
  const [touchedState, setTouchedState] = useState(true)


  // const [businessInputIdDisable, setBusinessInputIdDisable] = useState(true);

  let selectCountryCodeOption = [];
  const [state, setState] = useState({
    businessIdInputValue: "",
    businessIdTypeValue: "",
    integrationType: "woocommerce",
  });
  const [ecomList, setEcomList] = useState([]);
  const [ecomState, setEcomState] = useState({
    ecomUrl: "",
    ecomConsumerKey: "",
    ecomConsumerSecret: "",
    storeName: "",
    apiKey: "",
    apiSecretKey: "",
    accessToken: "",
    url: "https://www.amazon.com",
    magentoUrl: "",
    sellerId: "",
    marketPlaceRegion: "",
    marketPlaceId: "",
    ecwidUrl: "https://app.ecwid.com",
    secretToken: "",
    storeId: "",
    consumerKey: "",
    consumerSecret: "",
    accessTokenSecret: ""
  });

  let { businessid } = useParams();
  const dispatch = useDispatch();
  const _state = useSelector((state) => state.company, shallowEqual);
  const allViewData = _state.companyList;
  let viewData = JSON.parse(JSON.stringify(_state.currentCompanyObj));

  let AllErrors = _state.ErrorsformEditObject;
  const isCompanySearchLoading = _state.isCompanySearchLoading;

  useEffect(() => {
    getCompanySearchById()
    dispatch(getEcomPoint(businessid));

    return () => {
      dispatch(changeCompanyEditMode(false));
      dispatch(currentCompanyFormObject({}));
      dispatch(clearEcomAllData([]));
      setEcomList([]);
    };
  }, []);

  const getCompanySearchById = async () => {
    try {
      const result = await dispatch(getCompanySearch({ businessid: businessid })).unwrap();
      let languages = result && result[0].supportedLanguages;
      checkAllEULangState(languages)
      if (Array.isArray(languages)) {
        setModelList([...languages]);
      } else {
        console.log('Languages is not an array:', languages);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    dispatch(currentCompanyFormObject(allViewData));
    dispatch(AllErrorsEditFormObject([]));
  }, [allViewData]);

  useEffect(() => {
    if (!!_state.ecomList && _state.ecomList.length > 0) {
      // let allEcomPoints = ecomList.length > 0 ? JSON.parse(JSON.stringify(ecomList)) : [];
      let allEcomPoints = [];
      _state.ecomList.map((o, index) => {
        if (o.integrationType == "woocommerce") {
          allEcomPoints.push({
            ecomUrl: o.url,
            ecomConsumerKey: o.consumerKey,
            ecomConsumerSecret: o.consumerSecret,
            integrationType: o.integrationType,
            id: o.id,
          });
        } else if (o.integrationType == "shopify") {
          allEcomPoints.push({
            storeName: o.storeName,
            apiKey: o.apiKey,
            apiSecretKey: o.apiSecretKey,
            hostUrl: o.hostUrl,
            accessToken: o.accessToken,
            integrationType: o.integrationType,
            id: o.id,
          });
        } else if (o.integrationType == "amazon") {
          allEcomPoints.push({
            url: o.url,
            sellerid: o.sellerid,
            marketplaceids: [
              { region: o.marketplaceids.region, id: o.marketplaceids.id },
            ],
            integrationType: o.integrationType,
            id: o.id,
          });
        } else if (o.integrationType == "ecwid") {
          allEcomPoints.push({
            ecwidUrl: o.url,
            storeId: o.storeId,
            secretToken: o.secret_token,
            integrationType: o.integrationType,
            id: o.id,
          });
        } else if (o.integrationType == "magento") {
          allEcomPoints.push({
            url: o.url,
            access_token: o.access_token,
            access_token_secret: o.access_token_secret,
            consumer_key: o.consumer_key,
            consumer_secret: o.consumer_secret,
            integrationType: o.integrationType,
            id: o.id,
          });
        }
        else {
        }
      });
      setEcomList(allEcomPoints);
    }
  }, [_state.ecomList]);

  const isVatidAlreadyExists =
    viewData &&
      viewData[0] &&
      viewData[0].businessIdentifiers &&
      viewData[0].businessIdentifiers.find(({ type }) => type === "VATID")
      ? true
      : false;

  const businessIdTypeOptions = [
    {
      key: 1,
      text: "GLN",
      value: "GLN",
    },
    {
      key: 2,
      text: "VATID",
      value: "VATID",
      disabled: isVatidAlreadyExists,
    },
    {
      key: 3,
      text: "CFPID",
      value: "CFPID",
    },
  ];

  const toggleChecked = () => {
    setAddAllEULangChecked(!AddAllEULangChecked, (checked) => {
      if (checked) {
        let _list = [];
        const existingCodes = modelList.map(item => item.code);
        const uniqueArray = _.uniq(_.concat(existingCodes, EULanguageCodes));
        uniqueArray.forEach((o) => {
          _list.push(LanguageCode.find((lang) => lang.code === o));
        })
        checkAllEULangState(_list)
        setModelList(_list)
      }
      else {
        const filteredArray = modelList.filter(obj => !EULanguageCodes.includes(obj.code));
        checkAllEULangState(filteredArray)
        setModelList([...filteredArray])
      }
    });
  };

  const checkAllEULangState = (list) => {
    const isAllCodesPresent = EULanguageCodes?.every(codeToCheck =>
      list?.some(lang => lang.code === codeToCheck)
    );
    if (isAllCodesPresent) {
      setAddAllEULangChecked(true)
    } else {
      setAddAllEULangChecked(false)
    }
  }

  const createDropdownDataForCountryCode = (countryCodes) => {
    selectCountryCodeOption = [];
    countryCodes.map((o, index) => {
      let allCodes = {
        key: index,
        text: `(${o.countryCode})  ${o.name}`,
        value: o.countryCode,
        content: (
          <div key={index}>
            <span
              style={{ display: "block", fontWeight: "normal", fontSize: 14 }}
            >
              {o.name}
            </span>
            <span
              style={{
                display: "block",
                fontWeight: "normal",
                fontSize: 12,
                marginTop: 4,
                color: "#6489A0",
              }}
            >
              {o.countryCode}
            </span>
          </div>
        ),
      };
      selectCountryCodeOption.push(allCodes);
    });
  };
  createDropdownDataForCountryCode(countryCodes);

  const handleFormChange = (e) => {
    let latestViewData = viewData;
    if (["name", "address", "contact", "countryCode", "contentItemUrl", "webhookUrl", "webhookKey", "invoicingEmail"].includes(e.target.name)) {
      validator({ name: e.target.name, value: e.target.value });
    }
    if (e.target.name === "priceTable") {
      latestViewData[0][e.target.name] = { ...latestViewData[0].priceTable, default: e.target.value }
    } else {
      latestViewData[0][e.target.name] = e.target.value;
    }
    dispatch(
      currentCompanyFormObject({
        ...latestViewData,
      })
    );
    setTouchedState(true)
  };

  const validator = ({ name, value }) => {
    const weburlRegEx = /(https:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/

    const existingErrorIndex = AllErrors.findIndex(
      (error) => error.path === name
    );
    let newErrors = [...AllErrors];
    if (name === "contentItemUrl" || name === "webhookUrl") {
      if (!value && viewData[0]?.privacyAddendum?.target?.value) {
        newErrors.push({ path: name, message: "Url is required" });
      }
      else if (value && value !== "" && !weburlRegEx.test(value)) {
        newErrors.push({ path: name, message: "Url should be valid" });
      }
      else if (value && value !== "" && !value.includes("https://")) {
        newErrors.push({ path: name, message: "URL should be secured" });
      }
      else {
        newErrors = AllErrors.filter((error) => error.path !== name);
      }
      dispatch(AllErrorsEditFormObject(newErrors));
    } else if (name == "webhookKey") {
      if (!value && viewData[0]?.privacyAddendum?.target?.value) {
        newErrors.push({ path: name, message: "Webhook key is required" });
      }
      else {
        newErrors = AllErrors.filter((error) => error.path !== name);
      }
      dispatch(AllErrorsEditFormObject(newErrors));
    }
    else {
      yup
        .reach(companySchema, name)
        .validate(value)
        .then((valid) => {
          if (newErrors.length > 0) {
            newErrors = newErrors.filter((error) => error.path !== name);
            dispatch(AllErrorsEditFormObject(newErrors));
          }
        })
        .catch((err) => {
          if (existingErrorIndex !== -1) {
            newErrors[existingErrorIndex] = {
              path: name,
              message: err.message,
            };
          } else {
            newErrors.push({ path: name, message: err.message });
          }
          dispatch(AllErrorsEditFormObject(newErrors));
        });
    }
  };

  const handlePrivacyAddendumChange = (checked) => {
    let latestViewData = viewData;
    latestViewData[0].privacyAddendum = checked;
    dispatch(currentCompanyFormObject({ ...latestViewData, flagForPolicy: checked?.target?.value }));
  };

  let errorObject = {};

  const errorShown = (AllErrors) => {
    AllErrors &&
      AllErrors[0] &&
      AllErrors.map((obj, index) => {
        errorObject[obj.path] = obj.message;
      });
  };

  errorShown(AllErrors);

  const handleBusinessIdModelClose = () => {
    setBusinessIdModelShow(false);
    setBusinessIdInputWarrning();
    setState({ ...state, businessIdInputValue: "", businessIdTypeValue: "" });
  };

  const handleEcomModelClose = () => {
    setEcomModelShow(false);
    setEcomState({
      ...ecomState,
      ecomUrl: "",
      ecomConsumerKey: "",
      ecomConsumerSecret: "",
      storeName: "",
      apiKey: "",
      apiSecretKey: "",
      accessToken: "",
      sellerId: "",
      marketPlaceRegion: "",
      marketPlaceId: "",
    });
  };

  const handleDropdownChange = (e, { value }) => {
    setBusinessIdInputWarrning("");
    setDisableButtonHandler(true);
    setWarningHandler(false);
    setState({ ...state, businessIdTypeValue: value, businessIdInputValue: "" });
    // state.businessIdTypeValue?setBusinessInputIdDisable(false): setBusinessInputIdDisable(true);
  };

  const handleEcomIntegrationTypeChange = (e, { value }) => {
    setState({ ...state, integrationType: value });
  };

  function checkSpecialCharactor(input) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(input) ? true : false;
  }

  function businessIdValidation(input) {
    if (state.businessIdTypeValue === 'VATID') {
      if (input.length >= 2) {
        if (isNaN(input.charAt(0)) && isNaN(input.charAt(1))) {
          setBusinessIdInputWarrning();
          let checkSpecialCharFun = checkSpecialCharactor(input);
          if (checkSpecialCharFun) {
            setBusinessIdInputWarrning(t("Special Charactor not allowed"));
            setDisableButtonHandler(true);
            setWarningHandler(true);
          } else {
            setDisableButtonHandler(false);
          }
        } else {
          setDisableButtonHandler(true);
          setWarningHandler(true);
          setBusinessIdInputWarrning(
            t("First two charactor should be Alphabet")
          );
        }
      } else {
        setDisableButtonHandler(true);
        setWarningHandler(false);
        setBusinessIdInputWarrning(t("Should more than 2 charactors"));
      }
    } else if (state.businessIdTypeValue === 'GLN') {
      if (input.length <= 0) {
        setBusinessIdInputWarrning();
        setDisableButtonHandler(true);
        setWarningHandler(true);
      } else {
        if (!Number(input)) {
          setBusinessIdInputWarrning(t("should be Number and special Charactor not allowed"));
          setDisableButtonHandler(true);
          setWarningHandler(true);
        } else {
          setBusinessIdInputWarrning();
          setDisableButtonHandler(false);
          setWarningHandler(false);
        }

      }
    } else if (state.businessIdTypeValue === 'CFPID') {
      if (input.length > 0) {
        setDisableButtonHandler(false);
        setWarningHandler(false);
      } else {
        setDisableButtonHandler(true);
        setWarningHandler(true);
      }
    }
  }

  const handleBusinessIdChange = (e) => {
    businessIdValidation(e.target.value);
    // trim() to remove white spaces from string
    setState({ ...state, businessIdInputValue: e.target.value.trim() });
  };
  const handleEcomChange = (e, data) => {
    // trim() to remove white spaces from string
    if (!!data) {
      setEcomState({ ...ecomState, [data.name]: data.value.trim() });
    } else {
      setEcomState({ ...ecomState, [e.target.name]: e.target.value.trim() });
    }
  };

  const langArray = modelList?.map(item => item.code)

  const handleAddBusinessId = () => {
    viewData[0].businessIdentifiers.push({
      type: state.businessIdTypeValue,
      id: state.businessIdInputValue,
    });
    dispatch(
      currentCompanyFormObject({
        ...viewData,
      })
    );
    AllErrors = _.filter((_state.ErrorsformObject) || [], (d) => (d?.path !== "businessIdentifiers")) || []
    dispatch(AllErrorsEditFormObject(AllErrors));
    handleBusinessIdModelClose();
  };

  const handleDeleteBusinessId = (index) => {
    viewData[0].businessIdentifiers.splice(index, 1);
    dispatch(
      currentCompanyFormObject({
        ...viewData,
      })
    );
  };

  const handleAddEcomPoints = () => {
    let allEcomPoints =
      ecomList.length > 0 ? JSON.parse(JSON.stringify(ecomList)) : [];
    let addEcomReqModel;
    if (state.integrationType == "woocommerce") {
      allEcomPoints.push({
        ecomUrl: ecomState.ecomUrl,
        ecomConsumerKey: ecomState.ecomConsumerKey,
        ecomConsumerSecret: ecomState.ecomConsumerSecret,
        integrationType: state.integrationType,
      });
      addEcomReqModel = {
        url: ecomState.ecomUrl,
        consumerKey: ecomState.ecomConsumerKey,
        consumerSecret: ecomState.ecomConsumerSecret,
        integrationType: state.integrationType,
        businessIdentifiers: viewData[0].businessIdentifiers,
      };
    } else if (state.integrationType == "shopify") {
      allEcomPoints.push({
        storeName: ecomState.storeName,
        apiKey: ecomState.apiKey,
        apiSecretKey: ecomState.apiSecretKey,
        accessToken: ecomState.accessToken,
        integrationType: state.integrationType,
        hostUrl: "https://prod.api.2nd.gdn/v2",
      });
      addEcomReqModel = {
        storeName: ecomState.storeName,
        apiKey: ecomState.apiKey,
        apiSecretKey: ecomState.apiSecretKey,
        accessToken: ecomState.accessToken,
        integrationType: state.integrationType,
        hostUrl: "https://prod.api.2nd.gdn/v2",
        businessIdentifiers: viewData[0].businessIdentifiers,
      };
    } else if (state.integrationType == "amazon") {
      let marketplaceIdsArray = [
        { region: ecomState.marketPlaceRegion, id: ecomState.marketPlaceId },
      ];
      allEcomPoints.push({
        url: ecomState.url,
        sellerid: ecomState.sellerId,
        marketplaceids: marketplaceIdsArray,
        integrationType: state.integrationType,
      });
      addEcomReqModel = {
        url: ecomState.url,
        sellerid: ecomState.sellerId,
        marketplaceids: [
          {
            region: ecomState.marketPlaceRegion,
            id: ecomState.marketPlaceId,
          },
        ],
        integrationType: state.integrationType,
        businessIdentifiers: viewData[0].businessIdentifiers,
      };
    } else if (state.integrationType == "ecwid") {
      allEcomPoints.push({
        ecwidUrl: ecomState.ecwidUrl,
        storeId: ecomState.storeId,
        secretToken: ecomState.secretToken,
        integrationType: state.integrationType,
      });
      addEcomReqModel = {
        url: ecomState.ecwidUrl,
        secret_token: ecomState.secretToken,
        storeId: ecomState.storeId,
        integrationType: state.integrationType,
        businessIdentifiers: viewData[0].businessIdentifiers,
      };
    } else if (state.integrationType == "magento") {
      allEcomPoints.push({
        url: ecomState.magentoUrl,
        consumer_key: ecomState.consumerKey,
        consumer_secret: ecomState.consumerSecret,
        access_token: ecomState.accessToken,
        access_token_secret: ecomState.accessTokenSecret,
        integrationType: state.integrationType,
      });
      addEcomReqModel = {
        url: ecomState.magentoUrl,
        consumer_key: ecomState.consumerKey,
        consumer_secret: ecomState.consumerSecret,
        access_token: ecomState.accessToken,
        access_token_secret: ecomState.accessTokenSecret,
        integrationType: state.integrationType,
        businessIdentifiers: viewData[0].businessIdentifiers,
      };
    } else {
    }
    setEcomList(allEcomPoints);
    dispatch(addEcomPoint(addEcomReqModel));
    handleEcomModelClose();
    setEcomState({
      ...ecomState,
      ecomUrl: "",
      ecomConsumerKey: "",
      ecomConsumerSecret: "",
      storeName: "",
      apiKey: "",
      apiSecretKey: "",
      accessToken: "",
      sellerId: "",
      marketPlaceRegion: "",
      marketPlaceId: "",
    });
  };

  const handleDeleteEcomPoints = (index, id) => {
    let allEcomPoints =
      ecomList.length > 0 ? JSON.parse(JSON.stringify(ecomList)) : [];
    allEcomPoints.length > 0 && allEcomPoints.splice(index, 1);
    setEcomList(allEcomPoints);
    !!id &&
      dispatch(
        deleteEcomPoint({
          businessid: objectPath.get(
            _.find(viewData[0].businessIdentifiers, function (o) {
              return o.type === "VATID";
            }),
            "id",
            viewData[0].businessIdentifiers[0].id
          ),
          id: id,
        })
      );
  };

  const handleMultiSelectValueChange = (e, { name, value }) => {
    let _list = [];
    value.forEach((o) => {
      _list.push(LanguageCode.find((lang) => lang.code === o));
    });
    checkAllEULangState(_list)
    setModelList(_list);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleAdd = () => {
    viewData[0] = { ...viewData[0], supportedLanguages: modelList };
    dispatch(currentCompanyFormObject(viewData));
    setShow(false);
  };

  const delLanguage = (i) => {
    let oldList1 = [...modelList]
    oldList1.splice(i, 1);
    checkAllEULangState(oldList1)
    setModelList([...oldList1])
    viewData[0] = { ...viewData[0], supportedLanguages: oldList1 };
    dispatch(currentCompanyFormObject(viewData));
  };

  const getLangDropDownData = () => {
    return LanguageCode.map((o, index) => {
      return { key: index, text: o.name, value: o.code };
    });
  };

  const handleRecipientEmailCLose = () => {
    setRecipientEmailsShow(false);
  };

  const handleRecipientEmailChange = (e) => {
    setRecipientEmail(e.target.value);
  };

  const handleAddRecipientEmail = () => {
    let allRecipientEmails = !!viewData[0].recipientEmails
      ? JSON.parse(JSON.stringify(viewData[0].recipientEmails))
      : [];
    if (!!recipientEmail) {
      allRecipientEmails.push(recipientEmail);
    }
    viewData[0] = { ...viewData[0], recipientEmails: allRecipientEmails };
    dispatch(currentCompanyFormObject(viewData));
    handleRecipientEmailCLose();
    setRecipientEmail();
  };

  const handleAddDatapoolsId = () => {
    let allDatapoolsId = !!viewData[0].datapools
      ? JSON.parse(JSON.stringify(viewData[0].datapools))
      : [];
    if (!!datapoolId) {
      allDatapoolsId.push(datapoolId);
    }
    viewData[0] = { ...viewData[0], datapools: allDatapoolsId };
    dispatch(currentCompanyFormObject(viewData));
    setDatapoolModalShow();
    setDatapoolId();
  };

  const handleDatapoolIdChange = (e) => {
    setDatapoolId(e.target.value);
  };

  const handleDeleteDatapoolId = (index) => {
    viewData[0].datapools.splice(index, 1);
    dispatch(
      currentCompanyFormObject({
        ...viewData,
      })
    );
  };

  const deleteRecipientEmail = (i) => {
    let _oldList = [...viewData[0].recipientEmails];
    _oldList.splice(i, 1);
    viewData[0] = { ...viewData[0], recipientEmails: _oldList };
    dispatch(currentCompanyFormObject(viewData));
  };

  const generateAPIKeyHandle = () => {
    const key = generateUUID();
    viewData[0] = { ...viewData[0], apiKey: key };
    dispatch(currentCompanyFormObject(viewData));
  };

  function generateUUID() {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now();
    }

    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }

  const getEcomForm = (ecomType) => {
    switch (ecomType) {
      case "woocommerce":
        return (
          <div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.ecomUrl}
                onChange={handleEcomChange}
                placeholder={t("URL")}
                name="ecomUrl"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.ecomConsumerKey}
                onChange={handleEcomChange}
                placeholder={t("Consumer Key")}
                name="ecomConsumerKey"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.ecomConsumerSecret}
                onChange={handleEcomChange}
                placeholder={t("Consumer Secret")}
                name="ecomConsumerSecret"
                type="text"
              ></InputTextWithLabel>
            </div>
          </div>
        );
        break;

      case "shopify":
        return (
          <div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.storeName}
                onChange={handleEcomChange}
                placeholder={t("Store Name")}
                name="storeName"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.apiKey}
                onChange={handleEcomChange}
                placeholder={t("Api Key")}
                name="apiKey"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.apiSecretKey}
                onChange={handleEcomChange}
                placeholder={t("Api Secret Key")}
                name="apiSecretKey"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.accessToken}
                onChange={handleEcomChange}
                placeholder={t("Access Token")}
                name="accessToken"
                type="text"
              ></InputTextWithLabel>
            </div>
          </div>
        );
        break;

      case "amazon":
        return (
          <div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                disabled
                value={ecomState.url}
                onChange={handleEcomChange}
                placeholder=""
                name={t("Url")}
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.sellerId}
                onChange={handleEcomChange}
                placeholder={t("Seller Id")}
                name="sellerId"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <Dropdown
                style={{ width: 400 }}
                placeholder={t("Market Place Region")}
                search
                selection
                name="marketPlaceRegion"
                value={ecomState.marketPlaceRegion}
                options={amazonMarketPlaceRegions}
                onChange={handleEcomChange}
              />
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.marketPlaceId}
                onChange={handleEcomChange}
                placeholder={t("Market Place Id")}
                name="marketPlaceId"
                type="text"
              ></InputTextWithLabel>
            </div>
          </div>
        );
        break;

      case "ecwid":
        return (
          <div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                disabled
                value={ecomState.ecwidUrl}
                onChange={handleEcomChange}
                placeholder=""
                name="url"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.secretToken}
                onChange={handleEcomChange}
                placeholder={t("Secret Token")}
                name="secretToken"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.storeId}
                onChange={handleEcomChange}
                placeholder={t("Store Id")}
                name="storeId"
                type="text"
              ></InputTextWithLabel>
            </div>
          </div>
        );
        break;

      case "magento":
        return (
          <div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.magentoUrl}
                onChange={handleEcomChange}
                placeholder={t("URL")}
                name="magentoUrl"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.consumerKey}
                onChange={handleEcomChange}
                placeholder={t("Consumer Key")}
                name="consumerKey"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.consumerSecret}
                onChange={handleEcomChange}
                placeholder={t("Consumer Secret")}
                name="consumerSecret"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.accessToken}
                onChange={handleEcomChange}
                placeholder={t("Access Token")}
                name="accessToken"
                type="text"
              ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={""}
                value={ecomState.accessTokenSecret}
                onChange={handleEcomChange}
                placeholder={t("Access Token Secret")}
                name="accessTokenSecret"
                type="text"
              ></InputTextWithLabel>
            </div>
          </div>
        );
        break;

      default:
        break;
    }
  };

  const getLanguageOptions = () => {
    const languageList = []
    _.get(viewData, "[0]supportedLanguages", []).forEach((item) => {
      languageList.push({ label: item.name, value: item.code })
    })
    return languageList
  }


  const getSupportedLanguage = getLanguageOptions()

  return (
    <>
      {isCompanySearchLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="row">
          {viewData && viewData[0] && (
            <ManufacturerSideBar
              handleFormChange={handleFormChange}
              handlePrivacyAddendumChange={handlePrivacyAddendumChange}
              manufacturerData={viewData && viewData[0]}
              errorObject={errorObject}
            />
          )}
          <div
            style={{ height: "100vh", marginTop: 40 }}
            className="col-xl-9 col-xxl-8 col-md-8"
          >
            {viewData && viewData[0] && (
              <form>
                <div className="row col-12">
                  <InputTextWithLabel
                    label={t('Company Name')}
                    tooltipText={t("Company Name")}
                    readOnly={_state.isCompanyEditMode ? false : true}
                    onChange={handleFormChange}
                    name={"name"}
                    value={viewData[0].name}
                    type="text"
                    placeholder={t("Company Name")}
                    touched={touchedState}
                    errorMessage={
                      !!errorObject && errorObject.name ? errorObject.name : ""
                    }
                  ></InputTextWithLabel>

                  <InputTextWithLabel
                    label={t('Company Address')}
                    tooltipText={t("Company Address")}
                    readOnly={_state.isCompanyEditMode ? false : true}
                    onChange={handleFormChange}
                    name={"address"}
                    value={viewData[0].address}
                    type="text"
                    placeholder={t("Company Address")}
                    touched={touchedState}
                    errorMessage={
                      !!errorObject && errorObject.address
                        ? errorObject.address
                        : ""
                    }
                  />

                  <InputTextWithLabel
                    label={t('IBAN')}
                    tooltipText={t("IBAN")}
                    readOnly={_state.isCompanyEditMode ? false : true}
                    onChange={handleFormChange}
                    name={"IBAN"}
                    value={viewData[0].IBAN}
                    type="text"
                    placeholder={t("IBAN")}
                    errorMsg={
                      !!errorObject && errorObject.IBAN ? errorObject.IBAN : ""
                    }
                  />

                  <InputTextWithLabel
                    label={t('SWIFT/BIC')}
                    tooltipText={t("SWIFT/BIC")}
                    readOnly={_state.isCompanyEditMode ? false : true}
                    onChange={handleFormChange}
                    name={"SWIFT_BIC"}
                    value={viewData[0].SWIFT_BIC}
                    type="text"
                    placeholder={t("SWIFT/BIC")}
                    errorMsg={
                      !!errorObject && errorObject.SWIFT_BIC
                        ? errorObject.SWIFT_BIC
                        : ""
                    }
                  />

                  <InputSelectDropdown
                    label={t("Country Code")}
                    tooltipText={"Country Code"}
                    placeholder={t("Select country code")}
                    value={viewData[0].countryCode}
                    name="countryCode"
                    disabled={true}
                    options={selectCountryCodeOption}
                  />

                  <InputTextWithLabel
                    label={t('Contact Details')}
                    tooltipText={t("Contact Details")}
                    readOnly={_state.isCompanyEditMode ? false : true}
                    onChange={handleFormChange}
                    name={"contact"}
                    value={viewData[0].contact}
                    type="text"
                    placeholder={t("Contact Details")}
                    touched={touchedState}
                    errorMessage={
                      !!errorObject && errorObject.contact
                        ? errorObject.contact
                        : ""
                    }
                  ></InputTextWithLabel>

                  <InputSelectDropdown
                    label={t("Billed For")}
                    tooltipText={"Billed For"}
                    placeholder={t("Select Billed For")}
                    value={
                      viewData[0].billedFor ? viewData[0].billedFor : "events"
                    }
                    name="billedFor"
                    disabled={_state.isCompanyEditMode ? false : true}
                    options={billedForOptions}
                    onChange={handleFormChange}
                  />

                  <InputSelectDropdown
                    label={t("Billing Type")}
                    tooltipText={"Billing Type"}
                    placeholder={t("Select Billing Type")}
                    value={
                      viewData[0].billingType
                        ? viewData[0].billingType
                        : "post-charge"
                    }
                    name="billingType"
                    disabled={_state.isCompanyEditMode ? false : true}
                    options={billingTypeOptions}
                    onChange={handleFormChange}
                  />

                  {viewData[0]?.billedFor !== "products" &&
                    <InputTextWithLabel
                      label={t('Per Event Price')}
                      tooltipText={t("Per Event Price")}
                      readOnly={_state.isCompanyEditMode ? false : true}
                      onChange={handleFormChange}
                      name={"priceTable"}
                      value={
                        viewData[0]?.priceTable?.default && viewData[0]?.priceTable?.default
                      }
                      type="number"
                      placeholder={t("Per Event Price")}
                    ></InputTextWithLabel>}

                  <InputTextWithLabel
                    label={t('Credit Balance')}
                    tooltipText={t("Credit Balance")}
                    readOnly={_state.isCompanyEditMode ? false : true}
                    onChange={handleFormChange}
                    name={"creditBalance"}
                    value={
                      viewData[0].creditBalance ? viewData[0].creditBalance : 0
                    }
                    type="number"
                    placeholder={t("Credit Balance")}
                  ></InputTextWithLabel>

                  {viewData[0]?.billedFor == "products" &&
                    <InputTextWithLabel
                      label={t('Per Product Price')}
                      tooltipText={t("Per Product Price")}
                      readOnly={_state.isCompanyEditMode ? false : true}
                      onChange={handleFormChange}
                      name={"perProductPrice"}
                      value={viewData[0].perProductPrice}
                      type="number"
                      placeholder={t("Per Product Price")}
                    />}

                  <InputTextWithLabel
                    label={t('Vat')}
                    tooltipText={t("Vat")}
                    readOnly={_state.isCompanyEditMode ? false : true}
                    onChange={handleFormChange}
                    name={"vat"}
                    value={viewData[0].vat}
                    type="number"
                    placeholder={t("Vat")}
                  />

                  <InputSelectDropdown
                    label={t("Region")}
                    tooltipText={t("Region")}
                    disabled={_state.isCompanyEditMode ? false : true}
                    showTooltip={true}
                    placeholder={t("Region")}
                    name="region"
                    // search={true}
                    value={viewData[0].region}
                    options={regionsOptions}
                    onChange={handleFormChange}
                  // loading={_state.segmentLoading}
                  />

                  <InputTextWithLabel
                    label={t('Invoicing email')}
                    tooltipText={t("Invoicing email")}
                    name={'invoicingEmail'}
                    value={viewData[0]?.invoicingEmail}
                    type="text"
                    readOnly={_state.isCompanyEditMode ? false : true}
                    placeholder={t("Invoicing email")}
                    onChange={handleFormChange}
                    touched={touchedState}
                    errorMessage={!!errorObject && errorObject.invoicingEmail ? errorObject.invoicingEmail : ""}
                  />

                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>{t("Api Key")}</label>
                    <div>
                      <input
                        name="apiKey"
                        value={viewData[0].apiKey}
                        type={showApikey ? "text" : "password"}
                        placeholder={t("Api Key")}
                        readOnly={true}
                      />
                      <span className="product-profile eyeIcon">
                        {showApikey ? (
                          <i
                            onClick={() => setShowApikey(false)}
                            className="fa-eye-slash icon"
                          ></i>
                        ) : (
                          <i
                            onClick={() => setShowApikey(true)}
                            className="far fa-eye icon"
                          ></i>
                        )}
                      </span>
                      {_state.isCompanyEditMode && (
                        <Badges add onClick={() => generateAPIKeyHandle()}>
                          {t("Generate New Api Key")}
                        </Badges>
                      )}
                    </div>
                  </div>

                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>{t("Recipient Emails")}</label>
                    {!!viewData[0].recipientEmails &&
                      viewData[0].recipientEmails.map((o, index) => (
                        <Badges
                          key={index}
                          remove={_state.isCompanyEditMode}
                          onClick={() => deleteRecipientEmail(index)}
                        >
                          {o}
                        </Badges>
                      ))}
                    {_state.isCompanyEditMode && (
                      <Badges add onClick={() => setRecipientEmailsShow(true)}>
                        {t("Add Email")}
                      </Badges>
                    )}
                  </div>

                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <label>{t("Supported Languages")}</label>
                    {!!viewData[0].supportedLanguages &&
                      viewData[0].supportedLanguages.map((o, index) => (
                        <Badges
                          key={index}
                          remove={_state.isCompanyEditMode}
                          onClick={() => delLanguage(index)}
                        >
                          {o.name}
                        </Badges>
                      ))}
                    {_state.isCompanyEditMode && (
                      <Badges add onClick={() => setShow(true)}>
                        {t("Add Language")}
                      </Badges>
                    )}
                  </div>

                  <InputSelectDropdown
                    label={t("Communication language")}
                    tooltipText={"Communication language"}
                    placeholder={t("Select Language")}
                    name="language"
                    readOnly={_state.isCompanyEditMode ? false : true}
                    onChange={handleFormChange}
                    value={viewData[0].language}
                    options={getSupportedLanguage}
                  />
                </div>

                <div className="row col-12">
                  <div className="form-group col-lg-8 col-md-8 col-sm-8 col-8">
                    <label>{t("Ecommerce Integrations")}</label>
                    {!!errorObject && errorObject.ecomPoints && (
                      <div className="text-danger">
                        {errorObject.ecomPoints}
                      </div>
                    )}
                  </div>
                  {_state.isCompanyEditMode ? (
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                      <IconButton
                        text={t("Add New")}
                        icon={MdAddCircleOutline}
                        onClick={() => setEcomModelShow(true)}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-12 mb-2">
                    <div className="row">
                      {!!ecomList
                        ? ecomList.map((o, index) => (
                          <div key={index} className="col-11 mb-2">
                            <UrlCard
                              url={
                                o.integrationType == "woocommerce"
                                  ? o.ecomUrl
                                  : o.integrationType == "shopify"
                                    ? o.storeName
                                    : o.integrationType == "amazon"
                                      ? o.url
                                      : o.integrationType == "ecwid"
                                        ? o.ecwidUrl
                                        : o.integrationType == "magento"
                                          ? o.url
                                          : null
                              }
                              onClick={() => console.log("ecom url clicked")}
                              isDelete={_state.isCompanyEditMode}
                              onDeletClick={() =>
                                handleDeleteEcomPoints(index, o.id)
                              }
                            ></UrlCard>
                          </div>
                        ))
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="row col-12">
                  <div className="form-group col-lg-8 col-md-8 col-sm-8 col-8">
                    <label>{t("_Business Id")}</label>
                    {!!errorObject && errorObject.businessIdentifiers && (
                      <div className="text-danger">
                        {errorObject.businessIdentifiers}
                      </div>
                    )}
                  </div>

                  {_state.isCompanyEditMode ? (
                    <div
                      className="col-lg-4 col-md-4 col-sm-4 col-4"
                      onClick={() => setBusinessIdModelShow(true)}
                    >
                      <IconButton
                        text={t("Add New")}
                        icon={MdAddCircleOutline}
                        onClick={() => setBusinessIdModelShow(true)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12">
                  <div className="row">
                    {viewData[0] && !!viewData[0].businessIdentifiers
                      ? viewData[0].businessIdentifiers.map((o, index) => (
                        <div key={index} className="col-11 mb-2">
                          <BusinessIdCard
                            businessIdLabel={o.type}
                            businessId={o.id}
                            isDelete={_state.isCompanyEditMode}
                            onClick={() => handleDeleteBusinessId(index)}
                          ></BusinessIdCard>
                        </div>
                      ))
                      : ""}
                  </div>
                </div>

                {/* <div className="st-divider divider-thin my-3"></div> */}
                <div className="row col-12 mt-2">
                  <div className="form-group col-lg-8 col-md-8 col-sm-8 col-8">
                    <label>{t("_Datapools")}</label>
                    {/* {!!errorObject && errorObject.businessIdentifiers && (
                      <div className="text-danger">{errorObject.businessIdentifiers}</div>
                    )} */}
                  </div>
                  {_state.isCompanyEditMode ? (
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                      <IconButton
                        text={t("Add New")}
                        icon={MdAddCircleOutline}
                        onClick={() => setDatapoolModalShow(true)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12">
                  <div className="row">
                    {!!viewData[0] &&
                      viewData[0].datapools &&
                      viewData[0].datapools.map((o, index) => (
                        <div key={index} className="col-11 mb-2">
                          <UrlCard
                            url={o}
                            onClick={() => console.log("urlcard clicked")}
                            isDelete={_state.isCompanyEditMode}
                            onDeletClick={() => handleDeleteDatapoolId(index)}
                          ></UrlCard>
                        </div>
                      ))}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      )}

      <Modal
        size="md"
        show={ecomModelShow}
        onHide={handleEcomModelClose}
        centered={true}
      >
        <Modal.Header>
          <div style={{ position: "absolute", right: 10 }}>
            <StCloseButton
              text={t("Close")}
              onClick={() => handleEcomModelClose()}
            ></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: "normal", textAlign: "center" }}>
          <div className="row m-0 justify-content-center">
            <span className="col-12 product-profile model-text mb-10">
              {t("Enter Ecommerce Integration Info")}
            </span>
            <div className="form-group col-12">
              <Dropdown
                style={{ marginTop: 20, width: 400, height: 40 }}
                placeholder={t("Select Ecommerce Integration Type")}
                selection
                value={state.integrationType}
                options={ecommerceIntegrationTypes}
                onChange={handleEcomIntegrationTypeChange}
                name="type"
              />
            </div>
            {/* ecom integrations add form */}
            {getEcomForm(state.integrationType)}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <StSquareButton
            text={t("Add")}
            onClick={() => handleAddEcomPoints()}
          ></StSquareButton>
          <StCancelSquareButton
            text={t("Cancel")}
            onClick={() => handleEcomModelClose()}
          ></StCancelSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={businessIdModelShow}
        onHide={handleBusinessIdModelClose}
        centered={true}
      >
        <Modal.Header>
          <div style={{ position: "absolute", right: 10 }}>
            <StCloseButton
              text={t("Close")}
              onClick={() => handleBusinessIdModelClose()}
            ></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: "normal", textAlign: "center" }}>
          <div className="row m-0 justify-content-center">
            <span className="col-12 product-profile model-text mb-10">
              {t("Enter Business Id")}
            </span>
            <div className="form-group col-12">
              <Dropdown
                style={{ marginTop: 20, width: 400, height: 40 }}
                placeholder={t("Select Business Id Type")}
                selection
                value={state.businessIdTypeValue}
                options={businessIdTypeOptions}
                onChange={handleDropdownChange}
                name="type"
              />
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                errorMessage={businessIdInputWarrning}
                label={""}
                value={state.businessIdInputValue}
                onChange={handleBusinessIdChange}
                placeholder={t("_Business Id")}
                name="businessIdInputValue"
                type="text"
                touched={warnningHandler}
              // readOnly={businessInputIdDisable}
              ></InputTextWithLabel>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <StSquareButton
            disabled={disableButtonHandler}
            text={t("Add")}
            onClick={() => handleAddBusinessId()}
          ></StSquareButton>
          <StCancelSquareButton
            text={t("Cancel")}
            onClick={() => handleBusinessIdModelClose()}
          ></StCancelSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        centered={true}
        backdrop="static"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <div style={{ position: "absolute", right: 10 }}>
            <StCloseButton
              text={t("Close")}
              onClick={() => handleClose()}
            ></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: "normal" }}>
          <div className="row col-12 m-0 justify-content-center">
            <span
              style={{ fontSize: 18, color: "#000000", fontWeight: "bold" }}
            >
              {t("Select Supported Languages")}
            </span>
            <div className="d-flex flex-row">
              <div className="d-flex align-items-center">
                <span style={{ fontSize: 12, color: '#000000' }}>{t("addAllEULanguages")}</span>
              </div>
              <div className="d-flex mt-2">
                <InputSwitchWithLable
                  onHandleColor='#32cd32'
                  offHandleColor='#ffffff'
                  onColor='#32cd32'
                  offColor='#6c757d'
                  onChange={toggleChecked}
                  value={AddAllEULangChecked}
                />
              </div>
            </div>
            <Dropdown
              style={{ margin: 15, fontSize: 14 }}
              onChange={handleMultiSelectValueChange}
              selection
              multiple
              search
              fluid
              value={langArray}
              options={getLangDropDownData()}
              placeholder={t("Choose an Option")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <StSquareButton
            text={t("Add")}
            onClick={() => handleAdd()}
          ></StSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        centered={true}
        backdrop="static"
        show={recipientEmailsShow}
        onHide={handleRecipientEmailCLose}
      >
        <Modal.Header>
          <div style={{ position: "absolute", right: 10 }}>
            <StCloseButton
              text={t("Close")}
              onClick={() => handleRecipientEmailCLose()}
            ></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: "normal" }}>
          <div className="row col-12 m-0 justify-content-center form-group">
            <InputTextWithLabel isSmallScreen={true}
              label={t("Enter Recipient Email")}
              name={"recipientEmail"}
              type="text"
              placeholder={t("Enter Recipient Email")}
              value={recipientEmail}
              onChange={handleRecipientEmailChange}
            ></InputTextWithLabel>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <StSquareButton
            text={t("Add")}
            onClick={() => handleAddRecipientEmail()}
          ></StSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        centered={true}
        backdrop="static"
        show={datapoolModalShow}
        onHide={() => {
          setDatapoolModalShow(false);
          setDatapoolId();
        }}
      >
        <Modal.Header>
          <div style={{ position: "absolute", right: 10 }}>
            <StCloseButton
              text={t("Close")}
              onClick={() => {
                setDatapoolModalShow(false);
                setDatapoolId();
              }}
            ></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: "normal", textAlign: "center" }}>
          <div className="row col-12 m-0 justify-content-center form-group">
            <span className="col-12 product-profile model-text mb-10">
              {t("Enter Datapools Id")}
            </span>
            <InputTextWithLabel isSmallScreen={true}
              label={''}
              name={"datapoolId"}
              type="text"
              placeholder={t("Enter Datapools id")}
              value={datapoolId}
              onChange={handleDatapoolIdChange}
            ></InputTextWithLabel>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <StSquareButton
            text={t("Add")}
            onClick={() => handleAddDatapoolsId()}
          ></StSquareButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewCompany;
