import React, { Fragment, Suspense, useState } from "react";
import { Routes, Route, Navigate, useLocation, BrowserRouter, } from "react-router-dom";
import Cookies from 'universal-cookie';

import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './locales/multiplelangSupport'
import "./assets/fonts/fonts.scss";
import "bootstrap/dist/js/bootstrap"
import "./assets/styles/main.scss";
import './components/styles/theme-provider.scss'

import { ToastContainer } from 'react-toastify';
import LoginLayout from "./pages/login/LoginLayout";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import AppContainer from "./pages/AppContainer";
import UserLeaveConfirmation from "./pages/products/addProduct/UserLeaveConfirmation";
import { PersistGate } from 'redux-persist/integration/react'
import Swagger from "./pages/swaggerApi/Swagger";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Outlet } from "react-router-dom/dist";



function AppRoute() {
  const [confirmOpen, setConfirmOpen] = useState(true);
  const cookies = new Cookies();
  
  const theme = createTheme({
    spacing: 4, // Adjust the spacing value as needed
    // Add other theme configurations here
  });
  
  const PrivateRoute = (props) => {
    const _authState = cookies.get('AUTH_STATE');
    return _authState ? (
      <Outlet />
    ) : (
      <Navigate replace to="/login" />
    );
  };

  return (
    <>
      <Suspense fallback={(<div>Loading....</div>)}>
        {/* <NotificationContainer /> */}
        <Provider store={store}>
          <ToastContainer />
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter getUserConfirmation={(message, callback) => {
              return UserLeaveConfirmation(
                message,
                callback,
                confirmOpen,
                setConfirmOpen
              );
            }}
            >
              <ThemeProvider theme={theme}>
                <Routes>
                  {["/login", "/signup", "/unsubscribe", "/terms", "/resetpassword", "/newpassword"].map(path => (
                    <Route key={path} path={path} element={<LoginLayout />} />
                  ))}
                  <Route path="/swagger/public" element={<Swagger />} />
                  <Route element={<PrivateRoute />}>
                    <Route path="/*" element={<AppContainer />}></Route>
                  </Route>
                </Routes>
              </ThemeProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </Suspense>
    </>
  )
}

export default AppRoute
