import React, { useCallback, useEffect, useState, useRef } from 'react';
import { getUpidsProduct } from 'src/redux/products/productThunk';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import UpidsProductTableListing from './UpidsProductTableListing';
import { StSearchBox } from "src/components/StSearchBox";
import _, { debounce } from "lodash";



export const UpidsProduct = () => {
  const { t } = useTranslation('common');
  const [searchText, setSearchText] = useState("");
  const [upidsProductListData, setUpidsProductListData] = useState([])
  const upidsProductData = useRef();

  const dispatch = useDispatch();
  const productState = useSelector((state) => (state.products), shallowEqual);

  useEffect(() => {
    fetchUpidsProductData();
  }, [searchText]);

  const fetchUpidsProductData = async () => {
    if (searchText === "") {
      let res = await dispatch(getUpidsProduct({ field: 'articleIdentifier', value: productState?.productById?.gtin || productState.productById._2an }))
      setUpidsProductListData(res?.payload)
      upidsProductData.current = res?.payload;
    }
  }

  const getSearchData = async (nextValue) => {
    if (nextValue !== "") {
      const newFilteredArray = _.filter(upidsProductData.current, (value) => {
        return value?.upidsId?.includes(nextValue);
      });
      if (newFilteredArray.length == 0 && nextValue !== "") {
        let res = await dispatch(getUpidsProduct({ field: 'upidsId', value: nextValue }))
        setUpidsProductListData(res?.payload)
      } else {
        setUpidsProductListData(newFilteredArray)
      }
    }
  }

  const debouncedSave = useCallback(
    debounce(nextValue => getSearchData(nextValue), 1000),
    [],
  );

  const handleSearchInputChange = async (e) => {
    const searchText = (e.target.value);
    setSearchText(searchText)
    debouncedSave(searchText);

  }

  return (
    <>
      <div style={{ marginBottom: 26 }} className="row col-lg-12">
        <span className='col-lg-12 mb-3' style={{ color: '#253C4B', fontSize: 14, fontWeight: 'bold' }}>{t("Upids Products")}</span>
        <div className="row col-12 homepage-search-input-div pt-3" style={{ paddingLeft: 0, paddingBottom: 16, backgroundColor: "#eff8fd", marginLeft: 10 }}>
          <div className="col-lg-8 col-md-8">
            <StSearchBox
              iconName={searchText === "" ? t("Search") : t("Close")}
              width={'100%'}
              placeholder={t("Search Upids Product by UpidsId")}
              value={searchText}
              onChange={(e) => handleSearchInputChange(e)}
              onClick={() => setSearchText("")}
            />
          </div>
        </div>
        <div className='mt-2' style={{zIndex:1}}>
          <UpidsProductTableListing loading={productState.getUpidsProductLoader} upidsProductList={upidsProductListData} />
        </div>
      </div>
    </>
  )

}
