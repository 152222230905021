import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spinner } from "src/components/Spinner";
import { StSearchBox } from "src/components/StSearchBox";
import MetadataTable from "./metadataTable/MetadataTable";
import { getAllMetadataTaxonomyData } from "src/redux/products/productThunk";

const Metadata = () => {
  /** redux store useDispatch & useSelector ------------------------------ **/
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const _state = useSelector((state) => state.products, shallowEqual);

   /** hooks useState ----------------------------------------------------- **/
  const [searchText, setSearchText] = useState("");
  const [allTaxonomyList, setAllTaxonomyList] = useState([])

   /** Variable declaration ----------------------------------------------------- **/
  const isLoading = _state.actionsLoading;

 /** hooks useEffect----------------------------------------------------- **/
  useEffect(() => {
    getTaxonomyData();
  }, [searchText]);

   /** handler functions--------------------------------------------------- **/

  /** Function to retrieve all taxonomy data and also filter that based on the search text value */
  const getTaxonomyData = async () => {
    if (searchText === "") {
      let result = await dispatch(getAllMetadataTaxonomyData());
      setAllTaxonomyList(result.payload)
    } else {
      const timeOut = setTimeout(() => {
        clearTimeout(timeOut)
        const newFilter = _state?.taxonomyItemsData?.filter((value) => {
          return (
            value.attributeName.toLowerCase().includes(searchText.toLowerCase())
          );
        });
        setAllTaxonomyList(newFilter);
      }, 1000)
      return () => clearTimeout(timeOut)
    }
  }

  const getAllTaxonomyItemsFetchData = (_mode, _gtin) => {
    dispatch(getAllMetadataTaxonomyData({}));
  };


  return (
    <div className="homepage-main-div ps-2">
      <div className="row col-12 homepage-search-input-div pt-3" style={{ paddingLeft: 0 }}>
        <div className=" col-lg-6 col-md-6 ">
          <StSearchBox
            iconName={searchText === "" ? t("Search") : t("Close")}
            width={'100%'}
            placeholder={t("Search by taxonomy item")}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onClick={() => setSearchText("")}
          />
        </div>
      </div>

      <div style={{ marginTop: 76, position: 'fixed' }} className='st-divider divider-thin'></div>
      {_state.actionsLoading ?
        <div>
          <Spinner />
        </div> :
        <div className="homepage-main-subdiv">
          <MetadataTable
            getTaxonomyItemsData={getAllTaxonomyItemsFetchData}
            taxonomyList={allTaxonomyList}
            loading={isLoading}
          />
        </div>
      }
    </div>
  );
};

export default Metadata;
