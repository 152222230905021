import React, { useContext, useState } from 'react';
import { TabAccordion } from 'src/components/TabAccordion';
import moment from 'moment'; // require
import {BsChatSquareText} from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/pages/AppContext';

function LatestFeedbacks(props){
    const { t, i18n } = useTranslation('common');
    const context = useContext(AppContext);

    const [feedbackToView,setFeedbackToView]= useState()

    const getLatestFeedbackAccordianData=(latestFeedbacks)=>{
        let feedbacks = [];
        latestFeedbacks.map((data, i) => {
            feedbacks.push({
                title: <div>
                        <span className="">{data.productName}</span><br></br>
                        <span className="newsAndFeedbackSubtitle">{moment.unix(data.timestamp).format("YYYY-MM-DD HH:mm:ss")}</span>
                        </div>,
                tabs: ()=> 
                            <div key={i}>
                                <div>
                                    {/* <span className="prod_subtitle" style={{ fontSize: 13 }}>{t("Date")}: </span> <span className="newsAndFeedbackSubtitle">{moment.unix(data.timestamp).format("YYYY-MM-DD HH:mm:ss")}</span><br></br> */}
                                    <span className="prod_subtitle" style={{ fontSize: 13 }}>{t("UPIDS")}: </span><span className="newsAndFeedbackSubtitle">{data.articleIdentifier}</span><br></br>
                                    <span className="prod_subtitle" style={{ fontSize: 13 }}>{t("Email")}: </span><span className="newsAndFeedbackSubtitle">{!!context.authState && context.authState.level === 10 ? !!data.email && data.email !== "" ? data.email : t("Anonymous") : t("Anonymous")}</span><br></br>
                                    <span className="prod_subtitle" style={{ fontSize: 13 }}>{t("Text")}: </span><span className="newsAndFeedbackSubtitle">{data.text}</span><br></br>
                                </div>
                                  <BsChatSquareText size={18} style={{ float:'right',cursor:'pointer',position:'absolute',top:'50%',right:'5%'}} onClick={() => props.handleOnConversationButtonClick(data.feedbackId, i)} />
                            </div>
            })
        })
        return feedbacks;
    }
    
    return(

        !!props.latestFeedbacks && props.latestFeedbacks.length > 0 ? props.latestFeedbacks.map((data, i) => (
            <div key={i} className="mt-2 p-1 newsFeedRow">
                <div onClick={()=>props.handleOnConversationButtonClick(data.feedbackId, i)}>
                    <div className="fw-bold">{data.productName}</div>
                    <div className="prod_subtitle">{moment.unix(data.timestamp).format("YYYY-MM-DD HH:mm:ss")}
                      <span className="newsAndFeedbackSubtitle ms-2">{!!context.authState && context.authState.level === 10 ? !!data.email && data.email !== "" ? data.email : t("Anonymous") : t("Anonymous")}</span>
                    </div>
                </div>
               {/* {feedbackToView == i && <div className='d-flex'>
                    <div className='col-11'>
                        <span className="prod_subtitle" style={{ fontSize: 13 }}>{t("UPIDS")}: </span><span className="newsAndFeedbackSubtitle">{data.articleIdentifier}</span><br></br>
                        <span className="prod_subtitle" style={{ fontSize: 13 }}>{t("Email")}: </span><span className="newsAndFeedbackSubtitle">{!!context.authState && context.authState.level === 10 ? !!data.email && data.email !== "" ? data.email : t("Anonymous") : t("Anonymous")}</span><br></br>
                        <span className="prod_subtitle" style={{ fontSize: 13 }}>{t("Text")}: </span><span className="newsAndFeedbackSubtitle">{data.text}</span><br></br>
                    </div>
                    <div className='col-1'>
                     <BsChatSquareText size={18} onClick={() => props.handleOnConversationButtonClick(data.feedbackId, i)} />
                    </div>
                </div>} */}
            </div>
            )) :
            <div className="m-3">{t("No feedback yet")}</div>
    )
}

export default LatestFeedbacks;