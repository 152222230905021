import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';


export const getFieldAiDataAction = createAsyncThunk(
    "account/getFieldAiDataAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/services/openai/completions`,
                arg
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getAItranslation = createAsyncThunk(
    "account/getAItranslation",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/services/aitranslate`,
                arg.body
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);
