import React, { useEffect, useState } from 'react'
import appConfig from "src/helper/appConfig";
import { useSelector, shallowEqual } from 'react-redux';
import { Spinner } from 'src/components/Spinner'
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

const GoogleMapComponents = (props) => {
    const { googleMarkers, height,width, zoom } = props

    const analyticsState = useSelector((state) => state.analytics, shallowEqual);
    /****@satelite_types */
    // Roadmap: 'roadmap'
    // Satellite: 'satellite'
    // Terrain: 'terrain'
    // Hybrid: 'hybrid'

    const defaultProps = {
        center: { lat: Number(googleMarkers[0]?.lat), lng: Number(googleMarkers[0]?.lng) },
        zoom: zoom ? zoom : 5,
        gesture: "greedy",
    };

    if (analyticsState?.clickStreamEntries) {
        return <Spinner />
    }

    return (
        <div style={{ height: height ? height : "100vh", width:width?width: '100%' }}>
            <APIProvider apiKey={appConfig.googleMapApiKey}>
                {googleMarkers && googleMarkers.length !== 0 &&
                    <Map
                        style={{ width: '100%', height: '100%' }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        clickableIcons={true}
                        mapTypeControl
                        zoomControl
                    >
                        {
                            googleMarkers && googleMarkers.map((item, index) => {
                                return <Marker position={item} key={index} title={item?.text} />
                            })
                        }

                    </Map>}
            </APIProvider>
        </div >
    )
}

export default GoogleMapComponents