export const Keys = {
  AUTH_STATE: "AUTH_STATE",
  DEVICE_ID: "DEVICE_ID",
  REFRESH_TOKEN: "REFRESH_TOKEN"
}


/**
 * Retrive an object from local storage.
 * @param  string key
 * @return mixed
 */
export function localStorageGet(key) {
  var item = localStorage.getItem(key);

  if (!item) return;

  if (item[0] === "{" || item[0] === "[") return JSON.parse(item);

  return item;
}

/**
 * Save some value to local storage.
 * @param string key
 * @param string value
 */
export function localStorageSave(key, value) {
  if (value === undefined) console.log("Can't store undefinded value");

  if (typeof value == "object" || typeof value == "array") {
    value = JSON.stringify(value);
  }

  if (typeof value !== "string")
    console.log("Can't store unrecognized format value");

  localStorage.setItem(key, value);
}

/**
 * Remove element from local storage.
 * @param string key
 */
export function localStorageRemove(key) {
  localStorage.removeItem(key);
}




/**
 * @param {string} key 
 * @param {any} value 
 * @param {milliseconds} expiryMillisecondTime 
 */
export const localStorageSetWithExpiry = (key, value, expiryMillisecondTime = 604800000) => {
  const now = new Date()

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    data: value,
    expiry: now.getTime() + expiryMillisecondTime,
  }
  localStorage.setItem(key, JSON.stringify(item))
}



/**
 * @param {string} key 
 * @returns {any}
 */
export const localStorageGetWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key)
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key)
    return null
  }
  return item.data
}