import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';
import { getProductFeedback, getFeedbackConversations, replyToFeedback } from 'src/redux/feedback/feedbackThunk';
import { getUpidsProductScanCount, getClickstreamTotalCount } from 'src/redux/analytics/analyticsThunk';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FeedbackCard } from "src/components/FeedbackCard"
import { Spinner } from "src/components/Spinner";
import { InputTextWithLabel } from 'src/components/inputComponents/InputTextWithLabel';
import { useTranslation } from 'react-i18next';
import LineGraph from '../../../analytics/LineGraph';
import Modal from "react-bootstrap/Modal";
import { StCloseButton } from 'src/components/StCloseButton';
import { usePrevious } from '../../../../helper/CustomHook';
import { AppContext } from '../../../AppContext';
import { MdPerson, MdLaptopMac, MdSend } from "react-icons/md";
import DatePicker from "react-datepicker";
import objectPath from "object-path";
import moment from 'moment';
import _ from "lodash";

export const Feedback = () => {
  const dispatch = useDispatch();
  const context = useContext(AppContext);
  const { t, i18n } = useTranslation('common');

  const productState = useSelector(
    (state) => (state.products),
    shallowEqual
  );
  const productData = productState.formObject;
  
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showFeedbackReplySuccessMsg, setShowFeedbackReplySuccessMsg] = useState(false)
  const [showConversationsView, setShowConversationsView] = useState(false)
  const [feedbackReplyText, setFeedbackReplyText] = useState('')

  const [dateRange, setDateRange] = useState({
    start: moment().subtract(30, "days").toJSON(),
    end: moment().toJSON()
  })


  const elementRef = useRef();

  useEffect(() => {
    dispatch(getProductFeedback({ gtin: objectPath.get(productData, 'gtin'), _2an: objectPath.get(productData, '_2an') }))
  }, []);

  useEffect(() => {
    let queryParams;
    let gtin = objectPath.get(productData, 'gtin');
    let _2an = objectPath.get(productData, '_2an');

    if(gtin && _2an){
      queryParams = `?gtin=${gtin}&_2an=${_2an}`;
    }else if(gtin){
      queryParams = `?gtin=${gtin}`;
    }else{
      queryParams = `?_2an=${_2an}`;
    }
    
    dispatch(getUpidsProductScanCount(queryParams))
    dispatch(getClickstreamTotalCount(queryParams));

  }, []);


  const _state = useSelector(
    (state) => (state.feedback),
    shallowEqual
  );

  const _state1 = useSelector(
    (state) => (state.analytics),
    shallowEqual
  );

  const { feedbackConversationsResponse } = _state;
  const prevPropsState = usePrevious({ feedbackConversationsResponse });

  useEffect(() => {
    if (prevPropsState) {
      if (prevPropsState.feedbackConversationsResponse !== feedbackConversationsResponse && feedbackConversationsResponse) {
        setFeedbackReplyText("")
        //  window.scrollTo(0, elementRef.current.scrollHeight);
        // elementRef.current.scrollHeight({ behavior: "smooth" });
      }
    }
  }, [_state.feedbackConversationsResponse]);

  // Convert epoch to date
  const convertEpoch = (epoch) => {
    var d = new Date(0);
    d.setUTCSeconds(epoch);
    return (
      d.getUTCDate() + "." + (d.getUTCMonth() + 1) + "." + d.getUTCFullYear()
    );
  };

  // Convert YearMonthDate formatted timestamp
  const convertTimestamp = (timestamp) => {
    const dateArray = timestamp.split(/^(\d{4})(\d{2})(\d{2})/);
    return dateArray[3] + "." + dateArray[2] + "." + dateArray[1];
  };

  const getScansAndErrorsArray = () => {
    const startDate = moment(dateRange.start).format("YYYYMMDD")
    const endDate = moment(dateRange.end).format("YYYYMMDD")

    let lineGraphData = [];
    //create data model for nivo line graph
    lineGraphData = [
      {
        id: t("Scans"),
        data: [],
      },
      // {
      //   id: t("Errors"),
      //   data: [],
      // },
    ];
    lineGraphData.map((o, i) => {
      _.forEach(_state1.analyticsClickstreamTotalCount, function (e) {
        let timestamp = 0;
        if (startDate <= e.timestamp && e.timestamp <= endDate) {
          if (String(e.timestamp).length > 8) {
            timestamp = convertEpoch(e.timestamp).split(" ")[0];
          } else {
            timestamp = convertTimestamp(e.timestamp);
          }
          o.data.push({
            x: timestamp,
            y: e.scans,
          });
        }
      });
    });
    return lineGraphData
  }

  const handleUpdateGraphDateRange = (date, type) => {
    setDateRange({
      ...dateRange,
      [type]: moment(date).toJSON()
    })
  }

  const sendReplyToFeedback = () => {
    const lastConversationMessage = _.last(feedbackConversationsResponse);
    const feedback = _state.productFeedbacks[selectedIndex]


    const feedbackData = {
      "previousFeedbackText": lastConversationMessage.text,
      // "userEmail": feedback.email,
      "productName": feedback.productName,
      "feedbackId": feedback.feedbackId,
      "emailText": feedbackReplyText,
      "sourceUrl": feedback.sourceUrl,
      "businessIdentifiers": feedback.feedbackToBusinessId,
      "language": feedback.language

    }
    dispatch(replyToFeedback(feedbackData))
  }

  const handleOnConversationButtonClick = (feedbackId, index) => {
    setSelectedIndex(index)
    setShowConversationsView(true)
    dispatch(getFeedbackConversations(feedbackId))
  }

  const handleWriteMessage = (e) => {
    setFeedbackReplyText(e.target.value)
  }


  return (
    <>
      {(_state.isLoading || _state1.actionsLoading) ? <Spinner /> :
        <div>
          {_state1.upidsProductScanCount && _state.productFeedbacks &&
           <div className="row mb-4">
            {_state1.upidsProductScanCount.count == 0 ?
                          <div className="col-12 product-profile passive-message">{t("This product has not scanned yet")}</div> :
              <div className="col-12 product-profile feedback-count">{t("Total Scans")} {_state1.upidsProductScanCount.count}</div> 
            }
            {_state.productFeedbacks.length > 0 ? 
            <div className="mb-4">
              <span className="product-profile feedback-count">{t("Total Feedbacks")} {_state.productFeedbacks.length}</span>
            </div> : 
            <div className="mb-4">
            <span className="product-profile passive-message">{t("This product has not feedbacks yet")}</span>
          </div>}
          </div>}
          {_state.productFeedbacks && _state.productFeedbacks.length > 0 ?
            <div className='row'>
              <div style={{ overflowY: 'scroll', height: 500, borderRight: '1px solid #DFEDF5' }} className="col-5">
                {_state.productFeedbacks.map((o, index) => (
                  <div key={index} className='mt-2'>
                    <FeedbackCard
                      // feedbackEmail={!!context.authState && context.authState.level === 10 ? !!o.email && o.email !== "" ? o.email : t("Anonymous") : t("Anonymous")}
                      feedbackEmail={t("Anonymous")}
                      feedbackData={o.text}
                      showTooltip={context.authState.level === 10 ? true : false}
                      showTooltipText={o.email ? o.email : t("Anonymous")}
                      onClick={() => setSelectedIndex(index)}
                      selected={index == selectedIndex ? true : false}
                      onConversationButtonClick={() => handleOnConversationButtonClick(o.feedbackId, index)}
                    ></FeedbackCard>
                    {index == selectedIndex && showFeedbackReplySuccessMsg && <p style={{ color: '#50C878' }}>{t('Feedback response submitted')}</p>}
                  </div>
                ))}
              </div>

              { _state.productFeedbacks && _state.productFeedbacks.length > 0 &&
                <div className="col-7">
                  <InputTextWithLabel isSmallScreen={true}
                    label={t("UPIDS")}
                    readOnly={true}
                    value={_state.productFeedbacks[selectedIndex].articleIdentifier}
                    type="text"
                    placeholder={t("UPIDS")}
                  ></InputTextWithLabel>

                  <InputTextWithLabel isSmallScreen={true}
                    label={t("_Business Id")}
                    readOnly={true}
                    value={_state.productFeedbacks[selectedIndex].feedbackToBusinessId[0].id}
                    type="text"
                    placeholder={t("_Business Id")}
                  ></InputTextWithLabel>

                  <InputTextWithLabel isSmallScreen={true}
                    label={t("_Product Name")}
                    readOnly={true}
                    value={_state.productFeedbacks[selectedIndex].productName}
                    type="text"
                    placeholder={t("_Product Name")}
                  ></InputTextWithLabel>

                  <InputTextWithLabel isSmallScreen={true}
                    label={t("Date")}
                    readOnly={true}
                    value={moment.unix(_state.productFeedbacks[selectedIndex].timestamp).format("YYYY-MM-DD HH:mm:ss")}
                    type="text"
                    placeholder={t("Date")}
                  ></InputTextWithLabel>

                  <InputTextWithLabel isSmallScreen={true}
                    label={t("Text Analytics")}
                    readOnly={true}
                    value={_state.productFeedbacks[selectedIndex].textAnalytics.score}
                    type="text"
                    placeholder={t("Date")}
                  ></InputTextWithLabel>

                </div>
              }
            </div>
            :
            <span className="product-profile passive-message">{t("No feedbacks found")}</span>
          }
        </div>

      }
      {!!_state1.analyticsClickstreamTotalCount && _state1.analyticsClickstreamTotalCount.length > 0 &&
        <div className='feedbacktab__graph'>
          <div className='feedbacktab__graph__date'>
            <div className='feedbacktab_graph__date__picker'>
              <span>{t("Start date")}</span>
              <DatePicker
                id="startDatePicker"
                dateFormat="YYYY-MM-dd"
                selected={new Date(dateRange.start)}
                onChange={(date) => handleUpdateGraphDateRange(date, "start")}
              />
            </div>
            <div className='feedbacktab_graph__date__picker'>
              <span>{t("End date")}</span>
              <DatePicker
                id="endDatePicker"
                dateFormat="YYYY-MM-dd"
                selected={new Date(dateRange.end)}
                onChange={(date) => handleUpdateGraphDateRange(date, "end")}
              />
            </div>
          </div>
          <LineGraph lineData={getScansAndErrorsArray()} />
        </div>
      }

      <Modal scrollable show={showConversationsView} onHide={() => setShowConversationsView(false)} centered={true}>
        <Modal.Header style={{ height: 40 }}>
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => setShowConversationsView(false)}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body>
          {_state.conversationLoading ? <Spinner /> :
            <Fragment>
              <div ref={elementRef}>
                {!!feedbackConversationsResponse && feedbackConversationsResponse.map((data, i) => (
                  data.feedbackConversationFrom == "PDM_System" ?
                    <div className='d-flex align-items-center' key={i}>
                      <div><MdLaptopMac fontSize={26} style={{ color: '#6489a0' }} /></div>
                      <div style={{ backgroundColor: "#f8fcfd", color: '#6489a0', margin: 5, width: '60%', borderRadius: 20, padding: 5, border: '1px solid rgb(223, 237, 245' }}>
                        <span>{data.text}</span><br></br>
                        <span style={{ fontSize: 11 }}>{moment.unix(data.timestamp).format("YYYY-MM-DD hh:mm:ssA")}</span>
                      </div>
                    </div> :
                    <div className='d-flex justify-content-center align-items-center'  key={i}>
                      <div style={{ backgroundColor: "#f8fcfd", color: '#6489a0', margin: 5, width: '60%', borderRadius: 20, padding: 5, marginLeft: '40%', border: '1px solid rgb(223, 237, 245' }}>
                        <span>{data.text}</span><br></br>
                        <span style={{ fontSize: 11 }}>{moment.unix(data.timestamp).format("YYYY-MM-DD hh:mm:ssA")}</span>
                      </div>
                      <div><MdPerson fontSize={26} style={{ color: '#6489a0' }} /></div>
                    </div>
                ))}
              </div>

            </Fragment>
          }
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <div className='product_feedback_modal' style={{ pointerEvents: _state.productFeedbacks && _state.productFeedbacks.length > 0 && _state.productFeedbacks[selectedIndex].emailAvailable ? 'auto' : 'none' }}>
            <div className='form-group  col-lg-11 col-md-11 col-sm-11 col-xs-11'>
              <input type='text' placeholder='Enter something'
                className='product_feedback_modal_reply_input'
                value={feedbackReplyText}
                onChange={(e) => handleWriteMessage(e)}
                onKeyUp={(e) => (e.key === 'Enter' ? sendReplyToFeedback() : null)}
              />
            </div>

            <div className='form-group  col-lg-1 col-md-1 col-sm-1 col-xs-1' style={{ pointerEvents: feedbackReplyText ? 'auto' : 'none' }}>
              {_state.feedbackReplyLoading ?
                <div className="spinner-border text-secondary" role="status">
                  {" "}
                </div> :
                <MdSend className='product_feedback_modal_reply_button' onClick={sendReplyToFeedback} />
              }
            </div>
          </div>
        </Modal.Footer>
      </Modal>

    </>
  )

}




