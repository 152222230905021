import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import objectPath from "object-path";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputSelectDropdown } from 'src/components/inputComponents/InputSelectDropdown';
import { changeFormObject } from 'src/redux/products/productsSlice';
import { AppContext } from 'src/pages/AppContext';
import { getCompany } from 'src/redux/company/companyThunk';

export const BusinessIdentifiers = (props) => {
    const {
        readOnly,
        /** name, value, label, onChange */
    } = props



    const { t } = useTranslation('common');

    const context = useContext(AppContext);
    const dispatch = useDispatch();

    const productState = useSelector((state) => (state.products));
    const _companyState = useSelector((state) => state.company);
    const formObject = productState.formObject // productData === formObject

    const [companySelectData, setCompanySelectData] = useState([]);


    useEffect(() => {
        if (context.authState.level === 10) {
            dispatch(getCompany({}));
        } else {
            let _selectData = [{ key: context.authState.companyId, value: context.authState.companyId, text: context.authState.companyName }]
            setCompanySelectData(_selectData);
        }
        /**
         * @Validation IMPORTANT
         */
        // let tabNameFields = gettabNameFields();
        // yepSchema = tabNameFields.reduce(createYupSchema, {}, 'Identifier');
    }, []);

    useEffect(() => {
        if (!!_companyState.companyList && _companyState.companyList.length > 0) {
            if (context.authState.level === 10) {
                createDropdownDataCompany(_companyState.companyList);
                getCompanyId();
            }
        }
    }, [_companyState.companyList]);

    const createDropdownDataCompany = (companyList) => {
        let _compList = [];
        companyList.map((o, index) => {
            let eve = { key: index, text: o?.name || o?.id, value: o.id };
            _compList.push(eve);
        });
        setCompanySelectData(_compList)
    };

    const getCompanyId = () => {
        let _id = { id: 0 };
        _companyState.companyList.map((o) => {
            let companyBIds = o.businessIdentifiers
            let productBids = productState.formObject.businessIdentifiers

            let isProductAssociatedWithManufacturer = checkProductAssociatedWithManufacturer(productBids, companyBIds)

            if (isProductAssociatedWithManufacturer) {
                return _id = o;
            } else {
                return _id;
            }

        })
        dispatch(changeFormObject({ ...formObject, companyId: _id.id }))
    }


    const checkProductAssociatedWithManufacturer = (productBids, companyBIds) => {
        if (!productBids || !productBids[0]) {
            return;
        }
        return productBids.find(productBidObject => {
            return companyBIds.find(companyBidObject => {
                return productBidObject.id === companyBidObject.id;
            });
        });
    }


    const getProductBusinessIdentifier = () => {
        if (_.get(productState, "formObject.businessIdentifiers[0].id", "")) {
            let productBusinessIdentifier = objectPath.get(_.find(productState.formObject.businessIdentifiers, function (t) { return t.type === "VATID" }), 'id', productState.formObject.businessIdentifiers[0].id)
            return productBusinessIdentifier
        }
    }

    const getProductBusinessIdentifiers = () => {
        let productBusinessIdentifiers = []
        _.get(productState, "formObject.businessIdentifiers", []).map((data, i) => {
            productBusinessIdentifiers.push({ key: i, text: data.id, value: data.id })
        })
        return productBusinessIdentifiers
    }

    const handleCompanySelect = (e) => {
        const value = e.target.value
        let _newBid = (_.find(_companyState.companyList, function (o) { return o.id === value })).businessIdentifiers;
        dispatch(changeFormObject({ ...formObject, companyId: value, businessIdentifiers: _newBid, isChange: 1 }))

    }


    const formValue = context.authState.level === 1 ? context.authState.companyId : productState.formObject.companyId == 0 ? getProductBusinessIdentifier() : productState.formObject.companyId;
    const formOption = productState.formObject.companyId == 0 && context.authState.level !== 1 ? getProductBusinessIdentifiers() : companySelectData


    return (
        <InputSelectDropdown
            key={"companyId"}
            label={t("_Company")}
            tooltipText={"Company"}
            placeholder={t("Select Company Name")}
            name="companyId"
            search={true}
            value={formValue}
            options={formOption}
            onChange={handleCompanySelect}
            readOnly={true}
            errorMsg={productState.formObject.companyId == 0 ? t("No match found") : ""}
        />
    )
}
