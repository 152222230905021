import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { AppContext } from '../../AppContext';
import { Collapse } from "react-bootstrap";
import { FaCaretRight, FaCaretDown, FaHeadphones } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import SupportOutsideCheck from "../../login/component/SupportOutsideCheck";
import { sidebarJson } from "./menue";
import { useLocation } from "react-router-dom";


const Sidebar = (props) => {
  const { t, i18n } = useTranslation('common');
  const location = useLocation();
  const supportCardRef = useRef();
  const supporTextRef = useRef();


  const [state, setState] = useState({});
  const [showSupport, setShowSupport] = useState(false);

  SupportOutsideCheck(supportCardRef, supporTextRef, setShowSupport);

  const context = useContext(AppContext);

  function toggleMenuState(menuState) {
    if (state[menuState]) {
      setState({ [menuState]: false });
    } else if (Object.keys(state).length === 0) {
      setState({ [menuState]: true });
    } else {
      Object.keys(state).forEach((i) => {
        setState({ [i]: false });
      });
      setState({ [menuState]: true });
    }
  }

  const handleLangChange = (e) => {
    i18n.changeLanguage(e.target.value);
  }

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  useEffect(() => {
    onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
          // if (el.querySelector('.ReactCollapse--collapse')) {
          //   el.querySelector('.ReactCollapse--collapse').setAttribute('style', 'height: auto; overflow: initial;');
          // }
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
          // if (el.querySelector('.ReactCollapse--collapse')) {
          //   el.querySelector('.ReactCollapse--collapse').setAttribute('style', 'height: 0; overflow: hidden;');
          // }
        }
      });
    });

    // for showing language dropdown selected value
    // document.getElementById(i18n.language).selected = true;
  }, [])

  function onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(state).forEach((i) => {
      setState({ [i]: false });
    });
    const dropdownPaths = [{ path: "/Multilevel", state: "appsMenuOpen" }];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setState({ [obj.state]: true });
      }
    });
  }

  function isPathActive(path) {
    return location.pathname.startsWith(path);
  }

  const isPathActiveFromList = (menueItem) => {
    let isActive
    if (menueItem && menueItem.subMenu && menueItem.subMenu[0]) {
      let acitvePathList = []
      menueItem.subMenu.forEach((item) => {
        acitvePathList = acitvePathList.concat(item.acitvePathList)
      })

      acitvePathList.forEach((path) => {
        if (isActive !== true) {
          isActive = location.pathname.startsWith(path);
        }
      })
    }
    else {
      const acitvePathList = menueItem.acitvePathList
      acitvePathList.forEach((path) => {
        if (isActive !== true) {
          isActive = location.pathname.startsWith(path);
        }
      })
    }

    return isActive || false
  }



  return (
    <div>
      <nav style={{ paddingLeft: 0, zIndex: 999 }} className="sidebar sidebar-offcanvas" id="sidebar">
        <ul style={{ marginTop: 70, height: "calc(100vh - (72px + 70px))", overflow: "auto" }} className="nav" >

          {sidebarJson.map((menuItem, index) => {
            let isRouteAvailableForUser
            if (menuItem.requiredRoles.includes("anyAuth")) {
              isRouteAvailableForUser = true
            } else {
              isRouteAvailableForUser = menuItem.requiredRoles?.includes(context.authState.level)
            }
            if (!isRouteAvailableForUser) {
              return <></>
            }
            if (menuItem.subMenu) {
              return (
                <li key={index} className={isPathActiveFromList(menuItem) ? "nav-item active" : "nav-item"}  >
                  <div
                    className={state.basicUiMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                    onClick={() => toggleMenuState("basicUiMenuOpen")}
                    data-toggle="collapse"
                  >
                    <span style={{ size: 16, color: isPathActiveFromList(menuItem) ? "#cf2948" : "#ffffff" }}>
                      {menuItem.icon}
                    </span>
                    <span className="menu-title ms-3">
                      {t(menuItem.label)}
                    </span>

                    {state.basicUiMenuOpen
                      ? <FaCaretDown color={isPathActiveFromList(menuItem) ? "#cf2948" : "#ffffff"} size={18} />
                      : <FaCaretRight color={isPathActiveFromList(menuItem) ? "#cf2948" : "#ffffff"} size={18} />}
                  </div>
                  <Collapse in={state.basicUiMenuOpen}>
                    <div>
                      <ul className="nav" style={{ paddingBottom: "0rem" }}>
                        {menuItem.subMenu.map((nestedItem, i) => (
                          <li key={`${nestedItem.key}_${i}`} className={isPathActiveFromList(nestedItem) ? "nav-item active" : "nav-item"}>
                            <Link className="nav-link" to={nestedItem.path}>
                              <span style={{ size: 16, color: isPathActiveFromList(nestedItem) ? "#cf2948" : "#ffffff" }}>
                                {nestedItem.icon}
                              </span>
                              <span className="menu-title ms-3">
                                {t(nestedItem.label)}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Collapse>
                </li>
              )
            }


            return (
              <li key={`${menuItem.key}_${index}`} className={isPathActiveFromList(menuItem) ? "nav-item active" : "nav-item"}>
                <Link className="nav-link" to={menuItem.path}>
                  <span style={{ size: 16, color: isPathActiveFromList(menuItem) ? "#cf2948" : "#ffffff" }}>
                    {menuItem.icon}
                  </span>
                  <span className="menu-title ms-3">
                    {t(menuItem.label)}
                  </span>
                </Link>
              </li>

            )
          })}



          <li className={"nav-item"}>
            <div ref={supporTextRef} className="nav-link" onClick={() => setShowSupport(!showSupport)}>
              <FaHeadphones size={16} color={isPathActive("/support") ? "#cf2948" : "#ffffff"} />
              <span className="menu-title ms-3">
                {t("Support")}
              </span>
            </div>
          </li>

          <li>
            <div className="language-dropdown">
              <select value={i18n.language} onChange={handleLangChange} className="form-select" aria-label="Default select example">
                <option id="en" value="en">English</option>
                <option id="fi" value="fi">Finnish</option>
              </select>
            </div>
          </li>
        </ul>
      </nav>


      <div ref={supportCardRef} className={!showSupport ? " support-popup-hide" : " support-popup-show"}
        style={{ zIndex: 1 }}>
        <p className="support-title">{t('Happy to Help')}</p>
        <div style={{ marginTop: 20 }}>
          <p className="support-subtitle">{t('EMAIL US')}</p>
          <p className="support-text">support@secondthought.fi</p>
        </div>
        <div style={{ marginTop: 20 }}>
          <p className="support-subtitle">{t('Call Us')}</p>
          <p className="support-text">+358 45 7832 7251</p>
        </div>
      </div>
    </div>
  );




}

export default Sidebar;
