import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDataByUidAction } from "src/redux/users/usersThunk";
import { usersAllErrorsEditFormObject, currentUserFormObject, changeUserEditModeAction } from "src/redux/users/usersSlice";
import { getCompany } from 'src/redux/company/companyThunk';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getAllTimeZones } from "src/helper/timezones";
import AddUserImage from "../../../assets/images/addUser.png";
import { Spinner } from "src/components/Spinner";
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel";
import { InputSelectDropdown } from "src/components/inputComponents/InputSelectDropdown";
import { InputSwitchWithLable } from "src/components/inputComponents/InputSwitchWithLable";
import { userLevelOptions, userRoleOptions } from "../../../helper/MetadataCollection";
import { Dropdown } from "semantic-ui-react";
import { userEditSchema } from "src/helper/yup-schema";
import * as yup from "yup";
import _ from 'lodash'
import { AllErrorsEditFormObject } from "src/redux/company/companySlice";


const ViewUser = () => {
  let { uid } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  let timezoneValue;
  let BusinessIdentifiers;
  let selectTimezoneOption = [];
  let selectCompanyOption = [];
  const allTimezones = getAllTimeZones();

  useEffect(() => {
    dispatch(getDataByUidAction({ uid: uid }));
    dispatch(getCompany({}));
    return () => {
      dispatch(changeUserEditModeAction(false));
      dispatch(currentUserFormObject({}));

    };
  }, []);

  const _state = useSelector((state) => state.users, shallowEqual);
  const _companyState = useSelector((state) => state.company, shallowEqual);
  const companyNameList = _companyState.companyList
  const [touchedState, setTouchedState] = useState(false)


  const allViewData = _state.usersList;
  useEffect(() => {
    dispatch(currentUserFormObject(allViewData));
    dispatch(usersAllErrorsEditFormObject([]));
  }, [allViewData]);

  const { currentUserObj, companyInfo } = _state;

  const viewData = _state.currentUserObj;
  let AllErrors = _state.ErrorsformEditObject;

  const createDropdownDataForTimezone = (allTimezones) => {
    selectTimezoneOption = [];
    allTimezones.map((o, index) => {
      let eve = { key: index, text: o.text, value: o.abbr };
      selectTimezoneOption.push(eve);
    });
  };
  createDropdownDataForTimezone(allTimezones);

  const createDropdownDataForCompany = (companyNameList) => {

    if (!!companyNameList && companyNameList.length > 0) {
      companyNameList.map((o, index) => {
        let eve = { key: index, text: o.name, value: o.id };
        selectCompanyOption.push(eve);
      });
    }
  };
  createDropdownDataForCompany(companyNameList);

  const handleFormChange = (e) => {
    validator({ name: e.target.name, value: e.target.value });
    dispatch(
      currentUserFormObject({
        ...viewData,
        [e.target.name]: e.target.value,
      })
    );
    setTouchedState(true)
  };

  const validator = ({ name, value }) => {
    const existingErrorIndex = AllErrors.findIndex(
      (error) => error.path === name
    );
    let newErrors = [...AllErrors];
    if (name === "retypePassword") {
      if (!value) {
        newErrors.push({ path: name, message: "Confirm password is required" });
      } else if (value !== viewData.password) {
        newErrors.push({ path: name, message: "Password must match" });
      } else {
        newErrors = AllErrors.filter((error) => error.path !== name);
      }
      dispatch(usersAllErrorsEditFormObject(newErrors));
    } else {
      yup
        .reach(userEditSchema, name)
        .validate(value)
        .then((valid) => {
          if (newErrors.length > 0) {
            newErrors = newErrors.filter((error) => error.path !== name);
            dispatch(usersAllErrorsEditFormObject(newErrors));
          }
        })
        .catch((err) => {
          if (existingErrorIndex !== -1) {
            newErrors[existingErrorIndex] = {
              path: name,
              message: err.message,
            };
          } else {
            newErrors.push({ path: name, message: err.message });
          }
          dispatch(usersAllErrorsEditFormObject(newErrors));
        });
    }
  };



  const handleFormChangeCompany = (e) => {
    let latestViewData = JSON.parse(JSON.stringify({ ...viewData }));
    latestViewData.companies[0][e.target.name] = e.target.value;
    dispatch(
      currentUserFormObject({
        ...latestViewData,
      })
    );
  };

  const handleTimezoneSelect = (e) => {
    const value = e.target.value
    if (!!value) {
      timezoneValue = allTimezones.find(({ abbr }) => abbr === value);
      dispatch(
        currentUserFormObject({
          ...viewData,
          timezone: timezoneValue.offset.toString(),
          abbr: value,
        })
      );
    }
  };

  const handleCompanySelect = (e) => {
    const value = e.target.value
    if (!!value) {
      let allCompanyInfo = companyNameList.find(e1 => e1.id == value);
      let allBusinessIdentifiers = allCompanyInfo?.businessIdentifiers;
      dispatch(
        currentUserFormObject({
          ...viewData,
          companyId: value,
          companyName: allCompanyInfo?.name,
          companies: allBusinessIdentifiers,
          language: "",
        })
      );
    }
  };

  const handleUserLevelSelect = (e, { value }) => {
    if (!!value) {
      dispatch(currentUserFormObject({ ...viewData, userLevel: value }))
    }
  }

  const handleUserRoleSelect = (e, { value }) => {
    if (!!value) {
      dispatch(currentUserFormObject({ ...viewData, userRole: value }))
    } else {
      dispatch(currentUserFormObject({ ...viewData, userRole: 'Not-set' }));
    }
  }

  const toggleChecked = () => {
    dispatch(
      currentUserFormObject({
        ...viewData,
        isAdmin: !viewData.isAdmin,
      })
    );
  };


  const getCurrentComapnyLanguage = (viewData, companyNameList) => {
    let language = [];
    const businessId = viewData && viewData.companyId && viewData.companyId
    const currentCompanyObj = companyNameList && companyNameList[0] && companyNameList.find((item) => item.id === businessId)
    const supportedLanguage = currentCompanyObj && currentCompanyObj.supportedLanguages;
    supportedLanguage && supportedLanguage[0] && supportedLanguage.map((o, index) => {
      let eve = { key: index, label: o.name, value: o.code };
      language.push(eve)
    })
    return language
  }

  const selectCompanyLanguage = getCurrentComapnyLanguage(viewData, companyNameList)

  const handleSupportLanguage = (e) => {
    let value = e.target.value
    if (!!value) {
      let currentLanguage = selectCompanyLanguage.find((item) => item.value === value);
      dispatch(
        currentUserFormObject({
          ...viewData,
          language: currentLanguage.value
        })
      );
    }
  };

  const errorShown = (AllErrors) => {
    const errorObject = {}
    AllErrors &&
      AllErrors[0] &&
      AllErrors.forEach((obj, index) => {
        errorObject[obj.path] = obj.message;
      });
    return errorObject
  };




  const errorObject = errorShown(AllErrors);

  return (
    <>
      {_state.isGetDataByUidLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="row add-user main-container">
          <div className="add-user sidebar-container col-xl-3 col-xxl-4 col-md-4 mb-5" >
            <img alt="alt"
              src={AddUserImage}
              className="add-user profile-image"
            ></img>
            <span className="add-user profile-name">{viewData && viewData.foreName + " " + viewData.sureName}</span>
            <span className="add-user role-title" > {t("User Roles")} </span>
            <div className="add-user permission-card" >
              <div className="d-flex">
                <span className="add-user role-subtitle col-7">{t("Admin")}</span>
                <div className="mt-1 col-5 d-flex justify-content-end" style={{ right: 45 }}>
                  <InputSwitchWithLable
                    value={currentUserObj && currentUserObj.isAdmin}
                    checked={viewData && viewData.isAdmin === true ? true : false}
                    onHandleColor='#32cd32'
                    offHandleColor='#ffffff'
                    onColor='#32cd32'
                    offColor='#6c757d'
                    onChange={toggleChecked}
                    disabled={_state.isUserEditMode ? false : true}
                  />
                </div>
              </div>
              <span className="add-user description-text">
                {t("Allow Admin Access")}
              </span>
            </div>

            <span className="add-user role-title" > {t("User Levels")} </span>
            <div className="d-flex m-2">
              <Dropdown
                selection fluid
                value={!!viewData && viewData.userLevel}
                onChange={handleUserLevelSelect}
                options={userLevelOptions}
                placeholder={t("Select User Level")}
                disabled={_state.isUserEditMode ? false : true}
              />
            </div>

            <span className="add-user role-title" > {t("User role")} </span>
            <div className="d-flex m-2">
              <Dropdown
                value={!!viewData && viewData.userRole}
                selection fluid
                onChange={handleUserRoleSelect}
                options={userRoleOptions}
                placeholder={t("Select user role")}
                disabled={_state.isUserEditMode ? false : true}
              />
            </div>

          </div>

          <div className="add-user form-container col-xl-9 col-xxl-8 col-md-8">
            {viewData && (
              <form >
                <div className="row col-12">
                  <InputTextWithLabel
                    label={t('Email Address')}
                    tooltipText={t("Email Address")}
                    readOnly={true}
                    name={'email'}
                    value={viewData.email}
                    type="text"
                    placeholder={t("Email")}
                  ></InputTextWithLabel>


                  <InputTextWithLabel
                    label={t('First Name')}
                    tooltipText={t("First Name")}
                    readOnly={_state.isUserEditMode ? false : true}
                    name={'foreName'}
                    value={viewData.foreName}
                    type="text"
                    placeholder={t("First Name")}
                    onChange={handleFormChange}
                    touched={touchedState}
                    errorMessage={!!errorObject && errorObject.foreName ? errorObject.foreName : ""}
                  ></InputTextWithLabel>


                  <InputTextWithLabel
                    label={t('Last Name')}
                    tooltipText={t("Last Name")}
                    readOnly={_state.isUserEditMode ? false : true}
                    name={'sureName'}
                    value={viewData.sureName}
                    type="text"
                    placeholder={t("Last Name")}
                    onChange={handleFormChange}
                    touched={touchedState}
                    errorMessage={!!errorObject && errorObject.sureName ? errorObject.sureName : ""}
                  ></InputTextWithLabel>


                  <InputSelectDropdown
                    label={t("Company")}
                    tooltipText={"Company"}
                    placeholder={t("Select Company Name")}
                    search={true}
                    value={viewData.companyId}
                    disabled={_state.isUserEditMode ? false : true}
                    options={selectCompanyOption}
                    onChange={handleCompanySelect}
                  />


                  <InputTextWithLabel
                    label={t('Phone')}
                    tooltipText={t("Phone")}
                    readOnly={_state.isUserEditMode ? false : true}
                    name={'phoneNumber'}
                    value={viewData.phoneNumber}
                    type="text"
                    placeholder={t("Phone")}
                    onChange={handleFormChange}
                    touched={touchedState}
                    errorMessage={!!errorObject && errorObject.phoneNumber ? errorObject.phoneNumber : ""}
                  ></InputTextWithLabel>


                  <InputSelectDropdown
                    label={t("Timezone")}
                    tooltipText={"Timezone"}
                    placeholder={t("Select Timezone")}
                    search={true}
                    value={viewData.abbr}
                    disabled={_state.isUserEditMode ? false : true}
                    options={selectTimezoneOption}
                    onChange={handleTimezoneSelect}
                  />


                  <InputSelectDropdown
                    label={t("Communication language")}
                    tooltipText={t("Communication language")}
                    placeholder={t("Select Language")}
                    search={true}
                    value={viewData.language}
                    disabled={_state.isUserEditMode ? false : true}
                    options={selectCompanyLanguage && selectCompanyLanguage}
                    onChange={handleSupportLanguage}
                  />


                  <InputTextWithLabel
                    label={t('New Password')}
                    tooltipText={t("New password")}
                    readOnly={_state.isUserEditMode ? false : true}
                    name={'password'}
                    value={viewData.password}
                    type="text"
                    placeholder={t("New Password")}
                    onChange={handleFormChange}
                    touched={touchedState}
                    errorMessage={!!errorObject && errorObject.password ? errorObject.password : ""}
                  ></InputTextWithLabel>


                  <InputTextWithLabel
                    label={t('Retype Password')}
                    tooltipText={t("Retype Password")}
                    readOnly={_state.isUserEditMode ? false : true}
                    name={'retypePassword'}
                    value={viewData.retypePassword}
                    type="text"
                    placeholder={t("Retype Password")}
                    onChange={handleFormChange}
                    touched={touchedState}
                    errorMessage={!!errorObject && errorObject.retypePassword ? errorObject.retypePassword : ""}
                  ></InputTextWithLabel>


                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ViewUser;
