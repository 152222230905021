import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeFormObject, AllErrorsFormObject } from "src/redux/company/companySlice";
import * as companyActions from "../../../redux/company/companyActions";
import AddIcon from "../../../assets/images/Add.png";
import { MdAddCircleOutline, MdClose } from 'react-icons/md';
import ManufacturerLogo from '../../../assets/images/ManufacturerLogo.png'
import { IoAddCircleOutline } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Dropdown } from "semantic-ui-react";
import countryCodes from "src/helper/countryCodes";
import { LanguageCode, EULanguageCodes } from 'src/helper/MetadataCollection';
import { useTranslation } from "react-i18next";
import * as yup from "yup";


import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from "src/components/StSquareButton";
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel";
import { IconButton } from 'src/components/IconButton';
import { StCancelSquareButton } from 'src/components/StCancelSquareButton'
import { InputSelectDropdown } from "src/components/inputComponents/InputSelectDropdown";
import { Badges } from 'src/components/Badges'
import { UrlCard } from "src/components/UrlCard";
import { BusinessIdCard } from "src/components/BusinessIdCard";
import { InputSwitchWithLable } from "src/components/inputComponents/InputSwitchWithLable";
import _ from 'lodash'
import { useStateCallback } from "../../../helper/CustomHook";
import { companySchema } from "src/helper/yup-schema";
import FormFieldSchemaRender from "src/components/schemaRender/FormFieldSchemaRender";
import { privacyAddendunFormSchema } from "src/helper/helpers";


const ecommerceIntegrationTypes = [
  { key: 1, text: 'woocommerce', value: 'woocommerce' },
  { key: 1, text: 'shopify', value: 'shopify' },
  { key: 1, text: 'amazon', value: 'amazon' },
  { key: 1, text: 'ecwid', value: 'ecwid' },
]
const amazonMarketPlaceRegions = [
  { key: 1, text: 'USA', value: 'USA' },
]
const billedForOptions = [
  { key: 1, text: 'Events', value: 'events' },
  { key: 2, text: 'Products', value: 'products' },
]
const billingTypeOptions = [
  { key: 1, text: 'Post-charge', value: 'post-charge' },
  { key: 2, text: 'Prepaid', value: 'prepaid' },
]

const regionsOptions = [
  { key: 1, text: 'EU', value: 'eu' },
  { key: 2, text: 'Non-EU', value: 'non-eu' },
]





const AddCompany = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [generateKey, setGenerateKey] = useState("");
  const [businessIdModelShow, setBusinessIdModelShow] = useState(false);
  const [ecomModelShow, setEcomModelShow] = useState(false);
  const [show, setShow] = useState(false);
  const [recipientEmailsShow, setRecipientEmailsShow] = useState(false);
  const [recipientEmail, setRecipientEmail] = useState();
  const [modelList, setModelList] = useState([]);
  const [datapoolModalShow, setDatapoolModalShow] = useState(false);
  const [datapoolId, setDatapoolId] = useState();
  const [AddAllEULangChecked, setAddAllEULangChecked] = useStateCallback(false);
  const [touchedState, setTouchedState] = useState(true)
  const [businessIdInputWarrning, setBusinessIdInputWarrning] = useState();
  const [disableButtonHandler, setDisableButtonHandler] = useState(true);
  const [warnningHandler, setWarningHandler] = useState(false);

  let selectCountryCodeOption = [];
  const [state, setState] = useState({
    businessIdInputValue: "",
    businessIdTypeValue: "",
    integrationType: 'woocommerce',

  })
  const [ecomState, setEcomState] = useState({
    ecomUrl: "",
    ecomConsumerKey: "",
    ecomConsumerSecret: "",
    storeName: "",
    apiKey: "",
    apiSecretKey: "",
    accessToken: "",
    url: "https://www.amazon.com",
    sellerId: "",
    marketPlaceRegion: "",
    marketPlaceId: "",
    ecwidUrl: "https://app.ecwid.com",
    secretToken: "",
    storeId: ""
  })
  let errorObject = {};

  const _state = useSelector((state) => state.company, shallowEqual);
  let formObject = _state.formObject;

  let AllErrors = _state.ErrorsformObject;

  useEffect(() => {
    return () => {
      dispatch(changeFormObject({}));
      dispatch(AllErrorsFormObject([]));
    };
  }, []);

  const isVatidAlreadyExists = !!formObject.businessIdentifiers && formObject.businessIdentifiers.find(({ type }) => type === 'VATID') ? true : false;

  const businessIdTypeOptions = [
    {
      key: 1,
      text: 'GLN',
      value: 'GLN',
    },
    {
      key: 2,
      text: 'VATID',
      value: 'VATID',
      disabled: isVatidAlreadyExists
    },
    {
      key: 3,
      text: 'CFPID',
      value: 'CFPID',
    },
  ]

  const createDropdownDataForCountryCode = (countryCodes) => {
    selectCountryCodeOption = [];
    countryCodes.map((o, index) => {
      let allCodes = {
        key: index, text: `(${o.countryCode})  ${o.name}`, value: o.countryCode, content: (
          <div key={index}>
            <span style={{ display: "block", fontWeight: 'normal', fontSize: 14, }}>{o.name}</span>
            <span style={{ display: "block", fontWeight: 'normal', fontSize: 12, marginTop: 4, color: '#6489A0', }}>{o.countryCode}</span>
          </div>
        ),
      };
      selectCountryCodeOption.push(allCodes);
    });
  };
  createDropdownDataForCountryCode(countryCodes);

  const errorShown = (AllErrors) => {
    AllErrors &&
      AllErrors[0] &&
      AllErrors.map((obj, index) => {
        errorObject[obj.path] = obj.message;
      });
  };


  errorShown(AllErrors);

  const generateAPIKeyHandle = () => {
    const key = generateUUID();
    setGenerateKey(key);
    dispatch(changeFormObject({ ...formObject, apiKey: key }));
  };

  function generateUUID() {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now();
    }

    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }

  const handleFormChange = (e) => {
    if (["name", "address", "contact", "countryCode", "contentItemUrl", "webhookUrl", "webhookKey", "invoicingEmail"].includes(e.target.name)) {
      validator({ name: e.target.name, value: e.target.value });
    }
    if (e.target.name == "priceTable") {
      dispatch(changeFormObject({ ...formObject, priceTable: { ...formObject.priceTable, default: e.target.value } }))
    } else {
      dispatch(changeFormObject({ ...formObject, [e.target.name]: e.target.value }));
    }
    setTouchedState(true)

  };


  const validator = ({ name, value }) => {
    const weburlRegEx = /(https:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
    const existingErrorIndex = AllErrors.findIndex(
      (error) => error.path === name
    );
    let newErrors = [...AllErrors];
    if (name === "contentItemUrl" || name === "webhookUrl") {
      if (!value && formObject?.flagForPolicy) {
        newErrors.push({ path: name, message: "Url is required" });
      }
      else if (value && value !== "" && !weburlRegEx.test(value)) {
        newErrors.push({ path: name, message: "Url should be valid" });
      }
      else if (value && value !== "" && !value.includes("https://")) {
        newErrors.push({ path: name, message: "URL should be secured" });
      }
      else {
        newErrors = AllErrors.filter((error) => error.path !== name);
      }
      dispatch(AllErrorsFormObject(newErrors));
    } else if (name == "webhookKey") {
      if (!value && formObject?.flagForPolicy) {
        newErrors.push({ path: name, message: "Webhook key is required" });
      }
      else {
        newErrors = AllErrors.filter((error) => error.path !== name);
      }
      dispatch(AllErrorsFormObject(newErrors));
    }
    else {
      yup
        .reach(companySchema, name)
        .validate(value)
        .then((valid) => {
          if (newErrors.length > 0) {
            newErrors = newErrors.filter((error) => error.path !== name);
            dispatch(AllErrorsFormObject(newErrors));
          }
        })
        .catch((err) => {
          if (existingErrorIndex !== -1) {
            newErrors[existingErrorIndex] = {
              path: name,
              message: err.message,
            };
          } else {
            newErrors.push({ path: name, message: err.message });
          }
          dispatch(AllErrorsFormObject(newErrors));
        });
    }
  };

  const handleBusinessIdModelClose = () => {
    setBusinessIdModelShow(false);
    setBusinessIdInputWarrning();
    setState({ ...state, businessIdInputValue: "", businessIdTypeValue: "" })

  };
  const handleEcomModelClose = () => {
    setEcomModelShow(false);
    setEcomState({ ...ecomState, ecomUrl: "", ecomConsumerKey: "", ecomConsumerSecret: "", storeName: "", apiKey: "", apiSecretKey: "", accessToken: "", sellerId: "", marketPlaceRegion: "", marketPlaceId: "", })

  };

  const handleDropdownChange = (e, { value }) => {
    setState({ ...state, businessIdTypeValue: value })
    setDisableButtonHandler(true);
    setWarningHandler(false);
    setBusinessIdInputWarrning("");
  }

  const handleEcomIntegrationTypeChange = (e, { value }) => {
    setState({ ...state, integrationType: value })
  }

  function checkSpecialCharactor(input) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(input) ? true : false;
  }

  function businessIdValidation(input) {
    if (state.businessIdTypeValue === 'VATID') {
      if (input.length >= 2) {
        if (isNaN(input.charAt(0)) && isNaN(input.charAt(1))) {
          setBusinessIdInputWarrning();
          let checkSpecialCharFun = checkSpecialCharactor(input);
          if (checkSpecialCharFun) {
            setBusinessIdInputWarrning(t("Special Charactor not allowed"));
            setDisableButtonHandler(true);
            setWarningHandler(true);
          } else {
            setDisableButtonHandler(false);
          }
        } else {
          setDisableButtonHandler(true);
          setWarningHandler(true);
          setBusinessIdInputWarrning(
            t("First two charactor should be Alphabet")
          );
        }
      } else {
        setDisableButtonHandler(true);
        setWarningHandler(false);
        setBusinessIdInputWarrning(t("Should more than 2 charactors"));
      }
    } else if (state.businessIdTypeValue === 'GLN') {
      if (input.length <= 0) {
        setBusinessIdInputWarrning();
        setDisableButtonHandler(true);
        setWarningHandler(true);
      } else {
        if (!Number(input)) {
          setBusinessIdInputWarrning(t("should be Number and special Charactor not allowed"));
          setDisableButtonHandler(true);
          setWarningHandler(true);
        } else {
          setBusinessIdInputWarrning();
          setDisableButtonHandler(false);
          setWarningHandler(false);
        }

      }
    } else if (state.businessIdTypeValue === 'CFPID') {
      if (input.length > 0) {
        setDisableButtonHandler(false);
        setWarningHandler(false);
      } else {
        setDisableButtonHandler(true);
        setWarningHandler(true);
      }
    }
  }

  const handleBusinessIdChange = (e) => {
    businessIdValidation(e.target.value);
    // trim() to remove white spaces from string
    setState({ ...state, businessIdInputValue: e.target.value.trim() })
  }
  const handleEcomChange = (e, data) => {
    // trim() to remove white spaces from string
    if (!!data) {
      setEcomState({ ...ecomState, [data.name]: data.value.trim() })
    } else {
      setEcomState({ ...ecomState, [e.target.name]: e.target.value.trim() })
    }
  }

  const handleAddBusinessId = () => {
    let allBusinessIds = !!formObject.businessIdentifiers ? JSON.parse(JSON.stringify(formObject.businessIdentifiers)) : [];
    allBusinessIds.push({ "type": state.businessIdTypeValue, "id": state.businessIdInputValue });
    dispatch(changeFormObject({ ...formObject, businessIdentifiers: allBusinessIds }));
    AllErrors = _.filter((_state.ErrorsformObject) || [], (d) => (d?.path !== "businessIdentifiers")) || []
    dispatch(AllErrorsFormObject(AllErrors));
    handleBusinessIdModelClose();

  }

  const handleDeleteBusinessId = (index) => {
    let allBusinessIds = !!formObject.businessIdentifiers ? JSON.parse(JSON.stringify(formObject.businessIdentifiers)) : [];
    allBusinessIds.splice(index, 1);
    dispatch(changeFormObject({ ...formObject, businessIdentifiers: allBusinessIds }));
  }

  const handleAddEcomPoints = () => {
    let allEcomPoints = !!formObject.ecomPoints ? JSON.parse(JSON.stringify(formObject.ecomPoints)) : [];
    if (state.integrationType == "woocommerce") {
      allEcomPoints.push({ "ecomUrl": ecomState.ecomUrl, "ecomConsumerKey": ecomState.ecomConsumerKey, "ecomConsumerSecret": ecomState.ecomConsumerSecret, "integrationType": state.integrationType });
    } else if (state.integrationType == "shopify") {
      allEcomPoints.push({ "storeName": ecomState.storeName, "apiKey": ecomState.apiKey, "apiSecretKey": ecomState.apiSecretKey, "accessToken": ecomState.accessToken, "integrationType": state.integrationType, "hostUrl": "https://prod.api.2nd.gdn/v2", });
    } else if (state.integrationType == "amazon") {
      let marketplaceIdsArray = [{ "region": ecomState.marketPlaceRegion, "id": ecomState.marketPlaceId }]
      allEcomPoints.push({ "url": ecomState.url, "sellerid": ecomState.sellerId, "marketplaceids": marketplaceIdsArray, "integrationType": state.integrationType });
    } else if (state.integrationType == "ecwid") {
      allEcomPoints.push({ "ecwidUrl": ecomState.ecwidUrl, "storeId": ecomState.storeId, "secretToken": ecomState.secretToken, "integrationType": state.integrationType })
    } else { }
    dispatch(changeFormObject({ ...formObject, ecomPoints: allEcomPoints }));
    handleEcomModelClose();
    setEcomState({ ...ecomState, ecomUrl: "", ecomConsumerKey: "", ecomConsumerSecret: "", storeName: "", apiKey: "", apiSecretKey: "", accessToken: "", sellerId: "", marketPlaceRegion: "", marketPlaceId: "", ecwidUrl: "", secretToken: "", storeId: "" })

  }

  const handleDeleteEcomPoints = (index) => {
    let allEcomPoints = !!formObject.ecomPoints ? JSON.parse(JSON.stringify(formObject.ecomPoints)) : [];
    allEcomPoints.splice(index, 1);
    dispatch(changeFormObject({ ...formObject, ecomPoints: allEcomPoints }));
  }
  const handleCountryCodeSelect = (e) => {
    const value = e.target.value
    if (!!value) {
      dispatch(
        changeFormObject({
          ...formObject,
          countryCode: value,
        })
      );
      AllErrors = _.filter((_state.ErrorsformObject) || [], (d) => (d?.path !== e.target.name)) || []
      dispatch(AllErrorsFormObject(AllErrors));
    }
  };

  const handleMultiSelectValueChange = (e, { name, value }) => {
    let _list = [];
    value.forEach((o) => {
      _list.push(LanguageCode.find((lang) => lang.code === o));
    })
    checkAllEULangState(_list)
    setModelList(_list);

  };

  const toggleChecked = () => {
    setAddAllEULangChecked(!AddAllEULangChecked, (checked) => {
      if (checked) {
        let _list = [];
        const existingCodes = modelList.map(item => item.code);
        const uniqueArray = _.uniq(_.concat(existingCodes, EULanguageCodes));
        uniqueArray.forEach((o) => {
          _list.push(LanguageCode.find((lang) => lang.code === o));
        })
        checkAllEULangState(_list)
        setModelList(_list)
      }
      else {
        const filteredArray = modelList.filter(obj => !EULanguageCodes.includes(obj.code));
        checkAllEULangState(filteredArray)
        setModelList([...filteredArray])
      }
    });
  };

  const checkAllEULangState = (list) => {
    const isAllCodesPresent = EULanguageCodes.every(codeToCheck =>
      list.some(lang => lang.code === codeToCheck)
    );
    if (isAllCodesPresent) {
      setAddAllEULangChecked(true)
    } else {
      setAddAllEULangChecked(false)
    }
  }

  const langArray = modelList.map(item => item.code)

  const handleClose = () => {
    setShow(false);
  };

  const handleAdd = () => {
    dispatch(changeFormObject({ ...formObject, supportedLanguages: modelList }));
    setShow(false);
  };


  const delLanguage = (i) => {
    let _oldList = [...formObject.supportedLanguages];
    _oldList.splice(i, 1);
    let oldList1 = [...modelList]
    oldList1.splice(i, 1);
    checkAllEULangState(oldList1)
    setModelList([...oldList1])
    dispatch(changeFormObject({ ...formObject, supportedLanguages: _oldList }));
  }

  const getLangDropDownData = () => {
    return LanguageCode.map((o, index) => {
      return { key: index, text: o.name, value: o.code };
    })
  }
  const handleRecipientEmailCLose = () => {
    setRecipientEmailsShow(false);
  };

  const handleRecipientEmailChange = (e) => {
    setRecipientEmail(e.target.value);
  }

  const handleAddRecipientEmail = () => {
    let allRecipientEmails = !!formObject.recipientEmails ? JSON.parse(JSON.stringify(formObject.recipientEmails)) : [];
    if (!!recipientEmail) {
      allRecipientEmails.push(recipientEmail);
    }
    dispatch(changeFormObject({ ...formObject, recipientEmails: allRecipientEmails }));
    handleRecipientEmailCLose();
    setRecipientEmail();
  }

  const deleteRecipientEmail = (i) => {
    let _oldList = [...formObject.recipientEmails];
    _oldList.splice(i, 1);
    dispatch(changeFormObject({ ...formObject, recipientEmails: _oldList }));
  }

  const handleAddDatapoolsId = () => {
    let allDatapoolsId = !!formObject.datapools ? JSON.parse(JSON.stringify(formObject.datapools)) : [];
    if (!!datapoolId) {
      allDatapoolsId.push(datapoolId);
    }
    dispatch(changeFormObject({ ...formObject, datapools: allDatapoolsId }));
    setDatapoolModalShow();
    setDatapoolId();
  }

  const handleDatapoolIdChange = (e) => {
    setDatapoolId(e.target.value);
  }

  const handleDeleteDatapoolId = (i) => {
    let _oldList = [...formObject.datapools];
    _oldList.splice(i, 1);
    dispatch(changeFormObject({ ...formObject, datapools: _oldList }));
  }

  const handlePrivacyAddendum = (checked) => {
    dispatch(changeFormObject({ ...formObject, privacyAddendum: checked, flagForPolicy: checked?.target?.value }));
  }

  const getEcomForm = (ecomType) => {
    switch (ecomType) {
      case 'woocommerce':
        return (
          <div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} name={"ecomUrl"} type="text" placeholder={t("URL")} value={ecomState.ecomUrl} onChange={handleEcomChange} ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} value={ecomState.ecomConsumerKey} onChange={handleEcomChange} placeholder={t("Consumer Key")} name='ecomConsumerKey' type="text"  ></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label={""} value={ecomState.ecomConsumerSecret} onChange={handleEcomChange} placeholder={t("Consumer Secret")} name='ecomConsumerSecret' type="text"></InputTextWithLabel>
            </div>
          </div>
        )
        break;

      case 'shopify':
        return (
          <div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label="" value={ecomState.storeName} onChange={handleEcomChange} placeholder={t("Store Name")} name='storeName' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label="" value={ecomState.apiKey} onChange={handleEcomChange} placeholder={t("Api Key")} name='apiKey' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label="" value={ecomState.apiSecretKey} onChange={handleEcomChange} placeholder={t("Api Secret Key")} name='apiSecretKey' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label="" value={ecomState.accessToken} onChange={handleEcomChange} placeholder={t("Access Token")} name='accessToken' type="text"></InputTextWithLabel>
            </div>
          </div>
        )
        break;

      case 'amazon':
        return (
          <div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label="" disabled value={ecomState.url} onChange={handleEcomChange} placeholder="" name='url' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label="" value={ecomState.sellerId} onChange={handleEcomChange} placeholder={t("Seller Id")} name='sellerId' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <Dropdown style={{ width: 380 }} placeholder={t("Market Place Region")} search selection name="marketPlaceRegion"
                value={ecomState.marketPlaceRegion} options={amazonMarketPlaceRegions} onChange={handleEcomChange} />
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label="" value={ecomState.marketPlaceId} onChange={handleEcomChange} placeholder={t("Market Place Id")} name='marketPlaceId' type="text"></InputTextWithLabel>
            </div>
          </div>
        )
        break;

      case 'ecwid':
        return (
          <div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label="" disabled value={ecomState.ecwidUrl} onChange={handleEcomChange} placeholder="" name='url' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label="" value={ecomState.secretToken} onChange={handleEcomChange} placeholder={t("Secret Token")} name='secretToken' type="text"></InputTextWithLabel>
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true} label="" value={ecomState.storeId} onChange={handleEcomChange} placeholder={t("Store Id")} name='storeId' type="text"></InputTextWithLabel>
            </div>
          </div>
        )
        break;

      default:
        break;
    }
  }


  const getLanguageOptions = () => {
    const languageList = []
    _.get(formObject, "supportedLanguages", []).forEach((item) => {
      languageList.push({ label: item.name, value: item.code })
    })
    return languageList
  }
  const getSupportedLanguage = getLanguageOptions()

  return (
    <>
      <div className="row">
        <div className="manufacturer sidebar-container col-xl-3 col-xxl-4 col-md-4">
          <div className="main-image-div">
            <img className="imgTag" src={ManufacturerLogo} alt="alt"></img>
          </div>
          <div>
            <hr style={{ marginTop: 10 }}></hr>
          </div>

          <div className="d-flex mt-2">
            <span className="add-user role-subtitle col-9">{t("Privacy addendum")}</span>
            <div className="mt-2 col-3 d-flex justify-content-end" style={{ right: 70 }}>
              <InputSwitchWithLable
                checked={formObject?.privacyAddendum?.target?.value}
                onHandleColor='#32cd32'
                offHandleColor='#ffffff'
                onColor='#32cd32'
                offColor='#6c757d'
                name="privacyAddendum"
                onChange={handlePrivacyAddendum}
              // disabled={_state.isCompanyEditMode ? false : true}
              />

            </div>

          </div>


          <div style={{ marginLeft: 5 }}>
            {privacyAddendunFormSchema && privacyAddendunFormSchema[0] && privacyAddendunFormSchema.map((item, index) => {
              return (
                <>
                  <FormFieldSchemaRender
                    key={`formFieldSchemaRender${item.name}_${index}`}
                    index={index}
                    formItem={item}
                    isSmallScreen={true}
                    touched={true}
                    // formReadOnly={!isProdEditMode}
                    formValueObject={formObject}
                    validationObj={errorObject?.[item?.name] ? `${errorObject?.[item?.name]}` : ""}
                    // onBlur={(e) => handleBlurChange(e, item)}
                    onChange={handleFormChange}
                  />
                </>
              )
            })}
          </div>





        </div>

        <div className="manufacturer form-container col-xl-9 col-xxl-8 col-md-8">
          <form >
            <div className="row col-12">

              <InputTextWithLabel
                label={t('Company Name')}
                tooltipText={t("Company Name")}
                onChange={handleFormChange}
                name={'name'}
                value={formObject.name}
                type="text"
                placeholder={t("Company Name")}
                touched={touchedState}
                errorMessage={!!errorObject && errorObject.name ? errorObject.name : ""}
              ></InputTextWithLabel>


              <InputTextWithLabel
                label={t('Company Address')}
                tooltipText={t("Company Address")}
                onChange={handleFormChange}
                name={'address'}
                value={formObject.address}
                type="text"
                placeholder={t("Company Address")}
                touched={touchedState}
                errorMessage={!!errorObject && errorObject.address ? errorObject.address : ""}
              ></InputTextWithLabel>


              <InputSelectDropdown
                label={t("Country Code")}
                tooltipText={"Country Code"}
                placeholder={t("Select country code")}
                value={formObject.countryCode}
                name="countryCode"
                search={true}
                options={selectCountryCodeOption}
                onChange={handleCountryCodeSelect}
                touched={touchedState}
                errorMessage={!!errorObject && errorObject.countryCode ? errorObject.countryCode : ""}
              />


              <InputTextWithLabel
                label={t('Contact Details')}
                tooltipText={t("Contact Details")}
                onChange={handleFormChange}
                name={'contact'}
                value={formObject.contact}
                type="text"
                placeholder={t("Contact Details")}
                touched={touchedState}
                errorMessage={!!errorObject && errorObject.contact ? errorObject.contact : ""}
              ></InputTextWithLabel>

              <InputSelectDropdown
                label={t("Billed For")}
                tooltipText={t("Billed For")}
                placeholder={t("Select Billed For")}
                value={formObject.billedFor ? formObject.billedFor : 'events'}
                name="billedFor"
                options={billedForOptions}
                onChange={handleFormChange}
              />

              <InputSelectDropdown
                label={t("Billing Type")}
                tooltipText={t("Billing Type")}
                placeholder={t("Select Billing Type")}
                value={formObject.billingType ? formObject.billingType : 'post-charge'}
                name="billingType"
                options={billingTypeOptions}
                onChange={handleFormChange}
              />

              {formObject?.billedFor !== "products" &&
                <InputTextWithLabel
                  label={t('Per Event Price')}
                  tooltipText={t("Per Event Price")}
                  onChange={handleFormChange}
                  name={'priceTable'}
                  value={formObject?.priceTable?.default}
                  type="number"
                  placeholder={t("Per Event Price")}
                ></InputTextWithLabel>}

              <InputTextWithLabel
                label={t('Credit Balance')}
                tooltipText={t("Credit Balance")}
                onChange={handleFormChange}
                name={'creditBalance'}
                value={formObject.creditBalance}
                type="number"
                placeholder={t("Credit Balance")}
              ></InputTextWithLabel>

              {formObject?.billedFor =="products" &&
                <InputTextWithLabel
                  label={t('Per Product Price')}
                  tooltipText={t("Per Product Price")}
                  onChange={handleFormChange}
                  name={'perProductPrice'}
                  value={formObject.perProductPrice}
                  type="number"
                  placeholder={t("Per Product Price")}
                />}

              <InputTextWithLabel
                label={t('Vat')}
                tooltipText={t("Vat")}
                onChange={handleFormChange}
                name={'vat'}
                value={formObject.vat}
                type="number"
                placeholder={t("Vat")}
              />

              <InputSelectDropdown
                label={t("Region")}
                tooltipText={t("Region")}
                showTooltip={true}
                placeholder={t("Region")}
                name="region"
                search={true}
                value={formObject.region}
                options={regionsOptions}
                onChange={handleFormChange}
              // loading={_state.segmentLoading}
              />

              <InputTextWithLabel
                label={t('Invoicing email')}
                tooltipText={t("Invoicing email")}
                name={'invoicingEmail'}
                value={formObject.invoicingEmail}
                type="text"
                placeholder={t("Invoicing email")}
                onChange={handleFormChange}
                touched={touchedState}
                errorMessage={!!errorObject && errorObject.invoicingEmail ? errorObject.invoicingEmail : ""}
              />



              <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <label>
                  {t("Recipient Emails")}
                </label>
                {!!formObject.recipientEmails &&
                  formObject.recipientEmails.map((o, index) =>
                  (
                    <Badges key={index} remove onClick={() => deleteRecipientEmail(index)}>
                      {o}
                    </Badges>
                  )
                  )}
                <Badges add onClick={() => setRecipientEmailsShow(true)}>
                  {t("Add Email")}
                </Badges>
              </div>

              <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <label>
                  {t("Supported Languages")}
                </label>
                {!!formObject.supportedLanguages &&
                  formObject.supportedLanguages.map((o, index) =>
                  (
                    <Badges key={index} remove onClick={() => delLanguage(index)}>
                      {o.name}
                    </Badges>
                  )
                  )}
                <Badges add onClick={() => setShow(true)}>
                  {t("Add Language")}
                </Badges>
              </div>


              <InputSelectDropdown
                label={t("Communication language")}
                tooltipText={t("Communication language")}
                placeholder={t("Select Language")}
                name="language"
                onChange={handleFormChange}
                value={formObject.language}
                options={getSupportedLanguage}
              />
            </div>

            <div className="row col-12">
              <div className='form-group col-lg-8 col-md-8 col-sm-8 col-8 mb-1 mt-2'>
                <label>{t('Ecommerce Integrations')}</label>
                {!!errorObject && errorObject.ecomPoints && (
                  <div className="text-danger">{errorObject.ecomPoints}</div>
                )}
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4 col-4 mb-1 mt-2' onClick={() => setEcomModelShow(true)}>
                <IconButton text={t("Add New")} icon={MdAddCircleOutline} onClick={() => setEcomModelShow(true)} />
              </div>

              <div className="col-12">
                <div className="row">
                  {!!_state.formObject.ecomPoints ?
                    _state.formObject.ecomPoints.map((o, index) => (
                      <div key={index} className="col-11 mb-2">
                        <UrlCard
                          url={o.integrationType == "woocommerce" ? o.ecomUrl : o.integrationType == "shopify" ? o.storeName : o.integrationType == "amazon" ? o.url : o.integrationType == "ecwid" ? o.ecwidUrl : null}
                          onClick={() => console.log("ecom url clicked")}
                          isDelete={true}
                          onDeletClick={() => handleDeleteEcomPoints(index)}
                        ></UrlCard>
                      </div>
                    )) : ""
                  }
                </div>
              </div>
            </div>
            <div className="row col-12">
              <div className='form-group col-lg-8 col-md-8 col-sm-8 col-8 mb-1 mt-2'>
                <label>{t("_Business Id")}</label>
                {!!errorObject && errorObject.businessIdentifiers && (
                  <div className="text-danger">{errorObject.businessIdentifiers}</div>
                )}
              </div>
              <div className='form-group col-lg-4 col-md-4 col-sm-4 col-4 mb-1 mt-2' onClick={() => setBusinessIdModelShow(true)}>
                <IconButton text={t("Add New")} icon={MdAddCircleOutline} onClick={() => setBusinessIdModelShow(true)} />
              </div>

              <div className="col-12">
                <div className="row">
                  {!!_state.formObject.businessIdentifiers ?
                    _state.formObject.businessIdentifiers.map((o, index) => (
                      <div key={index} className="col-11 mb-2">
                        <BusinessIdCard
                          businessIdLabel={o.type}
                          businessId={o.id}
                          isDelete={true}
                          onClick={() => handleDeleteBusinessId(index)}
                        ></BusinessIdCard>
                      </div>
                    )) : ""
                  }
                </div>
              </div>
            </div>

            <div className="row col-12">
              <div className='form-group col-lg-8 col-md-8 col-sm-8 col-8 mb-1 mt-2'>
                <label>{t('_Datapools')}</label>
                {/* {!!errorObject && errorObject.ecomPoints && (
                  <div className="text-danger">{errorObject.ecomPoints}</div>
                )} */}
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4 col-4 mb-1 mt-2'>
                <IconButton text={t("Add New")} icon={MdAddCircleOutline} onClick={() => setDatapoolModalShow(true)} />
              </div>

              <div className="col-12">
                <div className="row">
                  {!!_state.formObject.datapools ?
                    _state.formObject.datapools.map((o, index) => (
                      <div key={index} className="col-11 mb-2">
                        <UrlCard
                          url={o}
                          onClick={() => console.log("ecom url clicked")}
                          isDelete={true}
                          onDeletClick={() => handleDeleteDatapoolId(index)}
                        ></UrlCard>
                      </div>
                    )) : ""
                  }
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
      <Modal size="md" show={ecomModelShow} onHide={handleEcomModelClose} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleEcomModelClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal', textAlign: 'center' }}>
          <div className="row m-0 justify-content-center">
            <span className="col-12 product-profile model-text mb-10" >{t("Enter Ecommerce Integration Info")}</span>
            <div className="form-group col-12">
              <Dropdown style={{ marginTop: 20, width: 400, height: 40 }}
                placeholder={t("Select Ecommerce Integration Type")}
                selection
                value={state.integrationType}
                options={ecommerceIntegrationTypes}
                onChange={handleEcomIntegrationTypeChange}
                name="type"
              />
            </div>
            {/* ecom integrations add form */}
            {getEcomForm(state.integrationType)}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("ADD")} onClick={() => handleAddEcomPoints()}></StSquareButton>
          <StCancelSquareButton text={t("Cancel")} onClick={() => handleEcomModelClose()}></StCancelSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal size="md" show={businessIdModelShow} onHide={handleBusinessIdModelClose} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleBusinessIdModelClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal', textAlign: 'center' }}>
          <div className="row m-0 justify-content-center">
            <span className="col-12 product-profile model-text mb-10" >{t("Enter Business addcompany Id")}</span>
            <div className="form-group col-12">
              <Dropdown style={{ marginTop: 20, width: 400, height: 40 }}
                placeholder={t("Select Business Id Type")}
                selection
                value={state.businessIdTypeValue}
                options={businessIdTypeOptions}
                onChange={handleDropdownChange}
                name="type"
              />
            </div>
            <div className="form-group col-12">
              <InputTextWithLabel
                errorMessage={businessIdInputWarrning}
                isSmallScreen={true}
                label={""}
                value={state.businessIdInputValue}
                onChange={handleBusinessIdChange}
                placeholder={t("_Business Id")}
                name='businessIdInputValue'
                type="text"
                touched={warnningHandler}
              >
              </InputTextWithLabel>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("ADD")}
            disabled={disableButtonHandler}
            onClick={() => handleAddBusinessId()}></StSquareButton>
          <StCancelSquareButton text={t("Cancel")} onClick={() => handleBusinessIdModelClose()}></StCancelSquareButton>
        </Modal.Footer>
      </Modal>


      <Modal size="lg" centered={true} backdrop="static" show={show} onHide={handleClose} >
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal' }}>
          <div className="row col-12 m-0 justify-content-center">
            <span style={{ fontSize: 18, color: '#000000', fontWeight: 'bold' }}>{t("Select Supported Languages")}</span>
            <div className="d-flex flex-row">
              <div className="d-flex align-items-center">
                <span style={{ fontSize: 14, color: '#000000' }}>{t("addAllEULanguages")}</span>
              </div>
              <div className="d-flex mt-2 ms-5">
                <InputSwitchWithLable
                  onHandleColor='#32cd32'
                  offHandleColor='#ffffff'
                  onColor='#32cd32'
                  offColor='#6c757d'
                  onChange={toggleChecked}
                  value={AddAllEULangChecked}
                />
              </div>
            </div>
            <Dropdown style={{ margin: 15, fontSize: 14 }} value={langArray} onChange={handleMultiSelectValueChange} selection multiple search fluid options={getLangDropDownData()} placeholder='Choose an option' />
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Add")} onClick={() => handleAdd()}></StSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" centered={true} backdrop="static" show={recipientEmailsShow} onHide={handleRecipientEmailCLose} >
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleRecipientEmailCLose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal' }}>
          <div className="row col-12 m-0 justify-content-center form-group">
            <InputTextWithLabel isSmallScreen={true}
              label={t("Enter Recipient Email")}
              name={"recipientEmail"}
              type="text"
              placeholder={t("Enter recipient email")}
              value={recipientEmail}
              onChange={handleRecipientEmailChange}
            ></InputTextWithLabel>
          </div>

        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("ADD")} onClick={() => handleAddRecipientEmail()}></StSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal size="md" centered={true} backdrop="static" show={datapoolModalShow} onHide={() => { setDatapoolModalShow(false); setDatapoolId() }} >
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => { setDatapoolModalShow(false); setDatapoolId() }}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ fontWeight: 'normal', textAlign: 'center' }}>
          <div className="row col-12 m-0 justify-content-center form-group">
            <span className="col-12 product-profile model-text mb-10" >{t("Enter Datapools Id")}</span>
            <InputTextWithLabel isSmallScreen={true}
              label={''}
              name={"datapoolId"}
              type="text"
              placeholder={t("Enter Datapools id")}
              value={datapoolId}
              onChange={handleDatapoolIdChange}
            ></InputTextWithLabel>
          </div>

        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Add")} onClick={() => handleAddDatapoolsId()}></StSquareButton>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default AddCompany;
