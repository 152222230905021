import React, { useEffect, useState } from "react";
import { getClickstreamEnvs } from "src/redux/analytics/analyticsThunk";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import PieGraph from "./PieGraph";
import { Spinner } from "src/components/Spinner";
import { useTranslation } from "react-i18next";

const ClickstreamEnvs = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const analyticsState = useSelector((state) => state.analytics, shallowEqual);
  const { actionsLoading: isLoading, analyticsClickstreamEnvs } = analyticsState

  const [chartTitle, setChartTitle] = useState(t("Number of scans per environment"));


  let envs;
  let labels;

  // Load initial data
  useEffect(() => {
    dispatch(getClickstreamEnvs());
  }, []);


  let pieGraphData = [];

  // Map counts and labels to arrays
  if (!!analyticsClickstreamEnvs) {
    envs = analyticsClickstreamEnvs.map((i) => i.count);
    labels = analyticsClickstreamEnvs.map((i) => i.env);

    analyticsClickstreamEnvs.map((data, i) => {
      pieGraphData.push({
        id: data.env,
        label: data.env,
        value: data.count,
      })
    })
  }

  /**
  // Parameters for chart
  const data = {
    labels: labels,
    datasets: [
      {
        label: t("Number of scans per environment"),
        data: envs,
        backgroundColor: ["#0000FF", "#FF0000"],

        borderColor: ["#0000FF", "#FF0000"],
        fill: true,
        borderWidth: 2,
        tension: 0,
      },
    ],
  };
  */

  /**
  // Options for chart
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: t("Number of scans per environment"),
    },
  };
  */
  return (
    <>
      {isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-12">
              {/* <Pie data={data} options={options} /> */}
              <PieGraph pieData={pieGraphData} title={chartTitle} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClickstreamEnvs;
