import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import ProductIconButton from "../../commonComponent/ProductIconButton";
import { AllErrorsFormObject } from "src/redux/company/companySlice";
import { addCompany } from "src/redux/company/companyThunk";
import * as companyActions from "../../../redux/company/companyActions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FcOk } from "react-icons/fc";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { usePrevious } from "../../../helper/CustomHook";
import { companySchema } from "../../../helper/yup-schema";
import { MdArrowBack, MdAddCircle, MdMenu, MdClose } from "react-icons/md";
import logo from '../../../assets/images/brand_icons/upids-pdm-logo.png'
import { IconButton } from "src/components/IconButton"
import { StMenu } from "src/components/StMenu";
import { RoundedgeButton } from "src/components/RoundedgeButton"
import { Header } from "src/components/Header"
import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from "src/components/StSquareButton";

const ManufacturerNavbar = () => {

  const manufacturerNavbarCollection = [
    { menuItem: () => <IconButton text={t("Add Manufacturer")} icon={MdAddCircle} onClick={() => handleAddCompanyOnClick()} /> },
  ]

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  let pathName = window.location.href
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const _state = useSelector((state) => state.company, shallowEqual);

  const response = _state.response;
  const prevPropsState = usePrevious({ response });

  let formObject = _state.formObject;

  useEffect(() => {
    if (prevPropsState) {
      if (
        prevPropsState.response !== response &&
        response.data.id &&
        response !== ""
      ) {
        setShow(true);
      }
    }
  }, [_state]);

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  let allErrors;

  const handleConfirmButtonChange = () => {
    companySchema
      .validate(formObject, { abortEarly: false })
      .then(function () {
        dispatch(AllErrorsFormObject([]));
      })
      .then(function () {

        let companyData = {
          name: formObject.name,
          businessId: formObject.businessIdentifiers[0].id,
          businessIdentifiers: formObject.businessIdentifiers,
          countryCode: formObject.countryCode,
          supportedLanguages: formObject.supportedLanguages,
          language: formObject.language,
          address: formObject.address,
          contact: formObject.contact,
          recipientEmails: formObject.recipientEmails,
          // apiKey: formObject.apiKey
          apiKey: generateAPIKeyHandle(),
          billedFor: formObject.billedFor ? formObject.billedFor : 'events',
          billingType: formObject.billingType ? formObject.billingType : 'post-charge',
          creditBalance: formObject.creditBalance,
          perProductPrice: formObject.perProductPrice,
          vat: formObject.vat,
          region: formObject.region,
          datapools: formObject.datapools,
          privacyAddendum: formObject.privacyAddendum ? formObject.privacyAddendum : false,
          contentItemUrl: formObject?.contentItemUrl,
          webhookUrl:formObject?.webhookUrl,
          webhookKey:formObject?.webhookKey,
          priceTable:formObject?.priceTable,
          invoicingEmail:formObject?.invoicingEmail || ""
        };;

        let ecomData = createEcomModel(formObject);
        dispatch(addCompany({ body: companyData, ecomBody: ecomData }));
      })
      .catch(function (err, inner) {
        allErrors = err.inner;
        dispatch(AllErrorsFormObject(allErrors));
      });
  };


  const createEcomModel = (formObject) => {
    let ecomList = [];
    if (formObject.ecomPoints) {
      formObject.ecomPoints.map((o, index) => {
        if (o.integrationType == "woocommerce") {
          ecomList.push({
            "url": o.ecomUrl,
            "consumerKey": o.ecomConsumerKey,
            "consumerSecret": o.ecomConsumerSecret,
            "integrationType": o.integrationType,
            "businessIdentifiers": formObject.businessIdentifiers
          });
        } else if (o.integrationType == "shopify") {
          ecomList.push({
            "storeName": o.storeName,
            "apiKey": o.apiKey,
            "apiSecretKey": o.apiSecretKey,
            "accessToken": o.accessToken,
            "integrationType": o.integrationType,
            "hostUrl": "https://prod.api.2nd.gdn/v2",
            "businessIdentifiers": formObject.businessIdentifiers
          })
        } else if (o.integrationType == "amazon") {
          ecomList.push({
            "url": o.url,
            "sellerid": o.sellerid,
            "marketplaceids": o.marketplaceids,
            "integrationType": o.integrationType,
            "businessIdentifiers": formObject.businessIdentifiers
          })
        } else if (o.integrationType == "ecwid") {
          ecomList.push({
            "url": o.ecwidUrl,
            "secret_token": o.secretToken,
            "storeId": o.storeId,
            "integrationType": o.integrationType,
            "businessIdentifiers": formObject.businessIdentifiers
          })
        }
        else { }
      });
    }

    return ecomList;
  };


  const generateAPIKeyHandle = () => {
    const key = generateUUID();
    // setGenerateKey(key);
    // dispatch(companyActions.changeFormObj({ ...formObject, apiKey: key }));
    return key
  };

  function generateUUID() {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now();
    }

    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }

  const handleClose = () => {
    setShow(false);
    navigate("/manufacturers");
  }

  const handleClick = () => {
    setShow(false);
    navigate("/manufacturers");
  };

  const handleAddCompanyOnClick = () => {
    navigate("/manufacturers/add");
  };

  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/dashboard">
            <img src={logo} alt="logo" />
          </Link>
          <Link
            className="navbar-brand brand-logo-mini d-inline-block d-lg-none"
            to="/"
          >
            <img
              src={require("../../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">

          {!pathName.includes("/manufacturers/add") ?
            <span><Header text={t("Manufacturers")} /></span> :
            <div>
              <span
                style={{

                  fontSize: 16,
                  color: "#131E27",
                  opacity: 1,
                }}
              >
                <MdArrowBack
                  onClick={() => navigate(-1)}
                  style={{
                    width: 24,
                    height: 24,
                    backgroundColor: "transparent",
                    opacity: 1,
                    cursor: "pointer",
                    marginLeft: -10,
                  }}
                />
              </span>
              <span style={{ marginLeft: 20 }}><Header text={t("Add New Manufacturer")} /></span>
            </div>}

          <span className="navbar-nav navbar-nav-right">
            {!pathName.includes("/manufacturers/add") ?
              <span className="nav-item nav-profile d-none d-sm-flex">
                <IconButton text={t("Add Manufacturer")} icon={MdAddCircle} onClick={() => handleAddCompanyOnClick()} />
              </span> :
              <div>
                <RoundedgeButton
                  text={t("Confirm")}
                  loading={_state.isLoading}
                  onClick={handleConfirmButtonChange}
                ></RoundedgeButton>
              </div>
            }
          </span>

          {/* responsive dropdown on mobile screen */}
          <span className="navbar-nav navbar-nav-right productNavResponsiveDropdown">
            {!pathName.includes("/manufacturers/add") &&
              <StMenu menuArray={manufacturerNavbarCollection}></StMenu>
            }
          </span>

          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
            <MdMenu color="#000000" size={24} />
          </button>
        </div>
      </nav>

      <Modal size="md" show={show} onHide={handleClose} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18, }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FcOk
              style={{
                width: 40,
                height: 40,
                textAlign: "center",
                alignSelf: "center",
              }}
            />
            <span>{t("New Manufacturer successfully added")}</span>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Ok")} onClick={() => handleClick()}></StSquareButton>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default ManufacturerNavbar;
