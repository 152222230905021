import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { MdArrowBack, MdMenu, MdOutlineModeEdit, MdOutlineSave, MdOutlineClose } from "react-icons/md";
import { changeProductTemplateEditMode, currentProductTemplateObject, handleErrorsInProductTemplate, handleJSONErrorsInProductTemplate, handleJSONTemplateLabelError, handleJSONTemplateNameError } from "src/redux/products/productsSlice";
import logo from '../../../assets/images/brand_icons/upids-pdm-logo.png'
import { IconButton } from "src/components/IconButton"
import { StMenu } from "src/components/StMenu";
import { StSquareButton } from "src/components/StSquareButton";
import { StCloseButton } from "src/components/StCloseButton";
import { deleteProductTemplateData, getIndividualProductTemplateData, updateIndividualProductTemplateData } from "src/redux/products/productThunk";
import { BiTrash } from "react-icons/bi";
import { StCancelSquareButton } from "src/components/StCancelSquareButton";


const ProudctTemplateNavbar = () => {
    const location = useLocation();
    const templateData = location.state && location.state.templateData;

    const [showDelPopup, setShowDelPopup] = useState(false);

    const manufacturerProfileNavbarCollection = [
        {
            menuItem: () => <IconButton text={t("Edit")} icon={MdOutlineModeEdit} onClick={() => changeMode()} />,
            submenu: [
                { menuItem: () => <IconButton text={t("Save Changes")} icon={MdOutlineSave} onClick={() => handleSaveClick()} /> },
                { menuItem: () => <IconButton text={t("Delete Template")} icon={BiTrash} onClick={() => handleDeleteClick()} /> },
                { menuItem: () => <IconButton text={t("Cancel")} icon={MdOutlineClose} onClick={() => handleCancelClick()} /> }
            ],
            submenuTitle: () => <IconButton text={t("Edit")} icon={MdOutlineModeEdit} onClick={() => changeMode()} />
        },
    ]

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const _productState = useSelector((state) => state.products, shallowEqual);

    const [updateTemplateDataLoading, setUpdateTemplateDataLoading] = useState(false);
    const [show, setShow] = useState(false);
    let validationErrors = [];

    let fullUrl = window.location.href;
    let name = fullUrl.split("/").pop();
    const data = _productState.currentTemplateObj;



    const handleClose = () => setShow(false);

    const handleSaveClick = () => {
        if (!_productState?.errorsInProductTemplate) {
            setShow(true);
        }
    };

    const handleCancelClick = async () => {
        dispatch(changeProductTemplateEditMode(false));
        dispatch(handleErrorsInProductTemplate(false));
        dispatch(handleJSONTemplateNameError(false))
        dispatch(handleJSONTemplateLabelError(false))
        await dispatch(handleJSONErrorsInProductTemplate([]))
        let result = await dispatch(getIndividualProductTemplateData(name))
        await dispatch(currentProductTemplateObject(result?.payload));
    };

    function toggleOffcanvas() {
        document.querySelector(".sidebar-offcanvas").classList.toggle("active");
    }

    const changeMode = () => {
        dispatch(changeProductTemplateEditMode(true));
    };

    // Function to validate each object in the JSON array against the schema
    const validateJSONArray = (obj, index, section) => {
        // Iterate over each object in the JSON array
        const missingFields = [];


        // Get the first key of the object
        const firstKey = Object.keys(obj)[0];

        // Check if the value of the first key is an array
        const isFirstValueArray = Array.isArray(obj[firstKey]);

        // If the first value is an array, skip validation for this object
        if (isFirstValueArray) {
            obj[firstKey].forEach((key, idx) => {
                let errorObj = validateJSONArray(key, idx, Object.keys(obj)[0])
                if (errorObj) {
                    validationErrors.push(errorObj);
                }
            })
            return;
        }


        // Check if type is "ecommerce"
        const ismanufacturerDetails = obj.type === "manufacturerDetails";

        // Check if type is "ecommerce"
        const isEcommerce = obj.type === "ecommerce";

        // Check if type is "videoUrl"
        const isVideoUrl = obj.type === "videoUrl" || obj.type === "gs1DigitalLinkStructure";

        // Check if name is "certifications"
        const isCertifications = obj.name === "certifications";

        // Check if name is "certifications"
        const isTabNameAndType = ["linkedUpids", "audio", "QrCodeViewer", "GrainpassData", "UserInstruction", "uniqueIdentifier"].includes(obj.name);


        // Check if tooltipText is an empty string
        const isLabelEmpty = obj.label === "";

        // Check for type if name of an object is directCommerce
        const typeCheckEmpty = obj.type === "" && templateData?.name == "textile" && obj.name == "directCommerce";

        // Check for tabName if name of an object is directCommerce
        const tabNameCheckEmpty = obj.tabName === "" && templateData?.name == "textile" && obj.name == "directCommerce";

        let labelSkipFields = ['multimedia', "Postal Address"];
        let isLabelSkip = labelSkipFields.includes(section) || labelSkipFields.includes(obj?.subsection);


        // Check for the presence of each required field in the object
        if (!ismanufacturerDetails && !obj.name) missingFields.push('name');
        if (!obj.type && !isTabNameAndType && !typeCheckEmpty) missingFields.push('type');
        if (!obj.tabName && !isTabNameAndType && !tabNameCheckEmpty && !isVideoUrl) missingFields.push('tabName');
        if ((!isEcommerce && !isLabelSkip && !ismanufacturerDetails && !isVideoUrl && !isCertifications) && obj.isMultilingual == undefined && !obj.isMultilingual) missingFields.push('isMultilingual');
        if (!isEcommerce && !isLabelSkip && !ismanufacturerDetails && !isLabelEmpty && !obj.label) missingFields.push('label');
        if (!isEcommerce && obj.readOnly == undefined && !obj.readOnly) missingFields.push('readOnly');


        // If any required field is missing, collect the field names in the errors array
        if (missingFields.length > 0) {
            return { index, section, missingFields, obj: obj.name, type: obj.type }
        }
    };

    const getTemplateValidationError = (addTemplateObject) => {
        addTemplateObject?.forEach((obj, idx) => {
            let errorObj = validateJSONArray(obj, idx)
            if (errorObj) {
                validationErrors.push(errorObj);
            }
        })
        return validationErrors;
    }

    const handleSaveChanges = async () => {
        setUpdateTemplateDataLoading(true);
        let newlyCreatedData = { ...data }
        const { modifiedDate, ...restOfObject } = newlyCreatedData;
        let sendData = { ...restOfObject, id: templateData?.id }
        const validationErrors = getTemplateValidationError(sendData?.template)

        // Output validation errors, if any
        if (validationErrors.length > 0) {
            await dispatch(handleJSONErrorsInProductTemplate(validationErrors))

        } else {
            await dispatch(handleJSONErrorsInProductTemplate([]))
        }
        if (sendData?.name == "") {
            await dispatch(handleJSONTemplateNameError(true))
        }
        if (sendData?.label == "") {
            await dispatch(handleJSONTemplateLabelError(true))
        }
        if (sendData?.name !== "" && sendData?.label !== "" && validationErrors?.length == 0) {
            try {
                await dispatch(updateIndividualProductTemplateData(sendData))
                dispatch(changeProductTemplateEditMode(false));

            } catch (error) {
                console.log(error)
            }
        }
        setShow(false)
        setUpdateTemplateDataLoading(false)
    };

    const handleDeleteClick = () => {
        setShowDelPopup(true);
    };

    const handleDelClose = () => {
        setShowDelPopup(false);
    }


    const handleDeleteChanges = async () => {
        try {
            await dispatch(deleteProductTemplateData(templateData?.id));
            navigate("/templates")
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <>
            <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <Link className="navbar-brand brand-logo" to="/dashboard">
                        <img src={logo} alt="logo" />
                    </Link>
                    <Link
                        className="navbar-brand brand-logo-mini d-inline-block d-lg-none"
                        to="/"
                    >
                        <img
                            src={require("../../../assets/images/logo-mini.svg")}
                            alt="logo"
                        />
                    </Link>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-center">
                    <span
                        style={{

                            fontSize: 16,
                            color: "#131E27",
                            opacity: 1,
                        }}
                    >
                        <MdArrowBack
                            onClick={() => navigate(-1)}
                            style={{
                                width: 24,
                                height: 24,
                                backgroundColor: "transparent",
                                opacity: 1,
                                cursor: "pointer",
                            }}
                        />
                        <Trans></Trans>
                    </span>
                    <span
                        style={{
                            marginLeft: 15,

                            textTransform: "uppercase",
                            fontWeight: "bold",
                            fontSize: 16,
                            color: "#131E27",
                        }}
                    >
                        {t("Edit Template")}
                    </span>


                    {!_productState.isProductTemplateEditMode ? (
                        <div className="navbar-nav navbar-nav-right d-none d-sm-flex">
                            <IconButton
                                text={t("Edit")}
                                icon={MdOutlineModeEdit}
                                onClick={changeMode}
                            />
                        </div>
                    ) : (
                        <div className="navbar-nav navbar-nav-right d-none d-sm-flex">
                            <IconButton
                                text={t("Save Changes")}
                                icon={MdOutlineSave}
                                onClick={handleSaveClick}
                            />
                            <IconButton
                                text={t("Delete Template")}
                                icon={BiTrash}
                                onClick={handleDeleteClick}
                            />
                            <IconButton
                                text={t("Cancel")}
                                icon={MdOutlineClose}
                                onClick={handleCancelClick}
                            />
                        </div>
                    )}

                    {/* responsive dropdown on mobile screen */}
                    <ul className="navbar-nav navbar-nav-right productNavResponsiveDropdown">
                        <StMenu menuArray={manufacturerProfileNavbarCollection}></StMenu>
                    </ul>

                    <button
                        className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                        type="button"
                        onClick={toggleOffcanvas}
                    >
                        <MdMenu color="#000000" size={24} />
                    </button>
                </div>
            </nav>

            <Modal size="md" show={show} onHide={handleClose} centered={true}>
                <Modal.Header >
                    <div style={{ position: 'absolute', right: 10 }}>
                        <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18, }}>
                    <span>{t("Are you sure want to save the changes")}</span>
                </Modal.Body>
                <Modal.Footer>
                    <StSquareButton text={t("Save Changes")} loading={updateTemplateDataLoading} onClick={() => handleSaveChanges()}></StSquareButton>
                    <StSquareButton text={t("Discard")} onClick={() => handleClose()}></StSquareButton>
                </Modal.Footer>
            </Modal>

            <Modal size="md" show={showDelPopup} onHide={handleDelClose} centered={true}>
                <Modal.Header >
                    <div style={{ position: 'absolute', right: 10 }}>
                        <StCloseButton text={t("Close")} onClick={() => handleDelClose()}></StCloseButton>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18, }}>
                    <span>{t("Are you sure want to delete the template")}</span>
                </Modal.Body>
                <Modal.Footer>
                    <StSquareButton text={t("Yes")} onClick={() => handleDeleteChanges()}
                        loading={_productState.isTemplateDeleteLoading}
                    ></StSquareButton>
                    <StCancelSquareButton text={t("No")} onClick={() => handleDelClose()}></StCancelSquareButton>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default ProudctTemplateNavbar;
