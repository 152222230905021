import React, { useState, useRef, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { emailYuSchema } from '../../../helper/yup-schema'
import { useLocation } from 'react-router-dom';
import { unsubscribeMarketingEmails } from "src/redux/products/productThunk";
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { usePrevious } from "../../../helper/CustomHook";

var CryptoJS = require("crypto-js");

const Unsubscribe = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();

    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const businessId = params.get('businessId');
    const businessNameEncoded = params.get('businessName');
    const emailEncoded = params.get('email');

    const [usubscribeSuccessMsg, setUnsubscribeSuccessMsg] = useState(false);

    const { register, handleSubmit, watch, formState: { errors }, } = useForm({
        resolver: yupResolver(emailYuSchema)
    });

    const _state = useSelector(
        (state) => (state.products),
        shallowEqual
    );

    const {unsubscribeSuccessful } = _state;
    const prevPropsState = usePrevious({ unsubscribeSuccessful });

    useEffect(() => {
        if (prevPropsState) {
            if (prevPropsState.unsubscribeSuccessful !== unsubscribeSuccessful && unsubscribeSuccessful) {
                setUnsubscribeSuccessMsg(true)
            }
          }
    }, [_state.unsubscribeSuccessful])

    const onSubmit = (data) => {
        // let encodedEmailString = window.btoa(data.email);
        var encodedEmailString = CryptoJS.AES.encrypt(data.email, businessId).toString();

          let unsubscribeData = {
            businessId: businessId,
            id: encodedEmailString,
            unsubscribeAll: data.unsubscribe == "unsubscribeAll" ? true : false
          }
          dispatch(unsubscribeMarketingEmails(unsubscribeData));
    };

    const getDataFromUrl =(encodedString)=>{
        var bytes  = CryptoJS.AES.decrypt(encodedString, businessId);
        var decodedString = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decodedString)
    }

    return (
        <>
            <p className="content-header">
                {t('Trace the product lifecycles, at each single step at atomic level')}
            </p>
           {usubscribeSuccessMsg ? <p className="content-subtitle">{t('Email successfully unsubscribed')}</p> 
              :  
              _state.unsubscribeLoading ? 
                    <div className="unsubscribeLoader">
                        <Spinner animation="border" variant="light" /> 
                    </div>
              :
              <div>
                    <p className="content-subtitle">{t('Unsubscribe email')}</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div><input name='email' {...register("email")} value={getDataFromUrl(emailEncoded)} readOnly={true} className={"login-input"} type="text" placeholder={t('Enter Email ID to unsubscribe')} /></div>
                        <span className="login-error-text"> {!!errors.email && errors.email.message}</span>
                        <div className="mt-4">
                            <input type='radio' {...register("unsubscribe")} name="unsubscribe" value='unsubscribeBusinessId' checked={true}/><label className="unsubscribeLabels"> {getDataFromUrl(businessNameEncoded)}</label><br></br>
                            <input type='radio' {...register("unsubscribe")} name="unsubscribe" value='unsubscribeAll' /><label className="unsubscribeLabels"> {t("All emails originating from UPIDS")}</label>
                        </div>
                        <div style={{ marginTop: 65 }}>
                            <button type='submit' className="login-button">{ t("Unsubscribe") } </button>
                        </div>
                    </form>
                </div>}
        </>
    )
}

export default Unsubscribe;