import React, { useState } from 'react'
import PropTypes from 'prop-types'


import { changeFormObject } from 'src/redux/products/productsSlice';
import { getAllSegmentList, getSegmentCodeDetails } from 'src/redux/products/productThunk';
import { Tab } from 'semantic-ui-react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import { findVal } from '../../../../helper/CustomHook';
import { Dropdown, Popup } from 'semantic-ui-react';
// import MultiLangTabWrapper from '../../components/MultiLangTabWrapper';
import { useTranslation } from 'react-i18next';
import { IoIosHelpCircleOutline } from 'react-icons/io'
import { restrictionsData } from 'src/helper/MetadataCollection';
import { doormatModalData } from 'src/helper/MetadataCollection';
import { InputTextWithLabel } from 'src/components/inputComponents/InputTextWithLabel';
import { InputSelectDropdown } from 'src/components/inputComponents/InputSelectDropdown';
import { MdClose, MdSend } from 'react-icons/md';
import { IconButton } from 'src/components/IconButton';


export const DoormatModal = props => {
  const {
    /** name, value, label, readOnly, onChange */
} = props



const { t } = useTranslation('common');

const dispatch = useDispatch();

const productState = useSelector((state) => (state.products));
const productData = productState.formObject // productData === formObject


  const resetDoormatModalHandle = () => {
    let doormatData = { ...productData?.router?.doormat } || {}
    doormatData.type = "";
    doormatData.fullProfile = "";
    dispatch(changeFormObject({ ...productData, router: { ...productData.router, doormat: doormatData }, isChange: 1 }))
  }


  const handleFormChange = (e, data) => {
    let doormatData = productData?.router?.doormat || {}
    if (!!data) {
        doormatData = { ...doormatData, [data.name]: data.value }
    } else {
        doormatData = { ...doormatData, [e.target.name]: e.target.value }
    }
    dispatch(changeFormObject({ ...productData, router: { ...productData.router, doormat: doormatData }, isChange: 1 }))
  };

  return (
    <div style={{ marginBottom: 16 }} className="row col-lg-12">
      <div className='d-flex' style={{ marginLeft: -15 }}>
        <span className='col-lg-9 col-md-9 col-sm-9 col-9 mb-3' style={{ color: '#253C4B', fontSize: 14, fontWeight: 'bold' }}>{t("_Doormat Modal")}
          <Popup
            pinned wide='very' position='top center'
            //  flowing
            on={'click'}
            trigger={<IoIosHelpCircleOutline style={{ marginBottom: 2, color: '#6c757d', cursor: 'pointer', marginLeft: 5 }} fontWeight={'bold'} />} >
            <p>{t('_Doormat Modal')}</p>
          </Popup>
        </span>
        {productState.isProdEditMode &&
          <div className='col-lg-3 col-md-3 col-sm-3 col-3' style={{ cursor: 'pointer' }}>
            <IconButton text={t("Reset")} icon={MdClose} onClick={() => resetDoormatModalHandle()} />
          </div>}
      </div>

      <InputSelectDropdown
        label={t("Type")}
        tooltipText={t('Doormat Type')}
        showTooltip={true}
        placeholder={t('Doormat Type')}
        name="type"
        search={true}
        value={!!productData?.router?.doormat ? productData?.router?.doormat?.type : ''}
        options={doormatModalData}
        onChange={handleFormChange}
        readOnly={productState.isProdEditMode ? false : true}
      />

      <InputTextWithLabel
        label={t('fullProfileId')}
        tooltipText={t('Doormat Value')}
        showTooltip={true}
        onChange={handleFormChange}
        name={"fullProfile"}
        value={!!productData?.router?.doormat ? productData?.router?.doormat?.fullProfile : ''}
        type="text"
        placeholder={t('Doormat Value')}
        readOnly={productState.isProdEditMode ? false : true}
      ></InputTextWithLabel>

    </div>
  )
}

DoormatModal.propTypes = {}
