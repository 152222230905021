import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { StCloseButton } from "src/components/StCloseButton";
import { useTranslation } from "react-i18next";
import { showPaymentModalAction, showPaymentTypeModalAction, showPaymentVoucherModalAction } from 'src/redux/account/accountSlice';
import { StSquareButton } from 'src/components/StSquareButton';
import { StCancelSquareButton } from 'src/components/StCancelSquareButton';
import { Dropdown } from 'semantic-ui-react';

const paymentTypeSelectData = [
    { key: 1, value: "voucher code", text: "Voucher code" },
    { key: 2, value: "credit card", text: "Credit card" },
]

function PaymentTypeModel() {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();

    const [paymentTypeMethod, setPaymentTypeMethod]= useState("");

    const { isShowPaymentTypeModal } = useSelector((state) => state.account);

    const handleClose = () => {
        // dispatch(companyActions.getCompanyDetails());
        // dispatch(getAccountBillingAction())
        dispatch(showPaymentTypeModalAction(false));
        setPaymentTypeMethod("")
    }
  
    const handlePaymentTypeSelect = (type) => {
        setPaymentTypeMethod(type);
        if(type=="Credit card"){
            dispatch(showPaymentModalAction(true));
        }else if(type=="Voucher code"){
            dispatch(showPaymentVoucherModalAction(true));
        }else{}
        handleClose();
    }
    
    return (
        <>
            <Modal size="md" centered={true} backdrop="static" show={isShowPaymentTypeModal} onHide={handleClose} >
                <Modal.Header >
                    <div style={{ position: 'absolute', right: 10 }}>
                        <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ fontWeight: 'normal' }}>
                        <div className="form-group col-12"  >
                            <label className="mt-2">{t("Top-up credit balance with")}</label>
                            <div className='mt-4 d-flex justify-content-center'>
                                <StSquareButton text={t("Credit card")} onClick={() => handlePaymentTypeSelect("Credit card")} ></StSquareButton>
                                <StSquareButton text={t("Voucher code")} onClick={() => handlePaymentTypeSelect("Voucher code")} ></StSquareButton>
                            </div>
                        </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PaymentTypeModel