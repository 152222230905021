import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Editor from '@monaco-editor/react';
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import { MdAddCircle } from "react-icons/md";
import { toast } from 'react-toastify';
import { getAllProductTemplates, addProductTemplateData, getIndividualProductTemplateData } from "src/redux/products/productThunk";
import { IconButton } from "src/components/IconButton";
import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from "src/components/StSquareButton";
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel";
import { StCancelSquareButton } from "src/components/StCancelSquareButton";
import CustomTooltip from "src/components/inputComponents/CustomTooltip";
import TemplatesTable from "./templatesTable/TemplatesTable";

const Templates = () => {
  /** redux store useDispatch & useSelector ------------------------------ **/
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const _state = useSelector((state) => (state.products), shallowEqual);

  /** hooks useState ----------------------------------------------------- **/
  const [show, setShow] = useState(false);
  const [jsonObjError, setJsonObjError] = useState(false)
  const [addTemplateObject, setAddTemplateObject] = useState({
    name: "",
    label: "",
    template: [{}]
  })
  const [templateNameError, setTemplateNameError] = useState("")
  const [templateLabelError, setTemplateLabelError] = useState("")
  const [templateValidationErrors, setTemplateValidationErrors] = useState([])

  /** Variable declaration ----------------------------------------------------- **/
  const isLoading = _state.actionsLoading;
  let validationErrors = [];

  /** hooks useEffect----------------------------------------------------- **/
  useEffect(() => {
    getProductTemplates()
  }, []);

  /** handler functions--------------------------------------------------- **/

  /** Function to retrieve all product template data------------------------- */
  const getProductTemplates = async () => {
    try {
      await dispatch(getAllProductTemplates())
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddTemplateClick = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false);
    setAddTemplateObject({ name: "", label: "", template: [{}] })
    setTemplateNameError("")
    setTemplateLabelError("")
    setJsonObjError("")
    setTemplateValidationErrors([])
  }

  /** Function to save input field values--------------------------- */
  const handleFormChange = (e) => {
    setAddTemplateObject(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
    if (e.target.name == "name") {
      setTemplateNameError("")
    }
    if (e.target.name == "label") {
      setTemplateLabelError("")
    }
  }

  /** Function execute when click on edit template action-------------------------- */
  const handleTemplateEditClick = (value) => {
    _.set(addTemplateObject, "template", value)
  }

  /** Function to validate each object in the JSON array against the schema--------------------*/
  const validateJSONArray = (obj, index, section) => {
    // Iterate over each object in the JSON array
    const missingFields = [];


    // Get the first key of the object
    const firstKey = Object.keys(obj)[0];

    // Check if the value of the first key is an array
    const isFirstValueArray = Array.isArray(obj[firstKey]);

    // If the first value is an array, skip validation for this object
    if (isFirstValueArray) {
      obj[firstKey].forEach((key, idx) => {
        let errorObj = validateJSONArray(key, idx, Object.keys(obj)[0])
        if (errorObj) {
          validationErrors.push(errorObj);
        }
      })
      return;
    }

    // Check if type is "ecommerce"
    const ismanufacturerDetails = obj.type === "manufacturerDetails";

    // Check if type is "ecommerce"
    const isEcommerce = obj.type === "ecommerce";

    // Check if type is "videoUrl"
    const isVideoUrl = obj.type === "videoUrl" || obj.type === "gs1DigitalLinkStructure";

    // Check if name is "certifications"
    const isCertifications = obj.name === "certifications";

    // Check if name is "certifications"
    const isTabNameAndType = ["linkedUpids", "audio", "QrCodeViewer", "GrainpassData", "UserInstruction", "uniqueIdentifier"].includes(obj.name);


    // Check if tooltipText is an empty string
    const isLabelEmpty = obj.label === "";

    let labelSkipFields = ['multimedia', "Postal Address"];
    let isLabelSkip = labelSkipFields.includes(section) || labelSkipFields.includes(obj?.subsection);


    // Check for the presence of each required field in the object
    if (!ismanufacturerDetails && !obj.name) missingFields.push('name');
    if (!obj.type && !isTabNameAndType) missingFields.push('type');
    if (!obj.tabName && !isTabNameAndType && !isVideoUrl) missingFields.push('tabName');
    if ((!isEcommerce && !isLabelSkip && !ismanufacturerDetails && !isVideoUrl && !isCertifications) && obj.isMultilingual == undefined && !obj.isMultilingual) missingFields.push('isMultilingual');
    if (!isEcommerce && !isLabelSkip && !ismanufacturerDetails && !isLabelEmpty && !obj.label) missingFields.push('label');
    if (!isEcommerce && obj.readOnly == undefined && !obj.readOnly) missingFields.push('readOnly');


    // If any required field is missing, collect the field names in the errors array
    if (missingFields.length > 0) {
      return { index, section, missingFields, obj: obj.name, type: obj.type }
    }
  };

  /** Function for retrieving template validation errors---------------------------- */
  const getTemplateValidationError = (addTemplateObject) => {
    addTemplateObject?.forEach((obj, idx) => {
      let errorObj = validateJSONArray(obj, idx)
      if (errorObj) {
        validationErrors.push(errorObj);
      }
    })
    return validationErrors;
  }

  /** Function execute when click on submit---------------------------------------- */
  const handleModalSubmit = async () => {
    const validationErrors = getTemplateValidationError(addTemplateObject?.template)

    // Output validation errors, if any
    if (validationErrors.length > 0) {
      setTemplateValidationErrors(validationErrors)

    } else {
      setTemplateValidationErrors([])
    }
    if (addTemplateObject?.name == "") {
      setTemplateNameError(t("Template name required"))
    }
    if (addTemplateObject?.label == "") {
      setTemplateLabelError(t("Template label required"))
    }
    if (addTemplateObject?.name !== "" && addTemplateObject?.label !== "" && validationErrors?.length == 0) {
      try {
        await dispatch(addProductTemplateData(addTemplateObject)).unwrap();
        handleClose();
        toast.success(t("Template added succesfully"))
        await getProductTemplates();
      } catch (error) {
        console.log(error)
      }
    }

  }

  /** Function executes to copy generic template data----------------------------------- */
  const handleCopyGenericTemplateDataClick = async () => {
    let result = await dispatch(getIndividualProductTemplateData("generic"))
    setAddTemplateObject({ ...addTemplateObject, template: result?.payload?.template })
    setTemplateValidationErrors([])
  }

  return (
    <>
      <div className="dashboard-main-div">
        <div className="subdiv-1">
          <span className="dashboard-span-text">{t("Templates")}</span>
          <div className="rightside-div d-none d-sm-flex">
            <IconButton
              text={t("Add Template")}
              icon={MdAddCircle}
              onClick={() => handleAddTemplateClick()}
            />
          </div>
        </div>
        <div className="st-divider divider-thin"></div>
        <div className="homepage-main-subdiv">
          <TemplatesTable
            templatesList={_state.allProductTemplate}
            loading={isLoading}
          />
        </div>
      </div>

      <Modal size="lg" show={show} onHide={handleClose} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row col-12 m-0">
            <span className="col-12 mb-2  product-profile model-text">{t("Add New Template")}</span>
            <div className="d-flex flex-row">
              <InputTextWithLabel
                label={t('Template Name')}
                tooltipText={t("Template Name")}
                onChange={handleFormChange}
                name={'name'}
                value={addTemplateObject.name}
                type="text"
                placeholder={t("Template Name")}
                touched={true}
                errorMessage={templateNameError ? templateNameError : ""}
              ></InputTextWithLabel>

              <InputTextWithLabel
                label={t('Template Label')}
                tooltipText={t("Template Label")}
                onChange={handleFormChange}
                name={'label'}
                value={addTemplateObject.label}
                type="text"
                placeholder={t("Template Label")}
                touched={true}
                errorMessage={templateLabelError ? templateLabelError : ""}
              ></InputTextWithLabel>
            </div>


            <div className={`form-group inputTextWithLabel"} mt-2`}>
              <div className="col inputTextWithLabel__label col-12 justify-content-start pb-3">
                <div className="col-md-6 d-flex flex-row">
                  <label className="form-label" >
                    {t("Template Body")}
                  </label>
                  <CustomTooltip
                    tooltipContent={<p>{t("Template Body")}</p>}
                  />
                </div>
                <div className="col-md-6 d-flex justify-content-end ms-1">
                  <button type="button" onClick={() => handleCopyGenericTemplateDataClick()} style={{ fontSize: 14 }}>{t("Copy generic template")}</button>
                </div>
              </div>

              <div className="mt-2"
                style={{ paddingLeft: "26px", paddingRight: "26px" }}
              >
                <Editor
                  height={"50vh"}
                  language="json"
                  theme="vs-light"
                  onValidate={(markers) => {
                    if (markers.length > 0) {
                      setJsonObjError(true);
                    } else {
                      setJsonObjError(false);
                    }
                  }}
                  value={JSON.stringify(addTemplateObject?.template, null, 3)}
                  onChange={(value) => {
                    try {

                      const parsedValue = JSON.parse(value);
                      handleTemplateEditClick(parsedValue)


                    } catch (error) {
                      // console.error('Invalid JSON:', error);
                    }
                  }}
                  options={{
                    inlineSuggest: true,
                    fontSize: "16px",
                    formatOnType: true,
                    formatOnPaste: true,
                    autoClosingBrackets: true,
                    minimap: { enabled: true, scale: 20 },
                  }}
                />
                <div className="d-flex justify-content-between">
                  <div>
                    {jsonObjError ?
                      <div className="mt-4 text-danger" style={{ fontSize: "14px" }}>{t("Invalid JSON input. Please correct the errors before save.")}</div>
                      :
                      <>
                        {templateValidationErrors.length > 0 && (
                          templateValidationErrors.map((error, index) => (
                            <div className="mt-2 text-danger" style={{ fontSize: "14px" }} key={index}>
                              {`${index + 1}>`}{error?.section ?
                                <span>
                                  <span>Section - </span>
                                  <span style={{ fontWeight: "bold" }}>{error.section}</span>
                                </span>
                                : ""}&nbsp;
                              Object {error?.obj ?
                                (
                                  <>
                                    <span>Name - </span>
                                    <span style={{ fontWeight: "bold" }}>{error.obj}</span>
                                  </>
                                )
                                : error?.type ?
                                  (
                                    <>
                                      <span>Type - </span>
                                      <span style={{ fontWeight: "bold" }}>{error.type}</span>
                                    </>
                                  )
                                  :
                                  (
                                    <>
                                      <span>at index - </span>
                                      <span style={{ fontWeight: "bold" }}>{error.index}</span>
                                    </>
                                  )
                              }: Missing fields - {error.missingFields.join(', ')}
                            </div>
                          ))




                        )}
                      </>
                    }
                  </div>
                </div>
              </div>


            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StCancelSquareButton
            text={t("Cancel")}
            onClick={() => handleClose()}
          ></StCancelSquareButton>
          <StSquareButton loading={_state?.isAddtemplateLoading}
            text={t("Submit")}
            onClick={() => handleModalSubmit()}
          ></StSquareButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Templates;
