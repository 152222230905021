import React, { useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { MdOutlineModeEdit, } from "react-icons/md";
import {  getAllCmsListContentAction } from 'src/redux/contentManagement/contentManagementThunk';
import { Spinner } from "src/components/Spinner";
import { TabAccordion } from 'src/components/TabAccordion';
import { useTranslation } from 'react-i18next';
import { StSquareButton } from 'src/components/StSquareButton';
import { Dropdown } from 'semantic-ui-react';

function ContentManagementList() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [cmsCategoriesList,setCmsCategoriesList]= useState([]);
  const [dataToFilter, setDataToFilter]= useState({});

  const cmsContent = useSelector((state) => state.cmsContent);
  const companyState = useSelector((state) => state.company, shallowEqual);
  const { isLoading, allCmsContentList,allCmsCategoriesList } = cmsContent


  useEffect(() => {
    dispatch(getAllCmsListContentAction());
    return () => {

    }
  }, [])

  useEffect(()=>{
    let categories =[];
    if(allCmsCategoriesList.categories){
      allCmsCategoriesList.categories.forEach((category,i) => {
        categories.push({key: i, text: category, value: category});
      });
      setCmsCategoriesList(categories)
    }
  },[cmsContent.allCmsCategoriesList])

  const handleEditContent = (item) => {
    navigate(`/cms/edit/${item.id}`)
  }


  const accordionCmsContentList = allCmsContentList && allCmsContentList.map((item, index) => {
    const object = {
      title: (
        <span>
          <MdOutlineModeEdit style={{ fontSize: "1.2rem", marginRight: "1rem" }} onClick={() => handleEditContent(item)} />
          {item.title}
        </span>
      ),
      tabs: () => (
        <div key={index} className="mt-2">
          <div>

          </div>
          <div dangerouslySetInnerHTML={{ __html: item.content }} />
        </div>
      )
    }

    return object
  })

  const handleFilterChange=(e,{ name, value })=>{
    setDataToFilter({...dataToFilter, [name]: value});
  }

  const handleFilterButtonClick=()=>{
    let queryParams;
    if(dataToFilter.category &&  dataToFilter.language){
      queryParams = `category=${dataToFilter.category}&language=${dataToFilter.language}`;
    }else if(dataToFilter.category){
      queryParams = `category=${dataToFilter.category}`;
    }else{
      queryParams = `language=${dataToFilter.language}`;
    }
    dispatch(getAllCmsListContentAction(queryParams));
  }
  
  const handleResetButtonClick =()=>{
    setDataToFilter({});
    dispatch(getAllCmsListContentAction());
  }

  const getSupportedLanguageList = () => {
    if (companyState.companyInfo && companyState.companyInfo.supportedLanguages && companyState.companyInfo.supportedLanguages[0]) {
      const languageOption = companyState.companyInfo.supportedLanguages.map((item, index) => {
        return { key: index, text: item.name, value: item.code }
      })
      return languageOption
    }
    else {
      const languageOption = [
        { key: 1, text: "English", value: "en" },
        // { key: 2, text: "Finnish", value: "fi" },
      ]
      return languageOption
    }
  }
  const supportedLanguageOption =companyState.companyInfo && getSupportedLanguageList();

  return (
    <div>
      <>
       <div style={{display:'flex',flexWrap:'wrap',margin:10,justifyContent:'center'}} >
              <Dropdown
                 selection
                placeholder={t('Select category')}
                name="category"
                value={dataToFilter.category ? dataToFilter.category : null}
                options={cmsCategoriesList}
                onChange={handleFilterChange}
                className='ms-3 m-1'
              />
              <Dropdown
                selection
                placeholder={t('Select language')}
                name="language"
                value={dataToFilter.language ? dataToFilter.language : null}
                options={supportedLanguageOption}
                onChange={handleFilterChange}
                className='ms-3 m-1'
              />
              <div className='ms-3 d-flex'>
               <StSquareButton text={t("Filter")} disabled={(dataToFilter.category || dataToFilter.language) ? false : true} onClick={() => handleFilterButtonClick()} ></StSquareButton> 
               <StSquareButton text={t("Reset")} onClick={() => handleResetButtonClick()} ></StSquareButton>
              </div>
            </div>

        {isLoading ? <Spinner /> : 
          <div>
            {accordionCmsContentList && accordionCmsContentList.length==0 ? 
              <div className='d-flex justify-content-center'>{t("No records")}</div> :
              <TabAccordion accordionData={accordionCmsContentList}></TabAccordion>}
          </div>}
         </>     
    </div>
  )
}

export default ContentManagementList