import _ from "lodash";

const dev = {
  SERVER_URL: "https://dev.api.2nd.gdn/v3",
  pdmUrl: 'https://dev.pdm.upids.io',
  b2cAppUrl: 'https://dev.upids.io/',
  // iamServerUrl: 'https://europe-west3-upids-v2.cloudfunctions.net/iam',
  iamServerUrl: 'https://prod.api.2nd.gdn/v3/iam',
  upidsMasterData: 'https://europe-west3-upids-v2.cloudfunctions.net/upidsmasterdata',
  cloudRunUrl: "https://upload-dev.api.2nd.gdn",
  googleMapApiKey: "AIzaSyDbcIyuhGR89nQ7ZuFXtOF5bZBIlCt-3G8"
};

const qa = {
  SERVER_URL: "https://dev.api.2nd.gdn/v3",
  pdmUrl: 'https://dev.pdm.upids.io',
  b2cAppUrl: 'https://dev.upids.io/',
  // iamServerUrl: 'https://europe-west3-upids-v2.cloudfunctions.net/iam',
  iamServerUrl: 'https://prod.api.2nd.gdn/v3/iam',
  upidsMasterData: 'https://europe-west3-upids-v2.cloudfunctions.net/upidsmasterdata',
  cloudRunUrl: "https://upload-dev.api.2nd.gdn",
};

const prod = {
  SERVER_URL: "https://prod.api.2nd.gdn/v3",
  pdmUrl: 'https://pdm.upids.io',
  b2cAppUrl: 'https://upids.io/',
  // iamServerUrl: 'https://europe-west3-upids-v2.cloudfunctions.net/iam',
  iamServerUrl: 'https://prod.api.2nd.gdn/v3/iam',
  upidsMasterData: 'https://europe-west3-upids-v2.cloudfunctions.net/upidsmasterdata',
  cloudRunUrl: "https://upload-prod.api.2nd.gdn",
  googleMapApiKey: "AIzaSyDbcIyuhGR89nQ7ZuFXtOF5bZBIlCt-3G8"
};

// const environment = (process && process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV : "dev"
const environment = "dev"
// const environment = "prod"

const socialMediaUrlTypes = [
  { value: "Facebook", label: "Facebook" },
  { value: "Instagram", label: "Instagram" },
  { value: "Twitter", label: "Twitter" },
  { value: "TikTok", label: "TikTok" }
]

const taxonomyDataTypes = [
  { value: "string", text: "String" },
  { value: "number", text: "Number" },
  { value: "boolean", text: "Boolean" },
  { value: "map", text: "Map" },
  { value: "array", text: "Array" },
  { value: "reference", text: "Reference" },
  { value: "geopoint", text: "Geopoint" },
  { value: "timestamp", text: "Timestamp" },
]

const taxonomyCategoryTypes = [
  { value: "masterdata", text: "MasterData" },
  { value: "item specific", text: "Item Specific" },
  { value: "custom", text: "Custom" },
  { value: "common", text: "Common" },
]

const appConfig = {
  // Add common appConfig values here
  socialMediaUrlTypes,
  taxonomyDataTypes,
  taxonomyCategoryTypes,
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(environment === "prod" ? prod : environment === "qa" ? qa : dev),
};

export default appConfig;