import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StSearchBox } from "src/components/StSearchBox";
import CertificationsTable from "./certificationsTable/CertificationsTable";
import { getAllCertificationsData } from "src/redux/certifications/certificationsThunk";

const Certifications = () => {
  /** redux store useDispatch & useSelector ------------------------------ **/
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const _state = useSelector((state) => state.certifications, shallowEqual);

  /** hooks useState ----------------------------------------------------- **/
  const [searchText, setSearchText] = useState("");
  const [allCertificateList, setAllCertificateList] = useState([])
  
  /** Variable declaration ----------------------------------------------------- **/
  const isLoading = _state.actionsLoading;

  /** hooks useEffect----------------------------------------------------- **/
  useEffect(() => {
    getAllCertificateData();
  }, [searchText])

  /** handler functions--------------------------------------------------- **/

  /** Function to retrieve all cretificate data and also filter based on the search text value-------------- */
  const getAllCertificateData = async () => {
    if (searchText === "") {
      let result = await dispatch(getAllCertificationsData());
      setAllCertificateList(result.payload)
    } else {
      const timeOut = setTimeout(() => {
        clearTimeout(timeOut)
        const newFilter = _state?.CertificationsData?.filter((value) => {
          return (
            value.name.toLowerCase().includes(searchText.toLowerCase())
          );
        });
        setAllCertificateList(newFilter);
      }, 1000)
      return () => clearTimeout(timeOut)
    }
  }


  return (
    <div className="homepage-main-div ps-2">
      <div className="row col-12 homepage-search-input-div pt-3" style={{ paddingLeft: 0 }}>
        <div className=" col-lg-6 col-md-6 ">
          <StSearchBox
            iconName={searchText === "" ? t("Search") : t("Close")}
            width={'100%'}
            placeholder={t("Search by Certificate Name")}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onClick={() => setSearchText("")}
          />
        </div>
      </div>

      <div style={{ marginTop: 76, position: 'fixed' }} className='st-divider divider-thin'></div>
      <div className="homepage-main-subdiv">
        <CertificationsTable
          getAllCertificateData={getAllCertificateData}
          certificationsList={allCertificateList}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default Certifications;
