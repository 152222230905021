import React, { useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { AppContext } from '../../AppContext';
import * as Store from "src/helper/localStorageHelper";
import {  resetFavouritesList  } from "src/redux/favourites/favouritesSlice";
import { MdMenu, MdOutlineModeEdit, MdList, MdPowerSettingsNew } from "react-icons/md";
import logo from 'src/assets/images/brand_icons/upids-pdm-logo.png'
import { StMenu } from "src/components/StMenu";
import { IconButton } from "src/components/IconButton";
import objectPath from "object-path";
import { showAddUpdateVoucherModalAction } from "src/redux/admin/adminSlice";


const AdminNavbar = () => {

  const dasboardNavbarCollection = [
    { menuItem: () => <IconButton text={t("Edit Profile")} icon={MdOutlineModeEdit} onClick={() => editProfile()} /> },
    { menuItem: () => <IconButton text={t("Api")} icon={MdList} onClick={() => goToApiDocs()} /> },
    { menuItem: () => <IconButton text={t("Sign Out")} icon={MdPowerSettingsNew} onClick={() => logout()} /> },
  ]

  const cookies = new Cookies();
  const context = useContext(AppContext);

  let fullName = objectPath.get(context, 'authState.foreName', 'NA') + objectPath.get(context, 'authState.sureName', 'NA');

  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const searchCardRef = useRef();
  const [isSearching, setIsSearching] = useState(false);
  const [showSearchCard, SetShowSearchCard] = useState(false);
  const [searchText, SetSearchText] = useState("");
  const dispatch = useDispatch();
  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  // function toggleRightSidebar() {
  //   document.querySelector(".right-sidebar").classList.toggle("open");
  // }
  const logout = (evt) => {
    // evt.preventDefault();
    cookies.remove("AUTH_STATE");
    //Store.localStorageRemove(Store.Keys.AUTH_STATE);
    Store.localStorageRemove("persist:root");
    dispatch(resetFavouritesList());
    navigate("/login");
  };

  const editProfile = () => {
    navigate(`/myprofile/${context.authState.uId}`);
  }

  const goToApiDocs = () => {
    navigate("/swagger/private");
  }

  const handleAddVoucher = () => {
    dispatch(showAddUpdateVoucherModalAction(true))
  }

  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/dashboard">
            <img src={logo} alt="logo" />
          </Link>
          <Link
            className="navbar-brand brand-logo-mini d-inline-block d-lg-none"
            to="/"
          >
            <img
              src={require("../../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">
          <span
            style={{
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            <Trans>{t("Product Traceability Management")}</Trans>
          </span>
          <ul className="navbar-nav navbar-nav-right">

            <button type="button"
              onClick={() => handleAddVoucher()}
              className="btn text-uppercase form-button"
              style={{ background: "#cf2948", height: 40, color: "#ffffff" }}
            >
              {t("Add voucher")}
            </button>
            {/* <li className="nav-item nav-profile d-sm-flex">

              <span>
                {!!context.authState
                  ? context.authState.foreName + " " + context.authState.sureName
                  : ""}
              </span>
              <StMenu menuArray={dasboardNavbarCollection}></StMenu>
            </li> */}
          </ul>

          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
            <MdMenu color="#000000" size={24} />
          </button>
        </div>
      </nav>
    </>
  );
};

export default AdminNavbar;
