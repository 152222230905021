import { createSlice } from "@reduxjs/toolkit";
import {
  startMediaUplaodAction, getAllProductImagesAction, uploadProductFileAction,
  deleteImageAction, getImageDownload, uploadCompanyLogoImage, deleteCompanyLogoImage
} from "./mediaThunk";

const initialMediaState = {
  actionsLoading: false,
  response: null,
  error: null,
  allFeedbacks: [],
  productFeedbacks: [],
  feedbackDetail: {},
  isLoading: false,
  isMediaDownloading: false,
  deleteImageResponse: null,
  isMediaDeleting: false,
  downloadImageData: null,
  uploadResponse: null,
  mediaUploadState: { otherImageUploading: false, profileImageUploading: false, documentUploading: false, profileImageType: 'NA', companyLogoImageUploading: false, _3dModelUploading: false }
};

export const mediaSlice = createSlice({
  name: "media",
  initialState: initialMediaState,
  reducers: {
    //Catch error if any
    catchError: (state, action) => {
      console.log("Slice error : ", action.payload)
      state.error = `${action.type}: ${action.payload}`;
      state.actionsLoading = false;
      state.mediaUploadState = { otherImageUploading: false, profileImageUploading: false, documentUploading: false, profileImageType: 'NA', companyLogoImageUploading: false, _3dModelUploading: false }
    },
    //Starting of a call
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    startLoadingToRefreshPage: (state, action) => {
      state.error = null;
      // state.actionsLoading = true;
    },

    startMediaDownload: (state, action) => {
      state.error = null;
      state.isMediaDownloading = true;
    },
    startMediaDelete: (state, action) => {
      state.error = null;
      state.isMediaDeleting = true;
    },
    startMediaUplaod: (state, action) => {

      switch (action.payload) {
        case 'primary':
        case 'backside':
        case 'frontal':
          state.mediaUploadState = { ...initialMediaState.mediaUploadState, profileImageUploading: true };
          break;
        case 'fromOtherImagesSection':
          state.mediaUploadState = { ...initialMediaState.mediaUploadState, otherImageUploading: true };
          break;
        case 'from3dModelSection':
          state.mediaUploadState = { ...initialMediaState.mediaUploadState, _3dModelUploading: true };
          break;
        case 'fromVideosSection':
          state.mediaUploadState = { ...initialMediaState.mediaUploadState, videoUploading: true };
          break;
        case 'pdf':
          console.log('FROM IMAGE UPLOAD SLICE', action.payload);
          state.mediaUploadState = { ...initialMediaState.mediaUploadState, documentUploading: true };
          break;

        case 'companyLogo':
          state.mediaUploadState = { ...initialMediaState.mediaUploadState, companyLogoImageUploading: true };
          break;

        default:
          state.mediaUploadState = { ...initialMediaState.mediaUploadState };
          break;
      }
    },
    //Upload product image data fetched
    uploadProductImageDone: (state, action) => {
      //state.isLoading = false;
      state.error = null;
      state.mediaUploadState = initialMediaState.mediaUploadState;
      state.uploadResponse = action.payload.data;
    },

    // All product images data fetched
    getAllProductImagesDone: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.getAllProductImages = action.payload.data;
    },
    getImageDownloadFetched: (state, action) => {
      state.isMediaDownloading = false;
      state.downloadImageData = action.payload.data;
    },
    deleteImageFetched: (state, action) => {
      state.isMediaDeleting = false;
      state.deleteImageResponse = action.payload.data;
    },
    changeAllProductImagesData: (state, action) => {
      state.getAllProductImages = {}
    },
  },


  extraReducers:
    (builder) => {
      builder
        .addCase(startMediaUplaodAction, (state, action) => {
          switch (action.payload) {
            case 'primary':
            case 'backside':
            case 'frontal':
              state.mediaUploadState = { ...initialMediaState.mediaUploadState, profileImageUploading: true };
              break;
            case 'fromOtherImagesSection':
              state.mediaUploadState = { ...initialMediaState.mediaUploadState, otherImageUploading: true };
              break;
            case 'from3dModelSection':
              state.mediaUploadState = { ...initialMediaState.mediaUploadState, _3dModelUploading: true };
              break;
            case 'fromVideosSection':
              state.mediaUploadState = { ...initialMediaState.mediaUploadState, videoUploading: true };
              break;
            case 'pdf':
              console.log('FROM IMAGE UPLOAD SLICE', action.payload);
              state.mediaUploadState = { ...initialMediaState.mediaUploadState, documentUploading: true };
              break;

            case 'companyLogo':
              state.mediaUploadState = { ...initialMediaState.mediaUploadState, companyLogoImageUploading: true };
              break;

            default:
              state.mediaUploadState = { ...initialMediaState.mediaUploadState };
              break;
          }
        })

        .addCase(getAllProductImagesAction.pending, (state, action) => {
          const arg = action.meta.arg

          if (arg && arg.isLoaderShow) {
            state.error = null;
            state.actionsLoading = true;
          }
          else {
            state.error = null;
          }
        })
        .addCase(getAllProductImagesAction.fulfilled, (state, action) => {
          state.error = null;
          state.actionsLoading = false;
          state.getAllProductImages = action.payload;
        })
        .addCase(getAllProductImagesAction.rejected, (state, action) => {
          state.error = action.payload;
          state.mediaUploadState = initialMediaState.mediaUploadState;
          state.actionsLoading = false;
        })



        .addCase(uploadProductFileAction.pending, (state) => {
          // state.isLoading = true;
        })
        .addCase(uploadProductFileAction.fulfilled, (state, action) => {
          state.error = null;
          state.mediaUploadState = initialMediaState.mediaUploadState;
          state.uploadResponse = action.payload;
        })
        .addCase(uploadProductFileAction.rejected, (state, action) => {
          state.error = action.payload;
          state.mediaUploadState = initialMediaState.mediaUploadState;
          state.actionsLoading = false;
        })


        .addCase(deleteImageAction.pending, (state) => {
          state.error = null;
          state.isMediaDeleting = true;
        })
        .addCase(deleteImageAction.fulfilled, (state, action) => {
          state.isMediaDeleting = false;
          state.deleteImageResponse = action.payload;
        })
        .addCase(deleteImageAction.rejected, (state, action) => {
          state.error = action.payload;
          state.mediaUploadState = initialMediaState.mediaUploadState;
          state.actionsLoading = false;
          state.isMediaDeleting = false;
        })


        .addCase(getImageDownload.pending, (state) => {
          state.error = null;
          state.isMediaDownloading = true;
        })
        .addCase(getImageDownload.fulfilled, (state, action) => {
          state.isMediaDownloading = false;
          state.downloadImageData = action.payload;
        })
        .addCase(getImageDownload.rejected, (state, action) => {
          state.error = action.payload;
          state.mediaUploadState = initialMediaState.mediaUploadState;
          state.actionsLoading = false;
          state.isMediaDownloading = false;
        })


        .addCase(uploadCompanyLogoImage.pending, (state) => {

        })
        .addCase(uploadCompanyLogoImage.fulfilled, (state, action) => {
          state.error = null;
          state.mediaUploadState = initialMediaState.mediaUploadState;
          state.uploadResponse = action.payload;
        })
        .addCase(uploadCompanyLogoImage.rejected, (state, action) => {
          state.error = action.payload;
          state.mediaUploadState = initialMediaState.mediaUploadState;
          state.actionsLoading = false;
        })

        .addCase(deleteCompanyLogoImage.pending, (state) => {
          state.error = null;
          state.isMediaDeleting = true;
        })
        .addCase(deleteCompanyLogoImage.fulfilled, (state, action) => {
          state.isMediaDeleting = false;
          state.deleteImageResponse = action.payload;
        })
        .addCase(deleteCompanyLogoImage.rejected, (state, action) => {
          state.error = action.payload;
          state.mediaUploadState = initialMediaState.mediaUploadState;
          state.actionsLoading = false;
        })


    },
});


export const { changeAllProductImagesData } = mediaSlice.actions