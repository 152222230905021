import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";


import { useTranslation } from "react-i18next";
import { StCloseButton } from 'src/components/StCloseButton';
import { StSquareButton } from 'src/components/StSquareButton';
import { StCancelSquareButton } from 'src/components/StCancelSquareButton';

const ModalWithSaveCancel = (props) => {
    const { show, handleModalClose, handleSaveChanges, modalBody, modalOkText, modalCancelText } = props

    const { t } = useTranslation('common');
    const [isLoading, setIsLoading] = useState(false)

    const handleOnclickSaveButton = async () => {
        setIsLoading(true)
        await handleSaveChanges()
        setIsLoading(false)
    }


    return (
        <div>
            <Modal size="md" show={show} onHide={handleModalClose} centered={true}>
                <Modal.Header >
                    <div style={{ position: 'absolute', right: 10 }}>
                        <StCloseButton text={t("Close")} onClick={() => handleModalClose()}></StCloseButton>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18,  }}>
                    <span >
                        {
                            modalBody ? modalBody : t("Are you sure want to save the changes")
                        }
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <StSquareButton text={modalOkText ? modalOkText : t("Save Changes")} onClick={() => handleOnclickSaveButton()} loading={isLoading ? true : false} disabled={false}></StSquareButton>
                    <StCancelSquareButton text={modalCancelText ? modalCancelText : t("Cancel")} onClick={() => handleModalClose()}></StCancelSquareButton>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default ModalWithSaveCancel