import React, { useState, useRef, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { newPasswordSchema } from '../../../helper/yup-schema'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import VisiblityWrapper from '../component/VisiblityWrapper';
import { MdCheckCircle } from 'react-icons/md';
import { newPassword } from "src/redux/auth/authThunk";
import { useTranslation } from 'react-i18next';
import { Spinner } from "react-bootstrap";
var qs = require('qs');


const NewPassword = (props) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  const { params } = useParams();
  const dispatch = useDispatch();
  const { oobCode } = qs.parse(location.search, { ignoreQueryPrefix: true })
  !(!!oobCode) && navigate('/login');
  const { register, handleSubmit, watch, formState: { errors }, } = useForm({
    resolver: yupResolver(newPasswordSchema)
  });
  const [newPassDone, setNewPassDone] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [repasswordType, setRePasswordType] = useState('password');
  const [restDone, setResetDone] = useState(false)
  const onSubmit = (data) => {
    dispatch(newPassword({ oobCode: oobCode, password: data.password }));
  };
  const _state = useSelector(
    (state) => (state.login),
    shallowEqual
  );

  useEffect(() => {
    _state.newPassDone && setNewPassDone(true)
  }, [_state])
  return (
    <>
      {!newPassDone ?

        <div>
          <p className="content-subtitle-new-pass">{t('Reset Password')}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VisiblityWrapper setPasswordType={setPasswordType} ><input name='password' {...register("password")} className={"login-input"} type={passwordType} placeholder={t('Type New Password')} /></VisiblityWrapper>
            <span className="login-error-text"> {!!errors.password && errors.password.message}</span>
            <VisiblityWrapper setPasswordType={setRePasswordType} ><input name='passwordConfirmation' {...register("passwordConfirmation")} className={"login-input"} type={repasswordType} placeholder={t('Re-Type New Password')} /></VisiblityWrapper>
            <span className="login-error-text"> {!!errors.passwordConfirmation && errors.passwordConfirmation.message}</span>
            <button type='submit' className="login-button">
              {!_state.actionsLoading ? (
                t("SUBMIT")
              ) : (
                <Spinner animation="border" variant="light" />
              )}
            </button>
          </form>
          <div className="signup-container">
            <p onClick={() => navigate('/login')} style={{ flex: 1, cursor: 'pointer', paddingBottom: 50, textAlign: 'center' }} >{t('Log In')}</p>
          </div>
        </div>
        : <div>
          <p className="content-subtitle-new-pass">{t('Reset Password')}</p>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 33, marginBottom: 80 }}>
            <MdCheckCircle color='#33D16A' size={32} />
            <p style={{ alignSelf: 'center', fontSize: 14, color: '#33D16A', margin: 0, marginLeft: 8 }}>{t('Password Successfully changed')}</p>
          </div>
          <div style={{ marginBottom: 80 }}>
            <button onClick={() => navigate('/login')} className="login-button">{t('GO TO LOGIN')}</button>
          </div>
        </div>}
    </>
  )
}

export default NewPassword;