import React from 'react';
import { shallowEqual, useSelector } from "react-redux";
import { Spinner } from "src/components/Spinner";
import { StLineChart } from "src/components/StLineChart";
import { useTranslation } from "react-i18next";

const LineGraph = (props) => {

  const { lineData, productNotFound } = props
  const { t } = useTranslation('common');

  const { actionsLoading } = useSelector((state) => state.analytics, shallowEqual);
  const { isLoading } = actionsLoading;



  return (
    isLoading ? (
      <div>
        <Spinner />
      </div>
    ) :
      <div style={{ height: 500 }}>
        {
          props.title && <div className='text-center fw-bold pt-4' style={{ color: '#6c757d' }}>
            {!!productNotFound ? productNotFound : t("To see the map you first select company and then article")}
            <br></br>
            {props.title}
            </div>
        }

        <StLineChart
          lineData={lineData}
        />
      </div>
  )
}


export default LineGraph;