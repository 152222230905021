import * as requestFromServer from "./companyCrud";
import { companySlice } from "./companySlice";
import { getProductByIdAction } from "../products/productThunk";

const { actions } = companySlice;








export const checkEcomIdGenerated = (params) => (dispatch) =>{
  let checkEcomIdGeneratedModel = {
    url: params.ecommerceShopUrl,
    articleIdentifer: params.ecomReqModel.articleIdentifer,
    articleIdentiferType: params.ecomReqModel.articleIdentiferType
  }
  return requestFromServer
    .checkEcomIdGenerated(checkEcomIdGeneratedModel)
    .then((response) => {
      if(response.data.ecomId){
          dispatch(actions.stopLoadingForEcom());
          dispatch(actions.checkEcomIdGeneratedFetched(response))
            dispatch(getProductByIdAction({ isLoader: true, gtin: params.productId, isCancelBtn: true }))
      }else{
        dispatch(checkEcomIdGenerated(params))
      }
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Records not found.";
      dispatch(actions.catchError(error));
    });
}

export const setSelectedEcom = (params) => (dispatch) => {
  //dispatch(actions.startCall());
  dispatch(actions.startLoadingForEcom());
  dispatch(actions.ecommerceIntegrationData(params))
  return requestFromServer
    .setSelectedEcomServer(params.ecomReqModel)
    .then((response) => {
      if(params.from=="AddEcom"){
        setTimeout(() => {
          dispatch(checkEcomIdGenerated(params))
        }, 5000);
        
        // setTimeout(() => {
        //   dispatch(getProductByIdAction({ isLoader: true, gtin: params.productId, isCancelBtn: true }))
        //   dispatch(actions.stopLoadingForEcom());
        // }, 21000);
      }else{
          dispatch(getProductByIdAction({ isLoader: true, gtin: params.productId, isCancelBtn: true }))
          dispatch(actions.stopLoadingForEcom());
      }
      dispatch(actions.setSelectedEcomFetched(response));
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Records not found.";
      dispatch(actions.catchError(error));
    });
};

//Action call for deleting integration point from product
export const deleteEcomIntegrationPointFromProduct = (params) => (dispatch) => {
  dispatch(actions.startLoadingForEcom());
  return requestFromServer
    .deleteEcomIntegrationPointFromProduct(params.ecomReqModel)
    .then((response) => {
        dispatch(getProductByIdAction({ gtin: params.productId, isCancelBtn: true }))
        dispatch(actions.stopLoadingForEcom());
      dispatch(actions.deleteEcomIntegrationFromProductFetched(response));
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Records not found.";
      dispatch(actions.catchError(error));
    });
};
