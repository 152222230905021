
/**
 * @info product operation type
 */
export const ADD = "add";
export const EDIT = "edit";

export const REMOVE = "remove"



/**
 * @info productType
 */
export const FOOD_BEWERAGE = "foodbewerage";
export const TEXTILE = "textile";
export const SOFTWARE = "software"
export const GENERIC = "generic"

export const termsOfUse = {
      en: `<h1>UPIDS general terms of use</h1>
          <br>

            <h2>DEFINITIONS</h2>
            <p><i>Customer</i> - an organization using the UPIDS Service.</p>
            <p><i>Customer Data</i> - data imported to, or created in, the UPIDS Service by the Customer.</p>
            <p><i>UPIDS Service</i> - the UPIDS system offered as a SaaS service with all the included standard modules and components, which are developed and delivered to the Customers by the Supplier. Use of the UPIDS Service is based on an order or a separate contract.</p>
            <p><i>Re-seller</i> - an entity functioning as an authorized UPIDS re-seller from which the Customer has acquired the UPIDS Service.</p>
            <p><i>User</i> - a natural person using the UPIDS Service after accepting the Terms of Use of the UPIDS Service.</p>
            <p><i>Module</i> - a standalone part of the UPIDS Service, such as UPIDS.IO (product data presentation to consumer), UPIDS PDM (product data management system), or UPIDS PLM (identifying information and product lifecycle management).</p>
            <p><i>Service charges</i> - UPIDS Service and user fees charged monthly in accordance with the contract concluded between the Supplier and Customer. Transaction-based fees are charged separately in accordance with the price list in force.</p>
            <p><i>Administrator</i> - a natural person appointed by the Customer who is granted the UPIDS Service administrator rights and who acts as the Customer's representative in UPIDS Service-related matters.</p>
            <p><i>Contract</i> - the contract concluded between the Supplier and Customer regarding the UPIDS Service.</p>
            <p><i>File</i> - any documents (images, videos and other attached files) the Customer has introduced into the Service.</p>
            <p><i>Data Processing Agreement</i> - an agreement concluded between the Supplier and Customer that determines in a manner established by the GDPR who has the right to access the Customer's data in the UPIDS Service, among other things.</p>
            <p><i>Supplier</i> - depending on the context, either Second Thought Oy or the Re-seller from which the Customer acquired the UPIDS Service.</p>
            <p><i>Transaction-based Service</i> - services used as part of the UPIDS Service, in which a single event forms the basis for charging. These include, for example, a unique product labelling  and updating of the data attached to it or transferring thereof to other systems.</p>
            
            <h2>APPLICATION</h2>
            
            <p>These General Terms of Use apply to Second Thought Oy's UPIDS Service ("UPIDS Service"). Customers can acquire the UPIDS Service either from Second Thought Oy or from its Re-
            seller. The Customer undertakes to use the UPIDS Service in accordance with these Terms of Use,
            the law and good practice.</p>
            <p>Any additional work requested by the Customer, as well as commissioning, development,
            installation, training, and other services possibly provided by the Supplier shall be agreed
            upon separately.</p>
            <p>The UPIDS Service is protected by Finnish and EU copyright and intellectual property
            rights. UPIDS is the registered trademark of Second Thought Oy in Finland and in the EU.
            The UPIDS Service may contain individual open source code software components subject
            to their own copyright and applicable license terms.</p>
            
            <h2>RIGHTS AND OBLIGATIONS OF THE SUPPLIER</h2>
            
            <p>The Supplier is responsible for provision of the UPIDS Service in accordance with the Contract, with care and the professional skills required for the provision of the UPIDS
            Service. The Supplier has the right to provide the UPIDS Service as it sees fit. The Supplier has the right to use subcontractors for the provision of the UPIDS Service.
            Second Thought Oy has the right to change the contents of the UPIDS Service without prior notice if this is necessary due to changes in legislation or for data security or other reasons.
            In such cases, efforts are made to notify about the changes as soon as possible afterwards. The notification obligation does not apply to technical changes, such as software updates.
            Second Thought Oy and the Supplier are not obliged to compensate the Customer for any damage caused by the aforementioned temporary suspension of the Service or changes in
            the Service contents or to reduce or annul payments.</p>
            <p>Second Thought Oy and the Supplier have the right to prevent access to the UPIDS Service
            with the Customer's user ID without notification of the Customer if the Supplier has reason to
            suspect that the user ID is being used for loading of the UPIDS Service or using the UPIDS
            Service in violation of the Contract or in a manner that endangers the supply of the UPIDS
            Service.</p>
            
            <h2>RIGHTS AND OBLIGATIONS OF THE CUSTOMER</h2>
            
            <p>The Customer has the right to use the UPIDS Service in compliance with these Terms of
            Use, the law and good practice. The Customer is responsible for ensuring that any works and
            measures for which the Customer is liable are carried out in accordance with the Contract
            and with care.</p>
            <p>The Customer is responsible for ensuring that each User using the UPIDS Service under the
            Contract accepts the UPIDS Service Terms of Use before using the UPIDS Service and
            complies with the terms when using the UPIDS Service. The Customer is responsible for the
            rights, obligations, and restrictions related to the use and management of user IDs and
            passwords created using user administration tools. In some UPIDS Service environments,
            the new User creation and deletion rights of the Customer's Administrator may have been
            restricted.</p>
            
            <p>The Customer must provide the Supplier with correct and sufficient data for the provision and
            charging of the UPIDS Service (address, factors affecting pricing or the like) and notify the
            Supplier immediately of any changes in the data. The Customer is responsible for the data
            and instructions provided to the Supplier.</p>
            <p>The Customer must prevent unauthorised access and use of the UPIDS Service. The
            Customer is responsible for the acquisition and condition of the equipment, connections, and
            software required to use the UPIDS Service and for ensuring that these do not cause
            inconvenience, harm, or damage to the Supplier or other users of the UPIDS Service. The
            Customer is responsible for its communication and other respective expenses related to the
            use of the UPIDS Service. The Customer is responsible for ensuring that the Customer's
            equipment, connections, software, and data systems comply with the system requirements of
            the UPIDS Service provided by the Supplier. The Customer is responsible for the data
            security of its data system and communications network. The Customer is the controller of
            the personal data processed under the UPIDS Service for the purposes of the Personal Data
            Act. The Customer is responsible for the materials stored in the UPIDS Service, as well as for
            that the materials do not violate the rights of third parties or the legislation in force and the EU General Data Protection Regulation.
            The data in the Customer's UPIDS Service is always the property of the Customer, for which
            reason the Customer is also responsible for all the data in its UPIDS Service, use of the data
            for various purposes, further distribution of the data, access rights to the data, and
            compliance of all practices with the EU General Data Protection Regulation and local
            legislation. Second Thought Oy has the right to use public product data stored in the
            Customer's system in its own business.</p>
            <p>Second Thought Oy is responsible for the liabilities and processing of personal data under
            the EU General Data Protection Regulation.</p>
            <p>The customer is responsible for the removal from its UPIDS Service of such personal data for
            the storage of which there is no legal prerequisite. The Customer is responsible for ensuring
            there are no shared user IDs. Each User must be identifiable on the personal level.</p>
            
            <p>During the validity of the Contract, the Customer may change its data in the UPIDS Service
            and extract data from the UPIDS Service on its own. The Customer should also bear in
            mind that it is responsible for the actions of third parties related to its UPIDS Service as for
            its own actions. These third parties may include, for example, users outside the Customer's
            organisation or third parties accessing the Customer's UPIDS Service through integration
            interfaces. Furthermore, with respect to its UPIDS Service data, the Customer must take
            into account the retention period of different data and remove outdated data from the UPIDS
            Service.</p>
            
            <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
            
            <p>Ownership, copyright and all other rights related to the UPIDS Service, its outcomes and
            products belong exclusively to Second Thought Oy and/or third parties, including but not
            limited to source and other codes, user manuals, training materials, and all other materials
            related to the UPIDS Service. However, the Customer is entitled to use the UPIDS Service
            and its outcomes in accordance with the Contract in its own activities for its own benefit.
            Second Thought Oy is responsible for ensuring that the UPIDS Service does not violate the
            valid intellectual property rights of third persons. Second Thought Oy is not responsible for
            claims arising from that the UPIDS Service has been used for a purpose for which it has
            not been intended or approved or from that the UPIDS Service has been used in violation
            of the Contract or in violation of applicable legislation. Second Thought Oy is also not
            responsible for claims arising from changes made by the Customer in the UPIDS Services,
            from compliance with the instructions given by the Customer, or from use of the UPIDS
            Services together with a product or UPIDS service other than approved by Second Thought
            Oy.</p>
            <p>The Customer is required to notify the Supplier immediately if a third party claims that the
            UPIDS Service infringes its intellectual property rights. If Second Thought Oy should find
            that the UPIDS Service or a part thereof infringes the third party rights referred to above,
            the Supplier has the right, at its own expense, to acquire for the Customer the right to
            continue using the UPIDS Service, to change the UPIDS Service or part thereof, or to
            change the UPIDS Service to end the infringement. If none of the options above is
            reasonably feasible, the Customer must discontinue the use of the UPIDS Service or part
            thereof at the Supplier's request.</p>
            
            <h2>BACKUP COPIES</h2>
            <p>The Supplier shall take care of appropriate backups. All data shall be backed up in real time.</p>
            
            <h2>CONFIDENTIALITY</h2>
            <p>The parties commit to abstain from disclosing the other party's confidential data to third
            persons without the other party's written consent. The parties shall make sure that all
            employees and representatives of the parties comply with the above commitment of
            confidentiality.</p>
            <p>A Re-seller may provide Customer-related data to Second Thought Oy to the extent
            necessary for the provision of the UPIDS Service in accordance with the Contract. The
            service is hosted by servers managed by Second Thought Oy and Second Thought Oy has
            access to the Customer's data.</p>
            
            <h2>PRICE AND INVOICING OF THE UPIDS SERVICE</h2>
            
            <p>The price of the UPIDS Service is as specified in the Supplier's valid price list or as
            agreed between the parties in the Contract. The Supplier has the right to change the
            pricing of the UPIDS Service and the payment criteria by notification about it at least
            three (3) months before the change takes effect. Price increases are notified to
            Administrators of the Customer's UPIDS Service by e-mail or mail. At its discretion, the
            Supplier is entitled to request a reasonable advance payment or collateral from the
            Customer. The value added tax is added to the prices in accordance with the legislation
            in force at the time. If the amount or charging principle of public charges established by
            the authorities should change due to changes in legislation or tax practice, the prices will
            be adjusted accordingly. The change will not affect payments for invoicing periods that
            began prior its entry into force. Cost increases arising from laws, regulations or actions
            of the authorities are effective immediately after entry into force of the legislation.
            The price of the UPIDS Service does not include travel expenses or other expenses and
            costs arising from travel in connection with provision, introduction, etc. of the UPIDS
            Service, such as accommodation expenses, car usage compensations and daily
            allowances. The Customer shall reimburse these separately to the Supplier. The maximum
            amounts of these compensations are based on the Tax Administration's decision on
            reimbursement of tax-free travel expenses in force at the time. The invoicing of travel time is
            agreed separately on a case-by-case basis.</p>
            <p>The charging of the UPIDS Service consists of a basic fee, user fee, and any additional fees.
            Payments are made against invoice. Unless otherwise, the invoicing period is a calendar
            month and invoicing takes place in advance at the beginning of each calendar month. Access
            to the UPIDS Service may be suspended if a Customer's invoice due for more than 3 months
            and the related accrued interest on arrears are not settled in full. The suspended access to
            the UPIDS Service will be restored after registered payment of the due sum with the interest
            on arrears and the UPIDS Service restoration fee specified in the price list.
            Interest on arrears: As provided for in Section 4a of the Interest Act. In connection with late
            payments, the Customer is also obliged to pay the related reminder and collection fees as
            specified in the price list.</p>
            
            <p>In the event of a dispute over an invoice, the undisputed part of the invoice must be paid by
            the due date. If the Customer uses Transaction-based Services as part of its UPIDS
            Service, Second Thought Oy is entitled to change the Service price accordingly by notifying
            the Customer at least two (2) weeks before the change takes effect. Such Transaction-
            based Services usable as part of the UPIDS Service include, for example, creation,
            updating, or reading of unique product identifiers from external systems. Transaction-based
            Services are charged to the Customer in accordance with the actual number of transactions
            once a month.</p>
            
            <h2>CONTRACT VALIDITY AND CANCELLATION</h2>
            
            <p>The Contract enters into force upon its signature or online sign-up and remains in force until further notice.
            Unless otherwise agreed in writing, both Parties have the right to terminate this Contract in
            writing with three months' notice. The term of notice is calculated from the last day of the
            calendar month in which the notice is given. The UPIDS Service will be invoiced until the end
            of the notice period.</p>
            <p>The Supplier has the right to terminate the Contract immediately, without a period of notice, if the Customer has failed to pay for the UPIDS Service for three separate calendar months.
            The Supplier is entitled to terminate the UPIDS Service or cancel the Contract immediately if
            the Customer is declared bankrupt or becomes permanently insolvent. The Supplier has the
            right to terminate the Contract or suspend the UPIDS Service if the UPIDS Service is used in
            violation of the Contract or the Terms of Use of the UPIDS Service or for any illegal activity or
            practice that may cause damage to the UPIDS Service, the Supplier, the Supplier's
            contractual partner, the Customer, a third party or bystander.</p>
            
            <p>T&C version 2022-03-17</p>`,

      fi: `<h1>UPIDS palvelun yleiset käyttöehdot</h1>
          <br>

                <h2>MÄÄRITELMÄT</h2>
                <p><i>Asiakas</i> on UPIDS-palvelua käyttävä organisaatio.</p>
                
                <p><i>Asiakasdatalla</i> tarkoitetaan tietoja, jotka Asiakas on tuonut tai luonut UPIDS-palveluun.</p>
                
                <p><i>UPIDS-palvelu</i> tarkoittaa SaaS-palveluna tarjottavaa UPIDS-järjestelmää kaikkine siihen kuuluvine vakiomoduuleineen ja -komponentteineen, joita kehitetään ja toimitetaan Asiakkaille Toimittajan toimesta. UPIDS-palvelun käyttö perustuu tilaukseen tai erilliseen sopimukseen.</p>
                
                <p><i>Jälleenmyyjä</i> on taho joka toimii UPIDS valtuuttamana jälleenmyyjänä ja jolta Asiakas on hankkinut UPIDS-palvelun.</p>
                
                <p><i>Käyttäjällä</i> tarkoitetaan luonnollista henkilöä, joka hyväksyttyään UPIDS-palvelun käyttöehdot käyttää UPIDS palvelua.</p>
                
                <p><i>Moduulilla</i> tarkoitetaan erillistä UPIDS-palvelun osaa, kuten UPIDS.IO (tuotetietojen esitys kuluttajalle), UPIDS PDM (tuotetiedon hallintajärjestelmä) tai UPIDS PLM (yksilöivät tuotetiedot ja tuotteen elinkaaren hallinta).</p>
                
                <p><i>Palveluveloituksella</i> tarkoitetaan kuukausittain laskutettavia Toimittajan ja Asiakkaan välisen Sopimuksen mukaisia UPIDS-palvelu- ja käyttäjämaksuja. Transaktiopohjaiset maksut veloitetaan erikseen voimassa olevan hinnaston mukaisesti.</p>
                
                <p><i>Pääkäyttäjällä</i> tarkoitetaan Asiakkaan nimeämää luonnollista henkilöä, jolle annetaan UPIDS-palvelun pääkäyttäjäoikeudet ja joka toimii Asiakkaan edustajana UPIDS-
                palveluasioissa.</p>
                
                <p><i>Sopimuksella</i> tarkoitetaan Toimittajan ja Asiakkaan välillä solmittua UPIDS-palvelua koskevaa sopimusta.</p>
                
                <p><i>Tiedostolla</i> tarkoitetaan mitä tahansa dokumentteja (kuvat, videot ja muut liitetiedostot), joita Asiakas on Palveluun tuonut.</p>
                
                <p><i>Tietojenkäsittelysopimuksella (engl. Data Processing Agreement, DPA)</i> tarkoitetaan sopimusta, joka solmitaan Toimittajan ja Asiakkaan välillä, ja se määrittää GDPR:n edellyttämällä tavalla muun muassa sen, kuka pääsee Asiakkaan UPIDS-palvelussa olevaan tietoon käsiksi.</p>
                
                <p><i>Toimittajalla</i> tarkoitetaan asiayhteydestä riippuen joko Second Thought Oy:tä tai Jälleenmyyjää, jolta Asiakas on hankkinut UPIDS-palvelun.</p>
                
                <p><i>Transaktiopohjainen palvelu</i> tarkoittaa osana UPIDS-palvelua käytettäviä palveluita, joissa
                yksittäinen tapahtuma muodostaa veloitusperusteen. Tällaisia ovat esimerkiksi yksilöllinen tuotemerkintä ja siihen liitettävien tietojen päivittäminen tai siirtäminen muihin järjestelmiin.</p>
                
                <h2>SOVELTAMINEN</h2>
                
                <p>Näitä yleisiä käyttöehtoja sovelletaan Second Thought Oy:n UPIDS-palveluun ("UPIDS-palvelu").</p>
                
                <p>Asiakas voi hankkia UPIDS-palvelun joko Second Thought Oy:ltä tai sen jälleenmyyjältä.</p>
                
                <p>Asiakas sitoutuu käyttämään UPIDS-palvelua näiden käyttöehtojen, lain ja hyvän tavan mukaisesti.</p>
                
                <p>Kaikista Asiakkaan pyytämistä lisätöistä sekä käyttöönotto-, kehitys-, asennus-, koulutus- ja muista Toimittajan mahdollisesti tuottamista palveluista sovitaan erikseen.</p>
                
                <p>UPIDS-palvelua suojaavat Suomen ja EU:n tekijänoikeus- ja immateriaalioikeudet. UPIDS on Second Thought Oy:n rekisteröity tavaramerkki Suomessa ja EU:ssa. UPIDS-palvelu voi sisältää yksittäisiä avoimen lähdekoodin ohjelmistokomponentteja, joilla jokaisella on omat tekijänoikeutensa ja sovellettavat lisenssiehdot.</p>
                
                <h2>TOIMITTAJAN OIKEUDET JA VELVOLLISUUDET</h2>
                
                <p>Toimittaja vastaa siitä, että UPIDS-palvelu toimitetaan Sopimuksen mukaisesti, huolellisesti sekä UPIDS-palvelun tarjoamisen edellyttämällä ammattitaidolla. Toimittajalla on oikeus tuottaa UPIDS-palvelu parhaaksi katsomallaan tavalla. Toimittajalla on oikeus käyttää UPIDS-palvelun toimittamisessa alihankkijoita.</p>
                
                <p>Second Thought Oy:llä on oikeus muuttaa UPIDS-palvelun sisältöä tiedottamatta tästä etukäteen, mikäli tämä on tarpeen esimerkiksi lainsäädännön muutoksista johtuen taikka tietoturva- tai muista syistä. Tällöin tehdyistä muutoksista pyritään tiedottamaan mahdollisimman nopeasti jälkikäteen. Ilmoitusvelvollisuus ei koske teknisiä muutoksia, kuten ohjelmiston päivityksiä.</p>
                
                <p>Second Thought Oy ja Toimittaja eivät ole velvollisia korvaamaan edellä tarkoitetuista Palvelun tilapäisestä keskeyttämisestä tai Palvelun sisältöä koskevista muutoksista Asiakkaalle mahdollisesti aiheutunutta vahinkoa taikka alentamaan tai poistamaan maksuja.</p>
                
                <p>Second Thought Oy:llä ja Toimittajalla on oikeus Asiakasta kuulematta estää Asiakkaan käyttäjätunnuksella pääsy UPIDS palveluun, jos Toimittaja perustellusti epäilee käyttäjätunnuksella kuormitettavan UPIDS-palvelua tai käytettävän UPIDS-palvelua Sopimuksen vastaisesti tai tavalla, joka vaarantaa
                UPIDS-palvelun toimittamisen.</p>
                
                <h2>ASIAKKAAN OIKEUDET JA VELVOLLISUUDET</h2>
                
                <p>Asiakkaalla on oikeus käyttää UPIDS-palvelua näiden käyttöehtojen, lain ja hyvän tavan mukaisesti. Asiakas vastaa siitä, että Asiakkaan vastuulla olevat työt ja toimenpiteet tehdään Sopimuksen mukaisesti ja huolellisesti.</p>
                
                <p>Asiakas vastaa siitä, että jokainen Sopimuksen perusteella UPIDS-palvelua käyttävä Käyttäjä on ennen UPIDS-palvelun käytön aloittamista hyväksynyt UPIDS-palvelun käyttöehdot ja noudattaa niitä UPIDS-palvelua käyttäessään. Asiakas vastaa käyttäjähallinnan työkalujen avulla luotujen käyttäjätunnusten ja salasanojen käyttämiseen ja ylläpitämiseen liittyvistä oikeuksista, velvollisuuksista ja rajoituksista. Joissain UPIDS-palveluympäristöissä on voitu rajoittaa uusien Käyttäjien luonti- ja poisto-oikeuksia Asiakkaan Pääkäyttäjällä.</p>
                
                <p>Asiakkaan tulee antaa Toimittajalle oikeat ja riittävät tiedot UPIDS-palvelun tarjoamista ja laskuttamista varten (osoite, hinnoitteluun vaikuttavat tekijät tms.) sekä viipymättä ilmoittaa Toimittajalle tietojen muutoksista. Asiakas vastaa Toimittajalle antamistaan tiedoista ja ohjeista.</p>
                
                <p>Asiakkaan tulee estää oikeudeton pääsy UPIDS-palveluun ja sen oikeudeton käyttö. Asiakas vastaa UPIDS-palvelun käyttämiseen tarvitsemiensa laitteiden, yhteyksien ja ohjelmistojen hankkimisesta ja toimintakunnosta ja siitä, etteivät ne aiheuta haittaa, häiriötä tai vahinkoa Toimittajalle tai muille UPIDS-palvelun käyttäjille. Asiakas vastaa UPIDS-palvelun käyttämiseen liittyvistä tietoliikenne- ja muista vastaavista kustannuksistaan. Asiakas vastaa Asiakkaan laitteiden, yhteyksien, ohjelmistojen ja tietojärjestelmien saattamisesta Toimittajan toimittaman UPIDS-palvelun järjestelmävaatimusten mukaisiksi. Asiakas vastaa oman tietojärjestelmänsä ja viestintäverkkonsa tietoturvasta. Asiakas on UPIDS-palvelussa käsittelemiensä henkilötietojen osalta henkilötietolain tarkoittama rekisterinpitäjä. Asiakas vastaa UPIDS-palveluun tallennetusta aineistosta ja siitä, ettei aineisto loukkaa kolmannen osapuolen oikeuksia tai kulloinkin voimassa olevaa lainsäädäntöä ja EU:n tietosuoja-asetusta.</p>
                
                <p>Asiakkaan UPIDS-palvelussa oleva tieto on aina Asiakkaan omaisuutta ja näin ollen Asiakas myös vastaa kaikesta omassa UPIDS-palvelussaan olevasta tiedosta, tiedon käytöstä eri tarkoituksissa, tiedon jakelusta eteenpäin, käyttöoikeuksista tietoon sekä siitä, että kaikki käytännöt noudattavat EU:n tietosuoja-asetusta sekä paikallista lainsäädäntöä. Second Thought Oy:llä on oikeus käyttää Asiakkaan järjestelmään tallentamia julkiseksi tarkoitettuja tuotetietoja omassa liiketoiminnassaan. </p>
                
                <p>EU:n tietosuoja-asetuksen mukaisista vastuista ja henkilötietojen käsittelystä vastaa Second Thought Oy.</p>
                
                <p>Asiakkaan vastuulla on poistaa omasta UPIDS-palvelustaan sellainen henkilötieto, johon ei ole lainmukaista edellytystä sitä säilyttää. Asiakas vastaa siitä, ettei jaettuja käyttäjätunnuksia ole käytössä. Jokainen Käyttäjä on oltava tunnistettavissa henkilötasolla.</p>
                
                <p>Asiakas voi Sopimuksen voimassa ollessa omatoimisesti muuttaa UPIDS- palvelussa olevia tietojaan ja ottaa tiedot UPIDS-palvelusta ulos. Asiakkaan on myös hyvä huomioida vastuu omaan UPIDS-palveluun liittyvien ulkopuolisten tahojen toimista kaikelta osin kuten omastaan. Ulkopuolisia tahoja voivat olla esimerkiksi Asiakkaan organisaation ulkopuoliset käyttäjät tai integraatiorajapintojen kautta Asiakkaan UPIDS-palveluun kiinni pääsevät kolmannet osapuolet. Asiakkaan tulee myös UPIDS-palvelun tiedoissaan huomioida eri tietojen säilytysajat ja poistaa vanhentunut tieto UPIDS-palvelusta.</p>
                
                <h2>IMMATERIAALIOIKEUDET</h2>
                
                <p>Omistusoikeus, tekijänoikeus ja kaikki muut oikeudet UPIDS-palveluun, niiden tuloksiin ja tuotteisiin kuuluvat yksinomaisesti Second Thought Oy:lle ja/tai kolmansille osapuolille, mukaan lukien mutta ei rajoittuen lähde- ja muihin koodeihin, käyttöohjeisiin, koulutusmateriaaliin ja kaikkiin muihin UPIDS-palveluun liittyviin materiaaleihin. Asiakkaalla on kuitenkin oikeus käyttää UPIDS-palvelua ja sen tuloksia Sopimuksen mukaisesti omissa toiminnoissa omaksi hyödykseen.</p>
                
                <p>Second Thought Oy vastaa siitä, ettei UPIDS-palvelu loukkaa kolmansien osapuolien voimassaolevia immateriaalioikeuksia. Second Thought Oy ei vastaa vaatimuksista, jotka aiheutuvat siitä, että UPIDS-palvelua on käytetty tarkoitukseen, johon sitä ei ole suunniteltu tai hyväksytty taikka siitä, että UPIDS-palvelua käytetään vastoin Sopimusta tai voimassa olevan lainsäädännön vastaisesti. Second Thought Oy ei myöskään vastaa vaatimuksesta, joka johtuu Asiakkaan UPIDS-palveluihin tekemästä muutoksesta tai Asiakkaan antamien ohjeiden noudattamisesta, johtuu UPIDS-palveluiden käyttämisestä yhdessä muun kuin Second Thought Oy:n hyväksymän tuotteen tai UPIDS palvelun kanssa.</p>
                
                <p>Asiakas on velvollinen viipymättä ilmoittamaan Toimittajalle, mikäli kolmas osapuoli väittää
                UPIDS-palvelun loukkaavan sen immateriaalioikeuksia. Mikäli Second Thought Oy katsoo, että UPIDS -palvelu tai jokin osa siitä loukkaa kolmannen osapuolen edellä tarkoitettua oikeutta, Toimittajalla on oikeus kustannuksellaan joko hankkia Asiakkaalle oikeus jatkaa UPIDS-palvelun käyttämistä, vaihtaa UPIDS-palvelu tai osa siitä, tai muuttaa UPIDS-palvelua siten, että oikeudenloukkaus lakkaa. Mikäli mikään edellä mainituista
                vaihtoehdoista ei ole mahdollista kohtuullisin ehdoin, Asiakkaan on lopetettava Toimittajan
                pyynnöstä UPIDS-palvelun tai sen osan käyttö.</p>
                
                <h2>VARMUUSKOPIOT</h2>
                
                <p>Toimittaja huolehtii asianmukaisista varmuuskopioista. Kaikesta datasta suoritetaan varmuuskopiointi reaaliaikaisesti.</p>
                
                <h2>LUOTTAMUKSELLISUUS</h2>
                
                <p>Osapuolet sitoutuvat olemaan luovuttamatta toisen osapuolen luottamuksellisia tietoja kolmansille ilman toisen osapuolen kirjallista lupaa. Osapuolten tulee varmistaa, että kaikki osapuolten työntekijät ja edustajat noudattavat yllä olevaa luottamuksellisuusvelvoitetta.</p>
                
                <p>Jälleenmyyjä voi antaa Second Thought Oy:lle Asiakasta koskevia tietoja siinä määrin kuin se on tarpeen Sopimuksen mukaisen UPIDS-palvelun tuottamista varten. Palvelu sijaitsee Second Thought Oy:n hallitsemilla palvelimilla ja Second Thought Oy:llä on pääsy Asiakkaan dataan.</p>
                
                <h2>UPIDS-PALVELUN HINTA JA LASKUTUS</h2>
                
                <p>UPIDS-palvelun hinta on Toimittajan kulloinkin voimassa olevan hinnaston mukainen tai osapuolten välillä Sopimuksessa sovitun mukainen. Toimittajalla on oikeus muuttaa UPIDS-palvelun hinnoittelua ja maksuperusteita ilmoittamalla siitä vähintään kolmea (3) kuukautta ennen muutoksen voimaantuloa. Hinnankorotuksesta ilmoitetaan Asiakkaan UPIDS-palvelun Pääkäyttäjille sähköpostitse tai kirjeitse. Toimittajalla on harkintansa mukaan oikeus vaatia Asiakkaalta kohtuullinen ennakkomaksu tai vakuus. Arvonlisävero lisätään hintoihin kulloinkin voimassaolevien säännösten mukaisesti. Jos viranomaisten määräämien julkisten maksujen suuruus tai kantoperuste muuttuu säädösmuutoksen tai verotuskäytännön muuttumisen takia, hinnat muuttuvat vastaavasti.  Muutoksella ei ole vaikutusta ennen sen voimaantuloa alkaneiden laskutuskausien maksuihin. Laista, asetuksista tai viranomaisten toimenpiteistä aiheutuvat kustannusten lisäykset korottavat hintoja välittömästi määräysten voimaantulohetkestä lukien.</p>
                
                <p>UPIDS-palvelun hinta ei sisällä UPIDS-palvelun tarjoamisesta, käyttöönotosta tms. Aiheutuvia matkakuluja eikä matkustamisesta aiheutuvia muita kuluja ja kustannuksia, kuten majoituskuluja, auton käyttökorvauksia ja päivärahoja, vaan Asiakas korvaa nämä erikseen Toimittajalle. Näiden korvausten enimmäismäärät perustuvat kulloinkin voimassaolevaan Verohallinnon päätökseen verovapaiden matkakustannusten korvauksista. Matka-ajan laskuttamisesta sovitaan erikseen tapauskohtaisesti.</p>
                
                <p>UPIDS-palvelun veloitus koostuu perusmaksusta, käyttäjämaksusta ja mahdollisten lisäosien maksuista. Maksut suoritetaan laskua vastaan. Jollei toisin ole sovittu, laskutuskausi on kalenterikuukausi ja laskutus tapahtuu etukäteen kalenterikuukauden alussa. UPIDS-palvelu voidaan sulkea, jos Asiakkaan yli 3 kuukautta erääntyneenä olleelle laskun määrälle ja sille kertyneille viivästyskoroille ei ole tullut täyttä suoritusta. Suljettu UPIDS-palvelu avautuu, kun erääntynyt summa viivästyskorkoineen ja hinnastossa ilmoitettu UPIDS-palvelun uudelleenavausmaksu on kirjautunut suoritetuksi.</p>
                
                <p>Viivästyskorko on korkolain 4 a §:n mukainen viivästyskorko. Asiakas on velvollinen
                maksuviivästyksien yhteydessä maksamaan lisäksi hinnaston mukaiset saatavien muistutus- ja
                perimismaksut. Laskua koskevissa erimielisyystapauksissa riidaton osa laskusta on maksettava eräpäivään mennessä. Jos Asiakas käyttää osana UPIDS-palveluaan Transaktiopohjaisia palveluita, voi Second Thought Oy muuttaa Palvelun hintaa näiltä osin ilmoittamalla tästä Asiakkaalle minimissään kaksi (2) viikkoa ennen muutoksen voimaantuloa. Tällaisia Transaktiopohjaisia palveluita ovat osana UPIDS-palvelua esimerkiksi yksilöivien tuotetunnisteiden luominen, päivittäinen tai lukeminen ulkopuolisista järjestelmistä. Transaktiopohjaiset palvelut veloitetaan asiakkaalta toteutuneiden tapahtumamäärien mukaisesti kerran kuukaudessa.</p>
                
                <h2>SOPIMUKSEN VOIMASSAOLO JA IRTISANOMINEN</h2>
                
                <p>Sopimus tulee voimaan Sopimuksen allekirjoituksella tai rekisteröitymällä palveluun ja on voimassa toistaiseksi. Ellei kirjallisesti ole toisin sovittu, kummallakin osapuolella on oikeus kirjallisesti irtisanoa Sopimus päättymään kolmen kuukauden irtisanomisajalla. Irtisanomisaika lasketaan sen kalenterikuukauden viimeisestä päivästä, jonka aikana irtisanominen on suoritettu. UPIDS-palvelu laskutetaan irtisanomisajan loppuun.</p>
                
                <p>Toimittajalla on oikeus irtisanoa Sopimus päättymään välittömästi ilman irtisanomisaikaa, jos
                Asiakkaalla on maksamatta kolmen eri kalenterikuukauden UPIDS-palvelun veloitukset. Toimittaja voi sulkea UPIDS-palvelun tai purkaa Sopimuksen välittömästi, mikäli Asiakas asetetaan konkurssiin tai tulee pysyvästi maksukyvyttömäksi. Toimittajalla on oikeus purkaa Sopimus tai keskeyttää UPIDS-palvelu, jos UPIDS-palvelua käytetään Sopimuksen tai UPIDS-palvelun käyttöehtojen vastaisesti taikka lainvastaiseen toimintaan tai tavalla, joka saattaa aiheuttaa vahinkoa UPIDS-palvelulle, Toimittajalle, Toimittajan sopimuskumppanille, Asiakkaalle, kolmannelle osapuolelle tai sivulliselle.</p>
                
                <p>Sopimusehtojen Versio 2022-03-17</p>`
}