import React from 'react';
import { useTranslation } from "react-i18next";
import SwitchComponent from "react-switch";
import TextInputWithLableWithAiData from 'src/components/aiComponent/TextInputWithLableWithAiData';

export const ExternalSources = (props) => {
    const { t } = useTranslation('common');
    const {
        name, value, label, onHandleColor, onColor, offHandleColor, offColor, readOnly, onChange
    } = props




    const handleInputChange = (item, e) => {

        const newValue = value.map((element) => {
            if (element.url === item.url) {
                const newElement = { ...element }
                newElement.enabled = e
                return newElement;
            }
            return element;
        })

        onChange({ target: { name: name, value: newValue } })
    }


    return (
        <div>
            <span className='tab-section-info'>{t(label)}</span>
            {value && value[0] && value.map((item, index) => (
                <div key={`${item.name}_${index}`} className='d-flex align-items-center'>
                    <TextInputWithLableWithAiData
                        label={''}
                        readOnly={true}
                        value={item.url}
                        type="text"
                    />
                    <SwitchComponent
                        checked={item.enabled == undefined ? true : item.enabled}
                        onChange={(e) => handleInputChange(item, e)}
                        onColor={onColor}
                        offColor={offColor}
                        onHandleColor={onHandleColor}
                        offHandleColor={offHandleColor}
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                        activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                        height={15}
                        width={35}
                        className='react-switch'
                        id='material-switch'
                        disabled={readOnly}
                    />
                </div>
            ))}
            {(!value || !value[0]) && <div>{t("No external source available")}</div>}
        </div>
    )
}



ExternalSources.defaultProps = {
    checked: false,
    onHandleColor: '#32cd32',
    offHandleColor: '#ffffff',
    onColor: '#32cd32',
    offColor: '#6c757d',
    disabled: false
};