import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { addUpidsProduct } from "src/redux/products/productThunk";
import { getCompany } from 'src/redux/company/companyThunk';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IoAddCircleOutline } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Dropdown } from "semantic-ui-react";
import { Spinner } from "react-bootstrap";
import { FcOk } from "react-icons/fc";
import { usePrevious } from "../../../helper/CustomHook";
import { MdClose } from "react-icons/md";
import { getAllTimeZones } from "src/helper/timezones";
import { addUpidsProductSchema } from '../../../helper/yup-schema'
import { yupResolver } from "@hookform/resolvers/yup";
import { AppContext } from '../../AppContext';
import { InputTextWithLabel } from "src/components/inputComponents/InputTextWithLabel";
import { InputSelectDropdown } from "src/components/inputComponents/InputSelectDropdown";
import { AddButton } from "src/components/AddButton";
import { StSquareButton } from "src/components/StSquareButton";
import { StCloseButton } from "src/components/StCloseButton";
import { useTranslation } from "react-i18next";

import objectPath from "object-path";
import _ from "lodash";


let resetFormValue;

let inputFieldsToRender = []

function UpidsForm(props) {
  const { productData } = props;
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const context = useContext(AppContext);
  const { t } = useTranslation('common');

  const [error, setError] = useState({
    valueError: "",
    timezoneError: "",
    customFieldTypeError: "",
    customFieldNumberOfValuesError: ""
  })

  const [inputValue, setInputValue] = useState("");
  const [fields, setFields] = useState([]);
  const [formObject, setFormObject] = useState({})
  const [customField, setCustomField] = useState({ customFieldType: '', customFieldToAdd: '' })
  const [customMultiValuesInput, setCustomMultiValuesInput] = useState([])
  const [renderInputFields, setRenderInputFields] = useState([])

  const productsState = useSelector((state) => state.products, shallowEqual);
  const _companyState = useSelector((state) => state.company, shallowEqual);


  const dispatch = useDispatch();

  const { register, handleSubmit, watch, formState: { errors }, resetForm } = useForm({
    resolver: yupResolver(addUpidsProductSchema)
  });

  useEffect(() => {
    if (context.authState.level === 10) {
      dispatch(getCompany({}));
    } else {
      let _selectData = context.authState.companyName;
      setCompanyName(_selectData);
    }
  }, []);

  const getCompanyId = () => {
    // let _id = { id: 0 };
    // _companyState.companyList.map(o => o.businessIdentifiers.map(j => j.id === objectPath.get(_.find(productsState.productById.businessIdentifiers, function(o){return o.type === "VATID"}), "id", o.businessIdentifiers[0].id) ? _id = o : null));

    let _id = { id: 0 };
    _companyState.companyList.map((o) => {
      let companyBIds = o.businessIdentifiers
      let productBids = productsState.productById.businessIdentifiers
      const companyBid = objectPath.get(_.find(companyBIds, function (o) { return o.type === "VATID" }), 'id', companyBIds[0].id)
      if (_.find(productBids, ["id", companyBid])) {
        return _id = o
      } else {
        return _id
      }
    })

    setCompanyName(_id.name);
  }

  useEffect(() => {
    if (!!_companyState.companyList && _companyState.companyList.length > 0) {
      getCompanyId();
    }
  }, [_companyState.companyList]);

  const response = productsState.response;
  const prevPropsState = usePrevious({ response });
  let timezoneValue;

  let selectTimezoneOption = [];
  const allTimezones = getAllTimeZones();

  const createDropdownDataForTimezone = (allTimezones) => {
    selectTimezoneOption = [];
    allTimezones.map((o, index) => {
      let eve = { key: index, text: o.text, value: o.abbr };
      selectTimezoneOption.push(eve);
    });
  };
  createDropdownDataForTimezone(allTimezones);




  useEffect(() => {
    if (prevPropsState) {
      if (
        prevPropsState.response !== response &&
        response.id &&
        response !== ""
      ) {
        setShowSubmit(true);
      }
    }
  }, [productsState]);

  const checkLinkedUpidsSelected = () => {
    if (customField.customFieldToAdd == "linkedUpids") {
      return true
    } else {
      return false
    }
  }

  // dropdowns for custom field
  const selectFieldTypeOptions = [
    { key: 1, text: 'Single value', value: 'Single value', disabled: checkLinkedUpidsSelected() },
    { key: 2, text: 'Multiple values', value: 'Multiple values' }
  ]
  const fieldToAddOptions = [
    { key: 1, text: 'Linked Upids', value: 'linkedUpids' },
    { key: 2, text: 'Other Field', value: 'otherField' },
  ]

  const handleClose = () => {
    setError({ ...error, valueError: "", customFieldNumberOfValuesError: "", customFieldTypeError: "" })
    setShow(false);
    setInputValue("")
    setCustomField({ ...customField, customFieldType: '', customFieldNumberOfValues: '' })

  }

  const handleSubmitClose = () => setShowSubmit(false);

  const handleOkClick = () => {
    setShowSubmit(false)
    resetFormValue.reset();
    setFormObject({ abbr: "" })
    let masterProductIdentifier = productData._2an ? productData._2an : productData.gtin;
    navigate(`/productProfile/${masterProductIdentifier}/upidsProduct/${productsState.response.upidsId}`)
  }

  const handleClick = () => {
    if (inputValue === "") {
      setError({ ...error, valueError: "Please enter new form item here" })
    }
    else {
      const values = [...fields];
      values.push({ label: inputValue });
      setFields(values);
      setShow(false);
      setInputValue("");
      setCustomField({ ...customField, customFieldType: '', customFieldToAdd: '', customFieldNumberOfValues: '' })
    }
  };

  const handleChange = (e) => {
    setInputValue(e.target.value)
    setError({ ...error, valueError: '' })
  }
  const handleTimezoneSelect = (e) => {
    const value = e.target.value
    if (!!value) {
      timezoneValue = allTimezones.find(({ abbr }) => abbr === value);
      setFormObject({ ...formObject, timezone: timezoneValue.offset.toString(), abbr: value })
      setError({ ...error, timezoneError: "" })
    }
  };

  const getCustomInputFieldData = (data) => {
    let dataToAdd = {}
    customMultiValuesInput.map((o, i) => {
      let property = { [o.label]: [] }
      let keys = Object.keys(o);
      keys.forEach((key, index) => {
        if (key !== "label") {
          property[o.label].push(o[key])
        }
      });
      dataToAdd = { ...dataToAdd, ...property }
    })
    return dataToAdd
  }

  const onSubmit = (data, e) => {
    let customDataToAdd = getCustomInputFieldData(data)
    if (!!formObject.abbr) {
      // data = { ...formObject, ...data,Company: productData.Company, gtin: productData.gtin, _2an: productData._2an, businessIdentifiers: productData.businessIdentifiers, ...customDataToAdd }
      data = { ...formObject, ...data, Company: companyName, gtin: productData.gtin, _2an: productData._2an, businessIdentifiers: productData.businessIdentifiers, ...customDataToAdd }
      dispatch(addUpidsProduct(data));
      resetFormValue = e.target;
    }
    else {
      setError({ ...error, timezoneError: "Timezone required" })
    }
  };
  const handleCustomField = (e, data) => {
    if (!!data) {
      setCustomField({ ...customField, [data.name]: data.value })
    } else {
      setCustomField({ ...customField, [e.target.name]: Number(e.target.value) })
    }

  }

  const handleCustomFieldToAdd = (e, data) => {
    if (data.value == "linkedUpids") {
      setInputValue('linkedUpids')
      setCustomField({ ...customField, customFieldType: 'Multiple values', [data.name]: data.value })
    } else {
      setInputValue("")
      setCustomField({ ...customField, [data.name]: data.value })
    }
  }

  const handleMultiValuesInputChanged = (e, i) => {
    //  let currentState= {...customMultiValuesInput}
    //  setCustomMultiValuesInput({...currentState, [e.target.name]: e.target.value})

    let allData = [...customMultiValuesInput]
    let currentCustomMultiValue = { ...customMultiValuesInput[i], label: inputValue.split(" ").join(""), [e.target.name]: e.target.value }
    allData[i] = currentCustomMultiValue

    setCustomMultiValuesInput(allData)

  }
  const handleMultipleValuesClick = () => {
    let inputFields = []
    for (let index = 0; index < customField.customFieldNumberOfValues; index++) {
      inputFields.push(`customMultiValuesInput_${index}`)
    }
    let data = { label: inputValue, renderData: inputFields }
    inputFieldsToRender.push(data)
    setRenderInputFields(inputFieldsToRender)
    setShow(false);
    // setInputValue("");
    setCustomField({ ...customField, customFieldType: '', customFieldToAdd: '', customFieldNumberOfValues: '' })
  }


  return (
    <>
      <div className="p-3 col-xl-8 col-sm-12">
        <span style={{ color: '#253C4B', fontSize: 14, fontWeight: 'bold', marginBottom: 15, display: 'inline-block', marginLeft: 10 }}>{t("Add UPIDS Product")}</span>
        <div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)} className="">
              <div className="row col-12">


                <InputTextWithLabel
                  label={t("_GTIN")}
                  readOnly={true}
                  // onChange={handleFormChange}
                  name={"gtin"}
                  value={productData.gtin}
                  type="text"
                  placeholder={t("_GTIN")}
                ></InputTextWithLabel>


                <InputTextWithLabel
                  label={t("__2AN")}
                  readOnly={true}
                  name={"_2an"}
                  value={productData._2an}
                  type="text"
                  placeholder={toString("__2AN")}
                ></InputTextWithLabel>


                <InputTextWithLabel
                  label={t("_Company")}
                  readOnly={true}
                  name={"Company"}
                  value={companyName}
                  type="text"
                  placeholder={t("Company Name")}
                ></InputTextWithLabel>

                <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <label className="label-class">{t("Smart Segment Extension")}</label>
                  <input

                    {...register("sse")}
                    name="sse"
                    type="text"
                    placeholder={t("Smart Segment Extension")}
                  />
                  <span className="text-danger">{!!errors.sse && errors.sse.message}</span>

                </div>


                <InputSelectDropdown
                  label={t("Select Timezone")}
                  placeholder={t("Select Timezone")}
                  name="timezone"
                  search={true}
                  value={formObject.abbr}
                  options={selectTimezoneOption}
                  onChange={handleTimezoneSelect}
                />
                <span className="text-danger">{!!error?.timezoneError && error?.timezoneError}</span>

                {!!fields &&
                  fields !== null &&
                  fields.map((field, index) => (
                    <div key={index} className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <label>{field.label}</label>
                      <input
                        {...register(`${field.label.split(" ").join("")}`)}
                        name={field.label.split(" ").join("")}
                        type="text"
                        placeholder={field.label}
                      />
                    </div>
                  ))}
              </div>

              <div className="row col-12">
                {renderInputFields.length > 0 && renderInputFields.map((field, i) => (
                  <div key={i}>
                    <div className="col-12"><label>{field.label}</label></div>
                    <div className="row col-12">
                      {field.renderData.map((data, index) => (
                        <div key={index} className="form-group col-6">
                          <input
                            value={customMultiValuesInput[i] ? customMultiValuesInput[i][`multiValuesInput${index + 1}`] : ""}
                            onChange={(e) => handleMultiValuesInputChanged(e, i)} type="text" name={`multiValuesInput${index + 1}`} placeholder={`Enter ${index + 1} value`} />
                        </div>
                      ))}
                    </div>
                  </div>
                ))
                }
              </div>

              <div style={{ backgroundColor: '#F8FCFD', height: 40, justifyContent: 'center', alignItems: 'center', display: 'flex', marginLeft: 15, marginRight: 15 }} onClick={() => setShow(true)} className="col-lg-12">
                <AddButton buttontext={t("Add New Form Field")} onClick={() => setShow(true)}></AddButton>
              </div>

              <button className="submit-button mt-5" type="submit">
                {productsState.isLoading ? (
                  <Spinner
                    style={{ width: 22, height: 22 }}
                    animation="border"
                    variant="light"
                  />
                ) : (
                  t("Submit")
                )}
              </button>
            </form>

          </div>
        </div>

      </div>

      <Modal size="md" show={show} onHide={handleClose} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', opacity: 1, fontWeight: 'bold', fontSize: 18, }}>
          <div className="form-group col-12">
            <label>{t("Choose Field To Add")}</label>
            <Dropdown
              className="analytics-dropdown addupids-product"
              style={{ width: "100%", height: 35, backgroundColor: '#F8FCFD' }}
              placeholder={t("Select Field")}
              search
              selection
              value={customField.customFieldToAdd}
              options={fieldToAddOptions}
              onChange={handleCustomFieldToAdd}
              name="customFieldToAdd"
            />

          </div>
          <div className="form-group col-12">
            <label>{t("Choose Field Type")}</label>
            <Dropdown
              className="analytics-dropdown addupids-product"
              style={{
                width: "100%", height: 35,
                backgroundColor: '#F8FCFD'
              }}
              placeholder={t("Select Field Type")}
              search
              selection
              value={customField.customFieldType}
              options={selectFieldTypeOptions}
              onChange={handleCustomField}
              name="customFieldType"
            />
          </div>
          {customField.customFieldType == "Multiple values" &&
            <div className="form-group col-12">
              <InputTextWithLabel isSmallScreen={true}
                label={t("Enter Number Of Values To Enter")}
                name={"customFieldNumberOfValues"}
                type="text"
                placeholder={t("Enter Number Of Values To Enter")}
                value={customField.customFieldNumberOfValues}
                onChange={handleCustomField}
              ></InputTextWithLabel>
            </div>}

          {customField.customFieldToAdd == "otherField" && <div className="form-group col-12"  >
            <InputTextWithLabel isSmallScreen={true}
              label={t("Enter name of new form item")}
              name={"name"}
              type="text"
              placeholder={t("Enter name of field")}
              value={inputValue}
              onChange={handleChange}
              errorMsg={error.valueError ? error.valueError : ''}
            ></InputTextWithLabel>
          </div>}
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Ok")}
            disabled={customField.customFieldToAdd == 'linkedUpids' ? (!!customField.customFieldNumberOfValues && inputValue != '') ? false : true : (inputValue == '') ? true : false}
            onClick={customField.customFieldType == "Multiple values" ? handleMultipleValuesClick : handleClick}>
          </StSquareButton>
        </Modal.Footer>
      </Modal>

      <Modal show={showSubmit} onHide={handleSubmitClose} centered={true}>
        <Modal.Header >
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleSubmitClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18, }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FcOk
              style={{
                width: 40,
                height: 40,
                textAlign: "center",
                alignSelf: "center",
              }}
            />
            <span>{t("Upids Product Inserted Successfully")}</span>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t('Ok')} onClick={() => handleOkClick()}></StSquareButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpidsForm;
