import { createSlice } from "@reduxjs/toolkit";
import {
  getUsersAction, editSpecifcUser, getUsersSearch, getLiveUsersSearch, getDataForSpecificUserAction,
  getDataByUidAction, editUser, addUser, deleteUser
} from './usersThunk'

const initialCompanyState = {
  actionsLoading: false,
  response: null,
  error: null,
  usersList: null,
  isLoading: false,
  isDeleteLoading: false,
  deleteResponse: null,
  isAllDataFetchedLoading: false,
  editResponse: null,
  isGetDataByUidLoading: false,
  formObject: {},
  currentUserObj: {},
  isUserEditMode: false,
  ErrorsformObject: [],
  ErrorsformEditObject: [],
  specificUserList: null,
  editSpecificUserList: null
};

export const usersSlice = createSlice({
  name: "users",
  initialState: initialCompanyState,
  reducers: {

    changeUsersFormObjectAction: (state, action) => {
      state.formObject = action.payload;
    },

    usersAllErrorsFormObject: (state, action) => {
      state.ErrorsformObject = action.payload;
    },

    usersAllErrorsEditFormObject: (state, action) => {
      state.ErrorsformEditObject = action.payload;
    },

    currentUserFormObject: (state, action) => {
      state.currentUserObj = action.payload;
    },

    changeUserEditModeAction: (state, action) => {
      state.isUserEditMode = action.payload;
    },
  },

  extraReducers:
    (builder) => {
      builder
        .addCase(getUsersAction.pending, (state) => {
          state.actionsLoading = true;
          state.isAllDataFetchedLoading = true;

        })
        .addCase(getUsersAction.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isAllDataFetchedLoading = false;
          state.usersList = action.payload;
        })
        .addCase(getUsersAction.rejected, (state, action) => {
          state.actionsLoading = false;
        })


        .addCase(editSpecifcUser.pending, (state) => {
          state.actionsLoading = true;
          state.isLoading = true;
          state.editResponse = null;
        })
        .addCase(editSpecifcUser.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.isLoading = false;
          state.editResponse = action.payload.status;
        })
        .addCase(editSpecifcUser.rejected, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
        })


        .addCase(getUsersSearch.pending, (state) => {
          state.actionsLoading = true;
          state.isLoading = true;
          state.editResponse = null;
        })
        .addCase(getUsersSearch.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.usersList = action.payload;
        })
        .addCase(getUsersSearch.rejected, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
        })


        .addCase(getLiveUsersSearch.pending, (state) => {
          state.actionsLoading = true;
          state.isLoading = true;
        })
        .addCase(getLiveUsersSearch.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = null;
          state.usersList = filterUsersData(action.payload);
        })
        .addCase(getLiveUsersSearch.rejected, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
        })

        .addCase(getDataForSpecificUserAction.pending, (state, action) => {
          const arg = action.meta.arg
          state.error = null;
          if (arg && !arg.isCancelBtn) {
            state.isGetDataByUidLoading = true;
          }

        })
        .addCase(getDataForSpecificUserAction.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.isGetDataByUidLoading = false;
          state.specificUserList = action.payload;
        })
        .addCase(getDataForSpecificUserAction.rejected, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
        })

        
        .addCase(getDataByUidAction.pending, (state, action) => {
          const arg = action.meta.arg
          state.error = null;
          if (arg && !arg.isCancelBtn) {
            state.isGetDataByUidLoading = true;
          }

        })
        .addCase(getDataByUidAction.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isGetDataByUidLoading = false;
          state.error = null;
          state.usersList = action.payload;
        })
        .addCase(getDataByUidAction.rejected, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
        })



        .addCase(editUser.pending, (state) => {
          state.actionsLoading = true;
          state.isLoading = true;
          state.editResponse = null;
        })
        .addCase(editUser.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = null;
          state.editResponse = action.payload.status;
        })
        .addCase(editUser.rejected, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
        })


        .addCase(addUser.pending, (state) => {
          state.actionsLoading = true;
          state.isLoading = true;
        })
        .addCase(addUser.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
          state.error = null;
          state.response = action.payload;
          state.formObject = {};
        })
        .addCase(addUser.rejected, (state, action) => {
          state.actionsLoading = false;
          state.isLoading = false;
        })

        .addCase(deleteUser.pending, (state) => {
          state.actionsLoading = true;
          state.isDeleteLoading = true;
          state.error = null;
          state.deleteResponse = null;
        })
        .addCase(deleteUser.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.isDeleteLoading = false;
          state.error = null;
          state.deleteResponse = action.payload.status;
        })
        .addCase(deleteUser.rejected, (state, action) => {
          state.actionsLoading = false;
          state.isDeleteLoading = false;
        })
    },
});

// live search api returns users array...each user contains companies array but gets string instead of array 
const filterUsersData = (usersData) => {
  let newData = []
  usersData.forEach((o) => {
    newData.push({ ...o, companies: JSON.parse(o.companies) })
  })
  return newData;
}


export const {
  changeUsersFormObjectAction, usersAllErrorsFormObject, usersAllErrorsEditFormObject,
  currentUserFormObject, changeUserEditModeAction
} = usersSlice.actions;
