

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Badges } from 'src/components/Badges';
import Modal from "react-bootstrap/Modal";
import { InputTextWithLabel } from 'src/components/inputComponents/InputTextWithLabel';
import { changeFormObject } from 'src/redux/products/productsSlice';
import { StSquareButton } from 'src/components/StSquareButton';
import { StCloseButton } from 'src/components/StCloseButton';
import { EcommerceComponent } from './EcommerceComponent';
import { findVal } from 'src/helper/CustomHook';
import { AddEmailsComponent } from './AddEmailsComponent';
import { AppContext } from 'src/pages/AppContext';
import SpinnerLoader from 'src/pages/components/Spinner';
import { getCompany, getCompanySearch, getCompanySearchList, getEcomPoint, getLiveCompanySearch } from 'src/redux/company/companyThunk';
import objectPath from "object-path";
import _ from "lodash";
import { Divider } from '@mui/material';
import { MdClose, MdSend } from 'react-icons/md';
import { IconButton } from 'src/components/IconButton';
import { ItemContent } from 'semantic-ui-react';
import { MdRemoveRedEye, MdDelete } from 'react-icons/md';
import { BiTrash } from "react-icons/bi";
import { InputMultiSelectDropdown } from "src/components/inputComponents/InputMultiSelectDropdown";
import { Spinner } from 'react-bootstrap';




let isCompanyAssociatedWithProduct = false;

export const ManufactureTab = (props) => {
    const { t } = useTranslation('common');
    const context = useContext(AppContext);
    const dispatch = useDispatch();
    const [companyDetailsArray, setCompanyDetailsArray] = useState([])
    const [loader, setLoader] = useState(true)
    const [modalData, setModalData] = useState({
        isShow: false,
        selectedCompanyData: {},
    })
    const [modalDataForAddManufacturer, setModalDataForAddManufacturer] = useState({
        isShow: false,
        multiSelectValue: [],
    })
    const [companyData, setCompanyData] = useState([])
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [addLoader, setAddLoader] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)

    const {
        componentSchema, name, value, label, onHandleColor, onColor, offHandleColor, offColor, readOnly, onChange, manufacturesSchema
    } = props

    const _product = useSelector((state) => (state.products.formObject), shallowEqual);
    const productState = useSelector((state) => (state.products));
    const companyState = useSelector((state) => state.company);

    useEffect(() => {
        setLoader(true)
        getAllCompaniesData()
    }, [_product.businessIdentifiers]);

    const getAllCompaniesData = async () => {
        await fetchCompanies()
        setLoader(false)
    }

    const _productState = useSelector((state) => (state.products), shallowEqual);
    const _state = useSelector((state) => (state.company), shallowEqual);

    const fetchCompanies = async () => {
        if (_product.businessIdentifiers && _product.businessIdentifiers.length > 0) {
            dispatch(getEcomPoint(objectPath.get(_.find(_product.businessIdentifiers, function (o) { return o.type === "VATID" }), 'id', _product.businessIdentifiers[0].id)))
            let companyDetailsArrayNew = [];
            let newFlag = false
            let result;
            for (const identifier of _product.businessIdentifiers) {
                if (context.authState.level === 10) {
                    let filteredArray = companyState?.masterCompanyList?.filter((obj) =>
                        obj.businessIdentifiers.some((item) => item.id === identifier?.id)
                    );
                    if (filteredArray?.length == 0 && !newFlag) {
                        result = await dispatch(getLiveCompanySearch({ searchText: identifier?.id, pageNo: 1 })).unwrap();
                        newFlag = true
                        filteredArray = result
                    }
                    if (filteredArray?.length > 0) {
                        if (companyDetailsArrayNew.length === 0) {
                            companyDetailsArrayNew.push(filteredArray[0])
                        }
                        const isObjectAlreadyPresent = companyDetailsArrayNew?.some(existingObj =>
                            existingObj?.id === filteredArray[0]?.id // Adjust the condition based on your object's properties
                        );
                        // If the object is not present, push it to the array
                        if (!isObjectAlreadyPresent) {
                            companyDetailsArrayNew.push(filteredArray[0])
                        }
                        setCompanyDetailsArray(companyDetailsArrayNew)
                    }
                }
                else {
                    let companyDetailsArrayNew = [];
                    let result = await dispatch(getCompanySearchList());
                    companyDetailsArrayNew.push(result.payload)
                    setCompanyDetailsArray(companyDetailsArrayNew)
                }
                // Additional logic inside the loop if needed
                isCompanyAssociatedWithProduct = checkUserAssociatedWithProduct();
            }
        }
    }

    const checkUserAssociatedWithProduct = () => {
        let companyBIds = context.authState.businessIdentifiers
        let productBids = _product.businessIdentifiers

        const companyBid = objectPath.get(_.find(companyBIds, function (o) { return o.type === "VATID" }), 'id', _product.businessIdentifiers[0].id)

        if (_.find(productBids, ["id", companyBid])) {
            return true
        } else {
            return false
        }

    }

    const handleViewCompanyClick = (item) => {
        setModalData({
            ...modalData,
            isShow: true,
            selectedCompanyData: {...item}
        })

    }

    const handleModalHide = () => {
        setModalData({
            isShow: false,
            multiSelectValue: [],
        })
    }

    const removeManufacturerFromProductProfile = (item) => {
        setDeleteConfirm(true);
        setModalData({
            ...modalData,
            isShow: false
        })
    }

    const fetchAllCompanies = async () => {
        try {
            const companyList = await dispatch(getCompany({})).unwrap();
            const idArray = _product?.businessIdentifiers?.map(identifier => identifier.id);
            const filteredArray = companyList.filter(obj =>
                obj.businessIdentifiers.every(identifier =>
                    !idArray.includes(identifier.id)
                )
            );
            const companyListOptions = filteredArray.map((o, index) => {
                const item = { key: index, label: o.name, value: o.id };
                return item
            });
            setCompanyData(companyListOptions)
            return companyList
        } catch (error) {

        }
    }

    const handleDeleteManufacturer = async (item) => {
        setDeleteLoader(true)
        const idsToRemove = item?.businessIdentifiers?.map(identifier => identifier.id);
        const filteredArray = _product?.businessIdentifiers?.filter(identifier => !idsToRemove?.includes(identifier?.id));
        await dispatch(changeFormObject({ ..._product, businessIdentifiers: filteredArray }))
        setTimeout(async () => {
            setDeleteLoader(false)
            setDeleteConfirm(false);
        }, 500);

    }

    const handleClose = () => {
        setDeleteConfirm(false)
    }

    const handleModalShow = () => {
        setModalDataForAddManufacturer({
            ...modalDataForAddManufacturer,
            isShow: true,
        })
        fetchAllCompanies();
    }

    const handleModalHideForAdd = () => {
        setModalDataForAddManufacturer({
            isShow: false,
            multiSelectValue: [],
        })
    }

    const handleMultiSelectValueChange = (e) => {
        setModalDataForAddManufacturer({
            ...modalDataForAddManufacturer,
            multiSelectValue: e.target.value
        })
    }

    const handleModalAdd = async () => {
        setAddLoader(true)
        let values = modalDataForAddManufacturer.multiSelectValue
        const result = await dispatch(getCompany({})).unwrap();
        let mappedData = values.map((value) => {
            return _.find(result, (d) => d.id == value)
        })
        let newlyAddedBusinessIdArray = mappedData.reduce((accumulator, val) => {
            if (val?.businessIdentifiers?.length > 0) {
                accumulator = accumulator.concat(val.businessIdentifiers);
            }
            return accumulator;
        }, []);
        let newlyModifiedArray = [
            ..._product.businessIdentifiers,
            ...newlyAddedBusinessIdArray
        ];
        await dispatch(changeFormObject({ ..._product, businessIdentifiers: newlyModifiedArray }))
        setAddLoader(false)
        setModalDataForAddManufacturer({
            isShow: false,
            multiSelectValue: [],
        })
    }

    return (
        <>
            {loader ?
                <SpinnerLoader />
                :
                <>
                    <div >
                        <div
                            className='formFieldSection__start mb-3'
                            style={{ marginTop: "1rem" }}
                        >
                            <span className='tab-section-info'>
                                {t("Manufacturers Tab")}
                            </span>

                            {productState.isProdEditMode && context.authState.level === 10 &&
                                <Badges add
                                    onClick={handleModalShow}
                                >
                                    {t("Add")}
                                </Badges>}
                        </div>
                        {companyDetailsArray && companyDetailsArray[0] ? companyDetailsArray.map((item, index) => {
                            return (
                                <>
                                    <div className='row'>
                                        {manufacturesSchema.map((o, index) => (
                                            <React.Fragment key={index}>
                                                {_.get(item, o.name) && (
                                                    <InputTextWithLabel
                                                        key={index}
                                                        label={t(`${o.label}`)}
                                                        tooltipText={t(`${o.tooltipText}`)}
                                                        showTooltip={true}
                                                        readOnly={true}
                                                        name={o.name}
                                                        value={_.get(item, o?.name, "")}
                                                        type={o.type}
                                                    />
                                                )}
                                            </React.Fragment>
                                        ))}
                                        <div className='d-flex justify-content-end' >
                                            <IconButton text="VIEW" icon={MdRemoveRedEye} onClick={() => handleViewCompanyClick(item)} />
                                        </div>
                                    </div>
                                    <Divider
                                        variant="fullWidth"
                                        component="div"
                                        style={{
                                            backgroundColor: "darkseagreen",
                                            height: "1px",
                                            marginBottom: "1rem",
                                            marginTop: "1rem",
                                        }}
                                    />
                                </>
                            )
                        }) :
                            <div style={{ marginLeft: 15 }}>
                                <span className="product-profile passive-message">{t("No Data Found")} {props.businessId}</span>
                                <div>
                                    <div className="table-responsive-md mt-4">
                                        <table className="table table-striped" style={{ width: "100%", tableLayout: "fixed" }} width="100%">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{t("Type")}</th>
                                                    <th scope="col">{t("Id")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {_productState.productById.businessIdentifiers.map((data, i) => (
                                                    <tr>
                                                        <td>{data.type}</td>
                                                        <td>{data.id}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <Modal size="lg" centered={true} backdrop="static" show={modalData.isShow} onHide={handleModalHide} >
                        <Modal.Header >
                            <div style={{ position: 'absolute', right: 10 }}>
                                <StCloseButton text={t("Close")} onClick={() => handleModalHide()}></StCloseButton>
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{ fontWeight: 'normal' }}>
                            <div className="row col-12 m-0 justify-content-center">
                                <div className='d-flex' style={{ marginLeft: -15 }}>
                                    <span className='col-lg-9 col-md-9 col-sm-9 col-9 mb-3' style={{ color: '#253C4B', fontSize: 14, fontWeight: 'bold' }}>
                                        {t("Manufacturer Details")}
                                    </span>
                                    {productState.isProdEditMode && companyDetailsArray?.length > 1 &&
                                        <div className='col-lg-3 col-md-3 col-sm-3 col-3' style={{ cursor: 'pointer' }}>
                                            <IconButton text={t("Remove button")} icon={BiTrash}
                                                onClick={() => removeManufacturerFromProductProfile(modalData.selectedCompanyData)}
                                            />
                                        </div>
                                    }
                                </div>

                                {componentSchema?.map((o, index) => (
                                    <React.Fragment key={index}>
                                        {_.get(modalData.selectedCompanyData, o.name) && (
                                            <InputTextWithLabel
                                                key={index}
                                                label={t(`${o.label}`)}
                                                tooltipText={t(`${o.tooltipText}`)}
                                                showTooltip={true}
                                                readOnly={true}
                                                name={o.name}
                                                value={_.get(modalData.selectedCompanyData, o.name, "")}
                                                type={o.type}
                                            />
                                        )}
                                    </React.Fragment>
                                ))
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ justifyContent: 'center' }}>
                        </Modal.Footer>
                    </Modal>


                    <Modal size="md" centered={true} backdrop="static" show={deleteConfirm} onHide={handleClose} >
                        <Modal.Header >
                            <div style={{ position: 'absolute', right: 10 }}>
                                <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{ fontWeight: 'normal' }}>
                            <div className="row col-12 m-0 justify-content-center">
                                {t("Are you sure you want to delete this manufacturer")}
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ justifyContent: 'space-around' }}>
                            <StSquareButton text={!deleteLoader ? t("Yes") : <Spinner animation="border" variant="light" />} onClick={() => handleDeleteManufacturer(modalData.selectedCompanyData)}></StSquareButton>
                            <StSquareButton text={t("No")} onClick={() => handleClose()}></StSquareButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal size="lg" centered={true} backdrop="static" show={modalDataForAddManufacturer.isShow} onHide={handleModalHideForAdd} >
                        <Modal.Header >
                            <div style={{ position: 'absolute', right: 10 }}>
                                <StCloseButton text={t("Close")} onClick={() => handleModalHideForAdd()}></StCloseButton>
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{ fontWeight: 'normal' }}>
                            <div className="row col-12 m-0 justify-content-center">
                                <span className="product-profile model-text" >{t("Select Company Here")}</span>
                                <InputMultiSelectDropdown
                                    placeholder={t("Choose an Option")}
                                    search={true}
                                    name={"multiSelectWithModal"}
                                    value={modalDataForAddManufacturer.multiSelectValue}
                                    options={companyData}
                                    onChange={handleMultiSelectValueChange}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ justifyContent: 'center' }}>
                            <StSquareButton text={!addLoader ? t("Add") : <Spinner animation="border" variant="light" />}
                                onClick={() => handleModalAdd()}
                            ></StSquareButton>
                        </Modal.Footer>
                    </Modal>

                </>
            }
        </>

    )
}
