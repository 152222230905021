import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';
// import * as _ from 'lodash'



export const getProductDescriptions = createAsyncThunk(
    "scraping/getProductDescriptions",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/services/scraper${arg}`,
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getAiImageAnalysisPrimary = createAsyncThunk(
    "scraping/getAiImageAnalysisPrimary",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/services/analyze?url=${arg}`,
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getAiImageAnalysisFrontal = createAsyncThunk(
    "scraping/getAiImageAnalysisFrontal",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/services/analyze?url=${arg}`,
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getAiImageAnalysisBackside = createAsyncThunk(
    "scraping/getAiImageAnalysisBackside",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/services/analyze?url=${arg}`,
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getAiImageAnalysisSquare = createAsyncThunk(
    "scraping/getAiImageAnalysisSquare",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/services/analyze?url=${arg}`,
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);
