import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import SwaggerUI from 'swagger-ui-react'
import "swagger-ui-react/swagger-ui.css"
import { swaggerauth } from "src/redux/swaggerAuth/swaggerauthThunk";
import { usePrevious } from "src/helper/CustomHook";
import { useNavigate, useLocation } from "react-router";
import { Spinner } from "src/components/Spinner";

const Swagger = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [swaggerJson,setSwaggerJson] = useState()

  useEffect(()=>{
    dispatch(swaggerauth(location.pathname))
  },[])


  const _state = useSelector((state) => state.swaggerauth, shallowEqual);

  const response = _state.response;
  const isLoading = _state.actionsLoading;
  const prevPropsState = usePrevious({ response });

  useEffect(()=>{
    if (prevPropsState) {
      if (prevPropsState.response !== response ) {
        setSwaggerJson(response)
      }
    }
  },[_state])

  
  return (
    <>
      {isLoading ? (
        <div>
          <Spinner />
        </div>
      ) :
      <div className="pt-1">
      <SwaggerUI spec={swaggerJson}  />
      </div>
}
    </>
  )
}

export default Swagger;