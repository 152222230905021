import { createSlice } from "@reduxjs/toolkit";
import {
  getClickstreamEntries, getClickstreamList, getProductScansAndErrors, getUpidsProductScanCount,
  getClickstreamTotalCount, getClickstreamCompanies, getClickstreamArticles, getClickstreamScanCountListByBusinessId,
  getClickstreamScanCountListByArticleId, getClickstreamEnvs, getBillingDaily, getBillingEstimation, getBillingMonthly, getClickstreamListProducts,
  getClickstreamListProductsNotFound,
  getClickstreamTotalCountNotFound
} from "./analyticsThunk";



const initialAnalyticsState = {
  actionsLoading: false,
  response: null,
  error: null,
  isAllDataFetchedLoading: false,
  isLoading: false,
  clickStreamTotalCountLoading: false,
  clickStreamEntries: false,
  clickStreamListingProduct: false,
  analyticsClickstreamEntries: { entries: [], totalCount: 0 },
  //analyticsClickstreamList: [],
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState: initialAnalyticsState,
  reducers: {

  },



  extraReducers:
    (builder) => {
      builder
        .addCase(getClickstreamEntries.pending, (state) => {
          state.clickStreamEntries = true;
        })
        .addCase(getClickstreamEntries.fulfilled, (state, action) => {
          state.clickStreamEntries = false;
          state.error = null;
          state.analyticsClickstreamEntries = action.payload;
        })
        .addCase(getClickstreamEntries.rejected, (state, action) => {
          state.clickStreamEntries = false;
        })


        .addCase(getClickstreamList.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getClickstreamList.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
        })
        .addCase(getClickstreamList.rejected, (state, action) => {
          state.actionsLoading = false;
        })


        .addCase(getProductScansAndErrors.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getProductScansAndErrors.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.productScansAndErrors = action.payload;
        })
        .addCase(getProductScansAndErrors.rejected, (state, action) => {
          state.actionsLoading = false;
        })

        .addCase(getUpidsProductScanCount.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getUpidsProductScanCount.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.upidsProductScanCount = action.payload;
        })
        .addCase(getUpidsProductScanCount.rejected, (state, action) => {
          state.actionsLoading = false;
        })


        .addCase(getClickstreamTotalCount.pending, (state) => {
          state.clickStreamTotalCountLoading = true;

        })
        .addCase(getClickstreamTotalCount.fulfilled, (state, action) => {
          state.clickStreamTotalCountLoading = false;
          state.error = null;
          state.analyticsClickstreamTotalCount = action.payload;
        })
        .addCase(getClickstreamTotalCount.rejected, (state, action) => {
          state.clickStreamTotalCountLoading = false;
        })

        .addCase(getClickstreamTotalCountNotFound.pending, (state) => {
        })
        .addCase(getClickstreamTotalCountNotFound.fulfilled, (state, action) => {
          state.analyticsClickstreamTotalCount = action.payload;
        })
        .addCase(getClickstreamTotalCountNotFound.rejected, (state, action) => {
        })

        .addCase(getClickstreamListProducts.pending, (state) => {
          state.clickStreamListingProduct = true;

        })
        .addCase(getClickstreamListProducts.fulfilled, (state, action) => {
          state.clickStreamListingProduct = false;
          state.error = null;
          state.analyticsClickstreamListProducts = action.payload;
        })
        .addCase(getClickstreamListProducts.rejected, (state, action) => {
          state.clickStreamListingProduct = false;
        })

        .addCase(getClickstreamListProductsNotFound.pending, (state) => {
        })
        .addCase(getClickstreamListProductsNotFound.fulfilled, (state, action) => {
          state.analyticsClickstreamListProducts = action.payload;
        })
        .addCase(getClickstreamListProductsNotFound.rejected, (state, action) => {
        })


        .addCase(getClickstreamCompanies.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getClickstreamCompanies.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.analyticsClickstreamCompanies = action.payload;
        })
        .addCase(getClickstreamCompanies.rejected, (state, action) => {
          state.actionsLoading = false;
        })


        .addCase(getClickstreamArticles.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getClickstreamArticles.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.analyticsClickstreamArticles = action.payload;
        })
        .addCase(getClickstreamArticles.rejected, (state, action) => {
          state.actionsLoading = false;
        })


        .addCase(getClickstreamScanCountListByBusinessId.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getClickstreamScanCountListByBusinessId.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.analyticsClickstreamTotalCount = action.payload;
        })
        .addCase(getClickstreamScanCountListByBusinessId.rejected, (state, action) => {
          state.actionsLoading = false;
        })


        .addCase(getClickstreamScanCountListByArticleId.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getClickstreamScanCountListByArticleId.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.analyticsClickstreamTotalCount = action.payload;
        })
        .addCase(getClickstreamScanCountListByArticleId.rejected, (state, action) => {
          state.actionsLoading = false;
        })


        .addCase(getClickstreamEnvs.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getClickstreamEnvs.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.analyticsClickstreamEnvs = action.payload;
        })
        .addCase(getClickstreamEnvs.rejected, (state, action) => {
          state.actionsLoading = false;
        })


        .addCase(getBillingDaily.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getBillingDaily.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.billingDaily = action.payload;
        })
        .addCase(getBillingDaily.rejected, (state, action) => {
          state.actionsLoading = false;
        })


        .addCase(getBillingEstimation.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getBillingEstimation.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.billingEstimation = action.payload;
        })
        .addCase(getBillingEstimation.rejected, (state, action) => {
          state.actionsLoading = false;
        })


        .addCase(getBillingMonthly.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(getBillingMonthly.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.error = null;
          state.billingMonthly = action.payload;
        })
        .addCase(getBillingMonthly.rejected, (state, action) => {
          state.actionsLoading = false;
        })

    },
});
