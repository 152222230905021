import React from "react";
import { useTranslation } from "react-i18next";
import BillingTableDaily from "./BillingTableDaily";
import BillingTableEstimation from "./BillingTableEstimation";
import BillingTableMonthly from "./BillingTableMonthly";

const Billing = () => {
  const { t } = useTranslation('common');

  return (
    <div className="dashboard-main-div ps-2">
      <div className="subdiv-1">
        <span className="dashboard-span-text">{t("Billing")}</span>
      </div>
      <div className="st-divider divider-thin"></div>

      <div>
        <div className="row">
          <div className="col-sm-12">
            <BillingTableDaily />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-12">
            <BillingTableEstimation />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-12">
            <BillingTableMonthly />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;
