import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import Modal from "react-bootstrap/Modal";
import { StSquareButton } from 'src/components/StSquareButton';
import { StCloseButton } from 'src/components/StCloseButton';
import { useTranslation } from 'react-i18next';
import { getEmmbededYoutubeVideoUrl } from 'src/helper/helpers';


function NewstFeedHtml(props) {
    const { t, i18n } = useTranslation('common');

    const { _state1, showMore } = props;

    const [openNewsModal, setOpenNewsModal] = useState(false);
    const [newsFeedDetailsToShowIndex, setNewsFeedDetailsToShowIndex] = useState();



    const handleMedia = (mediaList) => {
        const mediaRender = mediaList && mediaList[0] && mediaList.map((item, index) => {
            const contentType = _.get(item, 'sys.mediaFields.file.contentType', "")
            const mediaUrl = _.get(item, 'sys.mediaFields.file.url', "")

            if (contentType.includes("image") && mediaUrl) {
                return (
                    <div key={index} className='m-3'>
                        <img src={mediaUrl} className="contenfulData__image" />
                    </div>
                )
            }
            else if (contentType.includes("video") && mediaUrl) {
                let embadedUrl = mediaUrl
                if (mediaUrl.includes("youtube")) {
                    embadedUrl = getEmmbededYoutubeVideoUrl(mediaUrl)
                }

                return (
                    <div key={index} className='m-3'>
                        <div className='contenfulData__youtubeVideo'>
                            <iframe
                                className='youTubeVideoIframe'
                                id="youTubeVideoIframe"
                                width="100%"
                                height="100%"
                                src={embadedUrl}
                                title="YouTube video player"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                frameBorder="0"
                                allowFullScreen
                            />
                        </div>
                    </div>
                )
            }

        })
        return mediaRender
    }

    let dashboardNewsfeedData = [];
    if (_state1.dashboardNewsfeedData) {
        dashboardNewsfeedData = _.orderBy(_state1.dashboardNewsfeedData, function (obj) {
            return obj.createdDate._seconds;
        }, 'desc');
    }


    const openNewsfeedModal = (i) => {
        setOpenNewsModal(true);
        setNewsFeedDetailsToShowIndex(i);
    }

    return (
        <>

            {
                dashboardNewsfeedData && dashboardNewsfeedData.map((item, index) => (
                    <div key={index} className="mt-2 p-1 newsFeedRow" onClick={() => openNewsfeedModal(index)}>
                        <div className="fw-bold" >{item.title}</div>
                        <div className="prod_subtitle">{moment.unix(item.createdDate._seconds).format("YYYY-MM-DD HH:mm:ss")}</div>
                    </div>
                ))
            }

            <Modal scrollable size="md" show={openNewsModal} onHide={() => setOpenNewsModal(false)} centered={true}>
                <Modal.Header >
                    {dashboardNewsfeedData[newsFeedDetailsToShowIndex] && <span className="fw-bold">{dashboardNewsfeedData[newsFeedDetailsToShowIndex].title}</span>}
                    <div style={{ position: 'absolute', right: 10 }}>
                        <StCloseButton text={t("Close")} onClick={() => setOpenNewsModal(false)}></StCloseButton>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {dashboardNewsfeedData[newsFeedDetailsToShowIndex] &&
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: dashboardNewsfeedData[newsFeedDetailsToShowIndex].content }} />
                            {dashboardNewsfeedData[newsFeedDetailsToShowIndex].media && dashboardNewsfeedData[newsFeedDetailsToShowIndex].media[0] &&
                                <div>{handleMedia(dashboardNewsfeedData[newsFeedDetailsToShowIndex].media)}</div>
                            }
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <StSquareButton text={t("Ok")} onClick={() => setOpenNewsModal(false)} ></StSquareButton>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default NewstFeedHtml

