import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import VisiblityWrapper from "../component/VisiblityWrapper";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { FcOk } from "react-icons/fc";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema, userRegisterSchema } from "src/helper/yup-schema";
import { useNavigate } from "react-router-dom";
import { clearAuthError, allAuthErrorsFormObject } from "src/redux/auth/authSlice";
import { userRegistrationAction } from "src/redux/auth/authThunk";
import { loginAction } from "src/redux/auth/authThunk";
import { Modal, Spinner } from "react-bootstrap";
import * as localStore from "src/helper/localStorageHelper";
import { useTranslation } from "react-i18next";
import { usePrevious } from "src/helper/CustomHook";
import Cookies from 'universal-cookie';
import { TextWithLink } from "src/components/TextWithLink";
import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from "src/components/StSquareButton";
import { getAllTimeZones } from "src/helper/timezones";
import { termsOfUse } from "src/helper/constants/constants";
import SignupModal from "./SignupModal";
import _ from "lodash";
import * as yup from "yup";


const LoginForm = () => {
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { register, handleSubmit, watch, formState: { errors }, } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const allTimezones = getAllTimeZones();
  let selectTimezoneOption = [];
  let timezoneValue;

  const [errorState, setErrorState] = useState("Something went wrong")
  let allErrorsMsg;

  const cookies = new Cookies();
  const [passwordType, setPasswordType] = useState("password");
  const [isPassCheck, setIsPassCheck] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [touchedState, setTouchedState] = useState(false)
  const [showSignupSuccessModal, setShowSignupSuccessModal] = useState(false);
  const [userRegistrationData, setUserRegistrationData] = useState({
    isAgreeTerms: false,
    supportedLanguages: [
      // { code: "en", name: "English" }
    ]
  });

  const _state = useSelector((state) => state.login, shallowEqual);
  const [showTermsUseModal, setShowTermsUseModal] = useState(false);

  const { response, userRegistration, } = _state;
  const prevPropsState = usePrevious({ response, userRegistration });

  let AllErrors = _state.ErrorsformObject;

  useEffect(() => {
    if (window.location.href.indexOf("signup") > -1) {
      setShowSignupModal(true);
    }
    if (window.location.href.indexOf("terms") > -1) {
      setShowTermsUseModal(true);
    }
    return () => {
      dispatch(clearAuthError());
      dispatch(allAuthErrorsFormObject([]));
    }
  }, [window.location.href]);

  useEffect(() => {
    if (prevPropsState) {
      if (prevPropsState.response !== response) {
        localStore.localStorageSave(
          localStore.Keys.AUTH_STATE,
          JSON.stringify(_state.response)
        );
        cookies.set('AUTH_STATE', JSON.stringify(_state.response), { path: '/', secure: true, sameSite: "none", expires: _state.response.refreshToken ? date : null });
        navigate("/dashboard");
      }
    }
  }, [_state]);

  useEffect(() => {
    if (prevPropsState) {
      if (prevPropsState.userRegistration !== userRegistration && userRegistration) {
        handleSignupModalClose()
        setShowSignupSuccessModal(true)
      }
    }
  }, [_state.userRegistration]);

  const createDropdownDataForTimezone = (allTimezones) => {
    selectTimezoneOption = [];
    allTimezones.map((o, index) => {
      let eve = { key: index, text: o.text, value: o.abbr };
      selectTimezoneOption.push(eve);
    });
  };
  createDropdownDataForTimezone(allTimezones);

  const onSubmit = async (data) => {
    try {
      await dispatch(
        loginAction({
          ...data,
          rememberme: isPassCheck,
          deviceId: randomStr(20, "12345abcde"),
        })
      ).unwrap();
      // navigate("/dashboard")
    } catch (error) {
      console.log(error)
    }

  };


  const keepLoggedIn = (value) => {
    setIsPassCheck(value);
  };

  function randomStr(len, arr) {
    var ans = "";
    for (var i = len; i > 0; i--) {
      ans += arr[Math.floor(Math.random() * arr.length)];
    }
    return ans;
  }


  var date = new Date();
  date.setDate(date.getDate() + 30);


  const handleSignupModalClose = () => {
    navigate('/login')
    setShowSignupModal(false)
    setUserRegistrationData({
      isAgreeTerms: false,
      supportedLanguages: [
        // { code: "en", name: "English" }
      ]
    })

  }

  const handleSignupButtonClick = () => {
    navigate('/signup')
    setShowSignupModal(true)
  }

  const handleTermsButtonClick = () => {
    navigate('/terms')
    setShowTermsUseModal(true)
  }
  const handleTermsModalClose = () => {
    navigate('/signup')
    setShowTermsUseModal(false)
  }

  const handleSignupSuccessModelClose = () => {
    window.location.href = 'https://www.secondthought.fi/sign-up-complete/';
  }

  const validator = ({ name, value }) => {
    const existingErrorIndex = AllErrors.findIndex(
      (error) => error.path === name
    );
    let newErrors = [...AllErrors];
    if (name === "confirmPassword") {
      if (!value) {
        newErrors.push({ path: name, message: "Confirm password is required" });
      } else if (value !== userRegistrationData.password) {
        newErrors.push({ path: name, message: "Password must match" });
      } else {
        newErrors = AllErrors.filter((error) => error.path !== name);
      }
      dispatch(allAuthErrorsFormObject(newErrors));
    } else {
      yup
        .reach(userRegisterSchema, name)
        .validate(value)
        .then((valid) => {
          if (newErrors.length > 0) {
            newErrors = newErrors.filter((error) => error.path !== name);
            dispatch(allAuthErrorsFormObject(newErrors));
          }
        })
        .catch((err) => {
          if (existingErrorIndex !== -1) {
            newErrors[existingErrorIndex] = {
              path: name,
              message: err.message,
            };
          } else {
            newErrors.push({ path: name, message: err.message });
          }
          dispatch(allAuthErrorsFormObject(newErrors));
        });
    }
  };

  const handleFormChange = async (e) => {
    if (e.target.name !== "supportedLanguages") {
      validator({ name: e.target.name, value: e.target.value });
    }
    setTouchedState(true);
    setUserRegistrationData({
      ...userRegistrationData,
      [e.target.name]: e.target.value,
    });
  };



  const handleIsAgreeTerms = (e) => {
    setUserRegistrationData({ ...userRegistrationData, [e.target.name]: e.target.checked, })

  }

  const handleTimezoneSelect = (e) => {
    const value = e.target.value
    if (!!value) {
      timezoneValue = allTimezones.find(({ abbr }) => abbr === value);
      setUserRegistrationData({ ...userRegistrationData, timezone: timezoneValue.offset.toString(), abbr: value, })
    }
    AllErrors = _.filter((_state.ErrorsformObject) || [], (d) => (d?.path !== e.target.name)) || []
    dispatch(allAuthErrorsFormObject(AllErrors));
  };

  const handleUserRegistration = async () => {
    try {
      await userRegisterSchema.validate(userRegistrationData, { abortEarly: false });
      dispatch(allAuthErrorsFormObject([]));
      let res = await dispatch(userRegistrationAction(userRegistrationData)).unwrap();
      if (res) {
        setErrorState("")
      }
    } catch (error) {
      // Handle any validation errors or other unexpected errors here
      setErrorState(error?.response?.data?.error?.message || "Something Went Wrong");
      allErrorsMsg = error?.inner || [];
      dispatch(allAuthErrorsFormObject(allErrorsMsg));
      setTouchedState(true)
    }
  };


  const errorObject = {}; // Define the errorObject as an empty object

  const errorShown = (AllErrors) => {
    AllErrors &&
      AllErrors[0] &&
      AllErrors.forEach((obj, index) => {
        errorObject[obj.path] = obj.message;
      });
  };

  errorShown(AllErrors);

  return (
    <>
      <div>
        <p className="content-header">
          {t("Trace the product lifecycles, at each single step at atomic level")}
        </p>
        <div>
          <p className="content-subtitle">
            {t("Welcome, Log in to your account")}
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <input
                name="email" {...register('email')}
                className={"login-input"}
                type="text"
                placeholder={t("User Name")}
              />
            </div>
            <span className="login-error-text">
              {" "}
              {!!errors?.email && errors?.email?.message}
            </span>
            <VisiblityWrapper setPasswordType={setPasswordType}>
              <input
                name="password" 
                {...register('password')}
                className={"login-input"}
                type={passwordType}
                placeholder={t("Password")}
              />
            </VisiblityWrapper>
            <span className="login-error-text">
              {" "}
              {!!errors?.password && errors?.password?.message}
            </span>
            <span className="login-error-text">
              {" "}
              {!!_state.error && "Invalid password"}
            </span>
            <div className="keep-login-container">
              {isPassCheck ? (
                <MdCheckBox
                  onClick={() => keepLoggedIn(false)}
                  style={{ cursor: "pointer" }}
                  color="#ffffff"
                  size={24}
                />
              ) : (
                <MdCheckBoxOutlineBlank
                  onClick={() => keepLoggedIn(true)}
                  style={{ cursor: "pointer" }}
                  color="#203441"
                  size={24}
                />
              )}
              <label
                className="login-lable"
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: "#ffffff",
                  marginLeft: 8,
                }}
              >
                {t("Keep me logged in")}
              </label>
            </div>
            <button type="submit" className="login-button">
              {!_state.actionsLoading ? (
                t("LOGIN")
              ) : (
                <Spinner animation="border" variant="light" />
              )}
            </button>
          </form>
          <div className="signup-container col-12">
            <div className="col-5" style={{ fontSize: 13, cursor: 'pointer' }}>
              <TextWithLink onClick={() => handleSignupButtonClick()} text={t("Sign Up")} color="white" />
            </div>
            <div className="col-7" style={{ fontSize: 13, cursor: 'pointer' }} >
              <TextWithLink onClick={() => navigate("/resetpassword")} text={t("Reset Password")} color="white" />
            </div>
          </div>
        </div>

        <SignupModal
          showSignupModal={showSignupModal}
          userRegistrationData={userRegistrationData}
          handleSignupModalClose={handleSignupModalClose}
          handleFormChange={handleFormChange}
          selectTimezoneOption={selectTimezoneOption}
          handleTimezoneSelect={handleTimezoneSelect}
          handleIsAgreeTerms={handleIsAgreeTerms}
          errorObject={errorObject}
          handleTermsButtonClick={handleTermsButtonClick}
          errorMsg={errorState}
          handleUserRegistration={handleUserRegistration}
          touchedState={touchedState}
        />


        <Modal size="md" show={showSignupSuccessModal} centered={true}>
          <Modal.Header >
            <div style={{ position: 'absolute', right: 10 }}>
              <StCloseButton text={t("Close")} onClick={() => handleSignupSuccessModelClose()}></StCloseButton>
            </div>
          </Modal.Header>
          <Modal.Body style={{ display: 'block', padding: 50, textAlign: 'center', color: '#131e27', opacity: 1, fontWeight: 'bold', fontSize: 18, }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FcOk
                style={{
                  width: 40,
                  height: 40,
                  textAlign: "center",
                  alignSelf: "center",
                }}
              />
              <span>{t("Registration received succesfully")}</span>
              <span>{t("Registration will be reviewed by UPIDS admin team")}</span>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: 'center' }}>
            <StSquareButton text={t("Ok")} onClick={() => handleSignupSuccessModelClose()} ></StSquareButton>
          </Modal.Footer>
        </Modal>

        <Modal scrollable size="lg" show={showTermsUseModal} onHide={() => handleTermsModalClose()} centered={true}>
          <Modal.Header >
            <div style={{ position: 'absolute', right: 10 }}>
              <StCloseButton text={t("Close")} onClick={() => handleTermsModalClose()}></StCloseButton>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: termsOfUse[i18n.language] }}></div>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: 'center' }}>
            <StSquareButton text={t("Ok")} onClick={() => handleTermsModalClose()} ></StSquareButton>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default LoginForm;
