import { createSlice } from "@reduxjs/toolkit";
import { getAllCmsListContentAction, gateSpecificCmsContentAction, createCmsContentAction, deleteCmsContentAction, getAllCmsCategoriesAction } from "./contentManagementThunk";

const initialState = {
    isLoading: false,
    allCmsContentList: null,
    allCmsCategoriesList:[],
    isShowAddUpdateConentManagementModal: false,
    error: null,
    isEditMode: false,
    cmsFormData: {
        title: "",
        category: "",
        order: "",
        created: "",
        modified: "",
        language: "en",
        content: "",
        // contentId: 9876598,
        reference: "", // UPIDS-PDM
        // productIdentifier: "999888777666555",
    }
};

const cmsContent = createSlice({
    name: "cmsContent",
    initialState: initialState,
    reducers: {
        showAddUpdateContentManagementModalAction: (state, action) => {
            state.isShowAddUpdateConentManagementModal = action.payload;
        },
        updateCmsDataFormAction: (state, action) => {
            if (action.payload && action.payload.name) {
                const { name, value } = action.payload
                state.cmsFormData[name] = value;
            }
        },
        resetCmsDataFormAction: (state, action) => {
            state.cmsFormData = initialState.cmsFormData
        },
        changeEditModeAction: (state, action) => {
            state.isEditMode = action.payload
        }
    },
    extraReducers:
        (builder) => {
            builder
                /** RETRIEVE CONTENT */
                .addCase(getAllCmsListContentAction.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(getAllCmsListContentAction.fulfilled, (state, action) => {
                    state.allCmsContentList = action.payload;
                    state.isLoading = false;
                })
                .addCase(getAllCmsListContentAction.rejected, (state, action) => {
                    state.isLoading = false;
                    state.allCmsContentList = null;
                    state.error = action.payload;
                })

                .addCase(getAllCmsCategoriesAction.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(getAllCmsCategoriesAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.allCmsCategoriesList = action.payload;
                })
                .addCase(getAllCmsCategoriesAction.rejected, (state, action) => {
                    state.isLoading = false;
                    state.allCmsCategoriesList = [];
                    state.error = action.payload;
                })

                /** RETREIVE SPECIFIC CONTENT */
                .addCase(gateSpecificCmsContentAction.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(gateSpecificCmsContentAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.cmsFormData = action.payload;
                })
                .addCase(gateSpecificCmsContentAction.rejected, (state, action) => {
                    state.isLoading = false;
                    state.error = action.payload;
                })



                /** CREATE CONTENT */
                .addCase(createCmsContentAction.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(createCmsContentAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                })
                .addCase(createCmsContentAction.rejected, (state, action) => {
                    state.isLoading = false;
                    state.error = action.payload;
                })


                /** DELETE CONTENT */
                .addCase(deleteCmsContentAction.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(deleteCmsContentAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                })
                .addCase(deleteCmsContentAction.rejected, (state, action) => {
                    state.isLoading = false;
                    state.error = action.payload;
                })

        },
});

export const { showAddUpdateContentManagementModalAction, updateCmsDataFormAction,
     resetCmsDataFormAction, changeEditModeAction
} = cmsContent.actions;

export default cmsContent;
