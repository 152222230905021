import React, { Component, useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProductByIdAction } from "src/redux/products/productThunk";
import ProductSidebar from "../components/ProductSideBar";
import foodPic from "../../../assets/images/Dummy_Product_Pic.png";
import qrcode from "../../../assets/images/qrcode.png";
import './UpidsForm'
import UpidsForm from "./UpidsForm";
import { Spinner } from "src/components/Spinner";

function AddUpidsProduct() {
  let { gtin } = useParams();
  const dispatch = useDispatch();

  let extractGtinNumber = gtin.split("-")[0];

  const _state = useSelector((state) => state.products, shallowEqual);

  useEffect(() => {
    dispatch(getProductByIdAction({ isLoader: true, gtin: extractGtinNumber }));
  }, []);

  if (_state?.editProductProfileLoading) {
    return <Spinner />
  }

  return (
    <div>
      {!!_state.productById && (
        <div className="row">
          <ProductSidebar
            prod_image={
              !!_state.productById.multimedia && _state.productById.multimedia.primary ? _state.productById.multimedia.primary[0] :
                !!_state.productById.media &&
                  _state.productById.media.images[1024][0] !== "" &&
                  _state.productById.media.images[1024][0] !== null
                  ? _state.productById.media.images[1024][0]
                  : foodPic
            }
            prod_title={
              !!_state.productById.productName
                ? _state.productById.productName.en
                : ""
            }
            prod_subtitle={
              _state.productById.descriptionShort
                ? _state.productById.descriptionShort.en
                : ""
            }
            prod_desc={_state.productById.brandName}
            prod_status="Published"
            productData={_state.productById}
            prod_qrcode={qrcode}
          />
          <UpidsForm productData={_state.productById} />
        </div>
      )}
    </div>
  );
}

export default AddUpidsProduct;
