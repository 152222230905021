import React, { useEffect, useState } from 'react';
import { changeFormObject } from 'src/redux/products/productsSlice';
import { getNutritionalClaimsAction } from 'src/redux/products/productThunk';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { findVal } from '../../../../helper/CustomHook';
import { IoAddCircleOutline } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { MdClose } from "react-icons/md";
import { nutritionalCliams, nutritionalNames, nutritionalCalculatedFor, nutritionalQuantityUnit, nutritionalPrecision, nutritionalTypeCodes } from '../../../../helper/MetadataCollection';
import { Dropdown, Popup } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';
import { IoIosHelpCircleOutline } from 'react-icons/io'

import { StCloseButton } from "src/components/StCloseButton";
import { StSquareButton } from "src/components/StSquareButton";
import { StCancelSquareButton } from 'src/components/StCancelSquareButton'
import { Badges } from 'src/components/Badges';
import { AddButton } from 'src/components/AddButton';
import { InputSelectDropdown } from 'src/components/inputComponents/InputSelectDropdown';
import { InputTextWithLabel } from 'src/components/inputComponents/InputTextWithLabel';

import _ from "lodash"

export const NutritionalTab = () => {
  const { t, i18n } = useTranslation('common');
  const dispatch = useDispatch();

  const _state = useSelector(
    (state) => (state.products),
    shallowEqual
  );

  const productData = _state.formObject


  const [show, setShow] = useState(false);
  const [nutrientForm, setNutrientForm] = useState({
    nutrientName: {
      en: "",
    }
  });


  const [error, setError] = useState({
    adClaimsInput: "",
    nutrientNameError: "",
    quantityError: ""
  });



  useEffect(() => {
    dispatch(getNutritionalClaimsAction({ language: i18n.language }));
  }, [i18n.language])


  const nutritionalCalculatedForData = []
  const getNutritionalCalculatedFor = () => {
    nutritionalCalculatedFor.map((data, i) => {
      nutritionalCalculatedForData.push(
        { key: i, value: data, text: data }
      )
    })
  }
  getNutritionalCalculatedFor()

  const nutritionalCliamsDropdownData = [];
  const getNutritionalClaims = () => {
    _state.nutritionalCliams.map((data, i) => {
      nutritionalCliamsDropdownData.push(
        { key: i, value: data.claim, text: data.claim },
      )
    })
  }
  _state && _state.nutritionalCliams.length > 0 && getNutritionalClaims();



  const nutritionalQuantityUnitData = []
  const getNutritionalQuantityUnit = () => {
    nutritionalQuantityUnit.map((data, i) => {
      nutritionalQuantityUnitData.push(
        { key: i, value: data.value, text: data.value }
      )
    })
  }
  getNutritionalQuantityUnit()

  const nutritionalNamesData = []
  const getNutritionalNames = () => {
    nutritionalNames.map((data, i) => {
      nutritionalNamesData.push(
        { key: i, value: data.value, text: data.value }
      )
    })
  }
  getNutritionalNames()

  const nutritionalPrecisionData = []
  const getNutritionalPrecision = () => {
    nutritionalPrecision.map((data, i) => {
      nutritionalPrecisionData.push(
        { key: i, value: data, text: data }
      )
    })
  }
  getNutritionalPrecision()

  const nutritionalTypeCodesData = []
  const getNutritionalTypeCodes = () => {
    nutritionalTypeCodes.map((data, i) => {
      nutritionalTypeCodesData.push(
        { key: i, value: data, text: data }
      )
    })
  }
  getNutritionalTypeCodes()

  const handleFormChange = (e, _index) => {
    if (_index !== undefined) {
      let newDetails = [...productData.nutritional.details];
      if (e.target.name === 'nutrientName') {
        let nutrientNameObject = _.find(nutritionalNames, ["value", e.target.value])
        newDetails[_index] = { ...newDetails[_index], [e.target.name]: { en: e.target.value }, "typeCode": nutrientNameObject.code };
      } else if (e.target.name === 'typeCode') {
        let nutrientNameObject = _.find(nutritionalNames, ["code", e.target.value])
        newDetails[_index] = { ...newDetails[_index], [e.target.name]: e.target.value, "nutrientName": { en: nutrientNameObject.value } };
      } else if (e.target.name === 'quantityUnit') {
        let quantityUnitObject = _.find(nutritionalQuantityUnit, ["value", e.target.value])
        newDetails[_index] = { ...newDetails[_index], [e.target.name]: quantityUnitObject.code, "quantity_quantityUnit": `${newDetails[_index].quantity} ${quantityUnitObject.code}` };
      } 
      else {
      newDetails[_index] = { ...newDetails[_index], [e.target.name]: e.target.value };
      }

      const newProductData = JSON.parse(JSON.stringify(productData))
      _.set(newProductData, `nutritional.details`, newDetails);
      dispatch(changeFormObject({ ...newProductData, isChange: 1 }))
    }
  };


  const handleNutrientFormChange = (e, data) => {
    // dropdown added on add nutritional popup
    if (!!data) {
      if (e.target.name === 'nutrientName') {
        let nutrientNameObject = _.find(nutritionalNames, ["value", e.target.value])
        setNutrientForm({ ...nutrientForm, [e.target.name]: { en: e.target.value }, "typeCode": nutrientNameObject.code });
        setError({ ...error, nutrientNameError: '' })
      } else if (e.target.name === 'typeCode') {
        let nutrientNameObject = _.find(nutritionalNames, ["code", e.target.value])
        setNutrientForm({ ...nutrientForm, [e.target.name]: e.target.value, "nutrientName": { en: nutrientNameObject.value } });
      } else {
        setNutrientForm({ ...nutrientForm, [e.target.name]: e.target.value });
      }
    } else {
      // number validation for quantity field to check whether any alphabet includes in number
      const newValue = e.target.value.replace(",", ".")
      if (!isNaN(newValue)) {
        setNutrientForm({ ...nutrientForm, [e.target.name]: newValue, "quantity_quantityUnit": `${newValue} ${nutrientForm.quantityUnit}` });
        setError({ ...error, quantityError: "" })
      } else {
        setNutrientForm({ ...nutrientForm, [e.target.name]: "" });
        setError({ ...error, quantityError: "Please enter number" })
      }
    }
  };

  const handleClose = () => {
    setError({ ...error, nutrientNameError: "" });
    setShow(false);
  }


  const addNutrient = () => {
    if (nutrientForm.nutrientName.en === "") {
      setError({ ...error, nutrientNameError: "Nutrient Name is required" });
    }
    else {
      let newDetails = [..._.get(productData, "nutritional.details", [])]

      // to get quantityUnit code and value
      let nutritionalData = { ...nutrientForm }
      if (!!nutrientForm.quantityUnit) {
        let quantityUnitObject = _.find(nutritionalQuantityUnit, ["value", nutrientForm.quantityUnit])
        nutritionalData.quantityUnit = quantityUnitObject.code
        nutritionalData.quantity_quantityUnit = `${nutritionalData.quantity} ${nutritionalData.quantityUnit}`
      }

      newDetails.push(nutritionalData);

      const newProductData = JSON.parse(JSON.stringify(productData))
      _.set(newProductData, `nutritional.details`, newDetails);
      dispatch(changeFormObject({ ...newProductData, isChange: 1 }))

      setNutrientForm({
        nutrientName: {
          en: "",
        }
      });

      setShow(false)
    }
  }

  const deleteNutrient = (index) => {
    const newDetails = [..._.get(productData, "nutritional.details", [])]
    newDetails.splice(index, 1);

    const newProductData = JSON.parse(JSON.stringify(productData))
    _.set(newProductData, `nutritional.details`, newDetails);
    dispatch(changeFormObject({ ...newProductData, isChange: 1 }))
  }


  const getQuantityUnitValue = (quantityUnitCode) => {
    let quantityUnitObject = _.find(nutritionalQuantityUnit, ["code", quantityUnitCode])
    return quantityUnitObject.value
  }



  return (
    <div >
      <form>
        <div style={{ marginBottom: 26, marginTop: 10 }} className="row col-lg-12">
          <div style={{ marginBottom: 16 }} className="row col-lg-12">
            <span className='col-lg-12 tab-section-info mt-2'>{t("Details")}</span>
          </div>
          {
            _.get(productData, "nutritional.details", []).map((o, index) => (
              <div key={index} style={{ marginBottom: 0, marginTop: 0 }} className="row col-lg-12">
                {index > 0 && <div className="ui divider col-lg-12" style={{ marginTop: 5, marginBottom: 8 }}></div>}
                {_state.isProdEditMode ?
                  <div className="row col-lg-12 justify-content-end"><span onClick={() => deleteNutrient(index)} style={{ color: '#FF6060', fontSize: 12, fontWeight: 'bold', cursor: 'pointer' }}>{t("Delete")}</span></div>
                  : ""}

                <InputSelectDropdown
                  label={t("_Nutrient Name")}
                  tooltipText={t('Nutrient Name')}
                  showTooltip={true}
                  placeholder={t("_Nutrient Name")}
                  name="nutrientName"
                  search={true}
                  value={!!o.nutrientName ? o.nutrientName.en : ''}
                  options={nutritionalNamesData}
                  onChange={(e) => handleFormChange(e, index)}
                  disabled={_state.isProdEditMode ? false : true}
                />

                <InputSelectDropdown
                  label={t("_Precision")}
                  tooltipText={t('Precision')}
                  showTooltip={true}
                  placeholder={t("_Precision")}
                  name="precision"
                  search={true}
                  value={o.precision}
                  options={nutritionalPrecisionData}
                  onChange={(e) => handleFormChange(e, index)}
                  disabled={_state.isProdEditMode ? false : true}
                />

                <InputTextWithLabel
                  label={t('_Quantity')}
                  tooltipText={t('Quantity')}
                  showTooltip={true}
                  name={"quantity"}
                  type="text"
                  placeholder={t('_Quantity')}
                  value={o.quantity}
                  onChange={(e) => handleFormChange(e, index)}
                  readonly={_state.isProdEditMode ? false : true}
                />

                <InputSelectDropdown
                  label={t("_Quantity Unit")}
                  tooltipText={t('Quantity Unit')}
                  showTooltip={true}
                  placeholder={t("_Quantity Unit")}
                  name="quantityUnit"
                  search={true}
                  value={!!o.quantityUnit ? getQuantityUnitValue(o.quantityUnit) : ''}
                  options={nutritionalQuantityUnitData}
                  onChange={(e) => handleFormChange(e, index)}
                  disabled={_state.isProdEditMode ? false : true}
                />

                <InputSelectDropdown
                  label={t("_Type Code")}
                  tooltipText={t('Type Code')}
                  showTooltip={true}
                  placeholder={t("_Type Code")}
                  name="typeCode"
                  search={true}
                  value={o.typeCode}
                  options={nutritionalTypeCodesData}
                  onChange={(e, data) => handleFormChange(e, index, data)}
                  disabled={_state.isProdEditMode ? false : true}
                />

              </div>
            ))
          }

        </div>
        {_state.isProdEditMode ? <div className="d-flex justify-content-center align-items-center">
          <AddButton buttontext={t("Add New Nutrient")} onClick={() => setShow(true)}></AddButton>
        </div> : ""}
      </form>

      <Modal size="lg" show={show} onHide={handleClose} centered={true}>
        <Modal.Header>
          <div style={{ position: 'absolute', right: 10 }}>
            <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
          </div>
        </Modal.Header>
        <Modal.Body >
          <div className="row col-12 m-0 justify-content-center">
            <span className="col-12 mb-2  product-profile model-text">{t("Add New Nutrient")}</span>

            <InputSelectDropdown
              label={t("_Nutrient Name")}
              tooltipText={t('Nutrient Name')}
              showTooltip={true}
              placeholder={t("_Nutrient Name")}
              name="nutrientName"
              search={true}
              value={nutrientForm.nutrientName.en}
              options={nutritionalNamesData}
              onChange={(e) => handleNutrientFormChange(e, true)}
              errorMsg={error.nutrientNameError ? error.nutrientNameError : ''}
            />

            <InputSelectDropdown
              label={t("_Precision")}
              tooltipText={t('Precision')}
              showTooltip={true}
              placeholder={t("_Precision")}
              name="precision"
              search={true}
              options={nutritionalPrecisionData}
              onChange={(e) => handleNutrientFormChange(e, true)}
            />

            <InputTextWithLabel
              label={t('_Quantity')}
              tooltipText={t('Quantity')}
              showTooltip={true}
              onChange={handleNutrientFormChange}
              name={"quantity"}
              value={nutrientForm.quantity}
              type="text"
              placeholder={t('_Quantity')}
              errorMsg={error.quantityError != '' ? error.quantityError : ''}
            ></InputTextWithLabel>

            <InputSelectDropdown
              label={t("_Quantity Unit")}
              tooltipText={t('Quantity Unit')}
              showTooltip={true}
              placeholder={t("_Quantity Unit")}
              name="quantityUnit"
              search={true}
              options={nutritionalQuantityUnitData}
              onChange={(e) => handleNutrientFormChange(e, true)}
            />

            <InputSelectDropdown
              label={t("_Type Code")}
              tooltipText={t('Type Code')}
              showTooltip={true}
              placeholder={t("_Type Code")}
              search={true}
              name="typeCode"
              options={nutritionalTypeCodesData}
              value={nutrientForm.typeCode}
              onChange={(e) => handleNutrientFormChange(e, true)}
            />

          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <StSquareButton text={t("Add")} onClick={() => addNutrient()}></StSquareButton>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

