import { createSlice } from "@reduxjs/toolkit";
import { swaggerauth } from "./swaggerauthThunk";

const initialSwaggerState = {
  actionsLoading: false,
  response: null,
  error: null,

};

export const swaggerauthSlice = createSlice({
  name: "swaggerauth",
  initialState: initialSwaggerState,
  reducers: {
    
  },

  extraReducers:
    (builder) => {
      builder
        .addCase(swaggerauth.pending, (state) => {
          state.actionsLoading = true;
        })
        .addCase(swaggerauth.fulfilled, (state, action) => {
          state.actionsLoading = false;
          state.response = action.payload;
        })
        .addCase(swaggerauth.rejected, (state, action) => {
          state.actionsLoading = false;
        })
    },
})