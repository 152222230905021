import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';




export const getProductFeedbackDetail = createAsyncThunk(
    "feedback/getProductFeedbackDetail",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/feedback/id/${arg.id}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


//Action call for getting all users data
export const getAllFeedbacks = createAsyncThunk(
    "feedback/getAllFeedbacks",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/feedback/list`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getProductFeedback = createAsyncThunk(
    "feedback/getProductFeedback",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                // `/upids/feedback/gtin/${arg.gtin}`
                `/upids/feedback/articleid?gtin=${arg.gtin}&_2an=${arg._2an}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


//Action call for feedback data search by business id , upids or article identifier
export const getFeedbackSearch = createAsyncThunk(
    "feedback/getFeedbackSearch",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/feedback/search?value=${arg}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


//Action call for deleting the feedback record
export const deleteFeedback = createAsyncThunk(
    "feedback/deleteFeedback",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().delete(
                `/upids/feedback/id/${arg}`
            );
            return response;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



//Action call for getting the feedback conversations
export const getFeedbackConversations = createAsyncThunk(
    "feedback/getFeedbackConversations",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/feedback/conversation/${arg}`
            );

            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


//Action call for replying to the feedback
export const replyToFeedback = createAsyncThunk(
    "feedback/replyToFeedback",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/services/email`, arg
            );

            await thunkApi.dispatch(getFeedbackConversations(arg.feedbackId))

            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);
