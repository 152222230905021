import React from "react";
import Pagination from "./productsTable/Pagination";
import { liveProductSearch } from "src/redux/products/productThunk";
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import food_pic from '../../../assets/images/Dummy_Product_Pic.png';
import { Spinner } from "src/components/Spinner";
import { ProductCard } from "src/components/ProductCard";
import { useTranslation } from "react-i18next";

import objectPath from "object-path";


const GridView = (props) => {
  const { PageCount, setPageCount } = props.data;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation('common');

  const nextPage = () => {
    setPageCount(PageCount + 1);

    if (props.searchText === "") {
      if (props.productList[props.productList.length - 1].gtin) {
        props.getProducts(
          "next",
          props.productList[props.productList.length - 1].gtin,
          "gtin"
        );
      } else {
        props.getProducts(
          "next",
          props.productList[props.productList.length - 1]._2an,
          "_2an"
        );
      }
    } else {
      setPageCount(PageCount + 1, (PageCount) => {
        dispatch(liveProductSearch({ searchText: props.searchText, pageNo: PageCount }));


      })
    }

  };

  const prevPage = () => {
    setPageCount(PageCount - 1);
    if (props.searchText === "") {
      if (props.productList[0].gtin) {
        props.getProducts("previous", props.productList[0].gtin, "gtin");
      } else {
        props.getProducts("previous", props.productList[0]._2an, "_2an");
      }
    } else {
      setPageCount(PageCount - 1, (PageCount) => {
        dispatch(liveProductSearch({ searchText: props.searchText, pageNo: PageCount }));


      })
    }
  };

  const getProductName = (o) => {
    if (!!o.productName) {
      if (!!o.productName.en && o.productName.en !== "") {
        return o.productName.en
      } else {
        return o.productName.fi
      }

    } else {
      return "NA";
    }
  }

  const getProductCardImage = (data) => {
    let image = ''
    if (!!data.multimedia && data.multimedia.primary) {
      image = data.multimedia.primary[0]
    } else if (!!data.media) {
      image = typeof (data.media) == "string" ? data.media :
        typeof (data.media) == "object" ? data.media.images[1024][0] !== "" && data.media.images[1024][0] !== null ? data.media.images[1024][0] : food_pic : food_pic
    } else {
      image = food_pic
    }
    return image
  }

  return (
    <>
      {props.productList === 0 ? <div className="text-center">{t("No Records Found")}</div> : ''}
      {props.loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        !!props?.productList && props?.productList?.length > 0 ?
        <div>
          <div style={{ display: "flex", flexWrap: 'wrap' }} className="product_card">
            {!!props.productList &&
              props.productList.map((o, index) => (

                <ProductCard
                  key={index}
                  imageSrc={getProductCardImage(o)
                    // !!o.multimedia && o.multimedia.primary ? o.multimedia.primary[0]  :
                    // !!o.media && typeof(o.media) == "string" ? o.media : typeof(o.media) == "object" ? 
                    //   o.media.images[1024][0] !== "" &&
                    //   o.media.images[1024][0] !== null
                    //   ? o.media.images[1024][0]
                    //   : food_pic : food_pic
                  }
                  content={getProductName(o)}
                  height={'195px'}
                  width={'176px'}
                  imageHeight={'136px'}
                  imageWidth={'176px'}
                  onClick={() => navigate(`/productProfile/${!!objectPath.get(o, 'gtin') ? objectPath.get(o, 'gtin') : objectPath.get(o, '_2an')}`,
                    {
                      state: { 
                        productData: o,
                        firstProductObject:props?.productList?.[0],
                        countOfPage:PageCount
                       }
                    })}
                  isFavourite={!!o.isFavourite ? o.isFavourite : false}
                  onStarClick={() => props.favouritesClickHandle(!!o.gtin ? o.gtin : o._2an)}
                ></ProductCard>
              ))}
          </div>
          <div>
            <br></br>
            {!props.isDashboard && !props.isFavourite && <Pagination
              prevPage={prevPage}
              nextPage={nextPage}
              pageCount={PageCount}
              allProductsCount={props.productList && props.productList.length}
            />}

          </div>
        </div> :
        <div className="mb-4 text-center">{t("No products found")}</div>


      )}
    </>
  );
};

export default GridView;
