import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import ContentManagementList from "./components/ContentManagementList";
import ContentManagementCreate from "./components/ContentmanagementCreate";
import ContentmanagementEdit from "./components/ContentmanagementEdit";
import { getCompanyDetails } from 'src/redux/company/companyThunk';
import { getAllCmsCategoriesAction } from "src/redux/contentManagement/contentManagementThunk";
import { AppContext } from 'src/pages/AppContext';

const ContentManagement = () => {
  const dispatch = useDispatch()
  const context = useContext(AppContext);

  useEffect(() => {
    dispatch(getCompanyDetails())

    if (context.authState.level == 10) {
      dispatch(getAllCmsCategoriesAction('cms-admin-categories'));
    }
    else {
      dispatch(getAllCmsCategoriesAction('cms-user-categories'));
    }
    return () => {
    }
  }, [])

  return (
    <Routes>
      <Route path={`create`} element={<ContentManagementCreate />} />
      <Route path={`/edit/:id`} element={<ContentmanagementEdit />} />
      <Route path={`/`} element={<ContentManagementList />} />
    </Routes>
  );
};

export default ContentManagement;
