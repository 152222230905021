import { createSlice } from "@reduxjs/toolkit";
import { getAccountTransactionsAction, getAccountInvoicesAction, getAccountCountAction, getAccountBillingAction, paymentByVoucherCodeAction } from "./accountThunk";

const initialState = {
    isLoading: false,
    isBillingLoading: false,
    transactionEventList: [],
    invoiceList: [],
    accountCount: {
        productCount: 0
    },
    isShowPaymentModal: false,
    isShowPaymentTypeModal: false,
    isShowPaymentVoucherModal:false,
    paymentStatus: "",
    error: null,
    voucherCodeValue:null,
};

const accountSlice = createSlice({
    name: "accountSlice",
    initialState: initialState,
    reducers: {
        showPaymentModalAction: (state, action) => {
            state.isShowPaymentModal = action.payload;
        },
        showPaymentVoucherModalAction: (state, action) => {
            state.isShowPaymentVoucherModal = action.payload;
        },
        showPaymentTypeModalAction: (state, action) => {
            state.isShowPaymentTypeModal = action.payload;
        },
    },
    extraReducers:
        (builder) => {
            builder
                /** RETRIEVE INFO*/
                .addCase(getAccountTransactionsAction.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(getAccountTransactionsAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.transactionEventList = action.payload;
                })
                .addCase(getAccountTransactionsAction.rejected, (state, action) => {
                    state.isLoading = false;
                    state.transactionEventList = [];
                    state.error = action.payload;
                })


                .addCase(getAccountInvoicesAction.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(getAccountInvoicesAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.invoiceList = action.payload;
                })
                .addCase(getAccountInvoicesAction.rejected, (state, action) => {
                    state.isLoading = false;
                    state.invoiceList = [];
                    state.error = action.payload;
                })

                .addCase(getAccountCountAction.pending, (state) => {
                    state.isLoading = true;
                })
                .addCase(getAccountCountAction.fulfilled, (state, action) => {
                    state.isLoading = false;
                    state.accountCount = action.payload;
                })
                .addCase(getAccountCountAction.rejected, (state, action) => {
                    state.isLoading = false;
                    state.accountCount = {};
                    state.error = action.payload;
                })
            
                .addCase(getAccountBillingAction.pending, (state) => {
                    state.isBillingLoading = true;
                    state.paymentStatus = "";
                })
                .addCase(getAccountBillingAction.fulfilled, (state, action) => {
                    state.isBillingLoading = false;
                })
                .addCase(getAccountBillingAction.rejected, (state, action) => {
                    state.isBillingLoading = false;
                    state.error = action.payload;
                })

                .addCase(paymentByVoucherCodeAction.pending, (state) => {
                    state.isBillingLoading = true;
                    state.paymentStatus = "";
                    state.voucherCodeValue = null;
                })
                .addCase(paymentByVoucherCodeAction.fulfilled, (state, action) => {
                    state.isBillingLoading = false;
                    state.billingSuccess= true;
                    state.paymentStatus = "success";
                    state.voucherCodeValue = action.payload.value;
                })
                .addCase(paymentByVoucherCodeAction.rejected, (state, action) => {
                    state.isBillingLoading = false;
                    state.paymentStatus = "failed";
                    state.error = action.payload;
                    state.voucherCodeValue = null;
                })
        },
});

export const { showPaymentModalAction,showPaymentTypeModalAction,showPaymentVoucherModalAction } = accountSlice.actions;

export default accountSlice;
