import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { StMenu } from "src/components/StMenu";
import ModalWithSaveCancel from "./resources/ModalWithSaveCancel";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MdArrowBack, MdAddCircle, MdMenu, MdClose, MdOutlineModeEdit, MdOutlineSave, MdOutlineClose, } from "react-icons/md";
import { BiTrash } from "react-icons/bi";
import logo from 'src/assets/images/brand_icons/upids-pdm-logo.png'
import { IconButton } from "src/components/IconButton"
import { RoundedgeButton } from "src/components/RoundedgeButton"
import { Header } from "src/components/Header"
import { createCmsContentAction, updateCmsContentAction, deleteCmsContentAction } from 'src/redux/contentManagement/contentManagementThunk';
import { changeEditModeAction } from "src/redux/contentManagement/contentManagementSlice";
import { Spinner } from "src/components/Spinner";
import moment from "moment";



const ContentManagementNavbar = () => {

  /**
  const manufacturerNavbarCollection = [
    { menuItem: () => <IconButton text={t("Add Manufacturer")} icon={MdAddCircle} onClick={() => handleOnClickCreateRoute()} /> },
  ]
 */

  const navigate = useNavigate();
  let pathName = window.location.href
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const companyState = useSelector((state) => state.company, shallowEqual);
  const { companyInfo, } = companyState

  const cmsContent = useSelector((state) => state.cmsContent, shallowEqual);
  const { isLoading, isEditMode, cmsFormData } = cmsContent


  const [modalData, setModalData] = useState({
    show: false,
    modalBody: "",
    modalOkText: "",
    modalCancelText: "",
    handleSaveChanges: null,
  })

  const [showErrorMessage,setShowErrorMessage]= useState(false);


  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  const handleChaneEditMode = () => {
    dispatch(changeEditModeAction(true))
  }

  const handleOnclickConfirmButton = async () => {
    await handleCreateNewCmsContent()
  }

  const handleOnclickSaveButton = async () => {
    // await handleUpdateCmsContent()
    setModalData({
      show: true,
      modalBody: t("Are you sure want to update the content"),
      handleSaveChanges: async () => handleUpdateCmsContent(),
    })
  }



  const handleOnClickDeleteButton = async () => {
    setModalData({
      show: true,
      modalBody: t("Are you sure want to delete the content"),
      modalOkText: t("Delete"),
      handleSaveChanges: async () => handleDeleteCmsContent(),
    })
  }

  const handleCancelClick = () => {
    dispatch(changeEditModeAction(false))
  }

  const handleCreateNewCmsContent = async () => {
    try {
      if (companyInfo && companyInfo.businessIdentifiers && companyInfo.businessIdentifiers[0]) {
        const cmsContentModel = {
          title: cmsFormData.title,
          category: cmsFormData.category,
          language: cmsFormData.language,
          reference: cmsFormData.reference,
          order: parseInt(cmsFormData.order),
          content: cmsFormData.content,
          productIdentifier: cmsFormData.productIdentifier,
          businessIdentifiers: companyInfo.businessIdentifiers,
          // createdDate: moment().unix(),
        }
        await dispatch(createCmsContentAction(cmsContentModel)).unwrap()
        navigate(`/cms`)
      }
    } catch (error) {
      if(error.response.status == 400){
        setShowErrorMessage(t("All fields are required"));
      }else{
        setShowErrorMessage(t("Something went wrong.Please try again after sometime"));
      }
      setTimeout(()=>{
        setShowErrorMessage("");
      },5000)
    }
  }


  const handleUpdateCmsContent = async () => {
    try {
     let updatedCmsData = {...cmsFormData,createdDate : cmsFormData.createdDate._seconds * 1000};
      if (companyInfo && companyInfo.businessIdentifiers && companyInfo.businessIdentifiers[0]) {
        await dispatch(updateCmsContentAction(updatedCmsData)).unwrap()
        handleModalClose()
        navigate(`/cms`)
      }
    } catch (error) {
      handleModalClose()
      if(error.response.status == 400){
        setShowErrorMessage(t("All fields are required"));
      }else{
        setShowErrorMessage(t("Something went wrong.Please try again after sometime"));
      }
      setTimeout(()=>{
        setShowErrorMessage("");
      },5000)
    }
  }

  const handleDeleteCmsContent = async () => {
    try {
      await dispatch(deleteCmsContentAction(cmsFormData.id)).unwrap()
      handleModalClose()
      navigate(`/cms`)
    } catch (error) {

    }
  }

  const handleModalClose = () => {
    setModalData({
      show: false,
      modalBody: "",
      modalOkText: "",
      modalCancelText: "",
      handleSaveChanges: null,
    })
  }


  const handleBackButton = () => {
    navigate("/cms/");
  }


  const handleOnClickCreateRoute = () => {
    navigate("/cms/create");
  };

  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/dashboard">
            <img src={logo} alt="logo" />
          </Link>
          <Link className="navbar-brand brand-logo-mini d-inline-block d-lg-none" to="/"          >
            <img
              src={require("../../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">
          {(pathName.includes("/cms/create") || pathName.includes("/cms/edit")) ?
            <div>
              <span
                style={{
                  
                  fontSize: 16,
                  color: "#131E27",
                  opacity: 1,
                }}
              >
                <MdArrowBack
                  onClick={() => handleBackButton()}
                  style={{
                    width: 24,
                    height: 24,
                    backgroundColor: "transparent",
                    opacity: 1,
                    cursor: "pointer",
                    marginLeft: -10,
                  }}
                />
              </span>
              <span style={{ marginLeft: 20 }}>
                {pathName.includes("/cms/create") ?
                  <Header text={t("Add new content")} />
                  :
                  <Header text={t("Edit content")} />
                }
              </span>
            </div>
            :
            <span>
              <Header text={t("Content management")} />
            </span>
          }

          <span className="navbar-nav navbar-nav-right">
            {pathName.includes("/cms/create") ?
              <div>
                <RoundedgeButton
                  text={t("Confirm")}
                  loading={isLoading}
                  onClick={handleOnclickConfirmButton}
                ></RoundedgeButton>
              </div>
              :
              pathName.includes("/cms/edit") ?
                <>
                  {isEditMode ?
                    <>
                      <IconButton
                        text={t("Save Changes")}
                        icon={MdOutlineSave}
                        onClick={handleOnclickSaveButton}
                      />
                      <IconButton
                        text={t("Delete content")}
                        icon={BiTrash}
                        onClick={handleOnClickDeleteButton}
                      />
                      <IconButton
                        text={t("Cancel")}
                        icon={MdOutlineClose}
                        onClick={handleCancelClick}
                      />
                    </> :
                    <IconButton
                      text={t("Edit")}
                      icon={MdOutlineModeEdit}
                      onClick={handleChaneEditMode}
                    />
                  }
                </>
                :
                <span className="nav-item nav-profile d-none d-sm-flex">
                  <IconButton text={t("Add content")} icon={MdAddCircle} onClick={() => handleOnClickCreateRoute()} />
                </span>
            }
          </span>

          {/* responsive dropdown on mobile screen */}
          <span className="navbar-nav navbar-nav-right productNavResponsiveDropdown">
            {!pathName.includes("/manufacturers/add") &&
              <> {/* <StMenu menuArray={manufacturerNavbarCollection}></StMenu> */}</>
            }
          </span>

          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
            <MdMenu color="#000000" size={24} />
          </button>
        </div>
      </nav>

      {modalData.show &&
        <ModalWithSaveCancel
          show={modalData.show}
          modalBody={modalData.modalBody}
          modalOkText={modalData.modalOkText}
          modalCancelText={modalData.modalCancelText}
          handleSaveChanges={modalData.handleSaveChanges}
          handleModalClose={handleModalClose}
        />
      }

      {showErrorMessage && <div style={{backgroundColor:'#cf2948',position:'absolute',right:10,padding:7,fontFamily:'Poppins'}}>
        <p style={{color:'#ffffff'}}>{showErrorMessage}</p>
      </div>}


    </>
  );
};

export default ContentManagementNavbar;
