import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';



export const loginAction = createAsyncThunk(
    "auth/loginAction",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/iam/user/token`,
                arg
              );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const resetPassword = createAsyncThunk(
    "auth/resetPassword",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().put(
                `/iam/user/resetpassword`,
                arg
              );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const newPassword = createAsyncThunk(
    "auth/newPassword",
    async (arg, thunkApi) => {
        try {
            const response =  await getAxios().put(
                `/iam/user/confirmpassword`,
                arg
              );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const newToken = createAsyncThunk(
    "auth/newToken",
    async (arg, thunkApi) => {
        try {
            const response =  await getAxios().post(
                `/iam/user/refreshtoken`,
                arg
              );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const userRegistrationAction = createAsyncThunk(
    "auth/userRegistrationAction",
    async (arg, thunkApi) => {
        try {
            const response =  await getAxios().post(
                `/iam/user/register`,
                arg
              );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

