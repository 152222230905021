import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { Loader } from "src/components/Loader";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import appConfig from "src/helper/appConfig";
import { localStorageGet, Keys } from "src/helper/localStorageHelper";
import { getCompanyDetails } from 'src/redux/company/companyThunk';
import { getAccountBillingAction } from "src/redux/account/accountThunk";

/**
 * React component for Stripe card payments
 */
const CardPayment = (props) => {
  const { handleClose } = props;
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const _state = useSelector(state => state.company, shallowEqual);
  const { companyInfo } = _state;
  const _authState = localStorageGet(Keys.AUTH_STATE);
  const [stripePromise, setStripePromise] = useState();

  useEffect(() => {
    getStripeConfig();
  }, []);

  /**
   * Get Stripe configuration
   */
  const getStripeConfig = async e => {
    const { publishableKey } = await fetch(
      `${appConfig.SERVER_URL}/integrations/stripe/config`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + _authState.token,
        },
      },
    ).then(r => r.json());

    setStripePromise(loadStripe(publishableKey));
  }

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentTopupAmount, setPaymentTopupAmount] = useState("");
    const [paymentName, setPaymentName] = useState("");
    const [message, setMessage] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [paymentTaxAmount, setPaymentTaxAmount] = useState();
    const [paymentTotalAmount, setPaymentTotalAmount] = useState();
    const [taxText, setTaxText] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const PAYMENT_FAILED_TEXT = "Payment failed.";

    useEffect(() => {
      calculateTaxes();
    }, [paymentTopupAmount]);

    // Styling for card element
    const cardElementOptions = {
      // hidePostalCode: true,
      style: {
        base: {
          backgroundColor: "#f8fcfd",
          color: "#000000",
          fontSize: "12px",
        },
        invalid: {
          color: "#fa755a",
          fontSize: "12px",
        },
      },
    };

    /**
     * Calculate taxes
     */
    const calculateTaxes = async () => {
      if (paymentTopupAmount > 0) {
        let tax = 0;

        if (companyInfo.vat > 0) {
          tax = parseInt(paymentTopupAmount) / 100 * companyInfo.vat;
          tax = Math.round((tax + Number.EPSILON) * 100) / 100;
          setTaxText("VAT " + companyInfo.vat + "%");
        } else {
          if (companyInfo.region === "eu") {
            setTaxText("VAT 0% (reverse charge)");
          } else {
            setTaxText("VAT 0%");
          }
        }

        const total = tax + parseFloat(paymentTopupAmount);

        setPaymentTaxAmount(tax.toFixed(2));
        setPaymentTotalAmount(total.toFixed(2));
      }
    }

    /**
     * Handler for form submit
     * Creates a payment intent and then confirms it
     */
    const handleSubmit = async () => {
      setIsLoading(true)
      setMessage(t("Processing payment."));

      if (!stripe || !elements) {
        setIsLoading(false)
        return;
      }

      let businessId;
      const businessIdObj = companyInfo.businessIdentifiers.find(
        obj => obj.type === "VATID",
      );

      if (businessIdObj) {
        businessId = businessIdObj.id;
      } else {
        businessId = companyInfo.businessIdentifiers[0].id;
      }

      const description = "Balance top-up " + paymentTopupAmount + " €\n" + taxText + " " + paymentTaxAmount + " €\nPayment total " + paymentTotalAmount + " €";

      // Create payment intent
      const { error: backendError, clientSecret } = await fetch(
        `${appConfig.SERVER_URL}/integrations/stripe/create-payment-intent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + _authState.token,
          },
          body: JSON.stringify({
            paymentMethodType: "card",
            currency: "eur",
            businessId: businessId,
            amount: paymentTotalAmount,
            topupAmount: paymentTopupAmount,
            description: description,
          }),
        },
      ).then(r => r.json());

      if (backendError) {
        setIsLoading(false)
        setMessage(PAYMENT_FAILED_TEXT);
        setPaymentStatus("failed")
        return;
      }

      // Confirm payment intent
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: paymentName,
          },
        },
      });

      if (stripeError) {
        setMessage(PAYMENT_FAILED_TEXT);
        setPaymentStatus("failed")
        setIsLoading(false)
        return;
      }

      if (paymentIntent.status === "succeeded") {
        setMessage(t("Payment succeeded."));
        setPaymentStatus("succeeded")
      } else {
        setMessage(PAYMENT_FAILED_TEXT);
        setPaymentStatus("failed")
      }
      setIsLoading(false)
    };

    const handleSucessOk = () => {
      dispatch(getCompanyDetails());
      dispatch(getAccountBillingAction())
      handleClose()
    }

    return (
      <>
        {isLoading &&
          <div className="loading-overlay">
            <Loader message={message} color='primary' />
          </div>
        }

        {
          (paymentStatus !== 'succeeded') ?
            <form id="payment-form" onSubmit={handleSubmit}>
              <div>
                <div>
                  <input
                    id="paymentName"
                    className="payment-input"
                    title={t("Credit card holder name")}
                    type={"text"}
                    placeholder={t("Credit card holder name")}
                    value={paymentName}
                    onChange={e => setPaymentName(e.target.value)}
                  />
                </div>
                <div className="upidsPayment__card">
                  {/* <CardElement id="card" options={cardElementOptions} /> */}

                  <label>
                    {/* Card number */}
                    <CardNumberElement options={cardElementOptions} className="upidsPayment__card__number" />
                  </label>
                  <label>
                    {/* Expiration date */}
                    <CardExpiryElement options={cardElementOptions} className="upidsPayment__card__expiry" />
                  </label>
                  <label>
                    {/* CVC */}
                    <CardCvcElement options={cardElementOptions} className="upidsPayment__card__cvc" />
                  </label>
                </div>
                <div className="">
                  <input
                    id="paymentTopupAmount"
                    className="payment-input"
                    title={"Amount"}
                    type={"number"}
                    placeholder={t("Amount to top-up")}
                    value={paymentTopupAmount}
                    onChange={e => setPaymentTopupAmount(e.target.value)}
                    defaultValue={5}
                    min={5}
                  />
                </div>
                {
                  (paymentStatus === "failed") &&
                  <div className="margin-top-10" style={{ color: 'red' }}>{message}</div>
                }
                {paymentTopupAmount &&
                <div className="margin-top-10">
                  <div className="row">
                    <div className="col-6">Top-up balance</div>
                    <div className="col-6">{paymentTopupAmount} €</div>
                  </div>
                  <div className="row">
                    <div className="col-6">{taxText}</div>
                    <div className="col-6">{paymentTaxAmount} €</div>
                  </div>
                  <div className="row">
                    <div className="col-6">Payment total</div>
                    <div className="col-6">{paymentTotalAmount} €</div>
                  </div>
                </div>
                }
                <div className="margin-top-10">
                  <button
                    type="button"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary form-button cursor-pointer"
                  >
                    {t("Process payment with Stripe")}
                  </button>
                </div>
              </div>
            </form>
            :
            <div className="upidsPayment__sucess">
              <div className="upidsPayment__sucess__text">
                {t("Payment sucessfully done.")}
              </div>
              <div className="upidsPayment__sucess__okbtn">
                <button
                  type="button"
                  onClick={handleSucessOk}
                  className="btn btn-primary form-button cursor-pointer"
                >
                  {t("OK")}
                </button>
              </div>

            </div>
        }

      </>
    );
  };

  return (
    <>
      {!stripePromise &&
        <div className="loading-overlay loading-overlay--stripePromise">
          <Loader color='primary' />
        </div>
      }

      <div className="upidsPayment">
        <div className="upidsPayment__heading">
          {t("Balance top-up payment")}
        </div>
        {stripePromise &&
          <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        }
        <div className="upidsPayment__footer">
          <div className="upidsPayment__footer__text">
            {t("Payment is processed by")}
            <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer" className="upidsPayment__footer__text__strip">
              {t("Stripe Inc")}
            </a>
            {t("Credit card details not stored to UPIDS PDM")}
          </div>
          <div className="upidsPayment__footer__icon">
          </div>
        </div>
      </div>
    </>
  );
};

export default CardPayment;
