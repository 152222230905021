import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { StCloseButton } from "src/components/StCloseButton";
import { InputTextWithLabel } from 'src/components/inputComponents/InputTextWithLabel';
import { StSquareButton } from 'src/components/StSquareButton';
import { Loader } from 'src/components/Loader';
import DatePicker from "react-datepicker";
import { showAddUpdateVoucherModalAction } from 'src/redux/admin/adminSlice';
import { createVouchersAction, getVouchersAction } from 'src/redux/admin/adminThunk';
import moment from 'moment';


function AddUpdateVoucherModal() {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()

    const reduxState = useSelector(state => state)
    const { admin: adminState, } = reduxState
    const { isShowAddUpdateVoucherModal, isLoading } = adminState


    const [voucherData, setVoucherData] = useState({
        value: 0,
        expiryDate: moment().add(90, "days").toJSON(),
    })

    const handleClose = () => {
        dispatch(showAddUpdateVoucherModalAction(false))
    }


    const handleFormChange = (e) => {
        setVoucherData({
            ...voucherData,
            [e.target.name]: e.target.value
        })
    };

    const handleDateChange = (date) => {
        setVoucherData({
            ...voucherData,
            expiryDate: moment(date).toJSON()
        })
    }

    const handleSaveVoucher = async () => {
        try {
            const newvoucherData = {
                value: Number(voucherData.value),
                expiryDate: Number(moment(voucherData.expiryDate).format('x')),
            }
            await dispatch(createVouchersAction(newvoucherData))
            dispatch(getVouchersAction())
            dispatch(showAddUpdateVoucherModalAction(false))
        } catch (error) {

        }
    }



    return (
        <>
            {
                isLoading &&
                <div className="loading-overlay">
                    <Loader color='primary' />
                </div>
            }
            <Modal size="md" centered={true} backdrop="static" show={isShowAddUpdateVoucherModal} onHide={handleClose} >
                <Modal.Header >
                    <div style={{ position: 'absolute', right: 10 }}>
                        <StCloseButton text={t("Close")} onClick={() => handleClose()}></StCloseButton>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ fontWeight: 'normal' }}>

                    <div className="row col-12 m-0 justify-content-center form-group">
                        <InputTextWithLabel isSmallScreen={true}
                            label={t('Voucher amount')}
                            tooltipText={t("Voucher amount")}
                            readOnly={false}
                            onChange={handleFormChange}
                            name={'value'}
                            value={voucherData.value}
                            type="text"
                            placeholder={t("Voucher amount")}
                        // errorMsg={!!errorObject && errorObject.name ? errorObject.name : ""}
                        />

                        <div className='modalDatepicker'>
                            <div className='modalDatepicker__label col'>{t("Expiry date")}</div>
                            <DatePicker
                                style={{ width: "100%", paddingLeft: "0px !important" }}
                                id="endDatePicker"
                                dateFormat="dd/MM/yyyy"
                                selected={new Date(voucherData.expiryDate)}
                                onChange={(date) => handleDateChange(date)}
                            />
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <StSquareButton disabled={!!voucherData?.value ? false:true} text={t("Save")} onClick={() => handleSaveVoucher()}></StSquareButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddUpdateVoucherModal