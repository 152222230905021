import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import Modal from "react-bootstrap/Modal";
import { Loader } from 'src/components/Loader';
import { StCloseButton } from "src/components/StCloseButton";
import { InputTextWithLabel } from 'src/components/inputComponents/InputTextWithLabel';
import { StSquareButton } from 'src/components/StSquareButton';
import { getFieldAiDataAction } from 'src/redux/aiData/aiDataThunk';

function TextInputWithLableWithAiData(props) {
    const { t } = useTranslation('common');
    /**
    const {
        label,
        value,
        onChange,
        type,
        name,
        readOnly,
        placeholder,
        tooltipText,
        errorMsg,
        showTooltip,
    } = props
    */

    const dispatch = useDispatch()
    const { aiTranslations } = useSelector(state => state)
    const { isLoading } = aiTranslations

    const [showAiDataModal, setShowAiDataModal] = useState(false)
    const [modalData, setModalData] = useState({
        inputValue: "",
        aiDataResponse: "",
    })


    const countWords = (str) => {
        const arr = str.split(' ');
        return arr.filter(word => word !== '').length;
    }

    const isShowAiDataIcon = () => {
        if (props && props.aiEnable && !props.readOnly && (props.value && countWords(props.value) >= 2)) {
            return true
        }
        return false
    }


    const handleAiDataModal = async () => {
        setShowAiDataModal(true)
        try {
            const responseData = await dispatch(getFieldAiDataAction({
                text: props.value
            })).unwrap()

            setModalData({
                ...modalData,
                aiDataResponse: responseData.trim()
            })
        } catch (error) {

        }

    }


    const handleShowAiDataModal = (value) => {
        if (value === false) {
            setShowAiDataModal(value)
            setModalData({
                inputValue: "",
                aiDataResponse: "",
            })
        }
    }

    const handleSelectAiData = () => {
        const inputEvent = {
            target: {
                name: props.name,
                value: modalData.aiDataResponse
            }
        }
        props.onChange(inputEvent)
        setShowAiDataModal(false)

        setModalData({
            inputValue: "",
            aiDataResponse: "",
        })
    }


    return (
        <>
            <InputTextWithLabel
                {...props}
                isShowAiDataIcon={isShowAiDataIcon()}
                onClickAiDataIcon={handleAiDataModal}
                aiIconHoverText={t("")}
            />

            <Modal size="md" centered={true} backdrop="static" show={showAiDataModal} onHide={() => handleShowAiDataModal(false)} >
                <Modal.Header >
                    <div style={{ position: 'absolute', right: 10 }}>
                        <StCloseButton text={t("Close")} onClick={() => handleShowAiDataModal(false)}></StCloseButton>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ fontWeight: 'normal' }}>
                    {
                        isLoading &&
                        <Loader color='primary' />
                    }

                    {/* <InputField
                        input
                        value={modalData.inputValue}
                        onChange
                        type="text"
                        name="searchValue"
                        readOnly={false}
                        placeholder="Enter value"
                    // fontFamily
                    // errorMsg
                    /> */}

                    {modalData.aiDataResponse}

                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <StSquareButton text={t("Select")} onClick={() => handleSelectAiData()}></StSquareButton>
                    <StSquareButton text={t("Cancel")} onClick={() => handleShowAiDataModal(false)}></StSquareButton>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default TextInputWithLableWithAiData