import React, { forwardRef } from "react";
import { MdSort } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";
import { Spinner } from "src/components/Spinner"
import { MaterialTable } from "src/components/MaterialTable";

function MetadataTable(props) {
  /** redux store useDispatch & useSelector ------------------------------ **/
  const navigate = useNavigate();
  const { t } = useTranslation("common");

   /** Variable declaration --------------------------------------------------- **/
  const tableIcons = {
    SortArrow: forwardRef((props, ref) => <MdSort {...props} ref={ref} />),
  };
  const allData = [];

 /** Function execute when click on edit action--------------------- */
  const handleEditClickEvent = (o, p) => {
    navigate(`/metadata/profile/${o.id}`,{state:{metadata: o}});
  };

  /** Function to create table data which is used to show on table------------------------ */
  const createTableData = () => {
    if (!!props.taxonomyList && props.taxonomyList?.length > 0) {
      props.taxonomyList.map((o, index) => {
        allData.push({
          attributeName: o.attributeName,
          dataType: o.datatype,
          category: o.category,
          relation: o.relation,
          linking: o.link,
          createdDate: o?.createdDate ? moment(o.createdDate).format('YYYY-MM-DD HH:mm:ss') : "",
          modifiedDate: o?.modifiedDate ? moment(o.modifiedDate).format('YYYY-MM-DD HH:mm:ss') : "",
          action: (
            <MdEdit key={index}
              style={{ cursor: "pointer" }}
              onClick={(e) => handleEditClickEvent(o)}
            />
          ),
        });
      });
    }
  };

  createTableData();

  return (
    <>
      {props.loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <MaterialTable
          columnNames={[
            { title: t("Taxonomy Item"), field: "attributeName" },
            { title: t("Taxonomy Data Type"), field: "dataType" },
            { title: t("Taxonomy Category"), field: "category" },
            { title: t("Taxonomy Relation"), field: "relation" },
            { title: t("Taxonomy Linking"), field: "linking" },
            { title: t("Taxonomy Created Date"), field: "createdDate" },
            { title: t("Taxonomy Modified Date"), field: "modifiedDate" },
            { title: t("Action"), field: "action", sorting: false, headerStyle: { textAlign: 'center' }, cellStyle: { textAlign: 'center' } },
          ]}
          tableData={allData}
        ></MaterialTable>
      )}
      <br></br>
    </>
  );
}

export default MetadataTable;
