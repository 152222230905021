import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAccountBillingAction } from 'src/redux/account/accountThunk';
import _ from 'lodash';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { InputTextWithLabel } from 'src/components/inputComponents/InputTextWithLabel';
import { IconButton } from 'src/components/IconButton';
import { MdAddCircleOutline } from 'react-icons/md';
import { StCloseButton } from 'src/components/StCloseButton';
import DatePicker from "react-datepicker";
import { StSquareButton } from 'src/components/StSquareButton';
import { StCancelSquareButton } from 'src/components/StCancelSquareButton';
import { getWarningsForProducts, addWarningsForProduct, deleteWarningForProduct, editWarningForProduct } from 'src/redux/products/productThunk';
import objectPath from 'object-path';
import { usePrevious } from 'src/helper/CustomHook';
import { Spinner } from 'src/components/Spinner';
import { Tab, Divider } from 'semantic-ui-react';
import MultilanguageTab from 'src/pages/products/addProduct/tabs/MultilanguageTab';


function Warnings() {
    const { t, i18n } = useTranslation('common');
    const dispatch = useDispatch();

    const [warningModalShow, setWarningModalShow] = useState(false);
    const [warningEditEnable, setWarningEditEnable] = useState(false);
    const [warningsData, setWarningsData] = useState({ warningText: { [i18n.language]: '' }, productIdentifier: '' });
    const [timeRangeStart, setTimeRangeStart] = useState(new Date());
    const [timeRangeEnd, setTimeRangeEnd] = useState(new Date());
    const [warningsCollection, setWarningsCollection] = useState([]);
    const reduxState = useSelector(state => state);
    const { company: companyState, account: accountState } = reduxState;
    const { companyInfo } = companyState;

    const productState = useSelector((state) => (state.products));
    const { warningsDataForProducts } = productState;
    const prevPropsState = usePrevious({ warningsDataForProducts });

    const businessId = objectPath.get(_.find(companyInfo.businessIdentifiers, function (t) { return t.type === "VATID" }), 'id', companyInfo.businessIdentifiers[0].id);

    useEffect(() => {
        dispatch(getWarningsForProducts({ businessId: businessId }))
    }, [])

    useEffect(() => {
        if (prevPropsState) {
            if (prevPropsState.warningsDataForProducts !== warningsDataForProducts && warningsDataForProducts) {
                console.log("warningsDataForProducts......", warningsDataForProducts);
                //   let warnings = [];
                //   console.log("warnings.before......",warnings)
                //   warningsDataForProducts.forEach((data,i) => {
                //     warnings.push({...data,
                //         // timeRangeStart : moment.unix(data.timeRangeStart).format("YYYY-MM-DD hh:mm:ssA"),
                //         // timeRangeEnd : moment.unix(data.timeRangeEnd).format("YYYY-MM-DD hh:mm:ssA")
                //         timeRangeStart : moment(data.timeRangeStart).toDate(),
                //         timeRangeEnd : moment(data.timeRangeEnd).toDate()
                //          })
                //     });
                //     console.log("warnings.after......",warnings)
                setWarningsCollection(warningsDataForProducts);
                // setWarningsCollection(warnings);

            }
        }
    }, [warningsDataForProducts])

    const handleWarningsFormChange = e => {
        // if(e.target.name=="warningText"){
        //     setWarningsData({ ...warningsData, warningText: {...warningsData.warningText, [i18n.language]: e.target.value }  })
        // }else{
        setWarningsData({ ...warningsData, [e.target.name]: e.target.value })
        // }
    };

    const handleWarningsTextFormChange = (e, code) => {
        setWarningsData({ ...warningsData, warningText: { ...warningsData.warningText, [code]: e.target.value } })
    }

    const handleWarningsModalClose = () => {
        setWarningModalShow(false);
        setWarningEditEnable(false)
        setWarningsData({ warningText: { [i18n.language]: '' }, productIdentifier: '' });
        setTimeRangeStart(new Date());
        setTimeRangeEnd(new Date());
    };

    // Handle date change
    const handleDateChange = (date, type) => {
        var startEpoch;
        var endEpoch;
        if (type === "start") {
            if (date > timeRangeEnd) {
                alert(t("Start date can't be greater than end date"));
                return;
            } else {
                setTimeRangeStart(date)
                // setWarningsData({...warningsData, timeRangeStart : date});
            }
        } else {
            if (date < timeRangeStart) {
                alert(t("End date can't be lesser than start date"));
                return;
            } else {
                setTimeRangeEnd(date)
                // setWarningsData({...warningsData, timeRangeEnd : date});
            }
        }
    };

    const handleAddWarningsData = () => {

        let warningModel = {
            productIdentifier: warningsData.productIdentifier,
            businessIdentifiers: companyInfo.businessIdentifiers,
            timeRangeStart: moment(timeRangeStart).unix(),
            timeRangeEnd: moment(timeRangeEnd).unix(),
            // warningText: {
            //     [i18n.language]: warningsData.warningText[i18n.language]
            // },
            warningText: warningsData.warningText,
            source: 'PDM'
        }

        dispatch(addWarningsForProduct({ businessId: businessId, warningData: warningModel }));
        setWarningsData({ warningText: { [i18n.language]: "" }, productIdentifier: '' });
        setTimeRangeStart(new Date());
        setTimeRangeEnd(new Date());
        handleWarningsModalClose();
    }

    const deleteWarning = (index) => {
        dispatch(deleteWarningForProduct({ businessId: businessId, id: warningsCollection[index].id }));
        // warnings.splice(index, 1);
        // setWarningsCollection(warnings);
    }

    const editWarningModelShow = (index) => {
        let warnings = [...warningsCollection];
        setWarningsData(warnings[index]);
        setTimeRangeEnd(new Date(warnings[index].timeRangeEnd * 1000));
        setTimeRangeStart(new Date(warnings[index].timeRangeStart * 1000));
        setWarningModalShow(true);
        setWarningEditEnable(true);
    }

    const handleEditWarningDate = () => {
        let warningModel = {
            id: warningsData.id,
            productIdentifier: warningsData.productIdentifier,
            businessIdentifiers: companyInfo.businessIdentifiers,
            timeRangeStart: moment(timeRangeStart).unix(),
            timeRangeEnd: moment(timeRangeEnd).unix(),
            // warningText: {
            //     [i18n.language]: warningsData.warningText[i18n.language]
            // },
            warningText: warningsData.warningText,
            source: 'PDM'
        }
        dispatch(editWarningForProduct({ businessId: businessId, warningData: warningModel }));
        setWarningsData({ warningText: { [i18n.language]: "" }, productIdentifier: '' });
        setTimeRangeStart(new Date());
        setTimeRangeEnd(new Date());
        handleWarningsModalClose();
    }

    // const multilanguagePanes = [];
    // let createPans = () => {
    //     objectPath.get(companyState, 'companyInfo.supportedLanguages', []).map((o, index) => {
    //       multilanguagePanes.push(
    //         {
    //           menuItem: o.name,
    //           render: () => <Tab.Pane key={index} className="product-profile tab-pane-no-shadow border-0 shadow-none" attached={false}>
    //             <MultilanguageTab props={{ ...props, language: o.code, group: 'Ingredients' }} />
    //           </Tab.Pane>
    //         }
    //       )
    //     })
    //   }
    //   createPans();

    return (
        <>
            <div className="row ms-3">
                <div className='col-lg-8 col-md-8 col-sm-8 col-8 mb-3'>
                    <span className='tab-section-info'>{t(`_Warnings`)}</span>
                </div>
                {companyState.isCompanyEditMode && <div style={{ cursor: 'pointer' }} className='col-lg-4 col-md-4 col-sm-4 col-4'>
                    <IconButton text={t("Add New")} icon={MdAddCircleOutline} onClick={() => setWarningModalShow(true)} />
                </div>}
            </div>
            {productState.actionsLoading ?
                <div>
                    <Spinner />
                </div> :
                <div className='ms-4'>
                    {warningsCollection.length > 0 ? warningsCollection.map((data, i) => (
                        <div key={i} className='col-11 d-flex' style={{ border: "1px solid #c8d6e0", borderRadius: ".4375rem", padding: 10, marginTop: 5 }}>
                            <div className='col-10'>
                                <div className='warningsTitle'>{data.warningText[i18n.language]}</div>
                                <div>
                                    <span style={{ color: "#96a5af", fontSize: 12 }}>{t("Article identifer")}: {data.productIdentifier}</span>
                                    <span style={{ color: "#96a5af", fontSize: 12, marginLeft: 15 }}>{t("From")}: {moment.unix(data.timeRangeStart).format("YYYY-MM-DD hh:mm:ssA")}</span>
                                    <span style={{ color: "#96a5af", fontSize: 12, marginLeft: 15 }}>{t("To")}: {moment.unix(data.timeRangeEnd).format("YYYY-MM-DD hh:mm:ssA")}</span>
                                    {/* <span style={{color: "#96a5af",fontSize:12,marginLeft:15}}>{t("From")}: {data.timeRangeStart}</span>
                                                <span style={{color: "#96a5af",fontSize:12,marginLeft:15}}>{t("To")}: {data.timeRangeEnd}</span> */}
                                </div>
                            </div>
                            {companyState.isCompanyEditMode && <div className='col-2' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <span style={{ color: "#6489a0", fontSize: 12, marginLeft: 15, cursor: 'pointer' }} onClick={() => editWarningModelShow(i)}>{t("Edit")}</span>
                                <span style={{ color: "#6489a0", fontSize: 12, marginLeft: 15, cursor: 'pointer' }} onClick={() => deleteWarning(i)}>{t("Delete")}</span>
                            </div>}
                        </div>
                    )) :
                        <div className='text-center'>{t("No Warnings Found")}</div>
                    }
                </div>}


            <Modal scrollable size="md" centered={true} backdrop="static" show={warningModalShow} onHide={handleWarningsModalClose} >
                <Modal.Header >
                    <div style={{ position: 'absolute', right: 10 }}>
                        <StCloseButton text={t("Close")} onClick={() => handleWarningsModalClose()}></StCloseButton>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ fontWeight: 'normal' }}>
                    <div className="row m-0 justify-content-center">
                        <span className="col-12 media-card model-text" >{t("Enter warnings data")}</span>
                        <div className="form-group col-12 me-2" style={{ marginBottom: -5 }}  >
                            <InputTextWithLabel isSmallScreen={true}
                                label={t("Product identifier")}
                                name={"productIdentifier"}
                                type="text"
                                placeholder={t("Product identifier")}
                                value={warningsData?.productIdentifier}
                                onChange={handleWarningsFormChange}
                                readOnly={warningEditEnable ? true : false}
                            // errorMsg={buyurlErrorMsg ? t("Enter a valid url") : ''}
                            ></InputTextWithLabel>
                        </div>
                        <div className="form-group col-12 ms-4" style={{ marginBottom: 10 }} >
                            <label className='ms-4'>{("Start date")}</label>
                            <DatePicker
                                className='mydatePicker'
                                dateFormat="yyyy-MM-dd HH:mm"
                                selected={timeRangeStart}
                                showTimeSelect
                                onChange={(date) => handleDateChange(date, "start")}
                            />
                        </div>
                        <div className="form-group col-12 ms-4">
                            <label className='ms-4'>{("Start date")}</label>
                            <DatePicker
                                className='mydatePicker'
                                dateFormat="yyyy-MM-dd HH:mm"
                                selected={timeRangeEnd}
                                showTimeSelect
                                onChange={(date) => handleDateChange(date, "end")}
                            />
                        </div>

                        {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span className="ml-3 product-profile lang-text-span">{t("Language")}</span>
                            <MultiLangTabWrapper multilanguagePanes={multilanguagePanes} />
                        </div> */}

                        <div className="form-group col-12 me-2"  >
                            {companyInfo.supportedLanguages.map((data, i) => (
                                <InputTextWithLabel isSmallScreen={true} key={i}
                                    label={`${t("Warning text")} ${data.code}`}
                                    name={data.code}
                                    type="text"
                                    placeholder={`${t("Warning text")} ${data.code}`}
                                    value={warningsData?.warningText[data.code]}
                                    onChange={(e) => handleWarningsTextFormChange(e, data.code)}
                                // errorMsg={buyurlErrorMsg ? t("Enter a valid url") : ''}
                                ></InputTextWithLabel>
                            ))}

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    {warningEditEnable ?
                        <StSquareButton text={t("Save")} disabled={(warningsData.productIdentifier && warningsData.warningText) ? false : true} onClick={() => handleEditWarningDate()} ></StSquareButton> :
                        <StSquareButton text={t("Add")} disabled={(warningsData.productIdentifier && warningsData.warningText) ? false : true} onClick={() => handleAddWarningsData()} ></StSquareButton>
                    }
                    <StCancelSquareButton text={t("Cancel")} onClick={() => handleWarningsModalClose()}></StCancelSquareButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Warnings