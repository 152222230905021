import React, { Component } from 'react'
import loadingIcon from '../../assets/images/loadingIcon.svg'

export class SpinnerLoader extends Component {
  render() {
    const { UpidsProductCard } = this.props;
    return (
      <div className={UpidsProductCard === true ? 'margin-loader loader' : 'loader'} >
        <div
          className="spinner-border text-secondary box"
          style={{ width: "7.5rem", height: " 7.5rem" }}
          role="status"
        >
        </div>
        <img alt="alt"
          style={{ height: "3rem", width: "3rem", marginTop: -70 }}
          className="image"
          src={loadingIcon}
        />
      </div>
    );
  }
}

export default SpinnerLoader;