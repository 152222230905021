import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProductProfile from './products/productProfile/ProductProfile'
import Dashboard from "./dashboard/Dashboard";
import Products from "./products/allProducts/Products";
import Manufacturers from "./manufacturers/Manufacturers";
import ContentManagement from "./contentManagement/ContentManagement";
import Users from "./users/Users";
import Metadata from "./metadata/Metadata";
import ViewUsers from "./users/viewUser/ViewUser";
import ViewMetadata from "./metadata/viewMetadata/ViewMetadata";
import AddUser from "./users/addUser/AddUser";
import AddMetadata from "./metadata/addmetadata/AddMetadata";
import CompanyInfo from "./company/CompanyInfo";
import Vouchers from "./vouchers/Vouchers";
import Templates from "./templates/Templates";
import ViewCompany from "./manufacturers/viewCompany/ViewCompany";
import ViewTemplates from "./templates/viewTemplate/ViewTemplate";
import AddCompany from "./manufacturers/addCompany/AddCompany";
import AddProduct from "./products/addProduct/AddProduct";
import AddUpidsProduct from "./products/addUpidsProduct/AddUpidsProduct";
import ViewUpidsProduct from "./products/viewUpidsProduct/ViewUpidsProduct";
import Feedbacks from "./feedback/feedbacks";
import ViewFeedback from "./feedback/viewFeedback/ViewFeedback";
import EditOwnProfile from './dashboard/EditOwnProfile'
import ProductContextProvider from './products/ProductsContext'
import Analytics from "./analytics/Analytics";
import Swagger from "./swaggerApi/Swagger";
import Favourites from "./products/allProducts/Favourites";
import Documentation from "./documentation/Documentation";
import Billing from "./analytics/Billing";
import ManufacturerProducts from "./manufacturers/manufacturersTable/ManufacturerProducts";
import Certifications from "./certifications/Certifications";
import AddCertificate from "./certifications/addCertificate/AddCertificate";
import ViewCertificate from "./certifications/viewCertificate/ViewCertificate";

function PostAuthRoutes() {
  return (
    <React.Fragment>
      <Suspense>
        <ProductContextProvider>
          <Routes>
            <Route path="/products" element={<Products />} />
            <Route path="/productProfile/:gtin/:accordianSelectedName?" element={<ProductProfile />} />
            <Route path="/addproduct" element={<AddProduct />} />
            <Route path="/favourites" element={<Favourites />} />
            <Route path="/manufacturer/:businessid" element={<ManufacturerProducts />} />
          </Routes>
        </ProductContextProvider>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/myprofile/:uid" element={<EditOwnProfile />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/profile/:uid" element={<ViewUsers />} />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/metadata" element={<Metadata />} />
          <Route path="/metadata/add" element={<AddMetadata />} />
          <Route path="/metadata/profile/:id" element={<ViewMetadata />} />
          <Route path = "/certifications" element={<Certifications/>} />
          <Route path="/certifications/add" element={<AddCertificate />} />
          <Route path="/certifications/profile/:id" element={<ViewCertificate />} />
          <Route path="/manufacturers" element={<Manufacturers />} />
          <Route path="/cms/*" element={<ContentManagement />} />
          <Route path="/company/" element={<CompanyInfo />} />
          <Route path="/vouchers/" element={<Vouchers />} />
          <Route path="/templates/" element={<Templates />} />
          <Route path="/manufacturers/profile/:businessid" element={<ViewCompany />} />
          <Route path="/templates/:name" element={<ViewTemplates />} />
          <Route path="/manufacturers/add" element={<AddCompany />} />
          <Route path="/addupidsproduct/:gtin" element={<AddUpidsProduct />} />
          <Route path="/productProfile/:gtin/upidsProduct/:upids" element={<ViewUpidsProduct />} />
          <Route path="/feedbacks" element={<Feedbacks />} />
          <Route path="/feedbacks/:id" element={<ViewFeedback />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/swagger/private" element={<Swagger />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/" element={<Navigate replace to={"dashboard"} />} />
        </Routes>
      </Suspense>
    </React.Fragment>
  );

}

export default PostAuthRoutes;
