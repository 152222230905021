import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getContentfulData } from "src/redux/dashboard/dashboardThunk";
import _ from "lodash";

import { Spinner } from "src/components/Spinner";
import { useTranslation } from "react-i18next";

import DocumentaionHtml from "./component/DocumentaionHtml";

const Documentation = () => {
  const { i18n } = useTranslation('common');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContentfulData({ contentType: 'pdmDocumentation', language: i18n.language }));
  }, []);

  const dashboardState = useSelector(
    (state) => (state.dashboard),
    shallowEqual
  );


  return (
    <div className="homepage-main-div">
      {dashboardState.actionsLoading ?
        <div>
          <Spinner />
        </div> :
        <div>
          <div className="mt-2">
            {dashboardState.contentfulData &&
              <DocumentaionHtml contentfulData={dashboardState.contentfulData} />
            }
          </div>
        </div>
      }
    </div>
  )
}

export default Documentation;