import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';



export const saveFavourites = createAsyncThunk(
    "favourites/saveFavourites",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/iam/user/favourites`,
                arg.body
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getFavourites = createAsyncThunk(
    "favourites/getFavourites",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/iam/user/favourites`,
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);