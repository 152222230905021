import React from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppContext } from "../AppContext";
import { usePrevious } from '../../helper/CustomHook';
import { Spinner } from "src/components/Spinner";
import { StPieChart } from "src/components/StPieChart";

const PieGraph = (props) => {

  const _state = useSelector((state) => state.analytics, shallowEqual);

//   const response = _state.analyticsClickstreamTotalCount;
const isLoading = _state.actionsLoading;

  return (
    isLoading ? (
      <div>
        <Spinner />
      </div>
    ) : 
    <div style={{height:500}}>
          <div className='text-center fw-bold' style={{marginTop:70,color:'#6c757d'}}>{props.title}</div>
    
    <StPieChart
      pieData={props.pieData && props.pieData}
    ></StPieChart>

    </div>
  )
}


export default PieGraph;