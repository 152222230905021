const countryCodes =
    [
        {
            name: "Afghanistan",
            countryCode: "93",
            code: "AF"
        },
        // {
        //     name: "Aland Islands",
        //     countryCode: "358",
        //     code: "AX"
        // },
        {
            name: "Albania",
            countryCode: "355",
            code: "AL"
        },
        {
            name: "Algeria",
            countryCode: "213",
            code: "DZ"
        },
        {
            name: "AmericanSamoa",
            countryCode: "1684",
            code: "AS"
        },
        {
            name: "Andorra",
            countryCode: "376",
            code: "AD"
        },
        {
            name: "Angola",
            countryCode: "244",
            code: "AO"
        },
        {
            name: "Anguilla",
            countryCode: "1264",
            code: "AI"
        },
        {
            name: "Antarctica",
            countryCode: "672",
            code: "AQ"
        },
        {
            name: "Antigua and Barbuda",
            countryCode: "1268",
            code: "AG"
        },
        {
            name: "Argentina",
            countryCode: "54",
            code: "AR"
        },
        {
            name: "Armenia",
            countryCode: "374",
            code: "AM"
        },
        {
            name: "Aruba",
            countryCode: "297",
            code: "AW"
        },
        {
            name: "Australia",
            countryCode: "61",
            code: "AU"
        },
        {
            name: "Austria",
            countryCode: "43",
            code: "AT"
        },
        {
            name: "Azerbaijan",
            countryCode: "994",
            code: "AZ"
        },
        {
            name: "Bahamas",
            countryCode: "1242",
            code: "BS"
        },
        {
            name: "Bahrain",
            countryCode: "973",
            code: "BH"
        },
        {
            name: "Bangladesh",
            countryCode: "880",
            code: "BD"
        },
        {
            name: "Barbados",
            countryCode: "1246",
            code: "BB"
        },
        {
            name: "Belarus",
            countryCode: "375",
            code: "BY"
        },
        {
            name: "Belgium",
            countryCode: "32",
            code: "BE"
        },
        {
            name: "Belize",
            countryCode: "501",
            code: "BZ"
        },
        {
            name: "Benin",
            countryCode: "229",
            code: "BJ"
        },
        {
            name: "Bermuda",
            countryCode: "1441",
            code: "BM"
        },
        {
            name: "Bhutan",
            countryCode: "975",
            code: "BT"
        },
        {
            name: "Bolivia, Plurinational State of",
            countryCode: "591",
            code: "BO"
        },
        {
            name: "Bosnia and Herzegovina",
            countryCode: "387",
            code: "BA"
        },
        {
            name: "Botswana",
            countryCode: "267",
            code: "BW"
        },
        {
            name: "Brazil",
            countryCode: "55",
            code: "BR"
        },
        {
            name: "British Indian Ocean Territory",
            countryCode: "246",
            code: "IO"
        },
        {
            name: "Brunei Darussalam",
            countryCode: "673",
            code: "BN"
        },
        {
            name: "Bulgaria",
            countryCode: "359",
            code: "BG"
        },
        {
            name: "Burkina Faso",
            countryCode: "226",
            code: "BF"
        },
        {
            name: "Burundi",
            countryCode: "257",
            code: "BI"
        },
        {
            name: "Cambodia",
            countryCode: "855",
            code: "KH"
        },
        {
            name: "Cameroon",
            countryCode: "237",
            code: "CM"
        },
        // {
        //     name: "Canada",
        //     countryCode: "1",
        //     code: "CA"
        // },
        {
            name: "Cape Verde",
            countryCode: "238",
            code: "CV"
        },
        {
            name: "Cayman Islands",
            countryCode: " 345",
            code: "KY"
        },
        {
            name: "Central African Republic",
            countryCode: "236",
            code: "CF"
        },
        {
            name: "Chad",
            countryCode: "235",
            code: "TD"
        },
        {
            name: "Chile",
            countryCode: "56",
            code: "CL"
        },
        {
            name: "China",
            countryCode: "86",
            code: "CN"
        },
        {
            name: "Christmas Island",
            countryCode: "61",
            code: "CX"
        },
        {
            name: "Cocos (Keeling) Islands",
            countryCode: "61",
            code: "CC"
        },
        {
            name: "Colombia",
            countryCode: "57",
            code: "CO"
        },
        {
            name: "Comoros",
            countryCode: "269",
            code: "KM"
        },
        {
            name: "Congo",
            countryCode: "242",
            code: "CG"
        },
        {
            name: "Congo, The Democratic Republic of the Congo",
            countryCode: "243",
            code: "CD"
        },
        {
            name: "Cook Islands",
            countryCode: "682",
            code: "CK"
        },
        {
            name: "Costa Rica",
            countryCode: "506",
            code: "CR"
        },
        {
            name: "Cote d'Ivoire",
            countryCode: "225",
            code: "CI"
        },
        {
            name: "Croatia",
            countryCode: "385",
            code: "HR"
        },
        {
            name: "Cuba",
            countryCode: "53",
            code: "CU"
        },
        {
            name: "Cyprus",
            countryCode: "357",
            code: "CY"
        },
        {
            name: "Czech Republic",
            countryCode: "420",
            code: "CZ"
        },
        {
            name: "Denmark",
            countryCode: "45",
            code: "DK"
        },
        {
            name: "Djibouti",
            countryCode: "253",
            code: "DJ"
        },
        {
            name: "Dominica",
            countryCode: "1767",
            code: "DM"
        },
        {
            name: "Dominican Republic",
            countryCode: "1849",
            code: "DO"
        },
        {
            name: "Ecuador",
            countryCode: "593",
            code: "EC"
        },
        {
            name: "Egypt",
            countryCode: "20",
            code: "EG"
        },
        {
            name: "El Salvador",
            countryCode: "503",
            code: "SV"
        },
        {
            name: "Equatorial Guinea",
            countryCode: "240",
            code: "GQ"
        },
        {
            name: "Eritrea",
            countryCode: "291",
            code: "ER"
        },
        {
            name: "Estonia",
            countryCode: "372",
            code: "EE"
        },
        {
            name: "Ethiopia",
            countryCode: "251",
            code: "ET"
        },
        // {
        //     name: "Falkland Islands (Malvinas)",
        //     countryCode: "500",
        //     code: "FK"
        // },
        {
            name: "Faroe Islands",
            countryCode: "298",
            code: "FO"
        },
        {
            name: "Fiji",
            countryCode: "679",
            code: "FJ"
        },
        {
            name: "Finland",
            countryCode: "358",
            code: "FI"
        },
        {
            name: "France",
            countryCode: "33",
            code: "FR"
        },
        {
            name: "French Guiana",
            countryCode: "594",
            code: "GF"
        },
        {
            name: "French Polynesia",
            countryCode: "689",
            code: "PF"
        },
        {
            name: "Gabon",
            countryCode: "241",
            code: "GA"
        },
        {
            name: "Gambia",
            countryCode: "220",
            code: "GM"
        },
        {
            name: "Georgia",
            countryCode: "995",
            code: "GE"
        },
        {
            name: "Germany",
            countryCode: "49",
            code: "DE"
        },
        {
            name: "Ghana",
            countryCode: "233",
            code: "GH"
        },
        {
            name: "Gibraltar",
            countryCode: "350",
            code: "GI"
        },
        {
            name: "Greece",
            countryCode: "30",
            code: "GR"
        },
        {
            name: "Greenland",
            countryCode: "299",
            code: "GL"
        },
        {
            name: "Grenada",
            countryCode: "1473",
            code: "GD"
        },
        {
            name: "Guadeloupe",
            countryCode: "590",
            code: "GP"
        },
        {
            name: "Guam",
            countryCode: "1671",
            code: "GU"
        },
        {
            name: "Guatemala",
            countryCode: "502",
            code: "GT"
        },
        {
            name: "Guernsey",
            countryCode: "44",
            code: "GG"
        },
        {
            name: "Guinea",
            countryCode: "224",
            code: "GN"
        },
        {
            name: "Guinea-Bissau",
            countryCode: "245",
            code: "GW"
        },
        {
            name: "Guyana",
            countryCode: "595",
            code: "GY"
        },
        {
            name: "Haiti",
            countryCode: "509",
            code: "HT"
        },
        {
            name: "Holy See (Vatican City State)",
            countryCode: "379",
            code: "VA"
        },
        {
            name: "Honduras",
            countryCode: "504",
            code: "HN"
        },
        {
            name: "Hong Kong",
            countryCode: "852",
            code: "HK"
        },
        {
            name: "Hungary",
            countryCode: "36",
            code: "HU"
        },
        {
            name: "Iceland",
            countryCode: "354",
            code: "IS"
        },
        {
            name: "India",
            countryCode: "91",
            code: "IN"
        },
        {
            name: "Indonesia",
            countryCode: "62",
            code: "ID"
        },
        {
            name: "Iran, Islamic Republic of Persian Gulf",
            countryCode: "98",
            code: "IR"
        },
        {
            name: "Iraq",
            countryCode: "964",
            code: "IQ"
        },
        {
            name: "Ireland",
            countryCode: "353",
            code: "IE"
        },
        {
            name: "Isle of Man",
            countryCode: "44",
            code: "IM"
        },
        {
            name: "Israel",
            countryCode: "972",
            code: "IL"
        },
        {
            name: "Italy",
            countryCode: "39",
            code: "IT"
        },
        {
            name: "Jamaica",
            countryCode: "1876",
            code: "JM"
        },
        {
            name: "Japan",
            countryCode: "81",
            code: "JP"
        },
        {
            name: "Jersey",
            countryCode: "44",
            code: "JE"
        },
        {
            name: "Jordan",
            countryCode: "962",
            code: "JO"
        },
        {
            name: "Kazakhstan",
            countryCode: "77",
            code: "KZ"
        },
        {
            name: "Kenya",
            countryCode: "254",
            code: "KE"
        },
        {
            name: "Kiribati",
            countryCode: "686",
            code: "KI"
        },
        {
            name: "Korea, Democratic People's Republic of Korea",
            countryCode: "850",
            code: "KP"
        },
        {
            name: "Korea, Republic of South Korea",
            countryCode: "82",
            code: "KR"
        },
        {
            name: "Kuwait",
            countryCode: "965",
            code: "KW"
        },
        {
            name: "Kyrgyzstan",
            countryCode: "996",
            code: "KG"
        },
        {
            name: "Laos",
            countryCode: "856",
            code: "LA"
        },
        {
            name: "Latvia",
            countryCode: "371",
            code: "LV"
        },
        {
            name: "Lebanon",
            countryCode: "961",
            code: "LB"
        },
        {
            name: "Lesotho",
            countryCode: "266",
            code: "LS"
        },
        {
            name: "Liberia",
            countryCode: "231",
            code: "LR"
        },
        {
            name: "Libyan Arab Jamahiriya",
            countryCode: "218",
            code: "LY"
        },
        {
            name: "Liechtenstein",
            countryCode: "423",
            code: "LI"
        },
        {
            name: "Lithuania",
            countryCode: "370",
            code: "LT"
        },
        {
            name: "Luxembourg",
            countryCode: "352",
            code: "LU"
        },
        {
            name: "Macao",
            countryCode: "853",
            code: "MO"
        },
        {
            name: "Macedonia",
            countryCode: "389",
            code: "MK"
        },
        {
            name: "Madagascar",
            countryCode: "261",
            code: "MG"
        },
        {
            name: "Malawi",
            countryCode: "265",
            code: "MW"
        },
        {
            name: "Malaysia",
            countryCode: "60",
            code: "MY"
        },
        {
            name: "Maldives",
            countryCode: "960",
            code: "MV"
        },
        {
            name: "Mali",
            countryCode: "223",
            code: "ML"
        },
        {
            name: "Malta",
            countryCode: "356",
            code: "MT"
        },
        {
            name: "Marshall Islands",
            countryCode: "692",
            code: "MH"
        },
        {
            name: "Martinique",
            countryCode: "596",
            code: "MQ"
        },
        {
            name: "Mauritania",
            countryCode: "222",
            code: "MR"
        },
        {
            name: "Mauritius",
            countryCode: "230",
            code: "MU"
        },
        {
            name: "Mayotte",
            countryCode: "262",
            code: "YT"
        },
        {
            name: "Mexico",
            countryCode: "52",
            code: "MX"
        },
        {
            name: "Micronesia, Federated States of Micronesia",
            countryCode: "691",
            code: "FM"
        },
        {
            name: "Moldova",
            countryCode: "373",
            code: "MD"
        },
        {
            name: "Monaco",
            countryCode: "377",
            code: "MC"
        },
        {
            name: "Mongolia",
            countryCode: "976",
            code: "MN"
        },
        {
            name: "Montenegro",
            countryCode: "382",
            code: "ME"
        },
        {
            name: "Montserrat",
            countryCode: "1664",
            code: "MS"
        },
        {
            name: "Morocco",
            countryCode: "212",
            code: "MA"
        },
        {
            name: "Mozambique",
            countryCode: "258",
            code: "MZ"
        },
        {
            name: "Myanmar",
            countryCode: "95",
            code: "MM"
        },
        {
            name: "Namibia",
            countryCode: "264",
            code: "NA"
        },
        {
            name: "Nauru",
            countryCode: "674",
            code: "NR"
        },
        {
            name: "Nepal",
            countryCode: "977",
            code: "NP"
        },
        {
            name: "Netherlands",
            countryCode: "31",
            code: "NL"
        },
        {
            name: "Netherlands Antilles",
            countryCode: "599",
            code: "AN"
        },
        {
            name: "New Caledonia",
            countryCode: "687",
            code: "NC"
        },
        {
            name: "New Zealand",
            countryCode: "64",
            code: "NZ"
        },
        {
            name: "Nicaragua",
            countryCode: "505",
            code: "NI"
        },
        {
            name: "Niger",
            countryCode: "227",
            code: "NE"
        },
        {
            name: "Nigeria",
            countryCode: "234",
            code: "NG"
        },
        {
            name: "Niue",
            countryCode: "683",
            code: "NU"
        },
        {
            name: "Norfolk Island",
            countryCode: "672",
            code: "NF"
        },
        {
            name: "Northern Mariana Islands",
            countryCode: "1670",
            code: "MP"
        },
        {
            name: "Norway",
            countryCode: "47",
            code: "NO"
        },
        {
            name: "Oman",
            countryCode: "968",
            code: "OM"
        },
        {
            name: "Pakistan",
            countryCode: "92",
            code: "PK"
        },
        {
            name: "Palau",
            countryCode: "680",
            code: "PW"
        },
        {
            name: "Palestinian Territory, Occupied",
            countryCode: "970",
            code: "PS"
        },
        {
            name: "Panama",
            countryCode: "507",
            code: "PA"
        },
        {
            name: "Papua New Guinea",
            countryCode: "675",
            code: "PG"
        },
        {
            name: "Paraguay",
            countryCode: "595",
            code: "PY"
        },
        {
            name: "Peru",
            countryCode: "51",
            code: "PE"
        },
        {
            name: "Philippines",
            countryCode: "63",
            code: "PH"
        },
        {
            name: "Pitcairn",
            countryCode: "872",
            code: "PN"
        },
        {
            name: "Poland",
            countryCode: "48",
            code: "PL"
        },
        {
            name: "Portugal",
            countryCode: "351",
            code: "PT"
        },
        {
            name: "Puerto Rico",
            countryCode: "1939",
            code: "PR"
        },
        {
            name: "Qatar",
            countryCode: "974",
            code: "QA"
        },
        {
            name: "Romania",
            countryCode: "40",
            code: "RO"
        },
        {
            name: "Russia",
            countryCode: "7",
            code: "RU"
        },
        {
            name: "Rwanda",
            countryCode: "250",
            code: "RW"
        },
        {
            name: "Reunion",
            countryCode: "262",
            code: "RE"
        },
        // {
        //     name: "Saint Barthelemy",
        //     countryCode: "590",
        //     code: "BL"
        // },
        {
            name: "Saint Helena, Ascension and Tristan Da Cunha",
            countryCode: "290",
            code: "SH"
        },
        {
            name: "Saint Kitts and Nevis",
            countryCode: "1869",
            code: "KN"
        },
        {
            name: "Saint Lucia",
            countryCode: "1758",
            code: "LC"
        },
        // {
        //     name: "Saint Martin",
        //     countryCode: "590",
        //     code: "MF"
        // },
        {
            name: "Saint Pierre and Miquelon",
            countryCode: "508",
            code: "PM"
        },
        {
            name: "Saint Vincent and the Grenadines",
            countryCode: "1784",
            code: "VC"
        },
        {
            name: "Samoa",
            countryCode: "685",
            code: "WS"
        },
        {
            name: "San Marino",
            countryCode: "378",
            code: "SM"
        },
        {
            name: "Sao Tome and Principe",
            countryCode: "239",
            code: "ST"
        },
        {
            name: "Saudi Arabia",
            countryCode: "966",
            code: "SA"
        },
        {
            name: "Senegal",
            countryCode: "221",
            code: "SN"
        },
        {
            name: "Serbia",
            countryCode: "381",
            code: "RS"
        },
        {
            name: "Seychelles",
            countryCode: "248",
            code: "SC"
        },
        {
            name: "Sierra Leone",
            countryCode: "232",
            code: "SL"
        },
        {
            name: "Singapore",
            countryCode: "65",
            code: "SG"
        },
        {
            name: "Slovakia",
            countryCode: "421",
            code: "SK"
        },
        {
            name: "Slovenia",
            countryCode: "386",
            code: "SI"
        },
        {
            name: "Solomon Islands",
            countryCode: "677",
            code: "SB"
        },
        {
            name: "Somalia",
            countryCode: "252",
            code: "SO"
        },
        {
            name: "South Africa",
            countryCode: "27",
            code: "ZA"
        },
        {
            name: "South Sudan",
            countryCode: "211",
            code: "SS"
        },
        {
            name: "South Georgia and the South Sandwich Islands",
            countryCode: "500",
            code: "GS"
        },
        {
            name: "Spain",
            countryCode: "34",
            code: "ES"
        },
        {
            name: "Sri Lanka",
            countryCode: "94",
            code: "LK"
        },
        {
            name: "Sudan",
            countryCode: "249",
            code: "SD"
        },
        {
            name: "Suriname",
            countryCode: "597",
            code: "SR"
        },
        // {
        //     name: "Svalbard and Jan Mayen",
        //     countryCode: "47",
        //     code: "SJ"
        // },
        {
            name: "Swaziland",
            countryCode: "268",
            code: "SZ"
        },
        {
            name: "Sweden",
            countryCode: "46",
            code: "SE"
        },
        {
            name: "Switzerland",
            countryCode: "41",
            code: "CH"
        },
        {
            name: "Syrian Arab Republic",
            countryCode: "963",
            code: "SY"
        },
        {
            name: "Taiwan",
            countryCode: "886",
            code: "TW"
        },
        {
            name: "Tajikistan",
            countryCode: "992",
            code: "TJ"
        },
        {
            name: "Tanzania, United Republic of Tanzania",
            countryCode: "255",
            code: "TZ"
        },
        {
            name: "Thailand",
            countryCode: "66",
            code: "TH"
        },
        {
            name: "Timor-Leste",
            countryCode: "670",
            code: "TL"
        },
        {
            name: "Togo",
            countryCode: "228",
            code: "TG"
        },
        {
            name: "Tokelau",
            countryCode: "690",
            code: "TK"
        },
        {
            name: "Tonga",
            countryCode: "676",
            code: "TO"
        },
        {
            name: "Trinidad and Tobago",
            countryCode: "1868",
            code: "TT"
        },
        {
            name: "Tunisia",
            countryCode: "216",
            code: "TN"
        },
        {
            name: "Turkey",
            countryCode: "90",
            code: "TR"
        },
        {
            name: "Turkmenistan",
            countryCode: "993",
            code: "TM"
        },
        {
            name: "Turks and Caicos Islands",
            countryCode: "1649",
            code: "TC"
        },
        {
            name: "Tuvalu",
            countryCode: "688",
            code: "TV"
        },
        {
            name: "Uganda",
            countryCode: "256",
            code: "UG"
        },
        {
            name: "Ukraine",
            countryCode: "380",
            code: "UA"
        },
        {
            name: "United Arab Emirates",
            countryCode: "971",
            code: "AE"
        },
        {
            name: "United Kingdom",
            countryCode: "44",
            code: "GB"
        },
        {
            name: "United States",
            countryCode: "1",
            code: "US"
        },
        {
            name: "Uruguay",
            countryCode: "598",
            code: "UY"
        },
        {
            name: "Uzbekistan",
            countryCode: "998",
            code: "UZ"
        },
        {
            name: "Vanuatu",
            countryCode: "678",
            code: "VU"
        },
        {
            name: "Venezuela, Bolivarian Republic of Venezuela",
            countryCode: "58",
            code: "VE"
        },
        {
            name: "Vietnam",
            countryCode: "84",
            code: "VN"
        },
        {
            name: "Virgin Islands, British",
            countryCode: "1284",
            code: "VG"
        },
        {
            name: "Virgin Islands, U.S.",
            countryCode: "1340",
            code: "VI"
        },
        {
            name: "Wallis and Futuna",
            countryCode: "681",
            code: "WF"
        },
        {
            name: "Yemen",
            countryCode: "967",
            code: "YE"
        },
        {
            name: "Zambia",
            countryCode: "260",
            code: "ZM"
        },
        {
            name: "Zimbabwe",
            countryCode: "263",
            code: "ZW"
        }
    ]

export default countryCodes;