import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';



export const getDashboardCount = createAsyncThunk(
    "dashboard/getDashboardCount",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/masterdata/dashboard/counts`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getDashboardNewsfeedData = createAsyncThunk(
    "dashboard/getDashboardNewsfeedData",
    async (params, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/cms/list/all?category=pdmNewsfeed&language=${params.language}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getContentfulData = createAsyncThunk(
    "dashboard/getContentfulData",
    async (params, thunkApi) => {
        try {
                const response = await getAxios().get(
                    `/upids/cms/list/all?category=${params.contentType}&language=${params.language}`
                );
                return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const getDashboardLatestFeedbackData = createAsyncThunk(
    "dashboard/getDashboardLatestFeedbackData",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/feedback/latest?limit=3`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getAverageNpsScore = createAsyncThunk(
    "dashboard/getAverageNpsScore",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/feedback/nps/businessid/${arg.businessId}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getFeedbackSentimentScore = createAsyncThunk(
    "dashboard/getFeedbackSentimentScore",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().get(
                `/upids/feedback/textAnalytics/avg/businessid/${arg.businessId}`
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const sendFeedbackOfProduct = createAsyncThunk(
    "dashboard/sendFeedbackOfProduct",
    async (arg, thunkApi) => {
        try {
            const response = await getAxios().post(
                `/upids/feedback/add`,
                arg
            );
            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);