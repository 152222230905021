import React, { useEffect, useState, useRef, Fragment } from "react";
import { getVersionsList, getVersion, getCurrentVersion, restoreVersion } from "src/redux/versioning/versioningThunk";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import SpinnerLoader from "../components/Spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ImWarning } from "react-icons/im";
import { MdClose } from "react-icons/md";
import { VersioningCard } from "src/components/VersioningCard";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { diff, flattenChangeset } from 'json-diff-ts';

const VersionTable = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const [show, setShow] = useState(false);
  const [versionToRestore, setVersionToRestore] = useState();
  const inputRef = useRef(null);
  var changes = undefined;

  // Handle cancel and close button click in modal
  const handleClose = () => {
    setShow(false);
    setVersionToRestore();
  };

  // Handle OK button click in modal
  const handleOkClick = () => {
    setShow(false);
    if (versionToRestore) {
      const restoreVersionParams = "?eventId=" + versionToRestore;
      dispatch(restoreVersion(restoreVersionParams));
    }
  };

  // Get wanted version
  const getEvent = (eventId) => {
    const getVersionParams = "?eventId=" + eventId;
    dispatch(getVersion(getVersionParams));
  };

  // Get versions of wanted GTIN
  const getVersions = () => {
    if (inputRef.current.value) {
      const versionListParams = "?gtin=" + inputRef.current.value;
      dispatch(getVersionsList(versionListParams));
      const currentVersionParams = inputRef.current.value;
      dispatch(getCurrentVersion(currentVersionParams));
    }
  };

  // Create path prettier
  const prettyPath = (path, key) => {
    const items = path.split(".");
    let pathString = "ROOT";
    items.forEach((element, i) => {
      const bracketIndex = element.indexOf("[");
      if (bracketIndex > 0) {
        element = element.substring(0, bracketIndex);
      }
      if (element !== "$" && element !== key) {
        if (i !== items.length - 1 || pathString === "ROOT") {
          pathString += " » ";
        }

        pathString += element;
      }
    });

    return pathString;
  };

  // Handle Restore button click in versions list
  const handleRestoreButtonClick = (eventId) => {
    setVersionToRestore(eventId);
    setShow(true);
  };

  // Create table row for comparision
  const createRow = (item, index) => {
    
    let itemUpdate = {}
    if(item?.key === 'modifiedBy'){
      itemUpdate = {...item, value : item?.value.slice(0, -3), oldValue : item?.value.slice(0, -3)}
    }else{
      itemUpdate = {...item}
    }

    var currentValue = itemUpdate.value ? itemUpdate.value : "(empty)";
    var oldValue = itemUpdate.oldValue ? itemUpdate.oldValue : "(empty)";

    if (itemUpdate.valueType === "Array") {
      var items = [];
      itemUpdate.value.forEach((value) => {
        items.push(
          <tr key={index}>
            <td>{itemUpdate.key}</td>
            <td>{prettyPath(itemUpdate.path, itemUpdate.key)}</td>
            <td
              className="text-danger"
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {itemUpdate.type === "REMOVE" ? "(empty)" : typeof (value) === "object" ? JSON.stringify(value) : value}
            </td>
            <td
              className="text-success"
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {itemUpdate.type === "REMOVE" ? typeof (value) === "object" ? JSON.stringify(value) : value : "(empty)"}
            </td>
          </tr>
        );
      });
      return items;
    } else if (itemUpdate.valueType === "Object") {
      var items = [];
      for (var [key, value] of Object.entries(itemUpdate.value)) {
        if (typeof value === "object") {
          value = JSON.stringify(value);
        }

        items.push(
          <tr key={index}>
            <td>{key}</td>
            <td>ROOT » {itemUpdate.key}</td>
            <td
              className="text-danger"
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {itemUpdate.type === "REMOVE" ? "(empty)" : value}
            </td>
            <td
              className="text-success"
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {itemUpdate.type === "REMOVE" ? value : "(empty)"}
            </td>
          </tr>
        );
      }
      return items;
    } else {
      if (itemUpdate.type === "REMOVE") {
        currentValue = itemUpdate.oldValue ? itemUpdate.oldValue : "(empty)";
        oldValue = itemUpdate.value ? itemUpdate.value : "(empty)";
      }
      return (
        <Fragment key={index}>
          <tr>
            <td>{itemUpdate.key}</td>
            {/* <td>{itemUpdate.type}</td> */}
            <td>{prettyPath(itemUpdate.path, itemUpdate.key)}</td>
            <td
              className="text-danger"
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {currentValue}
            </td>
            <td
              className="text-success"
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {oldValue}
            </td>
          </tr>
        </Fragment>
      );
    }
  };

  // Load initial data
  useEffect(() => {
    if (props.gtin || props._2an) {
      const productCode = props.gtin ? props.gtin : props._2an;
      const versionListParams = "?gtin=" + productCode;
      const currentVersionParams = productCode;

      dispatch(getVersionsList(versionListParams));
      dispatch(getCurrentVersion(currentVersionParams));

      if (props.mode !== "product") {
        inputRef.current.value = productCode;
      }
    }
  }, []);

  const _state = useSelector((state) => state.versioning, shallowEqual);

  const isLoading = _state.actionsLoading;
  const isCompareLoading = _state.compareLoading;
  if (_state.currentVersion && _state.versionToCompare) {
    const diffs = diff(
      _state.currentVersion,
      _state.versionToCompare
      );
      const flatChanges = flattenChangeset(diffs);
    changes = flatChanges;
  }

  return [
    <div>
      {props.mode !== "product" ? (
        <div className="row mt-2 mb-4">
          <div className="col-8">
            <form className="form-inline">
              <input
                className="form-control me-2"
                type="text"
                id="gtin"
                placeholder={t("_GTIN")}
                ref={inputRef}
              />
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => getVersions()}
              >
                Search
              </button>
            </form>
          </div>
        </div>
      ) : (<></>)}
      {isLoading ? (
        <div>
          <SpinnerLoader />
        </div>
      ) : (
        <div>
          {!!_state.versions ? (
            <div className="versioningTabDiv">
              <div className="versioningCard">

                {!!_state.versions ? (
                  _state.versions.slice(0).reverse().map((o, index, arr) => (
                    index == 0 ?
                      <div key={index} className='ms-2' style={{ marginTop: 20, marginBottom: 20 }}>
                        <div>{moment.unix(o.timestamp).format("YYYY-MM-DD HH:mm:ss")}</div>
                        <div>{t("Current version")}</div>
                      </div> :
                      <div key={index} className="m-2">
                        <VersioningCard
                          text={moment.unix(o.timestamp).format("YYYY-MM-DD HH:mm:ss")}
                          onCompareClick={() => getEvent(o.event_id)}
                          onRestoreClick={() => handleRestoreButtonClick(o.event_id)}
                        />
                      </div>

                  ))
                ) : <div>{t("No versions found")}</div>}

              </div>
              <div className="versioningTableCard mt-4">
                <div className="text-center">
                  <h6>{t("Differences between selected and current version")}</h6>
                </div>
                {isCompareLoading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{t("Loading")}</span>
                  </div>
                ) : (
                  <div>
                    {!!_state.versionToCompare ? (
                      <div className="table-responsive-md mt-4">
                        <table
                          className="table table-striped"
                          style={{ width: "100%", tableLayout: "fixed" }}
                          width="100%"
                        >
                          <thead>
                            <tr>
                              <th scope="col">{t("Key")}</th>
                              {/* <th scope="col">Type</th> */}
                              <th scope="col">{t("Path")}</th>
                              <th scope="col">
                                {t("Value in selected version")}
                                {/* <br />({_state.versionToCompare.modifiedDate}) */}
                              </th>
                              <th scope="col">
                                {t("Value in current version")}
                                {/* <br />({_state.currentVersion.modifiedDate}) */}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {!!changes ? (
                              changes.map((i, index) => createRow(i, index))
                            ) : (
                              <tr>
                                <td></td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
      <Modal size="md" show={show} onHide={handleClose} centered={true}>
        <Modal.Header>
          <span className="close-text" onClick={handleClose} style={{ marginLeft: 400 }}>
            <MdClose
              style={{ marginBottom: 2, color: "black" }}
              fontSize={24}
              fontWeight={"bold"}
            />
            {t("Close")}
          </span>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "block",
            padding: 50,
            textAlign: "center",
            color: "#131e27",
            opacity: 1,
            fontWeight: "bold",
            fontSize: 18,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ImWarning
              style={{
                width: 40,
                height: 40,
                textAlign: "center",
                alignSelf: "center",
                color: "red",
                marginBottom: "20px",
              }}
            />
            <span>{t("Do you really want to restore this version")}</span>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button variant="secondary" type="button" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <Button variant="danger" type="button" onClick={handleOkClick}>
            {t("Ok")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  ];
};

export default VersionTable;
