import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios } from 'src/helper/axiosInterceptor';




export const swaggerauth = createAsyncThunk(
    "swaggerauth/swaggerauth",
    async (arg, thunkApi) => {
        try {
            let response
            if (arg == "/swagger/private") { // arg == fromRoute
                response = await getAxios().get(
                    `/iam/swagger/private`
                )
            } else {
                response = await getAxios().get(
                    `/iam/swagger/public`
                )
            }

            return response.data;
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);
